import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { Guid } from 'guid-typescript';
import L from 'leaflet';
import 'leaflet-editable';
import 'leaflet-draw';
import { environment } from 'src/environments/environment';
import {
  ILocationCircle,
  ILocationPolygon,
} from '../table/interfaces/table.interfaces';

export interface ISearchCoordinates {
  lat: number | undefined;
  lng: number | undefined;
  text: string | undefined;
}

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
})
export class MapSearchComponent {
  @Output() markerMoved = new EventEmitter<ISearchCoordinates>();
  @Output() circleSearchUpdated = new EventEmitter<ILocationCircle | null>();
  @Output() polygonSearchUpdated = new EventEmitter<
    ILocationPolygon[] | null
  >();
  @Input() sampleCoordinates!: ISearchCoordinates[];
  @Input() speciesCoordinates!: ISearchCoordinates[];
  @Input() randomSpeciesCoordinates!: ISearchCoordinates[];
  @Input() referencesCoordinates!: ISearchCoordinates[];
  @Input() idOfMap?: string;
  @Input() disabled = false;
  @Input() spaLayer = false;
  @Input() hasRandom = false;
  @Input() hasSample = false;
  @Input() hasSpecies = false;
  @Input() hasReference = false;

  private map!: L.Map;
  private marker!: L.Marker;
  public mapId: string;
  private basicLayer!: L.TileLayer;
  private secondaryLayer!: L.TileLayer;
  private alternativeLayer!: L.TileLayer;
  private realLayer!: L.TileLayer;
  private gridLayer!: L.TileLayer;
  private naturaLayerSCI!: L.TileLayer;
  private naturaLayerSPA!: L.TileLayer;
  private seaLayer!: L.TileLayer;
  private landLayer!: L.TileLayer;
  private polygonDrawer!: L.Draw.Polygon;
  private drawItems!: L.FeatureGroup;

  public gridLayerToggle: boolean = false;
  public naturaLayerSCIToggle: boolean = false;
  public naturaLayerSPAToggle: boolean = false;
  public seaLayerToggle: boolean = false;
  public landLayerToggle: boolean = false;
  public samplesLayerToggle: boolean = true;
  public speciesLayerToggle: boolean = true;
  public randomSpeciesLayerToggle: boolean = true;

  private samplesLayer: L.LayerGroup = new L.LayerGroup();
  private speciesLayer: L.LayerGroup = new L.LayerGroup();
  private randomSpeciesLayer: L.LayerGroup = new L.LayerGroup();
  private referencesLayer: L.LayerGroup = new L.LayerGroup();

  public layerControl: L.Control.Layers = new L.Control.Layers();

  public circle!: L.Circle<any> | null;
  public polygonPoints!: ILocationPolygon[] | null;
  private loadingOptions = {
    loading: false,
    vertexId: null,
    className: 'vertex-loading',
    dragged: false,
  };

  constructor(private commonService: CommonService) {
    this.mapId = this.idOfMap ? this.idOfMap : Guid.create().toString();
  }

  ngAfterViewChecked(): void {
    this.map.invalidateSize(true);
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: any): void {
    if (changes.speciesCoordinates?.currentValue != null) {
      this.populateSpeciesCoordinates();
    }

    if (changes.randomSpeciesCoordinates?.currentValue != null) {
      this.populateRandomSpeciesCoordinates();
    }

    if (changes.referencesCoordinates?.currentValue != null) {
      this.populateReferencesCoordinates();
    }

    if (changes.sampleCoordinates?.currentValue != null) {
      this.populateSampleCoordinates();
    }
  }

  private populateSampleCoordinates(): void {
    if (this.map != null) {
      this.samplesLayer.clearLayers();

      this.sampleCoordinates.forEach((item) => {
        const marker = this.createMarker(
          item.lat as number,
          item.lng as number,
          item.text,
          '#0149b0',
          'sample-marker'
        );
        marker.addTo(this.samplesLayer);
      });
    }
  }

  private populateSpeciesCoordinates(): void {
    if (this.map != null) {
      this.speciesLayer.clearLayers();

      this.speciesCoordinates.forEach((item) => {
        const marker = this.createMarker(
          item.lat as number,
          item.lng as number,
          item.text,
          '#9dacff',
          'species-marker'
        );
        marker.addTo(this.speciesLayer);
      });
    }
  }

  private populateRandomSpeciesCoordinates(): void {
    if (this.map != null) {
      this.randomSpeciesLayer.clearLayers();

      this.randomSpeciesCoordinates.forEach((item) => {
        const marker = this.createMarker(
          item.lat as number,
          item.lng as number,
          item.text,
          '#ffd89d',
          'random-marker'
        );
        marker.addTo(this.randomSpeciesLayer);
      });
    }
  }

  private populateReferencesCoordinates(): void {
    if (this.map != null) {
      this.referencesLayer.clearLayers();

      this.referencesCoordinates.forEach((item) => {
        const marker = this.createMarker(
          item.lat as number,
          item.lng as number,
          item.text,
          '#e114dd',
          'reference-marker'
        );
        marker.addTo(this.referencesLayer);
      });
    }
  }

  private createMarker(
    latitude: number,
    longitude: number,
    text: string | undefined,
    fillColor: string,
    className: string
  ): L.CircleMarker {
    const marker = L.circleMarker(new L.LatLng(latitude, longitude), {
      fillColor,
      color: '#fff',
      weight: 1,
      fillOpacity: 1,
      radius: 8,
    });
    if (text != null) {
      marker.bindTooltip(text, {
        permanent: false,
        direction: 'top',
        opacity: 1,
        className,
      });
    }

    return marker;
  }

  /**
   * Initializes the needed map layers.
   */
  private initializeLayers(): void {
    this.basicLayer = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 17,
      }
    );

    this.secondaryLayer = L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 17,
      }
    );

    this.alternativeLayer = L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 17,
      }
    );

    this.realLayer = L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 17,
      }
    );

    this.naturaLayerSCI = L.tileLayer(
      `${environment.endpoint}tiles/${'sci'}/{z}/{x}/{y}.png`,
      {
        maxZoom: 14,
        minZoom: 6,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );

    this.naturaLayerSPA = L.tileLayer(
      `${environment.endpoint}tiles/${'spa'}/{z}/{x}/{y}.png`,
      {
        maxZoom: 14,
        minZoom: 6,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );

    this.gridLayer = L.tileLayer(
      `${environment.endpoint}tiles/grid/{z}/{x}/{y}.png`,
      {
        maxZoom: 14,
        minZoom: 8,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );

    this.seaLayer = L.tileLayer(
      `${environment.endpoint}tiles/sea/{z}/{x}/{y}.png`,
      {
        maxZoom: 11,
        minZoom: 7,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );

    this.landLayer = L.tileLayer(
      `${environment.endpoint}tiles/land/{z}/{x}/{y}.png`,
      {
        maxZoom: 11,
        minZoom: 7,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );
  }

  private initMap() {
    this.initializeLayers();
    this.map = L.map(`map-${this.mapId}`, {
      editable: true,
    }).setView([37.98381, 23.727539], 6);
    this.basicLayer.addTo(this.map);
    L.control.scale().addTo(this.map);

    this.samplesLayer.addTo(this.map);
    this.speciesLayer.addTo(this.map);
    this.randomSpeciesLayer.addTo(this.map);
    this.referencesLayer.addTo(this.map);

    this.layerControl = L.control.layers({
      '<span style="margin-left: 8px"></span>Χάρτης - Βασικός': this.basicLayer,
      '<span style="margin-left: 8px"></span>Χάρτης - Βασικός 2':
        this.secondaryLayer,
      '<span style="margin-left: 8px"></span>Χάρτης - Εναλλακτικός':
        this.alternativeLayer,
      '<span style="margin-left: 8px"></span>Χάρτης - Ρεαλιστικός':
        this.realLayer,
    });

    if (this.hasSample) {
      this.layerControl.addOverlay(
        this.samplesLayer,
        '<span style="width: 8px;height: 8px;display: inline-block;border-radius: 50%;margin-left: 8px;margin-right: 8px;background-color: #0149b0;"></span>Δείγματα'
      );
    }

    if (this.hasSpecies) {
      this.layerControl.addOverlay(
        this.speciesLayer,
        '<span style="width: 8px;height: 8px;display: inline-block;border-radius: 50%;margin-left: 8px;margin-right: 8px;background-color: #9dacff;"></span>Παρουσία Ειδών'
      );
    }

    if (this.hasRandom) {
      this.layerControl.addOverlay(
        this.randomSpeciesLayer,
        '<span style="width: 8px;height: 8px;display: inline-block;border-radius: 50%;margin-left: 8px;margin-right: 8px;background-color: #ffd89d;"></span>Τυχαίες Παρατηρήσεις'
      );
    }

    if (this.hasReference) {
      this.layerControl.addOverlay(
        this.randomSpeciesLayer,
        '<span style="width: 8px;height: 8px;display: inline-block;border-radius: 50%;margin-left: 8px;margin-right: 8px;background-color: #e114dd;"></span>Βιβλιογραφικές Αναφορές'
      );
    }

    this.layerControl.addOverlay(
      this.naturaLayerSCI,
      '<span style="width: 8px;height: 8px;display: inline-block;margin-left: 8px;margin-right: 8px;border: 1px dotted #000;border-top-right-radius: 2px;border-bottom-left-radius: 2px;background-color: #1d1de199;"></span>Περιοχές Natura SCI'
    );
    this.layerControl.addOverlay(
      this.naturaLayerSPA,
      '<span style="width: 8px;height: 8px;display: inline-block;margin-left: 8px;margin-right: 8px;border: 1px dotted #000;border-top-right-radius: 2px;border-bottom-left-radius: 2px;background-color: #ef2140a6;"></span>Περιοχές Natura SPA'
    );
    this.layerControl.addOverlay(
      this.gridLayer,
      '<span style="width: 8px;height: 8px;display: inline-block;margin-left: 8px;margin-right: 8px;font-weight: bold;">#</span>Grid Cells'
    );

    this.layerControl.addTo(this.map);

    this.map.on('draw:created', (e) => {
      this.drawItems.addLayer(e.layer);
      this.polygonPoints = e.layer
        .toGeoJSON()
        .geometry.coordinates[0].map((item: any) => {
          return {
            latitude: item[1],
            longitude: item[0],
          };
        });
    });

    this.polygonDrawer = new L.Draw.Polygon(this.map as any, {
      allowIntersection: false, // Restricts shapes to simple polygons
      drawError: {
        color: '#e1e100', // Color the shape will turn when intersects
        message: '<strong>Λάθος πολύγωνο!<strong>', // Message that will show when intersect
      },
      shapeOptions: {
        color: '#598258',
      },
    });
  }

  /**
   * Toggles the natura SCI layer.
   */
  public toggleNaturaLayerSCI(): void {
    if (this.naturaLayerSCIToggle) {
      this.naturaLayerSCI.addTo(this.map);
    } else {
      this.map.removeLayer(this.naturaLayerSCI);
    }
  }

  /**
   * Toggles the natura SPA layer.
   */
  public toggleNaturaLayerSPA(): void {
    if (this.naturaLayerSPAToggle) {
      this.naturaLayerSPA.addTo(this.map);
    } else {
      this.map.removeLayer(this.naturaLayerSPA);
    }
  }

  /**
   * Toggles the grid layer.
   */
  public toggleGridLayer(): void {
    if (this.gridLayerToggle) {
      this.gridLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.gridLayer);
    }
  }

  /**
   * Toggles the sea layer.
   */
  public toggleSeaLayer(): void {
    if (this.seaLayerToggle) {
      this.seaLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.seaLayer);
    }
  }

  /**
   * Toggles the land layer.
   */
  public toggleLandLayer(): void {
    if (this.landLayerToggle) {
      this.landLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.landLayer);
    }
  }

  /**
   * Toggles the samples layer.
   */
  public toggleSamplesLayer(): void {
    if (this.samplesLayerToggle) {
      this.samplesLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.samplesLayer);
    }
  }

  /**
   * Toggles the species layer.
   */
  public toggleSpeciesLayer(): void {
    if (this.speciesLayerToggle) {
      this.speciesLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.speciesLayer);
    }
  }

  /**
   * Toggles the random species layer.
   */
  public toggleRandomSpeciesLayer(): void {
    if (this.randomSpeciesLayerToggle) {
      this.randomSpeciesLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.randomSpeciesLayer);
    }
  }

  public setCircleVertices(map: L.Map) {
    var self = this;
    var CircleVerticesEditor = (L.Editable as any).VertexMarker.extend({
      //Loading Options for a vertex
      initialize: function (
        latlng: any,
        latlngs: any,
        editor: any,
        options: any
      ) {
        //Getting vertex Index
        this.latlng = latlng;
        this.latlngs = latlngs;
        let index = this.getIndex();

        this.options.className =
          'leaflet-div-icon leaflet-vertex-icon vertex-' + index;

        (L.Editable as any).VertexMarker.prototype.initialize.call(
          this,
          latlng,
          latlngs,
          editor,
          options
        );
      },

      onDragEnd: function (e: any) {
        e.vertex = this;
        this.toggleVertexLoading(e, true);
        (L.Editable as any).VertexMarker.prototype.onDragEnd.call(this, e);
      },

      toggleVertexLoading: function (e: any, status: any) {
        //Getting VertexId
        let vertexClasses =
          e['vertex']['options']['icon']['options']['className'].split(' ');
        let vertexClass = vertexClasses[vertexClasses.length - 1];

        if (self.loadingOptions != null) {
          self.loadingOptions.loading = status;

          if (status)
            //  self.loadingOptions.vertexId = vertexClass[vertexClass.length - 1];
            L.DomUtil.addClass(
              document.getElementsByClassName(vertexClass)[0] as HTMLElement,
              'vertex-loading'
            );
          else
            L.DomUtil.removeClass(
              document.getElementsByClassName(vertexClass)[0] as HTMLElement,
              'vertex-loading'
            );
        }
      },
    });
    return CircleVerticesEditor;
  }

  public addCircle(): void {
    this.map.whenReady(() => {
      if (this.circle != null) {
        return;
      }
      this.removePolygonDraw();

      const vertexMarkerClass = this.setCircleVertices(this.map);
      (this.map as any)['editTools']['options']['vertexMarkerClass'] =
        vertexMarkerClass;

      const mapCenter = this.map.getCenter();
      const zoom = this.map.getZoom();
      const lat = this.map.getCenter().lat;
      const metersPerPixel =
        (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);
      // Multiply that by a factor based on how large the circle should appear on the screen
      const minDimension = Math.min(this.map.getSize().x, this.map.getSize().y);
      const radius = metersPerPixel * minDimension * 0.25;

      const options: L.CircleMarkerOptions = {
        radius: radius,
        interactive: true,
        fillColor: '#000000',
        fillOpacity: 0.2,
        className: 'map-circle',
        color: '#598258',
      };
      this.circle = L.circle(mapCenter, options);
      this.circle.addTo(this.map);
      (this.circle as any).enableEdit();
      this.circle.on('editable:vertex:dragend', (e) => {});
    });
  }

  /**
   * Removes circle mode from map.
   */
  public removeCircle() {
    if (this.circle != null) {
      this.circle.removeFrom(this.map);
      this.circleSearchUpdated.emit(null);
      this.circle = null;
    }

    this.mapInvalidateSize();
  }

  public addPolygonDraw(): void {
    this.removeCircle();
    this.polygonDrawer.enable();

    if (this.drawItems != null) {
      this.drawItems.clearLayers();
    }

    this.polygonPoints = [];

    this.drawItems = new L.FeatureGroup();
    this.map.addLayer(this.drawItems);
  }

  public removePolygonDraw(): void {
    this.polygonDrawer.disable();
    this.polygonPoints = null;
    this.polygonSearchUpdated.emit(null);

    if (this.drawItems != null) {
      this.drawItems.clearLayers();
    }
  }

  public search(): void {
    if (this.circle != null) {
      this.circleSearchUpdated.emit({
        latitude: this.circle.getLatLng().lat,
        longitude: this.circle.getLatLng().lng,
        radius: this.circle.getRadius(),
      });
    } else if (this.polygonPoints != null && this.polygonPoints.length > 0) {
      this.polygonSearchUpdated.emit(this.polygonPoints);
    }
  }

  private mapInvalidateSize(option: boolean = true): void {
    try {
      this.map.invalidateSize(option);
    } catch (exception) {}
  }
}
