<button
  type="button"
  class="custom-btn"
  (click)="buttonClicked.emit()"
  [disabled]="isDisabled"
  [ngClass]="{ isDisabled: isDisabled }"
  ngbTooltip="{{ tooltip }}"
>
  <span *ngIf="isExport" class="pi pi-file-export"></span>
  {{ btnText | translate | uppercase }}
</button>
