<div class="header-container">
  <div class="logo-wrapper">
    <img
      class="logo-image"
      src="assets/images/enveco.svg"
      (click)="NavigateToHome()"
    />
    <img src="assets/images/ekby.png" class="logo-ekby" />
    <span class="header-text">{{ "APP_TITLE" | translate | uppercase }}</span>
  </div>

  <button
    type="button"
    class="sign-out"
    (click)="logOutUser()"
    [ngClass]="{ hidden: router.url === '/' }"
    ngbTooltip="{{ 'LOGOUT' | translate }}"
  >
    <img class="exit" src="assets/images/sign_out.svg" />
  </button>
</div>
