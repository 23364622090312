<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>
<div class="title-container">
  <span class="title-text uppercase"
    >{{ "NEW_REGISTRATION" | translate }} - {{ "CHLORIS" | translate }}</span
  >
</div>
<div class="chloris-container">
  <div class="info-container">
    <span class="info-text uppercase">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container">
    <div class="form-row form-3">
      <app-input
        [formGroup]="chlorisForm"
        [formName]="'samplingCode'"
        [label]="'SAMPLE_CODE'"
        [required]="true"
      ></app-input>
    </div>
    <div class="form-row form-3">
      <app-dropdown
        class="dropdown-column"
        [formGroup]="chlorisForm"
        [formName]="'ergoEpoptiaFasiErgou'"
        [label]="'ERGO_EPOPTIA_FASI_ERGOU'"
        [dropdownList]="ergoEpoptiaFasiErgouDropdown"
        [required]="true"
        [preDefinedId]="chlorisById?.ergoEpoptiaFasiErgou"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-input
        [mask]="'00/00/0000'"
        [formGroup]="chlorisForm"
        [formName]="'date'"
        [label]="'DATE'"
        [required]="true"
      ></app-input>
      <app-input
        [formGroup]="chlorisForm"
        [formName]="'author'"
        [label]="'AUTHOR'"
      ></app-input>
      <app-input
        [formGroup]="chlorisForm"
        [formName]="'researcher'"
        [label]="'RESEARCHER'"
      ></app-input>
      <!-- <app-input
        [formGroup]="chlorisForm"
        [formName]="'releveNR'"
        [label]="'RELEVE_NROR'"
        [required]="true"
      ></app-input> -->
    </div>

    <div class="form-row form-3">
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'species'"
        [label]="'SPECIES'"
        [dropdownList]="speciesDropdown"
        [required]="true"
        [preDefinedId]="chlorisById?.species"
      ></app-dropdown>
      <app-input
        [formGroup]="chlorisForm"
        [formName]="'otherSpecies'"
        [label]="'OTHER_SPECIES'"
        [ngClass]="{
          disabled:
            chlorisForm.controls['otherSpecies'].disabled &&
            (!chlorisForm.controls['species'].disabled ||
              chlorisForm.controls['otherSpecies'].value === '')
        }"
      ></app-input>
    </div>
    <div class="form-row">
      <div class="checkbox-container">
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="chlorisForm.value.parII" />
          {{ "PARII" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="chlorisForm.value.parIV" />
          {{ "PARIV" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="chlorisForm.value.parV" />
          {{ "PARV" | translate }}
        </label>
      </div>
    </div>

    <div class="form-row">
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'financialInstrument'"
        [label]="'FINANCIAL_INSTRUMENT'"
        [dropdownList]="financialDropdown"
        [required]="true"
        [preDefinedId]="chlorisById?.financialInstrument"
      ></app-dropdown>
      <app-input
        [formGroup]="chlorisForm"
        [formName]="'altitudeText'"
        [label]="'BOOK_ALTITUDE'"
      ></app-input>
      <app-input
        [formGroup]="chlorisForm"
        [formName]="'epoptiaOneId'"
        [label]="'EPOPTIA_I'"
      ></app-input>
    </div>

    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="chlorisForm"
      [formName]="'epoptiaOneComments'"
      [label]="'EPOPTIA_I_COMMENTS'"
    ></app-textarea>

    <div class="form-row form-3">
      <app-input
        [formGroup]="chlorisForm"
        [formName]="'substrate'"
        [label]="'GEOLOGICAL_SUBSTRATE'"
      ></app-input>
      <app-input
        [formGroup]="chlorisForm"
        [formName]="'slope'"
        [label]="'SLOPE'"
      ></app-input>
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'relief'"
        [label]="'RELIEF'"
        [dropdownList]="reliefDropdown"
        [required]="true"
        [preDefinedId]="chlorisById?.relief"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-dropdown
        [style.max-width]="'100%'"
        [formGroup]="chlorisForm"
        [formName]="'soil'"
        [label]="'SOIL'"
        [dropdownList]="soilDropdown"
        [required]="true"
        [preDefinedId]="chlorisById?.soil"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'a0'"
        [label]="'A0_DEPTH'"
        [dropdownList]="depthDropdown"
        [required]="true"
        [preDefinedId]="chlorisById?.a0"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'exposit'"
        [label]="'EXPOSITION_D'"
        [dropdownList]="expositionDropdown"
        [required]="true"
        [preDefinedId]="chlorisById?.exposit"
      ></app-dropdown>
    </div>

    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="chlorisForm"
      [formName]="'habitat'"
      [label]="'HABITAT'"
    ></app-textarea>

    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="chlorisForm"
      [formName]="'threats'"
      [label]="'HABITAT_THREATS'"
    ></app-textarea>

    <div class="form-row">
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'quality'"
        [label]="'QUALITY'"
        [dropdownList]="sufficiencyDropdown"
        [preDefinedId]="chlorisById?.quality"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'qualityTrend'"
        [label]="'QUALITY_TREND'"
        [dropdownList]="trendingDropdown"
        [preDefinedId]="chlorisById?.qualityTrend"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'qualityFix'"
        [label]="'QUALITY_FIX'"
        [dropdownList]="fixPossibilityDropdown"
        [preDefinedId]="chlorisById?.qualityFix"
      ></app-dropdown>
    </div>

    <div class="form-row">
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'area'"
        [label]="'AREA'"
        [dropdownList]="sufficiencyDropdown"
        [preDefinedId]="chlorisById?.quality"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'areaTrend'"
        [label]="'AREA_TREND'"
        [dropdownList]="trendingDropdown"
        [preDefinedId]="chlorisById?.qualityTrend"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="chlorisForm"
        [formName]="'areaFix'"
        [label]="'AREA_FIX'"
        [dropdownList]="fixPossibilityDropdown"
        [preDefinedId]="chlorisById?.qualityFix"
      ></app-dropdown>
    </div>

    <div class="form-row" [formGroup]="chlorisForm" [hidden]="!readOnlyForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="parII" />
        {{ "PARII_FULL" | translate }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="parIV" />
        {{ "PARIV_FULL" | translate }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="parV" />
        {{ "PARV_FULL" | translate }}
      </label>
    </div>
    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="chlorisForm"
      [formName]="'observations'"
      [label]="'SAMPLE_OBSERVATIONS'"
    ></app-textarea>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "LOCATION" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container form-1">
    <div class="form-row" [formGroup]="chlorisForm" [hidden]="readOnlyForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isEGSA" />
        {{ coordSystemDropdown[0].id }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isWGS84" />
        {{ coordSystemDropdown[1].id }}
      </label>
    </div>
    <div class="form-row">
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="chlorisForm"
        [formName]="'lat'"
        [label]="'LATITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="chlorisForm"
        [formName]="'long'"
        [label]="'LONGTITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="chlorisForm"
        [formName]="'x'"
        [label]="'X'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="chlorisForm"
        [formName]="'y'"
        [label]="'Y'"
        [required]="true"
      ></app-input>
    </div>
  </div>
  <br />
  <div class="map-container">
    <app-map
      [coordinates]="coordinates"
      (markerMoved)="onMarkerMoved($event)"
      [disabled]="readOnlyForm"
    ></app-map>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>

  <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" class="multi-tab-buttons">
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name }}</a
      >
      <ng-template ngbNavContent>
        <div
          [ngClass]="
            readOnlyForm ? 'form-container form-2' : 'form-container form-1'
          "
        >
          <div class="form-row form-3">
            <app-dropdown
              [formGroup]="chlorisForm"
              [hidden]="!readOnlyForm"
              [formName]="'naturaCode'"
              [label]="'NATURA_CODE'"
              [dropdownList]="sitesDropdown"
              [preDefinedId]="chlorisById?.naturaCode"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="chlorisForm"
              [hidden]="!readOnlyForm"
              [formName]="'gridCell'"
              [label]="'GRID_CELL'"
              [dropdownList]="codesDropdown"
              [preDefinedId]="chlorisById?.gridCell"
            ></app-dropdown>
          </div>
          <div class="form-row form-3">
            <app-dropdown
              [formGroup]="chlorisForm"
              [hidden]="!readOnlyForm"
              [formName]="'prefecture'"
              [label]="'PREFECTURE'"
              [dropdownList]="prefecturesDropdown"
              [preDefinedId]="chlorisById?.prefecture"
            ></app-dropdown>
            <app-input
              [formGroup]="chlorisForm"
              [formName]="'locality'"
              [label]="'LOCATION'"
            ></app-input>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[1].name }}</a
      >
      <ng-template ngbNavContent>
        <app-chloris-functions
          [formGroup]="chlorisForm"
          [functions]="functions"
          [functionsById]="functionsById"
          [disabledControls]="readOnlyForm"
          [sampleId]="sampleId"
        ></app-chloris-functions>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
        >{{ multiTabGroup[2].name }}</a
      >
      <ng-template ngbNavContent>
        <app-chloris-threats
          [formGroup]="chlorisForm"
          [threats]="pressures"
          [threatsById]="pressuresById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="chlorisById?.dataVersion"
          [sampleId]="sampleId"
        ></app-chloris-threats>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[3].isChecked }"
        >{{ multiTabGroup[3].name }}</a
      >
      <ng-template ngbNavContent>
        <app-chloris-population
          [formGroup]="chlorisForm"
          [populations]="populations"
          [populationsById]="populationsById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="chlorisById?.dataVersion"
          [sampleId]="sampleId"
        ></app-chloris-population>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <ng-container
    *ngIf="
      !sampleId ||
      (sampleId && predefinedFiles.length > 0) ||
      (sampleId && !readOnlyForm)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    (buttonClicked)="prepareChlorisRequest()"
    [btnText]="sampleId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    [isDisabled]="!chlorisForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
