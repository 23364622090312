import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ISampleMammalHabitatsDto } from 'src/app/core/dtos/mammals.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { MammalsService } from '../../services/mammals.service';

@Component({
  selector: 'app-mammal-habitats',
  templateUrl: './mammal-habitats.component.html',
  styleUrls: ['./mammal-habitats.component.scss'],
})
export class MammalHabitatsComponent implements OnInit, OnChanges {
  @Input() public habitats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public habitatsById: ISampleMammalHabitatsDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private mammalsService: MammalsService,
    private translate: TranslateService
  ) {}

  public habitatCode1Dropdown: IDropdownItem[] = [
    { id: '1', name: ' Δάσος Κωνοφόρων' },
    { id: '2', name: ' Δάσος Φυλλοβόλων' },
    { id: '3', name: ' Δάσος Μεικτό' },
    { id: '4', name: ' Φρύγανα' },
    { id: '5', name: ' Μακκία' },
    { id: '6', name: ' Καλλιέργειες' },
    { id: '7', name: ' Αμμοθίνες' },
    { id: '8', name: ' Υγρά Λιβάδια' },
    { id: '9', name: ' Λίμνη' },
    { id: '10', name: ' Ποτάμι' },
    { id: '11', name: ' Κανάλι' },
    { id: '12', name: ' Καλαμιώνας' },
    { id: '13', name: ' Λιβάδι' },
    { id: '14', name: ' Σπήλαιο' },
    { id: '15', name: ' Σάρα (Χαλικώνας)' },
    { id: '16', name: ' Παρόχθιο δάσος' },
    { id: '17', name: ' Ορυχείο' },
    { id: '18', name: ' Σπίτι' },
    { id: '19', name: ' Γέφυρα' },
    { id: '20', name: ' Γαλαρία' },
  ];

  public habitatCode2Dropdown: IDropdownItem[] = [
    { id: 'a', name: ' Με Υποόροφο' },
    { id: 'b', name: ' Χωρίς Υποόροφο' },
    { id: 'c', name: ' Ξέφωτο-Άνοιγμα' },
    { id: 'd', name: ' Βραχώδες' },
    { id: 'e', name: ' Ετήσιες Καλλιέργειες' },
    { id: 'f', name: ' Δενδρώδεις Καλλιέργειες' },
    { id: 'g', name: ' Πέτρες' },
    { id: 'h', name: ' Συστάδα σε καλλιέργειές' },
    { id: 'i', name: ' φυτοφράχτης' },
  ];

  public habitatCode3Dropdown: IDropdownItem[] = [
    { id: 'Α', name: ' Φυσικό' },
    { id: 'Β', name: ' Τεχνητό' },
    { id: 'Γ', name: ' Έχει υποστεί αλλαγές' },
  ];

  public humanActivitiesDropdown: IDropdownItem[] = [
    { id: '1', name: 'Καμία' },
    { id: '2', name: 'Υλοτομία' },
    { id: '3', name: 'Αλιεία' },
    { id: '4', name: 'Τουρισμός' },
    { id: '5', name: 'Κτηνοτροφία (παρουσία ζώων, εγκαταστάσεων)' },
    { id: '6', name: 'Γεωργία' },
    { id: '7', name: 'Κυνήγι' },
    { id: '8', name: 'Έργα δόμησης, οδοποιίας, κλπ' },
    { id: '9', name: 'Φράγμα' },
    { id: '10', name: 'ΆΛΛΟ' },
  ];

  /**
   *
   */
  ngOnInit(): void {
    if (this.habitatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['habitatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    if (this.habitatsById.length > 0) {
      this.habitatsById.forEach(() => {
        this.addNewHabitat();
      });
      this.habitats.patchValue(this.habitatsById);
      this.habitats.markAsTouched();
      this.habitatsById = [];
      if (this.disabledControls === true) {
        this.habitats.disable();
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.habitats.controls[index] as FormGroup;
  }

  public addNewHabitat(): void {
    this.habitats.push(this.mammalsService.initHabitatsFormGroup(this.fb));
  }

  public removeHabitat(index: number): void {
    this.habitats.removeAt(index);
  }
}
