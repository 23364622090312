import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, forkJoin, Subject, takeUntil, finalize } from 'rxjs';
import {
  IFishDto,
  IFishSiteListDto,
  IMainCTSDto,
  ISampleFishOtherThreatsDto,
  ISampleFishSpeciesAndThreatsDto,
  ISampleFishSpeciesDto,
} from 'src/app/core/dtos/fish.dto';
import {
  ICellCodeDto,
  INaturaSitesDto,
  IPrefecturesDto,
} from 'src/app/core/dtos/protocols.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { ITabBtnGroup } from 'src/app/shared/button-group/button-group.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { ISampleIdResponse } from 'src/app/shared/dtos/generic.dto';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import { FishService } from '../../services/fish.service';
import { ProtocolsService } from '../../services/protocols.service';
import { IFileDeleteRequest, IFilePayload } from 'src/app/shared/files-container/files-container.component';

@Component({
  selector: 'app-fish',
  templateUrl: './fish.component.html',
  styleUrls: ['./fish.component.scss'],
})
export class FishComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public fish: IFishDto = {};
  public fishById?: IFishDto;
  public fishForm!: FormGroup;
  public naturaSites: INaturaSitesDto[] = [];
  public sitesDropdown: IDropdownItem[] = [];
  public prefectures: IPrefecturesDto[] = [];
  public prefecturesDropdown: IDropdownItem[] = [];
  public codes: ICellCodeDto[] = [];
  public codesDropdown: IDropdownItem[] = [];
  public siteList: IFishSiteListDto[] = [];
  public siteListDropdown: IDropdownItem[] = [];
  public ctEquipment: IMainCTSDto[] = [];
  public equipmentDropdown: IDropdownItem[] = [];
  public ctEffort: IMainCTSDto[] = [];
  public effortDropdown: IDropdownItem[] = [];
  public ctTurbididty: IMainCTSDto[] = [];
  public turbidityDropdown: IDropdownItem[] = [];
  public ctStrategyA: IMainCTSDto[] = [];
  public strategyADropdown: IDropdownItem[] = [];
  public ctStrategyB: IMainCTSDto[] = [];
  public strategyBDropdown: IDropdownItem[] = [];
  public ctFlow: IMainCTSDto[] = [];
  public flowDropdown: IDropdownItem[] = [];
  public ctWeather: IMainCTSDto[] = [];
  public weatherDropdown: IDropdownItem[] = [];
  public ctVelocity: IMainCTSDto[] = [];
  public velocityDropdown: IDropdownItem[] = [];
  public ctHelophytes: IMainCTSDto[] = [];
  public helophytesDropdown: IDropdownItem[] = [];
  public ctVegetation: IMainCTSDto[] = [];
  public vegetationsDropdown: IDropdownItem[] = [];
  public ctEfficacy: IMainCTSDto[] = [];
  public efficacyDropdown: IDropdownItem[] = [];
  public selectedSpecies: any[] = [];
  public ctSpecies: IMainCTSDto[] = [];

  public tabBtn: ITabBtnGroup[] = [
    { id: 'map', name: 'Επελεξε τοποθεσια', isChecked: true },
    { id: 'coordinates', name: 'Εισαγωγη συντεταγμενων', isChecked: false },
  ];

  public coordSystemDropdown: IDropdownItem[] = [
    { id: 'EGSA', name: 'EGSA' },
    { id: 'WGS84', name: 'WGS84' },
  ];

  public multiTabGroup = [
    { id: 0, name: 'Γεωγραφια', isChecked: true },
    { id: 1, name: 'Μέθοδος και εξοπλισμός', isChecked: true },
    { id: 2, name: 'Παρουσία 1:1', isChecked: true },
    { id: 3, name: 'Παρουσία 1:2', isChecked: true },
    { id: 4, name: 'Παρουσία 1:3', isChecked: true },
    { id: 5, name: 'Παρουσία ειδων', isChecked: true },
    { id: 6, name: 'Πιεσεις-Απειλες', isChecked: true },
    // { id: 7, name: 'Άλλες Πιέσεις και Απειλές', isChecked: true },
  ];

  public financialDropdown: IDropdownItem[] = [
    { id: 'ΠΕΠ 2007-2013', name: 'ΠΕΠ 2007-2013' },
    { id: 'ΕΠΠΕΡΑΑ 2007-2013', name: 'ΕΠΠΕΡΑΑ 2007-2013' },
    { id: 'ΥΜΕΠΕΡΑΑ', name: 'ΥΜΕΠΕΡΑΑ' },
    { id: 'ΠΕΚΑ 2021-2027', name: 'ΠΕΚΑ 2021-2027' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];

  public referenceSiteEstDropdown: IDropdownItem[] = [
    { id: 'Ναι', name: 'Ναι' },
    { id: 'Εγγύς', name: 'Εγγύς' },
    { id: 'Όχι', name: 'Όχι' },
  ];

  public electricityDropdown: IDropdownItem[] = [
    { id: 'PDC', name: 'PDC' },
    { id: 'DC', name: 'DC' },
    { id: 'AC', name: 'AC' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];

  public habitatAreaDropdown: IDropdownItem[] = [
    { id: 'Ναι', name: 'Ναι' },
    { id: 'Όχι', name: 'Όχι' },
    { id: 'Άγνωστο', name: 'Άγνωστο' },
  ];

  public ergoEpoptiaFasiErgouDropdown: IDropdownItem[] = [
    { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
    { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
    { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
    { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
    { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
  ];

  public predefinedFiles: IFilePayload[] = [];
  public coordinates: ICoordinates | undefined;
  public files: any[] = [];
  public filesToDelete: any[] = [];
  public errors: ValidationErrors[] = [];
  public speciesById: ISampleFishSpeciesDto[] = [];
  public threatsById: ISampleFishSpeciesAndThreatsDto[] = [];
  public otherThreatsById: ISampleFishOtherThreatsDto[] = [];
  public samplingEquipment = '';

  @Input() public protocolId!: number;
  @Input() public sampleId?: number;
  @Input() public readOnlyForm = false;

  constructor(
    private fb: FormBuilder,
    private fishService: FishService,
    private protocolService: ProtocolsService,
    private router: Router,
    public toastService: ToastService,
    private common: CommonService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      protocolId: number;
    };
    this.protocolId = state?.protocolId;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.protocolId = history.state.protocolId;
    this.initFishFormGroup();

    this.species.valueChanges.subscribe((value) => {
      const val = value as ISampleFishSpeciesDto[];
      const res: any[] = [];
      val.forEach((item) => {
        res.push({
          species: item.species,
          other: item.otherSpecies,
        });
      });

      this.selectedSpecies = res;
    });

    if (this.sampleId) {
      this.fishService
        .getFishById(this.sampleId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.getValuesFromServices();
            return;
          })
        )
        .subscribe((res) => {
          if (res != undefined) {
            this.fishById = res as IFishDto;
            // Fix different property names
            this.fishById.lat = this.fishById.pointX;
            this.fishById.long = this.fishById.pointY;

            if (res.files != null) {
              this.predefinedFiles = res.files as any;
            }

            // Fix date
            if (this.fishById.date) {
              const convertedDate = new Date(this.fishById.date);
              const dateString =
                convertedDate.getDate().toString().padStart(2, '0') +
                (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                convertedDate.getFullYear().toString();
              this.fishById.date = dateString;
            }
            // Fix time
            if (this.fishById.time) {
              const timeString = this.fishById.time
                .toString()
                .slice(0, 5)
                .replaceAll(':', '');
              this.fishById.time = timeString;
            }

            // Fix Threats
            if (this.fishById.threats && this.fishById.threats.length > 0) {
              this.fishById.threats.forEach((threat) => {
                if (threat.hdCode) {
                  threat.hDCode = threat.hdCode;
                }
              });
            }
          }
        });
    } else {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.protocolService.getCellCodes(),
      this.protocolService.getNaturaSites(this.fishById?.dataVersion),
      this.protocolService.getPrefectures(this.fishById?.dataVersion),
      this.fishService.getSiteList(this.fishById?.dataVersion),
      this.fishService.getEquipment(this.fishById?.dataVersion),
      this.fishService.getEffort(this.fishById?.dataVersion),
      this.fishService.getTurbidity(this.fishById?.dataVersion),
      this.fishService.getStrategyA(this.fishById?.dataVersion),
      this.fishService.getStrategyB(this.fishById?.dataVersion),
      this.fishService.getFlow(this.fishById?.dataVersion),
      this.fishService.getWeather(this.fishById?.dataVersion),
      this.fishService.getVelocity(this.fishById?.dataVersion),
      this.fishService.getHelophytes(this.fishById?.dataVersion),
      this.fishService.getVegetation(this.fishById?.dataVersion),
      this.fishService.getEfficacy(this.fishById?.dataVersion),
      this.fishService.getSpecies(this.fishById?.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.codes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.codes.forEach((code) => {
              dropdown.push({
                id: code.cellCode,
                name: code.cellCode,
              });
            });
            this.codesDropdown = dropdown;
          }

          if (this.naturaSites.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.naturaSites.forEach((site) => {
              dropdown.push({ id: site.naturaCode, name: site.name });
            });
            this.sitesDropdown = dropdown;
          }

          if (this.prefectures.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.prefectures.forEach((prefecture) => {
              dropdown.push({
                id: prefecture.id,
                name: prefecture.prefecture,
              });
            });
            this.prefecturesDropdown = dropdown;
          }

          if (this.siteList.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.siteList.forEach((site) => {
              dropdown.push({
                id: site.code,
                name: site.code + ' - ' + site.siteName,
              });
            });
            this.siteListDropdown = dropdown;
          }

          if (this.ctEquipment.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctEquipment.forEach((equipment) => {
              dropdown.push({
                id: equipment.code,
                name: equipment.code,
              });
            });
            this.equipmentDropdown = dropdown;
          }

          if (this.ctEffort.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctEffort.forEach((effort) => {
              dropdown.push({
                id: effort.code,
                name: effort.code,
              });
            });
            this.effortDropdown = dropdown;
          }

          if (this.ctTurbididty.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctTurbididty.forEach((turbitidy) => {
              dropdown.push({
                id: turbitidy.code,
                name: turbitidy.code,
              });
            });
            this.turbidityDropdown = dropdown;
          }

          if (this.ctStrategyA.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctStrategyA.forEach((strategy) => {
              dropdown.push({
                id: strategy.code,
                name: strategy.code,
              });
            });
            this.strategyADropdown = dropdown;
          }

          if (this.ctStrategyB.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctStrategyB.forEach((strategy) => {
              dropdown.push({
                id: strategy.code,
                name: strategy.code,
              });
            });
            this.strategyBDropdown = dropdown;
          }

          if (this.ctFlow.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctFlow.forEach((flow) => {
              dropdown.push({
                id: flow.code,
                name: flow.code,
              });
            });
            this.flowDropdown = dropdown;
          }

          if (this.ctWeather.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctWeather.forEach((weather) => {
              dropdown.push({
                id: weather.code,
                name: weather.code,
              });
            });
            this.weatherDropdown = dropdown;
          }

          if (this.ctVelocity.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctVelocity.forEach((vel) => {
              dropdown.push({
                id: vel.code,
                name: vel.code,
              });
            });
            this.velocityDropdown = dropdown;
          }

          if (this.ctHelophytes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctHelophytes.forEach((hel) => {
              dropdown.push({
                id: hel.code,
                name: hel.code,
              });
            });
            this.helophytesDropdown = dropdown;
          }

          if (this.ctVegetation.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctVegetation.forEach((veg) => {
              dropdown.push({
                id: veg.code,
                name: veg.code,
              });
            });
            this.vegetationsDropdown = dropdown;
          }

          if (this.ctEfficacy.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctEfficacy.forEach((efficacy) => {
              dropdown.push({
                id: efficacy.code,
                name: efficacy.code,
              });
            });
            this.efficacyDropdown = dropdown;
          }

          this.fishForm?.controls['samplingEquipment'].valueChanges.subscribe(
            (value) => {
              this.samplingEquipment = value;
              if (value === 'Δίχτυα' || value === 'Άλλο' || value === '') {
                this.fishForm?.controls['electricity'].setValue('');
                this.fishForm?.controls['averageVoltage'].setValue('');
                this.fishForm?.controls['averageFrequency'].setValue('');
                this.fishForm?.controls['electricity'].markAsUntouched();
                this.fishForm?.controls['averageVoltage'].markAsUntouched();
                this.fishForm?.controls['averageFrequency'].markAsUntouched();
              }
            }
          );

          if (this.sampleId && this.fishById) {
            if (this.fishById.species) {
              this.speciesById = this.fishById.species;
            }
            if (this.fishById.threats) {
              this.threatsById = this.fishById.threats;
            }
            // if (this.fishById.otherThreats) {
            //   this.otherThreatsById = this.fishById.otherThreats;
            // }
            if (this.readOnlyForm === true) {
              this.fishForm.disable();
            }
            this.fishForm.patchValue(this.fishById);
          }
          return;
        })
      )
      .subscribe(
        ([
          codes,
          naturaSites,
          prefectures,
          siteList,
          ctEquipment,
          ctEffort,
          ctTurbididty,
          ctStrategyA,
          ctStrategyB,
          ctFlow,
          ctWeather,
          ctVelocity,
          ctHelophytes,
          ctVegetation,
          ctEfficacy,
          ctSpecies,
        ]) => {
          if (codes != undefined) {
            this.codes = codes as ICellCodeDto[];
          }

          if (naturaSites != undefined) {
            this.naturaSites = naturaSites as INaturaSitesDto[];
          }

          if (prefectures != undefined) {
            this.prefectures = prefectures as IPrefecturesDto[];
          }

          if (siteList != undefined) {
            this.siteList = siteList as IFishSiteListDto[];
          }

          if (ctEquipment != undefined) {
            this.ctEquipment = ctEquipment as IMainCTSDto[];
          }

          if (ctEffort != undefined) {
            this.ctEffort = ctEffort as IMainCTSDto[];
          }

          if (ctTurbididty != undefined) {
            this.ctTurbididty = ctTurbididty as IMainCTSDto[];
          }

          if (ctStrategyA != undefined) {
            this.ctStrategyA = ctStrategyA as IMainCTSDto[];
          }

          if (ctStrategyB != undefined) {
            this.ctStrategyB = ctStrategyB as IMainCTSDto[];
          }

          if (ctFlow != undefined) {
            this.ctFlow = ctFlow as IMainCTSDto[];
          }

          if (ctWeather != undefined) {
            this.ctWeather = ctWeather as IMainCTSDto[];
          }

          if (ctVelocity != undefined) {
            this.ctVelocity = ctVelocity as IMainCTSDto[];
          }

          if (ctHelophytes != undefined) {
            this.ctHelophytes = ctHelophytes as IMainCTSDto[];
          }

          if (ctVegetation != undefined) {
            this.ctVegetation = ctVegetation as IMainCTSDto[];
          }

          if (ctEfficacy != undefined) {
            this.ctEfficacy = ctEfficacy as IMainCTSDto[];
          }

          if (ctSpecies != undefined) {
            this.ctSpecies = ctSpecies as IMainCTSDto[];
          }
        }
      );
  }

  private monitorCoords(): void {
    this.common
      .monitorCoords(this.destroy$, this.fishForm, 'x', 'y', 'lat', 'long')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICoordinates) => (this.coordinates = data));
  }

  /**
   * Initialization of the user form.
   */
  private initFishFormGroup() {
    this.fishForm = this.fishService.initFishFormGroup(this.fb);
    this.fishForm?.controls['samId'].clearValidators();
    this.monitorCoords();
    this.common.monitorCoordSystem(this.fishForm, this.destroy$);
  }

  public get species(): FormArray {
    return this.fishForm.controls['species'] as FormArray;
  }

  public get threats(): FormArray {
    return this.fishForm.controls['threats'] as FormArray;
  }

  public get otherThreats(): FormArray {
    return this.fishForm.controls['otherThreats'] as FormArray;
  }

  public get coordSystem(): number {
    return this.fishForm.controls['isWGS84'].value ? 1 : 0;
  }

  public hasErrors(controls: string[]): boolean {
    let flag = false;
    controls.forEach((ctrl) => {
      if (this.fishForm.controls[ctrl].errors != null) {
        flag = true;
      }
    });
    return flag;
  }

  public getErrors(controls: string[]): ValidationErrors {
    let error: ValidationErrors = {};
    controls.forEach((ctrl) => {
      if (this.fishForm.controls[ctrl].errors != null) {
        error = this.fishForm.controls[ctrl].errors ?? {};
      }
    });
    return error;
  }

  public touched(controls: string[]): boolean {
    let flag = false;
    controls.forEach((ctrl) => {
      if (this.fishForm.controls[ctrl].touched) {
        flag = true;
      }
    });
    return flag;
  }

  /**
   * Change view
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public tabBtnClick(btnId: string) {
    if (btnId === 'map') {
      this.tabBtn[0].isChecked = true;
      this.tabBtn[1].isChecked = false;
    } else {
      this.tabBtn[1].isChecked = true;
      this.tabBtn[0].isChecked = false;
    }
  }

  /**
   * Find the new coordinates of the map
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public onMarkerMoved(coordinates: { lat: number; lng: number }): void {
    this.coordinates = coordinates;
    this.submitCoordinates();
  }

  public submitCoordinates(): void {
    if (this.coordinates != null) {
      this.fishForm.controls['lat'].patchValue(this.coordinates.lat, {
        emitEvent: false,
      });
      this.fishForm.controls['long'].patchValue(this.coordinates.lng, {
        emitEvent: false,
      });

      const coordinates = this.common.convertWGStoEGSA(
        this.coordinates.lat,
        this.coordinates.lng
      );
      this.fishForm.controls['x'].patchValue(coordinates.x, {
        emitEvent: false,
      });
      this.fishForm.controls['y'].patchValue(coordinates.y, {
        emitEvent: false,
      });
    }
  }

  private registerNewSample(): void {
    this.fishService
      .addFish(this.fish)
      .pipe(
        catchError((err) => {
          this.toastService.show('GENERIC_FAILURE_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
          throw err;
        })
      )
      .subscribe((sampleIdResponse: ISampleIdResponse) => {
        if (this.files.length > 0) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append('files', file);
          });

          formData.append('SampleId', sampleIdResponse.sampleId.toString());
          this.fishService
            .addFiles(formData)
            .pipe(
              catchError((err) => {
                this.toastService.show('GENERIC_FAILURE_TEXT', {
                  header: 'GENERIC_FAILURE_HEADER',
                });
                throw err;
              })
            )
            .subscribe(() => {
              this.toastService.show('GENERIC_SUCCESS_TEXT', {
                header: 'GENERIC_SUCCESS_HEADER',
              });

              this.activeModal.close({
                sampleId: sampleIdResponse.sampleId,
                samplingCode: this.fishForm.value.samplingCode,
              });
            });
        } else {
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.fishForm.value.samplingCode,
          });
        }
      });
  }

  public updateSample(): void {
    if (this.sampleId) {
      this.fishService
        .putFish(this.sampleId, this.fish)
        .pipe(
          catchError((err) => {
            this.toastService.show('GENERIC_FAILURE_TEXT', {
              header: 'GENERIC_FAILURE_HEADER',
            });
            throw err;
          })
        )
        .subscribe((sampleIdResponse: ISampleIdResponse) => {
          if (this.files != null) {
            // Uploading new files
            const formData = new FormData();
            let addFiles = false;
            this.files.forEach((file: any) => {
              if (file instanceof File) {
                addFiles = true;
                formData.append('files', file);
              }
            });

            if (addFiles) {
              formData.append('SampleId', (this.sampleId as number).toString());
              this.fishService
                .addFiles(formData)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          // Deleting files
          if (this.filesToDelete != null && this.filesToDelete.length > 0) {
            const payload: IFileDeleteRequest = {
              sampleId: this.sampleId as number,
              fileIds: []
            };
            this.filesToDelete.forEach(file => {
              if (file.id != null) {
                payload.fileIds.push(file.id)
              }
            });

            if (payload.fileIds.length > 0) {
              this.fishService
                .removeFiles(payload)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.fishForm.value.samplingCode,
          });
        });
    }
  }

  public confirmClose(): void {
    if (this.fishForm.dirty) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.result.then((data) => {
        if (data) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  public prepareFishRequest(): void {
    this.errors = this.common.validateAllFormControl(this.fishForm);
    if (this.errors.length > 0) {
      return;
    }

    this.fish = this.fishService.prepareFishRequest(
      this.fishForm,
      false,
      this.ctSpecies,
      this.sampleId != null
    );

    if (this.sampleId && !this.readOnlyForm) {
      this.updateSample();
    } else {
      this.registerNewSample();
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
