import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LandPageComponent } from './components/land-page/land-page.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '../shared/services/auth.service';
import { BackComponent } from './components/back/back.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LandPageComponent,
    BackComponent,
  ],
  exports: [HeaderComponent, FooterComponent, BackComponent],
  providers: [AuthService],
  imports: [TranslateModule, SharedModule, RouterModule, CommonModule, NgbModule],
})
export class CoreModule {}
