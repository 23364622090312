import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';
import { IHabitatThreat, IThreatsRequest } from 'src/app/core/dtos/habitat.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { HabitatService } from '../../services/habitat.service';

@Component({
  selector: 'app-habitat-threat',
  templateUrl: './habitat-threat.component.html',
  styleUrls: ['./habitat-threat.component.scss'],
})
export class HabitatThreatComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctThreats: IHabitatThreat[] = [];
  public threatsForm!: FormGroup;
  public threatsDropdown: IDropdownItem[] = [];
  public typeDropdown: IDropdownItem[] = [
    {
      id: 'P or T(και τα δύο)',
      name: 'P or T(και τα δύο)',
    },
    {
      id: 'P(πίεση)',
      name: 'P(πίεση)',
    },
    {
      id: 'T(απειλή)',
      name: 'T(απειλή)',
    },
  ];
  public importanceDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'L',
    },
    {
      id: 'M',
      name: 'M',
    },
    {
      id: 'H',
      name: 'H',
    },
  ];

  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: IThreatsRequest[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private habitatService: HabitatService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.threatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    this.habitatService
      .getThreats(this.dataVersion)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          if (this.threatsById.length > 0) {
            this.threatsById.forEach(() => {
              this.addNewThreats();
            });
            this.threats.patchValue(this.threatsById);
            this.threats.markAsTouched();
            this.threatsById = [];
            if (this.disabledControls === true) {
              this.threats.disable();
            }
          }

          return;
        })
      )
      .subscribe((res) => {
        if (res != undefined) {
          this.ctThreats = res as IHabitatThreat[];
          if (this.ctThreats.length > 0) {
            this.ctThreats.forEach((threat) => {
              this.threatsDropdown.push({
                id: threat.code,
                name: threat.descript_EN,
              });
            });
          }
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    this.threats.push(this.habitatService.initThreatsFormGroup(this.fb));
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
