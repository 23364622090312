import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';
import { ISampleMammalSpeciesDto } from 'src/app/core/dtos/mammals.dto';
import { ISeaSpeciesDto } from 'src/app/core/dtos/sea.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { SeaService } from '../../services/sea.service';

@Component({
  selector: 'app-sea-habitat-species',
  templateUrl: './sea-habitat-species.component.html',
  styleUrls: ['./sea-habitat-species.component.scss'],
})
export class SeaHabitatSpeciesComponent
  implements OnInit, OnDestroy, OnChanges
{
  private destroy$ = new Subject<void>();
  public ctSpecies: ISeaSpeciesDto[] = [];
  public speciesForm!: FormGroup;
  public speciesDropdown: IDropdownItem[] = [];

  public abundanceDropdown: IDropdownItem[] = [
    { id: 'Α', name: 'Άφθονο' },
    { id: 'Κ', name: 'Κοινό' },
    { id: 'Π', name: 'Παρόν' },
    { id: 'Ν', name: 'Νεκρό' },
  ];

  @Input() public species!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public speciesById: ISampleMammalSpeciesDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private seaService: SeaService,
    private translate: TranslateService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.speciesById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesById'] && this.sampleId) {
      this.getValuesFromServices();
    }

    this.species.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.species.controls.forEach(
        (control: AbstractControl, index: number) => {
          this.checkSpecies(
            (control as FormGroup).controls['species'].value,
            index
          );
          // If the `species` is disabled, we skip the current execution.
          if ((control as FormGroup).controls['species'].disabled) {
            return;
          }

          const species = this.ctSpecies.find(
            (item) =>
              item.id === (control as FormGroup).controls['species'].value
          );
          if (species == null) {
            (control as FormGroup).controls['otherSpecies'].setValue('', {
              emitEvent: false,
            });
            (control as FormGroup).controls['otherSpecies'].clearValidators();
            (control as FormGroup).controls['otherSpecies'].disable({
              emitEvent: false,
            });
            return;
          }

          if (species.code === 'Άλλο') {
            (control as FormGroup).controls['otherSpecies'].enable({
              emitEvent: false,
            });
            (control as FormGroup).controls['otherSpecies'].setValidators(
              Validators.required
            );
            (control as FormGroup).controls[
              'otherSpecies'
            ].updateValueAndValidity({ emitEvent: false });
          } else {
            (control as FormGroup).controls['otherSpecies'].setValue('', {
              emitEvent: false,
            });
            (control as FormGroup).controls['otherSpecies'].disable({
              emitEvent: false,
            });
            (control as FormGroup).controls['otherSpecies'].clearValidators();
            (control as FormGroup).controls[
              'otherSpecies'
            ].updateValueAndValidity({ emitEvent: false });
          }
        }
      );
    });
  }

  private getValuesFromServices() {
    this.seaService
      .getSpecies(this.dataVersion)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          if (this.speciesById.length > 0) {
            this.speciesById.forEach(() => {
              this.addNewSpecies();
            });
            this.species.patchValue(this.speciesById);
            this.species.markAsTouched();
            this.speciesById = [];
            if (this.disabledControls === true) {
              this.species.disable();
            }
          }
          return;
        })
      )
      .subscribe((res) => {
        if (res != undefined) {
          this.ctSpecies = res as ISeaSpeciesDto[];
          if (this.ctSpecies.length > 0) {
            this.ctSpecies = this.ctSpecies.sort((a, b) => {
              return a.code > b.code ? 1 : -1;
            });
            this.ctSpecies.forEach((species) => {
              this.speciesDropdown.push({
                id: species.code.toString(),
                name: species.code.toString(),
                actualId: species.id,
              });
            });
          }
        }
      });
  }

  public checkSpecies(species: string, index: number): void {
    let speciesCode = this.ctSpecies.find(
      (value) => value.id === parseInt(species)
    );
    if (speciesCode != null) {
      if (parseInt(species) === speciesCode.id) {
        if (speciesCode.annexII?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'annexII'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'annexII'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexIV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'annexIV'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'annexIV'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'annexV'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'annexV'
          ].setValue(false, { emitEvent: false });
        }
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.species.controls[index] as FormGroup;
  }

  public addNewSpecies(): void {
    this.species.push(this.seaService.initSeaHabitatSpeciesFormGroup(this.fb));
  }

  public removeSpecies(index: number): void {
    this.species.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
