import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICellCodeDto,
  ICorineDto,
  IHabitatSpeciesDto,
  IHabitatTypesDto,
  INaturaSitesDto,
  IPrefecturesDto,
  IProtocolsDto,
  IRegionalUnitsDto,
} from 'src/app/core/dtos/protocols.dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProtocolsService {
  constructor(private http: HttpClient) {}

  public getProtocols(): Observable<IProtocolsDto[]> {
    const url = environment.endpoint + 'Protocols';
    return this.http.get<IProtocolsDto[]>(url);
  }

  public getNaturaSites(dataVersion?: string): Observable<INaturaSitesDto[]> {
    const url = environment.endpoint + 'NaturaSites';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<INaturaSitesDto[]>(url, { params: params });
  }

  public getPrefectures(dataVersion?: string): Observable<IPrefecturesDto[]> {
    const url = environment.endpoint + 'Prefecture';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IPrefecturesDto[]>(url, { params: params });
  }

  public getRegionalUnits(
    dataVersion?: string
  ): Observable<IRegionalUnitsDto[]> {
    const url = environment.endpoint + 'Prefecture/RegionalUnits';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IRegionalUnitsDto[]>(url, { params: params });
  }

  public getHabitatSpecies(
    dataVersion?: string
  ): Observable<IHabitatSpeciesDto[]> {
    const url = environment.endpoint + 'Habitat/Species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IHabitatSpeciesDto[]>(url, { params: params });
  }

  public getHabitatTypes(dataVersion?: string): Observable<IHabitatTypesDto[]> {
    const url = environment.endpoint + 'HabitatTypes';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IHabitatTypesDto[]>(url, { params: params });
  }

  public getCellCodes(): Observable<ICellCodeDto[]> {
    const url = environment.endpoint + 'CellCodes';
    return this.http.get<ICellCodeDto[]>(url);
  }

  public getCorines(dataVersion?: string): Observable<ICorineDto[]> {
    const url = environment.endpoint + 'Corine';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ICorineDto[]>(url, { params: params });
  }
}
