import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { ChlorisService } from '../../services/chloris.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, finalize } from 'rxjs';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { IChlorisPressures } from 'src/app/core/dtos/chloris.dto';

@Component({
  selector: 'app-chloris-threats',
  templateUrl: './chloris-threats.component.html',
  styleUrls: ['./chloris-threats.component.scss'],
})
export class ChlorisThreatsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: IChlorisPressures[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  private destroy$ = new Subject<void>();
  public threatsForm!: FormGroup;
  public CTThreat: any[] = [];
  public CTImpact: any[] = [];
  public CTImportance: any[] = [];
  public importanceDropdown: IDropdownItem[] = [];
  public impactDropdown: IDropdownItem[] = [];
  public pressuresDropdown: IDropdownItem[] = [];
  public intensityDropdown: IDropdownItem[] = [
    {
      id: 1,
      name: '1',
    },
    {
      id: 2,
      name: '2',
    },
    {
      id: 3,
      name: '3',
    },
  ];
  public typeDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('TYPE_P'),
      name: this.translate.instant('TYPE_P'),
    },
    {
      id: this.translate.instant('TYPE_T'),
      name: this.translate.instant('TYPE_T'),
    },
  ];
  public cover_Wh_Ma_Mi_Dropdown: IDropdownItem[] = [
    { id: 'Wh', name: '>90%' },
    { id: 'Ma', name: '50-90%' },
    { id: 'Mi', name: '<50%' },
  ];
  public targetDropdown: IDropdownItem[] = [
    { id: 'Πληθυσμό', name: 'Πληθυσμό' },
    { id: 'Ενδιαίτημα', name: 'Ενδιαίτημα' },
    { id: 'Πληθυσμό & Ενδιαίτημα', name: 'Πληθυσμό & Ενδιαίτημα' },
  ];

  public timeCharacterDropdown: IDropdownItem[] = [
    {
      id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
    },
    { id: 'Στο παρόν', name: 'Στο παρόν' },
    { id: 'Στο παρόν και στο μέλλον', name: 'Στο παρόν και στο μέλλον' },
    { id: 'Μόνο στο μέλλον', name: 'Μόνο στο μέλλον' },
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private chlorisService: ChlorisService
  ) {}

  ngOnInit(): void {
    if (this.threatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.chlorisService.getChlorisPressures(this.dataVersion),
      this.chlorisService.getChlorisImpacts(this.dataVersion),
      this.chlorisService.getChlorisImportances(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.CTThreat.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.CTThreat.forEach((threat) => {
              dropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
            this.pressuresDropdown = dropdown;
          }

          if (this.CTImpact.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.CTImpact.forEach((impact) => {
              dropdown.push({
                id: impact.code,
                name: impact.description,
              });
            });
            this.impactDropdown = dropdown;
          }

          if (this.CTImportance.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.CTImportance.forEach((importance) => {
              dropdown.push({
                id: importance.code,
                name: importance.description,
              });
            });
            this.importanceDropdown = dropdown;
          }

          if (this.threatsById.length > 0) {
            this.threatsById.forEach(() => {
              this.addNewThreats();
            });
            this.threats.patchValue(this.threatsById);
            this.threats.markAsTouched();
            this.threatsById = [];
            if (this.disabledControls === true) {
              this.threats.disable();
            }
          }
          return;
        })
      )
      .subscribe(([threat, impact, importance]) => {
        if (threat != undefined) {
          this.CTThreat = threat;
        }

        if (impact != undefined) {
          this.CTImpact = impact;
        }

        if (importance != undefined) {
          this.CTImportance = importance;
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    this.threats.push(this.chlorisService.initThreatsFormGroup(this.fb));

    this.threats.controls.forEach((control) => {
      (control as FormGroup).controls[
        'pressuresAndThreatsId'
      ].valueChanges.subscribe((value) => {
        if (value === 'PI01') {
          (control as FormGroup).controls['foreignSpecies'].enable({
            emitEvent: false,
          });
        } else {
          (control as FormGroup).controls['foreignSpecies'].setValue('', {
            emitEvent: false,
          });
          (control as FormGroup).controls['foreignSpecies'].disable({
            emitEvent: false,
          });
        }
      });
    });
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  public get hasErrors(): boolean {
    return this.threats.errors != null;
  }

  public get errors(): ValidationErrors | null {
    return this.threats.errors;
  }

  public get touched(): boolean {
    return this.threats.touched;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
