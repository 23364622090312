import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';
import {
  IMammalSpeciesDto,
  ISampleMammalSpeciesDto,
} from 'src/app/core/dtos/mammals.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { MammalsService } from '../../services/mammals.service';

@Component({
  selector: 'app-mammal-species',
  templateUrl: './mammal-species.component.html',
  styleUrls: ['./mammal-species.component.scss'],
})
export class MammalSpeciesComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public speciesForm!: FormGroup;
  public speciesDropdown: IDropdownItem[] = [];
  public EOKDropdown: IDropdownItem[] = [
    { id: 0, name: this.translate.instant('PARII') },
    { id: 1, name: this.translate.instant('PARIV') },
    { id: 2, name: this.translate.instant('PARV') },
  ];
  public genderDropdown: IDropdownItem[] = [
    { id: 'Αρσενικό', name: this.translate.instant('MALE') },
    { id: 'Θηλυκό', name: this.translate.instant('FEMALE') },
  ];

  public notesHiropteraDropdown: IDropdownItem[] = [
    { id: 'M', name: 'Αρσενικό (Φάση αναπαραγωγής Μ, ΜΜ, ΜΜΜ)' },
    { id: 'F', name: 'Θηλυκό (Φάση αναπαραγωγής F, FF, FFF)' },
    { id: 'J', name: 'Νεαρό' },
    { id: 'A', name: 'Ενήλικο ' },
    { id: 'P', name: 'Εγκυμοσύνη / Κύηση ' },
    { id: 'L', name: 'Θηλασμός / Γαλουχία' },
  ];

  public notesOpliforaDropdown: IDropdownItem[] = [
    { id: 'JJ', name: 'Αρ. κατσικιών (x<1 έτους)' },
    { id: 'J', name: 'Αρ. χρονιάρικων (1<x>2)' },
    { id: 'AF', name: 'Αρ. ενήλικων θηλυκών (x>3)' },
    { id: 'AM', name: 'Αρ. ενήλικων αρσενικών: (x>3)' },
  ];

  public notesSarkofagaDropdown: IDropdownItem[] = [
    { id: 'J', name: 'Νεαρό' },
    { id: 'A', name: 'Ενήλικο' },
    { id: 'P', name: 'Εγκυμοσύνη / Κύηση' },
    { id: 'L', name: 'Θηλασμός / Γαλουχία' },
    { id: 'C1', name: 'Άγριος φαινότυπος, με επιβεβαίωση γενετικών αναλύσεων' },
    {
      id: 'C2',
      name: 'Άγριος φαινότυπος βάσει αξιολόγησης χαρακτήρων (Kitchener 2005)',
    },
    {
      id: 'C3',
      name: 'Μη ολικά ορατός φαινότυπος ή δύσκολο να ερμηνευθεί, αλλά περιλαμβάνει κάποιους άγριους χαρακτήρες',
    },
    {
      id: 'C4',
      name: 'Φαινότυπος με κάποιους άγριους χαρακτήρες, αλλά καθαρά μη άγριο (πιθανό υβρίδιο)',
    },
    { id: '0', name: 'Φαινότυπος οικόσιτης γάτας' },
  ];

  public notesMikrothilastikaDropdown: IDropdownItem[] = [
    { id: 'M', name: 'Αρσενικό (Φάση αναπαραγωγής M, MM, MMM)' },
    { id: 'F', name: 'Θηλυκό (Φάση αναπαραγωγής F, FF, FFF)' },
    { id: 'J', name: 'Νεαρό' },
    { id: 'A', name: 'Ενήλικο ' },
    { id: 'P', name: 'Εγκυμοσύνη / Κύηση ' },
    { id: 'L', name: 'Θηλασμός / Γαλουχία' },
  ];

  public notesDropdown: IDropdownItem[] = [];

  public activitiesDropdown: IDropdownItem[] = [
    { id: 'M', name: 'Σε κίνηση' },
    { id: 'R', name: 'Στατικό' },
    { id: 'W', name: 'Στο νερό' },
    { id: 'T', name: 'Σε δέντρο' },
    { id: 'G', name: 'Στο έδαφος' },
    { id: 'D', name: 'Νεκρό' },
    { id: 'DOR', name: 'Νεκρό στο δρόμο' },
    { id: 'C', name: 'Ζευγάρωμα' },
    { id: 'F', name: 'Σε πτήση' },
    { id: 'Gr', name: 'Σε γκρουπ' },
    { id: 'Si', name: 'Μόνα άτομα' },
  ];

  public microHabitatDropdown: IDropdownItem[] = [
    { id: 'A', name: 'Δέντρα' },
    { id: 'B', name: 'Μακία-Θάμνοι' },
    { id: 'C', name: 'Φρύγανα' },
    { id: 'D', name: 'Πόες' },
    { id: 'E', name: 'Παρόχθια (καλάμια, βούρλα, λυγαριές)' },
    { id: 'F', name: 'Έδαφος' },
    { id: 'Fa', name: 'Οδόστρωμα' },
    { id: 'G', name: 'Ξέφωτο' },
    { id: 'H', name: 'Πέτρες' },
    { id: 'Ha', name: 'Ξερολιθιά / Πετροσωρός' },
    { id: 'I', name: 'Βράχια' },
    { id: 'J', name: 'Άμμος' },
    { id: 'K', name: 'Κορμός' },
    { id: 'Ka', name: 'Στοίβα από ξύλα' },
    { id: 'L', name: 'Ρυάκι' },
    { id: 'M', name: 'Ποτάμι' },
    { id: 'N', name: 'Βάλτος (πιο πολλά φυτά από νερό)' },
    { id: 'O', name: 'Έλος (πιο πολύ νερό από φυτά)' },
    { id: 'Pa', name: 'Ποτίστρα/ Πηγάδι' },
    { id: 'Pb', name: 'Στέρνα' },
    { id: 'Q', name: 'Λίμνη' },
    { id: 'Qa', name: 'Τεχνητή λιμνοδεξαμενή' },
    { id: 'R', name: 'Κτίσματα' },
  ];

  public habitatCode1Dropdown: IDropdownItem[] = [
    { id: '1', name: 'Δάσος Κωνοφόρων' },
    { id: '2', name: 'Δάσος Φυλλοβόλων' },
    { id: '3', name: 'Δάσος Μεικτό' },
    { id: '4', name: 'Φρύγανα' },
    { id: '5', name: 'Μακκία' },
    { id: '6', name: 'Καλλιέργειες' },
    { id: '7', name: 'Αμμοθίνες' },
    { id: '8', name: 'Υγρά Λιβάδια' },
    { id: '9', name: 'Λίμνη' },
    { id: '10', name: 'Ποτάμι' },
    { id: '11', name: 'Κανάλι' },
    { id: '12', name: 'Καλαμιώνας' },
    { id: '13', name: 'Λιβάδι' },
    { id: '14', name: 'Σπήλαιο' },
    { id: '15', name: 'Σάρα (Χαλικώνας)' },
    { id: '16', name: 'Παρόχθιο δάσος' },
    { id: '17', name: 'Ορυχείο' },
    { id: '18', name: 'Σπίτι' },
    { id: '19', name: 'Γέφυρα' },
    { id: '20', name: 'Γαλαρία' },
  ];

  public habitatCode2Dropdown: IDropdownItem[] = [
    { id: 'a', name: 'Με Υποόροφο' },
    { id: 'b', name: 'Χωρίς Υποόροφο' },
    { id: 'c', name: 'Ξέφωτο-Άνοιγμα' },
    { id: 'd', name: 'Βραχώδες' },
    { id: 'e', name: 'Ετήσιες Καλλιέργειες' },
    { id: 'f', name: 'Δενδρώδεις Καλλιέργειες' },
    { id: 'g', name: 'Πέτρες' },
    { id: 'h', name: 'Συστάδα σε καλλιέργειές' },
    { id: 'i', name: 'Φυτοφράχτης' },
  ];

  public habitatCode3Dropdown: IDropdownItem[] = [
    { id: 'Α', name: 'Φυσικό' },
    { id: 'Β', name: 'Τεχνητό' },
    { id: 'Γ', name: 'Έχει υποστεί αλλαγές' },
  ];

  public ctBiomarkerDropdown: IDropdownItem[] = [
    { id: 'Περιττώματα', name: 'Περιττώματα' },
    { id: 'Ίχνη', name: 'Ίχνη' },
    { id: 'Λαγούμια', name: 'Λαγούμια' },
    { id: 'Υπολείμματα τροφών', name: 'Υπολείμματα τροφών' },
    { id: 'Σημάδεμα', name: 'Σημάδεμα' },
  ];
  public biomarkerDropdown: IDropdownItem[] = [];

  public ctBiomarkerQualityDropdown: IDropdownItem[] = [
    { id: 'Παλιά', name: 'Παλιά' },
    { id: 'Νέα - πρόσφατα', name: 'Νέα - πρόσφατα' },
    { id: 'Φρέσκα', name: 'Φρέσκα' },
    { id: 'Ξερά', name: 'Ξερά' },
    { id: 'Παλιά', name: 'Παλιά' },
    { id: 'Ενεργά', name: 'Ενεργά' },
    { id: 'Ανενεργά', name: 'Ανενεργά' },
    { id: 'Θετικός σταθμός βίδρας', name: 'Θετικός σταθμός βίδρας' },
    { id: 'Αρνητικός σταθμός βίδρας', name: 'Αρνητικός σταθμός βίδρας' },
  ];
  public biomarkerQualityDropdown: IDropdownItem[] = [];

  public habitatQualityDropdown: IDropdownItem[] = [
    { id: 'G', name: 'Καλή' },
    { id: 'M', name: 'Μέτρια' },
    { id: 'B', name: 'Κακή' },
    { id: 'U', name: 'Άγνωστη' },
  ];

  @Input() public species!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public speciesById: ISampleMammalSpeciesDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;
  @Input() public ctSpecies: IMammalSpeciesDto[] = [];

  constructor(
    private fb: FormBuilder,
    private mammalsService: MammalsService,
    private translate: TranslateService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.speciesById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesById'] && this.sampleId) {
      this.getValuesFromServices();
    }
    if (changes['ctSpecies']) {
      this.monitorSpeciesDropdown();
      if (!this.sampleId) {
        this.species.controls.forEach((control) => {
          (control as FormGroup).controls['notes'].setValue('');
        });
      }
      this.monitorNotesDropdown();
    }
  }

  private getValuesFromServices() {
    this.monitorSpeciesDropdown();
    this.monitorNotesDropdown();

    this.species.valueChanges.subscribe((value) => {
      value.forEach((control: any, index: number) => {
        this.checkSpecies(control['species'], index);

        const ctOtherSpecies = this.ctSpecies.find((item) => {
          return item.code === 'Άλλο';
        });

        if (control['species'] !== ctOtherSpecies?.id) {
          (this.species.controls[index] as FormGroup).controls[
            'otherSpecies'
          ].setValue('', { emitEvent: false });
          (this.species.controls[index] as FormGroup).controls[
            'otherSpecies'
          ].removeValidators(Validators.required);
          (this.species.controls[index] as FormGroup).controls[
            'otherSpecies'
          ].disable({ emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'otherSpecies'
          ].addValidators(Validators.required);
          if (!this.disabledControls) {
            (this.species.controls[index] as FormGroup).controls[
              'otherSpecies'
            ].enable({ emitEvent: false });
          }
        }
      });
    });

    if (this.speciesById.length > 0) {
      this.speciesById.forEach((species, index) => {
        const ctOtherSpecies = this.ctSpecies.find((item) => {
          return item.code === 'Άλλο';
        });
        if (species.species == null && species.otherSpecies != null) {
          species.species = ctOtherSpecies?.id;
        }
        this.addNewSpecies();
        if (species.otherSpecies != null && !this.disabledControls) {
          (this.species.controls[index] as FormGroup).controls[
            'otherSpecies'
          ].enable({ emitEvent: false });
        }
        if (species.biomarker != null) {
          (this.species.controls[index] as FormGroup).controls[
            'biomarker'
          ].enable({ emitEvent: false });
        }
        if (species.biomarkerQuantity != null) {
          (this.species.controls[index] as FormGroup).controls[
            'biomarkerQuantity'
          ].enable({ emitEvent: false });
        }
        if (species.biomarkerQuality != null) {
          (this.species.controls[index] as FormGroup).controls[
            'biomarkerQuality'
          ].enable({ emitEvent: false });
        }
      });
      this.species.patchValue(this.speciesById);
      this.species.markAsTouched();
      this.speciesById = [];
      if (this.disabledControls === true) {
        this.species.disable();
      }
    }
  }

  private monitorSpeciesDropdown() {
    if (this.ctSpecies.length > 0) {
      const dropdown: IDropdownItem[] = [];
      this.ctSpecies.forEach((species) => {
        dropdown.push({
          id: species.code,
          name: species.code,
          actualId: species.id,
        });
      });
      this.speciesDropdown = dropdown;
    } else {
      this.speciesDropdown = [];
    }
  }

  private monitorNotesDropdown() {
    if (this.ctSpecies.length > 0) {
      if (this.ctSpecies[0].taxonomySubGroup === 'Χειρόπτερα') {
        this.notesDropdown = this.notesHiropteraDropdown;
      }
      if (this.ctSpecies[0].taxonomySubGroup === 'Οπληφόρα') {
        this.notesDropdown = this.notesOpliforaDropdown;
      }
      if (this.ctSpecies[0].taxonomySubGroup === 'Σαρκοφάγα') {
        this.notesDropdown = this.notesSarkofagaDropdown;
      }
      if (this.ctSpecies[0].taxonomySubGroup === 'Μικροθηλαστικά') {
        this.notesDropdown = this.notesMikrothilastikaDropdown;
      }
    }
  }

  public checkSpecies(species: string, index: number): void {
    let speciesCode = this.ctSpecies.find(
      (value) => value.id === parseInt(species)
    );
    if (speciesCode != null) {
      if (parseInt(species) === speciesCode.id) {
        if (speciesCode.annexII?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexIV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'pariv'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'pariv'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls['parv'].setValue(
            true,
            { emitEvent: false }
          );
        } else {
          (this.species.controls[index] as FormGroup).controls['parv'].setValue(
            false,
            { emitEvent: false }
          );
        }
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.species.controls[index] as FormGroup;
  }

  public isRandom(): boolean {
    return this.formGroup.controls['randomObservation'].value;
  }

  public addNewSpecies(): void {
    const fg = this.mammalsService.initSpeciesFormGroup(this.fb);
    fg.controls['otherSpecies'].disable({ emitEvent: false });

    if (this.isRandom()) {
      fg.controls['pointX'].removeValidators([Validators.required]);
      fg.controls['pointY'].removeValidators([Validators.required]);
    }

    if (this.formGroup.controls['method'].value !== 'I') {
      fg.controls['biomarker'].disable({ emitEvent: false });
      fg.controls['biomarkerQuantity'].disable({ emitEvent: false });
      fg.controls['biomarkerQuality'].disable({ emitEvent: false });
    }
    if (this.formGroup.controls['method'].value !== 'I') {
      this.biomarkerDropdown = [];
    } else {
      this.biomarkerDropdown = this.ctBiomarkerDropdown;
    }
    if (this.formGroup.controls['method'].value !== 'I') {
      this.biomarkerQualityDropdown = [];
    } else {
      this.biomarkerQualityDropdown = this.ctBiomarkerQualityDropdown;
    }

    fg.controls['biomarker'].valueChanges.subscribe((value) => {
      if (this.formGroup.controls['method'].value !== 'I') {
        this.biomarkerDropdown = [];
      } else {
        this.biomarkerDropdown = this.ctBiomarkerDropdown;
      }
    });

    fg.controls['biomarkerQuality'].valueChanges.subscribe((value) => {
      if (this.formGroup.controls['method'].value !== 'I') {
        this.biomarkerQualityDropdown = [];
      } else {
        this.biomarkerQualityDropdown = this.ctBiomarkerQualityDropdown;
      }
    });
    this.species.push(fg);
  }

  public removeSpecies(index: number): void {
    this.species.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
