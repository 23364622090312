import { Component } from '@angular/core';
import { TableWrapperComponent } from '../table-wrapper/table-wrapper.component';
import { ITableFilterRequest } from '../table/interfaces/table.interfaces';
import { SearchService } from '../services/search.service';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { FormBuilder } from '@angular/forms';
import {
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import {
  IDataTableColumns,
  sampleColumns,
  sampleSeaColumns,
  sampleSeaPinnaColumns,
  sampleSeaSpeciesColumns,
  sampleSeaThreatsColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  ISeaDto,
  ISeaPinnaRequestDto,
  ISeaSpeciesRequestDto,
  ISeaThreatsRequestDto,
} from 'src/app/core/dtos/sea.dto';
import { LazyLoadEvent } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { SeaService } from 'src/app/samplings/services/sea.service';
import { TranslateService } from '@ngx-translate/core';
import {
  seaConfig,
  SeaFormGroup,
} from 'src/app/samplings/config-files/sea-config';
import { utils, writeFile } from 'xlsx';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-sea-search',
  templateUrl: './sea-search.component.html',
  styleUrls: ['./sea-search.component.scss'],
})
export class SeaSearchComponent extends TableWrapperComponent {
  public sampleSeaTotalRecords: number = 0;
  public sampleSea: ISeaDto[] = [];
  public sampleSeaColumns: IDataTableColumns[] = [];
  public sampleSeaFirst = 0;

  public speciesTotalRecords: number = 0;
  public species: ISeaSpeciesRequestDto[] = [];
  public speciesColumns: IDataTableColumns[] = [];
  public speciesFirst = 0;

  public threatsTotalRecords: number = 0;
  public threats: ISeaThreatsRequestDto[] = [];
  public threatsColumns: IDataTableColumns[] = [];
  public threatsFirst = 0;

  public pinnaTotalRecords: number = 0;
  public pinnaNobilis: ISeaPinnaRequestDto[] = [];
  public pinnaColumns: IDataTableColumns[] = [];
  public pinnaNobilisFirst = 0;

  protected override payload: ITableFilterRequest = {
    samples: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    sampleSea: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    species: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    threats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    pinnaNobilis: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
  };
  private tablesInitialized: number = 0;

  public activeTabId: number = 0;
  public multiTabGroup = [
    { id: 0, name: 'SEARCH_SAMPLES', isChecked: true },
    { id: 1, name: 'SEARCH_SAMPLES_SEA', isChecked: true },
    { id: 2, name: 'SEARCH_SPECIES', isChecked: true },
    { id: 3, name: 'SEARCH_PINNA', isChecked: true },
    { id: 4, name: 'SEARCH_THREATS', isChecked: true },
  ];

  constructor(
    protected override searchService: SearchService,
    protected override protocolService: ProtocolsService,
    protected override fb: FormBuilder,
    private seaService: SeaService,
    protected override translate: TranslateService,
    private modalService: NgbModal,
    protected override toastService: ToastService
  ) {
    super(searchService, protocolService, fb, translate, toastService);
  }

  ngOnInit(): void {
    this.fetchSampleSeaColumns();
    this.fetchSampleColumns();
    this.fetchSpeciesColumns();
    this.fetchThreatsColumns();
    this.fetchPinnaNobilisColumns();

    this.watchDataversion();
  }

  protected override fetchSampleColumns(): void {
    this.sampleColumns = sampleColumns;
    const sampleColumnsTemp = [...sampleColumns];
    sampleColumnsTemp.forEach((column) => {
      if (
        column.searchDataType === DataType.List &&
        (column.data == null || column.data.length === 0)
      ) {
        if (column.propertyName === 'prefecture') {
          this.protocolService.getPrefectures().subscribe((data) => {
            column.data = data.map((prefecture) => {
              return { id: prefecture.id, name: prefecture.prefecture };
            });
            this.sampleColumns = sampleColumnsTemp;
          });
        }
      }
      if (column.header === 'RANDOM_OBSERVATION') {
        column.shown = false;
        this.sampleColumns = sampleColumnsTemp;
      }
    });
  }

  protected override fetchSpeciesColumns(): void {
    this.speciesColumns = sampleSeaSpeciesColumns;
    forkJoin([this.seaService.getSpecies(this.version)]).subscribe(
      ([species]) => {
        if (species != null) {
          const speciesItem = this.speciesColumns.find(
            (item) => item.propertyName === 'species'
          );
          if (speciesItem != null) {
            speciesItem.data = species.map((item) => {
              return {
                id: item.id,
                name: `(${item.id}) - ${item.code}`,
              };
            });
          }
        }
      }
    );
  }

  protected override fetchThreatsColumns(): void {
    this.threatsColumns = sampleSeaThreatsColumns;
    forkJoin([this.seaService.getSpecies(this.version)]).subscribe(
      ([species]) => {
        if (species != null) {
          const speciesItem = this.threatsColumns.find(
            (item) => item.propertyName === 'species'
          );
          if (speciesItem != null) {
            speciesItem.data = species.map((item) => {
              return {
                id: item.id,
                name: `(${item.id}) - ${item.code}`,
              };
            });
          }
        }
      }
    );
  }

  private fetchSampleSeaColumns(): void {
    this.sampleSeaColumns = sampleSeaColumns;
    forkJoin([this.seaService.getHabitats(this.version)]).subscribe(
      ([habitats]) => {
        if (habitats != null) {
          const habitat = this.sampleSeaColumns.find(
            (item) => item.propertyName === 'habType'
          );
          if (habitat != null) {
            habitat.data = habitats.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.habType}`,
              };
            });
          }
        }
      }
    );
  }

  private fetchPinnaNobilisColumns(): void {
    this.pinnaColumns = sampleSeaPinnaColumns;
  }

  protected override watchDataversion(): void {
    this.subscription.add(
      this.form.controls['dataVersion'].valueChanges.subscribe((version) => {
        // Updating tables.
        const dataVersion = this.payload.samples?.items.find(
          (item) => item.name === 'DataVersion'
        );
        if (dataVersion != null) {
          if (dataVersion.rules != null && dataVersion.rules.length > 0) {
            dataVersion.rules[0].value = version;
          } else {
            dataVersion.rules = [
              {
                value: version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ];
          }
        } else {
          this.payload.samples?.items.push({
            name: 'DataVersion',
            dataType: DataType.String,
            rules: [
              {
                value: this.version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ],
          });
        }

        this.fetchSampleSeaColumns();
        this.fetchSampleColumns();
        this.fetchSpeciesColumns();
        this.fetchThreatsColumns();
        this.fetchPinnaNobilisColumns();

        this.fetchData();
      })
    );
  }

  protected override fetchData(): void {
    this.searchService.filterSea(this.payload).subscribe((response) => {
      if (response != null) {
        if (response.samples != null) {
          this.sampleTotalRecords = response.samples.totalCount;

          // TODO: Uncomment this when we resolve the time filtering issue.
          // response.samples.rows.forEach(item => {
          //   if (item.time != null) {
          //     const timeString = item.time.toString().slice(0, 5);
          //     item.time = timeString;
          //   }
          // });

          this.samples = response.samples.rows;
        }

        if (response.sampleSea != null) {
          this.sampleSeaTotalRecords = response.sampleSea.totalCount;
          this.sampleSea = response.sampleSea.rows;

          this.sampleCoordinates = this.sampleSea
            .filter((item) => item.lat != null && item.long != null)
            .map((item) => {
              return {
                lat: item.lat,
                lng: item.long,
                text: item.samplingCode,
              };
            });
        }

        if (response.speciesSea != null) {
          this.speciesTotalRecords = response.speciesSea.totalCount;
          this.species = response.speciesSea.rows;

          this.speciesCoordinates = this.species
            .filter(
              (item) =>
                item.lat != null &&
                item.long != null &&
                item.lat !== -1 &&
                item.long !== -1
            )
            .map((item) => {
              return {
                lat: item.lat,
                lng: item.long,
                text:
                  item.species != null ? item.species.toString() : undefined,
              };
            });
        }

        if (response.threatsSea != null) {
          this.threatsTotalRecords = response.threatsSea.totalCount;
          this.threats = response.threatsSea.rows;
        }

        if (response.pinnaNobilisSea != null) {
          this.pinnaTotalRecords = response.pinnaNobilisSea.totalCount;
          this.pinnaNobilis = response.pinnaNobilisSea.rows;
        }
      }
    });
  }

  public loadSampleSeaData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 5) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(event, sampleSeaColumns);
    this.payload.sampleSea = tableFilter;

    if (event.first) {
      this.sampleSeaFirst = event.first;
    }

    this.fetchData();
  }

  public loadSpeciesData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 5) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaSpeciesColumns
    );
    this.payload.species = tableFilter;

    if (event.first) {
      this.speciesFirst = event.first;
    }

    this.fetchData();
  }

  public loadThreatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 5) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaThreatsColumns
    );
    this.payload.threats = tableFilter;

    if (event.first) {
      this.threatsFirst = event.first;
    }

    this.fetchData();
  }

  public loadPinnaData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 5) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(event, sampleSeaPinnaColumns);
    this.payload.pinnaNobilis = tableFilter;

    if (event.first) {
      this.pinnaNobilisFirst = event.first;
    }

    this.fetchData();
  }

  protected override initializePaginator() {
    this.sampleFirst = 0;
    this.sampleSeaFirst = 0;
    this.speciesFirst = 0;
    this.pinnaNobilisFirst = 0;
    this.threatsFirst = 0;
  }

  public openExportModal() {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ExportModalComponent, {
      centered: true,
      modalDialogClass: 'export-modal',
      backdrop: 'static',
      keyboard: true,
    });

    if (modalRef) {
      modalRef.result.then((res) => {
        if (res != null) {
          this.exportFiles(res.xlsxFile, res.zipFile);
        }
      });
    }
  }

  public exportFiles(xlsxFile = false, zipFile = false) {
    let payload = { ...this.payload };
    if (payload.samples) {
      payload.samples.rows = this.sampleTotalRecords;
      payload.samples.first = 0;
    }
    if (payload.sampleSea) {
      payload.sampleSea.rows = this.sampleSeaTotalRecords;
      payload.sampleSea.first = 0;
    }
    if (payload.species) {
      payload.species.rows = this.speciesTotalRecords;
      payload.species.first = 0;
    }
    if (payload.pinnaNobilis) {
      payload.pinnaNobilis.rows = this.pinnaTotalRecords;
      payload.pinnaNobilis.first = 0;
    }
    if (payload.threats) {
      payload.threats.rows = this.threatsTotalRecords;
      payload.threats.first = 0;
    }

    let samples: any[] = [];
    let speciesSea: any[] = [];
    let pinnaNobilisSea: any[] = [];
    let threatsSea: any[] = [];

    this.searchService.filterSea(payload).subscribe((response) => {
      const date = new Date();
      const filename =
        'EP2_Export_Sea_' +
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      if (xlsxFile) {
        if (response && response.sampleSea) {
          samples = this.buildExportArray(
            response,
            'sampleSea',
            true,
            seaConfig,
            ''
          );
        }

        if (response && response.speciesSea) {
          speciesSea = this.buildExportArray(
            response,
            'speciesSea',
            false,
            seaConfig,
            SeaFormGroup.Species
          );
        }

        if (response && response.pinnaNobilisSea) {
          pinnaNobilisSea = this.buildExportArray(
            response,
            'pinnaNobilisSea',
            false,
            seaConfig,
            SeaFormGroup.Pinna
          );
        }

        if (response && response.threatsSea) {
          threatsSea = this.buildExportArray(
            response,
            'threatsSea',
            false,
            seaConfig,
            SeaFormGroup.Threats
          );
        }

        const workbook = utils.book_new();

        const wsSamples = utils.aoa_to_sheet(samples);
        wsSamples['!cols'] = this.fixExportArrayColumnsWidth(samples);
        wsSamples['!rows'] = [{ hpt: 50 }];

        const wsSpeciesSea = utils.aoa_to_sheet(speciesSea);
        wsSpeciesSea['!cols'] = this.fixExportArrayColumnsWidth(speciesSea);
        wsSpeciesSea['!rows'] = [{ hpt: 50 }];

        const wsPinnaNobilisSea = utils.aoa_to_sheet(pinnaNobilisSea);
        wsPinnaNobilisSea['!cols'] =
          this.fixExportArrayColumnsWidth(pinnaNobilisSea);
        wsPinnaNobilisSea['!rows'] = [{ hpt: 50 }];

        const wsThreatsSea = utils.aoa_to_sheet(threatsSea);
        wsThreatsSea['!cols'] = this.fixExportArrayColumnsWidth(threatsSea);
        wsThreatsSea['!rows'] = [{ hpt: 50 }];

        utils.book_append_sheet(
          workbook,
          wsSamples,
          this.translate.instant('SPREADSHEET_SEA_SAMPLES')
        );
        utils.book_append_sheet(
          workbook,
          wsSpeciesSea,
          this.translate.instant('SPREADSHEET_SEA_SPECIES')
        );
        utils.book_append_sheet(
          workbook,
          wsPinnaNobilisSea,
          this.translate.instant('SPREADSHEET_SEA_PINNA')
        );
        utils.book_append_sheet(
          workbook,
          wsThreatsSea,
          this.translate.instant('SPREADSHEET_SEA_THREATS')
        );

        const date = new Date();
        writeFile(workbook, filename + '.xlsx', { compression: true });
      }

      if (zipFile) {
        this.getZip(response, filename);
      }
    });
  }
}
