import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ISampleFishOtherThreatsDto } from 'src/app/core/dtos/fish.dto';
import { FishService } from '../../services/fish.service';

@Component({
  selector: 'app-fish-other-threats',
  templateUrl: './fish-other-threats.component.html',
  styleUrls: ['./fish-other-threats.component.scss'],
})
export class FishOtherThreatsComponent implements OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();

  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: ISampleFishOtherThreatsDto[] = [];
  @Input() public disabledControls = false;
  @Input() public sampleId?: number;

  constructor(private fb: FormBuilder, public fishService: FishService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.threatsById.forEach(() => {
        this.addNewThreats();
      });
      this.threats.patchValue(this.threatsById);
      this.threats.markAsTouched();
      this.threatsById = [];
      if (this.disabledControls === true) {
        this.threats.disable();
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    this.threats.push(this.fishService.initOtherThreatsFormGroup(this.fb));
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
