import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, finalize } from 'rxjs';
import {
  IMammalSpeciesDto,
  IMammalThreatsDto,
  ISampleMammalThreatsDto,
} from 'src/app/core/dtos/mammals.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { MammalsService } from '../../services/mammals.service';

@Component({
  selector: 'app-mammal-threats',
  templateUrl: './mammal-threats.component.html',
  styleUrls: ['./mammal-threats.component.scss'],
})
export class MammalThreatsComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctThreats: IMammalThreatsDto[] = [];
  public threatsForm!: FormGroup;
  public threatsDropdown: IDropdownItem[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public typeDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('TYPE_P'),
      name: this.translate.instant('TYPE_P'),
    },
    {
      id: this.translate.instant('TYPE_T'),
      name: this.translate.instant('TYPE_T'),
    },
    {
      id: this.translate.instant('TYPE_P_T'),
      name: this.translate.instant('TYPE_P_T'),
    },
  ];
  public importanceDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'Low',
    },
    {
      id: 'M',
      name: 'Medium',
    },
    {
      id: 'H',
      name: 'High',
    },
  ];

  public timeCharacterDropdown: IDropdownItem[] = [
    {
      id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
    },
    {
      id: 'Στο παρόν',
      name: 'Στο παρόν',
    },
    {
      id: 'Στο παρόν και στο μέλλον',
      name: 'Στο παρόν και στο μέλλον',
    },
    {
      id: 'Μόνο στο μέλλον',
      name: 'Μόνο στο μέλλον',
    },
  ];

  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: ISampleMammalThreatsDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;
  @Input() public selectedSpecies: any[] = [];
  @Input() public ctSpecies: IMammalSpeciesDto[] = [];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private mammalsService: MammalsService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.threatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }

    if (changes['selectedSpecies']) {
      this.speciesDropdown = [];
      this.selectedSpecies.forEach((species) => {
        if (species) {
          const actualSpecies = this.ctSpecies.find(
            (item) => item.id === parseInt(species.species)
          );
          if (actualSpecies != null) {
            if (actualSpecies.code === 'Άλλο' && !!species.other) {
              this.speciesDropdown.push({
                id: actualSpecies.code,
                name: species.other,
                actualId: actualSpecies.id,
              });
            }
            if (actualSpecies.code !== 'Άλλο') {
              this.speciesDropdown.push({
                id: actualSpecies.code,
                name: actualSpecies.code,
                actualId: actualSpecies.id,
              });
            }
          }
        }
      });

      this.threats.controls.forEach((control, index) => {
        const ctrl = control as FormGroup;
        const species = this.selectedSpecies.find(
          (item) =>
            item.species === ctrl.controls['speciesId'].value &&
            (item.other === ctrl.controls['speciesIdOther'].value ||
              (item.other == null &&
                ctrl.controls['speciesIdOther'].value === ''))
        );
        if (species == null) {
          this.threats.removeAt(index);
        }
      });
    }
  }

  private getValuesFromServices() {
    this.mammalsService
      .getMammalsThreats(this.dataVersion)
      .pipe(
        finalize(() => {
          if (this.ctThreats.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctThreats.forEach((threat) => {
              dropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
            this.threatsDropdown = dropdown;
          }

          if (this.threatsById.length > 0) {
            this.threatsById.forEach((threat) => {
              const ctOtherSpecies = this.ctSpecies.find((item) => {
                return item.code === 'Άλλο';
              });
              if (threat.speciesId == null && threat.speciesIdOther != null) {
                threat.speciesId = ctOtherSpecies?.id;
              }
              this.addNewThreats();
            });
            this.threats.patchValue(this.threatsById);
            this.threats.markAsTouched();
            this.threatsById = [];
            if (this.disabledControls === true) {
              this.threats.disable();
            }
          }

          return;
        })
      )
      .subscribe((ctThreats) => {
        if (ctThreats != undefined) {
          this.ctThreats = ctThreats as IMammalThreatsDto[];
        }
      });
  }

  public updateOtherSpecies(otherSpecies: string, index: number): void {
    const species = this.ctSpecies.find(
      (item) =>
        item.id ===
        (this.threats.controls[index] as FormGroup).controls['speciesId'].value
    );
    if (
      (species != null && species.code === 'Άλλο') ||
      (this.threats.controls[index] as FormGroup).controls['speciesId']
        .value === 'Άλλο'
    ) {
      (this.threats.controls[index] as FormGroup).controls[
        'speciesIdOther'
      ].setValue(otherSpecies, { emitEvent: false });
    } else {
      (this.threats.controls[index] as FormGroup).controls[
        'speciesIdOther'
      ].setValue('', { emitEvent: false });
    }
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    this.threats.push(this.mammalsService.initThreatsFormGroup(this.fb));
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  public get hasErrors(): boolean {
    return this.threats.errors != null;
  }

  public get errors(): ValidationErrors | null {
    return this.threats.errors;
  }

  public get touched(): boolean {
    return this.threats.touched;
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
