import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ISeaHabitatCRequest } from 'src/app/core/dtos/sea-habitat.dto';
import { SeaService } from '../../services/sea.service';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';

@Component({
  selector: 'app-sea-habitat-b',
  templateUrl: './sea-habitat-b.component.html',
  styleUrls: ['./sea-habitat-b.component.scss'],
})
export class SeaHabitatBComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();

  @Input() public b!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public struct1120bById: ISeaHabitatCRequest[] = [];
  @Input() public disabledControls = false;
  @Input() public sampleId?: number;

  public positionDropdown: IDropdownItem[] = [
    {
      id: 'Διατομή 1',
      name: 'Διατομή 1',
    },
    {
      id: 'Διατομή 2',
      name: 'Διατομή 2',
    },
    {
      id: 'Διατομή 3',
      name: 'Διατομή 3',
    },
    {
      id: 'Κέντρο',
      name: 'Κέντρο',
    },
  ];

  constructor(private fb: FormBuilder, private seaService: SeaService) {}

  /**
   *
   */
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['struct1120bById'] && this.sampleId) {
      this.struct1120bById.forEach(() => {
        this.addNew();
      });
      this.b.patchValue(this.struct1120bById);
      this.b.markAsTouched();
      this.struct1120bById = [];
      if (this.disabledControls === true) {
        this.b.disable();
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.b.controls[index] as FormGroup;
  }

  public addNew(): void {
    let fg = this.seaService.initSeaHabitatStructBFormGroup(this.fb);

    fg.controls['meanLeavesBundleLength'].disable();

    fg.controls['position'].valueChanges.subscribe((value) => {
      if (value !== 'Κέντρο') {
        fg.controls['meanLeavesBundleLength'].setValue('', {
          emitEvent: false,
        });
        fg.controls['meanLeavesBundleLength'].disable();
      } else {
        fg.controls['meanLeavesBundleLength'].enable();
      }
    });
    this.b.push(fg);
  }

  public remove(index: number): void {
    this.b.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
