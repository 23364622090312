import { Component } from '@angular/core';

@Component({
  selector: 'app-details-page-wrapper',
  templateUrl: './details-page-wrapper.component.html',
  styleUrls: ['./details-page-wrapper.component.scss'],
})
export class DetailsPageWrapperComponent {
  /**
   * navigation back to landing page
   */
  public goBack(): void {
    window.history.back();
  }
}
