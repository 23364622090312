import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, forkJoin, Subject, takeUntil, finalize } from 'rxjs';
import {
  IBookSpeciesHabitatSpreadDto,
  IBookSpeciesHabitatSpreadEntryDto,
} from 'src/app/core/dtos/bookSpeciesHabitatSpread.dto';
import { IRegionalUnitsDto } from 'src/app/core/dtos/protocols.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { ITabBtnGroup } from 'src/app/shared/button-group/button-group.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { IReferenceIdResponse } from 'src/app/shared/dtos/generic.dto';
import { ToastService } from 'src/app/shared/services/toast.service';
import { BookHabitatsSpeciesService } from '../../services/book-habitats-species.service';
import { HabitatService } from '../../services/habitat.service';
import { ProtocolsService } from '../../services/protocols.service';
import { SeaService } from '../../services/sea.service';
import { AmphibiansService } from '../../services/amphibians.service';
import { InvertebrateService } from '../../services/invertebrate.service';
import { MammalsService } from '../../services/mammals.service';
import { FishService } from '../../services/fish.service';
import { BirdService } from '../../services/bird.service';
import { ChlorisService } from '../../services/chloris.service';
import {
  IBookFileDeleteRequest,
  IFilePayload,
} from 'src/app/shared/files-container/files-container.component';

export interface ISpeciesCategoryMapper {
  Ασπόνδυλα: string;
  Ορνιθοπανίδα: string;
  Χλωρίδα: string;
  Ιχθυοπανίδα: string;
  'Τύποι Οικοτόπων': string;
  'Αμφίβια - Ερπετά': string;
  'Θαλάσσια είδη και ΤΟ': string;
  Θηλαστικά: string;
}

@Component({
  selector: 'app-book-habitats-species',
  templateUrl: './book-habitats-species.component.html',
  styleUrls: ['./book-habitats-species.component.scss'],
})
export class BookHabitatsSpeciesComponent {
  private destroy$ = new Subject<void>();

  public bookHS: IBookSpeciesHabitatSpreadDto = {};
  public bookHSById?: IBookSpeciesHabitatSpreadDto;
  public bookHSForm!: FormGroup;
  public predefinedFiles: IFilePayload[] = [];
  public files: any[] = [];
  public filesToDelete: any[] = [];
  public errors: ValidationErrors[] = [];
  public entriesById: IBookSpeciesHabitatSpreadEntryDto[] = [];

  @Input() public protocolId!: number;
  @Input() public referenceId?: string;
  @Input() public readOnlyForm = false;

  public tabBtn: ITabBtnGroup[] = [
    { id: 'map', name: 'Επελεξε τοποθεσια', isChecked: true },
    { id: 'coordinates', name: 'Εισαγωγη συντεταγμενων', isChecked: false },
  ];

  public referenceTypes: IDropdownItem[] = [];
  public speciesIds: IDropdownItem[] = [];
  public abudance: IDropdownItem[] = [];
  public sourceReliability: IDropdownItem[] = [];
  public methodReliability: IDropdownItem[] = [];
  public habitatTypes: IDropdownItem[] = [];
  public prefectures: IDropdownItem[] = [];
  public naturaCodes: IDropdownItem[] = [];
  public gridCells: IDropdownItem[] = [];
  public regionalUnits: IDropdownItem[] = [];
  public ctGridCells: any[] = [];
  public ctReferenceTypes: any[] = [];
  public ctAbudance: any[] = [];
  public ctSourceReliability: any[] = [];
  public ctMethodReliability: any[] = [];
  public ctHabitatTypes: any[] = [];
  public ctPrefectures: any[] = [];
  public ctNaturaCodes: any[] = [];
  public ctRegionalUnits: any[] = [];
  public ctSpeciesCategories: any[] = [];
  public ctSpecies: any[] = [];
  public timestamp = '';

  public multiTabGroup = [
    { id: 0, name: 'Γενικά', isChecked: true },
    { id: 1, name: 'Εξάπλωση ειδών και ΤΟ', isChecked: true },
  ];

  public ergoEpoptiaFasiErgouDropdown: IDropdownItem[] = [
    { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
    { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
    { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
    { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
    { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
  ];

  public speciesCategoryDropdown: IDropdownItem[] = [];

  public speciesCategoryMapper: ISpeciesCategoryMapper = {
    Ασπόνδυλα: 'ASP',
    Ορνιθοπανίδα: 'ORN',
    Χλωρίδα: 'CHL',
    Ιχθυοπανίδα: 'ICT',
    'Τύποι Οικοτόπων': 'OIK',
    'Αμφίβια - Ερπετά': 'ΑΜΕ',
    'Θαλάσσια είδη και ΤΟ': 'THA',
    Θηλαστικά: 'THI',
  };

  /**
   * Change view
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public tabBtnClick(btnId: string) {
    if (btnId === 'map') {
      this.tabBtn[0].isChecked = true;
      this.tabBtn[1].isChecked = false;
    } else {
      this.tabBtn[1].isChecked = true;
      this.tabBtn[0].isChecked = false;
    }
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public toastService: ToastService,
    public common: CommonService,
    public bookService: BookHabitatsSpeciesService,
    public habitatService: HabitatService,
    public protocolService: ProtocolsService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private seaService: SeaService,
    private amphibianService: AmphibiansService,
    private invertebrateService: InvertebrateService,
    private mammalsService: MammalsService,
    private fishService: FishService,
    private birdService: BirdService,
    private chlorisService: ChlorisService
  ) {}

  ngOnInit(): void {
    this.timestamp = Date.now().toString();
    this.bookHSForm = this.bookService.initBibliographyFormGroup(this.fb);

    if (this.referenceId) {
      this.bookService
        .getBookById(this.referenceId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.getValuesFromServices();
            return;
          })
        )
        .subscribe((res) => {
          if (res != undefined) {
            this.bookHSById = res as IBookSpeciesHabitatSpreadDto;
            if (res.bookFiles != null) {
              this.predefinedFiles = res.bookFiles as any;
            }
            // Fix date
            if (this.bookHSById.downloadedOn) {
              const convertedDate = new Date(this.bookHSById.downloadedOn);
              const dateString =
                convertedDate.getDate().toString().padStart(2, '0') +
                (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                convertedDate.getFullYear().toString();
              this.bookHSById.downloadedOn = dateString;
            }
          }
        });
    } else {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.protocolService.getCellCodes(),
      this.bookService.getReferenceTypes(this.bookHSById?.dataVersion),
      this.bookService.getRelativeAbudances(this.bookHSById?.dataVersion),
      this.bookService.getSourceReliabilities(this.bookHSById?.dataVersion),
      this.bookService.getMethodReliabilities(this.bookHSById?.dataVersion),
      this.habitatService.getTypes(this.bookHSById?.dataVersion),
      this.protocolService.getPrefectures(this.bookHSById?.dataVersion),
      this.protocolService.getNaturaSites(this.bookHSById?.dataVersion),
      this.protocolService.getRegionalUnits(this.bookHSById?.dataVersion),
      this.bookService.getSpeciesGroups(this.bookHSById?.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.ctGridCells.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctGridCells.forEach((code) => {
              dropdown.push({
                id: code.cellCode,
                name: code.cellCode,
              });
            });
            this.gridCells = dropdown;
          }

          if (this.ctReferenceTypes != undefined) {
            const dropdown: IDropdownItem[] = [];
            this.ctReferenceTypes.forEach((x) => {
              dropdown.push({ id: x.code, name: x.code });
            });
            this.referenceTypes = dropdown;
          }

          if (this.bookHSById) {
            this.updateSpecies(this.bookHSById.speciesCategory);
          }

          if (this.ctAbudance != undefined) {
            const dropdown: IDropdownItem[] = [];
            this.ctAbudance.forEach((x) => {
              dropdown.push({
                id: x.code,
                name: x.description,
              });
            });
            this.abudance = dropdown;
          }

          if (this.ctSourceReliability != undefined) {
            const dropdown: IDropdownItem[] = [];
            this.ctSourceReliability.forEach((x) => {
              dropdown.push({
                id: x.code,
                name: x.description,
              });
            });
            this.sourceReliability = dropdown;
          }

          if (this.ctMethodReliability != undefined) {
            const dropdown: IDropdownItem[] = [];
            this.ctMethodReliability.forEach((x) => {
              dropdown.push({
                id: x.code,
                name: x.description,
              });
            });
            this.methodReliability = dropdown;
          }

          if (this.ctHabitatTypes != undefined) {
            const dropdown: IDropdownItem[] = [];
            this.ctHabitatTypes.forEach((x) => {
              dropdown.push({
                id: x.code,
                name: x.name,
                actualId: x.id,
              });
            });
            this.habitatTypes = dropdown;
          }

          if (this.ctPrefectures != undefined) {
            const dropdown: IDropdownItem[] = [];
            this.ctPrefectures.forEach((prefecture) => {
              dropdown.push({
                id: prefecture.id,
                name: prefecture.prefecture,
              });
            });
            this.prefectures = dropdown;
          }

          if (this.ctNaturaCodes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctNaturaCodes.forEach((code) => {
              dropdown.push({
                id: code.naturaCode,
                name: code.name,
              });
            });
            this.naturaCodes = dropdown;
          }

          if (this.ctRegionalUnits.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctRegionalUnits.forEach((code) => {
              dropdown.push({
                id: code.regionalUnit,
                name: code.regionalUnit,
              });
            });
            this.regionalUnits = dropdown;
          }

          if (this.ctSpeciesCategories.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctSpeciesCategories.forEach((code) => {
              if (code.code === 'Θαλάσσια') {
                code.code = 'Θαλάσσια είδη και ΤΟ';
              }
              dropdown.push({
                id: code.code,
                name: code.code,
              });
            });
            dropdown.push({
              id: 'Τύποι Οικοτόπων',
              name: 'Τύποι Οικοτόπων',
            });
            this.speciesCategoryDropdown = dropdown;
          }

          this.bookHSForm.controls[
            'ergoEpoptiaFasiErgou'
          ].valueChanges.subscribe((value) => {
            if (
              !this.bookHSById ||
              (this.bookHSById &&
                this.bookHSById.referenceId !==
                  this.bookHSForm.controls['referenceId'].value)
            ) {
              const referenceId = [
                this.timestamp,
                this.bookHSForm.controls['ergoEpoptiaFasiErgou'].value,
                this.speciesCategoryMapper[
                  this.bookHSForm.controls['speciesCategory']
                    .value as keyof ISpeciesCategoryMapper
                ],
                '0000',
              ];
              this.bookHSForm.controls['referenceId'].setValue(
                referenceId.join('_')
              );
            }
          });

          this.bookHSForm.controls['speciesCategory'].valueChanges.subscribe(
            (value) => {
              if (
                !this.bookHSById ||
                (this.bookHSById &&
                  this.bookHSById.referenceId !==
                    this.bookHSForm.controls['referenceId'].value)
              ) {
                const referenceId = [
                  this.timestamp,
                  this.bookHSForm.controls['ergoEpoptiaFasiErgou'].value,
                  this.speciesCategoryMapper[
                    this.bookHSForm.controls['speciesCategory']
                      .value as keyof ISpeciesCategoryMapper
                  ],
                  '0000',
                ];
                this.bookHSForm.controls['referenceId'].setValue(
                  referenceId.join('_')
                );
              }

              (
                this.bookHSForm.controls['entries'] as FormArray
              ).controls.forEach((control) => {
                const fg = control as FormGroup;
                fg.controls['speciesId'].setValue('');
                fg.controls['speciesIdOther'].setValue('');

                let selectedSpeciesCategory =
                  this.bookHSForm.controls['speciesCategory'].value;

                if (
                  selectedSpeciesCategory === 'Τύποι Οικοτόπων' &&
                  fg.controls['isHabitat'].value === false
                ) {
                  fg.controls['isHabitat'].setValue(true);
                }

                if (
                  selectedSpeciesCategory !== 'Τύποι Οικοτόπων' &&
                  fg.controls['isSpecies'].value === false
                ) {
                  fg.controls['isSpecies'].setValue(true);
                }

                if (
                  selectedSpeciesCategory === 'Χλωρίδα' &&
                  fg.controls['floristicRegion'].disabled
                ) {
                  fg.controls['floristicRegion'].enable();
                }

                if (
                  selectedSpeciesCategory !== 'Χλωρίδα' &&
                  fg.controls['floristicRegion'].enabled
                ) {
                  fg.controls['floristicRegion'].setValue('');
                  fg.controls['floristicRegion'].disable();
                }

                if (
                  selectedSpeciesCategory === 'Χλωρίδα' &&
                  fg.controls['releveCode'].disabled
                ) {
                  fg.controls['releveCode'].enable();
                }

                if (
                  selectedSpeciesCategory !== 'Χλωρίδα' &&
                  fg.controls['releveCode'].enabled
                ) {
                  fg.controls['releveCode'].setValue('');
                  fg.controls['releveCode'].disable();
                }
              });

              this.updateSpecies();
            }
          );

          if (this.referenceId && this.bookHSById) {
            if (this.bookHSById) {
              if (
                this.bookHSById.entries &&
                this.bookHSById.entries.length > 0
              ) {
                this.bookHSById.entries.forEach((entry) => {
                  if (!!!entry.speciesId) {
                    entry.speciesId = 'Άλλο';
                  }
                });
              }
            }
            this.bookHSForm.patchValue(this.bookHSById);
            if (this.bookHSById.entries) {
              this.entriesById = this.bookHSById.entries;
            }
            if (this.readOnlyForm === true) {
              this.bookHSForm.disable();
            }
          } else {
            this.bookHSForm.controls['referenceId'].setValue(
              this.timestamp + '___0000'
            );
          }

          this.bookHSForm.controls['referenceId'].disable();

          return;
        })
      )
      .subscribe(
        ([
          codes,
          referenceTypes,
          abudance,
          sourceReliability,
          methodReliability,
          habitatTypes,
          prefectures,
          naturaCodes,
          regionalUnits,
          speciesCategories,
        ]) => {
          if (codes != undefined) {
            this.ctGridCells = codes;
          }

          if (referenceTypes != undefined) {
            this.ctReferenceTypes = referenceTypes;
          }

          if (abudance != undefined) {
            this.ctAbudance = abudance;
          }

          if (sourceReliability != undefined) {
            this.ctSourceReliability = sourceReliability;
          }

          if (methodReliability != undefined) {
            this.ctMethodReliability = methodReliability;
          }

          if (habitatTypes != undefined) {
            this.ctHabitatTypes = habitatTypes;
          }

          if (prefectures.length > 0 && prefectures != undefined) {
            this.ctPrefectures = prefectures;
          }

          if (naturaCodes != undefined && naturaCodes.length > 0) {
            this.ctNaturaCodes = naturaCodes;
          }

          if (regionalUnits != undefined && regionalUnits.length > 0) {
            this.ctRegionalUnits = regionalUnits;
          }

          if (speciesCategories != undefined && speciesCategories.length > 0) {
            this.ctSpeciesCategories = speciesCategories;
          }
        }
      );
  }

  private updateSpecies(selectedSpeciesCategory: string | null = null): void {
    if (selectedSpeciesCategory == null) {
      selectedSpeciesCategory =
        this.bookHSForm.controls['speciesCategory'].value;
    }

    if (selectedSpeciesCategory === 'Αμφίβια - Ερπετά') {
      this.amphibianService.getAmphibianSpecies().subscribe((data) => {
        const dropdown: IDropdownItem[] = [];
        data.forEach((item) => {
          dropdown.push({ id: item.id, name: item.code });
        });

        this.speciesIds = dropdown;
        this.ctSpecies = data;
      });
    } else if (selectedSpeciesCategory === 'Ασπόνδυλα') {
      this.invertebrateService.getInvertebratesSpecies().subscribe((data) => {
        const dropdown: IDropdownItem[] = [];
        data.forEach((item) => {
          dropdown.push({ id: item.id, name: item.code });
        });

        dropdown.push({ id: 'Άλλο', name: 'Άλλο' });
        this.speciesIds = dropdown;
        this.ctSpecies = data;
      });
    } else if (selectedSpeciesCategory === 'Θαλάσσια είδη και ΤΟ') {
      this.seaService.getSpecies().subscribe((data) => {
        const dropdown: IDropdownItem[] = [];
        data.forEach((item) => {
          dropdown.push({ id: item.id, name: item.code });
        });

        this.speciesIds = dropdown;
        this.ctSpecies = data;
      });
    } else if (selectedSpeciesCategory === 'Θηλαστικά') {
      this.mammalsService.getMammalsSpecies().subscribe((data) => {
        const dropdown: IDropdownItem[] = [];
        data.forEach((item) => {
          dropdown.push({ id: item.id, name: item.code });
        });

        this.speciesIds = dropdown;
        this.ctSpecies = data;
      });
    } else if (selectedSpeciesCategory === 'Ιχθυοπανίδα') {
      this.fishService.getSpecies().subscribe((data) => {
        const dropdown: IDropdownItem[] = [];
        data.forEach((item) => {
          dropdown.push({ id: item.id as number, name: item.code });
        });

        this.speciesIds = dropdown;
        this.ctSpecies = data;
      });
    } else if (selectedSpeciesCategory === 'Ορνιθοπανίδα') {
      this.birdService.getBirdsSpecies().subscribe((data) => {
        const dropdown: IDropdownItem[] = [];
        data.forEach((item) => {
          dropdown.push({ id: item.id, name: item.code });
        });

        dropdown.push({ id: 'Άλλο', name: 'Άλλο' });
        this.speciesIds = dropdown;
        this.ctSpecies = data;
      });
    } else if (selectedSpeciesCategory === 'Χλωρίδα') {
      this.chlorisService.getSpecies().subscribe((data) => {
        const dropdown: IDropdownItem[] = [];
        data.forEach((item) => {
          dropdown.push({ id: item.id as number, name: item.code });
        });

        this.speciesIds = dropdown;
        this.ctSpecies = data;
      });
    } else if (selectedSpeciesCategory === 'Τύποι Οικοτόπων') {
      this.habitatService.getSpecies().subscribe((data) => {
        const dropdown: IDropdownItem[] = [];
        data.forEach((item) => {
          dropdown.push({ id: item.id as number, name: item.code });
        });

        this.speciesIds = dropdown;
        this.ctSpecies = data;
      });
    }
  }

  public get entries(): FormArray {
    return this.bookHSForm.controls['entries'] as FormArray;
  }

  /*
   * Adds form value in corresponding observable.
   */
  public prepareBookRequest(): void {
    (this.bookHSForm.controls['entries'] as FormArray).controls.forEach(
      (control) => {
        const fg = control as FormGroup;
        if (
          fg.controls['latitude'].value !== '' &&
          fg.controls['longitude'].value !== '' &&
          fg.controls['xegsa'].value === '' &&
          fg.controls['yegsa'].value === ''
        ) {
          fg.controls['xegsa'].removeValidators(
            this.common.latLongValidator('xegsa', 'yegsa', true)
          );
          fg.controls['yegsa'].removeValidators(
            this.common.latLongValidator('xegsa', 'yegsa', true)
          );
        }
      }
    );
    this.errors = this.common.validateAllFormControl(this.bookHSForm);
    if (this.errors.length > 0) {
      return;
    }

    this.bookHS = this.bookService.prepareBookRequest(this.bookHSForm);

    if (this.referenceId && !this.readOnlyForm) {
      this.updateBook();
    } else {
      this.registerNewBook();
    }
  }

  public registerNewBook(): void {
    this.bookService
      .AddBookSpeciesAndHabitatsSpread(this.bookHS)
      .pipe(
        catchError((err) => {
          this.toastService.show('GENERIC_FAILURE_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
          throw err;
        })
      )
      .subscribe((referenceIdResponse: IReferenceIdResponse) => {
        if (this.files.length > 0) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append('files', file);
          });

          formData.append(
            'ReferenceId',
            referenceIdResponse.referenceId.toString()
          );
          this.bookService
            .addBookFiles(formData)
            .pipe(
              catchError((err) => {
                this.toastService.show('GENERIC_FAILURE_TEXT', {
                  header: 'GENERIC_FAILURE_HEADER',
                });
                throw err;
              })
            )
            .subscribe(() => {
              this.toastService.show('GENERIC_SUCCESS_TEXT', {
                header: 'GENERIC_SUCCESS_HEADER',
              });

              this.activeModal.close({
                sampleId: referenceIdResponse.referenceId,
                samplingCode: this.bookHSForm.value.samplingCode,
              });
            });
        } else {
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });

          this.activeModal.close({
            sampleId: referenceIdResponse.referenceId,
            samplingCode: this.bookHSForm.value.samplingCode,
          });
        }
      });
  }

  public updateBook(): void {
    if (this.referenceId) {
      this.bookService
        .putBook(this.referenceId, this.bookHS)
        .pipe(
          catchError((err) => {
            this.toastService.show('GENERIC_FAILURE_TEXT', {
              header: 'GENERIC_FAILURE_HEADER',
            });
            throw err;
          })
        )
        .subscribe((referenceIdResponse: IReferenceIdResponse) => {
          if (this.files != null) {
            // Uploading new files
            const formData = new FormData();
            let addFiles = false;
            this.files.forEach((file: any) => {
              if (file instanceof File) {
                addFiles = true;
                formData.append('files', file);
              }
            });

            if (addFiles) {
              formData.append('ReferenceId', this.referenceId as string);
              this.bookService
                .addBookFiles(formData)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          // Deleting files
          if (this.filesToDelete != null && this.filesToDelete.length > 0) {
            const payload: IBookFileDeleteRequest = {
              referenceId: this.referenceId as string,
              fileIds: [],
            };
            this.filesToDelete.forEach((file) => {
              if (file.id != null) {
                payload.fileIds.push(file.id);
              }
            });

            if (payload.fileIds.length > 0) {
              this.bookService
                .removeBookFiles(payload)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: referenceIdResponse.referenceId,
            samplingCode: this.bookHSForm.value.samplingCode,
          });
        });
    }
  }

  public confirmClose(): void {
    if (this.bookHSForm.dirty) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.result.then((data) => {
        if (data) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  public confirmRegistration() {
    if (this.bookHSForm?.value.entries.length === 0) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.componentInstance.bibliographyReportWithoutSpeciesOrHabitats =
        true;
      successModalRef.result.then((data) => {
        if (data) {
          this.prepareBookRequest();
          successModalRef.close();
        }
      });
    } else {
      this.prepareBookRequest();
    }
  }
}
