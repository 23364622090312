<div class="map-container">
  <div class="map-frame">
    <div id="map-{{ mapId }}"></div>
  </div>
  <!-- <div class="map-layers">
    <div class="map-layers-title">
      <div class="map-layers-title-text">
        {{ "MAP_LAYERS" | translate }}
      </div>
      <div>
        <img
          src="assets/images/question.svg"
          ngbTooltip="{{ 'MAP_LAYERS_INFO' | translate }}"
        />
      </div>
    </div>
    <div class="map-layers-items">
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="naturaLayerToggle"
          (ngModelChange)="toggleNaturaLayer()"
        />
        {{ "NATURA_SITES" | translate }}
      </label>
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="gridLayerToggle"
          (ngModelChange)="toggleGridLayer()"
        />
        Grid Cells
      </label>
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="seaLayerToggle"
          (ngModelChange)="toggleSeaLayer()"
        />
        {{ 'SEA_LAYER' | translate }}
      </label>
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="landLayerToggle"
          (ngModelChange)="toggleLandLayer()"
        />
        {{ 'LAND_LAYER' | translate }}
      </label>
    </div>
  </div> -->
</div>
