<ng-container [formGroup]="formGroup">
  <div formArrayName="threats" class="form-container form-2">
    <ng-container
      *ngFor="let threatsForm of threats.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'pressuresAndThreatsId'"
          [label]="'PRESSURE_THREAT'"
          [dropdownList]="threatsDropdown"
          [required]="true"
          [preDefinedId]="
            getGroup(index).controls['pressuresAndThreatsId'].value
          "
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'speciesId'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['speciesId'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'type'"
          [label]="'TYPE'"
          [dropdownList]="typeDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['type'].value"
        ></app-dropdown>
      </div>
      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'importance'"
          [label]="'IMPORTANCE'"
          [dropdownList]="importanceDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['importance'].value"
        ></app-dropdown>
        <app-button
          (buttonClicked)="removeThreats(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== threats.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_THREAT'"
      (buttonClicked)="addNewThreats()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
