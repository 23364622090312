<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-2">
    <ng-container
      *ngFor="let speciesForm of species.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
        ></app-dropdown>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'crossSectionPart'"
          [label]="'CROSS_SECTION_PART'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfPeople'"
          [label]="'NUMBER_OF_PEOPLE'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row form-3">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'relativeAbundanceCategory'"
          [label]="'RELATIVE_ABUNDANCE_CATEGORY'"
          [required]="true"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'relativeAbundanceStatus'"
          [label]="'RELATIVE_ABUNDANCE_STATUS'"
          [required]="true"
        ></app-input>
      </div>
      <div class="checkbox-container">
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.parii" />
          {{ "PARII" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.pariv" />
          {{ "PARIV" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.parv" />
          {{ "PARV" | translate }}
        </label>
      </div>
      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observation'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>
      <div class="form-row">
        <app-button
          (buttonClicked)="removeSpecies(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== species.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_SPECIES'"
      (buttonClicked)="addNewSpecies()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
