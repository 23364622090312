import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { ISeaHabitatsDto } from 'src/app/core/dtos/sea-habitat.dto';
import {
  ISeaDto,
  ISeaHabitatDto,
  ISeaPressureAndThreatDto,
  ISeaSpeciesDto,
} from 'src/app/core/dtos/sea.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ISampleIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { environment } from 'src/environments/environment';
import { SamplesService } from './samples.service';
import { SeaSpeciesCategory } from '../config-files/sea-config';
import { dateValidator } from 'src/app/shared/validators/dateValidator';
import { IFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';

@Injectable({
  providedIn: 'root',
})
export class SeaService {
  constructor(
    private http: HttpClient,
    public common: CommonService,
    private sampleService: SamplesService
  ) {}

  public getSeas(dataVersion?: string): Observable<ISeaDto[]> {
    const url = environment.endpoint + 'Sea/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ISeaDto[]>(url, { params: params });
  }

  public getSeaById(id: number): Observable<ISeaDto> {
    const url = environment.endpoint + 'Sea';
    const params = new HttpParams().set('id', id);
    return this.http.get<ISeaDto>(url, { params: params });
  }

  public putSea(id: number, payload: ISeaDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Sea';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public addSea(payload: ISeaDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Sea';
    return this.http.post<ISampleIdResponse>(url, payload);
  }

  public addBatchSea(
    payload: ISeaDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Sea/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public getAllSeaHabitats(
    dataVersion?: string
  ): Observable<ISeaHabitatsDto[]> {
    const url = environment.endpoint + 'habitats/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ISeaHabitatsDto[]>(url, { params: params });
  }

  public getSeaHabitatById(id: number): Observable<ISeaHabitatsDto> {
    const url = environment.endpoint + 'habitats';
    const params = new HttpParams().set('id', id);
    return this.http.get<ISeaHabitatsDto>(url, { params: params });
  }

  public putSeaHabitat(
    id: number,
    payload: ISeaDto
  ): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'habitats';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public addSeaHabitat(
    payload: ISeaHabitatsDto
  ): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'habitats/add';
    return this.http.post<ISampleIdResponse>(url, payload);
  }

  public addBatchSeaHabitat(
    payload: ISeaHabitatsDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'habitats/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public addFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Sea/files';
    return this.http.post<void>(url, payload);
  }

  public removeFiles(payload: IFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Sea/remove-files';
    return this.http.post<void>(url, payload);
  }

  public getHabitats(dataVersion?: string): Observable<ISeaHabitatDto[]> {
    const url = environment.endpoint + 'Sea/habitats';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ISeaHabitatDto[]>(url, { params: params });
  }

  public getSpecies(dataVersion?: string): Observable<ISeaSpeciesDto[]> {
    const url = environment.endpoint + 'Sea/species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ISeaSpeciesDto[]>(url, { params: params });
  }

  public getThreats(
    dataVersion?: string
  ): Observable<ISeaPressureAndThreatDto[]> {
    const url = environment.endpoint + 'Sea/threats';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ISeaPressureAndThreatDto[]>(url, { params: params });
  }

  public initSeaFormGroup(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);

    sample = fb.group({
      ...sample.controls,
      duration: fb.control(''),
      durationOnEffort: fb.control(''),
      author: fb.control(''),
      method: fb.control('', [Validators.required]),
      habType: fb.control(''),
      transectLenght: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      transectHeading: fb.control(''),
      minDepth: fb.control('', [Validators.min(0)]),
      maxDepth: fb.control('', [Validators.min(0)]),
      timeMeasurementUnit: fb.control(''),
      terrestrialHabitatSeal: fb.control(''),
      habitatThreats: fb.control(''),
      species: fb.array([]),
      pinna: fb.array([]),
      threats: fb.array([]),
      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
      speciesCategory: fb.control(''),
      ergoEpoptiaFasiErgou: fb.control(''),
      samId: fb.control('', [Validators.required]),
      shapefile: fb.control(''),
    });

    // sample.removeControl('naturaCode');
    sample.removeControl('file');
    sample.removeControl('photo');
    sample.removeControl('prefecture');
    sample.controls['time'].removeValidators(Validators.required);
    sample.controls['naturaCode'].removeValidators(Validators.required);
    sample.controls['gridCell'].removeValidators(Validators.required);

    return sample;
  }

  public initSpeciesFormGroup(
    fb: FormBuilder,
    requiredLocation: boolean = true
  ): FormGroup<any> {
    const locationValidators = [this.common.latLongValidator('lat', 'long')];

    if (requiredLocation) {
      locationValidators.push(Validators.required);
    }

    return fb.group({
      id: fb.control(''),
      speciesCategory: fb.control(''),
      species: fb.control('', [Validators.required]),
      otherSpecies: fb.control(''),
      lat: fb.control('', locationValidators),
      long: fb.control('', locationValidators),
      age: fb.control(''),
      depth: fb.control(''),
      depthRange: fb.control('', [Validators.pattern(/^\d+-+\d+$/)]),
      gender: fb.control(''),
      habitat: fb.control(''),
      numberOfPeople: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      numberOfDeadPeople: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      presence: fb.control(''),
      biotope: fb.control(''),
      totalLength: fb.control(''),
      lengthTurtle: fb.control(''),
      methodTurtle: fb.control(''),
      deathStatus: fb.control(''),
      deathReasson: fb.control(''),
      observations: fb.control(''),
      annexII: fb.control(''),
      annexIV: fb.control(''),
      annexV: fb.control(''),
      annexIISufix: fb.control(''),
      annexIVSufix: fb.control(''),
      annexVSufix: fb.control(''),
      booleanAnnexII: fb.control(false),
      booleanAnnexIV: fb.control(false),
      booleanAnnexV: fb.control(false),
      obsId: fb.control(''),
      numberOfNewbornCetaceans: fb.control(''),
      numberOfVeryYoungCetaceans: fb.control(''),
      numberOfYoungCetaceans: fb.control(''),
      numberOfSubAdultCetaceans: fb.control(''),
      numberOfAdultCetaceans: fb.control(''),
      numberOfMaleCetaceans: fb.control(''),
      numberOfFemaleCetaceans: fb.control(''),
      numberOfUndefinedGenderCetaceans: fb.control(''),
      numberOfPeopleSeaInvertebrates: fb.control('', [Validators.min(0)]),
      observationDateCetaceans: fb.control('', [dateValidator()]),
    });
  }

  public initPinnaFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      crossSectionPart: fb.control(''),
      verticalDistance: fb.control('', [Validators.min(0)]),
      depth: fb.control('', [Validators.min(0)]),
      averageCrossSectionDepth: fb.control('', [Validators.min(0)]),
      typicalSize: fb.control('', [Validators.min(0)]),
      biotope: fb.control(''),
      observations: fb.control(''),
    });
  }

  public initThreatsFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      pressuresAndThreatsId: fb.control(''),
      species: fb.control('', [Validators.required]),
      otherSpecies: fb.control(''),
      // type: fb.control('', [Validators.required]),
      // importance: fb.control('', [Validators.required]),
      impact: fb.control(''),
      timeCharacter: fb.control(''),
      observations: fb.control(''),
    });
  }

  public prepareSeaRequest(
    seaForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctHabitats: ISeaHabitatDto[] = [],
    ctSpecies: ISeaSpeciesDto[] = [],
    editMode = false
  ): ISeaDto {
    const sea: ISeaDto = {};
    const species = seaForm.controls['species'] as FormArray;
    const pinna = seaForm.controls['pinna'] as FormArray;
    const threats = seaForm.controls['threats'] as FormArray;

    Object.keys(seaForm.controls).forEach((field) => {
      const control = seaForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        if (control.value === '') {
          control.patchValue(null);
        }
      }
    });

    sea.date = this.common.formatDate(seaForm?.value.date);
    sea.samplingCode = seaForm?.value.samplingCode;
    sea.financialInstrument = seaForm?.value.financialInstrument;
    // sea.prefecture = seaForm?.value.prefecture;
    sea.researcher = seaForm?.value.researcher;
    sea.time = this.common.formatTime(
      !!seaForm?.value.time ? seaForm?.value.time : '0000'
    );
    sea.locality = seaForm?.value.locality;
    sea.observations = seaForm?.value.observations;
    sea.duration = seaForm?.value.duration;
    sea.durationOnEffort = seaForm?.value.durationOnEffort;
    sea.method = seaForm?.value.method;
    sea.pointX = seaForm.value.lat;
    sea.pointY = seaForm.value.long;
    sea.author = seaForm?.value.author;
    sea.habType = seaForm?.value.habType;
    sea.transectLenght = seaForm?.value.transectLenght;
    sea.transectHeading = seaForm?.value.transectHeading;
    sea.minDepth = seaForm?.value.minDepth;
    sea.maxDepth = seaForm?.value.maxDepth;
    sea.protocolId = Protocols.Sea;
    sea.speciesCategory = seaForm.value.speciesCategory;
    sea.habitatThreats = seaForm?.value.habitatThreats;
    sea.ergoEpoptiaFasiErgou = seaForm?.value.ergoEpoptiaFasiErgou;
    sea.timeMeasurementUnit = seaForm?.value.timeMeasurementUnit;
    sea.terrestrialHabitatSeal = seaForm?.value.terrestrialHabitatSeal;
    sea.shapefile = seaForm?.value.shapefile;

    if (fromSpreadsheet === true) {
      sea.samId = seaForm.value.samId;
      // Updating hab type
      const habType = ctHabitats.find(
        (item) => item.habType.toString() === sea.habType?.toString()
      );
      if (habType != null) {
        sea.habType = habType.code;
      }

      sea.species = seaForm.value.species;
      if (sea.species) {
        if (sea.species.length === 0) {
          sea.species = undefined;
        } else {
          sea.species.forEach((sp) => {
            sp.speciesCategory = seaForm.value.speciesCategory;

            if (
              sp.speciesCategory &&
              sp.speciesCategory !== SeaSpeciesCategory.SeaInvertebrates
            ) {
              if (sp.observationDateCetaceans) {
                sp.observationDateCetaceans = this.common.formatDate(
                  sp.observationDateCetaceans.toString()
                );
              }
              sp.numberOfPeopleSeaInvertebrates = undefined;
            } else {
              sp.observationDateCetaceans = undefined;
            }

            if (sp.species === 'Άλλο') {
              sp.species = undefined;
            } else {
              sp.species = ctSpecies
                .find((itemInner) => itemInner.code === sp.species)
                ?.id?.toString();
              sp.otherSpecies = undefined;
            }
          });
        }
      }

      sea.pinna = seaForm.value.pinna;
      if (sea.pinna && sea.pinna.length === 0) {
        sea.pinna = undefined;
      }

      sea.threats = seaForm.value.threats;
      if (sea.threats && sea.threats.length === 0) {
        sea.threats = undefined;
      } else if (sea.threats && sea.threats.length > 0) {
        sea.threats.forEach((threat) => {
          if (threat.species === 'Άλλο') {
            threat.species = undefined;
          } else {
            threat.species = ctSpecies
              .find((itemInner) => itemInner.code === threat.species)
              ?.id?.toString();
            threat.otherSpecies = undefined;
          }
          if (threat.pressuresAndThreatsId) {
            if (threat.pressuresAndThreatsId.startsWith('PX')) {
              threat.impact = undefined;
              threat.timeCharacter = undefined;
            }
          }
        });
      }
    } else {
      let samId: string;
      let speciesSubCategory = '';
      if (seaForm.value.speciesCategory === SeaSpeciesCategory.Cetaceans) {
        speciesSubCategory = 'CE';
      } else if (seaForm.value.speciesCategory === SeaSpeciesCategory.Seals) {
        speciesSubCategory = 'MO';
      } else if (
        seaForm.value.speciesCategory === SeaSpeciesCategory.SeaTurtles
      ) {
        speciesSubCategory = 'CH';
      } else if (
        seaForm.value.speciesCategory === SeaSpeciesCategory.SeaInvertebrates
      ) {
        speciesSubCategory = 'AS';
      }

      if (!editMode) {
        const timestamp = Date.now().toString();
        samId =
          seaForm?.value.samplingCode +
          '_' +
          seaForm?.value.ergoEpoptiaFasiErgou +
          '_THA_0000_' +
          speciesSubCategory +
          '_' +
          timestamp;
        sea.samId = samId;
      }

      if (!species?.touched) {
        sea.species = undefined;
      } else {
        sea.species = species?.value;
        if (sea.species && sea.species.length > 0) {
          sea.species.forEach((sp, index) => {
            sp.speciesCategory = seaForm.value.speciesCategory;
            // sp.obsId = samId + '_' + (index + 1).toString().padStart(3, '0');
            sp.obsId = undefined;

            if (
              sp.speciesCategory &&
              sp.speciesCategory !== SeaSpeciesCategory.SeaInvertebrates
            ) {
              if (sp.observationDateCetaceans) {
                sp.observationDateCetaceans = this.common.formatDate(
                  sp.observationDateCetaceans.toString()
                );
              }
            } else {
              sp.observationDateCetaceans = undefined;
            }

            if (sp.speciesCategory === SeaSpeciesCategory.Cetaceans) {
              const numberOfNewbornCetaceans =
                parseInt(sp.numberOfNewbornCetaceans?.toString() ?? '0') ?? 0;
              const numberOfVeryYoungCetaceans =
                parseInt(sp.numberOfVeryYoungCetaceans?.toString() ?? '0') ?? 0;
              const numberOfYoungCetaceans =
                parseInt(sp.numberOfYoungCetaceans?.toString() ?? '0') ?? 0;
              const numberOfSubAdultCetaceans =
                parseInt(sp.numberOfSubAdultCetaceans?.toString() ?? '0') ?? 0;
              const numberOfMaleCetaceans =
                parseInt(sp.numberOfMaleCetaceans?.toString() ?? '0') ?? 0;
              const numberOfFemaleCetaceans =
                parseInt(sp.numberOfFemaleCetaceans?.toString() ?? '0') ?? 0;
              const numberOfUndefinedGenderCetaceans =
                parseInt(
                  sp.numberOfUndefinedGenderCetaceans?.toString() ?? '0'
                ) ?? 0;

              sp.numberOfPeople =
                numberOfNewbornCetaceans +
                numberOfVeryYoungCetaceans +
                numberOfYoungCetaceans +
                numberOfSubAdultCetaceans +
                numberOfMaleCetaceans +
                numberOfFemaleCetaceans +
                numberOfUndefinedGenderCetaceans;
            }
          });
        }
      }

      if (!pinna?.touched) {
        sea.pinna = undefined;
      } else {
        sea.pinna = pinna?.value;
      }

      if (!threats?.touched) {
        sea.threats = undefined;
      } else {
        sea.threats = threats?.value;
        sea.threats?.forEach((threat) => {
          if (threat.otherSpecies != null && threat.otherSpecies !== '') {
            threat.species = undefined;
          }
        });
      }
    }

    return sea;
  }

  public initSeaHabitatFormGroup(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);

    sample = fb.group({
      ...sample.controls,
      habType: fb.control('', [Validators.required]),
      depthMin: fb.control(''),
      depthMax: fb.control(''),
      orientation: fb.control(''),
      meadowLandscape: fb.control(''),
      author: fb.control(''),
      method: fb.control(''),
      typology: fb.control(''),
      species: fb.array([]),
      threats: fb.array([]),
      a: fb.array([], { validators: [this.uniquePicketValidator()] }),
      b: fb.array([]),
      c: fb.array([]),
      struct1170: fb.group({
        EGSIA01: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'EGSIA01',
              'EGSIB01',
              'EGSIC01',
              'EGSIIA01',
              'EGSIIB01',
              'invertebrate01',
              'other01',
            ],
            'overHundredWidth'
          ),
        ]),
        EGSIB01: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'EGSIA01',
              'EGSIB01',
              'EGSIC01',
              'EGSIIA01',
              'EGSIIB01',
              'invertebrate01',
              'other01',
            ],
            'overHundredWidth'
          ),
        ]),
        EGSIC01: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'EGSIA01',
              'EGSIB01',
              'EGSIC01',
              'EGSIIA01',
              'EGSIIB01',
              'invertebrate01',
              'other01',
            ],
            'overHundredWidth'
          ),
        ]),
        EGSIIA01: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'EGSIA01',
              'EGSIB01',
              'EGSIC01',
              'EGSIIA01',
              'EGSIIB01',
              'invertebrate01',
              'other01',
            ],
            'overHundredWidth'
          ),
        ]),
        EGSIIB01: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'EGSIA01',
              'EGSIB01',
              'EGSIC01',
              'EGSIIA01',
              'EGSIIB01',
              'invertebrate01',
              'other01',
            ],
            'overHundredWidth'
          ),
        ]),
        invertebrate01: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'EGSIA01',
              'EGSIB01',
              'EGSIC01',
              'EGSIIA01',
              'EGSIIB01',
              'invertebrate01',
              'other01',
            ],
            'overHundredWidth'
          ),
        ]),
        other01: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'EGSIA01',
              'EGSIB01',
              'EGSIC01',
              'EGSIIA01',
              'EGSIIB01',
              'invertebrate01',
              'other01',
            ],
            'overHundredWidth'
          ),
        ]),
        woody5: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'woody5',
              'bushy5',
              'filamentousEloquent5',
              'invertebrate5',
              'other5',
            ],
            'overHundredWidth'
          ),
        ]),
        bushy5: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'woody5',
              'bushy5',
              'filamentousEloquent5',
              'invertebrate5',
              'other5',
            ],
            'overHundredWidth'
          ),
        ]),
        filamentousEloquent5: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'woody5',
              'bushy5',
              'filamentousEloquent5',
              'invertebrate5',
              'other5',
            ],
            'overHundredWidth'
          ),
        ]),
        invertebrate5: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'woody5',
              'bushy5',
              'filamentousEloquent5',
              'invertebrate5',
              'other5',
            ],
            'overHundredWidth'
          ),
        ]),
        other5: fb.control('', [
          Validators.min(0),
          Validators.max(100),
          this.fullPercentageValidator(
            [
              'woody5',
              'bushy5',
              'filamentousEloquent5',
              'invertebrate5',
              'other5',
            ],
            'overHundredWidth'
          ),
        ]),
      }),
      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
      ergoEpoptiaFasiErgou: fb.control(''),
      samId: fb.control('', [Validators.required]),
      shapefile: fb.control(''),
    });

    return sample;
  }

  public initSeaHabitatSpeciesFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      species: fb.control('', [Validators.required]),
      relativeAbundance: fb.control('', [Validators.required]),
      observations: fb.control(''),
      otherSpecies: fb.control(''),
      populationDensity: fb.control(''),
      annexII: fb.control(''),
      annexIV: fb.control(''),
      annexV: fb.control(''),
    });
  }

  public initSeaHabitatThreatsFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      pressuresAndThreatsId: fb.control('', [Validators.required]),
      type: fb.control(''),
      importance: fb.control(''),
      habType: fb.control('', [Validators.required]),
      influenceIntensity: fb.control(''),
      timeDetermination: fb.control(''),
      observations: fb.control(''),
    });
  }

  public initSeaHabitatStructAFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      linearDistance: fb.control('', [Validators.required]),
      picket: fb.control('', [
        Validators.required,
        Validators.pattern(/^\d+$/),
        Validators.min(1),
        Validators.max(5),
      ]),
      lowestLimitDepth: fb.control('', [Validators.required]),
      latitude: fb.control('', [Validators.required]),
      longitude: fb.control('', [Validators.required]),
      lowestLimitTypology: fb.control('', [Validators.required]),
      observations: fb.control(''),
    });
  }

  public uniquePicketValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const picketValues = (control as FormArray).controls.map(
        (group: AbstractControl) => group.get('picket')?.value
      );
      const uniqueValues = new Set(picketValues);

      if (picketValues.length !== uniqueValues.size) {
        return { duplicatePicket: true };
      }

      return null;
    };
  }

  public initSeaHabitatStructBFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      position: fb.control('', [Validators.required]),
      meanSproutDensity: fb.control('', [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      meanPercentagePlagiotropin: fb.control('', [
        Validators.required,
        Validators.min(0),
      ]),
      meanLeavesBundleLength: fb.control('', [Validators.min(0)]),
      orientation: fb.control('', [Validators.required]),
      sandCoverage: fb.control('', [Validators.required, Validators.min(0)]),
      rockCoverage: fb.control('', [Validators.required, Validators.min(0)]),
      deadMatteCoverage: fb.control('', [
        Validators.required,
        Validators.min(0),
      ]),
      cNodosaCoverage: fb.control('', [Validators.required, Validators.min(0)]),
      caulerpaCoverage: fb.control('', [
        Validators.required,
        Validators.min(0),
      ]),
      hStipulaceCoverage: fb.control('', [
        Validators.required,
        Validators.min(0),
      ]),
      observations: fb.control(''),
    });
  }

  public initSeaHabitatStructCFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      higherLimitDepth: fb.control('', [Validators.required]),
      latitude: fb.control('', [Validators.required]),
      longitude: fb.control('', [Validators.required]),
      observations: fb.control(''),
    });
  }

  public initSeaHabitatStruct1170FormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      EGSIA01: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'EGSIA01',
            'EGSIB01',
            'EGSIC01',
            'EGSIIA01',
            'EGSIIB01',
            'invertebrate01',
            'other01',
          ],
          'overHundredWidth'
        ),
      ]),
      EGSIB01: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'EGSIA01',
            'EGSIB01',
            'EGSIC01',
            'EGSIIA01',
            'EGSIIB01',
            'invertebrate01',
            'other01',
          ],
          'overHundredWidth'
        ),
      ]),
      EGSIC01: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'EGSIA01',
            'EGSIB01',
            'EGSIC01',
            'EGSIIA01',
            'EGSIIB01',
            'invertebrate01',
            'other01',
          ],
          'overHundredWidth'
        ),
      ]),
      EGSIIA01: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'EGSIA01',
            'EGSIB01',
            'EGSIC01',
            'EGSIIA01',
            'EGSIIB01',
            'invertebrate01',
            'other01',
          ],
          'overHundredWidth'
        ),
      ]),
      EGSIIB01: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'EGSIA01',
            'EGSIB01',
            'EGSIC01',
            'EGSIIA01',
            'EGSIIB01',
            'invertebrate01',
            'other01',
          ],
          'overHundredWidth'
        ),
      ]),
      invertebrate01: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'EGSIA01',
            'EGSIB01',
            'EGSIC01',
            'EGSIIA01',
            'EGSIIB01',
            'invertebrate01',
            'other01',
          ],
          'overHundredWidth'
        ),
      ]),
      other01: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'EGSIA01',
            'EGSIB01',
            'EGSIC01',
            'EGSIIA01',
            'EGSIIB01',
            'invertebrate01',
            'other01',
          ],
          'overHundredWidth'
        ),
      ]),
      woody5: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'woody5',
            'bushy5',
            'filamentousEloquent5',
            'invertebrate5',
            'other5',
          ],
          'overHundredWidth'
        ),
      ]),
      bushy5: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'woody5',
            'bushy5',
            'filamentousEloquent5',
            'invertebrate5',
            'other5',
          ],
          'overHundredWidth'
        ),
      ]),
      filamentousEloquent5: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'woody5',
            'bushy5',
            'filamentousEloquent5',
            'invertebrate5',
            'other5',
          ],
          'overHundredWidth'
        ),
      ]),
      invertebrate5: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'woody5',
            'bushy5',
            'filamentousEloquent5',
            'invertebrate5',
            'other5',
          ],
          'overHundredWidth'
        ),
      ]),
      other5: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'woody5',
            'bushy5',
            'filamentousEloquent5',
            'invertebrate5',
            'other5',
          ],
          'overHundredWidth'
        ),
      ]),
    });
  }

  public fullPercentageValidator(
    fields: string[],
    errorName: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
        return null;
      }

      let res: ValidationErrors = {};
      res[errorName] = true;

      let total = 0;
      fields.forEach((field) => {
        if (control.parent) {
          let fieldValue = control.parent.get(field)?.value;
          if (!!!fieldValue) {
            fieldValue = 0;
          }
          total += parseFloat(fieldValue?.toString() ?? '0');
        }
      });

      if (total > 100) {
        fields.forEach((field) => {
          if (control.parent) {
            control.parent.get(field)?.markAsTouched();
            control.parent.get(field)?.setErrors(res, { emitEvent: false });
          }
        });
        return res;
      }

      fields.forEach((field) => {
        if (control.parent) {
          control.parent.get(field)?.setErrors(null, { emitEvent: false });
        }
      });

      return null;
    };
  }

  public prepareSeaHabitatRequest(
    seaHabitatForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctHabitats: ISeaHabitatDto[] = [],
    ctSpecies: ISeaSpeciesDto[] = [],
    editMode = false
  ): ISeaHabitatsDto {
    const seaHabitat: ISeaHabitatsDto = {};
    const species = seaHabitatForm.controls['species'] as FormArray;
    const threats = seaHabitatForm.controls['threats'] as FormArray;
    const a = seaHabitatForm.controls['a'] as FormArray;
    const b = seaHabitatForm.controls['b'] as FormArray;
    const c = seaHabitatForm.controls['c'] as FormArray;
    const struct1170 = seaHabitatForm.controls['struct1170'] as FormGroup;

    seaHabitat.date = this.common.formatDate(seaHabitatForm?.value.date);
    seaHabitat.samplingCode = seaHabitatForm?.value.samplingCode;
    seaHabitat.financialInstrument = seaHabitatForm?.value.financialInstrument;
    seaHabitat.prefecture = seaHabitatForm?.value.prefecture;
    seaHabitat.researcher = seaHabitatForm?.value.researcher;
    seaHabitat.time = this.common.formatTime(seaHabitatForm?.value.time);
    seaHabitat.locality = seaHabitatForm?.value.locality;
    seaHabitat.observations = seaHabitatForm?.value.observations;
    seaHabitat.file = seaHabitatForm?.value.file;
    seaHabitat.photo = seaHabitatForm?.value.photo;
    seaHabitat.method = seaHabitatForm?.value.method;
    seaHabitat.pointX = seaHabitatForm.value.lat;
    seaHabitat.pointY = seaHabitatForm.value.long;
    seaHabitat.protocolId = Protocols.SeaHabitat;
    seaHabitat.habType = seaHabitatForm.value.habType;
    seaHabitat.depthMin = seaHabitatForm.value.depthMin;
    seaHabitat.depthMax = seaHabitatForm.value.depthMax;
    seaHabitat.orientation = seaHabitatForm.value.orientation;
    seaHabitat.meadowLandscape = seaHabitatForm.value.meadowLandscape;
    seaHabitat.author = seaHabitatForm.value.author;
    seaHabitat.typology = seaHabitatForm.value.typology;
    seaHabitat.ergoEpoptiaFasiErgou =
      seaHabitatForm?.value.ergoEpoptiaFasiErgou;
    seaHabitat.shapefile = seaHabitatForm?.value.shapefile;

    if (fromSpreadsheet === true) {
      seaHabitat.samId = seaHabitatForm?.value.samId;
      // Updating hab type
      const habType = ctHabitats.find(
        (item) => item.habType.toString() === seaHabitat.habType?.toString()
      );
      if (habType != null) {
        seaHabitat.habType = habType.code;
      }

      seaHabitat.species = seaHabitatForm.value.species;
      if (seaHabitat.species && seaHabitat.species.length === 0) {
        seaHabitat.species = undefined;
      } else if (seaHabitat.species && seaHabitat.species.length > 0) {
        seaHabitat.species.forEach((item) => {
          if (item.species === 'Άλλο') {
            item.species = undefined;
          } else {
            item.species = ctSpecies
              .find((itemInner) => itemInner.code === item.species)
              ?.id?.toString();
            item.otherSpecies = undefined;
          }
        });
      }

      seaHabitat.threats = seaHabitatForm.value.threats;
      if (seaHabitat.threats && seaHabitat.threats.length === 0) {
        seaHabitat.threats = undefined;
      } else if (seaHabitat.threats && seaHabitat.threats.length > 0) {
        seaHabitat.threats.forEach((item) => {
          const habType = ctHabitats.find(
            (itemInner) =>
              itemInner.habType.toString() === item.habType?.toString()
          );
          if (habType != null) {
            item.habType = habType.code;
          }
          if (item.pressuresAndThreatsId) {
            if (item.pressuresAndThreatsId.startsWith('PX')) {
              item.timeDetermination = undefined;
              item.influenceIntensity = undefined;
            }
          }
        });
      }

      seaHabitat.a = seaHabitatForm.value.a;
      if (seaHabitat.a && seaHabitat.a.length === 0) {
        seaHabitat.a = undefined;
      }

      seaHabitat.b = seaHabitatForm.value.b;
      if (seaHabitat.b && seaHabitat.b.length === 0) {
        seaHabitat.b = undefined;
      } else if (seaHabitat.b && seaHabitat.b.length === 0) {
        seaHabitat.b.forEach((item) => {
          if (item.position !== 'Κέντρο') {
            item.meanLeavesBundleLength = undefined;
          }
        });
      }

      seaHabitat.c = seaHabitatForm.value.c;
      if (seaHabitat.c && seaHabitat.c.length === 0) {
        seaHabitat.c = undefined;
      }

      seaHabitat.struct1170 = seaHabitatForm.value.struct1170;
      if (!!!seaHabitat.struct1170) {
        seaHabitat.struct1170 = undefined;
      }

      if (habType?.habType.toString() !== '1120') {
        seaHabitat.a = undefined;
        seaHabitat.b = undefined;
        seaHabitat.c = undefined;
      }

      if (habType?.habType.toString() !== '1170') {
        seaHabitat.struct1170 = undefined;
      }

      if (
        habType?.habType.toString() !== '1110' &&
        habType?.habType.toString() !== '1170'
      ) {
        seaHabitat.typology = undefined;
      }
    } else {
      if (!editMode) {
        const timestamp = Date.now().toString();
        let samId =
          seaHabitatForm?.value.samplingCode +
          '_' +
          seaHabitatForm?.value.ergoEpoptiaFasiErgou +
          '_THA_0000_HA_' +
          timestamp;

        seaHabitat.samId = samId;
      }

      if (!species?.touched) {
        seaHabitat.species = undefined;
      } else {
        seaHabitat.species = species?.value;
      }

      if (!threats?.touched) {
        seaHabitat.threats = undefined;
      } else {
        seaHabitat.threats = threats?.value;
      }

      const habType = ctHabitats.find(
        (item) => item.code.toString() === seaHabitat.habType?.toString()
      );

      if (a?.touched && habType?.habType.toString() === '1120') {
        seaHabitat.a = a?.value;
      } else {
        seaHabitat.a = undefined;
      }

      if (b?.touched && habType?.habType.toString() === '1120') {
        seaHabitat.b = b?.value;
      } else {
        seaHabitat.b = undefined;
      }

      if (c?.touched && habType?.habType.toString() === '1120') {
        seaHabitat.c = c?.value;
      } else {
        seaHabitat.c = undefined;
      }

      if (struct1170?.touched && habType?.habType.toString() === '1170') {
        seaHabitat.struct1170 = struct1170?.value;
      } else {
        seaHabitat.struct1170 = undefined;
      }

      if (
        habType?.habType.toString() !== '1110' &&
        habType?.habType.toString() !== '1170'
      ) {
        seaHabitat.typology = undefined;
      }
    }

    return seaHabitat;
  }
}
