import { Injectable, TemplateRef } from '@angular/core';
import { Toast } from '../simple-toast/simple-toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public toasts: Toast[] = [];

  /**
   *
   * @param {string | TemplateRef<Element>}textOrTpl the toast's content.
   * @param {object} options toast options.
   */
  public show(textOrTpl: string | TemplateRef<Element>, options: object = {}) {
    this.toasts.push({ textOrTpl: textOrTpl, options: options });
  }

  /**
   * Removes toast.
   *
   * @param {Toast} toast the toast to be removed.
   */
  public remove(toast: Toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  /**
   * Clears all displayed toasts.
   */
  public clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
