import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, forkJoin, finalize } from 'rxjs';
import {
  IAmphibianActivityDto,
  IAmphibianMicroHabitatDto,
  IAmphibianQualityDto,
  IAmphibianSampleSpeciesDto,
  IAmphibianSpeciesDto,
  IAmphibianThreatsDto,
} from 'src/app/core/dtos/amphibian.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { AmphibiansService } from '../../services/amphibians.service';

@Component({
  selector: 'app-amphibian-species',
  templateUrl: './amphibian-species.component.html',
  styleUrls: ['./amphibian-species.component.scss'],
})
export class AmphibianSpeciesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public speciesDropdown: IDropdownItem[] = [];
  public microHabitat: IAmphibianMicroHabitatDto[] = [];
  public microHabitatDropdown: IDropdownItem[] = [];
  public activity: IAmphibianActivityDto[] = [];
  public activityDropdown: IDropdownItem[] = [];
  public quality: IAmphibianQualityDto[] = [];
  public qualityDropdown: IDropdownItem[] = [];
  public ctThreats: IAmphibianThreatsDto[] = [];
  public threatsDropdown: IDropdownItem[] = [];

  public EOKDropdown: IDropdownItem[] = [
    { id: 0, name: this.translate.instant('PARII') },
    { id: 1, name: this.translate.instant('PARIV') },
    { id: 2, name: this.translate.instant('PARV') },
  ];

  public typeDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('TYPE_P'),
      name: this.translate.instant('TYPE_P'),
    },
    {
      id: this.translate.instant('TYPE_T'),
      name: this.translate.instant('TYPE_T'),
    },
  ];
  public importanceDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'L',
    },
    {
      id: 'M',
      name: 'M',
    },
    {
      id: 'H',
      name: 'H',
    },
  ];

  public timeCharacterDropdown: IDropdownItem[] = [
    {
      id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
    },
    {
      id: 'Στο παρόν',
      name: 'Στο παρόν',
    },
    {
      id: 'Στο παρόν και στο μέλλον',
      name: 'Στο παρόν και στο μέλλον',
    },
    {
      id: 'Μόνο στο μέλλον',
      name: 'Μόνο στο μέλλον',
    },
  ];

  public impactDropdown: IDropdownItem[] = [
    {
      id: 'Έκταση',
      name: 'Έκταση',
    },
    {
      id: 'Έκταση και Δομή/λειτουργίες',
      name: 'Έκταση και Δομή/λειτουργίες',
    },
    {
      id: 'Δομή/λειτουργίες',
      name: 'Δομή/λειτουργίες',
    },
  ];

  @Input() public species!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public habitatADropdown!: IDropdownItem[];
  @Input() public habitatBDropdown!: IDropdownItem[];
  @Input() public codesDropdown!: IDropdownItem[];
  @Input() public speciesById: IAmphibianSampleSpeciesDto[] = [];
  @Input() public disabledControls = false;
  @Input() public readOnlyForm = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;
  @Input() public ctSpecies: IAmphibianSpeciesDto[] = [];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private amphibiansService: AmphibiansService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.speciesById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }

    this.species.valueChanges.subscribe((value) => {
      value.forEach((control: any, index: number) => {
        this.checkSpecies(control['species'], index);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesById'] && this.sampleId) {
      this.getValuesFromServices();
    }

    if (changes['ctSpecies']) {
      this.monitorSpeciesDropdown();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.amphibiansService.getAmphibianMicroHabitat(this.dataVersion),
      this.amphibiansService.getAmphibianActivity(this.dataVersion),
      this.amphibiansService.getAmphibianQuality(this.dataVersion),
      this.amphibiansService.getAmphibianThreats(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          this.monitorSpeciesDropdown();

          if (this.microHabitat.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.microHabitat.forEach((habitat) => {
              dropdown.push({
                id: habitat.code,
                name: habitat.description,
              });
            });
            const filteredDropdown = dropdown.sort((a, b) => {
              return a.id > b.id ? 1 : -1;
            });
            this.microHabitatDropdown = filteredDropdown;
          }

          if (this.activity.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.activity.forEach((act) => {
              dropdown.push({
                id: act.code,
                name: act.description,
              });
            });
            this.activityDropdown = dropdown;
          }

          if (this.quality.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.quality.forEach((hab) => {
              dropdown.push({
                id: hab.code,
                name: hab.description,
              });
            });
            this.qualityDropdown = dropdown;
          }

          if (this.ctThreats.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctThreats.forEach((threat) => {
              dropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
            this.threatsDropdown = dropdown;
          }

          this.species.valueChanges.subscribe((value) => {
            this.species.controls.forEach((control: AbstractControl) => {
              if (!(control as FormGroup).controls['species'].disabled) {
                const species = this.ctSpecies.find(
                  (item) =>
                    item.id === (control as FormGroup).controls['species'].value
                );
                if (species == null) {
                  (control as FormGroup).controls['otherSpecies'].setValue('', {
                    emitEvent: false,
                  });
                  (control as FormGroup).controls[
                    'otherSpecies'
                  ].clearValidators();
                  (control as FormGroup).controls['otherSpecies'].disable({
                    emitEvent: false,
                  });
                  return;
                }

                if (species.code === 'Άλλο') {
                  (control as FormGroup).controls['otherSpecies'].enable({
                    emitEvent: false,
                  });
                  (control as FormGroup).controls['otherSpecies'].setValidators(
                    Validators.required
                  );
                  (control as FormGroup).controls[
                    'otherSpecies'
                  ].updateValueAndValidity({
                    emitEvent: false,
                    onlySelf: true,
                  });
                } else {
                  (control as FormGroup).controls['otherSpecies'].setValue('', {
                    emitEvent: false,
                  });
                  (control as FormGroup).controls[
                    'otherSpecies'
                  ].clearValidators();
                  (control as FormGroup).controls['otherSpecies'].disable({
                    emitEvent: false,
                  });
                  (control as FormGroup).controls[
                    'otherSpecies'
                  ].updateValueAndValidity({
                    emitEvent: false,
                    onlySelf: true,
                  });
                }
              }
            });
          });

          if (this.speciesById.length > 0) {
            this.speciesById.forEach((item, index) => {
              this.addNewSpecies();
              if (item.otherSpecies) {
                (this.species.controls[index] as FormGroup).controls[
                  'otherSpecies'
                ].enable({
                  emitEvent: false,
                });
              }
              const ctOtherSpecies = this.ctSpecies.find((item) => {
                return item.code === 'Άλλο';
              });
              if (item.species == null && item.otherSpecies != null) {
                item.species = ctOtherSpecies?.id;
              }
              if (item.pointX != null && item.pointX === 0) {
                item.pointX = undefined;
              }
              if (item.pointY != null && item.pointY === 0) {
                item.pointY = undefined;
              }
              if (item.speciesThreats && item.speciesThreats.length > 0) {
                item.speciesThreats.forEach(() => {
                  this.addNewSpeciesThreats(index);
                });
              }
              // this.removeSpeciesThreats(index, 0);
            });
            this.species.patchValue(this.speciesById);
            this.species.markAsTouched();
            this.speciesById = [];
            if (this.disabledControls === true) {
              this.species.disable();
            }
          }

          return;
        })
      )
      .subscribe(([microHabitat, activity, quality, ctThreats]) => {
        if (microHabitat != undefined) {
          this.microHabitat = microHabitat as IAmphibianMicroHabitatDto[];
        }

        if (activity != undefined) {
          this.activity = activity as IAmphibianActivityDto[];
        }

        if (quality != undefined) {
          this.quality = quality as IAmphibianQualityDto[];
        }

        if (ctThreats != undefined) {
          this.ctThreats = ctThreats as IAmphibianThreatsDto[];
        }
      });
  }

  public monitorSpeciesDropdown() {
    if (this.ctSpecies.length > 0) {
      const dropdown: IDropdownItem[] = [];
      this.ctSpecies.forEach((species) => {
        const dropdownItem: IDropdownItem = {
          id: species.code,
          name: species.otherName,
          actualId: species.id,
        };
        if (
          species.otherName == null ||
          (species.otherName === '' && species.code !== 'Άλλο') ||
          species.code === species.otherName
        ) {
          dropdownItem.name = species.code + ' ';
        }
        dropdown.push(dropdownItem);
      });
      this.speciesDropdown = dropdown;
    }
  }

  public getGroup(index: number): FormGroup {
    return this.species.controls[index] as FormGroup;
  }

  public getGroupThreats(outerIndex: number, innerIndex: number): FormGroup {
    const formGroup = (this.species.controls[outerIndex] as FormGroup).controls[
      'speciesThreats'
    ] as FormArray;
    return formGroup.controls[innerIndex] as FormGroup;
  }

  public getGroupMicroHabitats(
    outerIndex: number,
    innerIndex: number
  ): FormGroup {
    const formGroup = (this.species.controls[outerIndex] as FormGroup).controls[
      'speciesMicroHabitats'
    ] as FormArray;
    return formGroup.controls[innerIndex] as FormGroup;
  }

  public addNewSpecies(): void {
    const fg = this.amphibiansService.initSpeciesFormGroup(this.fb);

    if (this.isRandom()) {
      fg.controls['pointX'].addValidators([Validators.required]);
      fg.controls['pointY'].addValidators([Validators.required]);
    }

    this.species.push(fg);
  }

  public removeSpecies(index: number): void {
    this.species.removeAt(index);
  }

  public getSpeciesThreats(i: number): FormArray {
    return (this.species.controls[i] as FormGroup).controls[
      'speciesThreats'
    ] as FormArray;
  }

  public addNewSpeciesThreats(outerIndex: number): void {
    (
      (this.species.controls[outerIndex] as FormGroup).controls[
        'speciesThreats'
      ] as FormArray
    ).push(this.amphibiansService.initSpeciesThreatsFormGroup(this.fb));
  }

  public removeSpeciesThreats(outerIndex: number, innerIndex: number): void {
    (
      (this.species.controls[outerIndex] as FormGroup).controls[
        'speciesThreats'
      ] as FormArray
    ).removeAt(innerIndex);
  }

  public get hasErrors(): boolean {
    return this.species.errors != null;
  }

  public get errors(): ValidationErrors | null {
    return this.species.errors;
  }

  public get touched(): boolean {
    return this.species.touched;
  }

  public isRandom(): boolean {
    return this.formGroup.controls['randomObservation'].value;
  }

  public checkSpecies(species: string, index: number): void {
    let speciesCode = this.ctSpecies.find(
      (value) => value.id === parseInt(species)
    );
    if (speciesCode != null) {
      if (parseInt(species) === speciesCode.id) {
        if (speciesCode.annexII?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexIV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'pariv'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'pariv'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls['parv'].setValue(
            true,
            { emitEvent: false }
          );
        } else {
          (this.species.controls[index] as FormGroup).controls['parv'].setValue(
            false,
            { emitEvent: false }
          );
        }
      }
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
