<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-1">
    <ng-container
      *ngFor="let speciesForm of species.controls; let index = index"
    >
      <div class="form-row">
        <app-input
          [mask]="'00/00/0000'"
          [formGroup]="getGroup(index)"
          [formName]="'date'"
          [label]="'DATE'"
        ></app-input>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
        ></app-dropdown>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'otherSpecies'"
          [label]="'OTHER_SPECIES'"
          [ngClass]="{
            disabled:
              getGroup(index).controls['otherSpecies'].disabled &&
              getGroup(index).controls['otherSpecies'].value === ''
          }"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfPeople'"
          [label]="'NUMBER_OF_PEOPLE'"
        ></app-input>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'notes'"
          [label]="'SPECIES_NOTES'"
          [dropdownList]="notesDropdown"
          [preDefinedId]="getGroup(index).controls['notes'].value"
          [ngClass]="{
            disabled: notesDropdown.length === 0
          }"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'activities'"
          [label]="'SPECIES_ACTIVITY'"
          [dropdownList]="activitiesDropdown"
          [preDefinedId]="getGroup(index).controls['activities'].value"
        ></app-dropdown>
      </div>
      <div
        class="form-row"
        [hidden]="formGroup.controls['randomObservation'].value"
      >
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'biomarker'"
          [label]="'BIOMARKER'"
          [dropdownList]="biomarkerDropdown"
          [preDefinedId]="getGroup(index).controls['biomarker'].value"
          [ngClass]="{
            disabled: getGroup(index).controls['biomarker'].disabled
          }"
        ></app-dropdown>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'biomarkerQuantity'"
          [label]="'BIOMARKER_QUANTITY'"
          [ngClass]="{
            disabled: getGroup(index).controls['biomarkerQuantity'].disabled
          }"
        ></app-input>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'biomarkerQuality'"
          [label]="'BIOMARKER_QUALITY'"
          [dropdownList]="biomarkerQualityDropdown"
          [preDefinedId]="getGroup(index).controls['biomarkerQuality'].value"
          [ngClass]="{
            disabled: getGroup(index).controls['biomarkerQuality'].disabled
          }"
        ></app-dropdown>
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCode1'"
          [label]="'HABITAT_CODE_1'"
          [dropdownList]="habitatCode1Dropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['habitatCode1'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCode2'"
          [label]="'HABITAT_CODE_2'"
          [dropdownList]="habitatCode2Dropdown"
          [preDefinedId]="getGroup(index).controls['habitatCode2'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCode3'"
          [label]="'HABITAT_CODE_3'"
          [dropdownList]="habitatCode3Dropdown"
          [preDefinedId]="getGroup(index).controls['habitatCode3'].value"
        ></app-dropdown>
      </div>

      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'microHabitat'"
          [label]="'MICROHABITAT'"
          [dropdownList]="microHabitatDropdown"
          [preDefinedId]="getGroup(index).controls['microHabitat'].value"
        ></app-dropdown>
      </div>

      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'pointX'"
          [label]="'SPECIES_LATITUDE'"
          [required]="isRandom()"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'pointY'"
          [label]="'SPECIES_LONGITUDE'"
          [required]="isRandom()"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'altitude'"
          [label]="'SPECIES_ALTITUDE'"
        ></app-input>
      </div>

      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatQuality'"
          [label]="'HABITAT_QUALITY'"
          [dropdownList]="habitatQualityDropdown"
          [preDefinedId]="getGroup(index).controls['habitatQuality'].value"
        ></app-dropdown>
      </div>

      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observations'"
        [label]="'SPECIES_OBSERVATIONS'"
      ></app-textarea>
      <div class="checkbox-container">
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.parii" />
          {{ "PARII" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.pariv" />
          {{ "PARIV" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.parv" />
          {{ "PARV" | translate }}
        </label>
      </div>
      <app-button
        (buttonClicked)="removeSpecies(index)"
        [style.--btnPadding]="'6px 15px'"
        class="minus-btn"
        [btnText]="'-'"
        [tooltip]="'REMOVE_ENTRY' | translate"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== species.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_SPECIES'"
      (buttonClicked)="addNewSpecies()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
