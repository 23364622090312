import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
export enum HabitatFormGroup {
  Species = 'species',
  Threats = 'threats',
  Impacts = 'impacts',
  Structs = 'structs',
}

export const habitatConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
    ],
    configuration: [
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
      },
      {
        header: 'Ώρα Δειγματοληψίας',
        type: 'time',
        formName: 'time',
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
      },
      // we use releveNR for habitats
      //   {
      //     header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
      //     type: 'string',
      //     formName: 'samplingCode',
      //   },
      {
        header: 'Νομός',
        type: 'prefecture',
        formName: 'prefecture',
      },
      {
        header: 'Τοποθεσία',
        type: 'string',
        formName: 'locality',
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Υψόμετρο (m)',
        type: 'float',
        formName: 'altitude',
      },
      {
        header: 'Υφιστάμενος αριθμός δειγματοληψίας',
        type: 'string',
        formName: 'releveNR',
      },
      {
        header: 'Αριθμός φυτοληψίας Releve (nr)',
        type: 'string',
        formName: 'releveNRor',
      },
      {
        header: 'Αξιολογητής',
        type: 'string',
        formName: 'evaluator',
      },
      {
        header: 'Cluster',
        type: 'string',
        formName: 'cluster',
      },
      {
        header: '92/43/ ΕΟΚ - Παρ I',
        type: 'boolean',
        formName: 'pari',
      },
      {
        header: 'Κωδικός επιφάνειας',
        type: 'string',
        formName: 'plotCode',
      },
      {
        header: 'Μέγεθος δειγματοληπτικής επιφάνειας (m2)',
        type: 'float',
        formName: 'plotSizeM2',
      },
      {
        header: 'Μέγεθος επιφάνειας αξιολόγησης (m2)',
        type: 'float',
        formName: 'areaAssessedM2',
      },
      {
        header: 'Έκθεση (ο)',
        type: 'float',
        formName: 'expositionD',
      },
      {
        header: 'Κλίση (ο)',
        type: 'float',
        formName: 'inclinationD',
      },
      {
        header: 'Ανάγλυφο',
        type: 'string',
        formName: 'relief',
      },
      {
        header: 'Τύπος εδάφους',
        type: 'string',
        formName: 'soilType',
      },
      {
        header: 'Γεωλογικό υπόστρωμα',
        type: 'string',
        formName: 'geologicalSubstrate',
      },
      {
        header: 'Κωδικός τύπου οικοτόπου',
        type: 'string',
        formName: 'kodikosTo',
      },
      {
        header: 'Καλύψεις: Συμπαγής Βράχος',
        type: 'string',
        formName: 'solidRock',
      },
      {
        header: 'Ογκόλιθος (>20cm)',
        type: 'string',
        formName: 'boulders',
      },
      {
        header: 'Πέτρες (2-20cm)',
        type: 'string',
        formName: 'stones',
      },
      {
        header: 'Χαλίκια (2mm-2cm)',
        type: 'string',
        formName: 'gravel',
      },
      {
        header: 'Λεπτόκοκκο έδαφος',
        type: 'string',
        formName: 'fineSoil',
      },
      {
        header: 'Φυλλοστρωμνή',
        type: 'string',
        formName: 'litter',
      },
      {
        header: 'Δενδρώδης κάλυψη (%)',
        type: 'float',
        formName: 'treeCover',
      },
      {
        header: 'Ύψος δένδρων (m)',
        type: 'float',
        formName: 'treeHeightM',
      },
      {
        header: 'Θαμνώδης κάλυψη (%)',
        type: 'float',
        formName: 'shrubCover',
      },
      {
        header: 'Ύψος θάμνων (m)',
        type: 'float',
        formName: 'shrubHeightM',
      },
      {
        header: 'Ποώδης κάλυψη (%)',
        type: 'float',
        formName: 'herbCover',
      },
      {
        header: 'Ύψος ποών (m)',
        type: 'float',
        formName: 'herbHeightM',
      },
      {
        header: 'Γειτονική βλάστηση',
        type: 'string',
        formName: 'adjVegHabTypes',
      },
      {
        header:
          'Υπόστρωμα με σημαντικές διαταραχές (πχ διάβρωση, σημάδια ποδοπάτησης, υψηλός βαθμός συμπίεσης του εδάφους)',
        type: 'boolean',
        formName: 'substrSignDisturb',
      },
      {
        header: 'Εισβολικά / Διαταραχόφιλα είδη (συμπ. αφθονίας)',
        type: 'string',
        formName: 'invasRudSpec',
      },
      {
        header: 'Θαμνώδης2 κάλυψη (%)',
        type: 'float',
        formName: 'shrub2Cover',
      },
      {
        header: 'Ύψος θάμνων2 (m)',
        type: 'float',
        formName: 'shrub2HeightM',
      },
      {
        header: 'Κάλυψη Βρύων (%)',
        type: 'float',
        formName: 'moss',
      },
      {
        header: 'Κάλυψη Λειχηνών (%)',
        type: 'float',
        formName: 'lichen',
      },
      {
        header: 'Μελλοντική τάση',
        type: 'string',
        formName: 'futureTrend',
      },
      {
        header: 'Μελλοντική κατάσταση',
        type: 'string',
        formName: 'futureStatus',
      },
      {
        header: 'Δυνατότητα αποκατάστασης',
        type: 'string',
        formName: 'restorationPossibility',
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: HabitatFormGroup.Species,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Αφορά A/A Δείγματος',
    configuration: [
      {
        header: 'Κωδικός είδους',
        type: 'string',
        formName: 'species',
      },
      {
        header: '92/43/ ΕΟΚ - Παρ II είδους',
        type: 'boolean',
        formName: 'parii',
      },
      {
        header: '92/43/ ΕΟΚ - Παρ IV είδους',
        type: 'boolean',
        formName: 'pariv',
      },
      {
        header: '92/43/ ΕΟΚ - Παρ V είδους',
        type: 'boolean',
        formName: 'parv',
      },
      {
        header: 'CoverPlot είδους',
        type: 'string',
        formName: 'coverPlot',
      },
      {
        header: 'Κάλυψη στην επιφάνεια αξιολόγησης είδους',
        type: 'string',
        formName: 'coverArea',
      },
      {
        header: 'Ζωτικότητα είδους',
        type: 'int',
        formName: 'vitality',
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: HabitatFormGroup.Threats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Αφορά A/A Δείγματος',
    configuration: [
      {
        header: 'Κωδικός πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsId',
      },
      {
        header: 'Τύπος πίεσης ή απειλής',
        type: 'string',
        formName: 'type',
      },
      {
        header: 'Σημασία απειλής',
        type: 'string',
        formName: 'importance',
      },
    ],
  },
  // This is the 4th sheet
  {
    formGroupName: HabitatFormGroup.Impacts,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Αφορά A/A Δείγματος',
    configuration: [
      {
        header: 'Θετική επίδραση',
        type: 'string',
        formName: 'positiveImpact',
      },
      {
        header: 'Σημασία επίδρασης',
        type: 'string',
        formName: 'importance',
      },
    ],
  },

  // This is the 5th sheet
  {
    formGroupName: HabitatFormGroup.Structs,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Αφορά A/A Δείγματος',
    configuration: [
      {
        header: 'Δομή ή λειτουργία',
        type: 'string',
        formName: 'code',
      },
    ],
  },
];
