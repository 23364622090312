import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(public authService: AuthService, public router: Router) { }

  /**
   * Logs user out of the system.
   */
  public logOutUser() {
    this.authService.auth.logout({ returnTo: window.location.origin });
  }

  /**
   * Navigates the user to the protocols page
   */
  public NavigateToHome() {
    this.router.navigate(['/']);
  }
}
