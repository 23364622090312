import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  URL_FISH,
  URL_AMPHIBIAN,
  URL_INVERTEBRATES,
  URL_MAMMAL,
  URL_SEA_SPECIES,
  URL_SEA_HABITATS,
  URL_HABITATS,
  URL_CHLORIS,
  URL_BIRD,
  URL_BIBLIOGRAPHY,
  URL_SEARCH,
} from '../URLS';
import { DetailsPageWrapperComponent } from './components/details-page-wrapper/details-page-wrapper.component';
import { NewRecordComponent } from './components/new-record/new-record.component';
import { ProtocolsComponent } from './components/protocols/protocols.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { TableWrapperComponent } from '../shared/table-wrapper/table-wrapper.component';
import { MammalSearchComponent } from '../shared/mammal-search/mammal-search.component';

const routes: Routes = [
  {
    path: '',
    component: DetailsPageWrapperComponent,
    children: [
      {
        path: '',
        redirectTo: 'protocols',
        pathMatch: 'full',
      },
      {
        path: '',
        component: ProtocolsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_MAMMAL,
        //component: MamalsComponent,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_AMPHIBIAN,
        // component: AmphibiansComponent,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_FISH,
        // component: FishComponent,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_SEA_SPECIES,
        // component: SeaComponent,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_SEA_HABITATS,
        // component: SeaHabitatComponent,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_HABITATS,
        // component: HabitatComponent,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_INVERTEBRATES,
        // component: InvertebratesComponent,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_BIRD,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_CHLORIS,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: URL_BIBLIOGRAPHY,
        component: NewRecordComponent,
        canActivate: [AuthGuard],
      },
      //   {
      //     // Wild Card Route for 404 request
      //     path: '**',
      //     pathMatch: 'full',
      //     component: NotFoundPageComponent,
      //   },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SamplingsRoutingModule {}
