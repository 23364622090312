import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, forkJoin, Subject, takeUntil, finalize } from 'rxjs';
import {
  ICellCodeDto,
  IHabitatSpeciesDto,
  INaturaSitesDto,
  IPrefecturesDto,
} from 'src/app/core/dtos/protocols.dto';
import {
  ISeaDto,
  ISeaHabitatDto,
  ISeaPinnaRequestDto,
  ISeaSpeciesDto,
  ISeaSpeciesRequestDto,
  ISeaThreatsRequestDto,
} from 'src/app/core/dtos/sea.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { ITabBtnGroup } from 'src/app/shared/button-group/button-group.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { ISampleIdResponse } from 'src/app/shared/dtos/generic.dto';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SeaSpeciesCategory } from '../../config-files/sea-config';
import { ProtocolsService } from '../../services/protocols.service';
import { SeaService } from '../../services/sea.service';
import { IFileDeleteRequest, IFilePayload } from 'src/app/shared/files-container/files-container.component';

@Component({
  selector: 'app-sea',
  templateUrl: './sea.component.html',
  styleUrls: ['./sea.component.scss'],
})
export class SeaComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public sea: ISeaDto = {};
  public seaById?: ISeaDto;
  public seaForm!: FormGroup;
  public naturaSites: INaturaSitesDto[] = [];
  public sitesDropdown: IDropdownItem[] = [];
  public prefectures: IPrefecturesDto[] = [];
  public prefecturesDropdown: IDropdownItem[] = [];
  public codes: ICellCodeDto[] = [];
  public codesDropdown: IDropdownItem[] = [];
  public habitatSpecies: IHabitatSpeciesDto[] = [];
  public habitatSpeciesDropdown: IDropdownItem[] = [];
  public habitatTypes: ISeaHabitatDto[] = [];
  public habitatTypesDropdown: IDropdownItem[] = [];
  public speciesCategory = '';
  public seaSpeciesCategory = SeaSpeciesCategory;
  public selectedSpecies: any[] = [];
  private pinnaNobilisId!: number;
  public ctSpecies: ISeaSpeciesDto[] = [];

  public predefinedFiles: IFilePayload[] = [];
  public coordinates: ICoordinates | undefined;
  public files: any[] = [];
  public filesToDelete: any[] = [];
  public errors: ValidationErrors[] = [];
  public speciesById: ISeaSpeciesRequestDto[] = [];
  public pinnaById: ISeaPinnaRequestDto[] = [];
  public threatsById: ISeaThreatsRequestDto[] = [];

  @Input() public protocolId!: number;
  @Input() public sampleId?: number;
  @Input() public readOnlyForm = false;

  public speciesCategoryDropdown: IDropdownItem[] = [
    { id: SeaSpeciesCategory.Cetaceans, name: SeaSpeciesCategory.Cetaceans },
    { id: SeaSpeciesCategory.Seals, name: SeaSpeciesCategory.Seals },
    { id: SeaSpeciesCategory.SeaTurtles, name: SeaSpeciesCategory.SeaTurtles },
    {
      id: SeaSpeciesCategory.SeaInvertebrates,
      name: SeaSpeciesCategory.SeaInvertebrates,
    },
  ];

  public transectHeadingDropdown: IDropdownItem[] = [
    { id: 'N', name: 'N' },
    { id: 'NE', name: 'NE' },
    { id: 'NW', name: 'NW' },
    { id: 'S', name: 'S' },
    { id: 'SE', name: 'SE' },
    { id: 'SW', name: 'SW' },
    { id: 'W', name: 'W' },
    { id: 'E', name: 'E' },
  ];

  public timeMeasurementUnitDropdown: IDropdownItem[] = [
    { id: 'Λεπτά', name: 'Λεπτά' },
    { id: 'Ώρες', name: 'Ώρες' },
    { id: 'Ημέρες', name: 'Ημέρες' },
    { id: 'Μήνες', name: 'Μήνες' },
  ];

  public terrestrialHabitatSealDropdown: IDropdownItem[] = [
    { id: 'Κατάλληλο για ξεκούραση', name: 'Κατάλληλο για ξεκούραση' },
    {
      id: 'Κατάλληλο για ξεκούραση και αναπαραγωγή',
      name: 'Κατάλληλο για ξεκούραση και αναπαραγωγή',
    },
  ];

  public methodCetaceansDropdown: IDropdownItem[] = [
    { id: 'Παρατήρηση επί σκάφους', name: 'Παρατήρηση επί σκάφους' },
    { id: 'Ακουστική', name: 'Ακουστική' },
    {
      id: 'Συνδυασμένη οπτική και ακουστική  αναζήτηση κητωδών με χρήση σκάφους',
      name: 'Συνδυασμένη οπτική και ακουστική  αναζήτηση κητωδών με χρήση σκάφους',
    },
    { id: 'Παρατήρηση από αέρως', name: 'Παρατήρηση από αέρως' },
    { id: 'Εκβρασμός', name: 'Εκβρασμός' },
  ];

  public methodSealsDropdown: IDropdownItem[] = [
    { id: 'Αυτόματη κάμερα', name: 'Αυτόματη κάμερα' },
    {
      id: 'Παρατήρηση κατά την επίσκεψη σε καταφύγιο',
      name: 'Παρατήρηση κατά την επίσκεψη σε καταφύγιο',
    },
    {
      id: 'Καταγραφή παρατήρησης μέσω του Δικτύου Διάσωσης και Συλλογής Πληροφοριών (ΔΔΣΠ)',
      name: 'Καταγραφή παρατήρησης μέσω του Δικτύου Διάσωσης και Συλλογής Πληροφοριών (ΔΔΣΠ)',
    },
    { id: 'Εκβρασμός', name: 'Εκβρασμός' },
  ];

  public methodSeaTurtlesDropdown: IDropdownItem[] = [
    {
      id: 'Παρατήρηση ιχνών στην παραλία',
      name: 'Παρατήρηση ιχνών στην παραλία',
    },
    {
      id: 'Παρατήρηση ατόμων στην παραλία',
      name: 'Παρατήρηση ατόμων στην παραλία',
    },
    { id: 'Σύλληψη ατόμων στη θάλασσα', name: 'Σύλληψη ατόμων στη θάλασσα' },
    { id: 'Εκβρασμός', name: 'Εκβρασμός' },
  ];

  public methodSeaInvertebratesDropdown: IDropdownItem[] = [
    { id: 'Δειγματοληψία ιζήματος', name: 'Δειγματοληψία ιζήματος' },
    { id: 'Δειγματοληψία μακροφύτων', name: 'Δειγματοληψία μακροφύτων' },
    { id: 'Αυτοψία από την ακτή', name: 'Αυτοψία από την ακτή' },
    {
      id: 'Υποβρύχιες οπτικές μετρήσεις με ελεύθερη κατάδυση',
      name: 'Υποβρύχιες οπτικές μετρήσεις με ελεύθερη κατάδυση',
    },
    {
      id: 'Υποβρύχιες οπτικές μετρήσεις με αυτόνομη κατάδυση',
      name: 'Υποβρύχιες οπτικές μετρήσεις με αυτόνομη κατάδυση',
    },
    {
      id: 'Επιτόπιες τηλεπισκοπικές μέθοδοι (υποβρύχια κάμερα ή drone)',
      name: 'Επιτόπιες τηλεπισκοπικές μέθοδοι (υποβρύχια κάμερα ή drone)',
    },
    { id: 'Δορυφορική τηλεπισκόπηση', name: 'Δορυφορική τηλεπισκόπηση' },
  ];

  public methodDropdown: IDropdownItem[] = [];

  public financialDropdown: IDropdownItem[] = [
    { id: 'ΥΜΕΠΕΡΑΑ', name: 'ΥΜΕΠΕΡΑΑ' },
    { id: 'ΕΠΠΕΡΑΑ 2007-2013', name: 'ΕΠΠΕΡΑΑ 2007-2013' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];

  public tabBtn: ITabBtnGroup[] = [
    { id: 'map', name: 'Επελεξε τοποθεσια', isChecked: true },
    { id: 'coordinates', name: 'Εισαγωγη συντεταγμενων', isChecked: false },
  ];

  public coordSystemDropdown: IDropdownItem[] = [
    { id: 'EGSA', name: 'EGSA' },
    { id: 'WGS84', name: 'WGS84' },
  ];

  public multiTabGroup = [
    { id: 0, name: 'Είδη', isChecked: true },
    { id: 1, name: 'Pinna Nobilis', isChecked: true },
    { id: 2, name: 'Πιέσεις - Απειλές', isChecked: true },
  ];

  public ergoEpoptiaFasiErgouDropdown: IDropdownItem[] = [
    { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
    { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
    { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
    { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
    { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public toastService: ToastService,
    public common: CommonService,
    private protocolService: ProtocolsService,
    private seaService: SeaService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      protocolId: number;
    };
    this.protocolId = state?.protocolId;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.protocolId = history.state.protocolId;
    this.initSeaFormGroup();
    this.seaForm.controls['speciesCategory'].valueChanges.subscribe((value) => {
      this.speciesCategory = value;
      this.multiTabGroup[0].name = this.speciesCategory;
      this.selectedSpecies = [];
      (this.seaForm.controls['species'] as FormArray).clear();
      (this.seaForm.controls['species'] as FormArray).markAsUntouched();
      (this.seaForm.controls['pinna'] as FormArray).clear();
      (this.seaForm.controls['pinna'] as FormArray).markAsUntouched();
      (this.seaForm.controls['threats'] as FormArray).clear();
      (this.seaForm.controls['threats'] as FormArray).markAsUntouched();
      this.seaForm.controls['method'].setValue('');
      this.seaForm.controls['method'].markAsUntouched();

      if (this.speciesCategory === SeaSpeciesCategory.Cetaceans) {
        this.methodDropdown = this.methodCetaceansDropdown;
      } else if (this.speciesCategory === SeaSpeciesCategory.Seals) {
        this.methodDropdown = this.methodSealsDropdown;
      } else if (this.speciesCategory === SeaSpeciesCategory.SeaTurtles) {
        this.methodDropdown = this.methodSeaTurtlesDropdown;
      } else if (this.speciesCategory === SeaSpeciesCategory.SeaInvertebrates) {
        this.methodDropdown = this.methodSeaInvertebratesDropdown;
      }

      this.seaForm.updateValueAndValidity();
    });

    this.species.valueChanges.subscribe((value) => {
      const val = value as ISeaSpeciesRequestDto[];
      const res: any[] = [];
      val.forEach((item) => {
        res.push({
          species: item.species,
          other: item.otherSpecies,
        });
      });
      if (this.pinna.controls.length > 0) {
        res.push({
          other: undefined,
          species: this.pinnaNobilisId,
        });
      }
      this.selectedSpecies = res;
    });

    this.pinna.valueChanges.subscribe((value) => {
      const res: any[] = [...this.selectedSpecies];
      const index = res.findIndex((item) => {
        return item.species === this.pinnaNobilisId;
      });

      if (value.length > 0) {
        if (index < 0) {
          res.push({
            other: undefined,
            species: this.pinnaNobilisId,
          });
        }
      } else {
        if (index > -1) {
          res.splice(index, 1);
        }
      }
      this.selectedSpecies = res;
    });

    if (this.sampleId) {
      this.seaService
        .getSeaById(this.sampleId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.getValuesFromServices();
            return;
          })
        )
        .subscribe((res) => {
          if (res != undefined) {
            this.seaById = res as ISeaDto;
            // Fix different property names
            this.seaById.lat = this.seaById.pointX;
            this.seaById.long = this.seaById.pointY;

            if (res.files != null) {
              this.predefinedFiles = res.files as any;
            }

            // Fix date
            if (this.seaById.date) {
              const convertedDate = new Date(this.seaById.date);
              const dateString =
                convertedDate.getDate().toString().padStart(2, '0') +
                (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                convertedDate.getFullYear().toString();
              this.seaById.date = dateString;
            }
            // Fix time
            if (this.seaById.time) {
              const timeString = this.seaById.time
                .toString()
                .slice(0, 5)
                .replaceAll(':', '');
              this.seaById.time = timeString;
            }
          }
        });
    } else {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.protocolService.getCellCodes(),
      this.protocolService.getPrefectures(this.seaById?.dataVersion),
      this.protocolService.getNaturaSites(this.seaById?.dataVersion),
      this.seaService.getHabitats(this.seaById?.dataVersion),
      this.seaService.getSpecies(this.seaById?.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.codes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.codes.forEach((code) => {
              dropdown.push({
                id: code.cellCode,
                name: code.cellCode,
              });
            });
            this.codesDropdown = dropdown;
          }

          if (this.prefectures.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.prefectures.forEach((prefecture) => {
              dropdown.push({
                id: prefecture.id,
                name: prefecture.id + ' - ' + prefecture.prefecture,
              });
            });
            this.prefecturesDropdown = dropdown;
          }

          if (this.naturaSites.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.naturaSites.forEach((site) => {
              dropdown.push({
                id: site.naturaCode,
                name: site.naturaCode + ' - ' + site.name,
              });
            });
            this.sitesDropdown = dropdown;
          }

          if (this.habitatTypes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.habitatTypes.forEach((habitat) => {
              dropdown.push({
                id: habitat.habType,
                name: habitat.habTypeName,
                actualId: parseInt(habitat.code),
              });
            });
            this.habitatTypesDropdown = dropdown;
          }

          if (this.sampleId && this.seaById) {
            if (this.seaById.species) {
              this.speciesById = this.seaById.species;
            }
            if (this.seaById.pinna) {
              this.pinnaById = this.seaById.pinna;
            }
            if (this.seaById.threats) {
              this.threatsById = this.seaById.threats;
            }
            if (this.readOnlyForm === true) {
              this.seaForm.disable();
            }
            this.seaForm.patchValue(this.seaById);
          }

          return;
        })
      )
      .subscribe(([codes, prefectures, naturaSites, habitatTypes, species]) => {
        if (codes != undefined) {
          this.codes = codes as ICellCodeDto[];
        }

        if (prefectures != undefined) {
          this.prefectures = prefectures as IPrefecturesDto[];
        }

        if (naturaSites != undefined) {
          this.naturaSites = naturaSites as INaturaSitesDto[];
        }

        if (habitatTypes != undefined) {
          this.habitatTypes = habitatTypes as ISeaHabitatDto[];
        }

        if (species != null) {
          this.ctSpecies = species as ISeaSpeciesDto[];
          const pinnaNobilis = species.find(
            (item) => item.code.toLowerCase() === 'pinna nobilis'
          );
          if (pinnaNobilis != null) {
            this.pinnaNobilisId = pinnaNobilis.id;
          }
        }
      });
  }

  private monitorCoords(): void {
    this.common
      .monitorCoords(this.destroy$, this.seaForm, 'x', 'y', 'lat', 'long')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICoordinates) => (this.coordinates = data));
  }

  /**
   * Initialization of the user form.
   */
  private initSeaFormGroup() {
    this.seaForm = this.seaService.initSeaFormGroup(this.fb);

    this.seaForm?.controls['samId'].clearValidators();

    this.monitorCoords();
    this.common.monitorCoordSystem(this.seaForm, this.destroy$);
  }

  public get species(): FormArray {
    return this.seaForm.controls['species'] as FormArray;
  }

  public get pinna(): FormArray {
    return this.seaForm.controls['pinna'] as FormArray;
  }

  public get threats(): FormArray {
    return this.seaForm.controls['threats'] as FormArray;
  }

  public get coordSystem(): number {
    return this.seaForm.controls['isWGS84'].value ? 1 : 0;
  }

  /**
   * Adds form value in corresponding observable.
   */
  public prepareSeaRequest(): void {
    this.errors = this.common.validateAllFormControl(this.seaForm);
    if (this.errors.length > 0) {
      return;
    }

    this.sea = this.seaService.prepareSeaRequest(
      this.seaForm,
      false,
      this.habitatTypes,
      this.ctSpecies,
      this.sampleId != null
    );

    if (this.sampleId && !this.readOnlyForm) {
      this.updateSample();
    } else {
      this.registerNewSample();
    }
  }

  public registerNewSample(): void {
    this.seaService
      .addSea(this.sea)
      .pipe(
        catchError((err) => {
          this.toastService.show('GENERIC_FAILURE_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
          throw err;
        })
      )
      .subscribe((sampleIdResponse: ISampleIdResponse) => {
        if (this.files.length > 0) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append('files', file);
          });

          formData.append('SampleId', sampleIdResponse.sampleId.toString());
          this.seaService
            .addFiles(formData)
            .pipe(
              catchError((err) => {
                this.toastService.show('GENERIC_FAILURE_TEXT', {
                  header: 'GENERIC_FAILURE_HEADER',
                });
                throw err;
              })
            )
            .subscribe(() => {
              this.toastService.show('GENERIC_SUCCESS_TEXT', {
                header: 'GENERIC_SUCCESS_HEADER',
              });

              this.activeModal.close({
                sampleId: sampleIdResponse.sampleId,
                samplingCode: this.seaForm.value.samplingCode,
              });
            });
        } else {
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });

          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.seaForm.value.samplingCode,
          });
        }
      });
  }

  public updateSample(): void {
    if (this.sampleId) {
      this.seaService
        .putSea(this.sampleId, this.sea)
        .pipe(
          catchError((err) => {
            this.toastService.show('GENERIC_FAILURE_TEXT', {
              header: 'GENERIC_FAILURE_HEADER',
            });
            throw err;
          })
        )
        .subscribe((sampleIdResponse: ISampleIdResponse) => {
          if (this.files != null) {
            // Uploading new files
            const formData = new FormData();
            let addFiles = false;
            this.files.forEach((file: any) => {
              if (file instanceof File) {
                addFiles = true;
                formData.append('files', file);
              }
            });

            if (addFiles) {
              formData.append('SampleId', (this.sampleId as number).toString());
              this.seaService
                .addFiles(formData)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          // Deleting files
          if (this.filesToDelete != null && this.filesToDelete.length > 0) {
            const payload: IFileDeleteRequest = {
              sampleId: this.sampleId as number,
              fileIds: []
            };
            this.filesToDelete.forEach(file => {
              if (file.id != null) {
                payload.fileIds.push(file.id)
              }
            });

            if (payload.fileIds.length > 0) {
              this.seaService
                .removeFiles(payload)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.seaForm.value.samplingCode,
          });
        });
    }
  }

  public confirmClose(): void {
    if (this.seaForm.dirty) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.result.then((data) => {
        if (data) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  /**
   * Change view
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public tabBtnClick(btnId: string) {
    if (btnId === 'map') {
      this.tabBtn[0].isChecked = true;
      this.tabBtn[1].isChecked = false;
    } else {
      this.tabBtn[1].isChecked = true;
      this.tabBtn[0].isChecked = false;
    }
  }

  /**
   * Find the new coordinates of the map
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public onMarkerMoved(coordinates: { lat: number; lng: number }): void {
    this.coordinates = coordinates;
    this.submitCoordinates();
  }

  public submitCoordinates(): void {
    if (this.coordinates != null) {
      this.seaForm.controls['lat'].patchValue(this.coordinates.lat, {
        emitEvent: false,
      });
      this.seaForm.controls['long'].patchValue(this.coordinates.lng, {
        emitEvent: false,
      });

      const coordinates = this.common.convertWGStoEGSA(
        this.coordinates.lat,
        this.coordinates.lng
      );
      this.seaForm.controls['x'].patchValue(coordinates.x, {
        emitEvent: false,
      });
      this.seaForm.controls['y'].patchValue(coordinates.y, {
        emitEvent: false,
      });
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
