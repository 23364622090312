<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>
<div class="title-container">
  <span class="title-text uppercase"
    >{{ "NEW_REGISTRATION" | translate }} - {{ "MAMMALS" | translate }}</span
  >
</div>
<div class="random-observation-check-box" [formGroup]="mammalForm">
  <label
    class="btn btn-secondary"
    ngbButtonLabel
    [ngClass]="{ disabled: sampleId }"
  >
    <input type="checkbox" formControlName="randomObservation" />
    {{ "RANDOM_OBSERVATION" | translate }}
  </label>
</div>
<div class="mammals-container">
  <div class="info-container">
    <span class="info-text uppercase">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>

  <div class="form-container">
    <div
      class="form-row form-3"
      [hidden]="mammalForm.controls['randomObservation'].value === true"
    >
      <app-input
        [formGroup]="mammalForm"
        [formName]="'samplingCode'"
        [label]="'SAMPLE_CODE'"
        [required]="true"
      ></app-input>
    </div>

    <div class="form-row form-3">
      <app-dropdown
        [formGroup]="mammalForm"
        [formName]="'ergoEpoptiaFasiErgou'"
        [label]="'ERGO_EPOPTIA_FASI_ERGOU'"
        [dropdownList]="ergoEpoptiaFasiErgouDropdown"
        [required]="true"
        [preDefinedId]="mammalById?.ergoEpoptiaFasiErgou"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="mammalForm"
        [formName]="'speciesCategory'"
        [label]="'SPECIES_CATEGORY'"
        [dropdownList]="speciesCategoryDropdown"
        [required]="true"
        [preDefinedId]="mammalById?.speciesCategory"
      ></app-dropdown>
    </div>

    <div
      class="form-row"
      [hidden]="mammalForm.controls['randomObservation'].value === true"
    >
      <app-input
        [mask]="'00/00/0000'"
        [formGroup]="mammalForm"
        [formName]="'date'"
        [label]="'DATE'"
        [required]="true"
      ></app-input>
      <app-input
        [mask]="'00:00'"
        [formGroup]="mammalForm"
        [formName]="'time'"
        [label]="'TIME'"
        [required]="true"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="mammalForm"
        [formName]="'duration'"
        [label]="'DURATION'"
      ></app-input>
    </div>

    <div
      class="form-row"
      [hidden]="mammalForm.controls['randomObservation'].value === true"
    >
      <app-input
        [formGroup]="mammalForm"
        [formName]="'researcher'"
        [label]="'RESEARCHER'"
      ></app-input>
      <app-input
        [formGroup]="mammalForm"
        [formName]="'author'"
        [label]="'AUTHOR'"
      ></app-input>
      <app-dropdown
        [formGroup]="mammalForm"
        [formName]="'financialInstrument'"
        [label]="'FINANCIAL_INSTRUMENT'"
        [dropdownList]="financialDropdown"
        [required]="true"
        [preDefinedId]="mammalById?.financialInstrument"
      ></app-dropdown>
    </div>

    <div
      class="form-row form-3"
      [hidden]="mammalForm.controls['randomObservation'].value === true"
    >
      <app-dropdown
        [formGroup]="mammalForm"
        [formName]="'method'"
        [label]="'METHOD'"
        [dropdownList]="methodDropdown"
        [required]="true"
        [preDefinedId]="mammalById?.method"
        [ngClass]="{
          disabled:
            mammalForm.controls['speciesCategory'].value === '' ||
            mammalForm.controls['method'].disabled
        }"
      ></app-dropdown>
      <div
        class="custom-check-box"
        [formGroup]="mammalForm"
        [hidden]="
          mammalForm.controls['method'].value !== 'B' &&
          mammalForm.controls['method'].value !== 'J' &&
          mammalForm.controls['method'].value !== 'N'
        "
      >
        <label
          class="btn btn-secondary"
          ngbButtonLabel
          [ngClass]="{ disabled: mammalForm.controls['capture'].disabled }"
        >
          <input type="checkbox" formControlName="capture" />
          {{ "CAPTURE" | translate }}
        </label>
      </div>
    </div>

    <app-textarea
      [hidden]="mammalForm.controls['randomObservation'].value === true"
      [style.max-width]="'100%'"
      [formGroup]="mammalForm"
      [formName]="'habitat'"
      [label]="'HABITAT_DESCRIPTION'"
    ></app-textarea>

    <div
      class="form-row"
      [hidden]="mammalForm.controls['randomObservation'].value === true"
    >
      <app-input
        [ngClass]="{ disabled: mammalForm.controls['courseLength'].disabled }"
        [type]="'number'"
        [formGroup]="mammalForm"
        [formName]="'courseLength'"
        [label]="'COURSE_LENGTH'"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="mammalForm"
        [formName]="'temperature'"
        [label]="'TEMPERATURE'"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="mammalForm"
        [formName]="'windIntensity'"
        [label]="'WIND_INTENSITY'"
      ></app-input>
    </div>

    <div
      class="form-row form-3"
      [hidden]="mammalForm.controls['randomObservation'].value === true"
    >
      <app-dropdown
        [formGroup]="mammalForm"
        [formName]="'cloudiness'"
        [label]="'WEATHER'"
        [dropdownList]="cloudinessDropdown"
        [preDefinedId]="mammalById?.cloudiness"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="mammalForm"
        [formName]="'raining'"
        [label]="'RAINING_SNOWING'"
        [dropdownList]="rainingDropdown"
        [preDefinedId]="mammalById?.raining"
      ></app-dropdown>
    </div>

    <app-textarea
      [hidden]="mammalForm.controls['randomObservation'].value === true"
      [style.max-width]="'100%'"
      [formGroup]="mammalForm"
      [formName]="'habitatThreats'"
      [label]="'HABITAT_THREATS'"
    ></app-textarea>

    <app-textarea
      [hidden]="mammalForm.controls['randomObservation'].value === true"
      [style.max-width]="'100%'"
      [formGroup]="mammalForm"
      [formName]="'observations'"
      [label]="'OBSERVATIONS'"
    ></app-textarea>
  </div>
  <div
    class="info-container"
    [hidden]="mammalForm.controls['randomObservation'].value === true"
  >
    <span class="info-text uppercase">{{ "LOCATION" | translate }}</span>
    <div class="line"></div>
  </div>
  <div
    class="form-container form-1"
    [hidden]="mammalForm.controls['randomObservation'].value === true"
  >
    <div class="form-row" [formGroup]="mammalForm" [hidden]="readOnlyForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isEGSA" />
        {{ coordSystemDropdown[0].id }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isWGS84" />
        {{ coordSystemDropdown[1].id }}
      </label>
    </div>
    <div class="form-row">
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="mammalForm"
        [formName]="'lat'"
        [label]="'LATITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="mammalForm"
        [formName]="'long'"
        [label]="'LONGTITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="mammalForm"
        [formName]="'x'"
        [label]="'X'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="mammalForm"
        [formName]="'y'"
        [label]="'Y'"
        [required]="true"
      ></app-input>
    </div>
  </div>
  <br />
  <div
    class="map-container"
    [hidden]="mammalForm.controls['randomObservation'].value === true"
  >
    <app-map
      [coordinates]="coordinates"
      (markerMoved)="onMarkerMoved($event)"
      [disabled]="readOnlyForm"
    ></app-map>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <nav
    ngbNav
    #nav="ngbNav"
    [destroyOnHide]="false"
    class="multi-tab-buttons"
    [activeId]="activeTabId"
    [ngClass]="{
      'hide-geography': mammalForm.controls['randomObservation'].value === true,
      'hide-habitats': mammalForm.controls['randomObservation'].value === true
    }"
  >
    <ng-container [ngbNavItem]="0">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name }}</a
      >
      <ng-template ngbNavContent>
        <div
          [ngClass]="
            readOnlyForm ? 'form-container form-2' : 'form-container form-1'
          "
        >
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="mammalForm"
              [formName]="'altitude'"
              [label]="'BOOK_ALTITUDE'"
            ></app-input>
            <app-input
              [formGroup]="mammalForm"
              [formName]="'locality'"
              [label]="'LOCATION'"
            ></app-input>
          </div>
          <div class="form-row">
            <app-dropdown
              [hidden]="!readOnlyForm"
              [formGroup]="mammalForm"
              [formName]="'prefecture'"
              [label]="'PREFECTURE'"
              [dropdownList]="prefecturesDropdown"
              [preDefinedId]="mammalById?.prefecture"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="mammalForm"
              [hidden]="!readOnlyForm"
              [formName]="'gridCell'"
              [label]="'GRID_CELL'"
              [dropdownList]="codesDropdown"
              [preDefinedId]="mammalById?.gridCell"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="mammalForm"
              [hidden]="!readOnlyForm"
              [formName]="'naturaCode'"
              [label]="'NATURA_CODE'"
              [dropdownList]="sitesDropdown"
              [preDefinedId]="mammalById?.naturaCode"
            ></app-dropdown>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="1">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[1].name }}</a
      >
      <ng-template ngbNavContent>
        <app-mammal-habitats
          [formGroup]="mammalForm"
          [habitats]="habitats"
          [habitatsById]="habitatsById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="mammalById?.dataVersion"
          [sampleId]="sampleId"
        ></app-mammal-habitats>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="2">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[2].name }}</a
      >
      <ng-template ngbNavContent>
        <app-mammal-species
          [formGroup]="mammalForm"
          [species]="species"
          [speciesById]="speciesById"
          [ctSpecies]="ctSpeciesFiltered"
          [disabledControls]="readOnlyForm"
          [dataVersion]="mammalById?.dataVersion"
          [sampleId]="sampleId"
        ></app-mammal-species>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="3">
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[3].isChecked }"
        >{{ multiTabGroup[3].name }}</a
      >
      <ng-template ngbNavContent>
        <app-mammal-threats
          [selectedSpecies]="selectedSpecies"
          [ctSpecies]="ctSpeciesFiltered"
          [formGroup]="mammalForm"
          [threats]="threats"
          [threatsById]="threatsById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="mammalById?.dataVersion"
          [sampleId]="sampleId"
        ></app-mammal-threats>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <ng-container
    *ngIf="
      (!sampleId && !!!mammalForm.controls['randomObservation'].value) ||
      (sampleId &&
        predefinedFiles.length > 0 &&
        !!!mammalForm.controls['randomObservation'].value) ||
      (sampleId &&
        !readOnlyForm &&
        !!!mammalForm.controls['randomObservation'].value)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    (buttonClicked)="prepareMammalRequest()"
    [btnText]="sampleId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    [isDisabled]="!mammalForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
