<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-2">
    <ng-container
      *ngFor="let structForm of species.controls; let index = index"
    >
      <div class="form-row" [formGroup]="getGroup(index)">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="structsDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'coverPlot'"
          [label]="'COVER_PLOT'"
          [dropdownList]="coverPlotDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['coverPlot'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'coverArea'"
          [label]="'COVER_AREA'"
          [dropdownList]="coverAreaDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['coverArea'].value"
        ></app-dropdown>
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'vitality'"
          [label]="'VITALITY'"
          [dropdownList]="vitalityDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['vitality'].value"
        ></app-dropdown>
      </div>
      <div class="custom-row" [formGroup]="getGroup(index)">
        <label class="btn btn-secondary" ngbButtonLabel>
          <input type="checkbox" formControlName="parii" />
          {{ "PARII_FULL" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel>
          <input type="checkbox" formControlName="pariv" />
          {{ "PARIV_FULL" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel>
          <input type="checkbox" formControlName="parv" />
          {{ "PARV_FULL" | translate }}
        </label>
        <app-button
          (buttonClicked)="remove(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== species.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_RECORD'"
      (buttonClicked)="addNew()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
