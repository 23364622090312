import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export function timeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // implementation of the validation

    if (!(control.value && (control.value as string).match(/^\d{2}\d{2}$/))) {
      return null;
    }

    //let parts = (control.value as string).split('/');
    let hour = parseInt((control.value as string).substring(0, 2));
    let minute = parseInt((control.value as string).substring(2, 4));

    if (hour < 0 || hour > 23 || minute < 0 || minute > 59)
      return {
        invalidTime: true,
      };

    return null;
  };
}
