<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>
<div class="title-container">
  <span class="title-text uppercase"
    >{{ "NEW_REGISTRATION" | translate }} - {{ "SEA" | translate }}</span
  >
</div>
<div class="sea-container">
  <div class="info-container uppercase">
    <span class="info-text">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container">
    <div class="form-row form-3">
      <app-input
        [formGroup]="seaForm"
        [formName]="'samplingCode'"
        [label]="'SAMPLE_CODE'"
        [required]="true"
      ></app-input>
    </div>

    <div class="form-row form-3">
      <app-dropdown
        class="dropdown-column"
        [formGroup]="seaForm"
        [formName]="'ergoEpoptiaFasiErgou'"
        [label]="'ERGO_EPOPTIA_FASI_ERGOU'"
        [dropdownList]="ergoEpoptiaFasiErgouDropdown"
        [required]="true"
        [preDefinedId]="seaById?.ergoEpoptiaFasiErgou"
      ></app-dropdown>
    </div>

    <div class="form-row">
      <app-input
        [mask]="'00/00/0000'"
        [formGroup]="seaForm"
        [formName]="'date'"
        [label]="'DATE'"
        [required]="true"
      ></app-input>
      <app-input
        [mask]="'00:00'"
        [formGroup]="seaForm"
        [formName]="'time'"
        [label]="'TIME'"
      ></app-input>
      <app-dropdown
        [formGroup]="seaForm"
        [formName]="'financialInstrument'"
        [label]="'FINANCIAL_INSTRUMENT'"
        [dropdownList]="financialDropdown"
        [required]="true"
        [preDefinedId]="seaById?.financialInstrument"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-input
        [formGroup]="seaForm"
        [formName]="'researcher'"
        [label]="'RESEARCHER'"
      ></app-input>
      <app-input
        [formGroup]="seaForm"
        [formName]="'author'"
        [label]="'AUTHOR'"
      ></app-input>
    </div>

    <div class="form-row">
      <app-dropdown
        [hidden]="!readOnlyForm"
        class="dropdown-column"
        [formGroup]="seaForm"
        [formName]="'naturaCode'"
        [label]="'NATURA_CODE'"
        [dropdownList]="sitesDropdown"
        [required]="true"
        [preDefinedId]="seaById?.naturaCode"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="seaForm"
        [hidden]="!readOnlyForm"
        [formName]="'gridCell'"
        [label]="'GRID_CELL'"
        [dropdownList]="codesDropdown"
        [required]="true"
        [preDefinedId]="seaById?.gridCell"
      ></app-dropdown>
    </div>

    <div class="form-row form-3">
      <app-dropdown
        [formGroup]="seaForm"
        [formName]="'speciesCategory'"
        [label]="'SPECIES_CATEGORY'"
        [dropdownList]="speciesCategoryDropdown"
        [preDefinedId]="seaById?.speciesCategory"
      ></app-dropdown>
    </div>

    <div class="form-row" [hidden]="speciesCategory === ''">
      <app-dropdown
        [formGroup]="seaForm"
        [formName]="'method'"
        [label]="'METHOD'"
        [dropdownList]="methodDropdown"
        [required]="true"
        [preDefinedId]="seaById?.method"
      ></app-dropdown>
    </div>

    <ng-container>
      <div class="form-row form-3" [hidden]="speciesCategory === ''">
        <app-input
          [type]="'number'"
          [formGroup]="seaForm"
          [formName]="'duration'"
          [label]="'DURATION_SEA'"
        ></app-input>
        <app-input
          [hidden]="speciesCategory !== seaSpeciesCategory.Cetaceans"
          [formGroup]="seaForm"
          [formName]="'durationOnEffort'"
          [label]="'DURATION_ON_EFFORT'"
        ></app-input>
        <app-dropdown
          [formGroup]="seaForm"
          [formName]="'timeMeasurementUnit'"
          [label]="'TIME_MEASUREMENT_UNIT'"
          [dropdownList]="timeMeasurementUnitDropdown"
          [preDefinedId]="seaById?.timeMeasurementUnit"
        ></app-dropdown>
      </div>

      <div class="form-row" [hidden]="speciesCategory === ''">
        <app-dropdown
          [hidden]="
            speciesCategory === seaSpeciesCategory.Cetaceans ||
            speciesCategory === seaSpeciesCategory.SeaTurtles
          "
          [formGroup]="seaForm"
          [formName]="'habType'"
          [label]="'HABITAT_TYPE'"
          [dropdownList]="habitatTypesDropdown"
          [preDefinedId]="seaById?.habType"
        ></app-dropdown>
        <app-dropdown
          [hidden]="speciesCategory !== seaSpeciesCategory.Seals"
          [formGroup]="seaForm"
          [formName]="'terrestrialHabitatSeal'"
          [label]="'TERRESTRIAL_HABITAT_SEAL'"
          [dropdownList]="terrestrialHabitatSealDropdown"
          [preDefinedId]="seaById?.terrestrialHabitatSeal"
        ></app-dropdown>
      </div>
      <div class="form-row" [hidden]="speciesCategory === ''">
        <app-input
          [hidden]="
            speciesCategory === seaSpeciesCategory.Seals ||
            speciesCategory === seaSpeciesCategory.SeaTurtles
          "
          [type]="'number'"
          [formGroup]="seaForm"
          [formName]="'transectLenght'"
          [label]="'TRANSECT_LENGTH'"
        ></app-input>
        <app-dropdown
          [hidden]="speciesCategory !== seaSpeciesCategory.SeaInvertebrates"
          [formGroup]="seaForm"
          [formName]="'transectHeading'"
          [label]="'TRANSECT_HEADING'"
          [dropdownList]="transectHeadingDropdown"
          [preDefinedId]="seaById?.transectHeading"
        ></app-dropdown>
        <app-input
          [hidden]="speciesCategory !== seaSpeciesCategory.SeaInvertebrates"
          [type]="'number'"
          [formGroup]="seaForm"
          [formName]="'minDepth'"
          [label]="'MIN_DEPTH'"
        ></app-input>
        <app-input
          [hidden]="speciesCategory !== seaSpeciesCategory.SeaInvertebrates"
          [type]="'number'"
          [formGroup]="seaForm"
          [formName]="'maxDepth'"
          [label]="'MAX_DEPTH'"
        ></app-input>
      </div>
    </ng-container>

    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="seaForm"
      [formName]="'observations'"
      [label]="'OBSERVATIONS'"
    ></app-textarea>
    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="seaForm"
      [formName]="'habitatThreats'"
      [label]="'HABITAT_THREATS'"
    ></app-textarea>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "LOCATION" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container form-1">
    <div class="form-row">
      <app-input
        [formGroup]="seaForm"
        [formName]="'locality'"
        [label]="'LOCATION'"
      ></app-input>
    </div>
    <div class="form-row" [formGroup]="seaForm" [hidden]="readOnlyForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isEGSA" />
        {{ coordSystemDropdown[0].id }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isWGS84" />
        {{ coordSystemDropdown[1].id }}
      </label>
    </div>
    <div class="form-row">
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="seaForm"
        [formName]="'lat'"
        [label]="'LATITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="seaForm"
        [formName]="'long'"
        [label]="'LONGTITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="seaForm"
        [formName]="'x'"
        [label]="'X'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="seaForm"
        [formName]="'y'"
        [label]="'Y'"
        [required]="true"
      ></app-input>
    </div>

    <div class="form-row">
      <div class="row-info">
        {{ "COORDINATES_REFERENCE" | translate }}
      </div>
    </div>
  </div>
  <br />
  <div class="map-container">
    <app-map
      [coordinates]="coordinates"
      (markerMoved)="onMarkerMoved($event)"
      [disabled]="readOnlyForm"
    ></app-map>
  </div>
  <div *ngIf="speciesCategory || !sampleId" class="info-container">
    <span class="info-text uppercase">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>

  <div *ngIf="!speciesCategory && !sampleId">
    {{ "CHOOSE_SPECIES_CATEGORY_INFO" | translate }}
  </div>
  <ng-container *ngIf="speciesCategory">
    <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" class="multi-tab-buttons">
      <ng-container ngbNavItem>
        <a
          class="multi-button"
          [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
          ngbNavLink
          >{{ multiTabGroup[0].name }}</a
        >
        <ng-template ngbNavContent>
          <app-sea-species
            [formGroup]="seaForm"
            [species]="species"
            [speciesCategory]="speciesCategory"
            [speciesById]="speciesById"
            [disabledControls]="readOnlyForm"
            [dataVersion]="seaById?.dataVersion"
            [sampleId]="sampleId"
          >
          </app-sea-species>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="speciesCategory === seaSpeciesCategory.SeaInvertebrates"
        ngbNavItem
      >
        <a
          class="multi-button"
          [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
          ngbNavLink
          >{{ multiTabGroup[1].name }}</a
        >
        <ng-template ngbNavContent>
          <app-sea-pinna-nobilis
            [formGroup]="seaForm"
            [pinna]="pinna"
            [pinnaById]="pinnaById"
            [disabledControls]="readOnlyForm"
            [sampleId]="sampleId"
          >
          </app-sea-pinna-nobilis>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a
          class="multi-button"
          ngbNavLink
          [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
          >{{ multiTabGroup[2].name }}</a
        >
        <ng-template ngbNavContent>
          <app-sea-threats
            [formGroup]="seaForm"
            [threats]="threats"
            [selectedSpecies]="selectedSpecies"
            [threatsById]="threatsById"
            [disabledControls]="readOnlyForm"
            [dataVersion]="seaById?.dataVersion"
            [sampleId]="sampleId"
          ></app-sea-threats>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>
  </ng-container>

  <ng-container
    *ngIf="
      !sampleId ||
      (sampleId && predefinedFiles.length > 0) ||
      (sampleId && !readOnlyForm)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    [btnText]="sampleId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    (click)="prepareSeaRequest()"
    [isDisabled]="!seaForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
