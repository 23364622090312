import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { ChlorisService } from '../../services/chloris.service';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { IChlorisFunctions } from 'src/app/core/dtos/chloris.dto';

@Component({
  selector: 'app-chloris-functions',
  templateUrl: './chloris-functions.component.html',
  styleUrls: ['./chloris-functions.component.scss'],
})
export class ChlorisFunctionsComponent implements OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public functionsForm!: FormGroup;
  public structuresDropdown: IDropdownItem[] = [];

  @Input() public functions!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public functionsById: IChlorisFunctions[] = [];
  @Input() public disabledControls = false;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private chlorisService: ChlorisService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['functionsById'] && this.sampleId) {
      this.functionsById.forEach(() => {
        this.addNewFunctions();
      });
      this.functions.patchValue(this.functionsById);
      this.functions.markAsTouched();
      this.functionsById = [];
      if (this.disabledControls === true) {
        this.functions.disable();
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.functions.controls[index] as FormGroup;
  }

  public addNewFunctions(): void {
    this.functions.push(this.chlorisService.initFunctionsFormGroup(this.fb));
  }

  public removeFunctions(index: number): void {
    this.functions.removeAt(index);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
