import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
export enum SeaHabitatFormGroup {
  Species = 'species',
  Threats = 'threats',
  A = 'a',
  B = 'b',
  C = 'c',
  Struct1170 = 'struct1170',
}

export const seaHabitatConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
    ],
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 4,
      },
      {
        header: 'Ώρα Δειγματοληψίας',
        type: 'time',
        formName: 'time',
        order: 5,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 7,
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
        order: 8,
      },
      {
        header: 'Τοποθεσία',
        type: 'string',
        formName: 'locality',
        order: 12,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 13,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
        order: 14,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 20,
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
      },
      {
        header: 'Έργο εποπτείας / Φάση έργου',
        type: 'string',
        formName: 'ergoEpoptiaFasiErgou',
        order: 3,
      },
      {
        header: 'Συντάκτης',
        type: 'string',
        formName: 'author',
        order: 6,
      },
      {
        header: 'Μέθοδος Δειγματοληψίας',
        type: 'string',
        formName: 'method',
        order: 9,
      },
      {
        header: 'Τύπος οικοτόπου',
        type: 'string',
        formName: 'habType',
        order: 10,
      },
      {
        header: 'Τυπολογία',
        type: 'string',
        formName: 'typology',
        order: 11,
      },
      {
        header: 'Shapefile',
        type: 'string',
        formName: 'shapefile',
        order: 15,
      },
      {
        header: 'Βάθος min (m)',
        type: 'float',
        formName: 'depthMin',
        order: 16,
      },
      {
        header: 'Βάθος max (m)',
        type: 'float',
        formName: 'depthMax',
        order: 17,
      },
      {
        header: 'Προσανατολισμός ακτής',
        type: 'string',
        formName: 'orientation',
        order: 18,
      },
      {
        header: 'Οικομορφή Λιβαδιού',
        type: 'string',
        formName: 'meadowLandscape',
        order: 19,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: SeaHabitatFormGroup.Species,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 1,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 2,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 3,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 4,
      },
      {
        header: 'Σχετική αφθονία είδους',
        type: 'string',
        formName: 'relativeAbundance',
        order: 5,
      },
      {
        header: 'Πληθυσμιακή πυκνότητα σε άτομα/m2',
        type: 'float',
        formName: 'populationDensity',
        order: 6,
      },
      {
        header: 'Παρατηρήσεις είδους',
        type: 'string',
        formName: 'observations',
        order: 7,
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: SeaHabitatFormGroup.Threats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 1,
        disabled: true,
      },
      {
        header: 'Κωδικός θαλάσσιου τύπου οικοτόπου',
        type: 'string',
        formName: 'habType',
        order: 2,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 3,
        disabled: true,
      },
      {
        header: 'Κωδικός πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsId',
        order: 4,
      },
      {
        header: 'Όνομα είδους απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsDescription',
        order: 5,
        disabled: true,
      },
      {
        header: 'Επίδραση/Ένταση',
        type: 'string',
        formName: 'influenceIntensity',
        order: 6,
      },
      {
        header: 'Χρονικός προσδιορισμός',
        type: 'string',
        formName: 'timeDetermination',
        order: 7,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 8,
      },
    ],
  },
  // This is the 4th sheet
  {
    formGroupName: SeaHabitatFormGroup.A,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 1,
        disabled: true,
      },
      {
        header: 'Τύπος οικοτόπου',
        type: 'string',
        formName: 'habType',
        order: 2,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 3,
        disabled: true,
      },
      {
        header: 'Πάσσαλος',
        type: 'int',
        formName: 'picket',
        order: 4,
      },
      {
        header: 'Γραμμική απόσταση (cm)',
        type: 'float',
        formName: 'linearDistance',
        order: 5,
      },
      {
        header: 'Βάθος (m) κατώτερου ορίου εξάπλωσης',
        type: 'float',
        formName: 'lowestLimitDepth',
        order: 6,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84) Κατώτερου ορίου εξάπλωσης',
        type: 'float',
        formName: 'latitude',
        order: 7,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84) Κατώτερου ορίου εξάπλωσης',
        type: 'float',
        formName: 'longitude',
        order: 8,
      },
      {
        header: 'Τυπολογία κατώτερου ορίου',
        type: 'string',
        formName: 'lowestLimitTypology',
        order: 9,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 10,
      },
    ],
  },
  // This is the 5th sheet
  {
    formGroupName: SeaHabitatFormGroup.B,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 1,
        disabled: true,
      },
      {
        header: 'Τύπος οικοτόπου',
        type: 'string',
        formName: 'habType',
        order: 2,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 3,
        disabled: true,
      },
      {
        header: 'Θέση',
        type: 'string',
        formName: 'position',
        order: 4,
      },
      {
        header: 'Μέση πυκνότητα βλαστών ανα m2',
        type: 'float',
        formName: 'meanSproutDensity',
        order: 5,
      },
      {
        header: 'Μέσο % πλαγιοτρόπων βλαστών',
        type: 'float',
        formName: 'meanPercentagePlagiotropin',
        order: 6,
      },
      {
        header: 'Μέσο μήκος δέσμης φύλλων',
        type: 'float',
        formName: 'meanLeavesBundleLength',
        order: 7,
      },
      {
        header: 'Κατεύθυνση (Degrees)',
        type: 'float',
        formName: 'orientation',
        order: 8,
      },
      {
        header: 'Κάλυψη ενδιαιτήματος ανά 10m (m): Άμμος ',
        type: 'float',
        formName: 'sandCoverage',
        order: 9,
      },
      {
        header: 'Κάλυψη ενδιαιτήματος ανά 10m (m): Βράχος',
        type: 'float',
        formName: 'rockCoverage',
        order: 10,
      },
      {
        header: 'Κάλυψη ενδιαιτήματος ανά 10m (m): Νεκρό Μatte',
        type: 'float',
        formName: 'deadMatteCoverage',
        order: 11,
      },
      {
        header: 'Κάλυψη ενδιαιτήματος ανά 10m (m): C. nodosa',
        type: 'float',
        formName: 'cNodosaCoverage',
        order: 12,
      },
      {
        header: 'Κάλυψη ενδιαιτήματος ανά 10m (m): Caulerpa sp',
        type: 'float',
        formName: 'caulerpaCoverage',
        order: 13,
      },
      {
        header: 'Κάλυψη ενδιαιτήματος ανά 10m (m): H. stipulacea',
        type: 'float',
        formName: 'hStipulaceCoverage',
        order: 14,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 15,
      },
    ],
  },
  // This is the 6th sheet
  {
    formGroupName: SeaHabitatFormGroup.C,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 1,
        disabled: true,
      },
      {
        header: 'Τύπος οικοτόπου',
        type: 'string',
        formName: 'habType',
        order: 2,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 3,
        disabled: true,
      },
      {
        header: 'Βάθος (m) ανώτερου ορίου εξάπλωσης',
        type: 'float',
        formName: 'higherLimitDepth',
        order: 4,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84) Ανώτερου ορίου εξάπλωσης',
        type: 'float',
        formName: 'latitude',
        order: 5,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84) Ανώτερου ορίου εξάπλωσης',
        type: 'float',
        formName: 'longitude',
        order: 6,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 7,
      },
    ],
  },
  // This is the 7th sheet
  {
    formGroupName: SeaHabitatFormGroup.Struct1170,
    formType: 'formGroup',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 1,
        disabled: true,
      },
      {
        header: 'Τύπος οικοτόπου',
        type: 'string',
        formName: 'habType',
        order: 2,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 3,
        disabled: true,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη EEIc (0-1m) ESG IA',
        type: 'float',
        formName: 'EGSIA01',
        order: 4,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη EEIc (0-1m) ESG IB',
        type: 'float',
        formName: 'EGSIB01',
        order: 5,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη EEIc (0-1m) ESG IC',
        type: 'float',
        formName: 'EGSIC01',
        order: 6,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη EEIc (0-1m) ESG IIA',
        type: 'float',
        formName: 'EGSIIA01',
        order: 7,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη EEIc (0-1m) ESG IIB',
        type: 'float',
        formName: 'EGSIIB01',
        order: 8,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη EEIc (0-1m) Ασπόνδυλα',
        type: 'float',
        formName: 'invertebrate01',
        order: 9,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη EEIc (0-1m) Άλλο',
        type: 'float',
        formName: 'other01',
        order: 10,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη (5m) Δενδρώδης',
        type: 'float',
        formName: 'woody5',
        order: 11,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη (5m) Θαμνώδης',
        type: 'float',
        formName: 'bushy5',
        order: 12,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη (5m) Νηματοειδής/Επιπαγική',
        type: 'float',
        formName: 'filamentousEloquent5',
        order: 13,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη (5m) Ασπόνδυλα',
        type: 'float',
        formName: 'invertebrate5',
        order: 14,
      },
      {
        header: 'Ποσοστιαία Βενθική Κάλυψη (5m) Άλλο',
        type: 'float',
        formName: 'other5',
        order: 15,
      },
    ],
  },
];
