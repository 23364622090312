import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api';
import {
  IDataTableColumns,
  sampleColumns,
  sampleFishColumns,
  sampleFishSpeciesColumns,
  sampleFishThreatsColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  IFishDto,
  ISampleFishSpeciesAndThreatsDto,
  ISampleFishSpeciesDto,
} from 'src/app/core/dtos/fish.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { FishService } from 'src/app/samplings/services/fish.service';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { SearchService } from '../services/search.service';
import { TableWrapperComponent } from '../table-wrapper/table-wrapper.component';
import {
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import { ITableFilterRequest } from '../table/interfaces/table.interfaces';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  fishConfig,
  FishFormGroup,
} from 'src/app/samplings/config-files/fish-config';
import { utils, writeFile } from 'xlsx';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-fish-search',
  templateUrl: './fish-search.component.html',
  styleUrls: ['./fish-search.component.scss'],
})
export class FishSearchComponent extends TableWrapperComponent {
  public sampleFishTotalRecords: number = 0;
  public sampleFish: IFishDto[] = [];
  public sampleFishColumns: IDataTableColumns[] = [];
  public sampleFishFirst = 0;

  public speciesTotalRecords: number = 0;
  public species: ISampleFishSpeciesDto[] = [];
  public speciesColumns: IDataTableColumns[] = [];
  public speciesFirst = 0;

  public threatsTotalRecords: number = 0;
  public threats: ISampleFishSpeciesAndThreatsDto[] = [];
  public threatsColumns: IDataTableColumns[] = [];
  public threatsFirst = 0;

  protected override payload: ITableFilterRequest = {
    samples: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    sampleFish: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    species: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    threats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
  };
  private tablesInitialized: number = 0;

  public activeTabId: number = 0;
  public multiTabGroup = [
    { id: 0, name: 'SEARCH_SAMPLES', isChecked: true },
    { id: 1, name: 'SEARCH_SAMPLES_FISH', isChecked: true },
    { id: 2, name: 'SEARCH_SPECIES', isChecked: true },
    { id: 3, name: 'SEARCH_THREATS', isChecked: true },
  ];

  constructor(
    protected override searchService: SearchService,
    protected override protocolService: ProtocolsService,
    private fishService: FishService,
    protected override fb: FormBuilder,
    protected override translate: TranslateService,
    private modalService: NgbModal,
    protected override toastService: ToastService
  ) {
    super(searchService, protocolService, fb, translate, toastService);
  }

  ngOnInit(): void {
    this.fetchSampleFishColumns();
    this.fetchSampleColumns();
    this.fetchSpeciesColumns();
    this.fetchThreatsColumns();

    this.watchDataversion();
  }

  protected override fetchSampleColumns(): void {
    this.sampleColumns = sampleColumns;
    const sampleColumnsTemp = [...sampleColumns];
    sampleColumnsTemp.forEach((column) => {
      if (
        column.searchDataType === DataType.List &&
        (column.data == null || column.data.length === 0)
      ) {
        if (column.propertyName === 'prefecture') {
          this.protocolService.getPrefectures().subscribe((data) => {
            column.data = data.map((prefecture) => {
              return { id: prefecture.id, name: prefecture.prefecture };
            });
            this.sampleColumns = sampleColumnsTemp;
          });
        }
      }
      if (column.header === 'RANDOM_OBSERVATION') {
        column.shown = false;
        this.sampleColumns = sampleColumnsTemp;
      }
    });
  }

  private fetchSampleFishColumns(): void {
    this.sampleFishColumns = sampleFishColumns;
    forkJoin([
      this.fishService.getEquipment(this.version),
      this.fishService.getEffort(this.version),
      this.fishService.getTurbidity(this.version),
      this.fishService.getStrategyA(this.version),
      this.fishService.getStrategyB(this.version),
      this.fishService.getFlow(this.version),
      this.fishService.getWeather(this.version),
      this.fishService.getVelocity(this.version),
      this.fishService.getHelophytes(this.version),
      this.fishService.getVegetation(this.version),
      this.fishService.getEfficacy(this.version),
    ]).subscribe(
      ([
        ctEquipment,
        ctEffort,
        ctTurbididty,
        ctStrategyA,
        ctStrategyB,
        ctFlow,
        ctWeather,
        ctVelocity,
        ctHelophytes,
        ctVegetation,
        ctEfficacy,
      ]) => {
        if (ctEquipment != null) {
          const samplingEquipment = this.sampleFishColumns.find(
            (item) => item.propertyName === 'samplingEquipment'
          );
          if (samplingEquipment != null) {
            samplingEquipment.data = ctEquipment.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctEffort != null) {
          const samplingEffort = this.sampleFishColumns.find(
            (item) => item.propertyName === 'samplingEffort'
          );
          if (samplingEffort != null) {
            samplingEffort.data = ctEffort.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctTurbididty != null) {
          const turbidity = this.sampleFishColumns.find(
            (item) => item.propertyName === 'turbidity'
          );
          if (turbidity != null) {
            turbidity.data = ctTurbididty.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctStrategyA != null) {
          const samplingStrategyA = this.sampleFishColumns.find(
            (item) => item.propertyName === 'samplingStrategyA'
          );
          if (samplingStrategyA != null) {
            samplingStrategyA.data = ctStrategyA.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctStrategyB != null) {
          const samplingStrategyB = this.sampleFishColumns.find(
            (item) => item.propertyName === 'samplingStrategyB'
          );
          if (samplingStrategyB != null) {
            samplingStrategyB.data = ctStrategyB.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctFlow != null) {
          const flowRegime = this.sampleFishColumns.find(
            (item) => item.propertyName === 'flowRegime'
          );
          if (flowRegime != null) {
            flowRegime.data = ctFlow.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctWeather != null) {
          const weather = this.sampleFishColumns.find(
            (item) => item.propertyName === 'weather'
          );
          if (weather != null) {
            weather.data = ctWeather.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctVelocity != null) {
          const velocityMSec = this.sampleFishColumns.find(
            (item) => item.propertyName === 'velocityMSec'
          );
          if (velocityMSec != null) {
            velocityMSec.data = ctVelocity.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctHelophytes != null) {
          const helophytes = this.sampleFishColumns.find(
            (item) => item.propertyName === 'helophytes'
          );
          if (helophytes != null) {
            helophytes.data = ctHelophytes.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctVegetation != null) {
          const bottomVegetation = this.sampleFishColumns.find(
            (item) => item.propertyName === 'bottomVegetation'
          );
          if (bottomVegetation != null) {
            bottomVegetation.data = ctVegetation.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctEfficacy != null) {
          const efficacyOfHabitatSampling = this.sampleFishColumns.find(
            (item) => item.propertyName === 'efficacyOfHabitatSampling'
          );
          if (efficacyOfHabitatSampling != null) {
            efficacyOfHabitatSampling.data = ctEfficacy.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
      }
    );
  }

  protected override fetchSpeciesColumns(): void {
    this.speciesColumns = sampleFishSpeciesColumns;
    const speciesColumnsTemp = [...sampleFishSpeciesColumns];
    speciesColumnsTemp.forEach((column) => {
      if (column.searchDataType === DataType.List) {
        if (column.propertyName === 'species') {
          this.searchService
            .getSpecies(Protocols.Fish, this.version)
            .subscribe((species) => {
              column.data = species.map((item) => {
                return {
                  id: item.id,
                  name: `(${item.id}) - ${item.name}`,
                };
              });
              this.speciesColumns = speciesColumnsTemp;
            });
        }
      }
    });
  }

  protected override fetchThreatsColumns(): void {
    this.threatsColumns = sampleFishThreatsColumns;
    forkJoin([
      this.searchService.getSpecies(Protocols.Fish, this.version),
    ]).subscribe(([ctSpecies]) => {
      if (ctSpecies != null) {
        const species = this.threatsColumns.find(
          (item) => item.propertyName === 'species'
        );
        if (species != null) {
          species.data = ctSpecies.map((item) => {
            return {
              id: item.id,
              name: `(${item.id}) - ${item.name}`,
            };
          });
        }
      }
    });
  }

  protected override watchDataversion(): void {
    this.subscription.add(
      this.form.controls['dataVersion'].valueChanges.subscribe((version) => {
        // Updating tables.
        const dataVersion = this.payload.samples?.items.find(
          (item) => item.name === 'DataVersion'
        );
        if (dataVersion != null) {
          if (dataVersion.rules != null && dataVersion.rules.length > 0) {
            dataVersion.rules[0].value = version;
          } else {
            dataVersion.rules = [
              {
                value: version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ];
          }
        } else {
          this.payload.samples?.items.push({
            name: 'DataVersion',
            dataType: DataType.String,
            rules: [
              {
                value: this.version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ],
          });
        }

        this.fetchSampleFishColumns();
        this.fetchSampleColumns();
        this.fetchSpeciesColumns();
        this.fetchThreatsColumns();

        this.fetchData();
      })
    );
  }

  protected override fetchData(): void {
    this.searchService.filterFish(this.payload).subscribe((response) => {
      if (response != null) {
        if (response.samples != null) {
          this.sampleTotalRecords = response.samples.totalCount;

          // TODO: Uncomment this when we resolve the time filtering issue.
          // response.samples.rows.forEach(item => {
          //   if (item.time != null) {
          //     const timeString = item.time.toString().slice(0, 5);
          //     item.time = timeString;
          //   }
          // });

          this.samples = response.samples.rows;
        }

        if (response.sampleFish != null) {
          this.sampleFishTotalRecords = response.sampleFish.totalCount;
          this.sampleFish = response.sampleFish.rows;

          this.sampleCoordinates = this.sampleFish
            .filter((item) => item.lat != null && item.long != null)
            .map((item) => {
              return {
                lat: item.lat,
                lng: item.long,
                text: item.samplingCode,
              };
            });
        }

        if (response.speciesFish != null) {
          this.speciesTotalRecords = response.speciesFish.totalCount;
          this.species = response.speciesFish.rows;
        }

        if (response.threatsFish != null) {
          this.threatsTotalRecords = response.threatsFish.totalCount;
          this.threats = response.threatsFish.rows;
        }
      }
    });
  }

  public loadSampleFishData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 4) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(event, sampleFishColumns);
    this.payload.sampleFish = tableFilter;

    if (event.first) {
      this.sampleFishFirst = event.first;
    }

    this.fetchData();
  }

  public loadSpeciesData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 4) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleFishSpeciesColumns
    );
    this.payload.species = tableFilter;

    if (event.first) {
      this.speciesFirst = event.first;
    }

    this.fetchData();
  }

  public loadThreatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 4) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleFishThreatsColumns
    );
    this.payload.threats = tableFilter;

    if (event.first) {
      this.threatsFirst = event.first;
    }

    this.fetchData();
  }

  protected override initializePaginator() {
    this.sampleFirst = 0;
    this.sampleFishFirst = 0;
    this.speciesFirst = 0;
    this.threatsFirst = 0;
  }

  public openExportModal() {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ExportModalComponent, {
      centered: true,
      modalDialogClass: 'export-modal',
      backdrop: 'static',
      keyboard: true,
    });

    if (modalRef) {
      modalRef.result.then((res) => {
        if (res != null) {
          this.exportFiles(res.xlsxFile, res.zipFile);
        }
      });
    }
  }

  public exportFiles(xlsxFile = false, zipFile = false) {
    let payload = { ...this.payload };
    if (payload.samples) {
      payload.samples.rows = this.sampleTotalRecords;
      payload.samples.first = 0;
    }
    if (payload.sampleFish) {
      payload.sampleFish.rows = this.sampleFishTotalRecords;
      payload.sampleFish.first = 0;
    }
    if (payload.species) {
      payload.species.rows = this.speciesTotalRecords;
      payload.species.first = 0;
    }
    if (payload.threats) {
      payload.threats.rows = this.threatsTotalRecords;
      payload.threats.first = 0;
    }

    let samples: any[] = [];
    let speciesFish: any[] = [];
    let threatsFish: any[] = [];

    this.searchService.filterFish(payload).subscribe((response) => {
      const date = new Date();
      const filename =
        'EP2_Export_Fish_' +
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      if (xlsxFile) {
        if (response && response.sampleFish) {
          samples = this.buildExportArray(
            response,
            'sampleFish',
            true,
            fishConfig,
            ''
          );
        }

        if (response && response.speciesFish) {
          speciesFish = this.buildExportArray(
            response,
            'speciesFish',
            false,
            fishConfig,
            FishFormGroup.Species
          );
        }

        if (response && response.threatsFish) {
          threatsFish = this.buildExportArray(
            response,
            'threatsFish',
            false,
            fishConfig,
            FishFormGroup.Threats
          );
        }

        const workbook = utils.book_new();

        const wsSamples = utils.aoa_to_sheet(samples);
        wsSamples['!cols'] = this.fixExportArrayColumnsWidth(samples);
        wsSamples['!rows'] = [{ hpt: 50 }];

        const wsSpeciesFish = utils.aoa_to_sheet(speciesFish);
        wsSpeciesFish['!cols'] = this.fixExportArrayColumnsWidth(speciesFish);
        wsSpeciesFish['!rows'] = [{ hpt: 50 }];

        const wsThreatsFish = utils.aoa_to_sheet(threatsFish);
        wsThreatsFish['!cols'] = this.fixExportArrayColumnsWidth(threatsFish);
        wsThreatsFish['!rows'] = [{ hpt: 50 }];

        utils.book_append_sheet(
          workbook,
          wsSamples,
          this.translate.instant('SPREADSHEET_FISH_SAMPLES')
        );
        utils.book_append_sheet(
          workbook,
          wsSpeciesFish,
          this.translate.instant('SPREADSHEET_FISH_SPECIES')
        );
        utils.book_append_sheet(
          workbook,
          wsThreatsFish,
          this.translate.instant('SPREADSHEET_FISH_THREATS')
        );

        const date = new Date();
        writeFile(workbook, filename + '.xlsx', { compression: true });
      }

      if (zipFile) {
        this.getZip(response, filename);
      }
    });
  }
}
