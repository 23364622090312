import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  IBirdDto,
  IBirdSpeciesDto,
  IBirdThreatsDto,
} from 'src/app/core/dtos/bird.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ISampleIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { timeValidator } from 'src/app/shared/validators/timeValidator';
import { environment } from 'src/environments/environment';
import { SamplesService } from './samples.service';
import { IFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';

@Injectable({
  providedIn: 'root',
})
export class BirdService {
  constructor(
    private http: HttpClient,
    private common: CommonService,
    private sampleService: SamplesService
  ) {}

  public getBirds(dataVersion?: string): Observable<IBirdThreatsDto[]> {
    const url = environment.endpoint + 'Bird/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IBirdThreatsDto[]>(url, { params: params });
  }

  public getBirdById(id: number): Observable<IBirdDto> {
    const url = environment.endpoint + 'Bird';
    const params = new HttpParams().set('id', id);
    return this.http.get<IBirdDto>(url, { params: params });
  }

  public putBird(id: number, payload: IBirdDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Bird';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public getBirdsThreats(dataVersion?: string): Observable<IBirdDto[]> {
    const url = environment.endpoint + 'Bird/threats';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IBirdDto[]>(url, { params: params });
  }

  public getBirdsSpecies(dataVersion?: string): Observable<IBirdSpeciesDto[]> {
    const url = environment.endpoint + 'Bird/species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IBirdSpeciesDto[]>(url, { params: params });
  }

  public addBird(payload: IBirdDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Bird';
    return this.http.post<ISampleIdResponse>(url, payload);
  }

  public addBatchBird(
    payload: IBirdDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Bird/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public addFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Bird/files';
    return this.http.post<void>(url, payload);
  }

  public removeFiles(payload: IFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Bird/remove-files';
    return this.http.post<void>(url, payload);
  }

  public initBirdForm(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);

    sample = fb.group({
      ...sample.controls,
      duration: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      habitat: fb.control(''),
      altitude: fb.control('', [
        Validators.required,
        Validators.min(-10),
        Validators.max(2800),
      ]),
      altitudeEnd: fb.control('', [
        Validators.required,
        Validators.min(-10),
        Validators.max(2800),
      ]),
      method: fb.control(''),
      weatherConditions: fb.control(''),
      topography: fb.control(''),
      cloudCover: fb.control(''),
      windDirection: fb.control(''),
      temperature: fb.control(''),
      windDirectionAzimuth: fb.control(''),
      windIntensity: fb.control('', [Validators.min(0), Validators.max(10)]),
      latEnd: fb.control('', [
        this.common.latLongValidator('latEnd', 'longEnd'),
      ]),
      longEnd: fb.control('', [
        this.common.latLongValidator('latEnd', 'longEnd'),
      ]),
      xEnd: fb.control('', [
        this.common.latLongValidator('xEnd', 'yEnd', true),
      ]),
      yEnd: fb.control('', [
        this.common.latLongValidator('xEnd', 'yEnd', true),
      ]),
      primaryVegetationType: fb.control(''),
      vegetationHeight: fb.control(''),
      coverage: fb.control(''),
      density: fb.control(''),
      quality: fb.control(''),
      species: fb.array([]),
      threats: fb.array([]),
      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
    });
    return sample;
  }

  public initSpeciesFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      species: fb.control('', [Validators.required]),
      time: fb.control('', [Validators.required, timeValidator()]),
      numberOfPeople: fb.control('', [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      age: fb.control('', [Validators.required, Validators.min(0)]),
      gender: fb.control('', [Validators.required]),
      egsax: fb.control('', [Validators.required]),
      egsay: fb.control('', [Validators.required]),
      observations: fb.control(''),
      observationType: fb.control('', [Validators.required]),
      attitude: fb.control('', [Validators.required]),
      pari: fb.control(false),
      parii: fb.control(false),
      pariii: fb.control(false),
    });
  }

  public initThreatsFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      pressuresAndThreatsId: fb.control('', [Validators.required]),
      speciesId: fb.control('', [Validators.required]),
      type: fb.control('', [Validators.required]),
      typeOfThreat: fb.control('', [Validators.required]),
      importance: fb.control('', [Validators.required]),
    });
  }

  public prepareBirdRequest(
    birdForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctSpecies: IBirdSpeciesDto[] = [],
    editMode = false
  ): IBirdDto {
    const bird: IBirdDto = {};
    const species = birdForm.controls['species'] as FormArray;
    const threats = birdForm.controls['threats'] as FormArray;

    bird.date = this.common.formatDate(birdForm?.value.date);
    bird.naturaCode = birdForm?.value.naturaCode;
    bird.samplingCode = birdForm?.value.samplingCode;
    bird.financialInstrument = birdForm?.value.financialInstrument;
    bird.prefecture = birdForm?.value.prefecture;
    bird.researcher = birdForm?.value.researcher;
    bird.time = this.common.formatTime(birdForm?.value.time);
    bird.locality = birdForm?.value.locality;
    bird.observations = birdForm?.value.observations;
    bird.file = birdForm?.value.file;
    bird.gridCell = birdForm?.value.gridCell;
    bird.photo = birdForm?.value.photo;
    bird.duration = birdForm?.value.duration;
    bird.habitat = birdForm?.value.habitat;
    bird.altitude = birdForm?.value.altitude;
    bird.altitudet = birdForm?.value.altitudeEnd;
    bird.method = birdForm?.value.method;
    bird.cloudCover = birdForm?.value.cloudCover;
    bird.windDirection = birdForm?.value.windDirection;
    bird.temperature = birdForm?.value.temperature;
    bird.windDirectionAzimuth = birdForm?.value.windDirectionAzimuth;
    bird.windIntensity = birdForm?.value.windIntensity;
    bird.pointX = birdForm?.value.lat;
    bird.pointY = birdForm?.value.long;
    bird.pointXEnd = birdForm?.value.latEnd;
    bird.pointYEnd = birdForm?.value.longEnd;
    bird.primaryVegetationType = birdForm?.value.primaryVegetationType;
    bird.vegetationHeight = birdForm?.value.vegetationHeight;
    bird.coverage = birdForm?.value.coverage;
    bird.density = birdForm?.value.density;
    bird.quality = birdForm?.value.quality;
    bird.protocolId = Protocols.Bird;

    if (fromSpreadsheet === true) {
      bird.species = birdForm.value.species;
      if (bird.species && bird.species.length > 0) {
        bird.species.forEach((item) => {
          if (item.time != null) {
            item.time = this.common.formatTime(item.time.toString());
          }
          item.species = ctSpecies
            .find((itemInner) => itemInner.code === item.species)
            ?.id?.toString();
        });
      } else {
        bird.species = undefined;
      }

      bird.threats = birdForm.value.threats;
      if (bird.threats && bird.threats.length > 0) {
        bird.threats.forEach((item) => {
          item.speciesId = ctSpecies
            .find((itemInner) => itemInner.code === item.speciesId)
            ?.id?.toString();
        });
      } else {
        bird.threats = undefined;
      }
    } else {
      bird.species = species?.value;
      if (!species?.touched) {
        bird.species = undefined;
      } else {
        bird.species?.forEach((item) => {
          if (item.time != null) {
            item.time = this.common.formatTime(item.time as string);
          }
        });
      }

      bird.threats = threats?.value;
      if (!threats?.touched) {
        bird.threats = undefined;
      }
    }

    return bird;
  }
}
