import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() public formName!: string;
  @Input() public formGroup!: FormGroup;
  @Input() public type?: string = 'text';
  @Input() public label?: string;
  @Input() public placeholder?: string = '';
  @Input() public mask?: string;
  @Input() public suffix?: string;
  @Input() public showMaskTyped?: boolean;
  // We must remove this in a future issue
  // and read directly the control to check
  // if it's required.
  @Input() public required = false;
  @Input() public allowNegativeNumbers = false;

  @Output() public valueInput = new EventEmitter<number | string | null>();

  /**
   *
   */
  public valueUpdate() {
    this.valueInput.emit(this.formGroup.controls[this.formName].value);
  }

  public get isRequired(): boolean {
    return this.formGroup.controls[this.formName].hasValidator(Validators.required);
  }

  public get disabled(): boolean {
    return this.formGroup.controls[this.formName].disabled;
  }

  public get hasErrors(): boolean {
    return this.formGroup.controls[this.formName].errors != null;
  }

  public get errors(): ValidationErrors | null {
    return this.formGroup.controls[this.formName].errors;
  }

  public get touched(): boolean {
    return this.formGroup.controls[this.formName].touched;
  }
}
