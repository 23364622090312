/**
 * Protocols
 */
export const URL_PROTOCOLS = 'protocols';

export const URL_HABITATS = 'habitats';

export const URL_MAMMAL = 'mammal';

export const URL_CHLORIS = 'chloris';

export const URL_AMPHIBIAN = 'amphibian';

export const URL_FISH = 'fish';

export const URL_SEA_SPECIES = 'sea-species';

export const URL_INVERTEBRATE_SPECIES = 'invertebrate-species';

export const URL_BIRD = 'bird';

export const URL_SEA_HABITATS = 'sea-habitats';

export const URL_INVERTEBRATES = 'invertebrate';

export const URL_BIBLIOGRAPHY = 'book';

export const URL_SEARCH = 'search';
