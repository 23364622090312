<div class="app-container">
  <app-header></app-header>
  <app-back *ngIf="router.url !== '/protocols'"></app-back>
  <div class="main-wrapper">
    <router-outlet> </router-outlet>
    <app-footer></app-footer>
  </div>
</div>
<app-loader></app-loader>
<app-simple-toast aria-live="polite" aria-atomic="true"></app-simple-toast>
