import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  forkJoin,
  Subject,
  takeUntil,
  finalize,
  Observable,
} from 'rxjs';
import {
  IInvertebrateDto,
  ISampleInvertebrateSpeciesDto,
  ISampleInvertebrateThreatsDto,
} from 'src/app/core/dtos/invertebrate.dto';
import {
  INaturaSitesDto,
  IPrefecturesDto,
  IHabitatSpeciesDto,
  IHabitatTypesDto,
  ICellCodeDto,
} from 'src/app/core/dtos/protocols.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { ITabBtnGroup } from 'src/app/shared/button-group/button-group.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { ISampleIdResponse } from 'src/app/shared/dtos/generic.dto';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import { InvertebrateService } from '../../services/invertebrate.service';
import { ProtocolsService } from '../../services/protocols.service';
import { IFileDeleteRequest, IFilePayload } from 'src/app/shared/files-container/files-container.component';

@Component({
  selector: 'app-invertebrates',
  templateUrl: './invertebrates.component.html',
  styleUrls: ['./invertebrates.component.scss'],
})
export class InvertebratesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public invertebrate: IInvertebrateDto = {};
  public invertebrateById?: IInvertebrateDto;
  public invertebrateForm!: FormGroup;
  public naturaSites: INaturaSitesDto[] = [];
  public sitesDropdown: IDropdownItem[] = [];
  public prefectures: IPrefecturesDto[] = [];
  public prefecturesDropdown: IDropdownItem[] = [];
  public habitatSpecies: IHabitatSpeciesDto[] = [];
  public habitatSpeciesDropdown: IDropdownItem[] = [];
  public habitatTypes: IHabitatTypesDto[] = [];
  public habitatTypesDropdown: IDropdownItem[] = [];
  public codes: ICellCodeDto[] = [];
  public codesDropdown: IDropdownItem[] = [];
  public financialDropdown: IDropdownItem[] = [
    { id: 'ΥΜΕΠΕΡΑΑ', name: 'ΥΜΕΠΕΡΑΑ' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];
  public tabBtn: ITabBtnGroup[] = [
    { id: 'map', name: 'Επελεξε τοποθεσια', isChecked: true },
    { id: 'coordinates', name: 'Εισαγωγη συντεταγμενων', isChecked: false },
  ];
  public coordSystemDropdown: IDropdownItem[] = [
    { id: 'EGSA', name: 'EGSA' },
    { id: 'WGS84', name: 'WGS84' },
  ];
  public multiTabGroup = [
    { id: 0, name: 'Γεωγραφια', isChecked: true },
    { id: 1, name: 'Παρουσία ειδων', isChecked: true },
    { id: 2, name: 'Πιεσεις-Απειλες', isChecked: true },
  ];
  public predefinedFiles: IFilePayload[] = [];
  public coordinates: ICoordinates | undefined;
  public files: any[] = [];
  public filesToDelete: any[] = [];
  public errors: ValidationErrors[] = [];
  public speciesById: ISampleInvertebrateSpeciesDto[] = [];
  public threatsById: ISampleInvertebrateThreatsDto[] = [];

  public get species(): FormArray {
    return this.invertebrateForm.controls['species'] as FormArray;
  }

  public get threats(): FormArray {
    return this.invertebrateForm.controls['threats'] as FormArray;
  }

  @Input() public protocolId!: number;
  @Input() public sampleId?: number;
  @Input() public readOnlyForm = false;

  constructor(
    private fb: FormBuilder,
    private protocolService: ProtocolsService,
    private invertebrateService: InvertebrateService,
    private router: Router,
    public toastService: ToastService,
    public common: CommonService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      protocolId: number;
    };
    this.protocolId = state?.protocolId;
  }

  ngOnInit(): void {
    this.protocolId = history.state.protocolId;
    this.initInvertebrateForm();

    if (this.sampleId) {
      this.invertebrateService
        .getInvertebrateById(this.sampleId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.getValuesFromServices();
            return;
          })
        )
        .subscribe((res) => {
          if (res != undefined) {
            this.invertebrateById = res as IInvertebrateDto;
            // Fix different property names
            this.invertebrateById.lat = this.invertebrateById.pointX;
            this.invertebrateById.long = this.invertebrateById.pointY;
            this.invertebrateById.latEnd = this.invertebrateById.pointXEnd;
            this.invertebrateById.longEnd = this.invertebrateById.pointYEnd;

            if (res.files != null) {
              this.predefinedFiles = res.files as any;
            }

            // Fix date
            if (this.invertebrateById.date) {
              const convertedDate = new Date(this.invertebrateById.date);
              const dateString =
                convertedDate.getDate().toString().padStart(2, '0') +
                (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                convertedDate.getFullYear().toString();
              this.invertebrateById.date = dateString;
            }
            // Fix time
            if (this.invertebrateById.time) {
              const timeString = this.invertebrateById.time
                .toString()
                .slice(0, 5)
                .replaceAll(':', '');
              this.invertebrateById.time = timeString;
            }
          }
        });
    } else {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.protocolService.getCellCodes(),
      this.protocolService.getNaturaSites(this.invertebrateById?.dataVersion),
      this.protocolService.getPrefectures(this.invertebrateById?.dataVersion),
      this.protocolService.getHabitatSpecies(
        this.invertebrateById?.dataVersion
      ),
    ])
      .pipe(
        finalize(() => {
          if (this.codes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.codes.forEach((code) => {
              dropdown.push({
                id: code.cellCode,
                name: code.cellCode,
              });
            });
            this.codesDropdown = dropdown;
          }

          if (this.naturaSites.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.naturaSites.forEach((site) => {
              dropdown.push({ id: site.naturaCode, name: site.name });
            });
            this.sitesDropdown = dropdown;
          }

          if (this.prefectures.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.prefectures.forEach((prefecture) => {
              dropdown.push({
                id: prefecture.id,
                name: prefecture.prefecture,
              });
            });
            this.prefecturesDropdown = dropdown;
          }

          if (this.habitatSpecies.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.habitatSpecies.forEach((habitat) => {
              dropdown.push({
                id: habitat.code,
                name: habitat.species,
              });
            });
            this.habitatSpeciesDropdown = dropdown;
          }

          if (this.sampleId && this.invertebrateById) {
            this.invertebrateForm.patchValue(this.invertebrateById);
            if (this.invertebrateById.species) {
              this.speciesById = this.invertebrateById.species;
            }
            if (this.invertebrateById.threats) {
              this.threatsById = this.invertebrateById.threats;
            }
            if (this.readOnlyForm === true) {
              this.invertebrateForm.disable();
            }
          }

          return;
        })
      )
      .subscribe(([codes, naturaSites, prefectures, habitatSpecies]) => {
        if (codes != undefined) {
          this.codes = codes as ICellCodeDto[];
        }

        if (naturaSites != undefined) {
          this.naturaSites = naturaSites as INaturaSitesDto[];
        }

        if (prefectures != undefined) {
          this.prefectures = prefectures as IPrefecturesDto[];
        }

        if (habitatSpecies != undefined) {
          this.habitatSpecies = habitatSpecies as IHabitatSpeciesDto[];
        }
      });
  }

  private monitorCoords(): void {
    this.common
      .monitorCoords(
        this.destroy$,
        this.invertebrateForm,
        'x',
        'y',
        'lat',
        'long',
        'xEnd',
        'yEnd',
        'latEnd',
        'longEnd'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICoordinates) => (this.coordinates = data));
  }

  private initInvertebrateForm(): void {
    this.invertebrateForm = this.invertebrateService.initInvertebrateForm(
      this.fb
    );
    this.monitorCoords();
    this.common.monitorCoordSystem(this.invertebrateForm, this.destroy$);
  }

  public get coordSystem(): number {
    return this.invertebrateForm.controls['isWGS84'].value ? 1 : 0;
  }

  public prepareInvertebrateRequest(): void {
    this.errors = this.common.validateAllFormControl(this.invertebrateForm);
    if (this.errors.length > 0) {
      return;
    }

    this.invertebrate = this.invertebrateService.prepareInvertebrateRequest(
      this.invertebrateForm
    );

    if (this.sampleId && !this.readOnlyForm) {
      this.updateSample();
    } else {
      this.registerNewSample();
    }
  }

  public registerNewSample(): void {
    this.invertebrateService
      .addInvertebrate(this.invertebrate)
      .pipe(
        catchError((err) => {
          this.toastService.show('GENERIC_FAILURE_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
          throw err;
        })
      )
      .subscribe((sampleIdResponse: ISampleIdResponse) => {
        if (this.files.length > 0) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append('files', file);
          });

          formData.append('SampleId', sampleIdResponse.sampleId.toString());
          this.invertebrateService
            .addFiles(formData)
            .pipe(
              catchError((err) => {
                this.toastService.show('GENERIC_FAILURE_TEXT', {
                  header: 'GENERIC_FAILURE_HEADER',
                });
                throw err;
              })
            )
            .subscribe(() => {
              this.toastService.show('GENERIC_SUCCESS_TEXT', {
                header: 'GENERIC_SUCCESS_HEADER',
              });

              this.activeModal.close({
                sampleId: sampleIdResponse.sampleId,
                samplingCode: this.invertebrateForm.value.samplingCode,
              });
            });
        } else {
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });

          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.invertebrateForm.value.samplingCode,
          });
        }
      });
  }

  public updateSample(): void {
    if (this.sampleId) {
      this.invertebrateService
        .putInvertebrate(this.sampleId, this.invertebrate)
        .pipe(
          catchError((err) => {
            this.toastService.show('GENERIC_FAILURE_TEXT', {
              header: 'GENERIC_FAILURE_HEADER',
            });
            throw err;
          })
        )
        .subscribe((sampleIdResponse: ISampleIdResponse) => {
          if (this.files != null) {
            // Uploading new files
            const formData = new FormData();
            let addFiles = false;
            this.files.forEach((file: any) => {
              if (file instanceof File) {
                addFiles = true;
                formData.append('files', file);
              }
            });

            if (addFiles) {
              formData.append('SampleId', (this.sampleId as number).toString());
              this.invertebrateService
                .addFiles(formData)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          // Deleting files
          if (this.filesToDelete != null && this.filesToDelete.length > 0) {
            const payload: IFileDeleteRequest = {
              sampleId: this.sampleId as number,
              fileIds: []
            };
            this.filesToDelete.forEach(file => {
              if (file.id != null) {
                payload.fileIds.push(file.id)
              }
            });

            if (payload.fileIds.length > 0) {
              this.invertebrateService
                .removeFiles(payload)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.invertebrateForm.value.samplingCode,
          });
        });
    }
  }

  public confirmClose(): void {
    if (this.invertebrateForm.dirty) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.result.then((data) => {
        if (data) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  /**
   * Change view
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public tabBtnClick(btnId: string) {
    if (btnId === 'map') {
      this.tabBtn[0].isChecked = true;
      this.tabBtn[1].isChecked = false;
    } else {
      this.tabBtn[1].isChecked = true;
      this.tabBtn[0].isChecked = false;
    }
  }
  public submitCoordinates(): void {
    if (this.coordinates != null) {
      this.invertebrateForm.controls['lat'].patchValue(this.coordinates.lat, {
        emitEvent: false,
      });
      this.invertebrateForm.controls['long'].patchValue(this.coordinates.lng, {
        emitEvent: false,
      });

      const coordinates = this.common.convertWGStoEGSA(
        this.coordinates.lat,
        this.coordinates.lng
      );
      this.invertebrateForm.controls['x'].patchValue(coordinates.x, {
        emitEvent: false,
      });
      this.invertebrateForm.controls['y'].patchValue(coordinates.y, {
        emitEvent: false,
      });
    }
  }

  /**
   * Find the new coordinates of the map
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public onMarkerMoved(coordinates: { lat: number; lng: number }): void {
    this.coordinates = coordinates;
    this.submitCoordinates();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
