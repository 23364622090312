<ng-container [formGroup]="formGroup">
  <div formArrayName="threats" class="form-container">
    <ng-container
      *ngFor="let threatsForm of threats.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'pressuresAndThreatsId'"
          [label]="'PRESSURE_THREAT'"
          [dropdownList]="threatsDropdown"
          [required]="true"
          [preDefinedId]="
            getGroup(index).controls['pressuresAndThreatsId'].value
          "
        ></app-dropdown>

        <!-- Deprecated, but let's keep it for future reference -->
        <!-- <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'type'"
          [label]="'TYPE'"
          [dropdownList]="typeDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['type'].value"
        ></app-dropdown> -->
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habType'"
          [label]="'HABITAT_TYPE'"
          [dropdownList]="habitatTypesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['habType'].value"
        ></app-dropdown>
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'timeDetermination'"
          [label]="'TIME_DETERMINATOR'"
          [dropdownList]="timeDeterminationDropdown"
          [preDefinedId]="getGroup(index).controls['timeDetermination'].value"
          [ngClass]="{
            disabled:
              getGroup(index).controls['timeDetermination'].disabled &&
              getGroup(index).controls['timeDetermination'].value === ''
          }"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'influenceIntensity'"
          [label]="'INTENSITY'"
          [dropdownList]="importanceDropdown"
          [preDefinedId]="getGroup(index).controls['influenceIntensity'].value"
          [ngClass]="{
            disabled:
              getGroup(index).controls['influenceIntensity'].disabled &&
              getGroup(index).controls['influenceIntensity'].value === ''
          }"
        ></app-dropdown>
      </div>
      <app-textarea
        [style.min-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>
      <div class="form-row form-3">
        <app-button
          (buttonClicked)="removeThreats(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'REMOVE_THREAT'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== threats.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_THREAT'"
      (buttonClicked)="addNewThreats()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
