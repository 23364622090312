import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IAmphibianDto } from 'src/app/core/dtos/amphibian.dto';
import { IBirdDto } from 'src/app/core/dtos/bird.dto';
import { IBookSpeciesHabitatSpreadDto } from 'src/app/core/dtos/bookSpeciesHabitatSpread.dto';
import { IChlorisDto } from 'src/app/core/dtos/chloris.dto';
import { IFishDto } from 'src/app/core/dtos/fish.dto';
import { IHabitatDto } from 'src/app/core/dtos/habitat.dto';
import { IInvertebrateDto } from 'src/app/core/dtos/invertebrate.dto';
import { IMammalsDto } from 'src/app/core/dtos/mammals.dto';
import { ISeaHabitatsDto } from 'src/app/core/dtos/sea-habitat.dto';
import { ISeaDto } from 'src/app/core/dtos/sea.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { IErrors } from '../spreadsheet-upload/spreadsheet-upload.component';

export interface IImportTables {
  name: string;
  count: number;
}

export interface ILogImports {
  id: string;
  failed: boolean;
  tables: IImportTables[];
}

export enum SpreadsheetReportModalType {
  Error,
  Success,
}

@Component({
  selector: 'app-errors-modal',
  templateUrl: './spreadsheet-report-modal.component.html',
  styleUrls: ['./spreadsheet-report-modal.component.scss'],
})
export class SpreadsheetReportModalComponent implements OnInit {
  @Input() type = SpreadsheetReportModalType.Error;
  @Input() errors?: IErrors[] = [];
  @Input() formErrors: ValidationErrors[] = [];
  @Input() request:
    | IMammalsDto[]
    | IAmphibianDto[]
    | IBirdDto[]
    | IChlorisDto[]
    | IFishDto[]
    | IHabitatDto[]
    | IInvertebrateDto[]
    | ISeaDto[]
    | ISeaHabitatsDto[]
    | IBookSpeciesHabitatSpreadDto[] = [];
  @Input() protocolId = 0;
  public reportType = SpreadsheetReportModalType;
  public imports: ILogImports[] = [];
  public successImports: ILogImports[] = [];
  public failedImports: ILogImports[] = [];
  public selectedColumns: string[] = [];
  public uploadId: number | null = null;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if (this.formErrors.length > 0) {
      this.formErrors.forEach((item) => {
        const keys = Object.keys(item['description']).forEach((key) => {
          if (key === 'max') {
            const res = this.translate.instant('VALIDATOR_MAX_VALUE', {
              value: item['description'][key]['actual'],
              maxValue: item['description'][key]['max'],
            });
            item['description'] = res;
            this.errors?.push(item as IErrors);
          }
          if (key === 'min') {
            const res = this.translate.instant('VALIDATOR_MIN_VALUE', {
              value: item['description'][key]['actual'],
              minValue: item['description'][key]['min'],
            });
            item['description'] = res;
            this.errors?.push(item as IErrors);
          }
          if (key === 'pattern') {
            const res = this.translate.instant('VALIDATOR_PATTERN', {
              value: item['description'][key]['actualValue'],
              pattern: item['description'][key]['requiredPattern'],
            });
            item['description'] = res;
            this.errors?.push(item as IErrors);
          }

          if (key === 'required') {
            const res = this.translate.instant('FIELD_REQUIRED_ERROR');
            item['description'] = res;
            this.errors?.push(item as IErrors);
          }

          if (key === 'duplicatePicket') {
            const res = this.translate.instant('PICKET_ERROR');
            item['description'] = res;
            this.errors?.push(item as IErrors);
          }

          if (key === 'duplicateSpecies') {
            const res = this.translate.instant('AMPHIBIAN_SPECIES_ERROR');
            item['description'] = res;
            this.errors?.push(item as IErrors);
          }
        });
      });
    }

    if (this.request.length > 0) {
      if (this.protocolId === Protocols.Bibliography) {
        this.selectedColumns = ['REPORT_REFERENCES_ENTRIES'];
        (this.request as IBookSpeciesHabitatSpreadDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.entries && item.entries.length > 0) {
            tables.push({
              name: 'REPORT_REFERENCES_ENTRIES',
              count: item.entries.length,
            });
          } else {
            tables.push({ name: 'REPORT_REFERENCES_ENTRIES', count: 0 });
          }
          this.imports.push({
            id: item.referenceId ?? 'REPORT_REFERENCES',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.Amphibian) {
        this.selectedColumns = [
          'REPORT_HABITATS',
          'REPORT_SPECIES',
          'REPORT_SPECIES_THREATS',
        ];
        (this.request as IAmphibianDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.habitats && item.habitats.length > 0) {
            tables.push({
              name: 'REPORT_HABITATS',
              count: item.habitats.length,
            });
          } else {
            tables.push({ name: 'REPORT_HABITATS', count: 0 });
          }
          if (item.species && item.species.length > 0) {
            tables.push({ name: 'REPORT_SPECIES', count: item.species.length });
            tables.push({ name: 'REPORT_SPECIES_THREATS', count: 0 });

            item.species.forEach((speciesItem) => {
              const findObj = tables.filter((obj) => {
                return obj.name === 'REPORT_SPECIES_THREATS';
              });
              if (
                speciesItem.speciesThreats &&
                speciesItem.speciesThreats.length > 0
              ) {
                if (findObj != null && findObj.length > 0) {
                  findObj[0].count += speciesItem.speciesThreats.length;
                } else {
                  tables.push({
                    name: 'REPORT_SPECIES_THREATS',
                    count: speciesItem.speciesThreats.length,
                  });
                }
              } else {
                if (findObj == null || findObj.length === 0) {
                  tables.push({ name: 'REPORT_SPECIES_THREATS', count: 0 });
                }
              }
            });
          } else {
            tables.push({ name: 'REPORT_SPECIES', count: 0 });
            tables.push({ name: 'REPORT_SPECIES_THREATS', count: 0 });
          }

          this.imports.push({
            id: item.samplingCode ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.Bird) {
        this.selectedColumns = ['REPORT_SPECIES', 'REPORT_THREATS'];
        (this.request as IBirdDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.species && item.species.length > 0) {
            tables.push({ name: 'REPORT_SPECIES', count: item.species.length });
          } else {
            tables.push({ name: 'REPORT_SPECIES', count: 0 });
          }
          if (item.threats && item.threats.length > 0) {
            tables.push({ name: 'REPORT_THREATS', count: item.threats.length });
          } else {
            tables.push({ name: 'REPORT_THREATS', count: 0 });
          }
          this.imports.push({
            id: item.samplingCode ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.Chloris) {
        this.selectedColumns = [
          'REPORT_FUNCTIONS',
          'REPORT_THREATS',
          'REPORT_POPULATIONS',
        ];
        (this.request as IChlorisDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.functions && item.functions.length > 0) {
            tables.push({
              name: 'REPORT_FUNCTIONS',
              count: item.functions.length,
            });
          } else {
            tables.push({ name: 'REPORT_FUNCTIONS', count: 0 });
          }
          if (item.pressures && item.pressures.length > 0) {
            tables.push({
              name: 'REPORT_THREATS',
              count: item.pressures.length,
            });
          } else {
            tables.push({ name: 'REPORT_THREATS', count: 0 });
          }
          if (item.populations && item.populations.length > 0) {
            tables.push({
              name: 'REPORT_POPULATIONS',
              count: item.populations.length,
            });
          } else {
            tables.push({ name: 'REPORT_POPULATIONS', count: 0 });
          }
          this.imports.push({
            id: item.samplingCode?.toString() ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.Fish) {
        this.selectedColumns = [
          'REPORT_SPECIES',
          'REPORT_THREATS',
          // 'REPORT_OTHER_THREATS',
        ];
        (this.request as IFishDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.species && item.species.length > 0) {
            tables.push({ name: 'REPORT_SPECIES', count: item.species.length });
          } else {
            tables.push({ name: 'REPORT_SPECIES', count: 0 });
          }
          if (item.threats && item.threats.length > 0) {
            tables.push({ name: 'REPORT_THREATS', count: item.threats.length });
          } else {
            tables.push({ name: 'REPORT_THREATS', count: 0 });
          }
          // if (item.otherThreats && item.otherThreats.length > 0) {
          //   tables.push({
          //     name: 'REPORT_OTHER_THREATS',
          //     count: item.otherThreats.length,
          //   });
          // } else {
          //   tables.push({ name: 'REPORT_OTHER_THREATS', count: 0 });
          // }
          this.imports.push({
            id: item.samplingCode?.toString() ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.Habitats) {
        this.selectedColumns = [
          'REPORT_SPECIES',
          'REPORT_THREATS',
          'REPORT_STRUCTURES',
          'REPORT_IMPACTS',
        ];
        (this.request as IHabitatDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.species && item.species.length > 0) {
            tables.push({ name: 'REPORT_SPECIES', count: item.species.length });
          } else {
            tables.push({ name: 'REPORT_SPECIES', count: 0 });
          }
          if (item.threats && item.threats.length > 0) {
            tables.push({ name: 'REPORT_THREATS', count: item.threats.length });
          } else {
            tables.push({ name: 'REPORT_THREATS', count: 0 });
          }
          if (item.structs && item.structs.length > 0) {
            tables.push({
              name: 'REPORT_STRUCTURES',
              count: item.structs.length,
            });
          } else {
            tables.push({ name: 'REPORT_STRUCTURES', count: 0 });
          }
          if (item.impacts && item.impacts.length > 0) {
            tables.push({ name: 'REPORT_IMPACTS', count: item.impacts.length });
          } else {
            tables.push({ name: 'REPORT_IMPACTS', count: 0 });
          }
          this.imports.push({
            id: item.releveNR?.toString() ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.Invertebrates) {
        this.selectedColumns = ['REPORT_SPECIES', 'REPORT_THREATS'];
        (this.request as IInvertebrateDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.species && item.species.length > 0) {
            tables.push({ name: 'REPORT_SPECIES', count: item.species.length });
          } else {
            tables.push({ name: 'REPORT_SPECIES', count: 0 });
          }
          if (item.threats && item.threats.length > 0) {
            tables.push({ name: 'REPORT_THREATS', count: item.threats.length });
          } else {
            tables.push({ name: 'REPORT_THREATS', count: 0 });
          }
          this.imports.push({
            id: item.samplingCode ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.Mammals) {
        this.selectedColumns = [
          'REPORT_HABITATS',
          'REPORT_SPECIES',
          'REPORT_THREATS',
        ];
        (this.request as IMammalsDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.habitats && item.habitats.length > 0) {
            tables.push({
              name: 'REPORT_HABITATS',
              count: item.habitats.length,
            });
          } else {
            tables.push({ name: 'REPORT_HABITATS', count: 0 });
          }
          if (item.species && item.species.length > 0) {
            tables.push({ name: 'REPORT_SPECIES', count: item.species.length });
          } else {
            tables.push({ name: 'REPORT_SPECIES', count: 0 });
          }
          if (item.threats && item.threats.length > 0) {
            tables.push({ name: 'REPORT_THREATS', count: item.threats.length });
          } else {
            tables.push({ name: 'REPORT_THREATS', count: 0 });
          }
          this.imports.push({
            id: item.samplingCode ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.Sea) {
        this.selectedColumns = [
          'REPORT_SPECIES',
          'REPORT_PINNA_NOBILIS',
          'REPORT_THREATS',
        ];
        (this.request as ISeaDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.species && item.species.length > 0) {
            tables.push({ name: 'REPORT_SPECIES', count: item.species.length });
          } else {
            tables.push({ name: 'REPORT_SPECIES', count: 0 });
          }
          if (item.pinna && item.pinna.length > 0) {
            tables.push({
              name: 'REPORT_PINNA_NOBILIS',
              count: item.pinna.length,
            });
          } else {
            tables.push({ name: 'REPORT_PINNA_NOBILIS', count: 0 });
          }
          if (item.threats && item.threats.length > 0) {
            tables.push({ name: 'REPORT_THREATS', count: item.threats.length });
          } else {
            tables.push({ name: 'REPORT_THREATS', count: 0 });
          }
          this.imports.push({
            id: item.samplingCode ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      if (this.protocolId === Protocols.SeaHabitat) {
        this.selectedColumns = [
          'REPORT_SPECIES',
          'REPORT_THREATS',
          'REPORT_STRUCTURE1120a',
          'REPORT_STRUCTURE1120b',
          'REPORT_STRUCTURE1120g',
          'REPORT_STRUCTURE1170',
        ];
        (this.request as ISeaHabitatsDto[]).forEach((item) => {
          const tables: IImportTables[] = [];
          if (item.species && item.species.length > 0) {
            tables.push({ name: 'REPORT_SPECIES', count: item.species.length });
          } else {
            tables.push({ name: 'REPORT_SPECIES', count: 0 });
          }
          if (item.threats && item.threats.length > 0) {
            tables.push({ name: 'REPORT_THREATS', count: item.threats.length });
          } else {
            tables.push({ name: 'REPORT_THREATS', count: 0 });
          }
          if (item.a && item.a.length > 0) {
            tables.push({
              name: 'REPORT_STRUCTURE1120a',
              count: item.a.length,
            });
          } else {
            tables.push({ name: 'REPORT_STRUCTURE1120a', count: 0 });
          }
          if (item.b && item.b.length > 0) {
            tables.push({
              name: 'REPORT_STRUCTURE1120b',
              count: item.b.length,
            });
          } else {
            tables.push({ name: 'REPORT_STRUCTURE1120b', count: 0 });
          }
          if (item.c && item.c.length > 0) {
            tables.push({
              name: 'REPORT_STRUCTURE1120g',
              count: item.c.length,
            });
          } else {
            tables.push({ name: 'REPORT_STRUCTURE1120g', count: 0 });
          }
          if (item.struct1170) {
            tables.push({ name: 'REPORT_STRUCTURE1170', count: 1 });
          } else {
            tables.push({ name: 'REPORT_STRUCTURE1170', count: 0 });
          }
          this.imports.push({
            id: item.samplingCode ?? '',
            failed: item.failedUpload ?? false,
            tables: tables,
          });
        });
      }

      this.successImports = this.imports.filter((item) => {
        return item.failed === false;
      });

      this.failedImports = this.imports.filter((item) => {
        return item.failed === true;
      });
    }
  }
}
