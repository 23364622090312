import { Injectable } from '@angular/core';
import { AuthService as Auth } from '@auth0/auth0-angular';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable()
export class AuthService {
  public authenticating$ = this.auth.isLoading$;
  public authenticated$ = this.auth.isAuthenticated$;
  public user$ = this.auth.user$;
  public jwtToken$: BehaviorSubject<string>;
  public authSubscription: Subscription | null | undefined;
  constructor(public auth: Auth) {
    this.jwtToken$ = new BehaviorSubject<string>('');
    this.getAuthToken();
  }
  /**
   * Gets auth token if user is logged in
   * passes if not and handdles corresponding subscribe.
   */
  public getAuthToken() {
    this.authenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated && this.authSubscription == null) {
        this.authSubscription = new Subscription();
        this.authSubscription.add(
          this.auth
            .getAccessTokenSilently()
            .pipe()
            .subscribe((token: string) => {
              this.jwtToken$.next(token);
            })
        );
      } else {
        if (this.authSubscription != null) {
          this.authSubscription.unsubscribe();
          this.authSubscription = null;
        }
      }
    });
  }
}
