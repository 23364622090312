import { Injectable } from '@angular/core';
import proj4 from 'proj4';
import pointInPolyton from 'point-in-polygon';
import * as greecePolygon from '../../../assets/greece.json';
import {
  FormGroup,
  FormArray,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public greecePolygon: any[] = [];
  constructor() {
    this.constructPolygon();
  }

  /**
   * Format date for db
   *
   * @param {string} insertedDate the value of the input form
   */
  public formatDate(insertedDate: string): string {
    let res = '';
    if (
      insertedDate != null &&
      insertedDate !== '' &&
      insertedDate.length === 8
    ) {
      const day = insertedDate.slice(0, 2);
      const month = insertedDate.slice(2, 4);
      const year = insertedDate.slice(4);
      res = [year + '-' + month + '-' + day + 'T13:00:00.100Z'].join();
    }

    return res;
  }

  /**
   * Format time for db
   *
   * @param {string} insertedTime the value of the input form
   */
  public formatTime(insertedTime: string): string {
    let time = insertedTime;
    const hours = time.slice(0, 2);
    const minutes = time.slice(2, 4);
    return [hours + ':' + minutes + ':00.100'].join();
  }

  /**
   * Format time to minutes for db
   *
   * @param {string} insertedTime the value of the input form
   */
  public formatTimeToMin(insertedTime: string): string {
    let time = insertedTime;
    const hours = Number(time.slice(0, 2));
    const minutes = Number(time.slice(2, 4));
    if (hours > 0) {
      return (hours * 60 + minutes).toString();
    } else {
      return minutes.toString();
    }
  }

  /**
   * Format minutes to time
   *
   * @param {string} insertedMinutes the value of the minutes
   */
  public formatMinToTime(insertedMinutes: string): string {
    const hours = Math.trunc(parseInt(insertedMinutes) / 60);
    const minutes = parseInt(insertedMinutes) % 60;
    const res =
      hours.toString().padStart(2, '0') + minutes.toString().padStart(2, '0');
    return res === '0000' ? '' : res;
  }

  /**
   * Converts from EGSA to WGS.
   * @param x The x param.
   * @param y The y param.
   * @returns The lat/long.
   */
  public convertEGSAtoWGS(x: number, y: number): { lat: number; long: number } {
    if (x == null || y == null || isNaN(x) || isNaN(y)) {
      return {
        lat: 0,
        long: 0,
      };
    }

    proj4.defs(
      'EPSG:2100',
      '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs'
    );
    proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');

    const coords = [x, y];
    const transformedCoords = proj4('EPSG:2100', 'EPSG:4326', coords);
    return {
      lat: transformedCoords[0],
      long: transformedCoords[1],
    };
  }

  /**
   * Converts from WGS to EGSA.
   * @param lat The lat param.
   * @param long The long param.
   * @returns The x/y.
   */
  public convertWGStoEGSA(lat: number, long: number): { x: number; y: number } {
    proj4.defs(
      'EPSG:2100',
      '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs'
    );
    proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');

    const coords = [lat, long];
    const transformedCoords = proj4('EPSG:4326', 'EPSG:2100', coords);
    return {
      x: transformedCoords[0],
      y: transformedCoords[1],
    };
  }

  public latLongValidator(
    latName: string,
    longName: string,
    isEGSA = false
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
        return null;
      }
      let latValue = control.parent.get(latName)?.value;
      let longValue = control.parent.get(longName)?.value;

      if (!!latValue && !!longValue && isEGSA) {
        const coordinates = this.convertEGSAtoWGS(
          parseFloat(control.parent.get(latName)?.value),
          parseFloat(control.parent.get(longName)?.value)
        );
        latValue = coordinates.lat;
        longValue = coordinates.long;
      }

      if (
        latValue == null ||
        longValue == null ||
        latValue.toString().trim() === '' ||
        longValue.toString().trim() === ''
      ) {
        return null;
      }

      if (
        this.isInGreece([parseFloat(latValue), parseFloat(longValue)]) === false
      ) {
        control.parent.get(latName)?.setErrors({ outOfBound: true });
        control.parent.get(longName)?.setErrors({ outOfBound: true });
        return { outOfBound: true };
      } else {
        control.parent.get(latName)?.setErrors(null);
        control.parent.get(longName)?.setErrors(null);
      }

      return null;
    };
  }

  public monitorCoords(
    destroy$: Subject<void>,
    formGroup: FormGroup<any>,
    x: string,
    y: string,
    lat: string,
    long: string,
    xEnd?: string,
    yEnd?: string,
    latEnd?: string,
    longEnd?: string
  ): Subject<ICoordinates> {
    const coordinatesObservable: Subject<ICoordinates> =
      new Subject<ICoordinates>();

    if (formGroup.controls[x].value !== '') {
      formGroup.controls[x].markAsTouched();
    }
    if (formGroup.controls[y].value !== '') {
      formGroup.controls[y].markAsTouched();
    }
    if (formGroup.controls[lat].value !== '') {
      formGroup.controls[lat].markAsTouched();
    }
    if (formGroup.controls[long].value !== '') {
      formGroup.controls[long].markAsTouched();
    }

    formGroup.controls[x].valueChanges
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        if (
          formGroup?.controls[x]?.value != null &&
          formGroup?.controls[y]?.value != null &&
          formGroup?.controls[x]?.value != '' &&
          formGroup?.controls[y]?.value != ''
        ) {
          const coordinates = this.convertEGSAtoWGS(
            parseFloat(formGroup?.controls[x]?.value),
            parseFloat(formGroup?.controls[y]?.value)
          );
          formGroup?.controls[lat].patchValue(coordinates.lat, {
            emitEvent: false,
          });
          formGroup?.controls[long].patchValue(coordinates.long, {
            emitEvent: false,
          });
          if (this.isInGreece([coordinates.lat, coordinates.long])) {
            coordinatesObservable.next({
              lat: coordinates.lat,
              lng: coordinates.long,
            });
          }
          formGroup.controls[x].markAsTouched();
          formGroup.controls[y].markAsTouched();
          formGroup.controls[lat].markAsTouched();
          formGroup.controls[long].markAsTouched();
        }
      });

    formGroup.controls[y].valueChanges
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        if (
          formGroup?.controls[x]?.value != null &&
          formGroup?.controls[y]?.value != null &&
          formGroup?.controls[x]?.value != '' &&
          formGroup?.controls[y]?.value != ''
        ) {
          const coordinates = this.convertEGSAtoWGS(
            parseFloat(formGroup?.controls[x]?.value),
            parseFloat(formGroup?.controls[y]?.value)
          );
          formGroup?.controls[lat].patchValue(coordinates.lat, {
            emitEvent: false,
          });
          formGroup?.controls[long].patchValue(coordinates.long, {
            emitEvent: false,
          });
          if (this.isInGreece([coordinates.lat, coordinates.long])) {
            coordinatesObservable.next({
              lat: coordinates.lat,
              lng: coordinates.long,
            });
          }
          formGroup.controls[x].markAsTouched();
          formGroup.controls[y].markAsTouched();
          formGroup.controls[lat].markAsTouched();
          formGroup.controls[long].markAsTouched();
        }
      });

    formGroup.controls[lat].valueChanges
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        if (
          formGroup?.controls[lat]?.value != null &&
          formGroup?.controls[long]?.value != null &&
          formGroup?.controls[lat]?.value != '' &&
          formGroup?.controls[long]?.value != ''
        ) {
          const coordinatesEGSA = this.convertWGStoEGSA(
            parseFloat(formGroup?.controls[lat]?.value),
            parseFloat(formGroup?.controls[long]?.value)
          );
          formGroup?.controls[x].patchValue(coordinatesEGSA.x, {
            emitEvent: false,
          });
          formGroup?.controls[y].patchValue(coordinatesEGSA.y, {
            emitEvent: false,
          });
          if (
            this.isInGreece([
              parseFloat(formGroup?.controls[lat]?.value),
              parseFloat(formGroup?.controls[long]?.value),
            ])
          ) {
            coordinatesObservable.next({
              lat: parseFloat(formGroup?.controls[lat]?.value),
              lng: parseFloat(formGroup?.controls[long]?.value),
            });
          }
          formGroup.controls[x].markAsTouched();
          formGroup.controls[y].markAsTouched();
          formGroup.controls[lat].markAsTouched();
          formGroup.controls[long].markAsTouched();
        }
      });

    formGroup.controls[long].valueChanges
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        if (
          formGroup?.controls[lat]?.value != null &&
          formGroup?.controls[long]?.value != null &&
          formGroup?.controls[lat]?.value != '' &&
          formGroup?.controls[long]?.value != ''
        ) {
          const coordinatesEGSA = this.convertWGStoEGSA(
            parseFloat(formGroup?.controls[lat]?.value),
            parseFloat(formGroup?.controls[long]?.value)
          );
          formGroup?.controls[x].patchValue(coordinatesEGSA.x, {
            emitEvent: false,
          });
          formGroup?.controls[y].patchValue(coordinatesEGSA.y, {
            emitEvent: false,
          });
          if (
            this.isInGreece([
              parseFloat(formGroup?.controls[lat]?.value),
              parseFloat(formGroup?.controls[long]?.value),
            ])
          ) {
            coordinatesObservable.next({
              lat: parseFloat(formGroup?.controls[lat]?.value),
              lng: parseFloat(formGroup?.controls[long]?.value),
            });
          }
          formGroup.controls[x].markAsTouched();
          formGroup.controls[y].markAsTouched();
          formGroup.controls[lat].markAsTouched();
          formGroup.controls[long].markAsTouched();
        }
      });

    if (
      xEnd !== undefined &&
      yEnd !== undefined &&
      latEnd !== undefined &&
      longEnd !== undefined
    ) {
      formGroup.controls[xEnd].valueChanges
        .pipe(takeUntil(destroy$))
        .subscribe(() => {
          if (
            formGroup?.controls[xEnd]?.value != null &&
            formGroup?.controls[yEnd]?.value != null &&
            formGroup?.controls[xEnd]?.value != '' &&
            formGroup?.controls[yEnd]?.value != ''
          ) {
            const coordinates = this.convertEGSAtoWGS(
              parseFloat(formGroup?.controls[xEnd]?.value),
              parseFloat(formGroup?.controls[yEnd]?.value)
            );

            formGroup?.controls[latEnd].patchValue(coordinates.lat, {
              emitEvent: false,
            });
            formGroup?.controls[longEnd].patchValue(coordinates.long, {
              emitEvent: false,
            });

            formGroup.controls[xEnd].markAsTouched();
            formGroup.controls[yEnd].markAsTouched();
            formGroup.controls[latEnd].markAsTouched();
            formGroup.controls[longEnd].markAsTouched();
          }
        });

      formGroup.controls[yEnd].valueChanges
        .pipe(takeUntil(destroy$))
        .subscribe(() => {
          if (
            formGroup?.controls[xEnd]?.value != null &&
            formGroup?.controls[yEnd]?.value != null &&
            formGroup?.controls[xEnd]?.value != '' &&
            formGroup?.controls[yEnd]?.value != ''
          ) {
            const coordinates = this.convertEGSAtoWGS(
              parseFloat(formGroup?.controls[xEnd]?.value),
              parseFloat(formGroup?.controls[yEnd]?.value)
            );
            formGroup?.controls[latEnd].patchValue(coordinates.lat, {
              emitEvent: false,
            });
            formGroup?.controls[longEnd].patchValue(coordinates.long, {
              emitEvent: false,
            });

            formGroup.controls[xEnd].markAsTouched();
            formGroup.controls[yEnd].markAsTouched();
            formGroup.controls[latEnd].markAsTouched();
            formGroup.controls[longEnd].markAsTouched();
          }
        });

      formGroup.controls[latEnd].valueChanges
        .pipe(takeUntil(destroy$))
        .subscribe(() => {
          if (
            formGroup?.controls[latEnd]?.value != null &&
            formGroup?.controls[longEnd]?.value != null &&
            formGroup?.controls[latEnd]?.value != '' &&
            formGroup?.controls[longEnd]?.value != ''
          ) {
            const coordinatesEGSA = this.convertWGStoEGSA(
              parseFloat(formGroup?.controls[latEnd]?.value),
              parseFloat(formGroup?.controls[longEnd]?.value)
            );
            formGroup?.controls[xEnd].patchValue(coordinatesEGSA.x, {
              emitEvent: false,
            });
            formGroup?.controls[yEnd].patchValue(coordinatesEGSA.y, {
              emitEvent: false,
            });

            formGroup.controls[xEnd].markAsTouched();
            formGroup.controls[yEnd].markAsTouched();
            formGroup.controls[latEnd].markAsTouched();
            formGroup.controls[longEnd].markAsTouched();
          }
        });

      formGroup.controls[longEnd].valueChanges
        .pipe(takeUntil(destroy$))
        .subscribe(() => {
          if (
            formGroup?.controls[latEnd]?.value != null &&
            formGroup?.controls[longEnd]?.value != null &&
            formGroup?.controls[latEnd]?.value != '' &&
            formGroup?.controls[longEnd]?.value != ''
          ) {
            const coordinatesEGSA = this.convertWGStoEGSA(
              parseFloat(formGroup?.controls[latEnd]?.value),
              parseFloat(formGroup?.controls[longEnd]?.value)
            );
            formGroup?.controls[xEnd].patchValue(coordinatesEGSA.x, {
              emitEvent: false,
            });
            formGroup?.controls[yEnd].patchValue(coordinatesEGSA.y, {
              emitEvent: false,
            });

            formGroup.controls[xEnd].markAsTouched();
            formGroup.controls[yEnd].markAsTouched();
            formGroup.controls[latEnd].markAsTouched();
            formGroup.controls[longEnd].markAsTouched();
          }
        });
    }

    return coordinatesObservable;
  }

  public isInGreece(coords: number[]): boolean {
    let res = true;
    if (isNaN(coords[0]) && isNaN(coords[1])) {
      res = true;
    } else {
      res = pointInPolyton(coords, this.greecePolygon);
    }
    return res;
  }

  private constructPolygon(): void {
    this.greecePolygon = [];
    // The parse-stringify is needed to bypass linter issues.
    (JSON.parse(JSON.stringify(greecePolygon)).features as any[]).forEach(
      (feature) => {
        (feature.geometry.coordinates as any[]).forEach((coordinate) => {
          this.greecePolygon.push([coordinate[1], coordinate[0]]);
        });
      }
    );
  }

  public validateAllFormControl(
    formGroup: FormGroup | FormArray,
    errors: ValidationErrors[] = []
  ): ValidationErrors[] {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        if (control.errors) {
          let err: ValidationErrors = {};
          err = control.errors;
          err['title'] = field;
          errors.push(err);
        }
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.validateAllFormControl(control, errors);
      }
    });

    return errors;
  }

  public monitorCoordSystem(
    formGroup: FormGroup,
    destroy$: Subject<void>
  ): void {
    const options = { emitEvent: false, onlySelf: true };
    formGroup.controls['isWGS84'].valueChanges
      .pipe(takeUntil(destroy$))
      .subscribe((value) => {
        if (value) {
          formGroup.controls['isEGSA'].patchValue(!value, options);
        } else {
          formGroup.controls['isWGS84'].patchValue(true, options);
        }
      });

    formGroup.controls['isEGSA'].valueChanges
      .pipe(takeUntil(destroy$))
      .subscribe((value) => {
        if (value) {
          formGroup.controls['isWGS84'].patchValue(!value, options);
        } else {
          formGroup.controls['isEGSA'].patchValue(true, options);
        }
      });
  }
}
