import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, takeUntil, finalize } from 'rxjs';
import {
  IFishPressureValueDto,
  IFishThreatsDto,
  IMainCTSDto,
  ISampleFishSpeciesAndThreatsDto,
} from 'src/app/core/dtos/fish.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { FishService } from '../../services/fish.service';

@Component({
  selector: 'app-fish-threats',
  templateUrl: './fish-threats.component.html',
  styleUrls: ['./fish-threats.component.scss'],
})
export class FishThreatsComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctThreats: IFishThreatsDto[] = [];
  public threatsForm!: FormGroup;
  public threatsDropdown: IDropdownItem[] = [];
  public ctSpecies: IMainCTSDto[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public typeDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('TYPE_P'),
      name: this.translate.instant('TYPE_P'),
    },
    {
      id: this.translate.instant('TYPE_T'),
      name: this.translate.instant('TYPE_T'),
    },
  ];
  public extendDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('EXTEND_SEGMENT'),
      name: this.translate.instant('EXTEND_SEGMENT'),
    },
    {
      id: this.translate.instant('EXTEND_SITE'),
      name: this.translate.instant('EXTEND_SITE'),
    },
  ];

  public populationPercentageDropdown: IDropdownItem[] = [
    { id: 'Ολόκληρος >90%', name: 'Ολόκληρος >90%' },
    { id: 'Πλειονότητα 50-90%', name: 'Πλειονότητα 50-90%' },
    { id: 'Μειονότητα <50%', name: 'Μειονότητα <50%' },
  ];

  public impactDropdown: IDropdownItem[] = [
    { id: 'L', name: 'Low' },
    { id: 'M', name: 'Medium' },
    { id: 'H', name: 'High' },
  ];

  public timeCharacterDropdown: IDropdownItem[] = [
    {
      id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
    },
    { id: 'Στο παρόν', name: 'Στο παρόν' },
    { id: 'Στο παρόν και στο μέλλον', name: 'Στο παρόν και στο μέλλον' },
    { id: 'Μόνο στο μέλλον', name: 'Μόνο στο μέλλον' },
  ];

  public ctImportance: IFishPressureValueDto[] = [];
  public importanceDropdown: IDropdownItem[] = [];

  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: ISampleFishSpeciesAndThreatsDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;
  @Input() public selectedSpecies: any[] = [];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private fishService: FishService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.threatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }

    if (changes['selectedSpecies']) {
      this.speciesDropdown = [];
      this.selectedSpecies.forEach((species) => {
        if (species) {
          const actualSpecies = this.ctSpecies.find(
            (item) => item.id === parseInt(species.species)
          );
          if (actualSpecies != null) {
            if (actualSpecies.code === 'Άλλο') {
              this.speciesDropdown.push({
                id: species.other,
                name: species.other,
                actualId: actualSpecies.id,
              });
            } else {
              this.speciesDropdown.push({
                id: actualSpecies.code,
                name: actualSpecies.code,
                actualId: actualSpecies.id,
              });
            }
          }
        }
      });
      this.threats.controls.forEach((control, index) => {
        const ctrl = control as FormGroup;
        const species = this.selectedSpecies.find(
          (item) =>
            item.species === ctrl.controls['species'].value &&
            (item.other === ctrl.controls['otherSpecies'].value ||
              (item.other == null &&
                ctrl.controls['otherSpecies'].value === ''))
        );
        if (species == null) {
          this.threats.removeAt(index);
        }
      });
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.fishService.getThreats(this.dataVersion),
      this.fishService.getPressureValue(this.dataVersion),
      this.fishService.getSpecies(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.ctThreats.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctThreats.forEach((threat) => {
              dropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
            this.threatsDropdown = dropdown;
          }

          if (this.ctImportance.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctImportance.forEach((importance) => {
              dropdown.push({
                id: importance.code,
                name: importance.code,
              });
            });
            this.importanceDropdown = dropdown;
          }

          // if (this.ctSpecies.length > 0) {
          //   const dropdown: IDropdownItem[] = [];
          //   this.ctSpecies.forEach((species) => {
          //     dropdown.push({
          //       id: species.code,
          //       name: species.code,
          //       actualId: species.id,
          //     });
          //   });
          //   this.speciesDropdown = dropdown;
          // }

          if (this.selectedSpecies.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.selectedSpecies.forEach((species) => {
              if (species) {
                const actualSpecies = this.ctSpecies.find(
                  (item) => item.id === parseInt(species.species)
                );
                if (actualSpecies != null) {
                  if (actualSpecies.code === 'Άλλο') {
                    dropdown.push({
                      id: species.other,
                      name: species.other,
                      actualId: actualSpecies.id,
                    });
                  } else {
                    dropdown.push({
                      id: actualSpecies.code,
                      name: actualSpecies.code,
                      actualId: actualSpecies.id,
                    });
                  }
                }
                this.speciesDropdown = dropdown;
              }
            });
          }

          if (this.threatsById.length > 0) {
            this.threatsById.forEach((threat) => {
              this.addNewThreats();
              if (threat.species === 'Άλλο' || threat.species == null) {
                threat.species = threat.otherSpecies;
              }
            });
            this.threats.patchValue(this.threatsById);
            this.threats.markAsTouched();
            this.threatsById = [];
            if (this.disabledControls === true) {
              this.threats.disable();
            }
          }
          return;
        })
      )
      .subscribe(([ctThreats, ctImportance, ctSpecies]) => {
        if (ctThreats != undefined) {
          this.ctThreats = ctThreats as IFishThreatsDto[];
        }

        if (ctImportance != undefined) {
          this.ctImportance = ctImportance as IFishPressureValueDto[];
        }

        if (ctSpecies != undefined) {
          this.ctSpecies = ctSpecies as IMainCTSDto[];
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    this.threats.push(this.fishService.initThreatsFormGroup(this.fb));
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  public updateOtherSpecies(otherSpecies: string, index: number): void {
    const species = this.ctSpecies.find(
      (item) =>
        item.id ===
        (this.threats.controls[index] as FormGroup).controls['species'].value
    );
    if (species != null && species.code === 'Άλλο') {
      (this.threats.controls[index] as FormGroup).controls[
        'otherSpecies'
      ].setValue(otherSpecies, { emitEvent: false });
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
