import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import * as L from 'leaflet';
import { Guid } from 'guid-typescript';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

export interface ICoordinates {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, AfterViewChecked {
  @Output() markerMoved = new EventEmitter<ICoordinates>();
  @Input() coordinates!: ICoordinates | undefined;
  @Input() idOfMap?: string;
  @Input() disabled = false;
  @Input() spaLayer = false;

  private map!: L.Map;
  private marker!: L.Marker;
  public mapId: string;
  private basicLayer!: L.TileLayer;
  private secondaryLayer!: L.TileLayer;
  private alternativeLayer!: L.TileLayer;
  private realLayer!: L.TileLayer;
  private gridLayer!: L.TileLayer;
  private naturaLayerSCI!: L.TileLayer;
  private naturaLayerSPA!: L.TileLayer;
  private seaLayer!: L.TileLayer;
  private landLayer!: L.TileLayer;

  public gridLayerToggle: boolean = false;
  public naturaLayerSCIToggle: boolean = false;
  public naturaLayerSPAToggle: boolean = false;
  public seaLayerToggle: boolean = false;
  public landLayerToggle: boolean = false;

  public layerControl: L.Control.Layers = new L.Control.Layers();

  // Options for the marker
  private markerIcon = L.icon({
    iconUrl: 'assets/images/location-icon.png',
    shadowUrl: '',
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });

  private markerOptions = {
    icon: this.markerIcon,
    title: 'MyLocation',
    clickable: true,
    draggable: false,
  };

  constructor(private commonService: CommonService) {
    this.mapId = this.idOfMap ? this.idOfMap : Guid.create().toString();
  }

  ngAfterViewChecked(): void {
    this.map.invalidateSize(true);
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.addMarker();
    if (this.disabled === true) {
      this.map.removeEventListener('click');
    }
  }

  ngOnChanges(changes: any): void {
    if (
      (changes.coordinates?.previousValue?.lat !== null &&
        typeof changes.coordinates?.previousValue?.lat !== 'string' &&
        changes?.coordinates?.currentValue?.lat !== null &&
        typeof changes?.coordinates?.currentValue?.lat !== 'string' &&
        Math.abs(
          changes.coordinates?.previousValue?.lat ??
            0 - changes?.coordinates?.currentValue?.lat ??
            0
        ) >= 0.0000000000000001) ||
      (changes.coordinates?.previousValue?.lng !== null &&
        typeof changes.coordinates?.previousValue?.lng !== 'string' &&
        changes?.coordinates?.currentValue?.lng !== null &&
        typeof changes?.coordinates?.currentValue?.lng !== 'string' &&
        Math.abs(
          changes.coordinates?.previousValue?.lng ??
            0 - changes?.coordinates?.currentValue?.lng ??
            0
        ) >= 0.0000000000000001)
    ) {
      if (this.marker != null) {
        this.marker.remove();
      }

      if (!changes.coordinates?.isFirstChange()) {
        this.addMarker();
      }
    }
  }

  /**
   * Initializes the needed map layers.
   */
  private initializeLayers(): void {
    this.basicLayer = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 17,
      }
    );

    this.secondaryLayer = L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 17,
      }
    );

    this.alternativeLayer = L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 17,
      }
    );

    this.realLayer = L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 17,
      }
    );

    this.naturaLayerSCI = L.tileLayer(
      `${environment.endpoint}tiles/${'sci'}/{z}/{x}/{y}.png`,
      {
        maxZoom: 14,
        minZoom: 6,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );

    this.naturaLayerSPA = L.tileLayer(
      `${environment.endpoint}tiles/${'spa'}/{z}/{x}/{y}.png`,
      {
        maxZoom: 14,
        minZoom: 6,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );

    this.gridLayer = L.tileLayer(
      `${environment.endpoint}tiles/grid/{z}/{x}/{y}.png`,
      {
        maxZoom: 14,
        minZoom: 8,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );

    this.seaLayer = L.tileLayer(
      `${environment.endpoint}tiles/sea/{z}/{x}/{y}.png`,
      {
        maxZoom: 11,
        minZoom: 7,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );

    this.landLayer = L.tileLayer(
      `${environment.endpoint}tiles/land/{z}/{x}/{y}.png`,
      {
        maxZoom: 11,
        minZoom: 7,
        tms: false,
        attribution: 'Generated by QTiles',
      }
    );
  }

  private initMap() {
    this.initializeLayers();
    this.map = L.map(`map-${this.mapId}`).setView([37.98381, 23.727539], 6);
    this.basicLayer.addTo(this.map);
    L.control.scale().addTo(this.map);

    this.layerControl = L.control.layers(
      {
        '<span style="margin-left: 8px"></span>Χάρτης - Βασικός':
          this.basicLayer,
        '<span style="margin-left: 8px"></span>Χάρτης - Βασικός 2':
          this.secondaryLayer,
        '<span style="margin-left: 8px"></span>Χάρτης - Εναλλακτικός':
          this.alternativeLayer,
        '<span style="margin-left: 8px"></span>Χάρτης - Ρεαλιστικός':
          this.realLayer,
      },
      {
        '<span style="width: 8px;height: 8px;display: inline-block;margin-left: 8px;margin-right: 8px;border: 1px dotted #000;border-top-right-radius: 2px;border-bottom-left-radius: 2px;background-color: #1d1de199;"></span>Περιοχές Natura SCI':
          this.naturaLayerSCI,
        '<span style="width: 8px;height: 8px;display: inline-block;margin-left: 8px;margin-right: 8px;border: 1px dotted #000;border-top-right-radius: 2px;border-bottom-left-radius: 2px;background-color: #ef2140a6;"></span>Περιοχές Natura SPA':
          this.naturaLayerSPA,
        '<span style="width: 8px;height: 8px;display: inline-block;margin-left: 8px;margin-right: 8px;font-weight: bold;">#</span>Grid Cells':
          this.gridLayer,
      }
    );

    this.layerControl.addTo(this.map);

    this.map.on('click', (event: L.LeafletMouseEvent) => {
      const pointsCoord = [event.latlng.lat, event.latlng.lng];
      if (this.commonService.isInGreece(pointsCoord)) {
        this.marker.setLatLng(event.latlng);
        const coordinates: ICoordinates = {
          lat: event.latlng.lat,
          lng: event.latlng.lng,
        };
        this.markerMoved.emit(coordinates);
      }
    });
  }

  /**
   * Toggles the natura SCI layer.
   */
  public toggleNaturaLayerSCI(): void {
    if (this.naturaLayerSCIToggle) {
      this.naturaLayerSCI.addTo(this.map);
    } else {
      this.map.removeLayer(this.naturaLayerSCI);
    }
  }

  /**
   * Toggles the natura SPA layer.
   */
  public toggleNaturaLayerSPA(): void {
    if (this.naturaLayerSPAToggle) {
      this.naturaLayerSPA.addTo(this.map);
    } else {
      this.map.removeLayer(this.naturaLayerSPA);
    }
  }

  /**
   * Toggles the grid layer.
   */
  public toggleGridLayer(): void {
    if (this.gridLayerToggle) {
      this.gridLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.gridLayer);
    }
  }

  /**
   * Toggles the sea layer.
   */
  public toggleSeaLayer(): void {
    if (this.seaLayerToggle) {
      this.seaLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.seaLayer);
    }
  }

  /**
   * Toggles the land layer.
   */
  public toggleLandLayer(): void {
    if (this.landLayerToggle) {
      this.landLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.landLayer);
    }
  }

  private addMarker() {
    if (
      this.coordinates != null &&
      this.coordinates.lat != null &&
      this.coordinates.lng != null
    ) {
      this.marker = L.marker(
        [this.coordinates.lat, this.coordinates.lng],
        this.markerOptions
      ).addTo(this.map);
    } else {
      this.marker = L.marker([37.98381, 23.727539], this.markerOptions).addTo(
        this.map
      );
    }
  }
}
