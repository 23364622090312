import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  IFishDto,
  IFishPressureValueDto,
  IFishSiteListDto,
  IFishThreatsDto,
  IMainCTSDto,
} from 'src/app/core/dtos/fish.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ISampleIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { environment } from 'src/environments/environment';
import { SamplesService } from './samples.service';
import { IFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';

@Injectable({
  providedIn: 'root',
})
export class FishService {
  constructor(
    private http: HttpClient,
    public common: CommonService,
    private sampleService: SamplesService
  ) {}

  public getFishes(dataVersion?: string): Observable<IFishDto[]> {
    const url = environment.endpoint + 'Fish/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IFishDto[]>(url, { params: params });
  }

  public getFishById(id: number): Observable<IFishDto> {
    const url = environment.endpoint + 'Fish';
    const params = new HttpParams().set('id', id);
    return this.http.get<IFishDto>(url, { params: params });
  }

  public putFish(id: number, payload: IFishDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Fish';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public addFish(payload: IFishDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Fish';
    return this.http.post<ISampleIdResponse>(url, payload);
  }

  public addBatchFish(
    payload: IFishDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Fish/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public addFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Fish/files';
    return this.http.post<void>(url, payload);
  }

  public removeFiles(payload: IFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Fish/remove-files';
    return this.http.post<void>(url, payload);
  }

  public getSpecies(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getPressureValue(
    dataVersion?: string
  ): Observable<IFishPressureValueDto[]> {
    const url = environment.endpoint + 'Fish/pressure';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IFishPressureValueDto[]>(url, { params: params });
  }

  public getThreats(dataVersion?: string): Observable<IFishThreatsDto[]> {
    const url = environment.endpoint + 'Fish/threats';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IFishThreatsDto[]>(url, { params: params });
  }

  public getEfficacy(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/efficacy';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getVegetation(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/vegetation';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getHelophytes(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/helophytes';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getTurbidity(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/turbidity';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getVelocity(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/velocity';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getWeather(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/weather';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getFlow(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/flow';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getStrategyB(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/strategyB';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getStrategyA(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/strategyA';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getEffort(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/effort';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getEquipment(dataVersion?: string): Observable<IMainCTSDto[]> {
    const url = environment.endpoint + 'Fish/equipment';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMainCTSDto[]>(url, { params: params });
  }

  public getSiteList(dataVersion?: string): Observable<IFishSiteListDto[]> {
    const url = environment.endpoint + 'Fish/sites';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IFishSiteListDto[]>(url, { params: params });
  }

  public initFishFormGroup(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);

    sample = fb.group({
      ...sample.controls,
      duration: fb.control('', [
        Validators.min(0),
        Validators.max(1440),
        Validators.pattern(/^\d+$/),
      ]),
      fisher: fb.control(''),
      completedBy: fb.control(''),
      altitude: fb.control('', [
        Validators.required,
        Validators.min(0),
        Validators.max(2800),
      ]),
      siteCode: fb.control(''),
      siteCodeText: fb.control(''),
      course: fb.control(''),
      ecoRegion: fb.control(''),
      hydrographicBasin: fb.control(''),
      researchProgram: fb.control(''),
      referenceSiteEst: fb.control(''),
      slope: fb.control('', [Validators.min(0), Validators.max(100)]),
      samplingEquipment: fb.control('', [Validators.required]),
      samplingEffort: fb.control('', [Validators.required]),
      samplingEffortComments: fb.control(''),
      samplingStrategyA: fb.control('', [Validators.required]),
      samplingStrategyB: fb.control('', [Validators.required]),
      fishedLength: fb.control('', [Validators.min(0), Validators.max(100000)]),
      fishedArea: fb.control('', [Validators.min(0), Validators.max(1000000)]),
      flowRegime: fb.control('', [Validators.required]),
      wettedWidthM: fb.control('', [Validators.min(0), Validators.max(100000)]),
      leftBankM: fb.control('', [Validators.min(0), Validators.max(100000)]),
      rightBankM: fb.control('', [Validators.min(0), Validators.max(100000)]),
      width1: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['width1', 'width5', 'width10', 'width20', 'widthGT20'],
          'overHundredWidth'
        ),
      ]),
      width5: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['width1', 'width5', 'width10', 'width20', 'widthGT20'],
          'overHundredWidth'
        ),
      ]),
      width10: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['width1', 'width5', 'width10', 'width20', 'widthGT20'],
          'overHundredWidth'
        ),
      ]),
      width20: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['width1', 'width5', 'width10', 'width20', 'widthGT20'],
          'overHundredWidth'
        ),
      ]),
      widthGT20: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['width1', 'width5', 'width10', 'width20', 'widthGT20'],
          'overHundredWidth'
        ),
      ]),
      depth025: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['depth025', 'depth05', 'depth1', 'depthGT1'],
          'overHundredDepth'
        ),
      ]),
      depth05: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['depth025', 'depth05', 'depth1', 'depthGT1'],
          'overHundredDepth'
        ),
      ]),
      depth1: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['depth025', 'depth05', 'depth1', 'depthGT1'],
          'overHundredDepth'
        ),
      ]),
      depthGT1: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          ['depth025', 'depth05', 'depth1', 'depthGT1'],
          'overHundredDepth'
        ),
      ]),
      depthMean: fb.control('', [Validators.min(0), Validators.max(10000)]),
      depthMax: fb.control('', [Validators.min(0), Validators.max(10000)]),
      subsRock: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsBoulder: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsCobble: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsPebble: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsGravel: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsSand: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsSilt: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsClay: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsOrganic: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      subsArtificial: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'subsRock',
            'subsBoulder',
            'subsCobble',
            'subsPebble',
            'subsGravel',
            'subsSand',
            'subsSilt',
            'subsClay',
            'subsOrganic',
            'subsArtificial',
          ],
          'overHundredSubs'
        ),
      ]),
      shadeness: fb.control('', [Validators.min(0), Validators.max(100)]),
      weather: fb.control('', [Validators.required]),
      velocityMSec: fb.control('', [Validators.required]),
      conductivitymScm: fb.control('', [
        Validators.min(0),
        Validators.max(10000),
      ]),
      o2mgL: fb.control('', [Validators.min(0), Validators.max(10000)]),
      pH: fb.control('', [Validators.min(0), Validators.max(14)]),
      tofAir: fb.control('', [Validators.min(-10), Validators.max(45)]),
      tofWater: fb.control('', [Validators.min(-10), Validators.max(45)]),
      salinityPpt: fb.control('', [Validators.min(0), Validators.max(10000)]),
      turbidity: fb.control('', [Validators.required]),
      helophytes: fb.control('', [Validators.required]),
      helophytesDominatingSp: fb.control(''),
      bottomVegetation: fb.control('', [Validators.required]),
      bottomVegetationDominatingSp: fb.control(''),
      habTypePool: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'habTypePool',
            'habTypeGlide',
            'habTypeRun',
            'habTypeRiffle',
            'habTypeRapid',
          ],
          'overHundredHab'
        ),
      ]),
      habTypeGlide: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'habTypePool',
            'habTypeGlide',
            'habTypeRun',
            'habTypeRiffle',
            'habTypeRapid',
          ],
          'overHundredHab'
        ),
      ]),
      habTypeRun: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'habTypePool',
            'habTypeGlide',
            'habTypeRun',
            'habTypeRiffle',
            'habTypeRapid',
          ],
          'overHundredHab'
        ),
      ]),
      habTypeRiffle: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'habTypePool',
            'habTypeGlide',
            'habTypeRun',
            'habTypeRiffle',
            'habTypeRapid',
          ],
          'overHundredHab'
        ),
      ]),
      habTypeRapid: fb.control('', [
        Validators.min(0),
        Validators.max(100),
        this.fullPercentageValidator(
          [
            'habTypePool',
            'habTypeGlide',
            'habTypeRun',
            'habTypeRiffle',
            'habTypeRapid',
          ],
          'overHundredHab'
        ),
      ]),
      habTypeOther: fb.control(''),
      importantPressures: fb.control(''),
      logsLargeWoodyDebris: fb.control(false),
      overhangingVegetation: fb.control(false),
      denseMacrophyteBeds: fb.control(false),
      deepPools: fb.control(false),
      bouldersCobbles: fb.control(false),
      undercutBanks: fb.control(false),
      thickRootMats: fb.control(false),
      marshyFringes: fb.control(false),
      isolatedBackwaterPools: fb.control(false),
      efficacyOfHabitatSampling: fb.control('', [Validators.required]),
      author: fb.control(''),
      riffles: fb.control(false),
      species: fb.array([]),
      threats: fb.array([]),
      // otherThreats: fb.array([]),
      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
      ergoEpoptiaFasiErgou: fb.control('', [Validators.required]),
      samId: fb.control('', [Validators.required]),
      electricity: fb.control(''),
      averageVoltage: fb.control('', [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      averageFrequency: fb.control('', [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      otherTypesPhysicalCoverage: fb.control(''),
      sufficientHabitatQuality: fb.control(''),
      insufficientHabitatQuality: fb.control(''),
      unknownHabitatQuality: fb.control(''),
      sufficientHabitatArea: fb.control(''),
      insufficientHabitatArea: fb.control(''),
      habitatThreats: fb.control(''),
      sufficientHabitatXQuality: fb.control(''),
      shapefile: fb.control(''),
    });
    return sample;
  }

  public fullPercentageValidator(
    fields: string[],
    errorName: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
        return null;
      }

      let res: ValidationErrors = {};
      res[errorName] = true;

      let total = 0;
      fields.forEach((field) => {
        if (control.parent) {
          const fieldValue = control.parent.get(field)?.value;
          total += parseFloat(fieldValue?.toString() ?? '0');
        }
      });

      if (total > 100) {
        fields.forEach((field) => {
          if (control.parent) {
            control.parent.get(field)?.markAsTouched();
            control.parent.get(field)?.setErrors(res, { emitEvent: false });
          }
        });
        return res;
      }

      fields.forEach((field) => {
        if (control.parent) {
          control.parent.get(field)?.setErrors(null, { emitEvent: false });
        }
      });
      return null;
    };
  }

  public initSpeciesFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      species: fb.control('', [Validators.required]),
      yofY: fb.control('', [
        Validators.required,
        Validators.min(0),
        Validators.max(1000000),
      ]),
      adults: fb.control('', [
        Validators.required,
        Validators.min(0),
        Validators.max(1000000),
      ]),
      sumOfIndivid: fb.control('', [Validators.min(0)]),
      parasitesPerCent: fb.control('', [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      otherSpecies: fb.control(''),
      obsId: fb.control(''),
      speciesHabitat: fb.control(''),
      parii: fb.control(false),
      pariv: fb.control(false),
      parv: fb.control(false),
    });
  }

  public initThreatsFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      hDCode: fb.control('', [Validators.required]),
      siteOrSegment: fb.control('', [Validators.required]),
      species: fb.control('', [Validators.required]),
      otherSpecies: fb.control(''),
      impact: fb.control(''),
      populationPercentage: fb.control(''),
      timeCharacter: fb.control(''),
      impactType: fb.control(''),
    });
  }

  public initOtherThreatsFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      otherPressuresAndThreats: fb.control('', [Validators.required]),
    });
  }

  public prepareFishRequest(
    fishForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctSpecies: IMainCTSDto[] = [],
    editMode = false
  ): IFishDto {
    const fish: IFishDto = {};
    const species = fishForm.controls['species'] as FormArray;
    const threats = fishForm.controls['threats'] as FormArray;
    const otherThreats = fishForm.controls['otherThreats'] as FormArray;

    fish.date = this.common.formatDate(fishForm?.value.date);
    fish.time = this.common.formatTime(fishForm?.value.time);
    fish.duration = fishForm?.value.duration;
    fish.researcher = fishForm?.value.researcher;
    fish.author = fishForm?.value.author;
    fish.fisher = fishForm?.value.fisher;
    fish.completedBy = fishForm?.value.completedBy;
    fish.financialInstrument = fishForm?.value.financialInstrument;
    fish.samplingCode = fishForm?.value.samplingCode;
    fish.naturaCode = fishForm?.value.naturaCode;
    fish.locality = fishForm?.value.locality;
    fish.pointX = fishForm?.value.lat;
    fish.pointY = fishForm?.value.long;
    fish.prefecture = fishForm?.value.prefecture;
    fish.altitude = fishForm?.value.altitude;
    fish.siteCode = fishForm?.value.siteCode;
    fish.siteCodeText = fishForm?.value.siteCodeText;
    fish.course = fishForm?.value.course;
    fish.ecoRegion = fishForm?.value.ecoRegion;
    fish.hydrographicBasin = fishForm?.value.hydrographicBasin;
    fish.researchProgram = fishForm?.value.researchProgram;
    fish.referenceSiteEst = fishForm?.value.referenceSiteEst;
    fish.slope = fishForm?.value.slope;
    fish.samplingEquipment = fishForm?.value.samplingEquipment;
    fish.samplingEffort = fishForm?.value.samplingEffort;
    fish.samplingStrategyA = fishForm?.value.samplingStrategyA;
    fish.samplingStrategyB = fishForm?.value.samplingStrategyB;
    fish.fishedLength = fishForm?.value.fishedLength;
    fish.fishedArea = fishForm?.value.fishedArea;
    fish.flowRegime = fishForm?.value.flowRegime;
    fish.wettedWidthM = fishForm?.value.wettedWidthM;
    fish.leftBankM = fishForm?.value.leftBankM;
    fish.rightBankM = fishForm?.value.rightBankM;
    fish.width1 = fishForm?.value.width1;
    fish.width5 = fishForm?.value.width5;
    fish.width10 = fishForm?.value.width10;
    fish.width20 = fishForm?.value.width20;
    fish.widthGT20 = fishForm?.value.widthGT20;
    fish.depth025 = fishForm?.value.depth025;
    fish.depth05 = fishForm?.value.depth05;
    fish.depth1 = fishForm?.value.depth1;
    fish.depthGT1 = fishForm?.value.depthGT1;
    fish.depthMean = fishForm?.value.depthMean;
    fish.depthMax = fishForm?.value.depthMax;
    fish.depthMax = fishForm?.value.depthMax;
    fish.subsRock = fishForm?.value.subsRock;
    fish.subsBoulder = fishForm?.value.subsBoulder;
    fish.subsCobble = fishForm?.value.subsCobble;
    fish.subsPebble = fishForm?.value.subsPebble;
    fish.subsGravel = fishForm?.value.subsGravel;
    fish.subsSand = fishForm?.value.subsSand;
    fish.subsSilt = fishForm?.value.subsSilt;
    fish.subsClay = fishForm?.value.subsClay;
    fish.subsOrganic = fishForm?.value.subsOrganic;
    fish.subsArtificial = fishForm?.value.subsArtificial;
    fish.shadeness = fishForm?.value.shadeness;
    fish.weather = fishForm?.value.weather;
    fish.velocityMSec = fishForm?.value.velocityMSec;
    fish.conductivitymScm = fishForm?.value.conductivitymScm;
    fish.o2mgL = fishForm?.value.o2mgL;
    fish.pH = fishForm?.value.pH;
    fish.tofAir = fishForm?.value.tofAir;
    fish.tofWater = fishForm?.value.tofWater;
    fish.salinityPpt = fishForm?.value.salinityPpt;
    fish.turbidity = fishForm?.value.turbidity;
    fish.helophytes = fishForm?.value.helophytes;
    fish.helophytesDominatingSp = fishForm?.value.helophytesDominatingSp;
    fish.bottomVegetation = fishForm?.value.bottomVegetation;
    fish.bottomVegetationDominatingSp =
      fishForm?.value.bottomVegetationDominatingSp;
    fish.habTypePool = fishForm?.value.habTypePool;
    fish.habTypeGlide = fishForm?.value.habTypeGlide;
    fish.habTypeRun = fishForm?.value.habTypeRun;
    fish.habTypeRiffle = fishForm?.value.habTypeRiffle;
    fish.habTypeRapid = fishForm?.value.habTypeRapid;
    fish.habTypeOther = fishForm?.value.habTypeOther;
    fish.importantPressures = fishForm?.value.importantPressures;
    fish.logsLargeWoodyDebris = fishForm?.value.logsLargeWoodyDebris;
    fish.overhangingVegetation = fishForm?.value.overhangingVegetation;
    fish.denseMacrophyteBeds = fishForm?.value.denseMacrophyteBeds;
    fish.deepPools = fishForm?.value.deepPools;
    fish.bouldersCobbles = fishForm?.value.bouldersCobbles;
    fish.undercutBanks = fishForm?.value.undercutBanks;
    fish.thickRootMats = fishForm?.value.thickRootMats;
    fish.marshyFringes = fishForm?.value.marshyFringes;
    fish.isolatedBackwaterPools = fishForm?.value.isolatedBackwaterPools;
    fish.efficacyOfHabitatSampling = fishForm?.value.efficacyOfHabitatSampling;
    fish.riffles = fishForm?.value.riffles;
    fish.gridCell = fishForm?.value.gridCell;
    fish.observations = fishForm?.value.observations;
    fish.protocolId = Protocols.Fish;
    fish.ergoEpoptiaFasiErgou = fishForm?.value.ergoEpoptiaFasiErgou;
    fish.electricity = fishForm?.value.electricity;
    fish.averageVoltage = fishForm?.value.averageVoltage;
    fish.averageFrequency = fishForm?.value.averageFrequency;
    fish.otherTypesPhysicalCoverage =
      fishForm?.value.otherTypesPhysicalCoverage;
    fish.sufficientHabitatQuality = fishForm?.value.sufficientHabitatQuality;
    fish.unknownHabitatQuality = fishForm?.value.unknownHabitatQuality;
    fish.insufficientHabitatQuality =
      fishForm?.value.insufficientHabitatQuality;
    fish.sufficientHabitatArea = fishForm?.value.sufficientHabitatArea;
    fish.insufficientHabitatArea = fishForm?.value.insufficientHabitatArea;
    fish.habitatThreats = fishForm?.value.habitatThreats;
    fish.sufficientHabitatXQuality = fishForm?.value.sufficientHabitatXQuality;
    fish.shapefile = fishForm?.value.shapefile;

    if (fromSpreadsheet === true) {
      fish.samId = fishForm?.value.samId;

      fish.threats = fishForm.value.threats;
      if (fish.threats && fish.threats.length === 0) {
        fish.threats = undefined;
      } else if (fish.threats && fish.threats.length > 0) {
        fish.threats.forEach((threat) => {
          if (threat.species === 'Άλλο') {
            threat.species = undefined;
          } else {
            threat.species = ctSpecies
              .find((itemInner) => itemInner.code === threat.species)
              ?.id?.toString();
            threat.otherSpecies = undefined;
          }
        });
      }

      fish.species = fishForm.value.species;
      if (fish.species && fish.species.length === 0) {
        fish.species = undefined;
      } else if (fish.species && fish.species.length > 0) {
        fish.species.forEach((item) => {
          if (item.species === 'Άλλο') {
            item.species = undefined;
          } else {
            item.species = ctSpecies
              .find((itemInner) => itemInner.code === item.species)
              ?.id?.toString();
            item.otherSpecies = undefined;
          }
        });
      }

      // fish.otherThreats = fishForm.value.otherThreats;
      // if (fish.otherThreats && fish.otherThreats.length === 0) {
      //   fish.otherThreats = undefined;
      // }

      if (fish.samplingEquipment) {
        if (
          fish.samplingEquipment === 'Δίχτυα' ||
          fish.samplingEquipment === 'Άλλο'
        ) {
          fish.electricity = undefined;
          fish.averageVoltage = undefined;
          fish.averageFrequency = undefined;
        }
      } else {
        fish.electricity = undefined;
        fish.averageVoltage = undefined;
        fish.averageFrequency = undefined;
      }
    } else {
      if (!editMode) {
        const timestamp = Date.now().toString();
        let samId =
          fishForm?.value.samplingCode +
          '_' +
          fishForm?.value.ergoEpoptiaFasiErgou +
          '_ICT_0000__' +
          timestamp;

        fish.samId = samId;
      }

      if (!species?.touched) {
        fish.species = undefined;
      } else {
        fish.species = species?.value;
        if (fish.species && fish.species.length > 0) {
          fish.species.forEach((sp, index) => {
            // sp.obsId = samId + '_' + (index + 1).toString().padStart(3, '0');
            sp.obsId = undefined;
          });
        }
      }

      if (!threats?.touched) {
        fish.threats = undefined;
      } else {
        fish.threats = threats?.value;
        fish.threats?.forEach((threat) => {
          if (threat.otherSpecies != null && threat.otherSpecies !== '') {
            threat.species = undefined;
          }
        });
      }

      // if (!otherThreats?.touched) {
      //   fish.otherThreats = undefined;
      // } else {
      //   fish.otherThreats = otherThreats?.value;
      // }

      if (fish.samplingEquipment) {
        if (
          fish.samplingEquipment === 'Δίχτυα' ||
          fish.samplingEquipment === 'Άλλο'
        ) {
          fish.electricity = undefined;
          fish.averageVoltage = undefined;
          fish.averageFrequency = undefined;
        }
      } else {
        fish.electricity = undefined;
        fish.averageVoltage = undefined;
        fish.averageFrequency = undefined;
      }
    }

    return fish;
  }
}
