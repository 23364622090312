import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  ISeaHabitatARequest,
  ISeaHabitatBRequest,
} from 'src/app/core/dtos/sea-habitat.dto';
import { ISeaSpeciesDto } from 'src/app/core/dtos/sea.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { SeaService } from '../../services/sea.service';

@Component({
  selector: 'app-sea-habitat-a',
  templateUrl: './sea-habitat-a.component.html',
  styleUrls: ['./sea-habitat-a.component.scss'],
})
export class SeaHabitatAComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctSpecies: ISeaSpeciesDto[] = [];
  public aForm!: FormGroup;

  @Input() public a!: FormArray;
  @Input() public c!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public struct1120aById: ISeaHabitatARequest[] = [];
  @Input() public struct1120cById: ISeaHabitatBRequest[] = [];
  @Input() public disabledControls = false;
  @Input() public sampleId?: number;

  public positionDropdown: IDropdownItem[] = [
    {
      id: 'Δεξιά',
      name: 'Δεξιά',
    },
    {
      id: 'Κέντρο',
      name: 'Κέντρο',
    },
    {
      id: 'Αριστερά',
      name: 'Αριστερά',
    },
  ];

  public typologyDropdown: IDropdownItem[] = [
    {
      id: 'Προελαύνον',
      name: 'Προελαύνον',
    },
    {
      id: 'Σταθερό (Γραμμικό με παρουσία πλαγιότροπων)',
      name: 'Σταθερό (Γραμμικό με παρουσία πλαγιότροπων)',
    },
    {
      id: 'Σταθερό (Γραμμικό χωρίς παρουσία πλαγιότροπων)',
      name: 'Σταθερό (Γραμμικό χωρίς παρουσία πλαγιότροπων)',
    },
    {
      id: 'Υποχωρόν',
      name: 'Υποχωρόν',
    },
  ];

  constructor(private fb: FormBuilder, private seaService: SeaService) {}

  /**
   *
   */
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['struct1120aById'] && this.sampleId) {
      this.struct1120aById.forEach(() => {
        this.addNewA();
      });
      this.a.patchValue(this.struct1120aById);
      this.a.markAsTouched();
      this.struct1120aById = [];
      if (this.disabledControls === true) {
        this.a.disable();
      }
    }
    if (changes['struct1120cById'] && this.sampleId) {
      this.struct1120cById.forEach(() => {
        this.addNewC();
      });
      this.c.patchValue(this.struct1120cById);
      this.c.markAsTouched();
      this.struct1120cById = [];
      if (this.disabledControls === true) {
        this.c.disable();
      }
    }
  }

  public getAGroup(index: number): FormGroup {
    return this.a.controls[index] as FormGroup;
  }

  public getCGroup(index: number): FormGroup {
    return this.c.controls[index] as FormGroup;
  }

  public addNewA(): void {
    this.a.push(this.seaService.initSeaHabitatStructAFormGroup(this.fb));
  }

  public addNewC(): void {
    this.c.push(this.seaService.initSeaHabitatStructCFormGroup(this.fb));
  }

  public removeA(index: number): void {
    this.a.removeAt(index);
  }

  public removeC(index: number): void {
    this.c.removeAt(index);
  }

  public get hasErrors(): boolean {
    return this.a.errors != null;
  }

  public get errors(): ValidationErrors | null {
    return this.a.errors;
  }

  public get touched(): boolean {
    return this.a.touched;
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
