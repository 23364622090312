import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import {
  ILocationCircle,
  ILocationPolygon,
  ISearchResponse,
  ITableFilter,
  ITableFilterItem,
  ITableFilterItemRule,
  ITableFilterRequest,
} from '../table/interfaces/table.interfaces';
import {
  IDataTableColumns,
  sampleColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  DataSubType,
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import { ISampleSearchItemResponse } from 'src/app/shared/table/interfaces/table.interfaces';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { IDropdownItem } from '../dropdown/dropdown.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, catchError } from 'rxjs';
import { SearchService } from '../services/search.service';
import { ISearchCoordinates } from '../map-search/map-search.component';
import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../services/toast.service';

export enum TableType {
  SAMPLES,
  SPECIES,
  THREATS,
}

@Component({
  selector: 'app-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss'],
})
export abstract class TableWrapperComponent implements OnDestroy, OnChanges {
  @Input() public protocolId?: Protocols;
  @Input() public recordChangedTrigger: boolean = false;
  public sampleTotalRecords: number = 0;
  public samples: ISampleSearchItemResponse[] = [];
  public sampleColumns: IDataTableColumns[] = [];
  public sampleFirst = 0;

  public sampleCoordinates: ISearchCoordinates[] = [];
  public speciesCoordinates: ISearchCoordinates[] = [];
  public randomSpeciesCoordinates: ISearchCoordinates[] = [];

  protected payload: ITableFilterRequest = {
    samples: {
      first: 0,
      rows: 3,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    species: {
      first: 0,
      rows: 3,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    threats: {
      first: 0,
      rows: 3,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
  };

  public dataVersions: IDropdownItem[] = [
    { id: '0', name: '0' },
    { id: '1', name: '1' },
  ];
  public form: FormGroup = this.fb.group({
    dataVersion: this.fb.control('1'),
  });

  protected subscription: Subscription;

  constructor(
    protected searchService: SearchService,
    protected protocolService: ProtocolsService,
    protected fb: FormBuilder,
    protected translate: TranslateService,
    protected toastService: ToastService
  ) {
    this.subscription = new Subscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['recordChangedTrigger'] &&
      changes['recordChangedTrigger'].currentValue === true
    ) {
      this.fetchData();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected abstract watchDataversion(): void;

  public get version(): string {
    return this.form.controls['dataVersion'].value;
  }

  protected abstract fetchSampleColumns(): void;

  protected abstract fetchSpeciesColumns(): void;

  protected abstract fetchThreatsColumns(): void;

  public loadSampleData(event: LazyLoadEvent): void {
    const tableFilter = this.prepareFilterPayload(event, sampleColumns, true);
    this.payload.samples = tableFilter;

    if (event.first) {
      this.sampleFirst = event.first;
    }

    this.fetchData();
  }

  public locationCircleSearch(location: ILocationCircle | null): void {
    this.payload.locationCircle = location;
    this.payload.locationPolygon = null;
    this.initializeSearch();
    this.initializePaginator();
    this.fetchData();
  }

  public locationPolygonSearch(location: ILocationPolygon[] | null): void {
    this.payload.locationPolygon = location;
    this.payload.locationCircle = null;
    this.initializeSearch();
    this.initializePaginator();
    this.fetchData();
  }

  public initializeSearch() {
    Object.entries(this.payload).forEach(([key, value]) => {
      if (this.payload[key as keyof typeof this.payload] != null) {
        if (value.first != null) {
          value.first = 0;
        }
      }
    });
  }

  protected abstract initializePaginator(): void;

  protected prepareFilterPayload(
    event: LazyLoadEvent,
    columns: IDataTableColumns[],
    isSample: boolean = false
  ): ITableFilter {
    const tableFilter: ITableFilter = {
      first: event.first,
      rows: event.rows,
      sortOrder: event.sortOrder,
      sortField: event.sortField ?? null,
      items: [],
    };

    if (event.sortField != null) {
      const column = columns.find(
        (item) => item.propertyName === event.sortField
      );
      if (column != null) {
        tableFilter.sortField = column.searchColumn;
      }
    }

    for (const property of Object.keys(event.filters as any)) {
      const column = columns.find((item) => item.propertyName === property);
      if (column != null) {
        const item: ITableFilterItem = {
          name: column.searchColumn,
          dataType: column.searchDataType,
          dataSubType: column.searchDataSubType,
          rules: [],
        };
        const rules: ITableFilterItemRule[] = [];

        if (item.dataType === DataType.List) {
          if (item.dataSubType != null) {
            item.dataType = DataType.Numerical;
            item.dataSubType = DataSubType.Int32N;
          } else {
            item.dataType = DataType.String;
          }
          (event.filters as any)[property]?.value?.forEach((value: any) => {
            rules.push({
              value: value.id,
              filterType:
                value.matchMode != null
                  ? this.getFilterType(value.matchMode)
                  : FilterType.Equal,
              operator: FilterOperator.Or,
            });
          });
        } else {
          ((event.filters as any)[property] as any[]).forEach((item) => {
            if (item.value != null && item.value !== '') {
              const filterType =
                typeof item.value === 'boolean'
                  ? FilterType.Equal
                  : this.getFilterType(item.matchMode);
              const operator = this.getFilterOperators(item.operator);

              // Checking if time is valid.
              if (column.searchDataType === DataType.Time) {
                const regex = /^([01]\d|2[0-3]):([0-5]\d)/;
                if (!regex.test(item.value)) {
                  item.value = '00:00:00.01';
                }
              }

              rules.push({
                value: item.value,
                filterType,
                operator,
              });
            }
          });
        }

        if (rules.length > 0) {
          item.rules = rules;
          tableFilter.items.push(item);
        }
      }
    }

    // Adding data version.
    if (this.version !== '-' && isSample) {
      const item: ITableFilterItem = {
        name: 'DataVersion',
        dataType: DataType.String,
        rules: [
          {
            value: this.version,
            filterType: FilterType.Equal,
            operator: FilterOperator.Or,
          },
        ],
      };

      tableFilter.items.push(item);
    }

    return tableFilter;
  }

  protected getFilterOperators(operator: string): FilterOperator {
    if (operator === 'and') {
      return FilterOperator.And;
    } else {
      return FilterOperator.Or;
    }
  }

  protected getFilterType(matchMode: string): FilterType {
    if (matchMode === 'equals' || matchMode === 'dateIs') {
      return FilterType.Equal;
    } else if (matchMode === 'notEquals') {
      return FilterType.NotEqual;
    } else if (matchMode === 'gt') {
      return FilterType.GreaterThan;
    } else if (matchMode === 'lt') {
      return FilterType.LessThan;
    } else if (matchMode === 'gte') {
      return FilterType.GreaterThanOrEqual;
    } else if (matchMode === 'lte') {
      return FilterType.LessThanOrEqual;
    } else if (matchMode === 'startsWith') {
      return FilterType.StartsWith;
    } else if (matchMode === 'endsWith') {
      return FilterType.EndsWith;
    } else {
      return FilterType.Contains;
    }
  }

  protected abstract fetchData(): void;

  public buildExportArray(
    records: ISearchResponse,
    recordName: keyof ISearchResponse,
    isSample: boolean = false,
    configFile: IConfigFile[],
    formGroupName: string,
    isRandom: boolean = false
  ): any[] {
    let res: any[] = [];

    if (records[recordName]) {
      // Get config properties
      const configurations = configFile.find((item) => {
        return (
          item.formGroupName === formGroupName &&
          !!item.isRandomSheet === isRandom
        );
      })?.configuration;

      // Initiate length of array
      const columns = configurations?.length;
      const rows = records[recordName]?.rows.length;
      res = new Array(rows != null ? rows + 1 : 1);
      if (columns != null) {
        for (let i = 0; i < res.length; i++) {
          res[i] = new Array(columns + 1);
        }
      }

      // Put A/A as a title to first column
      res[0][0] = 'A/A';
      // Add all other titles
      configurations?.forEach((item) => {
        if (item.order) {
          if (!res[0][item.order] || res[0][item.order] === '') {
            res[0][item.order] = item.header;
          }
        }
      });

      // Loop through every row
      records[recordName]?.rows.forEach((row, index) => {
        // Insert value for A/A
        res[index + 1][0] = index;

        // If it is sample, combine samples with sample*(category)
        let sampleRow = {};
        if (isSample) {
          const sample = records.samples.rows.find((item) => {
            return item.sampleId === (row as any).sampleId;
          });
          sampleRow = sample != null ? sample : {};
        }

        const combinedSample: any = { ...row, ...sampleRow };

        // Loop through every configuration property
        // to get tiltles and values
        configurations?.forEach((item) => {
          if (item.order) {
            // Fix format of values and insert them
            if (item.type === 'time') {
              if (combinedSample[item.formName] != null) {
                const timeArray = combinedSample[item.formName].split(':');
                res[index + 1][item.order] = timeArray[0] + ':' + timeArray[1];
              } else {
                res[index + 1][item.order] = '';
              }
            } else if (item.type === 'date') {
              if (combinedSample[item.formName] != null) {
                const dateArray = combinedSample[item.formName]
                  .split('T')[0]
                  .split('-');
                res[index + 1][item.order] =
                  dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
              } else {
                res[index + 1][item.order] = '';
              }
            } else if (item.type === 'boolean') {
              if (combinedSample[item.formName] != null) {
                if (combinedSample[item.formName] === true) {
                  res[index + 1][item.order] = 'Ναι';
                } else if (combinedSample[item.formName] === false) {
                  res[index + 1][item.order] = 'Όχι';
                } else {
                  res[index + 1][item.order] = '';
                }
              } else {
                res[index + 1][item.order] = '';
              }
            } else {
              if (combinedSample[item.formName] != null) {
                res[index + 1][item.order] = combinedSample[item.formName];
              } else {
                res[index + 1][item.order] = '';
              }
            }

            if (item.format) {
              if (
                combinedSample[item.formName] != null &&
                item.format === '####-####'
              ) {
                const stringPart1 = (
                  combinedSample[item.formName] as string
                ).substring(0, 4);
                const stringPart2 = (
                  combinedSample[item.formName] as string
                ).substring(4);
                res[index + 1][item.order] = stringPart1 + '-' + stringPart2;
              }
            }

            // If coordinates are -1, convert them to empty space
            if (
              item.header.includes('WGS84') &&
              (item.formName.includes('lat') ||
                item.formName.includes('long') ||
                item.formName.includes('point'))
            ) {
              if (
                combinedSample[item.formName] &&
                combinedSample[item.formName] < 0
              ) {
                res[index + 1][item.order] = '';
              }
            }
          }
        });
      });
    }

    return res;
  }

  public fixExportArrayColumnsWidth(array: any[]): any[] {
    const res: any[] = [];
    for (let i = 0; i < array[0].length; i++) {
      const maxWidthSamples = array
        .sort((a, b) => {
          return b[i].toString().length - a[i].toString().length;
        })[0]
        [i].toString().length;
      res.push({ wch: maxWidthSamples + 5, MDW: 100 });
    }

    return res;
  }

  public getZip(response: ISearchResponse, filename: string) {
    let sampleIds: number[] = [];
    sampleIds = response.samples.rows.map((sample) => {
      return sample.sampleId;
    });

    if (sampleIds.length === 0) {
      this.toastService.show('EXPORT_TOAST_NO_RECORDS_TEXT', {
        header: 'EXPORT_TOAST_NO_RECORDS_HEADER',
      });
    } else {
      this.searchService
        .getZipFile(sampleIds)
        .pipe(
          catchError(async (err) => {
            // const message = await err.error.text();
            if (err.status === 404) {
              this.toastService.show('EXPORT_TOAST_NO_FILES_TEXT', {
                header: 'EXPORT_TOAST_NO_FILES_HEADER',
              });
              throw null;
            } else {
              this.toastService.show('EXPORT_TOAST_FAILED_TEXT', {
                header: 'GENERIC_FAILURE_HEADER',
              });
              throw err;
            }
          })
        )
        .subscribe((result) => {
          const blob = new Blob([result], { type: 'application/zip' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename + '.zip';
          link.click();
        });
    }
  }
}
