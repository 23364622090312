<div *ngIf="type === reportType.Error" class="errors-container">
  <div class="title">{{ "REPORT_FAILED_LOADING_FILE" | translate }}</div>
  <div class="table-container">
    <table class="table align-middle table-sm">
      <thead class="table-titles bg-light">
        <tr>
          <th scope="col" class="title-sheet">{{ "SHEET" | translate }}</th>
          <th scope="col" class="title-row">{{ "ROW" | translate }}</th>
          <th scope="col" class="title-coloumn">{{ "COLUMN" | translate }}</th>
          <th scope="col" class="title-description">
            {{ "DESCRIPTION" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let error of errors">
          <tr>
            <th scope="row" class="row-sheet">{{ error.sheet }}</th>
            <th scope="row" class="row-row">{{ error.row }}</th>
            <td class="row-column">{{ error.column }}</td>
            <td class="row-description">{{ error.description }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="type === reportType.Success" class="imports-container">
  <div
    class="title success"
    *ngIf="successImports.length > 0 && failedImports.length === 0"
  >
    <span class="pi pi-check-circle"></span>
    {{ "REPORT_SUCCESS_UPLOAD" | translate }}
  </div>
  <div
    class="title success-with-failed"
    *ngIf="successImports.length > 0 && failedImports.length > 0"
  >
    <span class="pi pi-exclamation-triangle"></span>
    {{ "REPORT_SUCCESS_UPLOAD_WITH_FAILED" | translate }}
  </div>
  <div
    class="title failed"
    *ngIf="successImports.length === 0 && failedImports.length > 0"
  >
    <span class="pi pi-times-circle"></span>
    {{ "REPORT_FAILED_UPLOAD" | translate }}
  </div>
  <div *ngIf="successImports.length > 0" class="success-imports">
    <p-table
      [value]="imports"
      [columns]="selectedColumns"
      [tableStyle]="{ 'min-width': '28rem' }"
      [scrollable]="true"
      scrollHeight="215px"
      [styleClass]="'p-datatable-sm'"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          {{ "IMPORTED" | translate }} {{ successImports.length }}
          {{ "FROM_TOTAL_RECORDS" | translate }} {{ imports.length }}
          {{ "RECORDS" | translate }}
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ "RECORD_ID" | translate }}</th>
          <th *ngFor="let col of columns">
            {{ col | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-import>
        <tr>
          <td class="success">{{ import.id }}</td>
          <td class="success" *ngFor="let table of import.tables">
            {{ table.count }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="upload-files" *ngIf="uploadId !== null">
      {{ "REPORT_FILE_UPLOAD_INFO" | translate }}:
      <span class="upload-files-id">{{ uploadId }}</span>
    </div>
  </div>

  <div *ngIf="failedImports.length > 0" class="failed-imports">
    <p-table
      [value]="imports"
      [columns]="selectedColumns"
      [tableStyle]="{ 'min-width': '28rem' }"
      [scrollable]="true"
      scrollHeight="215px"
      [styleClass]="'p-datatable-sm'"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          {{ "NOT_IMPORTED" | translate }} {{ failedImports.length }}
          {{ "FROM_TOTAL_RECORDS" | translate }} {{ imports.length }}
          {{ "RECORDS" | translate }}
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ "RECORD_ID" | translate }}</th>
          <th *ngFor="let col of columns">
            {{ col | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-import>
        <tr>
          <td class="failed">{{ import.id }}</td>
          <td class="failed" *ngFor="let table of import.tables">
            {{ table.count }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
