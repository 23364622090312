<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>
<div class="title-container">
  <span class="title-text uppercase"
    >{{ "NEW_REGISTRATION" | translate }} - {{ "HABITATS" | translate }}</span
  >
</div>
<div class="habitat-container">
  <div class="info-container">
    <span class="info-text uppercase">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container">
    <div class="form-row form-3">
      <app-input
        [formGroup]="habitatForm"
        [formName]="'releveNR'"
        [label]="'SAMPLE_CODE'"
        [required]="true"
      ></app-input>
    </div>
    <div class="form-row form-3">
      <app-input
        [formGroup]="habitatForm"
        [formName]="'plotCode'"
        [label]="'PLOT_CODE'"
        [required]="true"
      ></app-input>
      <app-input
        [formGroup]="habitatForm"
        [formName]="'releveNRor'"
        [label]="'RELEVE_NROR'"
        [required]="true"
      ></app-input>
      <app-dropdown
        class="dropdown-column"
        [formGroup]="habitatForm"
        [hidden]="!readOnlyForm"
        [formName]="'naturaCode'"
        [label]="'NATURA_CODE'"
        [dropdownList]="sitesDropdown"
        [required]="true"
        [preDefinedId]="habitatById?.naturaCode"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-input
        [mask]="'00/00/0000'"
        [formGroup]="habitatForm"
        [formName]="'date'"
        [label]="'DATE'"
        [required]="true"
      ></app-input>
      <app-input
        [mask]="'00:00'"
        [formGroup]="habitatForm"
        [formName]="'time'"
        [label]="'TIME'"
        [required]="true"
      ></app-input>
      <app-input
        [formGroup]="habitatForm"
        [formName]="'evaluator'"
        [label]="'EVALUATOR'"
      ></app-input>
    </div>
    <div class="form-row">
      <app-input
        [type]="'number'"
        [formGroup]="habitatForm"
        [formName]="'plotSizeM2'"
        [label]="'PLOT_SIZE'"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="habitatForm"
        [formName]="'areaAssessedM2'"
        [label]="'AREA_ASSESSED'"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="habitatForm"
        [formName]="'expositionD'"
        [label]="'EXPOSITION_D'"
      ></app-input>
    </div>
    <div class="form-row form-3">
      <app-input
        [type]="'number'"
        [formGroup]="habitatForm"
        [formName]="'inclinationD'"
        [label]="'INCLINATION_D'"
      ></app-input>
    </div>
    <div class="form-row">
      <app-dropdown
        [formGroup]="habitatForm"
        [formName]="'financialInstrument'"
        [label]="'FINANCIAL_INSTRUMENT'"
        [dropdownList]="financialDropdown"
        [required]="true"
        [preDefinedId]="habitatById?.financialInstrument"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="habitatForm"
        [formName]="'relief'"
        [label]="'RELIEF'"
        [dropdownList]="reliefTypesDropdown"
        [required]="true"
        [preDefinedId]="habitatById?.relief"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="habitatForm"
        [formName]="'soilType'"
        [label]="'SOIL_TYPE'"
        [dropdownList]="soilTypesDropdown"
        [required]="true"
        [preDefinedId]="habitatById?.soilType"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-dropdown
        [formGroup]="habitatForm"
        [formName]="'kodikosTo'"
        [label]="'KODIKOS_TO'"
        [dropdownList]="habitatTypesDropdown"
        [preDefinedId]="habitatById?.kodikosTo"
      ></app-dropdown>
      <app-input
        [formGroup]="habitatForm"
        [formName]="'geologicalSubstrate'"
        [label]="'GEOLOGICAL_SUBSTRATE'"
      ></app-input>
      <app-input
        [formGroup]="habitatForm"
        [formName]="'cluster'"
        [label]="'CLUSTER'"
      ></app-input>
    </div>
    <div class="form-row" [formGroup]="habitatForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="pari" />
        {{ "PARI_FULL" | translate }}
      </label>
    </div>
    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="habitatForm"
      [formName]="'observations'"
      [label]="'OBSERVATIONS'"
    ></app-textarea>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "LOCATION" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container form-1">
    <div class="form-row" [formGroup]="habitatForm" [hidden]="readOnlyForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isEGSA" />
        {{ coordSystemDropdown[0].id }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isWGS84" />
        {{ coordSystemDropdown[1].id }}
      </label>
    </div>
    <div class="form-row">
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="habitatForm"
        [formName]="'lat'"
        [label]="'LATITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="habitatForm"
        [formName]="'long'"
        [label]="'LONGTITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="habitatForm"
        [formName]="'x'"
        [label]="'X'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="habitatForm"
        [formName]="'y'"
        [label]="'Y'"
        [required]="true"
      ></app-input>
    </div>
  </div>
  <br />
  <div class="map-container">
    <app-map
      [coordinates]="coordinates"
      (markerMoved)="onMarkerMoved($event)"
      [disabled]="readOnlyForm"
    ></app-map>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" class="multi-tab-buttons">
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name }}</a
      >
      <ng-template ngbNavContent>
        <div
          [ngClass]="
            readOnlyForm ? 'form-container form-2' : 'form-container form-1'
          "
        >
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'altitude'"
              [label]="'ALTITUDE'"
              [required]="true"
            ></app-input>
            <app-dropdown
              [formGroup]="habitatForm"
              [hidden]="!readOnlyForm"
              [formName]="'gridCell'"
              [label]="'GRID_CELL'"
              [dropdownList]="codesDropdown"
              [required]="true"
              [preDefinedId]="habitatById?.gridCell"
            ></app-dropdown>
            <app-input
              [formGroup]="habitatForm"
              [formName]="'locality'"
              [label]="'LOCATION'"
            ></app-input>
            <app-dropdown
              [formGroup]="habitatForm"
              [formName]="'prefecture'"
              [label]="'PREFECTURE'"
              [dropdownList]="prefecturesDropdown"
              [required]="true"
              [preDefinedId]="habitatById?.prefecture"
            ></app-dropdown>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[1].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container form-2">
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'solidRock'"
              [label]="'SOLID_ROCK'"
              [required]="true"
            ></app-input>
            <app-dropdown
              [formGroup]="habitatForm"
              [formName]="'boulders'"
              [label]="'BOULDERS'"
              [dropdownList]="geoTypesDropdown"
              [required]="true"
              [preDefinedId]="habitatById?.boulders"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="habitatForm"
              [formName]="'stones'"
              [label]="'STONES'"
              [dropdownList]="geoTypesDropdown"
              [required]="true"
              [preDefinedId]="habitatById?.stones"
            ></app-dropdown>
          </div>
          <div class="form-row">
            <app-dropdown
              [formGroup]="habitatForm"
              [formName]="'gravel'"
              [label]="'GRAVEL'"
              [dropdownList]="geoTypesDropdown"
              [required]="true"
              [preDefinedId]="habitatById?.gravel"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="habitatForm"
              [formName]="'fineSoil'"
              [label]="'FINE_SOIL'"
              [dropdownList]="geoTypesDropdown"
              [required]="true"
              [preDefinedId]="habitatById?.fineSoil"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="habitatForm"
              [formName]="'litter'"
              [label]="'LITTER'"
              [dropdownList]="geoTypesDropdown"
              [required]="true"
              [preDefinedId]="habitatById?.litter"
            ></app-dropdown>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'treeCover'"
              [label]="'TREE_COVER'"
              [required]="true"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'treeHeightM'"
              [label]="'TREE_HEIGHT'"
              [required]="true"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'shrubCover'"
              [label]="'SHRUB_COVER'"
              [required]="true"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'shrubHeightM'"
              [label]="'SHRUB_HEIGHT'"
              [required]="true"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'herbCover'"
              [label]="'HERB_COVER'"
              [required]="true"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'herbHeightM'"
              [label]="'HERB_HEIGHT'"
              [required]="true"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'shrub2Cover'"
              [label]="'SHRUB_2_COVER'"
              [required]="true"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'moss'"
              [label]="'MOSS'"
              [required]="true"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'shrub2HeightM'"
              [label]="'SHRUB_2_HEIGHT'"
              [required]="true"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'lichen'"
              [label]="'LICHEN'"
              [required]="true"
            ></app-input>
          </div>
          <div class="form-row form-3" [formGroup]="habitatForm">
            <app-input
              [type]="'number'"
              [formGroup]="habitatForm"
              [formName]="'adjVegHabTypes'"
              [label]="'ADJ_VEG_HAB_TYPE'"
              [required]="true"
            ></app-input>
            <label class="btn btn-secondary" ngbButtonLabel>
              <input type="checkbox" formControlName="substrSignDisturb" />
              {{ "SUBSTR_SIGN_DISTURB" | translate }}
            </label>
          </div>
          <div class="form-row form-3">
            <app-input
              [style.max-width]="'100%'"
              [formGroup]="habitatForm"
              [formName]="'invasRudSpec'"
              [label]="'INVAS_RUD_SPEC'"
              [required]="true"
            ></app-input>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
        >{{ multiTabGroup[2].name }}</a
      >
      <ng-template ngbNavContent>
        <app-habitat-struct
          [structs]="structs"
          [formGroup]="habitatForm"
          [structsById]="structsById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="habitatById?.dataVersion"
          [sampleId]="sampleId"
        >
        </app-habitat-struct>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[3].isChecked }"
        >{{ multiTabGroup[3].name }}</a
      >
      <ng-template ngbNavContent>
        <app-habitat-species
          [species]="species"
          [formGroup]="habitatForm"
          [speciesById]="speciesById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="habitatById?.dataVersion"
          [sampleId]="sampleId"
        >
        </app-habitat-species>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[4].isChecked }"
        >{{ multiTabGroup[4].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-row">
          <app-dropdown
            [formGroup]="habitatForm"
            [formName]="'futureTrend'"
            [label]="'FUTURE_TREND'"
            [dropdownList]="statusTypesDropdown"
            [required]="true"
            [preDefinedId]="habitatById?.futureTrend"
          ></app-dropdown>
          <app-dropdown
            [formGroup]="habitatForm"
            [formName]="'futureStatus'"
            [label]="'FUTURE_STATUS'"
            [dropdownList]="statusTypesDropdown"
            [required]="true"
            [preDefinedId]="habitatById?.futureStatus"
          ></app-dropdown>
          <app-dropdown
            [formGroup]="habitatForm"
            [formName]="'restorationPossibility'"
            [label]="'RESTORATION_POSSIBILITY'"
            [dropdownList]="restorationTypesDropdown"
            [required]="true"
            [preDefinedId]="habitatById?.restorationPossibility"
          ></app-dropdown>
        </div>
        <hr />
        <br />
        <app-habitat-impact
          [impacts]="impacts"
          [formGroup]="habitatForm"
          [impactsById]="impactsById"
          [disabledControls]="readOnlyForm"
          [sampleId]="sampleId"
        >
        </app-habitat-impact>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[5].isChecked }"
        >{{ multiTabGroup[5].name }}</a
      >
      <ng-template ngbNavContent>
        <app-habitat-threat
          [threats]="threats"
          [formGroup]="habitatForm"
          [threatsById]="threatsById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="habitatById?.dataVersion"
          [sampleId]="sampleId"
        >
        </app-habitat-threat>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <ng-container
    *ngIf="
      !sampleId ||
      (sampleId && predefinedFiles.length > 0) ||
      (sampleId && !readOnlyForm)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    [btnText]="sampleId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    (click)="prepareHabitatRequest()"
    [isDisabled]="!habitatForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
