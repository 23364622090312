import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api';
import {
  bookColumns,
  bookSpeciesColumns,
  IDataTableColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  IBookSpeciesHabitatSpreadDto,
  IBookSpeciesHabitatSpreadEntryDto,
} from 'src/app/core/dtos/bookSpeciesHabitatSpread.dto';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { SearchService } from '../services/search.service';
import { TableWrapperComponent } from '../table-wrapper/table-wrapper.component';
import {
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import { ITableFilterRequest } from '../table/interfaces/table.interfaces';
import { forkJoin } from 'rxjs';
import { BookHabitatsSpeciesService } from 'src/app/samplings/services/book-habitats-species.service';
import { HabitatService } from 'src/app/samplings/services/habitat.service';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { TranslateService } from '@ngx-translate/core';
import {
  bibliographyConfig,
  BibliographyFormGroup,
} from 'src/app/samplings/config-files/bibliography-config';
import { utils, writeFile } from 'xlsx';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-book-search',
  templateUrl: './book-search.component.html',
  styleUrls: ['./book-search.component.scss'],
})
export class BookSearchComponent extends TableWrapperComponent {
  public bookTotalRecords: number = 0;
  public book: IBookSpeciesHabitatSpreadDto[] = [];
  public bookColumns: IDataTableColumns[] = [];
  public bookFirst = 0;

  public speciesBookTotalRecords: number = 0;
  public speciesBook: IBookSpeciesHabitatSpreadEntryDto[] = [];
  public speciesBookColumns: IDataTableColumns[] = [];
  public speciesBookFirst = 0;

  protected override payload: ITableFilterRequest = {
    book: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    bookSpecies: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
  };
  private tablesInitialized: number = 0;

  public activeTabId: number = 0;
  public multiTabGroup = [
    { id: 0, name: 'SEARCH_BOOK', isChecked: true },
    { id: 1, name: 'SEARCH_BOOK_SPECIES', isChecked: true },
  ];

  constructor(
    protected override searchService: SearchService,
    protected override protocolService: ProtocolsService,
    protected override fb: FormBuilder,
    private bookService: BookHabitatsSpeciesService,
    private habitatService: HabitatService,
    protected override translate: TranslateService,
    private modalService: NgbModal,
    protected override toastService: ToastService
  ) {
    super(searchService, protocolService, fb, translate, toastService);
  }

  ngOnInit(): void {
    this.fetchBookColumns();
    this.fetchBookSpeciesColumns();

    this.watchDataversion();
  }

  protected override fetchSampleColumns(): void {}

  protected override fetchSpeciesColumns(): void {}

  protected override fetchThreatsColumns(): void {}

  private fetchBookColumns(): void {
    this.bookColumns = bookColumns;

    forkJoin([
      this.bookService.getReferenceTypes(this.version),
      this.bookService.getSpeciesGroups(this.version),
    ]).subscribe(([ctReferenceTypeCode, ctSpeciesCategory]) => {
      if (ctReferenceTypeCode != null) {
        const referenceTypeCode = this.bookColumns.find(
          (item) => item.propertyName === 'referenceTypeCode'
        );
        if (referenceTypeCode != null) {
          referenceTypeCode.data = ctReferenceTypeCode.map((item) => {
            return {
              id: item.code,
              name: item.code,
            };
          });
        }
      }
      if (ctSpeciesCategory != null) {
        const speciesCategory = this.bookColumns.find(
          (item) => item.propertyName === 'speciesCategory'
        );
        if (speciesCategory != null) {
          speciesCategory.data = ctSpeciesCategory.map((item) => {
            return {
              id: item.code,
              name: item.code,
            };
          });
        }
      }
    });
  }

  private fetchBookSpeciesColumns(): void {
    this.speciesBookColumns = bookSpeciesColumns;

    forkJoin([
      this.bookService.getRelativeAbudances(this.version),
      this.bookService.getSourceReliabilities(this.version),
      this.bookService.getMethodReliabilities(this.version),
      this.habitatService.getTypes(this.version),
      this.protocolService.getRegionalUnits(this.version),
      this.searchService.getSpecies(Protocols.Bibliography, this.version),
    ]).subscribe(
      ([
        ctAbundance,
        ctSourceReliability,
        ctMethodReliability,
        ctHabitatId,
        ctRegionalUnit,
        ctSpecies,
      ]) => {
        if (ctAbundance != null) {
          const abundance = this.speciesBookColumns.find(
            (item) => item.propertyName === 'abundance'
          );
          if (abundance != null) {
            abundance.data = ctAbundance.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }
        if (ctSourceReliability != null) {
          const sourceReliability = this.speciesBookColumns.find(
            (item) => item.propertyName === 'sourceReliability'
          );
          if (sourceReliability != null) {
            sourceReliability.data = ctSourceReliability.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }
        if (ctMethodReliability != null) {
          const methodReliability = this.speciesBookColumns.find(
            (item) => item.propertyName === 'methodReliability'
          );
          if (methodReliability != null) {
            methodReliability.data = ctMethodReliability.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }
        if (ctHabitatId != null) {
          const habitatId = this.speciesBookColumns.find(
            (item) => item.propertyName === 'habitatId'
          );
          if (habitatId != null) {
            habitatId.data = ctHabitatId.map((item) => {
              return {
                id: item.id,
                name: `(${item.id}) - ${item.code}`,
              };
            });
          }
        }
        if (ctRegionalUnit != null) {
          const regionalUnit = this.speciesBookColumns.find(
            (item) => item.propertyName === 'regionalUnit'
          );
          if (regionalUnit != null) {
            regionalUnit.data = ctRegionalUnit.map((item) => {
              return {
                id: item.regionalUnit,
                name: item.regionalUnit,
              };
            });
          }
        }
        if (ctSpecies != null) {
          const speciesId = this.speciesBookColumns.find(
            (item) => item.propertyName === 'speciesId'
          );
          if (speciesId != null) {
            speciesId.data = ctSpecies.map((item) => {
              let categoryName = '';
              if (item.protocol === Protocols.Amphibian) {
                categoryName = 'Αμφίβια - Ερπετά';
              }
              if (
                item.protocol === Protocols.Sea ||
                item.protocol === Protocols.SeaHabitat
              ) {
                categoryName = 'Θαλάσσια είδη';
              }
              if (item.protocol === Protocols.Mammals) {
                categoryName = 'Θηλαστικά';
              }
              if (item.protocol === Protocols.Fish) {
                categoryName = 'Ιχθυοπανίδα';
              }
              if (item.protocol === Protocols.Chloris) {
                categoryName = 'Χλωρίδα';
              }
              if (item.protocol === Protocols.Invertebrates) {
                categoryName = 'Ασπόνδυλα';
              }
              if (item.protocol === Protocols.Bird) {
                categoryName = 'Ορνιθοπανίδα';
              }
              return {
                id: item.id,
                name: `(${categoryName}) - ${item.name}`,
              };
            });
          }
        }
      }
    );
  }

  protected override watchDataversion(): void {
    this.subscription.add(
      this.form.controls['dataVersion'].valueChanges.subscribe((version) => {
        // Updating tables.
        const dataVersion = this.payload.book?.items.find(
          (item) => item.name === 'DataVersion'
        );
        if (dataVersion != null) {
          if (dataVersion.rules != null && dataVersion.rules.length > 0) {
            dataVersion.rules[0].value = version;
          } else {
            dataVersion.rules = [
              {
                value: version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ];
          }
        } else {
          this.payload.book?.items.push({
            name: 'DataVersion',
            dataType: DataType.String,
            rules: [
              {
                value: this.version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ],
          });
        }

        this.fetchBookColumns();
        this.fetchBookSpeciesColumns();

        this.fetchData();
      })
    );
  }

  protected override fetchData(): void {
    this.searchService.filterBook(this.payload).subscribe((response) => {
      if (response != null) {
        if (response.book != null) {
          this.bookTotalRecords = response.book.totalCount;

          this.book = response.book.rows;
        }

        if (response.speciesBook != null) {
          this.speciesBookTotalRecords = response.speciesBook.totalCount;
          this.speciesBook = response.speciesBook.rows;
          this.speciesCoordinates = this.speciesBook
            .filter(
              (item) =>
                item.latitude != null &&
                item.longitude != null &&
                item.latitude !== -1 &&
                item.longitude !== -1
            )
            .map((item) => {
              return {
                lat: item.latitude as number,
                lng: item.longitude as number,
                text:
                  item.referenceId != null
                    ? item.referenceId.toString()
                    : undefined,
              };
            });
        }
      }
    });
  }

  public loadBookData(event: LazyLoadEvent): void {
    this.tablesInitialized++;

    const tableFilter = this.prepareFilterPayload(event, bookColumns, true);
    this.payload.book = tableFilter;

    if (event.first) {
      this.bookFirst = event.first;
    }

    this.fetchData();
  }

  public loadSpeciesBookData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 3) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(event, bookSpeciesColumns);
    this.payload.bookSpecies = tableFilter;

    if (event.first) {
      this.speciesBookFirst = event.first;
    }

    this.fetchData();
  }

  protected override initializePaginator() {
    this.bookFirst = 0;
    this.speciesBookFirst = 0;
  }

  public openExportModal() {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ExportModalComponent, {
      centered: true,
      modalDialogClass: 'export-modal',
      backdrop: 'static',
      keyboard: true,
    });

    if (modalRef) {
      modalRef.result.then((res) => {
        if (res != null) {
          this.exportFiles(res.xlsxFile, res.zipFile);
        }
      });
    }
  }

  public exportFiles(xlsxFile = false, zipFile = false) {
    let payload = { ...this.payload };
    if (payload.book) {
      payload.book.rows = this.bookTotalRecords;
      payload.book.first = 0;
    }
    if (payload.bookSpecies) {
      payload.bookSpecies.rows = this.speciesBookTotalRecords;
      payload.bookSpecies.first = 0;
    }

    let book: any[] = [];
    let speciesBook: any[] = [];

    this.searchService.filterBook(payload).subscribe((response) => {
      const date = new Date();
      const filename =
        'EP2_Export_Bibliography_' +
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      if (xlsxFile) {
        if (response && response.book) {
          book = this.buildExportArray(
            response,
            'book',
            false,
            bibliographyConfig,
            ''
          );
        }

        if (response && response.speciesBook) {
          speciesBook = this.buildExportArray(
            response,
            'speciesBook',
            false,
            bibliographyConfig,
            BibliographyFormGroup.Entries
          );
        }

        const workbook = utils.book_new();

        const wsBook = utils.aoa_to_sheet(book);
        wsBook['!cols'] = this.fixExportArrayColumnsWidth(book);
        wsBook['!rows'] = [{ hpt: 50 }];

        const wsSpeciesBook = utils.aoa_to_sheet(speciesBook);
        wsSpeciesBook['!cols'] = this.fixExportArrayColumnsWidth(speciesBook);
        wsSpeciesBook['!rows'] = [{ hpt: 50 }];

        utils.book_append_sheet(
          workbook,
          wsBook,
          this.translate.instant('SPREADSHEET_BIBLIOGRAPHY_REFERENCES')
        );
        utils.book_append_sheet(
          workbook,
          wsSpeciesBook,
          this.translate.instant('SPREADSHEET_BIBLIOGRAPHY_ENTRIES')
        );

        const date = new Date();
        writeFile(workbook, filename + '.xlsx', { compression: true });
      }

      if (zipFile) {
        this.getZip(response, filename);
      }
    });
  }
}
