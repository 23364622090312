import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  IHabitatDto,
  IHabitatGeoType,
  IHabitatReliefType,
  IHabitatSoilType,
  IHabitatType,
} from 'src/app/core/dtos/habitat.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ISampleIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { environment } from 'src/environments/environment';
import { SamplesService } from './samples.service';
import { IFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';

@Injectable({
  providedIn: 'root',
})
export class HabitatService {
  constructor(
    private http: HttpClient,
    public common: CommonService,
    private sampleService: SamplesService
  ) {}

  public getHabitats(dataVersion?: string): Observable<IHabitatDto[]> {
    const url = environment.endpoint + 'Habitat/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IHabitatDto[]>(url, { params: params });
  }

  public getHabitatById(id: number): Observable<IHabitatDto> {
    const url = environment.endpoint + 'Habitat';
    const params = new HttpParams().set('id', id);
    return this.http.get<IHabitatDto>(url, { params: params });
  }

  public putHabitat(
    id: number,
    payload: IHabitatDto
  ): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Habitat';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public addHabitat(habitat: IHabitatDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Habitat';
    return this.http.post<ISampleIdResponse>(url, habitat);
  }

  public addBatchHabitat(
    habitat: IHabitatDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Habitat/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, habitat);
  }

  public addFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Habitat/files';
    return this.http.post<void>(url, payload);
  }

  public removeFiles(payload: IFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Habitat/remove-files';
    return this.http.post<void>(url, payload);
  }

  public getTypes(dataVersion?: string): Observable<IHabitatType[]> {
    const url = environment.endpoint + 'Habitat/types';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IHabitatType[]>(url, { params: params });
  }

  public getSoilTypes(dataVersion?: string): Observable<IHabitatSoilType[]> {
    const url = environment.endpoint + 'Habitat/soil';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IHabitatSoilType[]>(url, { params: params });
  }

  public getSoilReliefTypes(
    dataVersion?: string
  ): Observable<IHabitatReliefType[]> {
    const url = environment.endpoint + 'Habitat/relief';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IHabitatReliefType[]>(url, { params: params });
  }

  public getSoilGeoTypes(dataVersion?: string): Observable<IHabitatGeoType[]> {
    const url = environment.endpoint + 'Habitat/geo';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IHabitatGeoType[]>(url, { params: params });
  }

  public getStructs(dataVersion?: string): Observable<any[]> {
    const url = environment.endpoint + 'Habitat/structure';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<any[]>(url, { params: params });
  }

  public getSpecies(dataVersion?: string): Observable<any[]> {
    const url = environment.endpoint + 'Habitat/species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<any[]>(url, { params: params });
  }

  public getStatus(dataVersion?: string): Observable<any[]> {
    const url = environment.endpoint + 'Habitat/status';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<any[]>(url, { params: params });
  }

  public getRestoration(dataVersion?: string): Observable<any[]> {
    const url = environment.endpoint + 'Habitat/restoration';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<any[]>(url, { params: params });
  }

  public getVitality(dataVersion?: string): Observable<any[]> {
    const url = environment.endpoint + 'Habitat/vitality';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<any[]>(url, { params: params });
  }

  public getCoverArea(dataVersion?: string): Observable<any[]> {
    const url = environment.endpoint + 'Habitat/coverArea';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<any[]>(url, { params: params });
  }

  public getCoverPlot(dataVersion?: string): Observable<any[]> {
    const url = environment.endpoint + 'Habitat/coverPlot';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<any[]>(url, { params: params });
  }

  public getThreats(dataVersion?: string): Observable<any[]> {
    const url = environment.endpoint + 'Habitat/threats';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<any[]>(url, { params: params });
  }

  public initHabitatFormGroup(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);
    sample = fb.group({
      ...sample.controls,
      altitude: fb.control('', [
        Validators.required,
        Validators.min(-10),
        Validators.max(2800),
      ]),
      releveNR: fb.control('', [Validators.required]),
      releveNRor: fb.control('', [Validators.required]),
      evaluator: fb.control(''),
      cluster: fb.control(''),
      pari: fb.control(false),
      plotCode: fb.control('', [Validators.required]),
      plotSizeM2: fb.control(''),
      areaAssessedM2: fb.control(''),
      expositionD: fb.control(''),
      inclinationD: fb.control(''),
      relief: fb.control('', [Validators.required]),
      soilType: fb.control('', [Validators.required]),
      geologicalSubstrate: fb.control(''),
      kodikosTo: fb.control(''),

      solidRock: fb.control('', [Validators.required]),
      boulders: fb.control('', [Validators.required]),
      stones: fb.control('', [Validators.required]),
      gravel: fb.control('', [Validators.required]),
      fineSoil: fb.control('', [Validators.required]),
      litter: fb.control('', [Validators.required]),
      treeCover: fb.control('', [Validators.required, Validators.min(0)]),
      treeHeightM: fb.control('', [Validators.required, Validators.min(0)]),
      shrubCover: fb.control('', [Validators.required, Validators.min(0)]),
      shrubHeightM: fb.control('', [Validators.required, Validators.min(0)]),
      herbCover: fb.control('', [Validators.required, Validators.min(0)]),
      herbHeightM: fb.control('', [Validators.required, Validators.min(0)]),
      adjVegHabTypes: fb.control('', [Validators.required, Validators.min(0)]),
      substrSignDisturb: fb.control(false),
      invasRudSpec: fb.control('', [Validators.required, Validators.min(0)]),
      shrub2Cover: fb.control('', [Validators.required, Validators.min(0)]),
      shrub2HeightM: fb.control('', [Validators.required, Validators.min(0)]),
      moss: fb.control('', [Validators.required, Validators.min(0)]),
      lichen: fb.control('', [Validators.required, Validators.min(0)]),
      futureTrend: fb.control('', [Validators.required, Validators.min(0)]),
      futureStatus: fb.control('', [Validators.required, Validators.min(0)]),
      restorationPossibility: fb.control('', [Validators.required]),

      structs: fb.array([]),
      species: fb.array([]),
      impacts: fb.array([]),
      threats: fb.array([]),

      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
    });

    sample.removeControl('samplingCode');

    return sample;
  }

  public initImpactFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      positiveImpact: fb.control('', [Validators.required]),
      importance: fb.control('', [Validators.required]),
    });
  }

  public initSpeciesFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      species: fb.control('', [Validators.required]),
      parii: fb.control(false),
      pariv: fb.control(false),
      parv: fb.control(false),
      coverPlot: fb.control('', [Validators.required]),
      coverArea: fb.control('', [Validators.required]),
      vitality: fb.control('', [Validators.required]),
    });
  }

  public initStructFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      code: fb.control('', [Validators.required]),
    });
  }

  public initThreatsFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      pressuresAndThreatsId: fb.control('', [Validators.required]),
      type: fb.control('', [Validators.required]),
      importance: fb.control('', [Validators.required]),
    });
  }

  public prepareHabitatRequest(
    habitatForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctHabitats: IHabitatType[] = []
  ): IHabitatDto {
    let habitat: IHabitatDto = {};
    const impacts = habitatForm.controls['impacts'] as FormArray;
    const species = habitatForm.controls['species'] as FormArray;
    const structs = habitatForm.controls['structs'] as FormArray;
    const threats = habitatForm.controls['threats'] as FormArray;

    habitat = { ...habitatForm.value };
    habitat.date = this.common.formatDate(habitatForm?.value.date);
    habitat.time = this.common.formatTime(habitatForm?.value.time);
    habitat.pointX = habitatForm.value.lat;
    habitat.pointY = habitatForm.value.long;
    habitat.protocolId = Protocols.Habitats;

    if (fromSpreadsheet === true) {
      // Updating hab type
      const habType = ctHabitats.find(item => item.code === habitat.kodikosTo?.toString());
      if (habType != null) {
        habitat.kodikosTo = habType.id.toString();
      }

      habitat.structs = habitatForm.value.structs;
      if (habitat.structs && habitat.structs.length === 0) {
        habitat.structs = undefined;
      }

      habitat.species = habitatForm.value.species;
      if (habitat.species && habitat.species.length === 0) {
        habitat.species = undefined;
      }

      habitat.impacts = habitatForm.value.impacts;
      if (habitat.impacts && habitat.impacts.length === 0) {
        habitat.impacts = undefined;
      }

      habitat.threats = habitatForm.value.threats;
      if (habitat.threats && habitat.threats.length === 0) {
        habitat.threats = undefined;
      }
    } else {
      if (!structs?.touched) {
        habitat.structs = undefined;
      } else {
        habitat.structs = structs?.value;
      }

      if (!species?.touched) {
        habitat.species = undefined;
      } else {
        habitat.species = species?.value;
      }

      if (!impacts?.touched) {
        habitat.impacts = undefined;
      } else {
        habitat.impacts = impacts?.value;
      }

      if (!threats?.touched) {
        habitat.threats = undefined;
      } else {
        habitat.threats = threats?.value;
      }
    }

    return habitat;
  }
}
