<div
  class="btn-group btn-group-menu"
  role="group"
  aria-label="Basic radio toggle button group"
>
  <ng-container *ngFor="let btn of buttonList">
    <input
      [attr.disabled]="btn.isDisabled"
      type="radio"
      class="btn-check"
      [name]="groupName"
      [id]="btn.id"
      autocomplete="off"
      btn-action="display-unread-btn"
      [checked]="btn.isChecked"
    />
    <label
      class="btn btn-outline-primary custom-btn"
      [for]="btn.id"
      data-btn="btn"
      (click)="btnClick(btn.id)"
    >
      {{
        (btn.data === undefined
          ? (btn.name | translate)
          : (btn.name | translate) + " " + btn.data
        ) | translate
      }}
    </label>
  </ng-container>
</div>
