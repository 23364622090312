<ng-container [formGroup]="formGroup">
  <div formArrayName="threats" class="form-container form-2">
    <ng-container
      *ngFor="let threatsForm of threats.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'hDCode'"
          [label]="'PRESSURE_THREAT'"
          [dropdownList]="threatsDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['hDCode'].value"
        ></app-dropdown>
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
          (valueChangedExtraData)="updateOtherSpecies($event, index)"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'siteOrSegment'"
          [label]="'SITE_OR_SEGMENTS'"
          [dropdownList]="extendDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['siteOrSegment'].value"
        ></app-dropdown>
      </div>

      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'populationPercentage'"
          [label]="'POPULATION_PERCENTAGE'"
          [dropdownList]="populationPercentageDropdown"
          [preDefinedId]="
            getGroup(index).controls['populationPercentage'].value
          "
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'impact'"
          [label]="'IMPACT_THREAT'"
          [dropdownList]="impactDropdown"
          [preDefinedId]="getGroup(index).controls['impact'].value"
        ></app-dropdown>
      </div>

      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'timeCharacter'"
          [label]="'TIME_CHARACTER_THREAT'"
          [dropdownList]="timeCharacterDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['timeCharacter'].value"
        ></app-dropdown>
      </div>
      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'impactType'"
        [label]="'IMPACT_TYPE'"
      ></app-textarea>
      <app-button
        (buttonClicked)="removeThreats(index)"
        [style.--btnPadding]="'6px 15px'"
        class="minus-btn"
        [btnText]="'-'"
        [tooltip]="'REMOVE_ENTRY' | translate"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== threats.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_THREAT'"
      (buttonClicked)="addNewThreats()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
