import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { IHabitatStruct, IImpactRequest } from 'src/app/core/dtos/habitat.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { HabitatService } from '../../services/habitat.service';

@Component({
  selector: 'app-habitat-impact',
  templateUrl: './habitat-impact.component.html',
  styleUrls: ['./habitat-impact.component.scss'],
})
export class HabitatImpactComponent implements OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctStructs: IHabitatStruct[] = [];
  public speciesForm!: FormGroup;
  public structsDropdown: IDropdownItem[] = [];

  public importanceDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'L',
    },
    {
      id: 'M',
      name: 'M',
    },
    {
      id: 'H',
      name: 'H',
    },
  ];

  @Input() public impacts!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public impactsById: IImpactRequest[] = [];
  @Input() public disabledControls = false;
  @Input() public sampleId?: number;

  constructor(private fb: FormBuilder, public habitatService: HabitatService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['impactsById'] && this.sampleId) {
      this.impactsById.forEach(() => {
        this.addNew();
      });
      this.impacts.patchValue(this.impactsById);
      this.impacts.markAsTouched();
      this.impactsById = [];
      if (this.disabledControls === true) {
        this.impacts.disable();
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.impacts.controls[index] as FormGroup;
  }

  public addNew(): void {
    this.impacts.push(this.habitatService.initImpactFormGroup(this.fb));
  }

  public remove(index: number): void {
    this.impacts.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
