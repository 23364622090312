import { inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { dateValidator } from 'src/app/shared/validators/dateValidator';
import { timeValidator } from 'src/app/shared/validators/timeValidator';

export class MammalsConfig {
  constructor(public common: CommonService) {}
}
export enum MammalsFormGroup {
  Species = 'species',
  RandomObservations = 'species',
  Threats = 'threats',
  Habitats = 'habitats',
}
export const mammalsConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
    ],
    removeValidations: [
      {
        formName: 'date',
        validators: [Validators.required, dateValidator()],
        removeLatLongValidator: false,
      },
      {
        formName: 'time',
        validators: [Validators.required, timeValidator()],
        removeLatLongValidator: false,
      },
      {
        formName: 'samplingCode',
        validators: [Validators.required],
        removeLatLongValidator: false,
      },
      {
        formName: 'financialInstrument',
        validators: [Validators.required],
        removeLatLongValidator: false,
      },
      {
        formName: 'lat',
        validators: [Validators.required],
        removeLatLongValidator: true,
      },
      {
        formName: 'long',
        validators: [Validators.required],
        removeLatLongValidator: true,
      },
      {
        formName: 'method',
        validators: [Validators.required],
        removeLatLongValidator: false,
      },
    ],
    configuration: [
      {
        header: 'Τυχαία Παρατήρηση',
        type: 'boolean',
        formName: 'randomObservation',
        order: 1,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 2,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 6,
      },
      {
        header: 'Ώρα Δειγματοληψίας',
        type: 'time',
        formName: 'time',
        order: 7,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 10,
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
        order: 11,
      },
      {
        header: 'Τοποθεσία',
        type: 'string',
        formName: 'locality',
        order: 16,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 21,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
        order: 22,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 26,
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Έργο εποπτείας / Φάση έργου',
        type: 'string',
        formName: 'ergoEpoptiaFasiErgou',
        order: 3,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 4,
      },
      {
        header: 'Ομάδα Ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 5,
      },
      {
        header: 'Διάρκεια',
        type: 'duration',
        formName: 'duration',
        order: 8,
      },
      {
        header: 'Συντάκτης',
        type: 'string',
        formName: 'author',
        order: 9,
      },
      {
        header: 'Κωδικός Μεθόδου Δειγματοληψίας',
        type: 'string',
        formName: 'method',
        order: 12,
      },
      {
        header: 'Μήκος Διαδρομής',
        type: 'float',
        formName: 'courseLength',
        order: 13,
      },
      {
        header: 'Σύλληψη - Επανασύλληψη',
        type: 'boolean',
        formName: 'capture',
        order: 14,
      },
      {
        header: 'Περιγραφή Ενδιαιτήματος',
        type: 'string',
        formName: 'habitat',
        order: 15,
      },
      {
        header: 'Θερμοκρασία',
        type: 'float',
        formName: 'temperature',
        order: 17,
      },
      {
        header: 'Ένταση Ανέμου',
        type: 'int',
        formName: 'windIntensity',
        order: 18,
      },
      {
        header: 'Ηλιοφάνεια / Νέφωση',
        type: 'string',
        formName: 'cloudiness',
        order: 19,
      },
      {
        header: 'Βροχόπτωση / Χιόνι',
        type: 'string',
        formName: 'raining',
        order: 20,
      },
      {
        header: 'Shapefile',
        type: 'string',
        formName: 'shapefile',
        order: 23,
      },
      {
        header: 'Υψόμετρο',
        type: 'float',
        formName: 'altitude',
        order: 24,
      },
      {
        header: 'Πιέσεις/Απειλές Ενδιαιτήματος',
        type: 'string',
        formName: 'habitatThreats',
        order: 25,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: MammalsFormGroup.Habitats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
        disabled: true,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
        disabled: true,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 1',
        type: 'string',
        formName: 'habitatCode1',
        order: 3,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 2',
        type: 'string',
        formName: 'habitatCode2',
        order: 4,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 3',
        type: 'string',
        formName: 'habitatCode3',
        order: 5,
      },
      {
        header: 'Ανθρώπινες Δραστηριότητες',
        type: 'string',
        formName: 'humanActivities',
        order: 6,
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: MammalsFormGroup.Species,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    validateLocation: [
      [
        'Γεωγραφικό Πλάτος (WGS84) είδους',
        'Γεωγραφικό Μήκος (WGS84) είδους',
        false,
      ],
    ],
    configuration: [
      {
        header: 'Obs_ID',
        type: 'string',
        formName: 'obs_ID',
        order: 1,
      },
      {
        header: 'Ομάδα Ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 2,
        disabled: true,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 3,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 4,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 5,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 6,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 7,
      },
      {
        header: 'Αριθμός ατόμων',
        type: 'int',
        formName: 'numberOfPeople',
        order: 8,
      },
      {
        header: 'Σημειώσεις για το/τα άτομα του είδους',
        type: 'string',
        formName: 'notes',
        order: 9,
      },
      {
        header: 'Δραστηριότητα είδους',
        type: 'string',
        formName: 'activities',
        order: 10,
      },
      {
        header: 'Είδος βιοδηλτικού ίχνους',
        type: 'string',
        formName: 'biomarker',
        order: 11,
      },
      {
        header: 'Ποσοτικά στοιχεία βιοδηλωτικών ιχνών',
        type: 'int',
        formName: 'biomarkerQuantity',
        order: 12,
      },
      {
        header: 'Ποιοτικά στοιχεία βιοδηλωτικών ιχνών',
        type: 'string',
        formName: 'biomarkerQuality',
        order: 13,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 1',
        type: 'string',
        formName: 'habitatCode1',
        order: 14,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 2',
        type: 'string',
        formName: 'habitatCode2',
        order: 15,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 3',
        type: 'string',
        formName: 'habitatCode3',
        order: 16,
      },
      {
        header: 'Μικροενδιαίτημα είδους',
        type: 'string',
        formName: 'microHabitat',
        order: 17,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84) είδους',
        type: 'float',
        formName: 'pointX',
        order: 18,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84) είδους',
        type: 'float',
        formName: 'pointY',
        order: 19,
      },
      {
        header: 'Υψόμετρο (m) είδους',
        type: 'float',
        formName: 'altitude',
        order: 20,
      },
      {
        header: 'Ποιότητα ενδιαιτήματος είδους',
        type: 'string',
        formName: 'habitatQuality',
        order: 21,
      },
      {
        header: 'Παρατηρήσεις είδους',
        type: 'string',
        formName: 'observations',
        order: 22,
      },
    ],
  },
  // This is the 4th sheet
  {
    isRandomSheet: true,
    formGroupName: MammalsFormGroup.RandomObservations,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    validateLocation: [
      [
        'Γεωγραφικό Πλάτος (WGS84) είδους',
        'Γεωγραφικό Μήκος (WGS84) είδους',
        false,
      ],
    ],
    addValidations: [
      {
        formName: 'pointX',
        validators: [Validators.required],
      },
      {
        formName: 'pointY',
        validators: [Validators.required],
      },
    ],
    configuration: [
      {
        header: 'Obs_ID',
        type: 'string',
        formName: 'obs_ID',
        order: 1,
      },
      {
        header: 'Ομάδα Ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 2,
        disabled: true,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 3,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 4,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 5,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 6,
      },
      {
        header: 'Αριθμός ατόμων',
        type: 'int',
        formName: 'numberOfPeople',
        order: 7,
      },
      {
        header: 'Σημειώσεις για το/τα άτομα του είδους',
        type: 'string',
        formName: 'notes',
        order: 8,
      },
      {
        header: 'Δραστηριότητα είδους',
        type: 'string',
        formName: 'activities',
        order: 9,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 1',
        type: 'string',
        formName: 'habitatCode1',
        order: 10,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 2',
        type: 'string',
        formName: 'habitatCode2',
        order: 11,
      },
      {
        header: 'Κωδικός Ενδιαιτήματος 3',
        type: 'string',
        formName: 'habitatCode3',
        order: 12,
      },
      {
        header: 'Μικροενδιαίτημα είδους',
        type: 'string',
        formName: 'microHabitat',
        order: 13,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84) είδους',
        type: 'float',
        formName: 'pointX',
        order: 14,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84) είδους',
        type: 'float',
        formName: 'pointY',
        order: 15,
      },
      {
        header: 'Υψόμετρο (m) είδους',
        type: 'float',
        formName: 'altitude',
        order: 16,
      },
      {
        header: 'Ποιότητα ενδιαιτήματος είδους',
        type: 'string',
        formName: 'habitatQuality',
        order: 17,
      },
      {
        header: 'Παρατηρήσεις είδους',
        type: 'string',
        formName: 'observations',
        order: 18,
      },
    ],
  },
  // This is the 5th sheet
  {
    formGroupName: MammalsFormGroup.Threats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 1,
        disabled: true,
      },
      {
        header: 'Ομάδα Ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 2,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 3,
        disabled: true,
      },
      {
        header: 'Obs_ID Τυχαίας Παρατήρησης',
        type: 'string',
        formName: 'obs_ID',
        order: 4,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'speciesId',
        order: 5,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'speciesIdOther',
        order: 6,
      },
      {
        header: 'Κωδικός πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsId',
        order: 7,
      },
      {
        header: 'Όνομα είδους απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsDescription',
        order: 8,
        disabled: true,
      },
      {
        header: 'Σημασία / Επίδραση πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'importance',
        order: 9,
      },
      {
        header: 'Χρονικός Χαρακτήρας πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'timeCharacter',
        order: 10,
      },
      {
        header: 'Τρόπος Επίδρασης πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'effectMode',
        order: 11,
      },
    ],
  },
];
