import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, finalize } from 'rxjs';
import { ChlorisService } from '../../services/chloris.service';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { IChlorisPopulations } from 'src/app/core/dtos/chloris.dto';

@Component({
  selector: 'app-chloris-population',
  templateUrl: './chloris-population.component.html',
  styleUrls: ['./chloris-population.component.scss'],
})
export class ChlorisPopulationComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() public populations!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public populationsById: IChlorisPopulations[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  public CTVitality: any[] = [];
  public CTSex: any[] = [];
  public CTUnit: any[] = [];
  public CTStrat: any[] = [];
  public CTQuadrat: any[] = [];

  public vitDropdown: IDropdownItem[] = [];
  public sexDropdown: IDropdownItem[] = [];
  public unitsDropdown: IDropdownItem[] = [];
  public stratDropdown: IDropdownItem[] = [];
  public methodDropdown: IDropdownItem[] = [];

  private destroy$ = new Subject<void>();
  public populationsForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private chlorisService: ChlorisService
  ) {}

  ngOnInit(): void {
    if (this.populationsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['populationsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.chlorisService.getChlorisVitality(this.dataVersion),
      this.chlorisService.getChlorisSex(this.dataVersion),
      this.chlorisService.getChlorisUnits(this.dataVersion),
      this.chlorisService.getChlorisStratifications(this.dataVersion),
      this.chlorisService.getQuadrat(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.CTVitality.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.CTVitality.forEach((vitality) => {
              dropdown.push({
                id: vitality.code,
                name: vitality.description,
              });
            });
            this.vitDropdown = dropdown;
          }

          if (this.CTSex.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.CTSex.forEach((sex) => {
              dropdown.push({
                id: sex.sex,
                name: sex.sex,
              });
            });
            this.sexDropdown = dropdown;
          }

          if (this.CTUnit.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.CTUnit.forEach((unit) => {
              dropdown.push({
                id: unit.code,
                name: unit.code,
              });
            });
            this.unitsDropdown = dropdown;
          }

          if (this.CTStrat.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.CTStrat.forEach((strat) => {
              dropdown.push({
                id: strat.code,
                name: strat.code,
              });
            });
            this.stratDropdown = dropdown;
          }

          if (this.CTQuadrat.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.CTQuadrat.forEach((quadrat) => {
              dropdown.push({
                id: quadrat.code,
                name: quadrat.description,
              });
            });
            this.methodDropdown = dropdown;
          }

          this.formGroup.controls['method'].valueChanges.subscribe((value) => {
            if (value === 'ΟΛΑ ΤΑ ΑΤΟΜΑ' || value === 'ΠΑΡΟΥΣΙΑ/ΑΠΟΥΣΙΑ') {
              this.populations.controls.forEach((control) => {
                (control as FormGroup).controls['samplingSurface'].setValue(
                  '',
                  {
                    emitEvent: false,
                  }
                );
                (control as FormGroup).controls['samplingSurface'].disable({
                  emitEvent: false,
                });
              });
            } else {
              this.populations.controls.forEach((control) => {
                (control as FormGroup).controls['samplingSurface'].enable({
                  emitEvent: false,
                });
              });
            }
          });

          if (this.populationsById.length > 0) {
            this.populationsById.forEach((item) => {
              this.addNewPopulations();
            });
            this.populations.patchValue(this.populationsById);
            this.populations.markAsTouched();
            this.populationsById = [];
            if (this.disabledControls === true) {
              this.populations.disable();
            }
          }
          return;
        })
      )
      .subscribe(([vitality, sex, unit, strat, quadrat]) => {
        if (vitality != undefined) {
          this.CTVitality = vitality;
        }

        if (sex != undefined) {
          this.CTSex = sex;
        }

        if (unit != undefined) {
          this.CTUnit = unit;
        }

        if (strat != undefined) {
          this.CTStrat = strat;
        }

        if (quadrat != undefined) {
          this.CTQuadrat = quadrat;
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.populations.controls[index] as FormGroup;
  }

  public addNewPopulations(): void {
    const fg = this.chlorisService.initPopulationsFormGroup(this.fb);
    if (
      this.formGroup.controls['method'].value === 'ΟΛΑ ΤΑ ΑΤΟΜΑ' ||
      this.formGroup.controls['method'].value === 'ΠΑΡΟΥΣΙΑ/ΑΠΟΥΣΙΑ' ||
      this.formGroup.controls['method'].value === ''
    ) {
      fg.controls['samplingSurface'].disable({
        emitEvent: false,
      });
    }
    this.populations.push(fg);
  }

  public removePopulations(index: number): void {
    this.populations.removeAt(index);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
