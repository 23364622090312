<div [formGroup]="formGroup" class="input-container">
  <label class="label-text" *ngIf="label"
    >{{ label | translate
    }}<span *ngIf="(required || isRequired) && !disabled"> *</span></label
  >
  <input
    [type]="type"
    class="form-control"
    [placeholder]="placeholder"
    aria-label=""
    aria-describedby="basic-addon1"
    [formControlName]="formName"
    [mask]="mask !== undefined ? mask : ''"
    [allowNegativeNumbers]="allowNegativeNumbers"
    thousandSeparator=","
    [separatorLimit]="allowNegativeNumbers ? '10' : ''"
    [suffix]="suffix !== undefined ? suffix : ''"
    [showMaskTyped]="showMaskTyped !== undefined ? showMaskTyped : true"
    [ngClass]="{ disabled: disabled }"
    [disabled]="disabled"
    [ngClass]="{ 'error-status': touched && hasErrors }"
  />
  <div class="error-message">
    <ng-container *ngIf="touched && hasErrors">
      <ng-container
        *ngIf="
          errors != null && errors['required'] !== null && errors['required']
        "
      >
        {{ "FIELD_REQUIRED_ERROR" | translate }}.
      </ng-container>
      <ng-container
        *ngIf="errors != null && errors['min'] !== null && errors['min']"
      >
        {{ "FIELD_MIN_ERROR" | translate }}
        {{ errors["min"]["min"] }}.
      </ng-container>
      <ng-container
        *ngIf="errors != null && errors['max'] !== null && errors['max']"
      >
        {{ "FIELD_MAX_ERROR" | translate }}
        {{ errors["max"]["max"] }}.
      </ng-container>
      <ng-container
        *ngIf="errors != null && errors['mask'] !== null && errors['mask']"
      >
        {{ "FIELD_MASK_ERROR" | translate }}.
      </ng-container>
      <ng-container
        *ngIf="
          errors != null &&
          errors['pattern'] !== null &&
          errors['pattern'] &&
          errors['pattern']['requiredPattern'] !== '/^\\d+-+\\d+$/'
        "
        >{{ "FIELD_MASK_ERROR" | translate }}.
      </ng-container>
      <ng-container
        *ngIf="
          errors != null &&
          errors['pattern'] !== null &&
          errors['pattern'] &&
          errors['pattern']['requiredPattern'] === '/^\\d+-+\\d+$/'
        "
      >
        {{ "FIELD_MASK_ERROR_DASHED_NUMBERS" | translate }}
      </ng-container>

      <ng-container
        *ngIf="
          errors != null &&
          errors['invalidDate'] !== null &&
          errors['invalidDate']
        "
      >
        {{ "FIELD_INVALID_DATE" | translate }}
      </ng-container>

      <ng-container
        *ngIf="
          errors != null &&
          errors['invalidTime'] !== null &&
          errors['invalidTime']
        "
      >
        {{ "FIELD_INVALID_TIME" | translate }}
      </ng-container>

      <ng-container
        *ngIf="
          errors != null &&
          errors['outOfBound'] !== null &&
          errors['outOfBound']
        "
      >
        {{ "FIELD_MAP_OUT_ERROR" | translate }}.
      </ng-container>
    </ng-container>
  </div>
</div>
