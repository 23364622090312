import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '../services/toast.service';

export interface Toast {
  textOrTpl: string | TemplateRef<Element>;
  options: { header?: string; autohide?: boolean };
  classname?: string;
  delay?: number;
}

@Component({
  selector: 'app-simple-toast',
  templateUrl: './simple-toast.component.html',
  styleUrls: ['./simple-toast.component.scss'],
})
export class SimpleToastComponent {
  public show = true;
  constructor(public toastService: ToastService) {}

  /**
   * Checks if toast content is template or text.
   *
   * @param {Toast} toast toast content to be displayed.
   *@returns {boolean} true if toast content is a Template
   */
  public isTemplate(toast: Toast): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }
  /**
   * Converts to correct content type.
   *
   * @param {Toast} toast the displayed toast.
   * @returns {string} content as type string to append to component.
   */
  public convertToStringType(toast: Toast): string {
    return toast.textOrTpl as string;
  }
  /**
   * Converts to correct content type.
   *
   * @param {Toast} toast the displayed toast.
   * @returns {TemplateRef} content as type TemplateRef to append to component.
   */
  public convertToTemplateType(toast: Toast): TemplateRef<Element> {
    return toast.textOrTpl as TemplateRef<Element>;
  }
}
