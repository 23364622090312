import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ChlorisService } from '../../services/chloris.service';
import { ProtocolsService } from '../../services/protocols.service';
import { Subject, catchError, takeUntil, forkJoin, finalize } from 'rxjs';
import {
  IChlorisDto,
  IChlorisFunctions,
  IChlorisPopulations,
  IChlorisPressures,
} from 'src/app/core/dtos/chloris.dto';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { ITabBtnGroup } from 'src/app/shared/button-group/button-group.component';
import { IMammalThreatsDto } from 'src/app/core/dtos/mammals.dto';
import {
  INaturaSitesDto,
  IPrefecturesDto,
  IHabitatSpeciesDto,
  IHabitatTypesDto,
  ICellCodeDto,
} from 'src/app/core/dtos/protocols.dto';
import { ISampleIdResponse } from 'src/app/shared/dtos/generic.dto';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import {
  IFileDeleteRequest,
  IFilePayload,
} from 'src/app/shared/files-container/files-container.component';

@Component({
  selector: 'app-chloris',
  templateUrl: './chloris.component.html',
  styleUrls: ['./chloris.component.scss'],
})
export class ChlorisComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public chloris: IChlorisDto = {};
  public chlorisById?: IChlorisDto;
  public chlorisForm!: FormGroup;
  public coordinates: ICoordinates | undefined;
  public codesDropdown: IDropdownItem[] = [];
  public prefecturesDropdown: IDropdownItem[] = [];
  public naturaSites: INaturaSitesDto[] = [];
  public sitesDropdown: IDropdownItem[] = [];
  public prefectures: IPrefecturesDto[] = [];
  public habitatSpecies: IHabitatSpeciesDto[] = [];
  public habitatSpeciesDropdown: IDropdownItem[] = [];
  public habitatTypes: IHabitatTypesDto[] = [];
  public habitatTypesDropdown: IDropdownItem[] = [];
  public ctSpecies: any[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public ctRelief: any[] = [];
  public reliefDropdown: IDropdownItem[] = [];
  public ctSoil: any[] = [];
  public soilDropdown: IDropdownItem[] = [];
  public ctDepth: any[] = [];
  public depthDropdown: IDropdownItem[] = [];
  public ctExposition: any[] = [];
  public expositionDropdown: IDropdownItem[] = [];
  public mammalThreats: IMammalThreatsDto[] = [];
  public codes: ICellCodeDto[] = [];
  public predefinedFiles: IFilePayload[] = [];

  public files: any[] = [];
  public filesToDelete: any[] = [];
  public errors: ValidationErrors[] = [];
  public functionsById: IChlorisFunctions[] = [];
  public populationsById: IChlorisPopulations[] = [];
  public pressuresById: IChlorisPressures[] = [];

  @Input() public protocolId!: number;
  @Input() public sampleId?: number;
  @Input() public readOnlyForm = false;

  public coordSystemDropdown: IDropdownItem[] = [
    { id: 'EGSA', name: 'EGSA' },
    { id: 'WGS84', name: 'WGS84' },
  ];
  public tabBtn: ITabBtnGroup[] = [
    { id: 'map', name: 'Επέλεξε τοποθεσία', isChecked: true },
    { id: 'coordinates', name: 'Εισαγωγή συντεταγμένων', isChecked: false },
  ];
  public multiTabGroup = [
    { id: 0, name: 'Γεωγραφία', isChecked: true },
    { id: 1, name: 'Δομές και Λειτουργίες', isChecked: true },
    { id: 2, name: 'Πιέσεις/Απειλές', isChecked: true },
    { id: 3, name: 'Μετρήσεις Πληθυσμού', isChecked: true },
  ];
  public financialDropdown: IDropdownItem[] = [
    { id: 'ΕΠΠΕΡΑΑ 2007-2013', name: 'ΕΠΠΕΡΑΑ 2007-2013' },
    { id: 'ΥΜΕΠΕΡΑΑ', name: 'ΥΜΕΠΕΡΑΑ' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];

  public ergoEpoptiaFasiErgouDropdown: IDropdownItem[] = [
    { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
    { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
    { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
    { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
    { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
  ];

  public sufficiencyDropdown: IDropdownItem[] = [
    { id: 'Επαρκής', name: 'Επαρκής' },
    { id: 'Ανεπαρκής', name: 'Ανεπαρκής' },
    { id: 'Άγνωστη', name: 'Άγνωστη' },
  ];

  public trendingDropdown: IDropdownItem[] = [
    { id: '+', name: '+' },
    { id: '=', name: '=' },
    { id: '-', name: '-' },
    { id: 'Αβέβαιη', name: 'Αβέβαιη' },
    { id: 'Άγνωστη', name: 'Άγνωστη' },
  ];

  public fixPossibilityDropdown: IDropdownItem[] = [
    { id: 'Εύκολη', name: 'Εύκολη' },
    { id: 'Δυνατή', name: 'Δυνατή' },
    { id: 'Δύσκολη/Αδύνατη', name: 'Δύσκολη/Αδύνατη' },
  ];

  public get populations(): FormArray {
    return this.chlorisForm.controls['populations'] as FormArray;
  }

  public get functions(): FormArray {
    return this.chlorisForm.controls['functions'] as FormArray;
  }

  public get pressures(): FormArray {
    return this.chlorisForm.controls['pressures'] as FormArray;
  }

  public get coordSystem(): number {
    return this.chlorisForm.controls['isWGS84'].value ? 1 : 0;
  }

  constructor(
    private fb: FormBuilder,
    private protocolService: ProtocolsService,
    private router: Router,
    public toastService: ToastService,
    public common: CommonService,
    public chlorisService: ChlorisService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      protocolId: number;
    };
    this.protocolId = state?.protocolId;
  }

  ngOnInit(): void {
    this.protocolId = history.state.protocolId;
    this.initChlorisFormGroup();
    this.chlorisForm.controls['otherSpecies'].disable({
      emitEvent: false,
    });

    if (this.sampleId) {
      this.chlorisService
        .getChlorisById(this.sampleId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.getValuesFromServices();
            return;
          })
        )
        .subscribe((res) => {
          if (res != undefined) {
            this.chlorisById = res as IChlorisDto;
            // Fix different property names
            this.chlorisById.lat = this.chlorisById.pointX;
            this.chlorisById.long = this.chlorisById.pointY;

            if (res.files != null) {
              this.predefinedFiles = res.files as any;
            }

            // Fix date
            if (this.chlorisById.date) {
              const convertedDate = new Date(this.chlorisById.date);
              const dateString =
                convertedDate.getDate().toString().padStart(2, '0') +
                (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                convertedDate.getFullYear().toString();
              this.chlorisById.date = dateString;
            }
            // Fix time
            if (this.chlorisById.time) {
              const timeString = this.chlorisById.time
                .toString()
                .slice(0, 5)
                .replaceAll(':', '');
              this.chlorisById.time = timeString;
            }
          }
        });
    } else {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.protocolService.getCellCodes(),
      this.protocolService.getNaturaSites(this.chlorisById?.dataVersion),
      this.protocolService.getPrefectures(this.chlorisById?.dataVersion),
      this.chlorisService.getSpecies(this.chlorisById?.dataVersion),
      this.chlorisService.getSoil(this.chlorisById?.dataVersion),
      this.chlorisService.getDepth(this.chlorisById?.dataVersion),
      this.chlorisService.getExpo(this.chlorisById?.dataVersion),
      this.chlorisService.getRelief(this.chlorisById?.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.codes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.codes.forEach((code) => {
              dropdown.push({
                id: code.cellCode,
                name: code.cellCode,
              });
            });
            this.codesDropdown = dropdown;
          }

          if (this.naturaSites.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.naturaSites.forEach((site) => {
              dropdown.push({ id: site.naturaCode, name: site.name });
            });
            this.sitesDropdown = dropdown;
          }

          if (this.prefectures.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.prefectures.forEach((prefecture) => {
              dropdown.push({
                id: prefecture.id,
                name: prefecture.prefecture,
              });
            });
            this.prefecturesDropdown = dropdown;
          }

          if (this.ctSpecies.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctSpecies.forEach((code, i) => {
              dropdown.push({
                id: code.code === 'Άλλο' ? 'Άλλο' : code.speciesCodeEU,
                name: code.code,
                actualId: code.id,
              });
            });
            this.speciesDropdown = dropdown;
          }

          if (this.ctSoil.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctSoil.forEach((code) => {
              dropdown.push({
                id: code.code,
                name: code.descriptionGR,
              });
            });
            this.soilDropdown = dropdown;
          }

          if (this.ctDepth.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctDepth.forEach((code) => {
              dropdown.push({
                id: code.code,
                name: code.descriptionGR,
              });
            });
            this.depthDropdown = dropdown;
          }

          if (this.ctExposition.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctExposition.forEach((code) => {
              dropdown.push({
                id: code.code,
                name: code.codeGR,
              });
            });
            this.expositionDropdown = dropdown;
          }

          if (this.ctRelief.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctRelief.forEach((code) => {
              dropdown.push({
                id: code.code,
                name: code.descriptionGR,
              });
            });
            this.reliefDropdown = dropdown;
          }

          this.chlorisForm.controls['species'].valueChanges.subscribe(
            (value) => {
              this.checkSpecies(value);
              if (!this.chlorisForm.controls['species'].disabled) {
                const species = this.ctSpecies.find(
                  (item) => item.id === value
                );
                if (species == null) {
                  this.chlorisForm.controls['otherSpecies'].setValue('', {
                    emitEvent: false,
                  });
                  this.chlorisForm.controls['otherSpecies'].clearValidators();
                  this.chlorisForm.controls['otherSpecies'].disable({
                    emitEvent: false,
                  });
                  return;
                }

                if (species.code === 'Άλλο') {
                  this.chlorisForm.controls['otherSpecies'].enable({
                    emitEvent: false,
                  });
                  this.chlorisForm.controls['otherSpecies'].setValidators(
                    Validators.required
                  );
                  this.chlorisForm.controls[
                    'otherSpecies'
                  ].updateValueAndValidity({
                    emitEvent: false,
                    onlySelf: true,
                  });
                } else {
                  this.chlorisForm.controls['otherSpecies'].setValue('', {
                    emitEvent: false,
                  });
                  this.chlorisForm.controls['otherSpecies'].clearValidators();
                  this.chlorisForm.controls['otherSpecies'].disable({
                    emitEvent: false,
                  });
                  this.chlorisForm.controls[
                    'otherSpecies'
                  ].updateValueAndValidity({
                    emitEvent: false,
                    onlySelf: true,
                  });
                }
              }
            }
          );

          if (this.sampleId && this.chlorisById) {
            if (
              this.chlorisById.otherSpecies != null &&
              this.chlorisById.otherSpecies !== ''
            ) {
              this.chlorisForm.controls['otherSpecies'].enable({
                emitEvent: false,
              });
            }

            this.chlorisForm.patchValue(this.chlorisById);
            if (this.chlorisById.functions) {
              this.functionsById = this.chlorisById.functions;
            }
            if (this.chlorisById.pressures) {
              this.pressuresById = this.chlorisById.pressures;
            }
            if (this.chlorisById.populations) {
              this.populationsById = this.chlorisById.populations;
            }
            if (this.readOnlyForm === true) {
              this.chlorisForm.disable();
            }
            this.chlorisForm.updateValueAndValidity();
          }

          return;
        })
      )
      .subscribe(
        ([
          codes,
          naturaSites,
          prefectures,
          ctSpecies,
          ctSoil,
          ctDepth,
          ctExposition,
          ctRelief,
        ]) => {
          if (codes != undefined) {
            this.codes = codes as ICellCodeDto[];
          }

          if (naturaSites != undefined) {
            this.naturaSites = naturaSites as INaturaSitesDto[];
          }

          if (prefectures != undefined) {
            this.prefectures = prefectures as IPrefecturesDto[];
          }

          if (ctSpecies != undefined) {
            this.ctSpecies = ctSpecies as any[];
          }

          if (ctSoil != undefined) {
            this.ctSoil = ctSoil as any[];
          }

          if (ctDepth != undefined) {
            this.ctDepth = ctDepth as any[];
          }

          if (ctExposition != undefined) {
            this.ctExposition = ctExposition as any[];
          }

          if (ctRelief != undefined) {
            this.ctRelief = ctRelief as any[];
          }
        }
      );
  }

  public checkSpecies(species: string): void {
    let speciesCode = this.ctSpecies.find(
      (value) => value.id === parseInt(species)
    );
    if (speciesCode != null) {
      if (parseInt(species) === speciesCode.id) {
        if (speciesCode.annexII?.startsWith('Y')) {
          this.chlorisForm.controls['parII'].setValue(true, {
            emitEvent: false,
          });
        } else {
          this.chlorisForm.controls['parII'].setValue(false, {
            emitEvent: false,
          });
        }
        if (speciesCode.annexIV?.startsWith('Y')) {
          this.chlorisForm.controls['parIV'].setValue(true, {
            emitEvent: false,
          });
        } else {
          this.chlorisForm.controls['parIV'].setValue(false, {
            emitEvent: false,
          });
        }
        if (speciesCode.annexV?.startsWith('Y')) {
          this.chlorisForm.controls['parV'].setValue(true, {
            emitEvent: false,
          });
        } else {
          this.chlorisForm.controls['parV'].setValue(false, {
            emitEvent: false,
          });
        }
      }
    }
  }

  initChlorisFormGroup() {
    this.chlorisForm = this.chlorisService.initChlorisFormGroup(this.fb);
    this.monitorCoords();
    this.common.monitorCoordSystem(this.chlorisForm, this.destroy$);
  }

  private monitorCoords(): void {
    this.common
      .monitorCoords(this.destroy$, this.chlorisForm, 'x', 'y', 'lat', 'long')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICoordinates) => (this.coordinates = data));
  }

  /**
   * Adds form value in corresponding observable.
   */
  public prepareChlorisRequest(): void {
    this.errors = this.common.validateAllFormControl(this.chlorisForm);
    if (this.errors.length > 0) {
      return;
    }

    this.chloris = this.chlorisService.prepareChlorisRequest(
      this.chlorisForm,
      false,
      this.ctSpecies,
      this.sampleId != null
    );

    if (this.sampleId && !this.readOnlyForm) {
      this.updateSample();
    } else {
      this.registerNewSample();
    }
  }

  public registerNewSample(): void {
    this.chlorisService
      .addChloris(this.chloris)
      .pipe(
        catchError((err) => {
          this.toastService.show('GENERIC_FAILURE_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
          throw err;
        })
      )
      .subscribe((sampleIdResponse: ISampleIdResponse) => {
        if (this.files.length > 0) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append('files', file);
          });

          formData.append('SampleId', sampleIdResponse.sampleId.toString());
          this.chlorisService
            .addFiles(formData)
            .pipe(
              catchError((err) => {
                this.toastService.show('GENERIC_FAILURE_TEXT', {
                  header: 'GENERIC_FAILURE_HEADER',
                });
                throw err;
              })
            )
            .subscribe(() => {
              this.toastService.show('GENERIC_SUCCESS_TEXT', {
                header: 'GENERIC_SUCCESS_HEADER',
              });

              this.activeModal.close({
                sampleId: sampleIdResponse.sampleId,
                samplingCode: this.chlorisForm.value.samplingCode,
              });
            });
        } else {
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.chlorisForm.value.samplingCode,
          });
        }
      });
  }

  public updateSample(): void {
    if (this.sampleId) {
      this.chlorisService
        .putChloris(this.sampleId, this.chloris)
        .pipe(
          catchError((err) => {
            this.toastService.show('GENERIC_FAILURE_TEXT', {
              header: 'GENERIC_FAILURE_HEADER',
            });
            throw err;
          })
        )
        .subscribe((sampleIdResponse: ISampleIdResponse) => {
          if (this.files != null) {
            // Uploading new files
            const formData = new FormData();
            let addFiles = false;
            this.files.forEach((file: any) => {
              if (file instanceof File) {
                addFiles = true;
                formData.append('files', file);
              }
            });

            if (addFiles) {
              formData.append('SampleId', (this.sampleId as number).toString());
              this.chlorisService
                .addFiles(formData)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          // Deleting files
          if (this.filesToDelete != null && this.filesToDelete.length > 0) {
            const payload: IFileDeleteRequest = {
              sampleId: this.sampleId as number,
              fileIds: [],
            };
            this.filesToDelete.forEach((file) => {
              if (file.id != null) {
                payload.fileIds.push(file.id);
              }
            });

            if (payload.fileIds.length > 0) {
              this.chlorisService
                .removeFiles(payload)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.chlorisForm.value.samplingCode,
          });
        });
    }
  }

  public confirmClose(): void {
    if (this.chlorisForm.dirty) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.result.then((data) => {
        if (data) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  /**
   * Change view
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public tabBtnClick(btnId: string) {
    if (btnId === 'map') {
      this.tabBtn[0].isChecked = true;
      this.tabBtn[1].isChecked = false;
    } else {
      this.tabBtn[1].isChecked = true;
      this.tabBtn[0].isChecked = false;
    }
  }

  /**
   * Find the new coordinates of the map
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public onMarkerMoved(coordinates: { lat: number; lng: number }): void {
    this.coordinates = coordinates;
    this.submitCoordinates();
  }

  public submitCoordinates(): void {
    if (this.coordinates != null) {
      this.chlorisForm.controls['lat'].patchValue(this.coordinates.lat, {
        emitEvent: false,
      });
      this.chlorisForm.controls['long'].patchValue(this.coordinates.lng, {
        emitEvent: false,
      });

      const coordinates = this.common.convertWGStoEGSA(
        this.coordinates.lat,
        this.coordinates.lng
      );
      this.chlorisForm.controls['x'].patchValue(coordinates.x, {
        emitEvent: false,
      });
      this.chlorisForm.controls['y'].patchValue(coordinates.y, {
        emitEvent: false,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
