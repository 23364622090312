export enum DataType {
  Numerical,
  String,
  Boolean,
  Date,
  Time,
  List,
}

export enum DataSubType {
  Int32,
  Int32N,
  Int64,
  Int64N,
  Float,
  FloatN,
  Boolean,
  BooleanN,
  Date,
  DateN,
  Time,
  TimeN,
  Double,
  DoubleN
}

export enum FilterType {
  Equal,
  NotEqual,
  GreaterThan,
  LessThan,
  GreaterThanOrEqual,
  LessThanOrEqual,
  StartsWith,
  EndsWith,
  Contains
}

export enum FilterOperator {
  And,
  Or
}
