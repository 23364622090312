import { Component } from '@angular/core';
import { TableWrapperComponent } from '../table-wrapper/table-wrapper.component';
import { ITableFilterRequest } from '../table/interfaces/table.interfaces';
import { SearchService } from '../services/search.service';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { FormBuilder } from '@angular/forms';
import {
  IDataTableColumns,
  sampleAmphibianColumns,
  sampleAmphibianHabitatsColumns,
  sampleAmphibianRandomSpeciessColumns,
  sampleAmphibianSpeciessColumns,
  sampleAmphibianThreatsColumns,
  sampleColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import { LazyLoadEvent } from 'primeng/api';
import {
  IAmphibianDto,
  IAmphibianSampleHabitatsDto,
  IAmphibianSampleSpeciesDto,
  IAmphibianSpeciesThreatsDto,
} from 'src/app/core/dtos/amphibian.dto';
import { AmphibiansService } from 'src/app/samplings/services/amphibians.service';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  amphibianConfig,
  AmphibianFormGroup,
} from 'src/app/samplings/config-files/amphibian-config';
import { utils, writeFile } from 'xlsx';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-amphibian-search',
  templateUrl: './amphibian-search.component.html',
  styleUrls: ['./amphibian-search.component.scss'],
})
export class AmphibianSearchComponent extends TableWrapperComponent {
  public sampleAmphibiansTotalRecords: number = 0;
  public sampleAmphibians: IAmphibianDto[] = [];
  public sampleAmphibianColumns: IDataTableColumns[] = [];
  public sampleAmphibianFirst = 0;

  public speciesTotalRecords: number = 0;
  public species: IAmphibianSampleSpeciesDto[] = [];
  public speciesColumns: IDataTableColumns[] = [];
  public speciesFirst = 0;

  public randomSpeciesTotalRecords: number = 0;
  public randomSpecies: IAmphibianSampleSpeciesDto[] = [];
  public randomSpeciesColumns: IDataTableColumns[] = [];
  public randomSpeciesFirst = 0;

  public threatsTotalRecords: number = 0;
  public threats: IAmphibianSpeciesThreatsDto[] = [];
  public threatsColumns: IDataTableColumns[] = [];
  public threatsFirst = 0;

  public habitatsTotalRecords: number = 0;
  public habitats: IAmphibianSampleHabitatsDto[] = [];
  public habitatsColumns: IDataTableColumns[] = [];
  public habitatsFirst = 0;

  protected override payload: ITableFilterRequest = {
    samples: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    sampleAmphibians: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    species: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    speciesRandomAmphibians: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    threats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    habitats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
  };
  private tablesInitialized: number = 0;

  public activeTabId: number = 0;
  public multiTabGroup = [
    { id: 0, name: 'SEARCH_SAMPLES', isChecked: true },
    { id: 1, name: 'SEARCH_SAMPLES_AMPHIBIANS', isChecked: true },
    { id: 2, name: 'SEARCH_SPECIES', isChecked: true },
    { id: 3, name: 'RANDOM_OBSERVATION', isChecked: true },
    { id: 4, name: 'SEARCH_THREATS', isChecked: true },
    { id: 5, name: 'SEARCH_HABITATS', isChecked: true },
  ];

  constructor(
    protected override searchService: SearchService,
    protected override protocolService: ProtocolsService,
    protected override fb: FormBuilder,
    private amphibiansService: AmphibiansService,
    protected override translate: TranslateService,
    private modalService: NgbModal,
    protected override toastService: ToastService
  ) {
    super(searchService, protocolService, fb, translate, toastService);
  }

  ngOnInit(): void {
    this.fetchSampleAmphibianColumns();
    this.fetchSampleColumns();
    this.fetchSpeciesColumns();
    this.fetchRandomSpeciesColumns();
    this.fetchThreatsColumns();
    this.fetchHabitatsColumns();

    this.watchDataversion();
  }

  protected override fetchSampleColumns(): void {
    this.sampleColumns = sampleColumns;
    const sampleColumnsTemp = [...sampleColumns];
    sampleColumnsTemp.forEach((column) => {
      if (
        column.searchDataType === DataType.List &&
        (column.data == null || column.data.length === 0)
      ) {
        if (column.propertyName === 'prefecture') {
          this.protocolService.getPrefectures().subscribe((data) => {
            column.data = data.map((prefecture) => {
              return { id: prefecture.id, name: prefecture.prefecture };
            });
            this.sampleColumns = sampleColumnsTemp;
          });
        }
      }

      if (column.header === 'RANDOM_OBSERVATION') {
        column.shown = true;
        this.sampleColumns = sampleColumnsTemp;
      }
    });
  }

  protected override fetchSpeciesColumns(): void {
    this.speciesColumns = sampleAmphibianSpeciessColumns;
    forkJoin([
      this.searchService.getSpecies(4, this.version),
      this.amphibiansService.getAmphibianMicroHabitat(this.version),
      this.amphibiansService.getAmphibianActivity(this.version),
      this.amphibiansService.getAmphibianQuality(this.version),
      this.amphibiansService.getAmphibianHabitatA(this.version),
      this.amphibiansService.getAmphibianHabitatB(this.version),
    ]).subscribe(
      ([species, microHabitats, activities, qualities, habitatA, habitatB]) => {
        if (species != null) {
          const speciesItem = this.speciesColumns.find(
            (item) => item.propertyName === 'species'
          );
          if (speciesItem != null) {
            speciesItem.data = species.map((item) => {
              return {
                id: item.id,
                name: `(${item.id}) - ${item.name}`,
              };
            });
          }
        }

        if (microHabitats != null) {
          const microItem = this.speciesColumns.find(
            (item) => item.propertyName === 'microHabitat'
          );
          if (microItem != null) {
            microItem.data = microHabitats.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }

        if (activities != null) {
          const activityItem = this.speciesColumns.find(
            (item) => item.propertyName === 'activity'
          );
          if (activityItem != null) {
            activityItem.data = activities.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }

        if (qualities != null) {
          const qualityItem = this.speciesColumns.find(
            (item) => item.propertyName === 'habitatQuality'
          );
          if (qualityItem != null) {
            qualityItem.data = qualities.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }

        if (habitatA != null) {
          const habItem = this.speciesColumns.find(
            (item) => item.propertyName === 'habitatCodeA'
          );
          if (habItem != null) {
            habItem.data = habitatA.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }

        if (habitatB != null) {
          const habItem = this.speciesColumns.find(
            (item) => item.propertyName === 'habitatCodeB'
          );
          if (habItem != null) {
            habItem.data = habitatB.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }
      }
    );
  }

  public fetchRandomSpeciesColumns(): void {
    this.randomSpeciesColumns = sampleAmphibianRandomSpeciessColumns;
    forkJoin([
      this.searchService.getSpecies(4, this.version),
      this.amphibiansService.getAmphibianMicroHabitat(this.version),
      this.amphibiansService.getAmphibianActivity(this.version),
      this.amphibiansService.getAmphibianQuality(this.version),
      this.amphibiansService.getAmphibianHabitatA(this.version),
      this.amphibiansService.getAmphibianHabitatB(this.version),
    ]).subscribe(
      ([species, microHabitats, activities, qualities, habitatA, habitatB]) => {
        if (species != null) {
          const speciesItem = this.randomSpeciesColumns.find(
            (item) => item.propertyName === 'species'
          );
          if (speciesItem != null) {
            speciesItem.data = species.map((item) => {
              return {
                id: item.id,
                name: `(${item.id}) - ${item.name}`,
              };
            });
          }
        }

        if (microHabitats != null) {
          const microItem = this.randomSpeciesColumns.find(
            (item) => item.propertyName === 'microHabitat'
          );
          if (microItem != null) {
            microItem.data = microHabitats.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }

        if (activities != null) {
          const activityItem = this.randomSpeciesColumns.find(
            (item) => item.propertyName === 'activity'
          );
          if (activityItem != null) {
            activityItem.data = activities.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }

        if (qualities != null) {
          const qualityItem = this.randomSpeciesColumns.find(
            (item) => item.propertyName === 'habitatQuality'
          );
          if (qualityItem != null) {
            qualityItem.data = qualities.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }

        if (habitatA != null) {
          const habItem = this.randomSpeciesColumns.find(
            (item) => item.propertyName === 'habitatCodeA'
          );
          if (habItem != null) {
            habItem.data = habitatA.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }

        if (habitatB != null) {
          const habItem = this.randomSpeciesColumns.find(
            (item) => item.propertyName === 'habitatCodeB'
          );
          if (habItem != null) {
            habItem.data = habitatB.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }
      }
    );
  }

  protected override fetchThreatsColumns(): void {
    this.threatsColumns = sampleAmphibianThreatsColumns;
  }

  private fetchSampleAmphibianColumns(): void {
    this.sampleAmphibianColumns = sampleAmphibianColumns;
    forkJoin([
      this.amphibiansService.getAmphibianMethod(this.version),
      this.amphibiansService.getAmphibianWeather(this.version),
      this.amphibiansService.getAmphibianRain(this.version),
    ]).subscribe(([methods, weather, rain]) => {
      if (methods != null) {
        const method = this.sampleAmphibianColumns.find(
          (item) => item.propertyName === 'method'
        );
        if (method != null) {
          method.data = methods.map((item) => {
            return {
              id: item.code,
              name: `(${item.code}) - ${item.description}`,
            };
          });
        }
      }

      if (weather != null) {
        const weatherItem = this.sampleAmphibianColumns.find(
          (item) => item.propertyName === 'hliof_Nefosi'
        );
        if (weatherItem != null) {
          weatherItem.data = weather.map((item) => {
            return {
              id: item.kodikos,
              name: `(${item.kodikos}) - ${item.perigrafi}`,
            };
          });
        }
      }

      if (rain != null) {
        const rainItem = this.sampleAmphibianColumns.find(
          (item) => item.propertyName === 'rainCode'
        );
        if (rainItem != null) {
          rainItem.data = rain.map((item) => {
            return {
              id: item.code,
              name: `(${item.code}) - ${item.description}`,
            };
          });
        }
      }
    });
  }

  private fetchHabitatsColumns(): void {
    this.habitatsColumns = sampleAmphibianHabitatsColumns;
    forkJoin([
      this.amphibiansService.getAmphibianHabitatA(this.version),
      this.amphibiansService.getAmphibianHabitatB(this.version),
    ]).subscribe(([habA, habB]) => {
      if (habA != null) {
        const habAItem = this.habitatsColumns.find(
          (item) => item.propertyName === 'habitatCodeA'
        );
        if (habAItem != null) {
          habAItem.data = habA.map((item) => {
            return {
              id: item.code,
              name: `(${item.code}) - ${item.description}`,
            };
          });
        }
      }

      if (habB != null) {
        const habBItem = this.habitatsColumns.find(
          (item) => item.propertyName === 'habitatCodeB'
        );
        if (habBItem != null) {
          habBItem.data = habB.map((item) => {
            return {
              id: item.code,
              name: `(${item.code}) - ${item.description}`,
            };
          });
        }
      }
    });
  }

  protected override watchDataversion(): void {
    this.subscription.add(
      this.form.controls['dataVersion'].valueChanges.subscribe((version) => {
        // Updating tables.
        const dataVersion = this.payload.samples?.items.find(
          (item) => item.name === 'DataVersion'
        );
        if (dataVersion != null) {
          if (dataVersion.rules != null && dataVersion.rules.length > 0) {
            dataVersion.rules[0].value = version;
          } else {
            dataVersion.rules = [
              {
                value: version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ];
          }
        } else {
          this.payload.samples?.items.push({
            name: 'DataVersion',
            dataType: DataType.String,
            rules: [
              {
                value: this.version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ],
          });
        }

        this.fetchSampleAmphibianColumns();
        this.fetchSampleColumns();
        this.fetchSpeciesColumns();
        this.fetchRandomSpeciesColumns();
        this.fetchThreatsColumns();
        this.fetchHabitatsColumns();

        this.fetchData();
      })
    );
  }

  protected override fetchData(): void {
    this.searchService.filterAmphibians(this.payload).subscribe((response) => {
      if (response != null) {
        if (response.samples != null) {
          this.sampleTotalRecords = response.samples.totalCount;

          // TODO: Uncomment this when we resolve the time filtering issue.
          // response.samples.rows.forEach(item => {
          //   if (item.time != null) {
          //     const timeString = item.time.toString().slice(0, 5);
          //     item.time = timeString;
          //   }
          // });

          this.samples = response.samples.rows;
        }

        if (response.sampleAmphibians != null) {
          this.sampleAmphibiansTotalRecords =
            response.sampleAmphibians.totalCount;
          this.sampleAmphibians = response.sampleAmphibians.rows;
          this.sampleCoordinates = this.sampleAmphibians
            .filter((item) => item.lat != null && item.long != null)
            .map((item) => {
              return {
                lat: item.lat,
                lng: item.long,
                text: item.samplingCode,
              };
            });
        }

        if (response.speciesAmphibians != null) {
          this.speciesTotalRecords = response.speciesAmphibians.totalCount;
          this.species = response.speciesAmphibians.rows;
          this.speciesCoordinates = this.species
            .filter(
              (item) =>
                item.pointX != null &&
                item.pointY != null &&
                item.pointX !== -1 &&
                item.pointY !== -1
            )
            .map((item) => {
              return {
                lat: item.pointX,
                lng: item.pointY,
                text:
                  item.species != null ? item.species.toString() : undefined,
              };
            });
        }

        if (response.randomSpeciesAmphibians != null) {
          this.randomSpeciesTotalRecords =
            response.randomSpeciesAmphibians.totalCount;
          this.randomSpecies = response.randomSpeciesAmphibians.rows;
          this.randomSpeciesCoordinates = this.randomSpecies
            .filter(
              (item) =>
                item.pointX != null &&
                item.pointY != null &&
                item.pointX !== -1 &&
                item.pointY !== -1
            )
            .map((item) => {
              return {
                lat: item.pointX,
                lng: item.pointY,
                text:
                  item.species != null ? item.species.toString() : undefined,
              };
            });
        }

        if (response.threatsAmphibians != null) {
          this.threatsTotalRecords = response.threatsAmphibians.totalCount;
          this.threats = response.threatsAmphibians.rows;
        }

        if (response.habitatsAmphibians != null) {
          this.habitatsTotalRecords = response.habitatsAmphibians.totalCount;
          this.habitats = response.habitatsAmphibians.rows;
        }
      }
    });
  }

  public loadSampleAmphibiansData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleAmphibianColumns
    );
    this.payload.sampleAmphibians = tableFilter;

    if (event.first) {
      this.sampleAmphibianFirst = event.first;
    }

    this.fetchData();
  }

  public loadSpeciesData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleAmphibianSpeciessColumns
    );
    this.payload.species = tableFilter;

    if (event.first) {
      this.speciesFirst = event.first;
    }

    this.fetchData();
  }

  public loadRandomSpeciesData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleAmphibianRandomSpeciessColumns
    );
    this.payload.speciesRandomAmphibians = tableFilter;

    if (event.first) {
      this.randomSpeciesFirst = event.first;
    }

    this.fetchData();
  }

  public loadThreatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleAmphibianThreatsColumns
    );
    this.payload.threats = tableFilter;

    if (event.first) {
      this.threatsFirst = event.first;
    }

    this.fetchData();
  }

  public loadHabitatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleAmphibianHabitatsColumns
    );
    this.payload.habitats = tableFilter;

    if (event.first) {
      this.habitatsFirst = event.first;
    }

    this.fetchData();
  }

  protected override initializePaginator() {
    this.sampleFirst = 0;
    this.sampleAmphibianFirst = 0;
    this.speciesFirst = 0;
    this.randomSpeciesFirst = 0;
    this.threatsFirst = 0;
    this.habitatsFirst = 0;
  }

  public openExportModal() {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ExportModalComponent, {
      centered: true,
      modalDialogClass: 'export-modal',
      backdrop: 'static',
      keyboard: true,
    });

    if (modalRef) {
      modalRef.result.then((res) => {
        if (res != null) {
          this.exportFiles(res.xlsxFile, res.zipFile);
        }
      });
    }
  }

  public exportFiles(xlsxFile = false, zipFile = false) {
    let payload = { ...this.payload };
    if (payload.samples) {
      payload.samples.rows = this.sampleTotalRecords;
      payload.samples.first = 0;
    }
    if (payload.sampleAmphibians) {
      payload.sampleAmphibians.rows = this.sampleAmphibiansTotalRecords;
      payload.sampleAmphibians.first = 0;
    }
    if (payload.habitats) {
      payload.habitats.rows = this.habitatsTotalRecords;
      payload.habitats.first = 0;
    }
    if (payload.species) {
      payload.species.rows = this.speciesTotalRecords;
      payload.species.first = 0;
    }
    if (payload.speciesRandomAmphibians) {
      payload.speciesRandomAmphibians.rows = this.randomSpeciesTotalRecords;
      payload.speciesRandomAmphibians.first = 0;
    }
    if (payload.threats) {
      payload.threats.rows = this.threatsTotalRecords;
      payload.threats.first = 0;
    }

    let samples: any[] = [];
    let habitatsAmphibians: any[] = [];
    let speciesAmphibians: any[] = [];
    let randomSpeciesAmphibians: any[] = [];
    let threatsAmphibians: any[] = [];

    this.searchService.filterAmphibians(payload).subscribe((response) => {
      const date = new Date();
      const filename =
        'EP2_Export_Amphibians-Reptiles_' +
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      if (xlsxFile) {
        if (response && response.sampleAmphibians) {
          samples = this.buildExportArray(
            response,
            'sampleAmphibians',
            true,
            amphibianConfig,
            ''
          );
        }

        if (response && response.speciesAmphibians) {
          speciesAmphibians = this.buildExportArray(
            response,
            'speciesAmphibians',
            false,
            amphibianConfig,
            AmphibianFormGroup.Species
          );
        }

        if (response && response.randomSpeciesAmphibians) {
          randomSpeciesAmphibians = this.buildExportArray(
            response,
            'randomSpeciesAmphibians',
            false,
            amphibianConfig,
            AmphibianFormGroup.RandomObservations,
            true
          );
        }

        if (response && response.threatsAmphibians) {
          threatsAmphibians = this.buildExportArray(
            response,
            'threatsAmphibians',
            false,
            amphibianConfig,
            AmphibianFormGroup.SpeciesThreats
          );
        }

        if (response && response.habitatsAmphibians) {
          habitatsAmphibians = this.buildExportArray(
            response,
            'habitatsAmphibians',
            false,
            amphibianConfig,
            AmphibianFormGroup.Habitats
          );
        }

        const workbook = utils.book_new();

        const wsSamples = utils.aoa_to_sheet(samples);
        wsSamples['!cols'] = this.fixExportArrayColumnsWidth(samples);
        wsSamples['!rows'] = [{ hpt: 50 }];

        const wsHabitatsAmphibians = utils.aoa_to_sheet(habitatsAmphibians);
        wsHabitatsAmphibians['!cols'] =
          this.fixExportArrayColumnsWidth(habitatsAmphibians);
        wsHabitatsAmphibians['!rows'] = [{ hpt: 50 }];

        const wsSpeciesAmphibians = utils.aoa_to_sheet(speciesAmphibians);
        wsSpeciesAmphibians['!cols'] =
          this.fixExportArrayColumnsWidth(speciesAmphibians);
        wsSpeciesAmphibians['!rows'] = [{ hpt: 50 }];

        const wsRandomSpeciesAmphibians = utils.aoa_to_sheet(
          randomSpeciesAmphibians
        );
        wsRandomSpeciesAmphibians['!cols'] = this.fixExportArrayColumnsWidth(
          randomSpeciesAmphibians
        );
        wsRandomSpeciesAmphibians['!rows'] = [{ hpt: 50 }];

        const wsThreatsAmphibians = utils.aoa_to_sheet(threatsAmphibians);
        wsThreatsAmphibians['!cols'] =
          this.fixExportArrayColumnsWidth(threatsAmphibians);
        wsThreatsAmphibians['!rows'] = [{ hpt: 50 }];

        utils.book_append_sheet(
          workbook,
          wsSamples,
          this.translate.instant('SPREADSHEET_AMPHIBIANS_SAMPLES')
        );
        utils.book_append_sheet(
          workbook,
          wsHabitatsAmphibians,
          this.translate.instant('SPREADSHEET_AMPHIBIANS_HABITATS')
        );
        utils.book_append_sheet(
          workbook,
          wsSpeciesAmphibians,
          this.translate.instant('SPREADSHEET_AMPHIBIANS_SPECIES')
        );
        utils.book_append_sheet(
          workbook,
          wsRandomSpeciesAmphibians,
          this.translate.instant('SPREADSHEET_AMPHIBIANS_RANDOM_SPECIES')
        );
        utils.book_append_sheet(
          workbook,
          wsThreatsAmphibians,
          this.translate.instant('SPREADSHEET_AMPHIBIANS_THREATS')
        );

        const date = new Date();
        writeFile(workbook, filename + '.xlsx', { compression: true });
      }

      if (zipFile) {
        this.getZip(response, filename);
      }
    });
  }
}
