<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-1">
    <ng-container *ngIf="touched && hasErrors">
      <ng-container
        *ngIf="
          errors != null &&
          errors['duplicateSpecies'] !== null &&
          errors['duplicateSpecies']
        "
      >
        <div class="error-message">
          {{ "AMPHIBIAN_SPECIES_ERROR" | translate }}
        </div>
      </ng-container>
    </ng-container>
    <ng-container
      *ngFor="let speciesForm of species.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
        ></app-dropdown>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'otherSpecies'"
          [label]="'OTHER_SPECIES'"
          [ngClass]="{
            disabled:
              getGroup(index).controls['otherSpecies'].disabled &&
              getGroup(index).controls['otherSpecies'].value === ''
          }"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfPeople'"
          [label]="'NUMBER_OF_PEOPLE'"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          [mask]="'00/00/0000'"
          [formGroup]="getGroup(index)"
          [formName]="'date'"
          [label]="'DATE'"
        ></app-input>
        <app-input
          [mask]="'00:00'"
          [formGroup]="getGroup(index)"
          [formName]="'time'"
          [label]="'TIME_SPECIES_FOUND'"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'researcher'"
          [label]="'RESEARCHER'"
        ></app-input>
      </div>

      <div class="form-row">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'pointX'"
          [label]="'LATITUDE'"
          [required]="isRandom()"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'pointY'"
          [label]="'LONGTITUDE'"
          [required]="isRandom()"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'altitude'"
          [label]="'ALTITUDE'"
        ></app-input>
      </div>

      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCodeA'"
          [label]="'HABITAT_A'"
          [dropdownList]="habitatADropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['habitatCodeA'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCodeB'"
          [label]="'HABITAT_B'"
          [dropdownList]="habitatBDropdown"
          [preDefinedId]="getGroup(index).controls['habitatCodeB'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatQuality'"
          [label]="'HABITAT_QUALITY'"
          [dropdownList]="qualityDropdown"
          [preDefinedId]="getGroup(index).controls['habitatQuality'].value"
        ></app-dropdown>
      </div>

      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'activity'"
          [label]="'ACTIVITY'"
          [dropdownList]="activityDropdown"
          [preDefinedId]="getGroup(index).controls['activity'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'microHabitat'"
          [label]="'MICROHABITAT'"
          [dropdownList]="microHabitatDropdown"
          [preDefinedId]="getGroup(index).controls['microHabitat'].value"
        ></app-dropdown>
      </div>

      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>
      <div class="form-row">
        <div class="checkbox-container">
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.parii" />
            {{ "PARII" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.pariv" />
            {{ "PARIV" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.parv" />
            {{ "PARV" | translate }}
          </label>
        </div>
      </div>

      <ng-container [formGroup]="getGroup(index)">
        <div
          formArrayName="speciesThreats"
          class="threats-container"
          [hidden]="
            speciesById.length > 0 &&
            getSpeciesThreats(index).controls.length === 0
          "
        >
          <div
            class="species-threats"
            *ngFor="
              let speciesThreatsForm of getSpeciesThreats(index)?.controls;
              let i = index
            "
          >
            <div class="form-row">
              <app-dropdown
                [formGroup]="getGroupThreats(index, i)"
                [formName]="'pressuresAndThreatsId'"
                [label]="'CODE_OF_SPECIES_THREATS'"
                [dropdownList]="threatsDropdown"
                [required]="true"
                [preDefinedId]="
                  getGroupThreats(index, i).controls['pressuresAndThreatsId']
                    .value
                "
              >
              </app-dropdown>
            </div>
            <div class="form-row">
              <app-dropdown
                [formGroup]="getGroupThreats(index, i)"
                [formName]="'importance'"
                [label]="'IMPACT_THREAT'"
                [dropdownList]="importanceDropdown"
                [preDefinedId]="
                  getGroupThreats(index, i).controls['importance'].value
                "
              ></app-dropdown>
            </div>
            <div class="form-row">
              <app-dropdown
                [formGroup]="getGroupThreats(index, i)"
                [formName]="'timeCharacter'"
                [label]="'TIME_CHARACTER_THREAT'"
                [dropdownList]="timeCharacterDropdown"
                [preDefinedId]="
                  getGroupThreats(index, i).controls['timeCharacter'].value
                "
              ></app-dropdown>
            </div>

            <app-textarea
              [style.max-width]="'100%'"
              [formGroup]="getGroupThreats(index, i)"
              [formName]="'impact'"
              [label]="'IMPACT_TYPE'"
            ></app-textarea>

            <div class="form-row remove-threat">
              <app-button
                [style.--btnPadding]="'6px 17px'"
                [btnText]="'-'"
                (buttonClicked)="removeSpeciesThreats(index, i)"
                [hidden]="disabledControls"
              ></app-button>
            </div>
            <div
              class="line threat-line"
              *ngIf="i + 1 !== getSpeciesThreats(index)?.controls?.length"
            ></div>
          </div>
          <app-button
            [style.--btnPadding]="'5px 18px'"
            [btnText]="'ADD_PRESSURE_THREAT'"
            (buttonClicked)="addNewSpeciesThreats(index)"
            [ngStyle]="{ margin: '25px' }"
            [hidden]="disabledControls"
          ></app-button>
        </div>
      </ng-container>
      <app-button
        (buttonClicked)="removeSpecies(index)"
        [style.--btnPadding]="'8px 26px'"
        class="minus-btn remove-species"
        [btnText]="'REMOVE_SPECIES'"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== species.controls.length"></div>
    </ng-container>

    <app-button
      class="add-btn"
      [btnText]="'ADD_SPECIES'"
      (buttonClicked)="addNewSpecies()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
