<div
  appDragNDrop
  class="files-container"
  (fileDropped)="!readonly && onFileDropped($event)"
>
  <input
    *ngIf="!readonly"
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    (change)="fileBrowseHandler($event)"
    accept="{{ getAllowedExtensions() }}"
    hidden
  />
  <div class="label" *ngIf="!readonly">
    <img src="assets/images/upload.svg" />
    <span class="label-no-break">{{ "DRAG_OR" | translate }}</span>
    <label for="fileDropRef" class="label-clickable">{{
      "CHOOSE_FILE" | translate
    }} ({{getAllowedExtensions()}})</label>
  </div>
  <div class="images">
    <div
      class="images-container"
      *ngFor="let file of filesWithUrl; let i = index"
      [ngbTooltip]="tooltipFileDetails"
      (click)="downloadFile(file)"
    >
      <img
        class=""
        *ngIf="file"
        [src]="file.url"
        width="150px"
        height="150px"
      />
      <div class="delete-file" *ngIf="!readonly" (click)="deleteFile(i)">X</div>

      <ng-template #tooltipFileDetails>
        <div class="filename">{{ file.file.name }}</div>
      </ng-template>
    </div>
  </div>
</div>
