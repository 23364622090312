import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export interface FileHandle {
  file: File;
  url: SafeUrl;
}

export interface IFilePayload {
  id: number;
  name: string;
  path: string;
}

export interface IFileDeleteRequest {
  sampleId: number;
  fileIds: number[];
}

export interface IBookFileDeleteRequest {
  referenceId: string;
  fileIds: number[];
}

@Component({
  selector: 'app-files-container',
  templateUrl: './files-container.component.html',
  styleUrls: ['./files-container.component.scss'],
})
export class FilesContainerComponent {
  @Output() attachedFiles: EventEmitter<FileHandle[]> = new EventEmitter();
  @Output() filesToBeDeleted: EventEmitter<any[]> = new EventEmitter();
  @Input() predefinedFiles!: IFilePayload[];
  @Input() readonly: boolean = false;
  @Input() onlyZip: boolean = false;
  public filesWithUrl: any[] = [];
  public files: any[] = [];
  public filesToDelete: any[] = [];

  private allowedExtensions: string[] = [
    'xlsx',
    'xls',
    'png',
    'jpg',
    'shp',
    'cpg',
    'dbf',
    'prj',
    'qmd',
    'shx',
  ];

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.onlyZip) {
      this.allowedExtensions = ['zip'];
    }
  }

  ngOnChanges(changes: any): void {
    if (
      changes?.predefinedFiles?.currentValue != null &&
      changes?.predefinedFiles?.currentValue.length > 0
    ) {
      changes.predefinedFiles.currentValue.forEach((item: IFilePayload) => {
        const supportedImageExtensions = ['png', 'svg', 'jpg', 'jpeg', 'webp'];
        const extension = item.name.split('.')[item.name.split('.').length - 1];
        let url = `${environment.endpoint}files/${item.path}/${item.name}`;
        if (
          extension != null &&
          supportedImageExtensions.indexOf(extension) === -1
        ) {
          url = 'assets/images/attachment.svg';
        }

        this.filesWithUrl.push({
          file: { id: item.id, name: item.name },
          url,
          downloadUrl: `${environment.endpoint}files/${item.path}/${item.name}`,
          existing: true,
        });
        this.files.push({ name: item.name });
      });

      this.attachedFiles.emit(this.files);
    }
  }

  public getAllowedExtensions(): string {
    return this.allowedExtensions.map((item) => `.${item}`).join(',');
  }

  /**
   * on file drop handler
   */
  public onFileDropped(event: any) {
    this.prepareFilesList(event.dataTransfer.files);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(event: any) {
    if (event.target.files != null) {
      const files: any[] = [];
      for (const prop of Object.keys(event.target.files)) {
        if (event.target.files[prop].name.split('.').slice(-1).length > 0) {
          const extension = event.target.files[prop].name
            .split('.')
            .slice(-1)[0];
          if (this.allowedExtensions.indexOf(extension) !== -1) {
            files.push(event.target.files[prop]);
          }
        }
      }
      if (files.length > 0) {
        this.prepareFilesList(files);
      }
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  private prepareFilesList(files: Array<any>) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let url =
        file.type.split('/')[0] !== 'image'
          ? 'assets/images/attachment.svg'
          : this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(file)
            );
      this.filesWithUrl.push({ file, url });
      this.files.push(file);
    }
    this.attachedFiles.emit(this.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  public deleteFile(index: number) {
    if (
      !(this.filesWithUrl[index].file instanceof File) &&
      this.filesWithUrl[index].file.id != null
    ) {
      this.filesToDelete.push(this.filesWithUrl[index].file);
    }
    this.filesToBeDeleted.emit(this.filesToDelete);

    this.filesWithUrl.splice(index, 1);
    this.files.splice(index, 1);
    this.attachedFiles.emit(this.files);
  }

  public downloadFile(file: any): void {
    if (file.downloadUrl != null) {
      window.open(file.downloadUrl, '_blank');
    }
  }
}
