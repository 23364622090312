import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  IAmphibianActivityDto,
  IAmphibianDto,
  IAmphibianHabitatADto,
  IAmphibianHabitatBDto,
  IAmphibianMethodDto,
  IAmphibianMicroHabitatDto,
  IAmphibianQualityDto,
  IAmphibianRainDto,
  IAmphibianSpeciesDto,
  IAmphibianThreatsDto,
  IAmphibianWeatherDto,
} from 'src/app/core/dtos/amphibian.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ISampleIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { dateValidator } from 'src/app/shared/validators/dateValidator';
import { timeValidator } from 'src/app/shared/validators/timeValidator';
import { environment } from 'src/environments/environment';
import { SamplesService } from './samples.service';
import { IFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';

@Injectable({
  providedIn: 'root',
})
export class AmphibiansService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private sampleService: SamplesService
  ) {}

  public getAmphibians(dataVersion?: string): Observable<IAmphibianDto[]> {
    const url = environment.endpoint + 'Amphibian/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianDto[]>(url, { params: params });
  }

  public getAmphibianById(id: number): Observable<IAmphibianDto> {
    const url = environment.endpoint + 'Amphibian';
    const params = new HttpParams().set('id', id);
    return this.http.get<IAmphibianDto>(url, { params: params });
  }

  public putAmphibian(
    id: number,
    payload: IAmphibianDto
  ): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Amphibian';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public addAmphibian(payload: IAmphibianDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Amphibian';
    return this.http.post<ISampleIdResponse>(url, payload);
  }

  public addBatchAmphibian(
    payload: IAmphibianDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Amphibian/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public addFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Amphibian/files';
    return this.http.post<void>(url, payload);
  }

  public removeFiles(payload: IFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Amphibian/remove-files';
    return this.http.post<void>(url, payload);
  }

  public getAmphibianQuality(
    dataVersion?: string
  ): Observable<IAmphibianQualityDto[]> {
    const url = environment.endpoint + 'Amphibian/quality';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianQualityDto[]>(url, { params: params });
  }

  public getAmphibianMicroHabitat(
    dataVersion?: string
  ): Observable<IAmphibianMicroHabitatDto[]> {
    const url = environment.endpoint + 'Amphibian/micro';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianMicroHabitatDto[]>(url, { params: params });
  }

  public getAmphibianActivity(
    dataVersion?: string
  ): Observable<IAmphibianActivityDto[]> {
    const url = environment.endpoint + 'Amphibian/activity';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianActivityDto[]>(url, { params: params });
  }

  public getAmphibianSpecies(
    dataVersion?: string
  ): Observable<IAmphibianSpeciesDto[]> {
    const url = environment.endpoint + 'Amphibian/species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianSpeciesDto[]>(url, { params: params });
  }

  public getAmphibianThreats(
    dataVersion?: string
  ): Observable<IAmphibianThreatsDto[]> {
    const url = environment.endpoint + 'Amphibian/threats';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianThreatsDto[]>(url, { params: params });
  }

  public getAmphibianHabitatA(
    dataVersion?: string
  ): Observable<IAmphibianHabitatADto[]> {
    const url = environment.endpoint + 'Amphibian/haba';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianHabitatADto[]>(url, { params: params });
  }

  public getAmphibianHabitatB(
    dataVersion?: string
  ): Observable<IAmphibianHabitatBDto[]> {
    const url = environment.endpoint + 'Amphibian/habb';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianHabitatBDto[]>(url, { params: params });
  }

  public getAmphibianMethod(
    dataVersion?: string
  ): Observable<IAmphibianMethodDto[]> {
    const url = environment.endpoint + 'Amphibian/method';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianMethodDto[]>(url, { params: params });
  }

  public getAmphibianWeather(
    dataVersion?: string
  ): Observable<IAmphibianWeatherDto[]> {
    const url = environment.endpoint + 'Amphibian/weather';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianWeatherDto[]>(url, { params: params });
  }

  public getAmphibianRain(
    dataVersion?: string
  ): Observable<IAmphibianRainDto[]> {
    const url = environment.endpoint + 'Amphibian/rain';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IAmphibianRainDto[]>(url, { params: params });
  }

  /**
   * Initialization of the user form.
   */
  public initAmphibianFormGroup(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);

    sample = fb.group({
      ...sample.controls,
      samId: fb.control('', [Validators.required]),
      ergoEpoptiaFasiErgou: fb.control('', [Validators.required]),
      shapefile: fb.control(''),
      duration: fb.control('', [
        Validators.min(0),
        Validators.max(600),
        Validators.pattern(/^\d+$/),
      ]),
      author: fb.control(''),
      method: fb.control('', [Validators.required]),
      habitatDescription: fb.control(''),
      placeName: fb.control(''),
      courseLengthM: fb.control('', [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      threats: fb.control(''),
      altitude: fb.control('', [Validators.min(0), Validators.max(2800)]),
      temperature: fb.control('', [Validators.min(-10), Validators.max(45)]),
      windIntensity: fb.control('', [Validators.min(0), Validators.max(10)]),
      weather: fb.control(''),
      rainCode: fb.control(''),
      species: fb.array([], [this.uniqueSpeciesValidator()]),
      habitats: fb.array([]),
      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
      randomObservation: fb.control(false),
    });
    return sample;
  }

  /**
   * Initialization of the habitats form.
   */
  public initHabitatsFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      habitatCodeA: fb.control('', [Validators.required]),
      habitatCodeB: fb.control(''),
    });
  }

  /**
   * Initialization of the sample form.
   */
  public initSpeciesFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      species: fb.control('', [Validators.required]),
      researcher: fb.control(''),
      activity: fb.control(''),
      pointX: fb.control('', [
        this.commonService.latLongValidator('pointX', 'pointY'),
      ]),
      pointY: fb.control('', [
        this.commonService.latLongValidator('pointX', 'pointY'),
      ]),
      altitude: fb.control('', [Validators.min(0), Validators.max(2800)]),
      numberOfPeople: fb.control('', [
        Validators.min(0),
        Validators.max(1000),
        Validators.pattern(/^\d+$/),
      ]),
      microHabitat: fb.control(''),
      habitatCodeA: fb.control('', [Validators.required]),
      habitatCodeB: fb.control(''),
      gridCell: fb.control(''),
      habitatQuality: fb.control(''),
      observations: fb.control(''),
      date: fb.control('', dateValidator()),
      time: fb.control('', timeValidator()),
      parii: fb.control(false),
      pariv: fb.control(false),
      parv: fb.control(false),
      obsId: fb.control(''),
      otherSpecies: fb.control(''),
      speciesThreats: fb.array([]),
    });
  }

  /**
   * Initialization of the species form.
   */
  public initSpeciesThreatsFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      pressuresAndThreatsId: fb.control('', [Validators.required]),
      importance: fb.control(''),
      timeCharacter: fb.control(''),
      impact: fb.control(''),
      species: fb.control(''),
      otherSpecies: fb.control(''),
    });
  }

  /**
   * Adds form value in corresponding observable.
   */
  public prepareAmphibianRequest(
    amphibianForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctSpecies: IAmphibianSpeciesDto[] = [],
    editMode = false
  ): IAmphibianDto {
    const amphibian: IAmphibianDto = {};
    const species = amphibianForm.controls['species'] as FormArray;
    const habitats = amphibianForm.controls['habitats'] as FormArray;
    // const threats = amphibianForm.controls['threats'] as FormArray;

    if (amphibianForm?.controls['randomObservation'].value) {
      amphibian.randomObservation = true;
      amphibian.protocolId = Protocols.Amphibian;
      amphibian.ergoEpoptiaFasiErgou =
        amphibianForm?.value.ergoEpoptiaFasiErgou;
    } else {
      amphibian.randomObservation = false;
      amphibian.ergoEpoptiaFasiErgou =
        amphibianForm?.value.ergoEpoptiaFasiErgou;
      amphibian.shapefile = amphibianForm?.value.shapefile;
      amphibian.date = this.commonService.formatDate(amphibianForm?.value.date);
      // amphibian.naturaCode = amphibianForm?.value.naturaCode;
      amphibian.samplingCode = amphibianForm?.value.samplingCode;
      amphibian.financialInstrument = amphibianForm?.value.financialInstrument;
      // amphibian.prefecture = amphibianForm?.value.prefecture;
      amphibian.researcher = amphibianForm?.value.researcher;
      amphibian.author = amphibianForm?.value.author;
      amphibian.time = this.commonService.formatTime(amphibianForm?.value.time);
      amphibian.locality = amphibianForm?.value.locality;
      amphibian.observations = amphibianForm?.value.observations;
      // amphibian.file = amphibianForm?.value.file;
      // amphibian.gridCell = amphibianForm?.value.gridCell;
      // amphibian.photo = amphibianForm?.value.photo;
      amphibian.duration = amphibianForm?.value.duration;
      amphibian.altitude = amphibianForm?.value.altitude;
      amphibian.method = amphibianForm?.value.method;
      amphibian.pointX = amphibianForm?.value.lat;
      amphibian.pointY = amphibianForm?.value.long;
      amphibian.habitatCodeA = amphibianForm?.value.habitatCodeA;
      amphibian.habitatCodeB = amphibianForm?.value.habitatCodeB;
      amphibian.habitatDescription = amphibianForm?.value.habitatDescription;
      // amphibian.totalHabitats = amphibianForm?.value.totalHabitats;
      // amphibian.placeName = amphibianForm?.value.placeName;
      amphibian.courseLengthM = amphibianForm?.value.courseLengthM;
      amphibian.temperature = amphibianForm?.value.temperature;
      amphibian.windIntensity = amphibianForm?.value.windIntensity;
      amphibian.hliof_Nefosi = amphibianForm?.value.weather;
      amphibian.rainCode = amphibianForm?.value.rainCode;
      amphibian.threats = amphibianForm?.value.threats;
      amphibian.protocolId = Protocols.Amphibian;
    }

    if (fromSpreadsheet === true) {
      amphibian.samId = amphibianForm?.value.samId;

      amphibian.species = amphibianForm?.value.species;

      if (amphibian.species && amphibian.species.length === 0) {
        amphibian.species = undefined;
      } else if (amphibian.species && amphibian.species.length > 0) {
        amphibian.species.forEach((item) => {
          if (item.species === 'Άλλο') {
            item.species = undefined;
          } else {
            item.species = ctSpecies
              .find((itemInner) => itemInner.code === item.species)
              ?.id?.toString();
            item.otherSpecies = undefined;
          }

          if (!!item.date) {
            item.date = this.commonService.formatDate(item.date.toString());
          } else {
            item.date = undefined;
          }
          if (!!item.time) {
            item.time = this.commonService.formatTime(item.time);
          } else {
            item.time = undefined;
          }
          if (item.speciesThreats && item.speciesThreats.length === 0) {
            item.speciesThreats = undefined;
          }

          if (item.habitatCodeB === '') {
            item.habitatCodeB = undefined;
          }

          if (item.habitatQuality === '') {
            item.habitatQuality = undefined;
          }

          if (item.microHabitat === '') {
            item.microHabitat = undefined;
          }
        });
      }

      amphibian.habitats = amphibianForm?.value.habitats;
      if (amphibian.habitats && amphibian.habitats.length === 0) {
        amphibian.habitats = undefined;
      }
    } else {
      let samId = '';
      if (!editMode) {
        if (amphibianForm?.value.randomObservation) {
          samId =
            'RANDOM_' + amphibianForm?.value.ergoEpoptiaFasiErgou + '_AME_0000';
          amphibian.samId = samId;
        } else {
          const timestamp = Date.now().toString();
          samId =
            amphibianForm?.value.samplingCode +
            '_' +
            amphibianForm?.value.ergoEpoptiaFasiErgou +
            '_AME_0000__' +
            timestamp;

          amphibian.samId = samId;
        }
      }

      amphibian.species = species?.value;
      if (!species?.touched) {
        amphibian.species = undefined;
      } else {
        const ctOtherSpecies = ctSpecies.find((item) => {
          return item.code === 'Άλλο';
        });
        amphibian.species?.forEach((item, index) => {
          if (item.species === ctOtherSpecies?.id) {
            item.species = undefined;
          } else {
            item.otherSpecies = undefined;
          }

          if (!editMode) {
            item.obsId = samId + '_' + (index + 1).toString().padStart(3, '0');
          }

          if (!!item.date) {
            item.date = this.commonService.formatDate(item.date.toString());
          } else {
            item.date = undefined;
          }

          if (!!item.time) {
            item.time = this.commonService.formatTime(item.time);
          } else {
            item.time = undefined;
          }

          if (item.habitatCodeB === '') {
            item.habitatCodeB = undefined;
          }

          if (item.habitatQuality === '') {
            item.habitatQuality = undefined;
          }

          if (item.microHabitat === '') {
            item.microHabitat = undefined;
          }
        });
      }
      if (!habitats?.touched) {
        amphibian.habitats = undefined;
      } else {
        amphibian.habitats = habitats?.value;
      }
      // if (threats && !threats.touched) {
      //   amphibian.threats = undefined;
      // } else {
      //   amphibian.threats = threats?.value;
      // }
    }

    return amphibian;
  }

  public uniqueSpeciesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const controls = (control as FormArray).controls;
      let combinedKeys: { [key: string]: number } = {};
      for (let i = 0; i < controls.length; i++) {
        const x = parseFloat(controls[i].get('pointX')?.value);
        const y = parseFloat(controls[i].get('pointY')?.value);
        const habA = controls[i].get('habitatCodeA')?.value.toString();
        let habB = controls[i].get('habitatCodeB')?.value?.toString();
        const species = controls[i].get('species')?.value.toString();

        // To account in cases where this field is empty.
        if (habB === '') {
          habB = 'X';
        }

        if (!isNaN(x) && !isNaN(y) && habA !== '' && species !== '') {
          if (
            combinedKeys[x.toFixed(5) + y.toFixed(5) + habA + habB + species]
          ) {
            return { duplicateSpecies: true };
          } else {
            combinedKeys[
              x.toFixed(5) + y.toFixed(5) + habA + habB + species
            ] = 1;
          }
        }
      }
      return null;
    };
  }

  /**
   * Finds the distance (Km) between two points on map
   *
   * @param {ICoordinates} pointA Coordinates of PointA
   * @param {ICoordinates} pointB Coordinates of PointA
   *
   * @returns {number} The distance in Km
   */
  public geoDistance(pointA: ICoordinates, pointB: ICoordinates): number {
    var R = 6371.071; // Radius of the Earth in Km
    var rLat1 = pointA.lat * (Math.PI / 180); // Convert degrees to radians
    var rLat2 = pointB.lat * (Math.PI / 180); // Convert degrees to radians
    var rLng1 = pointA.lng * (Math.PI / 180); // Convert degrees to radians
    var rLng2 = pointB.lng * (Math.PI / 180); // Convert degrees to radians
    var diffLat = rLat2 - rLat1; // Radian difference (latitudes)
    var diffLon = rLng2 - rLng1; // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
            Math.cos(rLat1) *
              Math.cos(rLat2) *
              Math.sin(diffLon / 2) *
              Math.sin(diffLon / 2)
        )
      );
    return d;
  }
}
