<ng-container [formGroup]="formGroup">
  <div *ngIf="selectedSpecies.length === 0" class="disclaimer">
    {{ "IMPORT_SPECIES_FIRST" | translate }}
  </div>
  <div
    *ngIf="selectedSpecies.length > 0"
    formArrayName="threats"
    class="form-container form-1"
  >
    <ng-container
      *ngFor="let threatsForm of threats.controls; let index = index"
    >
      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'pressuresAndThreatsId'"
          [label]="'PRESSURE_THREAT'"
          [dropdownList]="threatsDropdown"
          [required]="true"
          [preDefinedId]="
            getGroup(index).controls['pressuresAndThreatsId'].value
          "
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'speciesId'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['speciesId'].value"
          (valueChangedExtraDataName)="updateOtherSpecies($event, index)"
        ></app-dropdown>
        <!-- <app-input
          [formGroup]="getGroup(index)"
          [formName]="'speciesIdOther'"
          [label]="'OTHER_SPECIES'"
        ></app-input> -->
      </div>

      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'importance'"
          [label]="'IMPORTANCE'"
          [dropdownList]="importanceDropdown"
          [preDefinedId]="getGroup(index).controls['importance'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'timeCharacter'"
          [label]="'TIME_CHARACTER'"
          [dropdownList]="timeCharacterDropdown"
          [preDefinedId]="getGroup(index).controls['timeCharacter'].value"
        ></app-dropdown>
      </div>

      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'effectMode'"
        [label]="'IMPACT_TYPE'"
      ></app-textarea>

      <app-button
        (buttonClicked)="removeThreats(index)"
        [style.--btnPadding]="'6px 15px'"
        class="minus-btn"
        [btnText]="'-'"
        [tooltip]="'REMOVE_ENTRY' | translate"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== threats.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_THREAT'"
      (buttonClicked)="addNewThreats()"
      [hidden]="disabledControls"
    ></app-button>
    <ng-container *ngIf="touched && hasErrors">
      <ng-container
        *ngIf="
          errors != null &&
          errors['duplicatePressure'] !== null &&
          errors['duplicatePressure']
        "
      >
        <div class="error-message">
          {{ "DUPLICATE_SPECIES_PRESSURE_ERROR" | translate }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
