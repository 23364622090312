import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
export enum InvertebrateFormGroup {
  Species = 'species',
  Threats = 'threats',
}
export const invertebrateConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
      [
        'Γεωγραφικό Πλάτος (WGS84) Τέλος',
        'Γεωγραφικό Μήκος (WGS84) Τέλος',
        false,
      ],
    ],
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 2,
      },
      {
        header: 'Ώρα Δειγματοληψίας',
        type: 'time',
        formName: 'time',
        order: 3,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 5,
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
        order: 6,
      },
      {
        header: 'Νομός',
        type: 'prefecture',
        formName: 'prefecture',
        order: 10,
      },
      {
        header: 'Τοποθεσία',
        type: 'string',
        formName: 'locality',
        order: 11,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 12,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
        order: 13,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 22,
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Διάρκεια (min)',
        type: 'duration',
        formName: 'duration',
        order: 4,
      },
      {
        header: 'Μέθοδος Δειγματοληψίας',
        type: 'string',
        formName: 'method',
        order: 7,
      },
      {
        header: 'Ενδιαίτημα',
        type: 'string',
        formName: 'habitat',
        order: 8,
      },
      {
        header: 'Τύπος βλάστησης',
        type: 'string',
        formName: 'vegetationType',
        order: 9,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84) Τέλος',
        type: 'float',
        formName: 'latEnd',
        order: 14,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84) Τέλος',
        type: 'float',
        formName: 'longEnd',
        order: 15,
      },
      {
        header: 'Υψόμετρο (m)',
        type: 'float',
        formName: 'altitude',
        order: 16,
      },
      {
        header: 'Θερμοκρασία (οC)',
        type: 'float',
        formName: 'temperature',
        order: 17,
      },
      {
        header: 'Μήκος Διατομής (m)',
        type: 'float',
        formName: 'crossSectionLength',
        order: 18,
      },
      {
        header: 'Τοπογραφία',
        type: 'string',
        formName: 'topography',
        order: 19,
      },
      {
        header: 'Υγρασία',
        type: 'string',
        formName: 'humidity',
        order: 20,
      },
      {
        header: 'Καιρικές Συνθήκες',
        type: 'string',
        formName: 'weatherConditions',
        order: 21,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: InvertebrateFormGroup.Species,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Αφορά A/A Δείγματος',
    configuration: [
      {
        header: 'Αφορά A/A Δείγματος',
        type: 'string',
        formName: 'aaSample',
        order: 1,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 2,
      },
      {
        header: 'Τμήμα Διατομής είδους',
        type: 'string',
        formName: 'crossSectionPart',
        order: 3,
      },
      {
        header: 'Αριθμός ατόμων είδους',
        type: 'int',
        formName: 'numberOfPeople',
        order: 4,
      },
      {
        header: 'Κατηγορία Σχετικής αφθονίας είδους',
        type: 'string',
        formName: 'relativeAbundanceCategory',
        order: 5,
      },
      {
        header: 'Κατάσταση Σχετικής αφθονίας είδους',
        type: 'string',
        formName: 'relativeAbundanceStatus',
        order: 6,
      },
      {
        header: 'Παρατηρήσεις είδους',
        type: 'string',
        formName: 'observation',
        order: 7,
      },
      {
        header: '92/43/ ΕΟΚ - Παρ II είδους',
        type: 'boolean',
        formName: 'parii',
        order: 8,
      },
      {
        header: '92/43/ ΕΟΚ - Παρ IV είδους',
        type: 'boolean',
        formName: 'pariv',
        order: 9,
      },
      {
        header: '92/43/ ΕΟΚ - Παρ V είδους',
        type: 'boolean',
        formName: 'parv',
        order: 10,
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: InvertebrateFormGroup.Threats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Αφορά A/A Δείγματος',
    configuration: [
      {
        header: 'Αφορά A/A Δείγματος',
        type: 'string',
        formName: 'aaSample',
        order: 1,
        disabled: true,
      },
      {
        header: 'Κωδικός πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsId',
        order: 2,
      },
      {
        header: 'Κωδικός είδους απειλής',
        type: 'string',
        formName: 'speciesId',
        order: 3,
      },
      {
        header: 'Τύπος πίεσης ή απειλής',
        type: 'string',
        formName: 'type',
        order: 4,
      },
      {
        header: 'Σημασία απειλής',
        type: 'string',
        formName: 'importance',
        order: 5,
      },
    ],
  },
];
