import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-label-input',
  templateUrl: './label-input.component.html',
  styleUrls: ['./label-input.component.scss'],
})
export class LabelInputComponent {
  @Input() public value: string = 'value';
  @Input() public type?: string = 'text';
  @Input() public label?: string;
  @Input() public placeholder?: string = '';
  @Input() public mask?: string;
  @Input() public suffix?: string;
  @Input() public showMaskTyped?: boolean;
  @Input() public required = false;
}
