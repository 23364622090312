<ng-container [formGroup]="formGroup">
  <div formArrayName="threats" class="form-container form-1">
    <ng-container
      *ngFor="let threatsForm of threats.controls; let index = index"
    >
      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'pressuresAndThreatsId'"
          [label]="'PRESSURE_THREAT'"
          [dropdownList]="pressuresDropdown"
          [required]="true"
          [preDefinedId]="
            getGroup(index).controls['pressuresAndThreatsId'].value
          "
        ></app-dropdown>
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'importance'"
          [label]="'IMPORTANCE_THREAT'"
          [dropdownList]="importanceDropdown"
          [preDefinedId]="getGroup(index).controls['importance'].value"
        ></app-dropdown>

        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'target'"
          [label]="'TARGET'"
          [dropdownList]="targetDropdown"
          [preDefinedId]="getGroup(index).controls['target'].value"
        ></app-dropdown>

        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'timeCharacter'"
          [label]="'TIME_CHARACTER_THREAT'"
          [dropdownList]="timeCharacterDropdown"
          [preDefinedId]="getGroup(index).controls['timeCharacter'].value"
        ></app-dropdown>
      </div>

      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'cover_Wh_Ma_Mi'"
          [label]="'COVER_WH_MA_MI'"
          [dropdownList]="cover_Wh_Ma_Mi_Dropdown"
          [preDefinedId]="getGroup(index).controls['cover_Wh_Ma_Mi'].value"
        ></app-dropdown>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'cover'"
          [label]="'COVER'"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'explanation'"
          [label]="'EXPLANATION'"
        ></app-input>
      </div>

      <div
        class="form-row"
        *ngIf="
          getGroup(index).controls['pressuresAndThreatsId'].value === 'PI01'
        "
      >
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'foreignSpecies'"
          [label]="'FOREIGN_SPECIES'"
        ></app-input>
      </div>

      <div class="form-row form-3" [formGroup]="getGroup(index)">
        <app-button
          (buttonClicked)="removeThreats(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== threats.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_THREAT'"
      (buttonClicked)="addNewThreats()"
      [hidden]="disabledControls"
    ></app-button>
    <ng-container *ngIf="touched && hasErrors">
      <ng-container
        *ngIf="
          errors != null &&
          errors['duplicatePressure'] !== null &&
          errors['duplicatePressure']
        "
      >
        <div class="error-message">
          {{ "DUPLICATE_PRESSURE_ERROR" | translate }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
