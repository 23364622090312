import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  IDataTableColumns,
  sampleColumns,
  sampleSeaHabitatThreatsColumns,
  sampleSeaHabitatColumns,
  sampleSeaHabitatStructures1120aColumns,
  sampleSeaHabitatStructures1120bColumns,
  sampleSeaHabitatStructures1120cColumns,
  sampleSeaHabitatStructures1170Columns,
  sampleSeaHabitatSpeciesColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  ISeaHabitat1170Request,
  ISeaHabitatARequest,
  ISeaHabitatBRequest,
  ISeaHabitatCRequest,
  ISeaHabitatPATRequest,
  ISeaHabitatsDto,
  ISeaHabitatSpeciesRequest,
} from 'src/app/core/dtos/sea-habitat.dto';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { SeaService } from 'src/app/samplings/services/sea.service';
import { SearchService } from '../services/search.service';
import { TableWrapperComponent } from '../table-wrapper/table-wrapper.component';
import { ITableFilterRequest } from '../table/interfaces/table.interfaces';
import { forkJoin } from 'rxjs';
import {
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { LazyLoadEvent } from 'primeng/api';
import { ISearchCoordinates } from '../map-search/map-search.component';
import { TranslateService } from '@ngx-translate/core';
import {
  seaHabitatConfig,
  SeaHabitatFormGroup,
} from 'src/app/samplings/config-files/sea-habitat-config';
import { utils, writeFile } from 'xlsx';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-sea-habitats-search',
  templateUrl: './sea-habitats-search.component.html',
  styleUrls: ['./sea-habitats-search.component.scss'],
})
export class SeaHabitatsSearchComponent extends TableWrapperComponent {
  public sampleSeaHabitatTotalRecords: number = 0;
  public sampleSeaHabitat: ISeaHabitatsDto[] = [];
  public sampleSeaHabitatColumns: IDataTableColumns[] = [];
  public sampleSeaHabitatFirst = 0;
  public speciesFirst = 0;
  public threatsFirst = 0;
  public structures1120aFirst = 0;
  public structures1120bFirst = 0;
  public structures1120cFirst = 0;
  public structures1170First = 0;

  public speciesTotalRecords: number = 0;
  public species: ISeaHabitatSpeciesRequest[] = [];
  public speciesColumns: IDataTableColumns[] = [];

  public threatsTotalRecords: number = 0;
  public threats: ISeaHabitatPATRequest[] = [];
  public threatsColumns: IDataTableColumns[] = [];

  public structures1120aTotalRecords: number = 0;
  public structures1120a: ISeaHabitatARequest[] = [];
  public structures1120aColumns: IDataTableColumns[] = [];

  public structures1120bTotalRecords: number = 0;
  public structures1120b: ISeaHabitatBRequest[] = [];
  public structures1120bColumns: IDataTableColumns[] = [];

  public structures1120cTotalRecords: number = 0;
  public structures1120c: ISeaHabitatCRequest[] = [];
  public structures1120cColumns: IDataTableColumns[] = [];

  public structures1170TotalRecords: number = 0;
  public structures1170: ISeaHabitat1170Request[] = [];
  public structures1170Columns: IDataTableColumns[] = [];

  protected override payload: ITableFilterRequest = {
    samples: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    sampleSeaHabitat: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    species: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    threats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    structure1120a: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    structure1120b: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    structure1120c: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    structure1170: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
  };
  private tablesInitialized: number = 0;

  public activeTabId: number = 0;
  public multiTabGroup = [
    { id: 0, name: 'SEARCH_SAMPLES', isChecked: true },
    { id: 1, name: 'SEARCH_SAMPLES_SEA_HABITATS', isChecked: true },
    { id: 2, name: 'SEARCH_SPECIES', isChecked: true },
    { id: 3, name: 'SEARCH_THREATS', isChecked: true },
    { id: 4, name: 'SEARCH_STRUCTURES_1120A', isChecked: true },
    { id: 5, name: 'SEARCH_STRUCTURES_1120B', isChecked: true },
    { id: 6, name: 'SEARCH_STRUCTURES_1120C', isChecked: true },
    {
      id: 7,
      name: 'SEARCH_STRUCTURES_1170',
      isChecked: true,
    },
  ];

  constructor(
    protected override searchService: SearchService,
    protected override protocolService: ProtocolsService,
    private seaService: SeaService,
    protected override fb: FormBuilder,
    protected override translate: TranslateService,
    private modalService: NgbModal,
    protected override toastService: ToastService
  ) {
    super(searchService, protocolService, fb, translate, toastService);
  }

  ngOnInit(): void {
    this.fetchSampleSeaHabitatsColumns();
    this.fetchSampleColumns();
    this.fetchSpeciesColumns();
    this.fetchThreatsColumns();
    this.fetchStructures1120aColumns();
    this.fetchStructures1120bColumns();
    this.fetchStructures1120cColumns();
    this.fetchStructures1170Columns();

    this.watchDataversion();
  }

  protected override fetchSampleColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.sampleColumns = sampleColumns;
    const sampleColumnsTemp = [...sampleColumns];
    sampleColumnsTemp.forEach((column) => {
      if (
        column.searchDataType === DataType.List &&
        (column.data == null || column.data.length === 0)
      ) {
        if (column.propertyName === 'prefecture') {
          this.protocolService.getPrefectures().subscribe((data) => {
            column.data = data.map((prefecture) => {
              return { id: prefecture.id, name: prefecture.prefecture };
            });
            this.sampleColumns = sampleColumnsTemp;
          });
        }
      }
      if (column.header === 'RANDOM_OBSERVATION') {
        column.shown = false;
        this.sampleColumns = sampleColumnsTemp;
      }
    });
  }

  protected override fetchSpeciesColumns(): void {
    this.speciesColumns = sampleSeaHabitatSpeciesColumns;
    forkJoin([
      this.searchService.getSpecies(Protocols.SeaHabitat, this.version),
    ]).subscribe(([species]) => {
      if (species != null) {
        const speciesItem = this.speciesColumns.find(
          (item) => item.propertyName === 'species'
        );
        if (speciesItem != null) {
          speciesItem.data = species.map((item) => {
            return {
              id: item.id,
              name: `(${item.id}) - ${item.name}`,
            };
          });
        }
      }
    });
  }

  protected override fetchThreatsColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.threatsColumns = sampleSeaHabitatThreatsColumns;

    forkJoin([this.seaService.getHabitats(this.version)]).subscribe(
      ([habitatTypes]) => {
        if (habitatTypes != null) {
          const habType = this.threatsColumns.find(
            (item) => item.propertyName === 'habType'
          );
          if (habType != null) {
            habType.data = habitatTypes.map((item) => {
              return {
                id: item.code,
                name: `${item.habType}`,
              };
            });
          }
        }
      }
    );
  }

  private fetchSampleSeaHabitatsColumns(): void {
    this.sampleSeaHabitatColumns = sampleSeaHabitatColumns;

    forkJoin([this.seaService.getHabitats(this.version)]).subscribe(
      ([habitatTypes]) => {
        if (habitatTypes != null) {
          const habType = this.sampleSeaHabitatColumns.find(
            (item) => item.propertyName === 'habType'
          );
          if (habType != null) {
            habType.data = habitatTypes.map((item) => {
              return {
                id: item.code,
                name: `${item.habType}`,
              };
            });
          }
        }
      }
    );
  }

  private fetchStructures1120aColumns(): void {
    this.structures1120aColumns = sampleSeaHabitatStructures1120aColumns;
  }

  private fetchStructures1120bColumns(): void {
    this.structures1120bColumns = sampleSeaHabitatStructures1120bColumns;
  }

  private fetchStructures1120cColumns(): void {
    this.structures1120cColumns = sampleSeaHabitatStructures1120cColumns;
  }

  private fetchStructures1170Columns(): void {
    this.structures1170Columns = sampleSeaHabitatStructures1170Columns;
  }

  protected override watchDataversion(): void {
    this.subscription.add(
      this.form.controls['dataVersion'].valueChanges.subscribe((version) => {
        // Updating tables.
        const dataVersion = this.payload.samples?.items.find(
          (item) => item.name === 'DataVersion'
        );
        if (dataVersion != null) {
          if (dataVersion.rules != null && dataVersion.rules.length > 0) {
            dataVersion.rules[0].value = version;
          } else {
            dataVersion.rules = [
              {
                value: version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ];
          }
        } else {
          this.payload.samples?.items.push({
            name: 'DataVersion',
            dataType: DataType.String,
            rules: [
              {
                value: this.version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ],
          });
        }

        this.fetchSampleSeaHabitatsColumns();
        this.fetchSampleColumns();
        this.fetchSpeciesColumns();
        this.fetchThreatsColumns();
        this.fetchStructures1120aColumns();
        this.fetchStructures1120bColumns();
        this.fetchStructures1120cColumns();
        this.fetchStructures1170Columns();

        this.fetchData();
      })
    );
  }

  protected override fetchData(): void {
    this.searchService.filterSeaHabitats(this.payload).subscribe((response) => {
      if (response != null) {
        if (response.samples != null) {
          this.sampleTotalRecords = response.samples.totalCount;

          // TODO: Uncomment this when we resolve the time filtering issue.
          // response.samples.rows.forEach(item => {
          //   if (item.time != null) {
          //     const timeString = item.time.toString().slice(0, 5);
          //     item.time = timeString;
          //   }
          // });

          this.samples = response.samples.rows;
        }

        if (response.sampleSeaHabitat != null) {
          this.sampleSeaHabitatTotalRecords =
            response.sampleSeaHabitat.totalCount;
          this.sampleSeaHabitat = response.sampleSeaHabitat.rows;
          this.sampleCoordinates = this.sampleSeaHabitat
            .filter((item) => item.lat != null && item.long != null)
            .map((item) => {
              return {
                lat: item.lat,
                lng: item.long,
                text: item.samplingCode,
              };
            });
        }

        if (response.speciesSeaHabitat != null) {
          this.speciesTotalRecords = response.speciesSeaHabitat.totalCount;
          this.species = response.speciesSeaHabitat.rows;
        }

        if (response.threatsSeaHabitat != null) {
          this.threatsTotalRecords = response.threatsSeaHabitat.totalCount;
          this.threats = response.threatsSeaHabitat.rows;
        }

        let speciesCoordinates: ISearchCoordinates[] = [];

        if (response.structures1120aSeaHabitat != null) {
          this.structures1120aTotalRecords =
            response.structures1120aSeaHabitat.totalCount;
          this.structures1120a = response.structures1120aSeaHabitat.rows;
          speciesCoordinates = this.structures1120a
            .filter(
              (item) =>
                item.latitude != null &&
                item.longitude != null &&
                item.latitude !== -1 &&
                item.longitude !== -1
            )
            .map((item) => {
              return {
                lat: item.latitude,
                lng: item.longitude,
                text:
                  item.samplingCode != null
                    ? item.samplingCode.toString()
                    : undefined,
              };
            });
        }

        if (response.structures1120bSeaHabitat != null) {
          this.structures1120bTotalRecords =
            response.structures1120bSeaHabitat.totalCount;
          this.structures1120b = response.structures1120bSeaHabitat.rows;
        }

        if (response.structures1120cSeaHabitat != null) {
          this.structures1120cTotalRecords =
            response.structures1120cSeaHabitat.totalCount;
          this.structures1120c = response.structures1120cSeaHabitat.rows;

          speciesCoordinates = this.structures1120c
            .filter(
              (item) =>
                item.latitude != null &&
                item.longitude != null &&
                item.latitude !== -1 &&
                item.longitude !== -1
            )
            .map((item) => {
              return {
                lat: item.latitude,
                lng: item.longitude,
                text:
                  item.samplingCode != null
                    ? item.samplingCode.toString()
                    : undefined,
              };
            });
        }

        this.speciesCoordinates = speciesCoordinates;

        if (response.structures1170SeaHabitat != null) {
          this.structures1170TotalRecords =
            response.structures1170SeaHabitat.totalCount;
          this.structures1170 = response.structures1170SeaHabitat.rows;
        }
      }
    });
  }

  public loadSampleSeaHabitatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 8) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaHabitatColumns
    );
    this.payload.sampleSeaHabitat = tableFilter;

    if (event.first) {
      this.sampleSeaHabitatFirst = event.first;
    }

    this.fetchData();
  }

  public loadSpeciesData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 8) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaHabitatSpeciesColumns
    );
    this.payload.species = tableFilter;

    if (event.first) {
      this.speciesFirst = event.first;
    }

    this.fetchData();
  }

  public loadThreatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 8) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaHabitatThreatsColumns
    );
    this.payload.threats = tableFilter;

    if (event.first) {
      this.threatsFirst = event.first;
    }

    this.fetchData();
  }

  public loadStructures1120aData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 8) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaHabitatStructures1120aColumns
    );
    this.payload.structure1120a = tableFilter;

    if (event.first) {
      this.structures1120aFirst = event.first;
    }

    this.fetchData();
  }

  public loadStructures1120bData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 8) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaHabitatStructures1120bColumns
    );
    this.payload.structure1120b = tableFilter;

    if (event.first) {
      this.structures1120bFirst = event.first;
    }

    this.fetchData();
  }

  public loadStructures1120cData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 8) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaHabitatStructures1120cColumns
    );
    this.payload.structure1120c = tableFilter;

    if (event.first) {
      this.structures1120cFirst = event.first;
    }

    this.fetchData();
  }

  public loadStructures1170Data(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 8) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleSeaHabitatStructures1170Columns
    );
    this.payload.structure1170 = tableFilter;

    if (event.first) {
      this.structures1170First = event.first;
    }

    this.fetchData();
  }

  protected override initializePaginator() {
    this.sampleFirst = 0;
    this.sampleSeaHabitatFirst = 0;
    this.speciesFirst = 0;
    this.threatsFirst = 0;
    this.structures1120aFirst = 0;
    this.structures1120bFirst = 0;
    this.structures1120cFirst = 0;
    this.structures1170First = 0;
  }

  public openExportModal() {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ExportModalComponent, {
      centered: true,
      modalDialogClass: 'export-modal',
      backdrop: 'static',
      keyboard: true,
    });

    if (modalRef) {
      modalRef.result.then((res) => {
        if (res != null) {
          this.exportFiles(res.xlsxFile, res.zipFile);
        }
      });
    }
  }

  public exportFiles(xlsxFile = false, zipFile = false) {
    let payload = { ...this.payload };
    if (payload.samples) {
      payload.samples.rows = this.sampleTotalRecords;
      payload.samples.first = 0;
    }
    if (payload.sampleSeaHabitat) {
      payload.sampleSeaHabitat.rows = this.sampleSeaHabitatTotalRecords;
      payload.sampleSeaHabitat.first = 0;
    }
    if (payload.species) {
      payload.species.rows = this.speciesTotalRecords;
      payload.species.first = 0;
    }
    if (payload.threats) {
      payload.threats.rows = this.threatsTotalRecords;
      payload.threats.first = 0;
    }
    if (payload.structure1120a) {
      payload.structure1120a.rows = this.structures1120aTotalRecords;
      payload.structure1120a.first = 0;
    }
    if (payload.structure1120b) {
      payload.structure1120b.rows = this.structures1120bTotalRecords;
      payload.structure1120b.first = 0;
    }
    if (payload.structure1120c) {
      payload.structure1120c.rows = this.structures1120cTotalRecords;
      payload.structure1120c.first = 0;
    }
    if (payload.structure1170) {
      payload.structure1170.rows = this.structures1170TotalRecords;
      payload.structure1170.first = 0;
    }

    let samples: any[] = [];
    let speciesSeaHabitat: any[] = [];
    let threatsSeaHabitat: any[] = [];
    let structures1120aSeaHabitat: any[] = [];
    let structures1120bSeaHabitat: any[] = [];
    let structures1120cSeaHabitat: any[] = [];
    let structures1170SeaHabitat: any[] = [];

    this.searchService.filterSeaHabitats(payload).subscribe((response) => {
      const date = new Date();
      const filename =
        'EP2_Export_Sea-Habitats_' +
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      if (xlsxFile) {
        if (response && response.sampleSeaHabitat) {
          samples = this.buildExportArray(
            response,
            'sampleSeaHabitat',
            true,
            seaHabitatConfig,
            ''
          );
        }

        if (response && response.speciesSeaHabitat) {
          speciesSeaHabitat = this.buildExportArray(
            response,
            'speciesSeaHabitat',
            false,
            seaHabitatConfig,
            SeaHabitatFormGroup.Species
          );
        }

        if (response && response.threatsSeaHabitat) {
          threatsSeaHabitat = this.buildExportArray(
            response,
            'threatsSeaHabitat',
            false,
            seaHabitatConfig,
            SeaHabitatFormGroup.Threats
          );
        }

        if (response && response.structures1120aSeaHabitat) {
          structures1120aSeaHabitat = this.buildExportArray(
            response,
            'structures1120aSeaHabitat',
            false,
            seaHabitatConfig,
            SeaHabitatFormGroup.A
          );
        }

        if (response && response.structures1120bSeaHabitat) {
          structures1120bSeaHabitat = this.buildExportArray(
            response,
            'structures1120bSeaHabitat',
            false,
            seaHabitatConfig,
            SeaHabitatFormGroup.B
          );
        }

        if (response && response.structures1120cSeaHabitat) {
          structures1120cSeaHabitat = this.buildExportArray(
            response,
            'structures1120cSeaHabitat',
            false,
            seaHabitatConfig,
            SeaHabitatFormGroup.C
          );
        }

        if (response && response.structures1170SeaHabitat) {
          structures1170SeaHabitat = this.buildExportArray(
            response,
            'structures1170SeaHabitat',
            false,
            seaHabitatConfig,
            SeaHabitatFormGroup.Struct1170
          );
        }

        const workbook = utils.book_new();

        const wsSamples = utils.aoa_to_sheet(samples);
        wsSamples['!cols'] = this.fixExportArrayColumnsWidth(samples);
        wsSamples['!rows'] = [{ hpt: 50 }];

        const wsSpeciesSeaHabitat = utils.aoa_to_sheet(speciesSeaHabitat);
        wsSpeciesSeaHabitat['!cols'] =
          this.fixExportArrayColumnsWidth(speciesSeaHabitat);
        wsSpeciesSeaHabitat['!rows'] = [{ hpt: 50 }];

        const wsThreatsSeaHabitat = utils.aoa_to_sheet(threatsSeaHabitat);
        wsThreatsSeaHabitat['!cols'] =
          this.fixExportArrayColumnsWidth(threatsSeaHabitat);
        wsThreatsSeaHabitat['!rows'] = [{ hpt: 50 }];

        const wsStructures1120aSeaHabitat = utils.aoa_to_sheet(
          structures1120aSeaHabitat
        );
        wsStructures1120aSeaHabitat['!cols'] = this.fixExportArrayColumnsWidth(
          structures1120aSeaHabitat
        );
        wsStructures1120aSeaHabitat['!rows'] = [{ hpt: 50 }];

        const wsStructures1120bSeaHabitat = utils.aoa_to_sheet(
          structures1120bSeaHabitat
        );
        wsStructures1120bSeaHabitat['!cols'] = this.fixExportArrayColumnsWidth(
          structures1120bSeaHabitat
        );
        wsStructures1120bSeaHabitat['!rows'] = [{ hpt: 50 }];

        const wsStructures1120cSeaHabitat = utils.aoa_to_sheet(
          structures1120cSeaHabitat
        );
        wsStructures1120cSeaHabitat['!cols'] = this.fixExportArrayColumnsWidth(
          structures1120aSeaHabitat
        );
        wsStructures1120cSeaHabitat['!rows'] = [{ hpt: 50 }];

        const wsStructures1170SeaHabitat = utils.aoa_to_sheet(
          structures1170SeaHabitat
        );
        wsStructures1170SeaHabitat['!cols'] = this.fixExportArrayColumnsWidth(
          structures1170SeaHabitat
        );
        wsStructures1170SeaHabitat['!rows'] = [{ hpt: 50 }];

        utils.book_append_sheet(
          workbook,
          wsSamples,
          this.translate.instant('SPREADSHEET_SEA_HABITAT_SAMPLES')
        );
        utils.book_append_sheet(
          workbook,
          wsSpeciesSeaHabitat,
          this.translate.instant('SPREADSHEET_SEA_HABITAT_SPECIES')
        );
        utils.book_append_sheet(
          workbook,
          wsThreatsSeaHabitat,
          this.translate.instant('SPREADSHEET_SEA_HABITAT_THREATS')
        );
        utils.book_append_sheet(
          workbook,
          wsStructures1120aSeaHabitat,
          this.translate.instant('SPREADSHEET_SEA_HABITAT_A')
        );
        utils.book_append_sheet(
          workbook,
          wsStructures1120bSeaHabitat,
          this.translate.instant('SPREADSHEET_SEA_HABITAT_B')
        );
        utils.book_append_sheet(
          workbook,
          wsStructures1120cSeaHabitat,
          this.translate.instant('SPREADSHEET_SEA_HABITAT_C')
        );
        utils.book_append_sheet(
          workbook,
          wsStructures1170SeaHabitat,
          this.translate.instant('SPREADSHEET_SEA_HABITAT_1170')
        );

        const date = new Date();
        writeFile(workbook, filename + '.xlsx', { compression: true });
      }

      if (zipFile) {
        this.getZip(response, filename);
      }
    });
  }
}
