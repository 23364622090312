import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { IUploadCodesResponse } from 'src/app/core/dtos/upload-codes.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import { dateValidator } from 'src/app/shared/validators/dateValidator';
import { timeValidator } from 'src/app/shared/validators/timeValidator';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SamplesService {
  constructor(private http: HttpClient, private common: CommonService) {}

  public initSamplesFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      date: fb.control('', {
        validators: [Validators.required, dateValidator()],
      }),
      time: fb.control('', {
        validators: [Validators.required, timeValidator()],
      }),
      naturaCode: fb.control(''),
      samplingCode: fb.control('', [Validators.required]),
      financialInstrument: fb.control('ΥΜΕΠΕΡΑΑ', [Validators.required]),
      prefecture: fb.control(''),
      researcher: fb.control(''),
      locality: fb.control(''),
      observations: fb.control(''),
      file: fb.control(''),
      gridCell: fb.control(''),
      photo: fb.control(''),
      lat: fb.control('', [
        Validators.required,
        this.common.latLongValidator('lat', 'long'),
      ]),
      long: fb.control('', [
        Validators.required,
        this.common.latLongValidator('lat', 'long'),
      ]),
      x: fb.control('', [this.common.latLongValidator('x', 'y', true)]),
      y: fb.control('', [this.common.latLongValidator('x', 'y', true)]),
    });
  }

  public addSpreadsheetFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'fileUpload';
    return this.http.post<void>(url, payload);
  }

  public addSpreadsheetBookFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'bookFileUpload';
    return this.http.post<void>(url, payload);
  }

  public getUpdateCodes(
    protocolId: Protocols
  ): Observable<IUploadCodesResponse[]> {
    const url = environment.endpoint + 'uploadCodes';
    const params = new HttpParams().set('protocolId', protocolId);
    return this.http.get<IUploadCodesResponse[]>(url, { params: params });
  }
}
