<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="
    toast.options.autohide !== undefined ? toast.options.autohide : true
  "
  [delay]="toast.delay || 5000"
  (hidden)="show = false"
  header="{{ toast.options.header ?? '' | translate }}"
  class="toast-show"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template
      [ngTemplateOutlet]="convertToTemplateType(toast)"
    ></ng-template>
  </ng-template>

  <ng-template #text>{{ convertToStringType(toast) | translate }}</ng-template>
</ngb-toast>
