<ng-container [formGroup]="formGroup">
  <div formArrayName="habitats" class="form-container form-1">
    <ng-container
      *ngFor="let speciesForm of habitats.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCode1'"
          [label]="'HABITAT_CODE_1'"
          [dropdownList]="habitatCode1Dropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['habitatCode1'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCode2'"
          [label]="'HABITAT_CODE_2'"
          [dropdownList]="habitatCode2Dropdown"
          [preDefinedId]="getGroup(index).controls['habitatCode2'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCode3'"
          [label]="'HABITAT_CODE_3'"
          [dropdownList]="habitatCode3Dropdown"
          [preDefinedId]="getGroup(index).controls['habitatCode3'].value"
        ></app-dropdown>
      </div>
      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'humanActivities'"
          [label]="'HUMAN_ACTIVITIES'"
          [dropdownList]="humanActivitiesDropdown"
          [preDefinedId]="getGroup(index).controls['humanActivities'].value"
        ></app-dropdown>
      </div>

      <app-button
        (buttonClicked)="removeHabitat(index)"
        [style.--btnPadding]="'6px 15px'"
        class="minus-btn"
        [btnText]="'-'"
        [tooltip]="'REMOVE_ENTRY' | translate"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== habitats.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_HABITAT'"
      (buttonClicked)="addNewHabitat()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
