import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './button/button.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { LabelInputComponent } from './label-input/label-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { MapComponent } from './map/map.component';
import { NgxMaskModule } from 'ngx-mask';
import { SimpleToastComponent } from './simple-toast/simple-toast.component';
import { SpreadsheetUploadComponent } from './spreadsheet-upload/spreadsheet-upload.component';
import { SpreadsheetReportModalComponent } from './spreadsheet-report-modal/spreadsheet-report-modal.component';
import { AuthGuard } from './guards/auth.guard';
import { RedirectAuthGuard } from './guards/redirect-auth.guard';
import { DragNDropDirective } from './directives/drag-n-drop.directive';
import { FilesContainerComponent } from './files-container/files-container.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormSuccessModalComponent } from './form-success-modal/form-success-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextareaComponent } from './textarea/textarea.component';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';
import { DropdownModule } from 'primeng/dropdown';
import { LoaderComponent } from './loader/loader.component';
import { TableModule } from 'primeng/table';
import { UploadFilesModalComponent } from './upload-files-modal/upload-files-modal.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { TableComponent } from '../shared/table/table.component';
import { TableWrapperComponent } from '../shared/table-wrapper/table-wrapper.component';
import { MammalSearchComponent } from '../shared/mammal-search/mammal-search.component';
import { AmphibianSearchComponent } from '../shared/amphibian-search/amphibian-search.component';
import { SeaSearchComponent } from '../shared/sea-search/sea-search.component';
import { FishSearchComponent } from './fish-search/fish-search.component';
import { ChlorisSearchComponent } from './chloris-search/chloris-search.component';
import { MapSearchComponent } from '../shared/map-search/map-search.component';
import { SeaHabitatsSearchComponent } from './sea-habitats-search/sea-habitats-search.component';
import { BookSearchComponent } from './book-search/book-search.component';
import { AccordionModule } from 'primeng/accordion';
import { InvertebrateSearchComponent } from './invertebrate-search/invertebrate-search.component';
import { BirdSearchComponent } from './bird-search/bird-search.component';
import { ExportModalComponent } from './export-modal/export-modal.component';

@NgModule({
  declarations: [
    ButtonComponent,
    InputComponent,
    DropdownComponent,
    ButtonGroupComponent,
    LabelInputComponent,
    MapComponent,
    SimpleToastComponent,
    SpreadsheetUploadComponent,
    SpreadsheetReportModalComponent,
    DragNDropDirective,
    FilesContainerComponent,
    FormSuccessModalComponent,
    TextareaComponent,
    ConfirmModalComponent,
    ConfirmModalComponent,
    LoaderComponent,
    UploadFilesModalComponent,
    TableComponent,
    MammalSearchComponent,
    AmphibianSearchComponent,
    SeaSearchComponent,
    FishSearchComponent,
    ChlorisSearchComponent,
    MapSearchComponent,
    SeaHabitatsSearchComponent,
    BookSearchComponent,
    InvertebrateSearchComponent,
    BirdSearchComponent,
    ExportModalComponent,
  ],
  exports: [
    ButtonComponent,
    InputComponent,
    TextareaComponent,
    DropdownComponent,
    ButtonGroupComponent,
    LabelInputComponent,
    MapComponent,
    SimpleToastComponent,
    SpreadsheetUploadComponent,
    DragNDropDirective,
    FilesContainerComponent,
    NgbTypeaheadModule,
    ConfirmModalComponent,
    NgbModule,
    LoaderComponent,
    TableModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TableComponent,
    MammalSearchComponent,
    AmphibianSearchComponent,
    SeaSearchComponent,
    FishSearchComponent,
    ChlorisSearchComponent,
    MapSearchComponent,
    SeaHabitatsSearchComponent,
    BookSearchComponent,
    AccordionModule,
    InvertebrateSearchComponent,
    BirdSearchComponent,
    ExportModalComponent,
  ],
  providers: [AuthGuard, RedirectAuthGuard],
  imports: [
    TranslateModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgxMaskModule.forRoot(),
    NgbToastModule,
    NgbTypeaheadModule,
    NgbModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    AccordionModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
