export enum AppPermissions {
  AMPHIBIAN = 'access:amphibian',
  FISH = 'access:fish',
  HABITAT = 'access:habitat',
  INVERTEBRATE = 'access:invertebrate',
  MAMMAL = 'access:mammal',
  SEA = 'access:sea',
  CHLORIS = 'access:chloris',
  BIRD = 'access:bird',
  BIBLIOGRAPHY = 'access:book',
}
