import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';
import { IMainCTSDto, ISampleFishSpeciesDto } from 'src/app/core/dtos/fish.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { FishService } from '../../services/fish.service';

@Component({
  selector: 'app-fish-species',
  templateUrl: './fish-species.component.html',
  styleUrls: ['./fish-species.component.scss'],
})
export class FishSpeciesComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctSpecies: IMainCTSDto[] = [];
  public speciesForm!: FormGroup;
  public speciesDropdown: IDropdownItem[] = [];
  public EOKDropdown: IDropdownItem[] = [
    { id: 0, name: this.translate.instant('PARII') },
    { id: 1, name: this.translate.instant('PARIV') },
    { id: 2, name: this.translate.instant('PARV') },
  ];

  public speciesHabitatDropdown: IDropdownItem[] = [
    { id: 'Ευνοϊκό', name: 'Ευνοϊκό' },
    { id: 'Ανεπαρκές', name: 'Ανεπαρκές' },
    { id: 'Δυσμενές', name: 'Δυσμενές' },
    { id: 'Άγνωστο', name: 'Άγνωστο' },
  ];

  @Input() public species!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public speciesById: ISampleFishSpeciesDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private fishService: FishService,
    private translate: TranslateService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.speciesById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    this.fishService
      .getSpecies(this.dataVersion)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          if (this.speciesById.length > 0) {
            this.speciesById.forEach((sp, index) => {
              this.addNewSpecies();
              if (sp.otherSpecies) {
                (this.species.controls[index] as FormGroup).controls[
                  'otherSpecies'
                ].enable({
                  emitEvent: false,
                });
              }
            });
            this.species.patchValue(this.speciesById);
            this.species.markAsTouched();
            this.speciesById = [];
            if (this.disabledControls === true) {
              this.species.disable();
            }
          }
          return;
        })
      )
      .subscribe((res) => {
        if (res != undefined) {
          this.ctSpecies = res as IMainCTSDto[];
          if (this.ctSpecies.length > 0) {
            this.ctSpecies.forEach((species) => {
              this.speciesDropdown.push({
                id: species.code,
                name: species.code,
                actualId: species.id,
              });
            });
          }
        }
        this.species.valueChanges.subscribe((value) => {
          this.species.controls.forEach(
            (control: AbstractControl, index: number) => {
              this.checkSpecies(
                (control as FormGroup).controls['species'].value,
                index
              );
              if (!(control as FormGroup).controls['species'].disabled) {
                const species = this.ctSpecies.find(
                  (item) =>
                    item.id === (control as FormGroup).controls['species'].value
                );
                if (species == null) {
                  (control as FormGroup).controls['otherSpecies'].setValue('', {
                    emitEvent: false,
                  });
                  (control as FormGroup).controls[
                    'otherSpecies'
                  ].clearValidators();
                  (control as FormGroup).controls['otherSpecies'].disable({
                    emitEvent: false,
                  });
                  return;
                }

                if (species.code === 'Άλλο') {
                  (control as FormGroup).controls['otherSpecies'].enable({
                    emitEvent: false,
                  });
                  (control as FormGroup).controls['otherSpecies'].setValidators(
                    Validators.required
                  );
                  (control as FormGroup).controls[
                    'otherSpecies'
                  ].updateValueAndValidity({
                    emitEvent: false,
                    onlySelf: true,
                  });
                } else {
                  (control as FormGroup).controls['otherSpecies'].setValue('', {
                    emitEvent: false,
                  });
                  (control as FormGroup).controls[
                    'otherSpecies'
                  ].clearValidators();
                  (control as FormGroup).controls['otherSpecies'].disable({
                    emitEvent: false,
                  });
                  (control as FormGroup).controls[
                    'otherSpecies'
                  ].updateValueAndValidity({
                    emitEvent: false,
                    onlySelf: true,
                  });
                }
              }
            }
          );
        });
      });
  }

  public checkSpecies(species: string, index: number): void {
    let speciesCode = this.ctSpecies.find(
      (value) => value.id === parseInt(species)
    );
    if (speciesCode != null) {
      if (parseInt(species) === speciesCode.id) {
        if (speciesCode.annexII?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexIV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'pariv'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'pariv'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls['parv'].setValue(
            true,
            { emitEvent: false }
          );
        } else {
          (this.species.controls[index] as FormGroup).controls['parv'].setValue(
            false,
            { emitEvent: false }
          );
        }
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.species.controls[index] as FormGroup;
  }

  public addNewSpecies(): void {
    this.species.push(this.fishService.initSpeciesFormGroup(this.fb));
  }

  public removeSpecies(index: number): void {
    this.species.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
