import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Subject, finalize } from 'rxjs';
import {
  IHabitatCoverArea,
  IHabitatCoverPlot,
  IHabitatSpecies,
  IHabitatVitality,
  ISpeciesRequest,
} from 'src/app/core/dtos/habitat.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { HabitatService } from '../../services/habitat.service';

@Component({
  selector: 'app-habitat-species',
  templateUrl: './habitat-species.component.html',
  styleUrls: ['./habitat-species.component.scss'],
})
export class HabitatSpeciesComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctStructs: IHabitatSpecies[] = [];
  public speciesForm!: FormGroup;
  public structsDropdown: IDropdownItem[] = [];

  public ctVitality: IHabitatVitality[] = [];
  public vitalityDropdown: IDropdownItem[] = [];
  public ctCoverArea: IHabitatCoverArea[] = [];
  public coverAreaDropdown: IDropdownItem[] = [];
  public ctCoverPlot: IHabitatCoverPlot[] = [];
  public coverPlotDropdown: IDropdownItem[] = [];

  @Input() public species!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public speciesById: ISpeciesRequest[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private habitatService: HabitatService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.speciesById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.habitatService.getSpecies(this.dataVersion),
      this.habitatService.getVitality(this.dataVersion),
      this.habitatService.getCoverArea(this.dataVersion),
      this.habitatService.getCoverPlot(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.ctStructs.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctStructs.forEach((species) => {
              dropdown.push({
                id: species.code,
                name: (species as any).species,
              });
            });
            this.structsDropdown = dropdown;
          }

          if (this.ctVitality.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctVitality.forEach((species) => {
              dropdown.push({
                id: species.code,
                name: species.description ?? '',
              });
            });
            this.vitalityDropdown = dropdown;
          }

          if (this.ctCoverArea.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctCoverArea.forEach((species) => {
              dropdown.push({
                id: species.code,
                name: species.description ?? '',
              });
            });
            this.coverAreaDropdown = dropdown;
          }

          if (this.ctCoverPlot.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctCoverPlot.forEach((species) => {
              dropdown.push({
                id: species.code,
                name: species.code,
              });
            });
            this.coverPlotDropdown = dropdown;
          }

          if (this.speciesById.length > 0) {
            this.speciesById.forEach(() => {
              this.addNew();
            });
            this.species.patchValue(this.speciesById);
            this.species.markAsTouched();
            this.speciesById = [];
            if (this.disabledControls === true) {
              this.species.disable();
            }
          }

          return;
        })
      )
      .subscribe(([ctStructs, ctVitality, ctCoverArea, ctCoverPlot]) => {
        if (ctStructs != undefined) {
          this.ctStructs = ctStructs as any[];
        }

        if (ctVitality != undefined) {
          this.ctVitality = ctVitality as any[];
        }

        if (ctCoverArea != undefined) {
          this.ctCoverArea = ctCoverArea as any[];
        }

        if (ctCoverPlot != undefined) {
          this.ctCoverPlot = ctCoverPlot as any[];
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.species.controls[index] as FormGroup;
  }

  public addNew(): void {
    this.species.push(this.habitatService.initSpeciesFormGroup(this.fb));
  }

  public remove(index: number): void {
    this.species.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
