import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IMammalSpeciesDto, IMammalsDto } from 'src/app/core/dtos/mammals.dto';
import {
  IAmphibianDto,
  IAmphibianSpeciesDto,
} from 'src/app/core/dtos/amphibian.dto';
import { IBirdDto, IBirdSpeciesDto } from 'src/app/core/dtos/bird.dto';
import { IChlorisDto, IChlorisSpecies } from 'src/app/core/dtos/chloris.dto';
import { IFishDto, IMainCTSDto } from 'src/app/core/dtos/fish.dto';
import { IHabitatDto, IHabitatType } from 'src/app/core/dtos/habitat.dto';
import {
  IInvertebrateDto,
  IInvertebrateSpeciesDto,
} from 'src/app/core/dtos/invertebrate.dto';
import { ISeaDto } from 'src/app/core/dtos/sea.dto';
import { ISeaHabitatsDto } from 'src/app/core/dtos/sea-habitat.dto';
import { read, utils, WorkBook, WorkSheet } from 'xlsx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MammalsService } from 'src/app/samplings/services/mammals.service';
import { AmphibiansService } from 'src/app/samplings/services/amphibians.service';
import { FishService } from 'src/app/samplings/services/fish.service';
import {
  SpreadsheetReportModalComponent,
  SpreadsheetReportModalType,
} from '../spreadsheet-report-modal/spreadsheet-report-modal.component';
import { InvertebrateService } from 'src/app/samplings/services/invertebrate.service';
import { HabitatService } from 'src/app/samplings/services/habitat.service';
import { SeaService } from 'src/app/samplings/services/sea.service';
import { BirdService } from 'src/app/samplings/services/bird.service';
import { ChlorisService } from 'src/app/samplings/services/chloris.service';
import { IBookSpeciesHabitatSpreadDto } from 'src/app/core/dtos/bookSpeciesHabitatSpread.dto';
import { Subject, forkJoin, takeUntil, map } from 'rxjs';
import { BookHabitatsSpeciesService } from 'src/app/samplings/services/book-habitats-species.service';
import { CommonService } from 'src/app/core/services/common.service';
import { isValid, parseISO } from 'date-fns';
import { ToastService } from '../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
import {
  amphibianConfig,
  AmphibianFormGroup,
} from 'src/app/samplings/config-files/amphibian-config';
import { birdConfig } from 'src/app/samplings/config-files/bird-config';
import { chlorisConfig } from 'src/app/samplings/config-files/chloris-config';
import {
  bibliographyConfig,
  BibliographyFormGroup,
} from 'src/app/samplings/config-files/bibliography-config';
import { mammalsConfig } from 'src/app/samplings/config-files/mammals-config';
import { fishConfig } from 'src/app/samplings/config-files/fish-config';
import { habitatConfig } from 'src/app/samplings/config-files/habitat-config';
import { invertebrateConfig } from 'src/app/samplings/config-files/invertebrate-config';
import {
  seaConfig,
  SeaFormGroup,
  SeaSpeciesCategory,
} from 'src/app/samplings/config-files/sea-config';
import { seaHabitatConfig } from 'src/app/samplings/config-files/sea-habitat-config';
import { IJsonData, IJsonResponse } from 'src/app/core/dtos/spreadsheets.dto';
import { Prefectures } from 'src/app/core/dtos/prefectures.dto';
import { SpreadsheetFormsService } from 'src/app/samplings/services/spreadsheet-forms.service';
import { ISpreadsheetFileUploadPayload } from '../dtos/generic.dto';

export interface ISpreadsheet {
  configFile: IConfigFile[];
  downloadFilepath: string;
  savedFilename: string;
}

export interface IErrors {
  sheet: string;
  row: number;
  column: string;
  description: string;
}

@Component({
  selector: 'app-spreadsheet-upload',
  templateUrl: './spreadsheet-upload.component.html',
  styleUrls: ['./spreadsheet-upload.component.scss'],
})
export class SpreadsheetUploadComponent implements OnInit, OnDestroy {
  @Input() protocolId = 0;
  @Output() uploadCompleted = new EventEmitter<void>();
  public fileJson: IJsonResponse | undefined;
  public spreadsheetFormArray!: FormArray<any>;
  public spreadsheetForm!: FormGroup;
  public spreadsheet: ISpreadsheet = {
    configFile: [],
    downloadFilepath: '',
    savedFilename: '',
  };
  public configFile: IConfigFile[] = [];
  public disableUpload = true;
  public fileName = '';
  public request:
    | IMammalsDto[]
    | IAmphibianDto[]
    | IBirdDto[]
    | IChlorisDto[]
    | IFishDto[]
    | IHabitatDto[]
    | IInvertebrateDto[]
    | ISeaDto[]
    | ISeaHabitatsDto[]
    | IBookSpeciesHabitatSpreadDto[] = [];
  public uploaded = false;
  public numberOfRequest = 0;
  public notUploaded = false;
  public uploadedWithErrors = false;
  public loading = false;
  public infoMsg = '';
  public formErrors: ValidationErrors[] = [];
  public errors: IErrors[] = [];
  private destroy$ = new Subject<void>();

  @ViewChild('uploader', { read: ElementRef }) uploader!: ElementRef;

  constructor(
    private fb: FormBuilder,
    private spreadsheetFormsService: SpreadsheetFormsService,
    private renderer: Renderer2,
    private modalService: NgbModal,
    private mammalsService: MammalsService,
    private amphibiansService: AmphibiansService,
    private fishService: FishService,
    private habitatService: HabitatService,
    private invertebrateService: InvertebrateService,
    private seaService: SeaService,
    private birdService: BirdService,
    private chlorisService: ChlorisService,
    public bookService: BookHabitatsSpeciesService,
    private commonService: CommonService,
    public toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.spreadsheetFormArray = this.fb.array([]);
    if (this.protocolId === Protocols.Bibliography) {
      this.spreadsheet = {
        configFile: bibliographyConfig,
        downloadFilepath: 'assets/spreadsheets/Bibliography.xlsx',
        savedFilename: 'Bibliography.xlsx',
      };
    } else if (this.protocolId === Protocols.Mammals) {
      this.spreadsheet = {
        configFile: mammalsConfig,
        downloadFilepath: 'assets/spreadsheets/Mammals.xlsx',
        savedFilename: 'Mammals.xlsx',
      };
    } else if (this.protocolId === Protocols.Amphibian) {
      this.spreadsheet = {
        configFile: amphibianConfig,
        downloadFilepath: 'assets/spreadsheets/Amphibians.xlsx',
        savedFilename: 'Amphibians.xlsx',
      };
    } else if (this.protocolId === Protocols.Bird) {
      this.spreadsheet = {
        configFile: birdConfig,
        downloadFilepath: 'assets/spreadsheets/Birds.xlsx',
        savedFilename: 'Birds.xlsx',
      };
    } else if (this.protocolId === Protocols.Chloris) {
      this.spreadsheet = {
        configFile: chlorisConfig,
        downloadFilepath: 'assets/spreadsheets/Chloris.xlsx',
        savedFilename: 'Chloris.xlsx',
      };
    } else if (this.protocolId === Protocols.Fish) {
      this.spreadsheet = {
        configFile: fishConfig,
        downloadFilepath: 'assets/spreadsheets/Fish.xlsx',
        savedFilename: 'Fish.xlsx',
      };
    } else if (this.protocolId === Protocols.Habitats) {
      this.spreadsheet = {
        configFile: habitatConfig,
        downloadFilepath: 'assets/spreadsheets/Habitats.xlsx',
        savedFilename: 'Habitats.xlsx',
      };
    } else if (this.protocolId === Protocols.Invertebrates) {
      this.spreadsheet = {
        configFile: invertebrateConfig,
        downloadFilepath: 'assets/spreadsheets/Invertebrate.xlsx',
        savedFilename: 'Invertebrate.xlsx',
      };
    } else if (this.protocolId === Protocols.Sea) {
      this.spreadsheet = {
        configFile: seaConfig,
        downloadFilepath: 'assets/spreadsheets/Sea.xlsx',
        savedFilename: 'Sea.xlsx',
      };
    } else if (this.protocolId === Protocols.SeaHabitat) {
      this.spreadsheet = {
        configFile: seaHabitatConfig,
        downloadFilepath: 'assets/spreadsheets/SeaHabitats.xlsx',
        savedFilename: 'SeaHabitats.xlsx',
      };
    } else {
      this.spreadsheet = {
        configFile: [],
        downloadFilepath: '',
        savedFilename: '',
      };
    }
  }

  /**
   * Download the appropriate spreadsheet depending on the protocolId
   *
   */
  public downloadSpreadsheet() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');

    link.setAttribute('href', this.spreadsheet.downloadFilepath);
    link.setAttribute('download', this.spreadsheet.savedFilename);

    link.click();
    link.remove();
  }

  /**
   * If upload was successfull
   *
   */
  private successUpload() {
    this.uploaded = true;
    if (this.notUploaded === true) {
      this.uploadedWithErrors = true;
      this.notUploaded = false;
      this.uploaded = false;
    }
    this.notUploaded = false;
    this.loading = false;

    this.numberOfRequest += 1;
    this.disableUpload = true;
    this.uploader.nativeElement.value = null;
    this.infoMsg =
      this.uploadedWithErrors === true
        ? 'Μερικώς επιτυχής μεταφόρτωση!' + '<br>' + 'Ελέγξτε τα σφάλματα.'
        : 'Επιτυχής μεταφόρτωση!' + '<br>' + 'Επιλέξτε ένα νέο αρχείο.';
  }

  /**
   * If upload was not successfull
   *
   */
  private failedUpload() {
    this.notUploaded = true;
    if (this.uploaded === true) {
      this.uploadedWithErrors = true;
      this.notUploaded = false;
      this.uploaded = false;
    }
    this.uploaded = false;
    this.loading = false;

    this.numberOfRequest += 1;
    this.uploader.nativeElement.value = null;
    this.infoMsg =
      this.uploadedWithErrors === true
        ? 'Μερικώς επιτυχής μεταφόρτωση!' + '<br>' + 'Ελέγξτε τα σφάλματα.'
        : 'Σφάλμα κατά την μεταφόρτωση!' +
          '<br>' +
          'Παρακαλώ δοκιμάστε αργότερα.';

    this.spreadsheetFormArray.clear();
    if (this.spreadsheetForm) {
      this.spreadsheetForm.reset;
    }
  }

  /**
   * Shows a toast with the error
   *
   * @param {string} error The error message
   */
  public showToastError(error: any): void {
    if (
      (typeof error === 'string' || error instanceof String) &&
      (error.indexOf('@') !== -1 || error.indexOf('~') !== -1)
    ) {
      const errorContainer = error.split('@');
      let errorMsg = '';
      let errorList: string[] = [];
      if (errorContainer && errorContainer.length === 2) {
        errorMsg = errorContainer[1];
        errorList = errorContainer[0].split('~');
      } else {
        errorList = error.split('~');
      }

      let message = '';
      let header = '';
      if (errorMsg !== '') {
        message = errorMsg;
      } else {
        errorList.forEach((item) => {
          message += this.translate.instant(item);
        });
      }
      if (errorList.length > 0) {
        header = this.translate.instant('RECORD_GENERIC_ERROR');
      }
      this.toastService.show(message, {
        header: header,
        autohide: false,
      });
    } else {
      this.toastService.show(this.translate.instant('GENERAL_FAILED_GENERIC'), {
        header: this.translate.instant('RECORD_GENERIC_ERROR'),
        autohide: false,
      });
    }
  }

  /**
   * Load the appropriate spreadsheet depending on the protocolId
   *
   */
  public uploadSpreadsheet() {
    this.uploaded = false;
    this.numberOfRequest = 0;
    this.notUploaded = false;
    this.uploadedWithErrors = false;
    this.infoMsg = 'Μεταφόρτωση αρχείου...';

    switch (this.protocolId) {
      case Protocols.Bibliography:
        this.habitatService
          .getTypes()
          .subscribe((ctHabitats: IHabitatType[]) => {
            this.spreadsheetFormArray.controls.forEach((fg) => {
              const request = this.bookService.prepareBookRequest(
                fg as FormGroup,
                true,
                ctHabitats
              );
              (this.request as IBookSpeciesHabitatSpreadDto[]).push(request);
            });
            if ((this.request as IBookSpeciesHabitatSpreadDto[]).length > 0) {
              this.loading = true;
              this.bookService
                .addBatchBookSpeciesAndHabitatsSpread(
                  this.request as IBookSpeciesHabitatSpreadDto[]
                )
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (data: ISpreadsheetFileUploadPayload) => {
                    this.successUpload();
                    this.showUploadReportModal(data.uploadId);
                  },
                  error: (err) => {
                    this.failedUpload();
                    this.showToastError(err.error);
                  },
                });
            }
          });
        break;

      case Protocols.Mammals:
        this.mammalsService
          .getMammalsSpecies()
          .subscribe((ctSpecies: IMammalSpeciesDto[]) => {
            this.spreadsheetFormArray.controls.forEach((fg) => {
              const request = this.mammalsService.prepareMammalRequest(
                fg as FormGroup,
                true,
                ctSpecies
              );
              (this.request as IMammalsDto[]).push(request);
            });
            if ((this.request as IMammalsDto[]).length > 0) {
              this.loading = true;
              this.mammalsService
                .addBatchMammal(this.request as IMammalsDto[])
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (data: ISpreadsheetFileUploadPayload) => {
                    this.successUpload();
                    this.showUploadReportModal(data.uploadId);
                  },
                  error: (err) => {
                    this.failedUpload();
                    this.showToastError(err.error);
                  },
                });
            }
          });
        break;

      case Protocols.Amphibian:
        this.amphibiansService
          .getAmphibianSpecies()
          .subscribe((species: IAmphibianSpeciesDto[]) => {
            this.spreadsheetFormArray.controls.forEach((fg) => {
              const request = this.amphibiansService.prepareAmphibianRequest(
                fg as FormGroup,
                true,
                species
              );
              (this.request as IAmphibianDto[]).push(request);
            });
            if ((this.request as IAmphibianDto[]).length > 0) {
              this.loading = true;
              this.amphibiansService
                .addBatchAmphibian(this.request as IAmphibianDto[])
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (data: ISpreadsheetFileUploadPayload) => {
                    this.successUpload();
                    this.showUploadReportModal(data.uploadId);
                  },
                  error: (err) => {
                    this.failedUpload();
                    this.showToastError(err.error);
                  },
                });
            }
          });
        break;

      case Protocols.Bird:
        this.birdService
          .getBirdsSpecies()
          .subscribe((ctSpecies: IBirdSpeciesDto[]) => {
            this.spreadsheetFormArray.controls.forEach((fg) => {
              const request = this.birdService.prepareBirdRequest(
                fg as FormGroup,
                true,
                ctSpecies
              );
              (this.request as IBirdDto[]).push(request);
            });
            if ((this.request as IBirdDto[]).length > 0) {
              this.loading = true;
              this.birdService
                .addBatchBird(this.request as IBirdDto[])
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (data: ISpreadsheetFileUploadPayload) => {
                    this.successUpload();
                    this.showUploadReportModal(data.uploadId);
                  },
                  error: (err) => {
                    this.failedUpload();
                    this.showToastError(err.error);
                  },
                });
            }
          });

        break;

      case Protocols.Chloris:
        this.chlorisService
          .getSpecies()
          .subscribe((ctSpecies: IChlorisSpecies[]) => {
            this.spreadsheetFormArray.controls.forEach((fg) => {
              const request = this.chlorisService.prepareChlorisRequest(
                fg as FormGroup,
                true,
                ctSpecies
              );
              (this.request as IChlorisDto[]).push(request);
            });
            if ((this.request as IChlorisDto[]).length > 0) {
              this.loading = true;
              this.chlorisService
                .addBatchChloris(this.request as IChlorisDto[])
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (data: ISpreadsheetFileUploadPayload) => {
                    this.successUpload();
                    this.showUploadReportModal(data.uploadId);
                  },
                  error: (err) => {
                    this.failedUpload();
                    this.showToastError(err.error);
                  },
                });
            }
          });
        break;

      case Protocols.Fish:
        this.fishService.getSpecies().subscribe((ctSpecies: IMainCTSDto[]) => {
          this.spreadsheetFormArray.controls.forEach((fg) => {
            const request = this.fishService.prepareFishRequest(
              fg as FormGroup,
              true,
              ctSpecies
            );
            (this.request as IFishDto[]).push(request);
          });
          if ((this.request as IFishDto[]).length > 0) {
            this.loading = true;
            this.fishService
              .addBatchFish(this.request as IFishDto[])
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (data: ISpreadsheetFileUploadPayload) => {
                  this.successUpload();
                  this.showUploadReportModal(data.uploadId);
                },
                error: (err) => {
                  this.failedUpload();
                  this.showToastError(err.error);
                },
              });
          }
        });
        break;

      case Protocols.Habitats:
        this.habitatService
          .getTypes()
          .subscribe((ctHabitats: IHabitatType[]) => {
            this.spreadsheetFormArray.controls.forEach((fg) => {
              const request = this.habitatService.prepareHabitatRequest(
                fg as FormGroup,
                true,
                ctHabitats
              );
              (this.request as IHabitatDto[]).push(request);
            });
            if ((this.request as IHabitatDto[]).length > 0) {
              this.loading = true;
              this.habitatService
                .addBatchHabitat(this.request as IHabitatDto[])
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (data: ISpreadsheetFileUploadPayload) => {
                    this.successUpload();
                    this.showUploadReportModal(data.uploadId);
                  },
                  error: (err) => {
                    this.failedUpload();
                    this.showToastError(err.error);
                  },
                });
            }
          });
        break;

      case Protocols.Invertebrates:
        this.invertebrateService
          .getInvertebratesSpecies()
          .subscribe((ctSpecies: IInvertebrateSpeciesDto[]) => {
            this.spreadsheetFormArray.controls.forEach((fg) => {
              const request =
                this.invertebrateService.prepareInvertebrateRequest(
                  fg as FormGroup,
                  true,
                  ctSpecies
                );
              (this.request as IInvertebrateDto[]).push(request);
            });
            if ((this.request as IInvertebrateDto[]).length > 0) {
              this.loading = true;
              this.invertebrateService
                .addBatchInvertebrate(this.request as IInvertebrateDto[])
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (data: ISpreadsheetFileUploadPayload) => {
                    this.successUpload();
                    this.showUploadReportModal(data.uploadId);
                  },
                  error: (err) => {
                    this.failedUpload();
                    this.showToastError(err.error);
                  },
                });
            }
          });
        break;

      case Protocols.Sea:
        forkJoin([
          this.seaService.getHabitats(),
          this.seaService.getSpecies(),
        ]).subscribe(([ctHabitats, ctSpecies]) => {
          this.spreadsheetFormArray.controls.forEach((fg) => {
            const request = this.seaService.prepareSeaRequest(
              fg as FormGroup,
              true,
              ctHabitats,
              ctSpecies
            );
            (this.request as ISeaDto[]).push(request);
          });
          if ((this.request as ISeaDto[]).length > 0) {
            this.loading = true;
            this.seaService
              .addBatchSea(this.request as ISeaDto[])
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (data: ISpreadsheetFileUploadPayload) => {
                  this.successUpload();
                  this.showUploadReportModal(data.uploadId);
                },
                error: (err) => {
                  this.failedUpload();
                  this.showToastError(err.error);
                },
              });
          }
        });
        break;

      case Protocols.SeaHabitat:
        forkJoin([
          this.seaService.getHabitats(),
          this.seaService.getSpecies(),
        ]).subscribe(([ctHabitats, ctSpecies]) => {
          this.spreadsheetFormArray.controls.forEach((fg) => {
            const request = this.seaService.prepareSeaHabitatRequest(
              fg as FormGroup,
              true,
              ctHabitats,
              ctSpecies
            );
            (this.request as ISeaHabitatsDto[]).push(request);
          });
          if ((this.request as ISeaHabitatsDto[]).length > 0) {
            this.loading = true;
            this.seaService
              .addBatchSeaHabitat(this.request as ISeaHabitatsDto[])
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (data: ISpreadsheetFileUploadPayload) => {
                  this.successUpload();
                  this.showUploadReportModal(data.uploadId);
                },
                error: (err) => {
                  this.failedUpload();
                  this.showToastError(err.error);
                },
              });
          }
        });
        break;

      default:
        break;
    }
  }

  /**
   * Open file dialog to choose the xlsx
   *
   * @param {any} event /
   */
  public chooseSpreadsheet(event: any) {
    if (event.target.files != null) {
      const files: any[] = [];
      for (const prop of Object.keys(event.target.files)) {
        if (event.target.files[prop].name.split('.').slice(-1).length > 0) {
          const extension = event.target.files[prop].name
            .split('.')
            .slice(-1)[0];
          if (['xlsx', 'xls'].indexOf(extension) !== -1) {
            files.push(event.target.files[prop]);
          }
        }
      }
      if (files.length === 0) {
        return;
      }
    }

    this.disableUpload = true;
    this.uploaded = false;
    this.notUploaded = false;
    this.uploadedWithErrors = false;
    this.infoMsg = 'Έλεγχος αρχείου...';

    /* wire up file reader */
    const target: DataTransfer = !!event.dataTransfer
      ? <DataTransfer>event.dataTransfer
      : <DataTransfer>event.target;
    this.loading = true;
    if (target.files.length !== 1) {
      this.disableUpload = true;
      throw new Error('Cannot use multiple files');
    }
    const extention = target.files[0].name.split('.').pop();
    if (extention !== 'xlsx' && extention !== 'xls') {
      this.disableUpload = true;
      throw new Error('Only xls and xlsx files are allowed');
    }
    this.fileName = target.files[0].name;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      // Initialize arrays
      this.errors = [];
      this.formErrors = [];
      this.request = [];
      this.spreadsheetFormArray.clear();
      if (this.spreadsheetForm) {
        this.spreadsheetForm.reset;
      }

      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: WorkBook = read(binarystr, {
        type: 'binary',
        cellNF: true,
      });

      const config = this.spreadsheet.configFile;
      const samplesMapping: { [key: string]: number[] } = {};

      // For each config-object inside configuration file
      for (
        let configIndex = 0;
        configIndex < this.spreadsheet.configFile.length;
        configIndex++
      ) {
        // Load specific sheet to a json
        const wsName: string = wb.SheetNames[configIndex];
        if (wsName !== 'Λίστες - Μενού') {
          const ws: WorkSheet = wb.Sheets[wsName];
          this.fileJson = utils.sheet_to_json(ws, {
            raw: true,
            defval: '',
          }) as IJsonResponse;
          // Check missing titles
          this.validateTitles(configIndex, wsName);

          // Validate duplicate samplingCode
          if (config[configIndex].formGroupName === '') {
            this.validateSamplingCodeDoublicates(
              wsName,
              'Κωδικός Δειγματοληψίας (Έντυπο)'
            );
          }

          if (this.protocolId === Protocols.Habitats) {
            this.validateSamplingCodeDoublicates(
              wsName,
              'Υφιστάμενος αριθμός δειγματοληψίας'
            );
          }

          // For each line in xls file (that is for each object in json)
          for (
            let xlsLine = 1;
            xlsLine < Object.keys(this.fileJson as IJsonResponse).length;
            xlsLine++
          ) {
            // Break if A/A is empty
            if (
              (this.fileJson as IJsonResponse)[xlsLine][
                config[configIndex].headerNumber?.toString() as keyof IJsonData
              ] === undefined ||
              (this.fileJson as IJsonResponse)[xlsLine][
                config[configIndex].headerNumber?.toString() as keyof IJsonData
              ] === ''
            ) {
              break;
            }

            // Init form group
            this.spreadsheetForm = this.spreadsheetFormsService.InitFormGroup(
              config[configIndex].formGroupName,
              this.protocolId
            );

            let isSeaInvertebrates = false;
            if (
              this.protocolId === Protocols.Sea &&
              config[configIndex].formGroupName === SeaFormGroup.Species
            ) {
              if (
                (this.fileJson as IJsonResponse)[xlsLine][
                  config[
                    configIndex
                  ].speciesCategory?.toString() as keyof IJsonData
                ] === SeaSpeciesCategory.SeaInvertebrates
              ) {
                isSeaInvertebrates = true;
              }
            }

            if (isSeaInvertebrates) {
              this.spreadsheetForm.controls['lat'].removeValidators(
                Validators.required
              );
              this.spreadsheetForm.controls['long'].removeValidators(
                Validators.required
              );
            }

            if (
              this.protocolId === Protocols.Bibliography &&
              config[configIndex].formGroupName ===
                BibliographyFormGroup.Entries
            ) {
              if (
                (this.fileJson as IJsonResponse)[xlsLine]['Ομάδα ειδών'] ===
                'Τύποι Οικοτόπων'
              ) {
                this.spreadsheetForm.controls['speciesId'].removeValidators(
                  Validators.required
                );
              } else {
                this.spreadsheetForm.controls['habitatId'].removeValidators(
                  Validators.required
                );
              }
            }

            // Validation for location
            if (config[configIndex].validateLocation && !isSeaInvertebrates) {
              let validateLocation = config[configIndex].validateLocation;

              // If it's Bibliography, handle the EGSA validation
              if (
                this.protocolId === Protocols.Bibliography &&
                config[configIndex].formGroupName ===
                  BibliographyFormGroup.Entries
              ) {
                if (
                  validateLocation &&
                  (this.fileJson as IJsonResponse)[xlsLine][
                    validateLocation[0][0] as keyof IJsonData
                  ] === '' &&
                  (this.fileJson as IJsonResponse)[xlsLine][
                    validateLocation[0][1] as keyof IJsonData
                  ] === '' &&
                  (this.fileJson as IJsonResponse)[xlsLine][
                    validateLocation[1][0] as keyof IJsonData
                  ] !== '' &&
                  (this.fileJson as IJsonResponse)[xlsLine][
                    validateLocation[1][0] as keyof IJsonData
                  ] !== ''
                ) {
                  validateLocation = validateLocation.filter((item) => {
                    return !item[0].toString().includes('EGSA');
                  });
                }
              }

              validateLocation?.forEach((validation) => {
                this.validateLocation(
                  wsName,
                  xlsLine,
                  validation[0],
                  validation[1],
                  validation[2]
                );
              });
            }

            // For each configuration in the specified config-object
            config[configIndex].configuration.forEach((cfg) => {
              if (!cfg.disabled) {
                let value: string | number | boolean | undefined = (
                  this.fileJson as IJsonResponse
                )[xlsLine][cfg.header as keyof IJsonData];

                if (value === null) {
                  value = '';
                }

                if (value === '' && cfg.header === 'Τυχαία Παρατήρηση') {
                  value = 'Όχι';
                }
                // Check validations and convert values to apropriate format
                if (value && cfg.type === 'prefecture') {
                  value = Prefectures[value ?? 'Άγνωστος'];
                }
                if (value && cfg.type === 'int') {
                  this.validateIntegers(wsName, xlsLine, cfg.header);
                  value = this.convertNumber(value);
                }
                if (value && cfg.type === 'float') {
                  this.validateFloats(wsName, xlsLine, cfg.header);
                  value = this.convertNumber(value);
                }
                if (value && cfg.type === 'boolean') {
                  value = this.convertBoolean(value.toString());
                }
                if (value && cfg.type === 'date') {
                  const dateNumber = parseFloat(value.toString() ?? '0');
                  const date = this.parseDateExcel(dateNumber);
                  const dateString =
                    date.getDate().toString().padStart(2, '0') +
                    (date.getMonth() + 1).toString().padStart(2, '0') +
                    date.getFullYear().toString();
                  value = dateNumber !== 0 ? dateString : undefined;
                }
                if (value && cfg.type === 'time') {
                  const timeNumber =
                    40000 + parseFloat(value.toString() ?? '0');
                  value =
                    timeNumber !== 0
                      ? this.parseDateExcel(timeNumber)
                          .toTimeString()
                          .split(' ')[0]
                          .slice(0, 5)
                          .replaceAll(':', '')
                      : undefined;
                }
                if (value && cfg.type === 'duration') {
                  // value = this.commonService.formatTimeToMin(value.toString());
                }

                // Patch the value to the FormGroup
                this.spreadsheetForm.controls[cfg.formName].patchValue(value);

                // Remove validators if needed (random observation)
                const randomObs = (this.fileJson as IJsonResponse)[xlsLine][
                  'Τυχαία Παρατήρηση' as keyof IJsonData
                ];
                if (randomObs && randomObs === 'Ναι') {
                  config[configIndex].removeValidations?.forEach((formName) => {
                    this.spreadsheetForm.controls[
                      formName.formName
                    ].removeValidators(formName.validators);
                    if (formName.removeLatLongValidator) {
                      this.spreadsheetForm.controls[
                        formName.formName
                      ].removeValidators(
                        this.commonService.latLongValidator('lat', 'long')
                      );
                    }
                  });
                }

                // Add validators if needed (random observation)
                if (!!config[configIndex].isRandomSheet) {
                  config[configIndex].addValidations?.forEach((formName) => {
                    this.spreadsheetForm.controls[
                      formName.formName
                    ].addValidators(formName.validators);
                  });
                }

                // Check form validations
                if (this.spreadsheetForm.controls[cfg.formName].errors) {
                  this.formErrors.push({
                    sheet: wsName,
                    row: xlsLine + 2,
                    column: cfg.header,
                    description:
                      this.spreadsheetForm.controls[cfg.formName].errors,
                  });
                }
              }
            });
            if (config[configIndex].formGroupName === '') {
              // If values are from Sample-sheet add them to FormArray
              this.spreadsheetFormArray.push(this.spreadsheetForm);
            } else {
              // If values are from secondary sheets, find the specified array-field and add them
              const formGroupArray =
                config[configIndex].formGroupName.split('.');
              const assignedToIndexHeader =
                config[configIndex].headerAssignedNumber;

              if (assignedToIndexHeader) {
                let assignedToIndex: number | null = null;
                if (config[configIndex].splitHeaderToMap) {
                  const mapHeader = (this.fileJson as IJsonResponse)[xlsLine][
                    assignedToIndexHeader as keyof IJsonData
                  ]
                    ?.toString()
                    .split('_');
                  if (mapHeader && mapHeader?.length > 2) {
                    if (config[configIndex].isRandomSheet) {
                      assignedToIndex = parseInt(mapHeader[3]);
                    } else {
                      assignedToIndex = parseInt(mapHeader[2]);
                    }
                  }
                } else {
                  assignedToIndex = parseInt(
                    (this.fileJson as IJsonResponse)[xlsLine][
                      assignedToIndexHeader as keyof IJsonData
                    ]?.toString() ?? '-1'
                  );
                }

                if (assignedToIndex != null && assignedToIndex > -1) {
                  // The `samplesMapping` is used to map the samples
                  // with the species. This is needed in cases we have
                  // threats assigned to species.
                  // if (
                  //   formGroupArray.length === 1 &&
                  //   config[configIndex].formGroupName ===
                  //     AmphibianFormGroup.Species
                  // ) {
                  //   if (samplesMapping[assignedToIndex] === undefined) {
                  //     samplesMapping[assignedToIndex] = [];
                  //   }
                  //   const headerAA = config[configIndex].headerNumber;
                  //   const numberAA = parseInt(
                  //     (this.fileJson as IJsonResponse)[xlsLine][
                  //       headerAA as keyof IJsonData
                  //     ]?.toString() ?? '-1'
                  //   );
                  //   samplesMapping[assignedToIndex].push(numberAA);
                  // }

                  if (formGroupArray.length === 2) {
                    let assignedToIndexOutter = -1;
                    // Object.entries(samplesMapping).find(([key, value]) => {
                    //   if (
                    //     assignedToIndex &&
                    //     value.indexOf(assignedToIndex) > -1
                    //   ) {
                    //     assignedToIndexOutter = parseInt(key);
                    //     return true;
                    //   }
                    //   return false;
                    // });

                    // const realAssignedToIndex =
                    //   samplesMapping[assignedToIndexOutter.toString()].indexOf(
                    //     assignedToIndex
                    //   );

                    assignedToIndexOutter = assignedToIndex;
                    const realAssignedToIndex = (
                      (
                        this.spreadsheetFormArray.controls[
                          assignedToIndexOutter
                        ] as FormGroup
                      ).controls[formGroupArray[0]] as FormArray
                    ).controls.findIndex((control) => {
                      return (
                        (control as FormGroup).controls['species'].value ===
                          this.spreadsheetForm.controls['species'].value &&
                        (control as FormGroup).controls['otherSpecies']
                          .value ===
                          this.spreadsheetForm.controls['otherSpecies'].value
                      );
                    });

                    if (
                      realAssignedToIndex != null &&
                      realAssignedToIndex > -1
                    ) {
                      // Add values to FormArray
                      (
                        (
                          (
                            (
                              this.spreadsheetFormArray.controls[
                                assignedToIndexOutter
                              ] as FormGroup
                            ).controls[formGroupArray[0]] as FormArray
                          ).controls[realAssignedToIndex] as FormGroup
                        ).controls[formGroupArray[1]] as FormArray
                      ).push(this.spreadsheetForm);
                    }
                  } else {
                    if (
                      this.protocolId === Protocols.Sea &&
                      config[configIndex].formGroupName ===
                        SeaFormGroup.Species &&
                      config[configIndex].speciesCategory &&
                      config[configIndex].speciesCategory !==
                        SeaSpeciesCategory.Cetaceans
                    ) {
                      const lat = (
                        this.spreadsheetFormArray.controls[
                          assignedToIndex
                        ] as FormGroup
                      ).controls['lat'].value;
                      const long = (
                        this.spreadsheetFormArray.controls[
                          assignedToIndex
                        ] as FormGroup
                      ).controls['long'].value;
                    }

                    // Add values to FormArray
                    if (config[configIndex].formType === 'formArray') {
                      (
                        (
                          this.spreadsheetFormArray.controls[
                            assignedToIndex
                          ] as FormGroup
                        ).controls[formGroupArray[0]] as FormArray
                      ).push(this.spreadsheetForm);

                      // Checking if the FormArray has become
                      // invalid after the FromGroup add.
                      if (
                        (
                          this.spreadsheetFormArray.controls[
                            assignedToIndex
                          ] as FormGroup
                        ).controls[formGroupArray[0]].invalid &&
                        (
                          this.spreadsheetFormArray.controls[
                            assignedToIndex
                          ] as FormGroup
                        ).controls[formGroupArray[0]].errors != null
                      ) {
                        this.formErrors.push({
                          sheet: wsName,
                          row: xlsLine + 2,
                          column: '-',
                          description: (
                            this.spreadsheetFormArray.controls[
                              assignedToIndex
                            ] as FormGroup
                          ).controls[formGroupArray[0]].errors,
                        });
                      }
                    } else {
                      (
                        (
                          this.spreadsheetFormArray.controls[
                            assignedToIndex
                          ] as FormGroup
                        ).controls[formGroupArray[0]] as FormGroup
                      ).patchValue(this.spreadsheetForm.value);
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (this.spreadsheetFormArray.controls.length === 0) {
        this.errors.push({
          sheet: 'Όλα',
          row: 1,
          column: 'Όλες',
          description: 'Το αρχείο δεν περιέχει καμία εγγραφή',
        });
      }

      if (this.errors.length > 0 || this.formErrors.length > 0) {
        this.spreadsheetFormArray.clear();
        if (this.spreadsheetForm) {
          this.spreadsheetForm.reset;
        }
        this.infoMsg = 'Μη έγκυρο αρχείο! Παρακαλώ επιλέξτε ένα έγκυρο.';
        this.disableUpload = true;
        this.showErrorsModal();
      } else {
        this.infoMsg = 'Έγκυρο αρχείο! Έτοιμο για μεταφόρτωση.';
        this.disableUpload = false;
      }

      this.loading = false;
      this.uploader.nativeElement.value = null;
    };
  }

  /**
   * Checkes if all titles exist
   *
   * @param {string[]} titles The names of the titles
   */
  public validateTitles(configIndex: number, sheetName: string): void {
    this.spreadsheet.configFile[configIndex].configuration.forEach((cfg) => {
      if (!cfg.disabled) {
        let value: string | number | boolean | undefined = (
          this.fileJson as IJsonResponse
        )[0][cfg.header as keyof IJsonData];

        if (value === undefined) {
          this.errors.push({
            sheet: sheetName,
            row: 1,
            column: 'Τίτλοι στηλών',
            description: 'Λείπει ο τίτλος: ' + cfg.header,
          });
        }
      }
    });
  }

  /**
   * Checkes if there are doublicates in samplingCode column
   *
   * @param {string} sheetName The name of the sheet
   * @param {string} title The name of the column
   */
  public validateSamplingCodeDoublicates(
    sheetName: string,
    title: string
  ): void {
    let dictionary: {
      [k: string]: string;
    } = {};
    for (
      let i = 0;
      i < Object.keys(this.fileJson as IJsonResponse).length;
      i++
    ) {
      const value = (this.fileJson as IJsonResponse)[i][
        title as keyof IJsonData
      ];
      if (value !== undefined && value !== null && value !== '') {
        if (dictionary[value]) {
          this.errors.push({
            sheet: sheetName,
            row: i + 2,
            column: title,
            description:
              this.translate.instant('SAME_CODE_WITH_LINE') + dictionary[value],
          });
        } else {
          dictionary[value] = (i + 2).toString();
        }
      }
    }
  }

  /**
   * Checks if a string is numeric. May be used in conjunction with validateIntegers
   *
   * @param {string} sheetName The name of the sheet
   * @param {number} rowIndex Index of the data row
   * @param {string} column The name of the column
   */
  public validateNumericInteger(
    sheetName: string,
    rowIndex: number,
    column: string
  ) {
    const int = (this.fileJson as IJsonResponse)[rowIndex][
      column as keyof IJsonData
    ];
    if (int !== undefined) {
      if (!/^\d*$/.test(int + '')) {
        this.errors.push({
          sheet: sheetName,
          row: rowIndex + 2,
          column: column,
          description: 'H τιμή δεν αποτελεί ακέραιο αριθμό',
        });
      }
    }
  }

  /**
   * Validates the integers
   *
   * @param {string} sheetName The name of the sheet
   * @param {number} rowIndex The index of the row
   * @param {string} column The name of the column
   */
  public validateIntegers(sheetName: string, rowIndex: number, column: string) {
    const int = (this.fileJson as IJsonResponse)[rowIndex][
      column as keyof IJsonData
    ];
    if (int !== undefined) {
      const value = parseFloat(int.toString());
      if (!(!isNaN(value) && (value | 0) === value)) {
        this.errors.push({
          sheet: sheetName,
          row: rowIndex + 2,
          column: column,
          description: 'Η τιμή δεν αποτελεί ακέραιο αριθμό',
        });
      }
    }
  }

  /**
   * Validates the floats
   *
   * @param {string} sheetName The name of the sheet
   * @param {number} rowIndex The index of the row
   * @param {string} column The name of the column
   */
  public validateFloats(sheetName: string, rowIndex: number, column: string) {
    const float = (this.fileJson as IJsonResponse)[rowIndex][
      column as keyof IJsonData
    ];
    if (float !== undefined && float !== null) {
      const value = parseFloat(float.toString());
      if (isNaN(value)) {
        this.errors.push({
          sheet: sheetName,
          row: rowIndex + 2,
          column: column,
          description: 'Η τιμή δεν αποτελεί αριθμό',
        });
      }
    }
  }

  /**
   * Validates the dates
   *
   * @param {string} sheetName The name of the sheet
   * @param {number} rowIndex The index of the row
   * @param {string} column The name of the column
   */
  public validateDate(sheetName: string, rowIndex: number, column: string) {
    const date: string = (this.fileJson as IJsonResponse)[rowIndex][
      column as keyof IJsonData
    ] as string;
    if (date !== undefined && date) {
      if (date.length == 10) {
        const parts = date.split('/');
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        if (isValid(parseISO(year + '-' + month + '-' + day))) {
          return;
        }
      }
      this.errors.push({
        sheet: sheetName,
        row: rowIndex + 2,
        column: column,
        description: 'Η τιμή δεν είναι σωστή ημερομηνία (μμ/ΜΜ/ΧΧΧΧ)',
      });
    }
  }

  /**
   * Validates that sample is inside Greece.
   *
   * @param {string} sheetName The name of the sheet
   * @param {number} rowIndex The index of the row
   * @param {string} column The name of the column
   */
  public validateLocation(
    sheetName: string,
    rowIndex: number,
    columnLat: string,
    columnLong: string,
    convertToLatLong: boolean = false
  ) {
    let xLat = (this.fileJson as IJsonResponse)[rowIndex][
      columnLat as keyof IJsonData
    ];
    let yLong = (this.fileJson as IJsonResponse)[rowIndex][
      columnLong as keyof IJsonData
    ];

    if (xLat != null && yLong != null) {
      if (convertToLatLong) {
        const coordinates = this.commonService.convertEGSAtoWGS(
          parseFloat(xLat?.toString()),
          parseFloat(yLong.toString())
        );
        xLat = coordinates.lat;
        yLong = coordinates.long;
      }

      if (
        !this.commonService.isInGreece([
          parseFloat(xLat.toString()),
          parseFloat(yLong.toString()),
        ])
      ) {
        this.errors.push({
          sheet: sheetName,
          row: rowIndex + 2,
          column: `${columnLat}/${columnLong}`,
          description: 'Οι συντεταγμένες είναι εκτός Ελλάδας',
        });
      }
    }
  }

  /**
   * Converts string or number to number
   *
   * @param {number | string | undefined} value The string or number to be converted
   * @returns {number | undefined} The converted number or undefined
   */
  public convertNumber(value: number | string | undefined): number | undefined {
    let result: number;
    if (value) {
      if (value !== '') {
        result = parseFloat(value.toString());
        if (
          result.toString().endsWith('.0') ||
          result.toString().endsWith(',0') ||
          result.toString().endsWith('.00') ||
          result.toString().endsWith(',00')
        ) {
          result = parseInt(value.toString());
        }
        return result;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  /**
   * Converts string "Ναι" to true "Όχι" to false
   *
   * @param {string | undefined} value The string "Ναι" or "Όχι"
   * @returns {boolean | undefined} The converted boolean or undefined
   */
  public convertBoolean(value: string | undefined): boolean | undefined {
    let result = false;
    if (value) {
      if (value !== '') {
        if (value.toString().includes('Ναι')) {
          result = true;
        }
        return result;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  /**
   * Converts the number that sends the xlsx as a date, to an actual date
   *
   * @param {number} excelTimestamp The number that represents the date
   */
  public parseDateExcel(excelTimestamp: number): Date {
    const timeZoneOffset = new Date().getTimezoneOffset() / 60;
    var hours = Math.floor((excelTimestamp % 1) * 24);
    var minutes = Math.ceil(((excelTimestamp % 1) * 24 - hours) * 60);
    return new Date(
      Date.UTC(0, 0, excelTimestamp - 1, hours + timeZoneOffset, minutes)
    );
  }

  /**
   * Shows the modal with the errors.
   *
   */
  public showErrorsModal(): void {
    const modalRef = this.modalService.open(SpreadsheetReportModalComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.type = SpreadsheetReportModalType.Error;
    modalRef.componentInstance.errors = this.errors;
    if (this.formErrors) {
      modalRef.componentInstance.formErrors = this.formErrors;
    }
  }

  /**
   * Shows the modal with the errors.
   *
   */
  public showUploadReportModal(uploadId: number | null = null): void {
    this.spreadsheetFormArray.clear();
    const modalRef = this.modalService.open(SpreadsheetReportModalComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.type = SpreadsheetReportModalType.Success;
    modalRef.componentInstance.request = this.request;
    modalRef.componentInstance.protocolId = this.protocolId;
    modalRef.componentInstance.uploadId = uploadId;

    this.uploadCompleted.emit();
  }

  /**
   *
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
