import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize, forkJoin } from 'rxjs';
import {
  ISeaPressureAndThreatDto,
  ISeaSpeciesDto,
  ISeaThreatsRequestDto,
} from 'src/app/core/dtos/sea.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { SeaService } from '../../services/sea.service';

@Component({
  selector: 'app-sea-threats',
  templateUrl: './sea-threats.component.html',
  styleUrls: ['./sea-threats.component.scss'],
})
export class SeaThreatsComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctThreats: ISeaPressureAndThreatDto[] = [];
  public threatsForm!: FormGroup;
  public threatsDropdown: IDropdownItem[] = [];
  public ctSpecies: ISeaSpeciesDto[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public typeDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('TYPE_P'),
      name: this.translate.instant('TYPE_P'),
    },
    {
      id: this.translate.instant('TYPE_T'),
      name: this.translate.instant('TYPE_T'),
    },
  ];
  public ctImpactDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'L',
    },
    {
      id: 'M',
      name: 'M',
    },
    {
      id: 'H',
      name: 'H',
    },
  ];
  public impactDropdown: IDropdownItem[] = [];

  public ctTimeCharacterDropdown: IDropdownItem[] = [
    {
      id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
    },
    {
      id: 'Στο παρόν',
      name: 'Στο παρόν',
    },
    {
      id: 'Στο παρόν και στο μέλλον',
      name: 'Στο παρόν και στο μέλλον',
    },
    {
      id: 'Μόνο στο μέλλον',
      name: 'Μόνο στο μέλλον',
    },
  ];
  public timeCharacterDropdown: IDropdownItem[] = [];

  @Input() public threats!: FormArray;
  @Input() public selectedSpecies: any[] = [];
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: ISeaThreatsRequestDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private seaService: SeaService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.threatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }

    if (changes['selectedSpecies']) {
      this.speciesDropdown = [];
      this.selectedSpecies.forEach((species) => {
        if (species) {
          const actualSpecies = this.ctSpecies.find(
            (item) => item.id === parseInt(species.species)
          );
          if (actualSpecies != null) {
            if (actualSpecies.code === 'Άλλο') {
              this.speciesDropdown.push({
                id: species.other,
                name: species.other,
                actualId: actualSpecies.id,
              });
            } else {
              this.speciesDropdown.push({
                id: actualSpecies.code,
                name: actualSpecies.code,
                actualId: actualSpecies.id,
              });
            }
          }
        }
      });
      this.threats.controls.forEach((control, index) => {
        const ctrl = control as FormGroup;
        const species = this.selectedSpecies.find(
          (item) =>
            item.species === ctrl.controls['species'].value &&
            (item.other === ctrl.controls['otherSpecies'].value ||
              (item.other == null &&
                ctrl.controls['otherSpecies'].value === ''))
        );
        if (species == null) {
          this.threats.removeAt(index);
        }
      });
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.seaService.getThreats(this.dataVersion),
      this.seaService.getSpecies(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          this.impactDropdown = this.ctImpactDropdown;
          this.timeCharacterDropdown = this.ctTimeCharacterDropdown;

          if (this.ctThreats.length > 0) {
            this.ctThreats.forEach((threat) => {
              this.threatsDropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
          }

          this.selectedSpecies.forEach((species) => {
            if (species) {
              const actualSpecies = this.ctSpecies.find(
                (item) => item.id === parseInt(species.species)
              );
              if (actualSpecies != null) {
                if (actualSpecies.code === 'Άλλο') {
                  this.speciesDropdown.push({
                    id: species.other,
                    name: species.other,
                    actualId: actualSpecies.id,
                  });
                } else {
                  this.speciesDropdown.push({
                    id: actualSpecies.code,
                    name: actualSpecies.code,
                    actualId: actualSpecies.id,
                  });
                }
              }
            }
          });

          if (this.threatsById.length > 0) {
            this.threatsById.forEach((threat) => {
              this.addNewThreats();
              if (threat.species === 'Άλλο' || threat.species == null) {
                threat.species = threat.otherSpecies;
              }
            });
            this.threats.patchValue(this.threatsById);
            this.threats.markAsTouched();
            this.threatsById = [];
            if (this.disabledControls === true) {
              this.threats.disable();
            }
          }

          return;
        })
      )
      .subscribe(([threats, species]) => {
        if (threats != undefined) {
          this.ctThreats = threats as ISeaPressureAndThreatDto[];
        }

        if (species != undefined) {
          this.ctSpecies = species as ISeaSpeciesDto[];
          this.ctSpecies = this.ctSpecies.sort((a, b) => {
            return a.code > b.code ? 1 : -1;
          });
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    let fg = this.seaService.initThreatsFormGroup(this.fb);

    fg.controls['pressuresAndThreatsId'].valueChanges.subscribe((value) => {
      if (value) {
        if ((value as string).startsWith('PX')) {
          fg.controls['impact'].setValue('', { emiiValue: false });
          fg.controls['impact'].disable();
          fg.controls['timeCharacter'].setValue('', { emiiValue: false });
          fg.controls['timeCharacter'].disable();

          this.impactDropdown = [];
          this.timeCharacterDropdown = [];
        } else {
          fg.controls['impact'].enable();
          fg.controls['timeCharacter'].enable();

          this.impactDropdown = this.ctImpactDropdown;
          this.timeCharacterDropdown = this.ctTimeCharacterDropdown;
        }
      }
    });

    this.threats.push(fg);
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  public updateOtherSpecies(otherSpecies: string, index: number): void {
    const species = this.ctSpecies.find(
      (item) =>
        item.id ===
        (this.threats.controls[index] as FormGroup).controls['species'].value
    );
    if (species != null && species.code === 'Άλλο') {
      (this.threats.controls[index] as FormGroup).controls[
        'otherSpecies'
      ].setValue(otherSpecies, { emitEvent: false });
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
