<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>
<div class="title-container">
  <span class="title-text uppercase"
    >{{ "NEW_REGISTRATION" | translate }} - {{ "FISH" | translate }}</span
  >
</div>
<div class="fish-container">
  <div class="info-container">
    <span class="info-text uppercase">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container">
    <div class="form-row form-3">
      <app-input
        [formGroup]="fishForm"
        [formName]="'samplingCode'"
        [label]="'SAMPLE_CODE'"
        [required]="true"
      ></app-input>
    </div>
    <div class="form-row form-3">
      <app-dropdown
        class="dropdown-column"
        [formGroup]="fishForm"
        [formName]="'ergoEpoptiaFasiErgou'"
        [label]="'ERGO_EPOPTIA_FASI_ERGOU'"
        [dropdownList]="ergoEpoptiaFasiErgouDropdown"
        [required]="true"
        [preDefinedId]="fishById?.ergoEpoptiaFasiErgou"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-dropdown
        [formGroup]="fishForm"
        [formName]="'financialInstrument'"
        [label]="'FINANCIAL_INSTRUMENT'"
        [dropdownList]="financialDropdown"
        [required]="true"
        [preDefinedId]="fishById?.financialInstrument"
      ></app-dropdown>

      <app-input
        [formGroup]="fishForm"
        [formName]="'hydrographicBasin'"
        [label]="'HYDROGRAPHIC_BASIN'"
      ></app-input>
      <app-input
        [formGroup]="fishForm"
        [formName]="'course'"
        [label]="'FISH_COURSE'"
      ></app-input>
    </div>
    <div class="form-row">
      <app-input
        [mask]="'00/00/0000'"
        [formGroup]="fishForm"
        [formName]="'date'"
        [label]="'DATE'"
        [required]="true"
      ></app-input>
      <app-input
        [mask]="'00:00'"
        [formGroup]="fishForm"
        [formName]="'time'"
        [label]="'TIME'"
        [required]="true"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="fishForm"
        [formName]="'duration'"
        [label]="'DURATION'"
      ></app-input>
    </div>
    <div class="form-row">
      <app-input
        [formGroup]="fishForm"
        [formName]="'researcher'"
        [label]="'RESEARCHER'"
      ></app-input>
      <app-input
        [formGroup]="fishForm"
        [formName]="'fisher'"
        [label]="'FISHER'"
      ></app-input>
      <app-input
        [formGroup]="fishForm"
        [formName]="'author'"
        [label]="'AUTHOR'"
      ></app-input>
    </div>
  </div>
  <app-textarea
    [style.max-width]="'100%'"
    [formGroup]="fishForm"
    [formName]="'habitatThreats'"
    [label]="'HABITAT_THREATS_PRESSURES'"
  ></app-textarea>
  <app-textarea
    [style.max-width]="'100%'"
    [formGroup]="fishForm"
    [formName]="'observations'"
    [label]="'OBSERVATIONS'"
  ></app-textarea>
  <div class="info-container">
    <span class="info-text uppercase">{{ "LOCATION" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container form-1">
    <div class="form-row" [formGroup]="fishForm" [hidden]="readOnlyForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isEGSA" />
        {{ coordSystemDropdown[0].id }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isWGS84" />
        {{ coordSystemDropdown[1].id }}
      </label>
    </div>
    <div class="form-row">
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="fishForm"
        [formName]="'lat'"
        [label]="'LATITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="fishForm"
        [formName]="'long'"
        [label]="'LONGTITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="fishForm"
        [formName]="'x'"
        [label]="'X'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="fishForm"
        [formName]="'y'"
        [label]="'Y'"
        [required]="true"
      ></app-input>
    </div>
  </div>
  <br />
  <div class="map-container">
    <app-map
      [coordinates]="coordinates"
      (markerMoved)="onMarkerMoved($event)"
      [disabled]="readOnlyForm"
    ></app-map>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" class="multi-tab-buttons">
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container form-2">
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'altitude'"
              [label]="'BOOK_ALTITUDE'"
              [required]="true"
            ></app-input>

            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'slope'"
              [label]="'SLOPE'"
            ></app-input>
          </div>
          <div class="form-row">
            <app-input
              [formGroup]="fishForm"
              [formName]="'locality'"
              [label]="'LOCATION'"
            ></app-input>

            <app-input
              [formGroup]="fishForm"
              [formName]="'siteCodeText'"
              [label]="'SITE_CODE'"
            ></app-input>
          </div>
          <div class="form-row">
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'referenceSiteEst'"
              [label]="'REFERENCE_SITE_EST'"
              [dropdownList]="referenceSiteEstDropdown"
              [preDefinedId]="fishById?.referenceSiteEst"
            ></app-dropdown>
          </div>
          <div class="form-row" [hidden]="!readOnlyForm">
            <app-dropdown
              [formGroup]="fishForm"
              [hidden]="!readOnlyForm"
              [formName]="'gridCell'"
              [label]="'GRID_CELL'"
              [dropdownList]="codesDropdown"
              [required]="true"
              [preDefinedId]="fishById?.gridCell"
            ></app-dropdown>
            <app-dropdown
              class="dropdown-column"
              [formGroup]="fishForm"
              [hidden]="!readOnlyForm"
              [formName]="'naturaCode'"
              [label]="'NATURA_CODE'"
              [dropdownList]="sitesDropdown"
              [required]="true"
              [preDefinedId]="fishById?.naturaCode"
            ></app-dropdown>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[1].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container form-2">
          <div class="form-row">
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'samplingEquipment'"
              [label]="'EQUIPMENT_DROPDOWN'"
              [dropdownList]="equipmentDropdown"
              [required]="true"
              [preDefinedId]="fishById?.samplingEquipment"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'samplingEffort'"
              [label]="'SAMPLING_EFFORT'"
              [dropdownList]="effortDropdown"
              [required]="true"
              [preDefinedId]="fishById?.samplingEffort"
            ></app-dropdown>
          </div>
          <div
            class="form-row form-3"
            [hidden]="
              samplingEquipment === 'Δίχτυα' ||
              samplingEquipment === 'Άλλο' ||
              samplingEquipment === ''
            "
          >
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'electricity'"
              [label]="'ELECTRICITY'"
              [dropdownList]="electricityDropdown"
              [preDefinedId]="fishById?.electricity"
            ></app-dropdown>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'averageVoltage'"
              [label]="'AVERAGE_VOLTAGE'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'averageFrequency'"
              [label]="'AVERAGE_FREQUENCY'"
            ></app-input>
          </div>
          <div class="form-row">
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'samplingStrategyA'"
              [label]="'SAMPLING_STRATEGY_A'"
              [dropdownList]="strategyADropdown"
              [required]="true"
              [preDefinedId]="fishById?.samplingStrategyA"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'samplingStrategyB'"
              [label]="'SAMPLING_STRATEGY_Β'"
              [dropdownList]="strategyBDropdown"
              [required]="true"
              [preDefinedId]="fishById?.samplingStrategyB"
            ></app-dropdown>
          </div>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'fishedLength'"
              [label]="'FISHED_LENGTH'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'fishedArea'"
              [label]="'FISHED_AREA'"
            ></app-input>
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'flowRegime'"
              [label]="'FLOW_REGIME'"
              [dropdownList]="flowDropdown"
              [required]="true"
              [preDefinedId]="fishById?.flowRegime"
            ></app-dropdown>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
        >{{ multiTabGroup[2].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container no-form">
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'shadeness'"
              [label]="'SHADENESS'"
            ></app-input>
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'weather'"
              [label]="'WEATHER_FISH'"
              [dropdownList]="weatherDropdown"
              [required]="true"
              [preDefinedId]="fishById?.weather"
            ></app-dropdown>
          </div>
          <div class="sub-category-title">{{ "WIDTH_SITE" | translate }}</div>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'wettedWidthM'"
              [label]="'WETTED_WIDTH'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'leftBankM'"
              [label]="'LEFT_BANK'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'rightBankM'"
              [label]="'RIGHT_BANK'"
            ></app-input>
          </div>
          <div class="sub-category-title">{{ "WIDTH" | translate }}</div>
          <ng-container
            *ngIf="
              touched([
                'width1',
                'width5',
                'width10',
                'width20',
                'widthGT20'
              ]) &&
              hasErrors(['width1', 'width5', 'width10', 'width20', 'widthGT20'])
            "
          >
            <ng-container
              *ngIf="
                getErrors([
                  'width1',
                  'width5',
                  'width10',
                  'width20',
                  'widthGT20'
                ]) != {} &&
                getErrors([
                  'width1',
                  'width5',
                  'width10',
                  'width20',
                  'widthGT20'
                ])['overHundredWidth'] !== null &&
                getErrors([
                  'width1',
                  'width5',
                  'width10',
                  'width20',
                  'widthGT20'
                ])['overHundredWidth']
              "
            >
              <div class="error-message">
                {{ "OVER_HUNDRED_ERROR" | translate }}
              </div>
            </ng-container>
          </ng-container>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'width1'"
              [label]="'WIDTH_1'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'width5'"
              [label]="'WIDTH_5'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'width10'"
              [label]="'WIDTH_10'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'width20'"
              [label]="'WIDTH_20'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'widthGT20'"
              [label]="'WIDTH_GT20'"
            ></app-input>
          </div>
          <div class="sub-category-title">{{ "DEPTH" | translate }}</div>
          <ng-container
            *ngIf="
              touched(['depth025', 'depth05', 'depth1', 'depthGT1']) &&
              hasErrors(['depth025', 'depth05', 'depth1', 'depthGT1'])
            "
          >
            <ng-container
              *ngIf="
                getErrors(['depth025', 'depth05', 'depth1', 'depthGT1']) !=
                  {} &&
                getErrors(['depth025', 'depth05', 'depth1', 'depthGT1'])[
                  'overHundredDepth'
                ] !== null &&
                getErrors(['depth025', 'depth05', 'depth1', 'depthGT1'])[
                  'overHundredDepth'
                ]
              "
            >
              <div class="error-message">
                {{ "OVER_HUNDRED_ERROR" | translate }}
              </div>
            </ng-container>
          </ng-container>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'depth025'"
              [label]="'DEPTH_025'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'depth05'"
              [label]="'DEPTH_05'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'depth1'"
              [label]="'DEPTH_1'"
            ></app-input>
          </div>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'depthGT1'"
              [label]="'DEPTH_GT1'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'depthMean'"
              [label]="'DEPTH_MEAN'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'depthMax'"
              [label]="'DEPTH_MAX'"
            ></app-input>
          </div>
          <div class="sub-category-title">{{ "SUBS" | translate }}</div>
          <ng-container
            *ngIf="
              touched([
                'subsRock',
                'subsBoulder',
                'subsCobble',
                'subsPebble',
                'subsGravel',
                'subsSand',
                'subsSilt',
                'subsClay',
                'subsOrganic',
                'subsArtificial'
              ]) &&
              hasErrors([
                'subsRock',
                'subsBoulder',
                'subsCobble',
                'subsPebble',
                'subsGravel',
                'subsSand',
                'subsSilt',
                'subsClay',
                'subsOrganic',
                'subsArtificial'
              ])
            "
          >
            <ng-container
              *ngIf="
                getErrors([
                  'subsRock',
                  'subsBoulder',
                  'subsCobble',
                  'subsPebble',
                  'subsGravel',
                  'subsSand',
                  'subsSilt',
                  'subsClay',
                  'subsOrganic',
                  'subsArtificial'
                ]) != {} &&
                getErrors([
                  'subsRock',
                  'subsBoulder',
                  'subsCobble',
                  'subsPebble',
                  'subsGravel',
                  'subsSand',
                  'subsSilt',
                  'subsClay',
                  'subsOrganic',
                  'subsArtificial'
                ])['overHundredSubs'] !== null &&
                getErrors([
                  'subsRock',
                  'subsBoulder',
                  'subsCobble',
                  'subsPebble',
                  'subsGravel',
                  'subsSand',
                  'subsSilt',
                  'subsClay',
                  'subsOrganic',
                  'subsArtificial'
                ])['overHundredSubs']
              "
            >
              <div class="error-message">
                {{ "OVER_HUNDRED_ERROR" | translate }}
              </div>
            </ng-container>
          </ng-container>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsRock'"
              [label]="'SUBS_ROCK'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsBoulder'"
              [label]="'SUBS_BOULDER'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsCobble'"
              [label]="'SUBS_COBBLE'"
            ></app-input>
          </div>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsPebble'"
              [label]="'SUBS_PEBBLE'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsGravel'"
              [label]="'SUBS_GRAVEL'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsSand'"
              [label]="'SUBS_SAND'"
            ></app-input>
          </div>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsSilt'"
              [label]="'SUBS_SILT'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsClay'"
              [label]="'SUBS_CLAY'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsOrganic'"
              [label]="'SUBS_ORGANIC'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'subsArtificial'"
              [label]="'SUBS_ARTIFICIAL'"
            ></app-input>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[3].isChecked }"
        >{{ multiTabGroup[3].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container form-2">
          <div class="form-row">
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'velocityMSec'"
              [label]="'VELOCITY'"
              [dropdownList]="velocityDropdown"
              [required]="true"
              [preDefinedId]="fishById?.velocityMSec"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'helophytes'"
              [label]="'HELOPHYTES'"
              [dropdownList]="helophytesDropdown"
              [required]="true"
              [preDefinedId]="fishById?.helophytes"
            ></app-dropdown>
            <app-input
              [formGroup]="fishForm"
              [formName]="'helophytesDominatingSp'"
              [label]="'HELOPHYTES_MAIN'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'bottomVegetation'"
              [label]="'BOTTOM_VEGETATION'"
              [dropdownList]="vegetationsDropdown"
              [required]="true"
              [preDefinedId]="fishById?.bottomVegetation"
            ></app-dropdown>
            <app-input
              [formGroup]="fishForm"
              [formName]="'bottomVegetationDominatingSp'"
              [label]="'BOTTOM_VEGETATION_MAIN'"
            ></app-input>
          </div>
          <div class="sub-category-title">
            {{ "CHEMICAL_MEASUREMENTS" | translate }}
          </div>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'conductivitymScm'"
              [label]="'CONDUCTIVITY'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'o2mgL'"
              [label]="'OXYGEN'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'pH'"
              [label]="'PH'"
            ></app-input>
          </div>
          <div class="form-row">
            <app-input
              [showMaskTyped]="false"
              [suffix]="'°C'"
              [mask]="'separator.2'"
              [allowNegativeNumbers]="true"
              [formGroup]="fishForm"
              [formName]="'tofAir'"
              [label]="'AIR_TEMPERATURE'"
            ></app-input>
            <app-input
              [showMaskTyped]="false"
              [suffix]="'°C'"
              [mask]="'separator.2'"
              [allowNegativeNumbers]="true"
              [formGroup]="fishForm"
              [formName]="'tofWater'"
              [label]="'WATER_TEMPERATURE'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'salinityPpt'"
              [label]="'SALINITY'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'turbidity'"
              [label]="'TURBIDITY'"
              [dropdownList]="turbidityDropdown"
              [required]="true"
              [preDefinedId]="fishById?.turbidity"
            ></app-dropdown>
          </div>
          <div class="sub-category-title">
            {{ "HABITAT_TYPE_FISH" | translate }}
          </div>
          <ng-container
            *ngIf="
              touched([
                'habTypePool',
                'habTypeGlide',
                'habTypeRun',
                'habTypeRiffle',
                'habTypeRapid'
              ]) &&
              hasErrors([
                'habTypePool',
                'habTypeGlide',
                'habTypeRun',
                'habTypeRiffle',
                'habTypeRapid'
              ])
            "
          >
            <ng-container
              *ngIf="
                getErrors([
                  'habTypePool',
                  'habTypeGlide',
                  'habTypeRun',
                  'habTypeRiffle',
                  'habTypeRapid'
                ]) != {} &&
                getErrors([
                  'habTypePool',
                  'habTypeGlide',
                  'habTypeRun',
                  'habTypeRiffle',
                  'habTypeRapid'
                ])['overHundredHab'] !== null &&
                getErrors([
                  'habTypePool',
                  'habTypeGlide',
                  'habTypeRun',
                  'habTypeRiffle',
                  'habTypeRapid'
                ])['overHundredHab']
              "
            >
              <div class="error-message">
                {{ "OVER_HUNDRED_ERROR" | translate }}
              </div>
            </ng-container>
          </ng-container>
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'habTypePool'"
              [label]="'HABTYPE_POOL'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'habTypeGlide'"
              [label]="'HABTYPE_GLIDE'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'habTypeRun'"
              [label]="'HABTYPE_RUN'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'habTypeRiffle'"
              [label]="'HABTYPE_RIFFLE'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'habTypeRapid'"
              [label]="'HABTYPE_RAPID'"
            ></app-input>
          </div>
        </div>
        <div class="form-row">
          <app-textarea
            [style.max-width]="'100%'"
            [type]="'text'"
            [formGroup]="fishForm"
            [formName]="'habTypeOther'"
            [label]="'HABTYPE_OTHER'"
          ></app-textarea>
        </div>
        <br />
        <div class="form-row">
          <app-textarea
            [style.max-width]="'100%'"
            [type]="'text'"
            [formGroup]="fishForm"
            [formName]="'importantPressures'"
            [label]="'IMPORTANT_PRESSURES'"
          ></app-textarea>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[4].isChecked }"
        >{{ multiTabGroup[4].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container form-none" [formGroup]="fishForm">
          <div class="sub-category-title">
            {{ "HABITAT_TYPE_SAMPLED" | translate }}
          </div>
          <div class="form-row">
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="logsLargeWoodyDebris" />
              <div class="hab-type-label">{{ "LOGS_LARGE" | translate }}</div>
            </label>
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="overhangingVegetation" />
              <div class="hab-type-label">
                {{ "OVERHANGING_VEGETATION" | translate }}
              </div>
            </label>
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="denseMacrophyteBeds" />
              <div class="hab-type-label">
                {{ "DENSE_MACROPHYTEBEDS" | translate }}
              </div>
            </label>
          </div>
          <div class="form-row">
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="deepPools" />
              <div class="hab-type-label">{{ "DEEP_POOLS" | translate }}</div>
            </label>
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="bouldersCobbles" />
              <div class="hab-type-label">
                {{ "BOULDERS_COBBLES" | translate }}
              </div>
            </label>
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="undercutBanks" />
              <div class="hab-type-label">
                {{ "UNDERCUT_BANKS" | translate }}
              </div>
            </label>
          </div>
          <div class="form-row">
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="thickRootMats" />
              <div class="hab-type-label">
                {{ "THICK_ROOT_MATS" | translate }}
              </div>
            </label>
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="marshyFringes" />
              <div class="hab-type-label">
                {{ "MARSHY_FRINGES" | translate }}
              </div>
            </label>
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="riffles" />
              <div class="hab-type-label">{{ "RIFFLES" | translate }}</div>
            </label>
          </div>
          <div class="form-row form-3">
            <label class="btn btn-secondary hab-type-check-box" ngbButtonLabel>
              <input type="checkbox" formControlName="isolatedBackwaterPools" />
              <div class="hab-type-label">
                {{ "ISOLATED_BACKWATER_POOLS" | translate }}
              </div>
            </label>
          </div>
          <div class="form-row">
            <app-textarea
              [style.max-width]="'100%'"
              [type]="'text'"
              [formGroup]="fishForm"
              [formName]="'otherTypesPhysicalCoverage'"
              [label]="'OTHER_TYPES_PHYSICAL_COVERAGE'"
            ></app-textarea>
          </div>

          <div class="form-row">
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'efficacyOfHabitatSampling'"
              [label]="'EFFICACY'"
              [dropdownList]="efficacyDropdown"
              [required]="true"
              [preDefinedId]="fishById?.efficacyOfHabitatSampling"
            ></app-dropdown>
          </div>

          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'sufficientHabitatQuality'"
              [label]="'SUFFICIENT_HABITAT_QUALITY'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'insufficientHabitatQuality'"
              [label]="'INSUFFICIENT_HABITAT_QUALITY'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="fishForm"
              [formName]="'unknownHabitatQuality'"
              [label]="'UNKNOWN_HABITAT_QUALITY'"
            ></app-input>
          </div>

          <div class="form-row">
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'sufficientHabitatArea'"
              [label]="'SUFFICIENT_HABITAT_AREA'"
              [dropdownList]="habitatAreaDropdown"
              [preDefinedId]="fishById?.sufficientHabitatArea"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'sufficientHabitatXQuality'"
              [label]="'SUFFICIENT_X_HABITAT_QUALITY'"
              [dropdownList]="habitatAreaDropdown"
              [preDefinedId]="fishById?.sufficientHabitatXQuality"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="fishForm"
              [formName]="'insufficientHabitatArea'"
              [label]="'INSUFFICIENT_HABITAT_AREA'"
              [dropdownList]="habitatAreaDropdown"
              [preDefinedId]="fishById?.insufficientHabitatArea"
            ></app-dropdown>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[5].isChecked }"
        >{{ multiTabGroup[5].name }}</a
      >
      <ng-template ngbNavContent>
        <app-fish-species
          [formGroup]="fishForm"
          [species]="species"
          [speciesById]="speciesById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="fishById?.dataVersion"
          [sampleId]="sampleId"
        ></app-fish-species>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[6].isChecked }"
        >{{ multiTabGroup[6].name }}</a
      >
      <ng-template ngbNavContent>
        <app-fish-threats
          [formGroup]="fishForm"
          [threats]="threats"
          [selectedSpecies]="selectedSpecies"
          [threatsById]="threatsById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="fishById?.dataVersion"
          [sampleId]="sampleId"
        ></app-fish-threats>
      </ng-template>
    </ng-container>
    <!-- <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[7].isChecked }"
        >{{ multiTabGroup[7].name }}</a
      >
      <ng-template ngbNavContent>
        <app-fish-other-threats
          [formGroup]="fishForm"
          [threats]="otherThreats"
          [threatsById]="otherThreatsById"
          [disabledControls]="readOnlyForm"
          [sampleId]="sampleId"
        ></app-fish-other-threats>
      </ng-template>
    </ng-container> -->
  </nav>
  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <ng-container
    *ngIf="
      !sampleId ||
      (sampleId && predefinedFiles.length > 0) ||
      (sampleId && !readOnlyForm)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    [btnText]="sampleId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    (buttonClicked)="prepareFishRequest()"
    [isDisabled]="!fishForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
