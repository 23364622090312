import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, finalize } from 'rxjs';
import {
  IInvertebrateThreatsDto,
  ISampleInvertebrateThreatsDto,
} from 'src/app/core/dtos/invertebrate.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { InvertebrateService } from '../../services/invertebrate.service';

@Component({
  selector: 'app-invertebrate-threats',
  templateUrl: './invertebrate-threats.component.html',
  styleUrls: ['./invertebrate-threats.component.scss'],
})
export class InvertebrateThreatsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: ISampleInvertebrateThreatsDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  private destroy$ = new Subject<void>();
  public threatsForm!: FormGroup;
  public invertebratesThreats: IInvertebrateThreatsDto[] = [];
  public threatsDropdown: IDropdownItem[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public ctThreats: any[] = [];
  public ctSpecies: any[] = [];
  public typeDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('TYPE_P_T'),
      name: this.translate.instant('TYPE_P_T'),
    },
    {
      id: this.translate.instant('TYPE_P'),
      name: this.translate.instant('TYPE_P'),
    },
    {
      id: this.translate.instant('TYPE_T'),
      name: this.translate.instant('TYPE_T'),
    },
  ];
  public importanceDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'L (Low)',
    },
    {
      id: 'M',
      name: 'M (Medium)',
    },
    {
      id: 'H',
      name: 'H (High)',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private invertebrateService: InvertebrateService
  ) {}

  ngOnInit(): void {
    if (this.threatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.invertebrateService.getInvertebratesThreats(this.dataVersion),
      this.invertebrateService.getInvertebratesSpecies(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.ctThreats.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctThreats.forEach((threat) => {
              dropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
            this.threatsDropdown = dropdown;
          }

          if (this.ctSpecies.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctSpecies.forEach((species) => {
              dropdown.push({
                id: species.code,
                name: species.code,
                actualId: species.id,
              });
            });
            this.speciesDropdown = dropdown;
          }

          if (this.threatsById.length > 0) {
            this.threatsById.forEach(() => {
              this.addNewThreats();
            });
            this.threats.patchValue(this.threatsById);
            this.threats.markAsTouched();
            this.threatsById = [];
            if (this.disabledControls === true) {
              this.threats.disable();
            }
          }

          return;
        })
      )
      .subscribe(([threat, species]) => {
        if (threat && threat.length > 0) {
          this.ctThreats = threat;
        }

        if (species && species.length > 0) {
          this.ctSpecies = species;
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    this.threats.push(this.invertebrateService.initThreatsFormGroup(this.fb));
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
