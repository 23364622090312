<ng-container [formGroup]="formGroup">
  <div formArrayName="functions" class="form-container form-1">
    <ng-container
      *ngFor="let functionsForm of functions.controls; let index = index"
    >
      <div class="form-row form-3">
        <app-input
          [style.max-width]="'100%'"
          [formGroup]="getGroup(index)"
          [formName]="'structure'"
          [label]="'STRUCTURE'"
          [required]="true"
        ></app-input>
      </div>
      <div
        class="form-row custom-check-container form-3"
        [formGroup]="getGroup(index)"
      >
        <label class="btn btn-secondary" ngbButtonLabel>
          <input type="checkbox" formControlName="statusBad" />
          {{ "QUALITY_BAD" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel>
          <input type="checkbox" formControlName="statusMed" />
          {{ "QUALITY_MED" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel>
          <input type="checkbox" formControlName="statusEx" />
          {{ "QUALITY_EX" | translate }}
        </label>
      </div>
      <app-button
        (buttonClicked)="removeFunctions(index)"
        [style.--btnPadding]="'6px 15px'"
        class="minus-btn"
        [btnText]="'-'"
        [tooltip]="'REMOVE_ENTRY' | translate"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== functions.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_STRUCT_FUNCTION'"
      (buttonClicked)="addNewFunctions()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
