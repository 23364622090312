<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-2">
    <ng-container
      *ngFor="let speciesForm of species.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
        ></app-dropdown>
      </div>
      <div class="checkbox-container">
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.pari" />
          {{ "PARI_FULL" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.parii" />
          {{ "PARII_FULL" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel [class.disabled]="true">
          <input type="checkbox" [checked]="getGroup(index).value.pariii" />
          {{ "PARIII_FULL" | translate }}
        </label>
      </div>
      <div class="form-row">
        <app-input
          [mask]="'00:00'"
          [formGroup]="getGroup(index)"
          [formName]="'time'"
          [label]="'TIME'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfPeople'"
          [label]="'NUMBER_OF_PEOPLE'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'age'"
          [label]="'AGE'"
          [dropdownList]="ageDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['age'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'gender'"
          [label]="'SEX'"
          [dropdownList]="genderDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['gender'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'attitude'"
          [label]="'ATTITUDE'"
          [dropdownList]="behaviorDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['attitude'].value"
        ></app-dropdown>
      </div>
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'egsax'"
          [label]="'EGSAX'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'egsay'"
          [label]="'EGSAY'"
          [required]="true"
        ></app-input>

        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'observationType'"
          [label]="'OBSERVATION_TYPE'"
          [dropdownList]="observationDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['observationType'].value"
        ></app-dropdown>
      </div>
      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>
      <app-button
        (buttonClicked)="removeSpecies(index)"
        [style.--btnPadding]="'6px 15px'"
        class="minus-btn"
        [btnText]="'-'"
        [tooltip]="'REMOVE_ENTRY' | translate"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== species.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_SPECIES'"
      (buttonClicked)="addNewSpecies()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
