import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, forkJoin, Subject, takeUntil, finalize } from 'rxjs';
import {
  IBirdDto,
  IBirdSpeciesDto,
  ISampleBirdSpeciesDto,
  ISampleBirdThreatsDto,
} from 'src/app/core/dtos/bird.dto';
import {
  ICellCodeDto,
  ICorineDto,
  IHabitatSpeciesDto,
  INaturaSitesDto,
  IPrefecturesDto,
} from 'src/app/core/dtos/protocols.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { ITabBtnGroup } from 'src/app/shared/button-group/button-group.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { ISampleIdResponse } from 'src/app/shared/dtos/generic.dto';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import { BirdService } from '../../services/bird.service';
import { ProtocolsService } from '../../services/protocols.service';
import {
  IFileDeleteRequest,
  IFilePayload,
} from 'src/app/shared/files-container/files-container.component';

@Component({
  selector: 'app-birds',
  templateUrl: './birds.component.html',
  styleUrls: ['./birds.component.scss'],
})
export class BirdsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public bird: IBirdDto = {};
  public birdById?: IBirdDto;
  public birdForm!: FormGroup;
  public naturaSites: INaturaSitesDto[] = [];
  public sitesDropdown: IDropdownItem[] = [];
  public prefectures: IPrefecturesDto[] = [];
  public prefecturesDropdown: IDropdownItem[] = [];
  public codes: ICellCodeDto[] = [];
  public corines: ICorineDto[] = [];
  public codesDropdown: IDropdownItem[] = [];
  public corinesDropdown: IDropdownItem[] = [];
  public habitatSpecies: IHabitatSpeciesDto[] = [];
  public habitatSpeciesDropdown: IDropdownItem[] = [];
  public financialDropdown: IDropdownItem[] = [
    { id: 'ΥΜΕΠΕΡΑΑ', name: 'ΥΜΕΠΕΡΑΑ' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];
  public tabBtn: ITabBtnGroup[] = [
    { id: 'map', name: 'Επελεξε τοποθεσια', isChecked: true },
    { id: 'coordinates', name: 'Εισαγωγη συντεταγμενων', isChecked: false },
  ];
  public coordSystemDropdown: IDropdownItem[] = [
    { id: 'EGSA', name: 'EGSA' },
    { id: 'WGS84', name: 'WGS84' },
  ];
  public multiTabGroup = [
    { id: 0, name: 'Γεωγραφια', isChecked: true },
    {
      id: 1,
      name: 'Κλιματικές και Περιβαλλοντικές Παράμετροι',
      isChecked: true,
    },
    { id: 2, name: 'Χαρακτηριστικά Ενδιαιτήματος', isChecked: true },
    { id: 3, name: 'Παρουσία ειδων', isChecked: true },
    { id: 4, name: 'Πιέσεις-Απειλές', isChecked: true },
  ];
  public cloudDropdown: IDropdownItem[] = [
    { id: 0, name: '<20%' },
    { id: 1, name: '20-40%' },
    { id: 2, name: '40-60%' },
    { id: 3, name: '60-80%' },
    { id: 4, name: '>80%' },
  ];
  public windDirectionDropdown: IDropdownItem[] = [
    { id: 0, name: 'Β' },
    { id: 1, name: 'ΒΑ' },
    { id: 2, name: 'Α' },
    { id: 3, name: 'ΝΑ' },
    { id: 4, name: 'Ν' },
    { id: 5, name: 'ΝΔ' },
    { id: 6, name: 'Δ' },
    { id: 7, name: 'ΒΔ' },
    { id: 8, name: 'Χωρίς Άνεμο' },
  ];
  public vegetationHeightDropdown: IDropdownItem[] = [
    { id: 0, name: 'Γυμνό (0-1εκ.)' },
    { id: 1, name: 'Χαμηλή (1-15εκ.)' },
    { id: 2, name: 'Μετρίου Ύψους (15-50εκ.)' },
    { id: 3, name: 'Θάμνοι και χαμηλά δέντρα (50εκ-2μ)' },
    { id: 4, name: 'Δέντρα (2-5μ)' },
    { id: 5, name: 'Ψηλά Δέντρα (>5μ)' },
  ];
  public densityDropdown: IDropdownItem[] = [
    { id: 0, name: 'Υψηλή' },
    { id: 1, name: 'Μέτρια' },
    { id: 2, name: 'Χαμηλή' },
  ];
  public qualityDropDown: IDropdownItem[] = [
    { id: 0, name: 'Π. Κακή' },
    { id: 1, name: 'Κακή' },
    { id: 2, name: 'Μέτρια' },
    { id: 3, name: 'Καλή' },
    { id: 4, name: 'Άριστη' },
  ];
  public methodDropdown: IDropdownItem[] = [
    {
      id: 0,
      name: 'Καταγραφή με τη μέθοδο των σταθμών σημειακής καταγραφής από εποπτικά σημεία',
    },
    {
      id: 1,
      name: "Καταγραφή με τη μέθοδο της εξ' αποστάσεως καταγραφής από γραμμικές διαδρομές με αυτοκίνητο",
    },
    {
      id: 2,
      name: 'Καταγραφή με τη μέθοδο των σταθμών σημειακής καταγραφής και την αναπαραγωγή ηχητικών αρχείων',
    },
    {
      id: 3,
      name: "Καταγραφή με τη μέθοδο των σταθμών σημειακής καταγραφής με άμεση παρατήρηση και εξ' ακοής",
    },
    {
      id: 4,
      name: 'Καταγραφή με τη μέθοδο των γραμμικών διαδρομών με διαβηματισμό',
    },
    {
      id: 5,
      name: "Καταγραφή με τη μέθοδο της εξ' αποστάσεως καταγραφής από γραμμικές διαδρομές με διαβηματισμό",
    },
    {
      id: 6,
      name: 'Καταγραφή με τη μέθοδο της άμεσης καταμέτρησης από σταθμούς σημειακής καταγραφής',
    },
    {
      id: 7,
      name: 'Καταγραφή με τη μέθοδο της καταγραφής από γραμμικές διαδρομές με διαβηματισμό',
    },
    {
      id: 8,
      name: 'Καταγραφή με τη μέθοδο του θαλάσσιου περίπλου ακτογραμμών με σκάφος',
    },
    {
      id: 9,
      name: 'Καταγραφή με τη μέθοδο των θαλάσσιων διαδρομών ανοιχτής θαλάσσης',
    },
    {
      id: 10,
      name: 'Συλλογή υλικού για εφαρμογή γενετικής ανάλυσης με την μη παρεμβατική μέθοδο της συλλογής βιολογικού υλικού',
    },
    { id: 11, name: 'Τυχαίες καταγραφές' },
  ];
  public coordinates: ICoordinates | undefined;

  public get species(): FormArray {
    return this.birdForm.controls['species'] as FormArray;
  }

  public get threats(): FormArray {
    return this.birdForm.controls['threats'] as FormArray;
  }

  public predefinedFiles: IFilePayload[] = [];
  public files: any[] = [];
  public filesToDelete: any[] = [];
  public errors: ValidationErrors[] = [];
  public speciesById: ISampleBirdSpeciesDto[] = [];
  public threatsById: ISampleBirdThreatsDto[] = [];
  public ctSpecies: IBirdSpeciesDto[] = [];

  @Input() public protocolId!: number;
  @Input() public sampleId?: number;
  @Input() public readOnlyForm = false;

  constructor(
    private fb: FormBuilder,
    private protocolService: ProtocolsService,
    private birdService: BirdService,
    private router: Router,
    public toastService: ToastService,
    public common: CommonService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      protocolId: number;
    };
    this.protocolId = state?.protocolId;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.protocolId = history.state.protocolId;
    this.initBirdForm();

    if (this.sampleId) {
      this.birdService
        .getBirdById(this.sampleId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.getValuesFromServices();
            return;
          })
        )
        .subscribe((res) => {
          if (res != undefined) {
            this.birdById = res as IBirdDto;
            // Fix different property names
            this.birdById.lat = this.birdById.pointX;
            this.birdById.long = this.birdById.pointY;

            if (res.files != null) {
              this.predefinedFiles = res.files as any;
            }

            // Fix date
            if (this.birdById.date) {
              const convertedDate = new Date(this.birdById.date);
              const dateString =
                convertedDate.getDate().toString().padStart(2, '0') +
                (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                convertedDate.getFullYear().toString();
              this.birdById.date = dateString;
            }
            // Fix time
            if (this.birdById.time) {
              const timeString = this.birdById.time
                .toString()
                .slice(0, 5)
                .replaceAll(':', '');
              this.birdById.time = timeString;
            }

            if (
              this.birdById.species != null &&
              this.birdById.species.length > 0
            ) {
              this.birdById.species.forEach((species) => {
                if (species?.time != null) {
                  species.time = species.time
                    .toString()
                    .slice(0, 5)
                    .replaceAll(':', '');
                }
              });
            }

            // Fix altitudet
            if (this.birdById.altitudet) {
              this.birdById.altitudeEnd = this.birdById.altitudet;
            }
          }
        });
    } else {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.protocolService.getCellCodes(),
      this.protocolService.getNaturaSites(this.birdById?.dataVersion),
      this.protocolService.getPrefectures(this.birdById?.dataVersion),
      this.protocolService.getHabitatSpecies(this.birdById?.dataVersion),
      this.protocolService.getCorines(this.birdById?.dataVersion),
      this.birdService.getBirdsSpecies(this.birdById?.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.codes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.codes.forEach((code) => {
              dropdown.push({
                id: code.cellCode,
                name: code.cellCode,
              });
            });
            this.codesDropdown = dropdown;
          }

          if (this.naturaSites.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.naturaSites.forEach((site) => {
              dropdown.push({ id: site.naturaCode, name: site.name });
            });
            this.sitesDropdown = dropdown;
          }

          if (this.prefectures.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.prefectures.forEach((prefecture) => {
              dropdown.push({
                id: prefecture.id,
                name: prefecture.prefecture,
              });
            });
            this.prefecturesDropdown = dropdown;
          }

          if (this.habitatSpecies.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.habitatSpecies.forEach((habitat) => {
              dropdown.push({
                id: habitat.code,
                name: habitat.species,
              });
            });
            this.habitatSpeciesDropdown = dropdown;
          }

          if (this.corines.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.corines.forEach((code) => {
              dropdown.push({
                id: code.code,
                name: code.description,
              });
            });
            this.corinesDropdown = dropdown;
          }

          if (this.sampleId && this.birdById) {
            this.birdForm.patchValue(this.birdById);
            if (this.birdById.species) {
              this.speciesById = this.birdById.species;
            }
            if (this.birdById.threats) {
              this.threatsById = this.birdById.threats;
            }
            if (this.readOnlyForm === true) {
              this.birdForm.disable();
            }
          }
          return;
        })
      )
      .subscribe(
        ([
          codes,
          naturaSites,
          prefectures,
          habitatSpecies,
          corines,
          ctSpecies,
        ]) => {
          if (codes != undefined) {
            this.codes = codes as ICellCodeDto[];
          }

          if (naturaSites != undefined) {
            this.naturaSites = naturaSites as INaturaSitesDto[];
          }

          if (prefectures != undefined) {
            this.prefectures = prefectures as IPrefecturesDto[];
          }

          if (habitatSpecies != undefined) {
            this.habitatSpecies = habitatSpecies as IHabitatSpeciesDto[];
          }

          if (corines != undefined) {
            this.corines = corines as ICorineDto[];
          }

          if (ctSpecies != undefined) {
            this.ctSpecies = ctSpecies as IBirdSpeciesDto[];
          }
        }
      );
  }

  private monitorCoords(): void {
    this.common
      .monitorCoords(
        this.destroy$,
        this.birdForm,
        'x',
        'y',
        'lat',
        'long',
        'xEnd',
        'yEnd',
        'latEnd',
        'longEnd'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICoordinates) => (this.coordinates = data));
  }

  private initBirdForm(): void {
    this.birdForm = this.birdService.initBirdForm(this.fb);
    this.monitorCoords();
    this.common.monitorCoordSystem(this.birdForm, this.destroy$);
  }

  public get coordSystem(): number {
    return this.birdForm.controls['isWGS84'].value ? 1 : 0;
  }

  public prepareBirdRequest(): void {
    this.errors = this.common.validateAllFormControl(this.birdForm);
    if (this.errors.length > 0) {
      return;
    }

    this.bird = this.birdService.prepareBirdRequest(
      this.birdForm,
      false,
      this.ctSpecies,
      this.sampleId != null
    );

    if (this.sampleId && !this.readOnlyForm) {
      this.updateSample();
    } else {
      this.registerNewSample();
    }
  }

  public registerNewSample(): void {
    this.birdService
      .addBird(this.bird)
      .pipe(
        catchError((err) => {
          this.toastService.show('GENERIC_FAILURE_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
          throw err;
        })
      )
      .subscribe((sampleIdResponse: ISampleIdResponse) => {
        if (this.files.length > 0) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append('files', file);
          });

          formData.append('SampleId', sampleIdResponse.sampleId.toString());
          this.birdService
            .addFiles(formData)
            .pipe(
              catchError((err) => {
                this.toastService.show('GENERIC_FAILURE_TEXT', {
                  header: 'GENERIC_FAILURE_HEADER',
                });
                throw err;
              })
            )
            .subscribe(() => {
              this.toastService.show('GENERIC_SUCCESS_TEXT', {
                header: 'GENERIC_SUCCESS_HEADER',
              });

              this.activeModal.close({
                sampleId: sampleIdResponse.sampleId,
                samplingCode: this.birdForm.value.samplingCode,
              });
            });
        } else {
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });

          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.birdForm.value.samplingCode,
          });
        }
      });
  }

  public updateSample(): void {
    if (this.sampleId) {
      this.birdService
        .putBird(this.sampleId, this.bird)
        .pipe(
          catchError((err) => {
            this.toastService.show('GENERIC_FAILURE_TEXT', {
              header: 'GENERIC_FAILURE_HEADER',
            });
            throw err;
          })
        )
        .subscribe((sampleIdResponse: ISampleIdResponse) => {
          if (this.files != null) {
            // Uploading new files
            const formData = new FormData();
            let addFiles = false;
            this.files.forEach((file: any) => {
              if (file instanceof File) {
                addFiles = true;
                formData.append('files', file);
              }
            });

            if (addFiles) {
              formData.append('SampleId', (this.sampleId as number).toString());
              this.birdService
                .addFiles(formData)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          // Deleting files
          if (this.filesToDelete != null && this.filesToDelete.length > 0) {
            const payload: IFileDeleteRequest = {
              sampleId: this.sampleId as number,
              fileIds: [],
            };
            this.filesToDelete.forEach((file) => {
              if (file.id != null) {
                payload.fileIds.push(file.id);
              }
            });

            if (payload.fileIds.length > 0) {
              this.birdService
                .removeFiles(payload)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.birdForm.value.samplingCode,
          });
        });
    }
  }

  public confirmClose(): void {
    if (this.birdForm.dirty) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.result.then((data) => {
        if (data) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  /**
   * Change view
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public tabBtnClick(btnId: string) {
    if (btnId === 'map') {
      this.tabBtn[0].isChecked = true;
      this.tabBtn[1].isChecked = false;
    } else {
      this.tabBtn[1].isChecked = true;
      this.tabBtn[0].isChecked = false;
    }
  }

  public getCoordinates(): ICoordinates | undefined {
    if (
      this.birdForm?.controls['lat']?.value != null &&
      this.birdForm?.controls['long']?.value != null
    ) {
      return {
        lat: this.birdForm.controls['lat'].value,
        lng: this.birdForm.controls['long'].value,
      };
    }

    return undefined;
  }

  /**
   * Find the new coordinates of the map
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public onMarkerMoved(coordinates: { lat: number; lng: number }): void {
    this.coordinates = coordinates;
    this.submitCoordinates();
  }

  public submitCoordinates(): void {
    if (this.coordinates != null) {
      this.birdForm.controls['lat'].patchValue(this.coordinates.lat, {
        emitEvent: false,
      });
      this.birdForm.controls['long'].patchValue(this.coordinates.lng, {
        emitEvent: false,
      });

      const coordinates = this.common.convertWGStoEGSA(
        this.coordinates.lat,
        this.coordinates.lng
      );
      this.birdForm.controls['x'].patchValue(coordinates.x, {
        emitEvent: false,
      });
      this.birdForm.controls['y'].patchValue(coordinates.y, {
        emitEvent: false,
      });
    }
  }
}
