<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-1">
    <ng-container
      *ngFor="let speciesForm of species.controls; let index = index"
    >
      <div
        class="form-row form-3"
        *ngIf="speciesCategory === seaSpeciesCategory.Cetaceans"
      >
        <app-input
          [mask]="'00/00/0000'"
          [formGroup]="getGroup(index)"
          [formName]="'observationDateCetaceans'"
          [label]="'OBSERVATION_DATE_CETACEANS'"
        ></app-input>
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
        ></app-dropdown>
        <app-input
          *ngIf="
            speciesCategory === seaSpeciesCategory.Cetaceans ||
            speciesCategory === seaSpeciesCategory.SeaInvertebrates
          "
          [formGroup]="getGroup(index)"
          [formName]="'otherSpecies'"
          [label]="'OTHER_SPECIES'"
          [ngClass]="{
            disabled:
              getGroup(index).controls['otherSpecies'].disabled &&
              getGroup(index).controls['otherSpecies'].value === ''
          }"
        ></app-input>
      </div>

      <div class="form-row">
        <div class="checkbox-container">
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexII" />
            {{ "PARII" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexIV" />
            {{ "PARIV" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexV" />
            {{ "PARV" | translate }}
          </label>
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="speciesCategory !== seaSpeciesCategory.SeaInvertebrates"
      >
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'lat'"
          [label]="'LATITUDE'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'long'"
          [label]="'LONGTITUDE'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row">
        <app-dropdown
          *ngIf="speciesCategory === seaSpeciesCategory.Seals"
          [formGroup]="getGroup(index)"
          [formName]="'habitat'"
          [label]="'HABITAT'"
          [dropdownList]="habitatDropdown"
          [preDefinedId]="getGroup(index).controls['habitat'].value"
        ></app-dropdown>
        <app-input
          *ngIf="speciesCategory === seaSpeciesCategory.SeaInvertebrates"
          [formGroup]="getGroup(index)"
          [formName]="'biotope'"
          [label]="'BIOTOPE'"
        ></app-input>
      </div>
      <div class="form-row form-3">
        <app-dropdown
          *ngIf="
            speciesCategory === seaSpeciesCategory.Seals ||
            speciesCategory === seaSpeciesCategory.SeaTurtles
          "
          [formGroup]="getGroup(index)"
          [formName]="'age'"
          [label]="'AGE'"
          [dropdownList]="ageDropdown"
          [preDefinedId]="getGroup(index).controls['age'].value"
        ></app-dropdown>
        <app-dropdown
          *ngIf="
            speciesCategory === seaSpeciesCategory.Seals ||
            speciesCategory === seaSpeciesCategory.SeaTurtles
          "
          [formGroup]="getGroup(index)"
          [formName]="'gender'"
          [label]="'GENDER'"
          [dropdownList]="genderDropdown"
          [preDefinedId]="getGroup(index).controls['gender'].value"
        ></app-dropdown>
        <app-input
          *ngIf="
            speciesCategory === seaSpeciesCategory.Cetaceans ||
            speciesCategory === seaSpeciesCategory.Seals
          "
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'totalLength'"
          [label]="'TOTAL_LENGTH'"
        ></app-input>
      </div>
      <div
        *ngIf="speciesCategory === seaSpeciesCategory.SeaInvertebrates"
        class="form-row form-3"
      >
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'depth'"
          [label]="'DEPTH'"
        ></app-input>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'depthRange'"
          [label]="'DEPTH_RANGE'"
          [dropdownList]="depthRangeDropdown"
          [preDefinedId]="getGroup(index).controls['depthRange'].value"
        ></app-dropdown>
      </div>
      <div class="form-row form-3">
        <app-input
          *ngIf="speciesCategory === seaSpeciesCategory.Cetaceans"
          [hidden]="!disabledControls"
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfPeople'"
          [label]="'NUMBER_OF_PEOPLE'"
        ></app-input>
        <app-input
          *ngIf="speciesCategory === seaSpeciesCategory.SeaInvertebrates"
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfPeopleSeaInvertebrates'"
          [label]="'NUMBER_OF_PEOPLE'"
        ></app-input>
        <app-input
          *ngIf="speciesCategory === seaSpeciesCategory.SeaInvertebrates"
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfDeadPeople'"
          [label]="'NUMBER_OF_DEAD_PEOPLE'"
        ></app-input>
      </div>

      <div
        class="form-row form-3"
        *ngIf="speciesCategory === seaSpeciesCategory.Cetaceans"
      >
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfNewbornCetaceans'"
          [label]="'NUMBER_OF_NEWBORN_CETACEANS'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfVeryYoungCetaceans'"
          [label]="'NUMBER_OF_VERY_YOUNG_CETACEANS'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfYoungCetaceans'"
          [label]="'NUMBER_OF_YOUNG_CETACEANS'"
        ></app-input>
      </div>

      <div
        class="form-row form-3"
        *ngIf="speciesCategory === seaSpeciesCategory.Cetaceans"
      >
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfSubAdultCetaceans'"
          [label]="'NUMBER_OF_SUB_ADULT_CETACEANS'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfAdultCetaceans'"
          [label]="'NUMBER_OF_ADULT_CETACEANS'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfUndefinedGenderCetaceans'"
          [label]="'NUMBER_OF_UNDEFINED_GENDER_CETACEANS'"
        ></app-input>
      </div>

      <div
        class="form-row form-3"
        *ngIf="speciesCategory === seaSpeciesCategory.Cetaceans"
      >
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfMaleCetaceans'"
          [label]="'NUMBER_OF_MALE_CETACEANS'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfFemaleCetaceans'"
          [label]="'NUMBER_OF_FEMALE_CETACEANS'"
        ></app-input>
      </div>

      <div
        *ngIf="speciesCategory === seaSpeciesCategory.SeaInvertebrates"
        class="form-row form-3"
        [formGroup]="getGroup(index)"
      >
        <label class="btn btn-secondary btn-checkbox" ngbButtonLabel>
          <input type="checkbox" formControlName="presence" />
          {{ "PRESENCE" | translate }}
        </label>
      </div>
      <div
        *ngIf="speciesCategory === seaSpeciesCategory.SeaTurtles"
        class="form-row"
      >
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'lengthTurtle'"
          [label]="'LENGTH_TURTLE'"
        ></app-input>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'methodTurtle'"
          [label]="'METHOD_TURTLE'"
          [dropdownList]="methodTurtleDropdown"
          [preDefinedId]="getGroup(index).controls['methodTurtle'].value"
        ></app-dropdown>
      </div>
      <div
        *ngIf="speciesCategory !== seaSpeciesCategory.SeaInvertebrates"
        class="form-row"
      >
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'deathStatus'"
          [label]="'DEATH_STATUS'"
          [dropdownList]="deathStatusDropdown"
          [preDefinedId]="getGroup(index).controls['deathStatus'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'deathReasson'"
          [label]="'DEATH_REASON'"
          [dropdownList]="deathReassonDropdown"
          [preDefinedId]="getGroup(index).controls['deathReasson'].value"
        ></app-dropdown>
      </div>

      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>

      <app-button
        (buttonClicked)="removeSpecies(index)"
        [style.--btnPadding]="'6px 15px'"
        class="minus-btn"
        [btnText]="'REMOVE_SPECIES'"
        [tooltip]="'REMOVE_ENTRY' | translate"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== species.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_SPECIES'"
      (buttonClicked)="addNewSpecies()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
