<ng-container [formGroup]="formGroup">
  <div class="form-container form-2">
    <ng-container
      *ngFor="let entriesForm of entries.controls; let index = index"
    >
      <div
        class="form-row"
        [formGroup]="getGroup(index)"
        style="margin-bottom: 30px"
        [hidden]="true"
      >
        <label class="btn btn-secondary" ngbButtonLabel>
          <input type="checkbox" formControlName="isSpecies" />
          {{ "SPECIES" | translate }}
        </label>
        <label class="btn btn-secondary" ngbButtonLabel>
          <input type="checkbox" formControlName="isHabitat" />
          {{ "HABITAT" | translate }}
        </label>
      </div>

      <div
        class="form-row-two"
        [ngClass]="{ hidden: !entriesForm.value.isSpecies }"
      >
        <app-dropdown
          [dropdownList]="entriesForm.value.isSpecies ? speciesIds : []"
          [formGroup]="getGroup(index)"
          [formName]="'speciesId'"
          [label]="'BOOK_SPECIES_ID'"
          (valueChanged)="speciesSelected($event, entriesForm.value.groupIndex)"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['speciesId'].value"
        ></app-dropdown>
        <app-input
          [formGroup]="getGroup(index)"
          [required]="!getGroup(index).controls['speciesIdOther'].disabled"
          [formName]="'speciesIdOther'"
          [label]="'BOOK_SPECIES_ID_OTHER'"
          [ngClass]="{
            disabled:
              getGroup(index).controls['speciesIdOther'].disabled &&
              getGroup(index).controls['speciesId'].value !== 'Άλλο'
          }"
        ></app-input>
      </div>

      <div class="form-row">
        <div class="checkbox-container">
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexII" />
            {{ "PARII" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexIV" />
            {{ "PARIV" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexV" />
            {{ "PARV" | translate }}
          </label>
        </div>
      </div>

      <div
        class="form-row-two"
        [ngClass]="{ hidden: !entriesForm.value.isSpecies }"
      >
        <app-label-input
          [value]="selectedSpecies[index].class"
          [label]="'BOOK_SPECIES_CLASS'"
        ></app-label-input>
        <app-label-input
          [value]="selectedSpecies[index].order"
          [label]="'BOOK_SPECIES_ORDER'"
        ></app-label-input>
        <app-label-input
          [value]="selectedSpecies[index].family"
          [label]="'BOOK_SPECIES_FAMILY'"
        ></app-label-input>
        <app-label-input
          [value]="selectedSpecies[index].genus"
          [label]="'BOOK_SPECIES_GENUS'"
        ></app-label-input>
      </div>

      <div class="form-row-two" style="margin-top: 20px">
        <app-dropdown
          [ngClass]="{ hidden: !entriesForm.value.isHabitat }"
          [dropdownList]="entriesForm.value.isHabitat ? habitatTypes : []"
          [formGroup]="getGroup(index)"
          [formName]="'habitatId'"
          [label]="'BOOK_HABITAT_ID'"
          [style.maxWidth]="'100%'"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['habitatId'].value"
        ></app-dropdown>
        <app-input
          [ngClass]="{ hidden: !entriesForm.value.isHabitat }"
          [formGroup]="getGroup(index)"
          [formName]="'syntaxa'"
          [label]="'BOOK_SYNTAXA'"
          [style.maxWidth]="'100%'"
        ></app-input>
      </div>
      <div class="form-row" style="margin-top: 20px">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'xegsa'"
          [label]="'BOOK_XEGSA'"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'yegsa'"
          [label]="'BOOK_YEGSA'"
        ></app-input>
      </div>
      <div class="form-row" style="margin-top: 20px">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'latitude'"
          [label]="'BOOK_LATITUDE'"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'longitude'"
          [label]="'BOOK_LONGITUDE'"
        ></app-input>
      </div>

      <br />
      <div class="map-container">
        <app-map
          [idOfMap]="entriesForm.value.groupIndex"
          [coordinates]="entryCoordinates(entriesForm.value.groupIndex)"
          (markerMoved)="onMarkerMoved($event, entriesForm.value.groupIndex)"
          [disabled]="disabledControls"
        ></app-map>
      </div>
      <br />

      <div class="form-row form-3" style="margin-top: 20px">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'altitude'"
          [label]="'BOOK_ALTITUDE'"
        ></app-input>
        <app-dropdown
          [dropdownList]="gridCells"
          [formGroup]="getGroup(index)"
          [formName]="'gridCell'"
          [label]="'GRID_CELL'"
          [preDefinedId]="getGroup(index).controls['gridCell'].value"
        ></app-dropdown>
      </div>
      <div class="form-row" style="margin-top: 20px">
        <app-dropdown
          [dropdownList]="regionalUnits"
          [formGroup]="getGroup(index)"
          [formName]="'regionalUnit'"
          [label]="'REGIONAL_UNIT'"
          [preDefinedId]="getGroup(index).controls['regionalUnit'].value"
        ></app-dropdown>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'region'"
          [label]="'BOOK_REGION'"
        >
        </app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'location'"
          [label]="'BOOK_LOCATION'"
        ></app-input>
      </div>
      <div class="form-row form-3" style="margin-top: 20px">
        <app-dropdown
          [dropdownList]="naturaCodes"
          [formGroup]="getGroup(index)"
          [formName]="'naturaCode'"
          [label]="'BOOK_NATURACODE'"
          [preDefinedId]="getGroup(index).controls['naturaCode'].value"
        ></app-dropdown>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'samplingMethod'"
          [label]="'BOOK_SAMPLING_METHOD'"
        ></app-input>
        <app-dropdown
          [dropdownList]="methodReliability"
          [formGroup]="getGroup(index)"
          [formName]="'methodReliability'"
          [label]="'BOOK_METHOD_RELIABILITY'"
          [preDefinedId]="getGroup(index).controls['methodReliability'].value"
        ></app-dropdown>
      </div>
      <div class="form-row" style="margin-top: 20px">
        <app-dropdown
          [dropdownList]="sourceReliability"
          [formGroup]="getGroup(index)"
          [formName]="'sourceReliability'"
          [label]="'BOOK_SOURCE_RELIABILITY'"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['sourceReliability'].value"
        >
        </app-dropdown>
        <app-dropdown
          [dropdownList]="positionAccuracy"
          [formGroup]="getGroup(index)"
          [formName]="'positionAccuracy'"
          [label]="'BOOK_POSITION_ACCURACY'"
          [preDefinedId]="getGroup(index).controls['positionAccuracy'].value"
        >
        </app-dropdown>

        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'floristicRegion'"
          [label]="'BOOK_FLORISTIC_REGION'"
          [ngClass]="{
            disabled: formGroup.controls['speciesCategory'].value !== 'Χλωρίδα'
          }"
        >
        </app-input>
      </div>
      <div class="form-row" style="margin-top: 20px">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'seaRegion'"
          [label]="'BOOK_SEA_REGION'"
        >
        </app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'waterBody'"
          [label]="'BOOK_WATER_BODY'"
          [ngClass]="{
            disabled:
              formGroup.controls['speciesCategory'].value !== 'Ιχθυοπανίδα'
          }"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'releveCode'"
          [label]="'BOOK_RELEVE_CODE'"
          [ngClass]="{
            disabled: formGroup.controls['speciesCategory'].value !== 'Χλωρίδα'
          }"
        ></app-input>
      </div>
      <div class="form-row" style="margin-top: 20px">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'conservationStatus'"
          [label]="'BOOK_CONSERVATION_STATUS'"
          [ngClass]="{
            disabled: formGroup.controls['speciesCategory'].value !== 'Χλωρίδα'
          }"
        ></app-input>
        <app-dropdown
          [dropdownList]="abudance"
          [formGroup]="getGroup(index)"
          [formName]="'abundance'"
          [label]="'BOOK_ABUDANCE'"
          [preDefinedId]="getGroup(index).controls['abundance'].value"
        ></app-dropdown>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfPeople'"
          [label]="'BOOK_NUMBER_OF_PEOPLE'"
        ></app-input>
      </div>
      <div class="form-row" style="margin-top: 20px">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'minNumberOfPeople'"
          [label]="'BOOK_MIN_NUMBER_OF_PEOPLE'"
        ></app-input>

        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'maxNumberOfPeople'"
          [label]="'BOOK_MAX_NUMBER_OF_PEOPLE'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'numberOfPairs'"
          [label]="'BOOK_NUMBER_OF_PAIRS'"
        ></app-input>
      </div>
      <div class="form-row form-3" style="margin-top: 20px">
        <app-dropdown
          [dropdownList]="measurementUnit"
          [formGroup]="getGroup(index)"
          [formName]="'measurementUnit'"
          [label]="'BOOK_MEASUREMENT_UNIT'"
          [preDefinedId]="getGroup(index).controls['measurementUnit'].value"
        ></app-dropdown>
        <app-dropdown
          [dropdownList]="populationIndicator"
          [formGroup]="getGroup(index)"
          [formName]="'populationIndicator'"
          [label]="'BOOK_POPULATION_INDICATOR'"
          [preDefinedId]="getGroup(index).controls['populationIndicator'].value"
        ></app-dropdown>
      </div>
      <div class="form-row-single delete-container">
        <app-button
          (buttonClicked)="removeEntries(entriesForm.value.groupIndex)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== entries.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      style="margin-top: 20px"
      [btnText]="'BOOK_ADD_RECORD_HABITAT_SPECIES'"
      (buttonClicked)="addNewEntries()"
      [hidden]="
        disabledControls || formGroup.controls['speciesCategory'].value === ''
      "
    ></app-button>
    <div
      *ngIf="formGroup.controls['speciesCategory'].value === ''"
      class="disclaimer"
    >
      {{ "BOOK_DISCLAIMER" | translate }}
    </div>
  </div>
</ng-container>
