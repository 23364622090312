<div appDragNDrop class="container" (fileDropped)="chooseSpreadsheet($event)">
  <div class="download-container">
    <app-button
      [btnText]="'DOWNLOAD_SPREADSHEET'"
      (buttonClicked)="downloadSpreadsheet()"
      [isDisabled]="loading"
    ></app-button>
  </div>
  <div class="input-container">
    <div class="input">
      <input
        #uploader
        type="file"
        id="files"
        accept=".xlsx"
        (change)="chooseSpreadsheet($event)"
        hidden
      />
      <label class="input-label" for="files">
        <img *ngIf="fileName === ''" src="assets/images/upload.svg" />
        <span class="input-label-no-break"
          >{{ fileName === "" ? ("DRAG_OR" | translate) : "" }}
        </span>
        <span class="input-label-clickable">{{
          fileName === "" ? ("CHOOSE_FILE" | translate) + (' (.xslx, .xls)') : fileName
        }}</span>
        <div *ngIf="loading" class="spinner-border small" role="status">
          <span class="sr-only"></span>
        </div>
        <span *ngIf="uploaded" class="tick-mark pi pi-check-circle"></span>
        <span *ngIf="notUploaded" class="x-mark pi pi-times-circle"></span>
        <span
          *ngIf="uploadedWithErrors"
          class="warning-mark pi pi-exclamation-triangle"
        ></span>
      </label>
    </div>
    <div
      *ngIf="infoMsg !== ''"
      class="info-container"
      [innerHTML]="infoMsg"
    ></div>
  </div>
  <div class="upload-container">
    <app-button
      [btnText]="'UPLOAD_FILE'"
      (buttonClicked)="uploadSpreadsheet()"
      [isDisabled]="disableUpload || loading"
    ></app-button>
  </div>
</div>
