import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';
import {
  IBookReferenceTypeDto,
  IBookRelativeAbudanceDto,
  IBookSpeciesHabitatSpreadDto,
  IBookSpeciesHabitatSpreadEntryDto,
  IMethodReliabilityDto,
  ISourceReliabilityDto,
  ISpeciesGroupDto,
  ISpeciesInfoDto,
  ISpeciesSubGroupDto,
} from 'src/app/core/dtos/bookSpeciesHabitatSpread.dto';
import { IHabitatType } from 'src/app/core/dtos/habitat.dto';
import { CommonService } from 'src/app/core/services/common.service';
import {
  IReferenceIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { IBookFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BookHabitatsSpeciesService {
  constructor(private http: HttpClient, private common: CommonService) {}

  public getBooks(
    dataVersion?: string
  ): Observable<IBookSpeciesHabitatSpreadDto[]> {
    const url = environment.endpoint + 'Book/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IBookSpeciesHabitatSpreadDto[]>(url, {
      params: params,
    });
  }

  public getBookById(id: string): Observable<IBookSpeciesHabitatSpreadDto> {
    const url = environment.endpoint + 'Book';
    const params = new HttpParams().set('id', id);
    return this.http.get<IBookSpeciesHabitatSpreadDto>(url, { params: params });
  }

  public putBook(
    id: string,
    payload: IBookSpeciesHabitatSpreadDto
  ): Observable<IReferenceIdResponse> {
    const url = environment.endpoint + 'Book';
    const params = new HttpParams().set('id', id);
    return this.http.put<IReferenceIdResponse>(url, payload, {
      params: params,
    });
  }

  public AddBookSpeciesAndHabitatsSpread(
    payload: IBookSpeciesHabitatSpreadDto
  ): Observable<IReferenceIdResponse> {
    const url = environment.endpoint + 'Book';
    return this.http.post<IReferenceIdResponse>(url, payload);
  }

  public addBatchBookSpeciesAndHabitatsSpread(
    payload: IBookSpeciesHabitatSpreadDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Book/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public addBookFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Book/files';
    return this.http.post<void>(url, payload);
  }

  public removeBookFiles(payload: IBookFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Book/remove-files';
    return this.http.post<void>(url, payload);
  }

  public GetAllSpeciesIds(dataVersion?: string): Observable<string[]> {
    const url = environment.endpoint + 'Book/speciesIds';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<string[]>(url, { params: params });
  }

  public async GetSpeciesInfo(
    speciesId: string,
    dataVersion?: string
  ): Promise<ISpeciesInfoDto> {
    const url = environment.endpoint + 'Book/speciesById';
    const params = new HttpParams()
      .set('speciesId', speciesId ?? '')
      .set('DataVersion', dataVersion ?? '');
    return await firstValueFrom(
      this.http.get<ISpeciesInfoDto>(url, { params: params })
    );
  }

  public getReferenceTypes(
    dataVersion?: string
  ): Observable<IBookReferenceTypeDto[]> {
    const url = environment.endpoint + 'Book/referenceTypes';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IBookReferenceTypeDto[]>(url, { params: params });
  }

  public getRelativeAbudances(
    dataVersion?: string
  ): Observable<IBookRelativeAbudanceDto[]> {
    const url = environment.endpoint + 'Book/relativeAbudances';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IBookRelativeAbudanceDto[]>(url, { params: params });
  }

  public getMethodReliabilities(
    dataVersion?: string
  ): Observable<IMethodReliabilityDto[]> {
    const url = environment.endpoint + 'Book/methodReliabilities';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMethodReliabilityDto[]>(url, { params: params });
  }

  public getSourceReliabilities(
    dataVersion?: string
  ): Observable<ISourceReliabilityDto[]> {
    const url = environment.endpoint + 'Book/sourceReliabilities';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ISourceReliabilityDto[]>(url, { params: params });
  }

  public getSpeciesGroups(
    dataVersion?: string
  ): Observable<ISpeciesGroupDto[]> {
    const url = environment.endpoint + 'Book/speciesGroups';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ISpeciesGroupDto[]>(url, { params: params });
  }

  public getSpeciesSubGroups(
    dataVersion?: string
  ): Observable<ISpeciesSubGroupDto[]> {
    const url = environment.endpoint + 'Book/speciesSubGroups';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<ISpeciesSubGroupDto[]>(url, { params: params });
  }

  public initBibliographyFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      referenceId: fb.control(''),
      ergoEpoptiaFasiErgou: fb.control('', [Validators.required]),
      speciesCategory: fb.control('', [Validators.required]),
      referenceTypeCode: fb.control('', [Validators.required]),
      fullReference: fb.control('', [Validators.required]),
      shortReference: fb.control('', [Validators.required]),
      articleTitle: fb.control('', [Validators.required]),
      authors: fb.control(''),
      publicationYear: fb.control(''),
      fromYearTo: fb.control(''),
      magazine: fb.control(''),
      book: fb.control(''),
      issn: fb.control(''),
      isbn: fb.control(''),
      doi: fb.control(''),
      congress: fb.control(''),
      publisher: fb.control(''),
      editor: fb.control(''),
      url: fb.control(''),
      downloadedOn: fb.control(''),
      entries: fb.array([]),
    });
  }

  public initEntriesFormGroup(
    fb: FormBuilder,
    groupIndex?: number
  ): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      spRef_ID: fb.control(''),
      speciesId: fb.control('', [Validators.required]),
      speciesIdOther: fb.control(''),
      habitatId: fb.control('', [Validators.required]),
      syntaxa: fb.control(''),
      xegsa: fb.control('', [
        this.common.latLongValidator('xegsa', 'yegsa', true),
      ]),
      yegsa: fb.control('', [
        this.common.latLongValidator('xegsa', 'yegsa', true),
      ]),
      latitude: fb.control('', [
        this.common.latLongValidator('latitude', 'longitude'),
      ]),
      longitude: fb.control('', [
        this.common.latLongValidator('latitude', 'longitude'),
      ]),
      altitude: fb.control('', [Validators.min(-10), Validators.max(2800)]),
      gridCell: fb.control(''),
      shapefile: fb.control(''),
      regionalUnit: fb.control(''),
      region: fb.control(''),
      location: fb.control(''),
      naturaCode: fb.control(''),
      samplingMethod: fb.control(''),
      methodReliability: fb.control(''),
      sourceReliability: fb.control('', [Validators.required]),
      positionAccuracy: fb.control(''),
      floristicRegion: fb.control(''),
      seaRegion: fb.control(''),
      waterBody: fb.control(''),
      releveCode: fb.control(''),
      conservationStatus: fb.control(''),
      abundance: fb.control(''),
      numberOfPeople: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      minNumberOfPeople: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      maxNumberOfPeople: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      numberOfPairs: fb.control('', [Validators.min(0)]),
      measurementUnit: fb.control(''),
      populationIndicator: fb.control(''),
      groupIndex: fb.control(
        groupIndex !== undefined ? groupIndex.toString() : ''
      ),
      isSpecies: fb.control(false),
      isHabitat: fb.control(false),
      annexII: fb.control(''),
      annexIV: fb.control(''),
      annexV: fb.control(''),
    });
  }

  public prepareBookRequest(
    bookHSForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctHabitats: IHabitatType[] = []
  ): IBookSpeciesHabitatSpreadDto {
    const bookHS: IBookSpeciesHabitatSpreadDto = {};
    const entries = bookHSForm.controls['entries'] as FormArray;

    bookHS.referenceId = bookHSForm.controls['referenceId'].value;
    bookHS.downloadedOn = this.common.formatDate(
      bookHSForm?.value.downloadedOn
    );
    bookHS.ergoEpoptiaFasiErgou = bookHSForm?.value.ergoEpoptiaFasiErgou;
    bookHS.speciesCategory = bookHSForm?.value.speciesCategory;
    bookHS.articleTitle = bookHSForm?.value.articleTitle;
    bookHS.authors = bookHSForm?.value.authors;
    bookHS.book = bookHSForm?.value.book;
    bookHS.congress = bookHSForm?.value.congress;
    bookHS.editor = bookHSForm?.value.editor;
    bookHS.fromYearTo = bookHSForm?.value.fromYearTo;
    bookHS.fullReference = bookHSForm?.value.fullReference;
    bookHS.isbn = bookHSForm?.value.isbn;
    bookHS.issn = bookHSForm?.value.issn;
    bookHS.doi = bookHSForm?.value.doi;
    bookHS.magazine = bookHSForm?.value.magazine;
    bookHS.publicationYear = bookHSForm?.value.publicationYear;
    bookHS.publisher = bookHSForm?.value.publisher;
    bookHS.referenceTypeCode = bookHSForm?.value.referenceTypeCode;
    bookHS.shortReference = bookHSForm?.value.shortReference;
    bookHS.url = bookHSForm?.value.url;
    bookHS.entries = [];

    if (bookHS.speciesCategory === 'Θαλάσσια είδη και ΤΟ') {
      bookHS.speciesCategory = 'Θαλάσσια';
    }

    if (fromSpreadsheet) {
      const timestamp = Date.now().toString();
      bookHS.referenceId = timestamp + '_' + bookHSForm?.value.referenceId;
    }

    bookHSForm?.value.entries.forEach((x: any, index: number) => {
      const entry: IBookSpeciesHabitatSpreadEntryDto = {
        spRef_ID: x.spRef_ID,
        abundance: x.abundance,
        conservationStatus: x.conservationStatus,
        floristicRegion: x.floristicRegion,
        gridCell: x.gridCell,
        habitatId: x.habitatId,
        latitude: (x.latitude + '').replace(',', '.'),
        longitude: (x.longitude + '').replace(',', '.'),
        altitude: (x.altitude + '').replace(',', '.'),
        location: x.location,
        measurementUnit: x.measurementUnit,
        methodReliability: x.methodReliability,
        minNumberOfPeople:
          x.minNumberOfPeople != '' ? parseInt(x.minNumberOfPeople) : undefined,
        maxNumberOfPeople:
          x.maxNumberOfPeople != '' ? parseInt(x.maxNumberOfPeople) : undefined,
        naturaCode: x.naturaCode,
        numberOfPairs:
          x.numberOfPairs != '' ? parseInt(x.numberOfPairs) : undefined,
        numberOfPeople:
          x.numberOfPeople != '' ? parseInt(x.numberOfPeople) : undefined,
        populationIndicator: x.populationIndicator,
        positionAccuracy: x.positionAccuracy,
        regionalUnit: x.regionalUnit,
        shapefile: x.shapefile,
        region: x.region,
        releveCode: x.releveCode,
        samplingMethod: x.samplingMethod,
        seaRegion: x.seaRegion,
        sourceReliability: x.sourceReliability,
        waterBody: x.waterBody,
        speciesId: x.speciesId,
        speciesIdOther: x.speciesIdOther,
        syntaxa: x.syntaxa,
        xegsa: x.xegsa,
        yegsa: x.yegsa,
      };

      if (fromSpreadsheet) {
        const habType = ctHabitats.find(
          (item) => item.code === x.habitatId?.toString()
        );
        if (habType != null) {
          entry.habitatId = habType.id.toString();
        }
      } else {
        entry.spRef_ID =
          bookHS.referenceId + '_' + (index + 1).toString().padStart(3, '0');
      }

      if (bookHS.speciesCategory !== 'Χλωρίδα') {
        entry.floristicRegion = '';
        entry.releveCode = '';
        entry.conservationStatus = '';
      }

      if (bookHS.speciesCategory !== 'Ιχθυοπανίδα') {
        entry.waterBody = '';
      }

      if (bookHS.speciesCategory === 'Τύποι Οικοτόπων') {
        entry.speciesId = '';
        entry.speciesIdOther = '';
      }

      if (bookHS.speciesCategory !== 'Τύποι Οικοτόπων') {
        entry.habitatId = '';
        entry.syntaxa = '';
      }

      bookHS.entries?.push(entry);
    });

    if (bookHS.entries.length === 0) {
      bookHS.entries = undefined;
    }

    return bookHS;
  }
}
