<div class="custom-modal-container">
  <div class="title">
    {{ "EXPORT_TITLE" | translate }}
  </div>
  <div class="checkboxes-container">
    <label class="btn btn-secondary custom-checkbox" ngbButtonLabel>
      <input type="checkbox" [(ngModel)]="xlsxFile" />
      {{ "EXPORT_XLSX_FILE" | translate }}
    </label>
    <label class="btn btn-secondary custom-checkbox" ngbButtonLabel>
      <input type="checkbox" [(ngModel)]="zipFile" />
      {{ "EXPORT_ZIP_FILE" | translate }}
    </label>
  </div>
  <div class="buttons-container">
    <app-button
      class="cancel-btn"
      (buttonClicked)="cancelExport()"
      [btnText]="'CONFIRM_MODAL_NO'"
    ></app-button>
    <app-button
      class="export-btn"
      (buttonClicked)="confirmExport()"
      [isDisabled]="!xlsxFile && !zipFile"
      [btnText]="'EXPORT_BUTTON'"
      [style.--btnBgColor]="'var(--color-header)'"
    ></app-button>
  </div>
</div>
