<div [formGroup]="formGroup" class="input-container">
  <label class="label-text" *ngIf="label"
    >{{ label | translate }}<span *ngIf="required">*</span></label
  >
  <textarea
    class="form-control"
    [placeholder]="placeholder"
    aria-label=""
    aria-describedby="basic-addon1"
    [formControlName]="formName"
    [mask]="mask !== undefined ? mask : ''"
    [suffix]="suffix !== undefined ? suffix : ''"
    [showMaskTyped]="showMaskTyped !== undefined ? showMaskTyped : true"
    [ngClass]="{ disabled: disabled }"
    [disabled]="disabled"
    [ngClass]="{ 'error-status': touched && hasErrors }"
  ></textarea>
  <div class="error-message">
    <ng-container *ngIf="touched && hasErrors">
      <ng-container
        *ngIf="
          errors != null && errors['required'] !== null && errors['required']
        "
      >
        {{ "FIELD_REQUIRED_ERROR" | translate }}.
      </ng-container>
    </ng-container>
  </div>
</div>
