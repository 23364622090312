<div class="close-modal" (click)="activeModal.close()">
  <img src="assets/images/x.svg" />
</div>
<div class="upload-files-modal">
  <div class="title">{{ "UPLOAD_FILES" | translate }}</div>
  <div class="subtitle">{{ "UPLOAD_FILES_SUBTITLE" | translate }}</div>
  <div class="content">
    <div class="upload-file-id">
      <app-input
        [type]="'number'"
        [formGroup]="form"
        [formName]="'uploadId'"
        [label]="'UPLOAD_FILE_ID'"
      ></app-input>
    </div>
    <app-files-container
      (attachedFiles)="files = $event; checkFiles()"
      [onlyZip]="true"
    ></app-files-container>

    <div class="errors">
      <div class="error" *ngFor="let error of errors">
        {{ error | translate }}
      </div>
    </div>

    <div class="upload-container">
      <app-button
        [btnText]="'UPLOAD_FILE_BUTTON'"
        (buttonClicked)="uploadFiles()"
        [isDisabled]="cannotUpload()"
      ></app-button>
    </div>
  </div>
</div>
