import { Component } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { URL_PROTOCOLS } from 'src/app/URLS';

@Component({
  selector: 'app-land-page',
  templateUrl: './land-page.component.html',
  styleUrls: ['./land-page.component.scss'],
})
export class LandPageComponent {
  public protocols = URL_PROTOCOLS;

  constructor(
    public auth: AuthService
  ) {
  }
}
