<div class="modal-container">
  <div class="img">
    <img src="assets/images/green_tick.svg" />
  </div>
  <div class="title">
    {{
      successfullEdit
        ? ("SUCCESSFULL_ΕDIT" | translate)
        : ("SUCCESSFULL_IMPORT" | translate)
    }}
  </div>
  <div class="description">
    <ng-container *ngIf="reference">
      {{
        successfullEdit
          ? ("SUCCESSFULL_REFERENCE_EDIT_DESCRIPTION" | translate : param)
          : ("SUCCESSFULL_REFERENCE_DESCRIPTION" | translate : param)
      }}
    </ng-container>
    <ng-container *ngIf="!reference">
      {{
        successfullEdit
          ? param.isRandom
            ? ("SUCCESSFULL_EDIT_DESCRIPTION_RANDOM" | translate : param)
            : ("SUCCESSFULL_EDIT_DESCRIPTION" | translate : param)
          : param.isRandom
          ? ("SUCCESSFULL_IMPORT_DESCRIPTION_RANDOM" | translate : param)
          : ("SUCCESSFULL_IMPORT_DESCRIPTION" | translate : param)
      }}
    </ng-container>
  </div>
  <div class="btn">
    <app-button
      class="completion-btn"
      (buttonClicked)="activeModal.close()"
      [btnText]="'OK_CONTINUE'"
      [style.--btnBgColor]="'var(--color-header)'"
      [style.--btnWidth]="'200px'"
    ></app-button>
  </div>
</div>
