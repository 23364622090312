<div class="table">
  <p-table
    #dt1
    dataKey="id"
    [columns]="selectedColumns"
    [value]="records"
    [lazy]="true"
    [tableStyle]="{
      'min-width': '50rem'
    }"
    [totalRecords]="totalRecords"
    [resizableColumns]="true"
    columnResizeMode="expand"
    [scrollable]="true"
    scrollHeight="calc(100vh - 450px)"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Εμφάνιση {first} έως {last} από {totalRecords} εγγραφές"
    [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="allCols"
    (onLazyLoad)="loadData($event)"
  >
    <!-- <ng-template pTemplate="caption">
      <div
        class="d-flex justify-content-between caption"
        [style]="{ padding: '1rem 1rem' }"
      >
        <p-multiSelect
          [options]="cols"
          [(ngModel)]="selectedColumns"
          optionLabel="header"
          selectedItemsLabel="{0} επιλεγμένες στήλες"
          [style]="{ 'min-width': '200px' }"
          placeholder="Επιλέξτε στήλες"
        ></p-multiSelect>
      </div>
    </ng-template> -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th
            style="min-width: 100px"
            pSortableColumn="{{ col?.propertyName }}"
            [pSortableColumnDisabled]="col?.noSort"
          >
            {{ col?.header | translate }}
            <p-sortIcon
              field="{{ col?.propertyName }}"
              *ngIf="!col?.noSort"
            ></p-sortIcon>
            <ng-container *ngIf="!col?.noFilter">
              <ng-container *ngIf="col?.searchDataType !== 5; else list">
                <p-columnFilter
                  type="{{
                    col?.searchDataType === 0
                      ? 'numeric'
                      : col?.searchDataType === 2
                      ? 'boolean'
                      : col?.searchDataType === 3
                      ? 'date'
                      : 'text'
                  }}"
                  field="{{ col?.propertyName }}"
                  display="menu"
                  [matchModeOptions]="
                    col?.searchDataType === 0
                      ? numberMatchModeOptions
                      : col?.searchDataType === 1
                      ? stringMatchModeOptions
                      : col?.searchDataType === 2
                      ? []
                      : col?.searchDataType === 3
                      ? dateMatchModeOptions
                      : col?.searchDataType === 4
                      ? timeMatchModeOptions
                      : []
                  "
                ></p-columnFilter>
              </ng-container>
              <ng-template #list>
                <p-columnFilter
                  field="{{ col?.propertyName }}"
                  matchMode="in"
                  [showMenu]="false"
                  (click)="$event.stopPropagation()"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <p-multiSelect
                      [ngModel]="value"
                      appendTo="body"
                      [options]="col?.data"
                      placeholder="Any"
                      (onChange)="filter($event.value)"
                      optionLabel="name"
                      [maxSelectedLabels]="1"
                      [selectedItemsLabel]="'{0} Επιλογές'"
                    >
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <span class="ml-1 mt-1">{{ option.name }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </ng-template>
            </ng-container>
          </th>
        </ng-container>
        <th
          pFrozenColumn
          alignFrozen="right"
          style="min-width: 150px; text-align: center"
        >
          {{ "TABLE_OPTIONS" | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          {{
            col.searchDataType === searchDataType.Date
              ? (record[col.propertyName] | date : "dd/MM/yyyy")
              : record[col.propertyName]
          }}
        </td>
        <td pFrozenColumn alignFrozen="right">
          <div class="action-icons-container">
            <img
              src="assets/images/eye.svg"
              class="action-icon eye"
              ngbTooltip="{{ 'PREVIEW' | translate }}"
              container="body"
              (click)="
                openModal(
                  'read',
                  isReference ? record.referenceId : record.samplingCode,
                  record.sampleId ? record.sampleId : -1
                )
              "
            />
            <img
              src="assets/images/pencil.svg"
              class="action-icon pencil"
              ngbTooltip="{{ 'EDIT' | translate }}"
              container="body"
              (click)="
                openModal(
                  'update',
                  isReference ? record.referenceId : record.samplingCode,
                  record.sampleId ? record.sampleId : -1
                )
              "
            />
            <!-- Edit/Delete are not implemented yet -->
            <!-- <img
                src="assets/images/trash.svg"
                class="action-icon trash"
                ngbTooltip="{{ 'DELETE' | translate }}"
              /> -->
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
