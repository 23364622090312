import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValid, parseISO } from 'date-fns';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // implementation of the validation

    if (
      !(control.value && (control.value as string).match(/^\d{2}\d{2}\d{4}$/))
    ) {
      return null;
    }

    //let parts = (control.value as string).split('/');
    let day = (control.value as string).substring(0, 2);
    let month = (control.value as string).substring(2, 4);
    let year = (control.value as string).substring(4);
    if (!isValid(parseISO(year + '-' + month + '-' + day)))
      return {
        invalidDate: true,
      };

    return null;
  };
}
