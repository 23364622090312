import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  IChlorisImportanceDto,
  IChlorisImpactDto,
  IChlorisPressureDto,
  IChlorisSexDto,
  IChlorisVitalityDto,
  IChlorisDto,
  IChlorisStratificationDto,
  IChlorisUnitDto,
  IChlorisGeneral,
  IChlorisExpo,
  IChlorisSpecies,
} from 'src/app/core/dtos/chloris.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ISampleIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { environment } from 'src/environments/environment';
import { SamplesService } from './samples.service';
import { IFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';

@Injectable({
  providedIn: 'root',
})
export class ChlorisService {
  constructor(
    private http: HttpClient,
    public common: CommonService,
    private sampleService: SamplesService
  ) {}

  public getChloris(dataVersion?: string): Observable<IChlorisDto[]> {
    const url = environment.endpoint + 'Chloris/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisDto[]>(url, { params: params });
  }

  public getChlorisById(id: number): Observable<IChlorisDto> {
    const url = environment.endpoint + 'Chloris';
    const params = new HttpParams().set('id', id);
    return this.http.get<IChlorisDto>(url, { params: params });
  }

  public putChloris(
    id: number,
    payload: IChlorisDto
  ): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Chloris';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public getChlorisImportances(
    dataVersion?: string
  ): Observable<IChlorisImportanceDto[]> {
    const url = environment.endpoint + 'Chloris/importance';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisImportanceDto[]>(url, { params: params });
  }

  public getChlorisImpacts(
    dataVersion?: string
  ): Observable<IChlorisImpactDto[]> {
    const url = environment.endpoint + 'Chloris/impacts';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisImpactDto[]>(url, { params: params });
  }

  public getChlorisPressures(
    dataVersion?: string
  ): Observable<IChlorisPressureDto[]> {
    const url = environment.endpoint + 'Chloris/preassures';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisPressureDto[]>(url, { params: params });
  }

  public getChlorisVitality(
    dataVersion?: string
  ): Observable<IChlorisVitalityDto[]> {
    const url = environment.endpoint + 'Chloris/vitality';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisVitalityDto[]>(url, { params: params });
  }

  public getChlorisSex(dataVersion?: string): Observable<IChlorisSexDto[]> {
    const url = environment.endpoint + 'Chloris/sex';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisSexDto[]>(url, { params: params });
  }

  public getSpecies(dataVersion?: string): Observable<IChlorisSpecies[]> {
    const url = environment.endpoint + 'Chloris/species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisSpecies[]>(url, { params: params });
  }

  public getChlorisUnits(dataVersion?: string): Observable<IChlorisUnitDto[]> {
    const url = environment.endpoint + 'Chloris/units';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisUnitDto[]>(url, { params: params });
  }

  public getChlorisStratifications(
    dataVersion?: string
  ): Observable<IChlorisStratificationDto[]> {
    const url = environment.endpoint + 'Chloris/stratifications';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisStratificationDto[]>(url, { params: params });
  }

  public getRelief(dataVersion?: string): Observable<IChlorisGeneral[]> {
    const url = environment.endpoint + 'Chloris/relief';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisGeneral[]>(url, { params: params });
  }

  public getSoil(dataVersion?: string): Observable<IChlorisGeneral[]> {
    const url = environment.endpoint + 'Chloris/soil';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisGeneral[]>(url, { params: params });
  }

  public getDepth(dataVersion?: string): Observable<IChlorisGeneral[]> {
    const url = environment.endpoint + 'Chloris/depth';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisGeneral[]>(url, { params: params });
  }

  public getQuadrat(dataVersion?: string): Observable<IChlorisGeneral[]> {
    const url = environment.endpoint + 'Chloris/quadrat';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisGeneral[]>(url, { params: params });
  }

  public getExpo(dataVersion?: string): Observable<IChlorisExpo[]> {
    const url = environment.endpoint + 'Chloris/exposition';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IChlorisExpo[]>(url, { params: params });
  }

  public addChloris(payload: IChlorisDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Chloris';
    return this.http.post<ISampleIdResponse>(url, payload);
  }

  public addBatchChloris(
    payload: IChlorisDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Chloris/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public addFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Chloris/files';
    return this.http.post<void>(url, payload);
  }

  public removeFiles(payload: IFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Chloris/remove-files';
    return this.http.post<void>(url, payload);
  }

  public initChlorisFormGroup(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);

    sample = fb.group({
      ...sample.controls,
      habitat: fb.control(''),
      author: fb.control(''),
      species: fb.control('', [Validators.required]),
      substrate: fb.control(''),
      parII: fb.control(false),
      parIV: fb.control(false),
      parV: fb.control(false),
      relief: fb.control('', [Validators.required]),
      altitudeText: fb.control(''),
      soil: fb.control('', [Validators.required]),
      a0: fb.control('', [Validators.required]),
      exposit: fb.control('', [Validators.required]),
      slope: fb.control(''),
      threats: fb.control(''),
      unit: fb.control('', [Validators.required]),
      strat: fb.control('', [Validators.required]),
      method: fb.control('', [Validators.required]),
      functions: fb.array([]),
      populations: fb.array([]),
      pressures: fb.array([], { validators: [this.uniquePressures()] }),
      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
      samId: fb.control(''),
      ergoEpoptiaFasiErgou: fb.control(''),
      otherSpecies: fb.control(''),
      epoptiaOneId: fb.control(''),
      epoptiaOneComments: fb.control(''),
      quality: fb.control(''),
      qualityTrend: fb.control(''),
      qualityFix: fb.control(''),
      area: fb.control(''),
      areaTrend: fb.control(''),
      areaFix: fb.control(''),
      shapefile: fb.control(''),
      populationObservations: fb.control(''),
    });

    sample.removeControl('time');

    return sample;
  }

  public uniquePressures(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const pressureValues = (control as FormArray).controls.map(
        (group: AbstractControl) => group.get('pressuresAndThreatsId')?.value
      );
      const uniqueValues = new Set(pressureValues);

      if (pressureValues.length !== uniqueValues.size) {
        return { duplicatePressure: true };
      }

      return null;
    };
  }

  public initFunctionsFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      structure: fb.control('', [Validators.required]),
      statusEx: fb.control(false),
      statusMed: fb.control(false),
      statusBad: fb.control(false),
    });
  }

  public initPopulationsFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      adult_K1: fb.control('', [Validators.required, Validators.min(0)]),
      adult_K2: fb.control('', [Validators.required, Validators.min(0)]),
      adult_A: fb.control('', [Validators.required, Validators.min(0)]),
      young_A: fb.control('', [Validators.required, Validators.min(0)]),
      young_K2: fb.control('', [Validators.required, Validators.min(0)]),
      veget_A: fb.control('', [Validators.required, Validators.min(0)]),
      veget_K2: fb.control('', [Validators.required, Validators.min(0)]),
      height: fb.control('', [Validators.required]),
      vit: fb.control('', [Validators.required]),
      sex: fb.control('', [Validators.required]),
      structure: fb.control('', [Validators.required]),
      comment: fb.control(''),
      shapefile: fb.control(''),
      obsId: fb.control(''),
      aApolygon: fb.control(''),
      samplingSurface: fb.control(''),
      populationArea: fb.control('', [Validators.min(0)]),
      clusterMin: fb.control('', [Validators.min(0)]),
      clusterMax: fb.control('', [Validators.min(0)]),
    });
  }

  public initThreatsFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      pressuresAndThreatsId: fb.control('', [Validators.required]),
      explanation: fb.control(''),
      importance: fb.control(''),
      cover: fb.control('', [Validators.min(0), Validators.max(100)]),
      timeCharacter: fb.control(''),
      target: fb.control(''),
      cover_Wh_Ma_Mi: fb.control(''),
      foreignSpecies: fb.control(''),
    });
  }

  public prepareChlorisRequest(
    chlorisForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctSpecies: IChlorisSpecies[] = [],
    editMode = false
  ): IChlorisDto {
    const chloris: IChlorisDto = {};
    const pressures = chlorisForm.controls['pressures'] as FormArray;
    const functions = chlorisForm.controls['functions'] as FormArray;
    const populations = chlorisForm.controls['populations'] as FormArray;

    chloris.date = this.common.formatDate(chlorisForm?.value.date);
    chloris.naturaCode = chlorisForm?.value.naturaCode;
    chloris.samplingCode = chlorisForm?.value.samplingCode;
    chloris.financialInstrument = chlorisForm?.value.financialInstrument;
    chloris.prefecture = chlorisForm?.value.prefecture;
    chloris.researcher = chlorisForm?.value.researcher;
    chloris.locality = chlorisForm?.value.locality;
    chloris.observations = chlorisForm?.value.observations;
    chloris.file = chlorisForm?.value.file;
    chloris.gridCell = chlorisForm?.value.gridCell;
    chloris.photo = chlorisForm?.value.photo;
    chloris.habitat = chlorisForm?.value.habitat;
    chloris.author = chlorisForm?.value.author;
    chloris.pointX = chlorisForm?.value.lat;
    chloris.pointY = chlorisForm?.value.long;
    chloris.substrate = chlorisForm?.value.substrate;
    chloris.parii = chlorisForm?.value.parII;
    chloris.pariv = chlorisForm?.value.parIV;
    chloris.parv = chlorisForm?.value.parV;
    chloris.relief = chlorisForm?.value.relief;
    chloris.soil = chlorisForm?.value.soil;
    chloris.a0 = chlorisForm?.value.a0;
    chloris.exposit = chlorisForm?.value.exposit;
    chloris.slope = chlorisForm?.value.slope;
    chloris.altitudeText = chlorisForm?.value.altitudeText;
    chloris.unit = chlorisForm?.value.unit;
    chloris.strat = chlorisForm?.value.strat;
    chloris.method = chlorisForm?.value.method;
    chloris.threats = chlorisForm?.value.threats;
    chloris.protocolId = Protocols.Chloris;
    chloris.ergoEpoptiaFasiErgou = chlorisForm?.value.ergoEpoptiaFasiErgou;
    chloris.epoptiaOneId = chlorisForm?.value.epoptiaOneId;
    chloris.epoptiaOneComments = chlorisForm?.value.epoptiaOneComments;
    chloris.quality = chlorisForm?.value.quality;
    chloris.qualityTrend = chlorisForm?.value.qualityTrend;
    chloris.qualityFix = chlorisForm?.value.qualityFix;
    chloris.area = chlorisForm?.value.area;
    chloris.areaTrend = chlorisForm?.value.areaTrend;
    chloris.areaFix = chlorisForm?.value.areaFix;
    chloris.shapefile = chlorisForm?.value.shapefile;
    chloris.populationObservations = chlorisForm?.value.populationObservations;
    chloris.otherSpecies = chlorisForm?.value.otherSpecies;

    if (fromSpreadsheet === true) {
      chloris.samId = chlorisForm?.value.samId;

      if (chlorisForm?.value.species !== '') {
        const species = ctSpecies.find(
          (itemInner) => itemInner.code === chlorisForm?.value.species
        );

        if (species != null) {
          chloris.species = species.id;

          if (species.code !== 'Άλλο') {
            chloris.otherSpecies = undefined;
          }
        }
      }

      chloris.pressures = chlorisForm.value.pressures;
      if (chloris.pressures && chloris.pressures.length === 0) {
        chloris.pressures = undefined;
      }
      if (chloris.pressures && chloris.pressures.length > 0) {
        chloris.pressures.forEach((press) => {
          if (press.pressuresAndThreatsId !== 'PI01') {
            press.foreignSpecies = undefined;
          }
          if (press.importance === '') {
            press.importance = undefined;
          }
        });
      }

      chloris.functions = chlorisForm.value.functions;
      if (chloris.functions && chloris.functions.length === 0) {
        chloris.functions = undefined;
      }

      chloris.populations = chlorisForm.value.populations;
      if (chloris.populations && chloris.populations.length === 0) {
        chloris.populations = undefined;
      }

      if (chloris.populations && chloris.populations.length > 0) {
        if (
          chloris.method == null ||
          chloris.method === 'ΟΛΑ ΤΑ ΑΤΟΜΑ' ||
          chloris.method === 'ΠΑΡΟΥΣΙΑ/ΑΠΟΥΣΙΑ'
        ) {
          chloris.populations.forEach((pop) => {
            pop.samplingSurface = undefined;
          });
        }
      }
    } else {
      if (!editMode) {
        const timestamp = Date.now().toString();
        let samId =
          chlorisForm?.value.samplingCode +
          '_' +
          chlorisForm?.value.ergoEpoptiaFasiErgou +
          '_CHL_0000_' +
          timestamp;

        chloris.samId = samId;
      }

      if (chlorisForm?.value.species !== '') {
        const species = ctSpecies.find(
          (itemInner) => itemInner.id === chlorisForm?.value.species
        );

        if (species != null) {
          chloris.species = species.id;
        }
      }

      if (!pressures?.touched) {
        chloris.pressures = undefined;
      } else {
        chloris.pressures = pressures?.value;
        if (chloris.pressures && chloris.pressures.length > 0) {
          chloris.pressures.forEach((press) => {
            if (press.pressuresAndThreatsId !== 'PI01') {
              press.foreignSpecies = undefined;
            }
            if (press.importance === '') {
              press.importance = undefined;
            }
          });
        }
      }

      if (!functions?.touched) {
        chloris.functions = undefined;
      } else {
        chloris.functions = functions?.value;
      }

      if (!populations?.touched) {
        chloris.populations = undefined;
      } else {
        chloris.populations = populations?.value;
        if (chloris.populations && chloris.populations.length > 0) {
          chloris.populations.forEach((pop, index) => {
            // pop.obsId = samId + '_' + (index + 1).toString().padStart(3, '0');
            pop.obsId = undefined;
            if (
              chloris.method == null ||
              chloris.method === 'ΟΛΑ ΤΑ ΑΤΟΜΑ' ||
              chloris.method === 'ΠΑΡΟΥΣΙΑ/ΑΠΟΥΣΙΑ'
            ) {
              pop.samplingSurface = undefined;
            }
          });
        }
      }
    }

    return chloris;
  }
}
