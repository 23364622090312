import { Component, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  IAmphibianHabitatADto,
  IAmphibianHabitatBDto,
  IAmphibianSampleHabitatsDto,
} from 'src/app/core/dtos/amphibian.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { AmphibiansService } from '../../services/amphibians.service';
import { forkJoin, finalize } from 'rxjs';

@Component({
  selector: 'app-amphibian-habitats',
  templateUrl: './amphibian-habitats.component.html',
  styleUrls: ['./amphibian-habitats.component.scss'],
})
export class AmphibianHabitatsComponent {
  @Input() public habitats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public habitatsById: IAmphibianSampleHabitatsDto[] = [];
  @Input() public disabledControls = false;
  @Input() public readOnlyForm = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  public habA: IAmphibianHabitatADto[] = [];
  public habADropdown: IDropdownItem[] = [];
  public habB: IAmphibianHabitatBDto[] = [];
  public habBDropdown: IDropdownItem[] = [];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private amphibiansService: AmphibiansService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.habitatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['habitatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.amphibiansService.getAmphibianHabitatA(this.dataVersion),
      this.amphibiansService.getAmphibianHabitatB(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.habA.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.habA.forEach((hab) => {
              dropdown.push({
                id: hab.code,
                name: hab.description,
              });
            });
            this.habADropdown = dropdown;
          }

          if (this.habB.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.habB.forEach((hab) => {
              dropdown.push({
                id: hab.code,
                name: hab.description,
              });
            });
            this.habBDropdown = dropdown;
          }

          if (this.habitatsById.length > 0) {
            this.habitatsById.forEach((item) => {
              this.addNewHabitat();
            });
            this.habitats.patchValue(this.habitatsById);
            this.habitats.markAsTouched();
            this.habitatsById = [];
            if (this.disabledControls === true) {
              this.habitats.disable();
            }
          }

          return;
        })
      )
      .subscribe(([habA, habB]) => {
        if (habA != undefined) {
          this.habA = habA as IAmphibianHabitatADto[];
        }

        if (habB != undefined) {
          this.habB = habB as IAmphibianHabitatBDto[];
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.habitats.controls[index] as FormGroup;
  }

  public addNewHabitat(): void {
    this.habitats.push(this.amphibiansService.initHabitatsFormGroup(this.fb));
  }

  public removeHabitat(index: number): void {
    this.habitats.removeAt(index);
  }
}
