<div class="input-container">
  <label class="label-text" *ngIf="label">{{ label | translate }}</label>

  <input
    type="text"
    class="form-control disabled"
    aria-label=""
    aria-describedby="basic-addon1"
    [value]="value"
    [disabled]="true"
  />
  <div class="error-message"></div>
</div>
