<div class="dropdown-container">
  <div *ngIf="label" class="label-text">
    {{ label | translate }} <span *ngIf="required">*</span>
  </div>
  <p-dropdown
    [filter]="true"
    [filterBy]="'name,id'"
    form-controlName="{{ formName }}"
    [virtualScroll]="dropdownList.length > 500"
    [virtualScrollItemSize]="40"
    id="typeahead-basic"
    [options]="dropdownList"
    [(ngModel)]="selectedValue"
    (onBlur)="onBlur()"
    (onChange)="onModelChanging($event)"
    placeholder=""
    [editable]="false"
    [ngClass]="{ 'error-status': touched && hasErrors }"
    [dropdownAppendTo]="'.dropdown-container'"
    [autoDisplayFirst]="false"
    [style]="{ width: '100%' }"
    [disabled]="disabled"
  >
    <ng-template pTemplate="selectedItem">
      <ng-container *ngIf="selectedValue?.id !== selectedValue?.name">
        <ng-container *ngIf="selectedValue?.name === ''">
          <div class="custom-dropdown-item">
            <span>{{ selectedValue?.id }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedValue?.id === ''">
          <div class="custom-dropdown-item">
            <span>{{ selectedValue?.name }}</span>
          </div>
        </ng-container>
        <ng-container
          *ngIf="selectedValue?.name !== '' && selectedValue?.id !== ''"
        >
          <div class="custom-dropdown-item">
            <span>({{ selectedValue?.id }}) - {{ selectedValue?.name }}</span>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedValue?.id === selectedValue?.name">
        <div class="custom-dropdown-item">
          <span>{{ selectedValue?.name }}</span>
        </div>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <ng-container *ngIf="item.id !== item.name">
        <ng-container *ngIf="item.name === ''">
          <div class="custom-dropdown-item">
            <span>{{ item.id }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="item.id === ''">
          <div class="custom-dropdown-item">
            <span>{{ item.name }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="item.name !== '' && item.id !== ''">
          <div class="custom-dropdown-item">
            <span>({{ item.id }}) - {{ item.name }}</span>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="item.id === item.name">
        <div class="custom-dropdown-item">
          <span>{{ item.name }}</span>
        </div>
      </ng-container>
    </ng-template>
  </p-dropdown>
  <div class="error-message">
    <ng-container *ngIf="touched && hasErrors">
      <ng-container
        *ngIf="
          errors != null && errors['required'] !== null && errors['required']
        "
      >
        {{ "FIELD_REQUIRED_ERROR" | translate }}
      </ng-container>
    </ng-container>
  </div>
</div>
