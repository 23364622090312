<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-2">
    <div class="sub-category-title">
      {{ "1120B" | translate }}
    </div>
    <ng-container *ngFor="let bForm of b.controls; let index = index">
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'position'"
          [label]="'POSITION'"
          [dropdownList]="positionDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['position'].value"
        ></app-dropdown>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'meanSproutDensity'"
          [label]="'MEAN_SPROUT_DENSITY'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'meanPercentagePlagiotropin'"
          [label]="'MEAN_PERCENTAGE_PLAGIOTROPIN'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'meanLeavesBundleLength'"
          [label]="'MEAN_LEAVES_BUNDLE_LENGTH'"
          [ngClass]="{
            disabled:
              getGroup(index).controls['meanLeavesBundleLength'].disabled &&
              getGroup(index).controls['meanLeavesBundleLength'].value === ''
          }"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'orientation'"
          [label]="'ORIENTATION'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'sandCoverage'"
          [label]="'SAND_COVERAGE'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row form-3">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'rockCoverage'"
          [label]="'ROCK_COVERAGE'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'deadMatteCoverage'"
          [label]="'DEAD_MATTE_COVERAGE'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'cNodosaCoverage'"
          [label]="'C_NODOSA_COVERAGE'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row form-3">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'caulerpaCoverage'"
          [label]="'CAULERPA_COVERAGE'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'hStipulaceCoverage'"
          [label]="'H_STIPULACE_COVERAGE'"
          [required]="true"
        ></app-input>
      </div>
      <app-textarea
        [style.min-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>
      <div class="form-row form-3">
        <app-button
          (buttonClicked)="remove(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== b.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_RECORD'"
      (buttonClicked)="addNew()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
