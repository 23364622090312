import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';
import {
  IInvertebrateSpeciesDto,
  ISampleInvertebrateSpeciesDto,
} from 'src/app/core/dtos/invertebrate.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { InvertebrateService } from '../../services/invertebrate.service';

@Component({
  selector: 'app-invertebrate-species',
  templateUrl: './invertebrate-species.component.html',
  styleUrls: ['./invertebrate-species.component.scss'],
})
export class InvertebrateSpeciesComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() public formGroup!: FormGroup;
  @Input() public species!: FormArray;
  @Input() public speciesById: ISampleInvertebrateSpeciesDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  destroy$ = new Subject<void>();
  private ctSpecies: IInvertebrateSpeciesDto[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public speciesForm!: FormGroup;
  public EOKDropdown: IDropdownItem[] = [
    { id: 0, name: this.translate.instant('PARII') },
    { id: 1, name: this.translate.instant('PARIV') },
    { id: 2, name: this.translate.instant('PARV') },
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private invertebrateService: InvertebrateService
  ) {}

  ngOnInit(): void {
    if (this.speciesById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }

    this.species.valueChanges.subscribe((value) => {
      value.forEach((control: any, index: number) => {
        this.checkSpecies(control['species'], index);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    this.invertebrateService
      .getInvertebratesSpecies(this.dataVersion)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          if (this.speciesById.length > 0) {
            this.speciesById.forEach(() => {
              this.addNewSpecies();
            });
            this.species.patchValue(this.speciesById);
            this.species.markAsTouched();
            this.speciesById = [];
            if (this.disabledControls === true) {
              this.species.disable();
            }
          }

          return;
        })
      )
      .subscribe((res) => {
        this.ctSpecies = res;
        if (res && res.length > 0) {
          res.forEach((species) => {
            this.speciesDropdown.push({
              id: species.code,
              name: species.code,
              actualId: species.id,
            });
          });
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.species.controls[index] as FormGroup;
  }

  public removeSpecies(index: number): void {
    this.species.removeAt(index);
  }

  public addNewSpecies(): void {
    this.species.push(this.invertebrateService.initSpeciesFormGroup(this.fb));
  }

  public checkSpecies(species: string, index: number): void {
    let speciesCode = this.ctSpecies.find(
      (value) => value.id === parseInt(species)
    );
    if (speciesCode != null) {
      if (parseInt(species) === speciesCode.id) {
        if (speciesCode.annexII?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexIV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'pariv'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'pariv'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls['parv'].setValue(
            true,
            { emitEvent: false }
          );
        } else {
          (this.species.controls[index] as FormGroup).controls['parv'].setValue(
            false,
            { emitEvent: false }
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
