<div class="land-container">
  <div class="register-container">
    <span class="register-text">{{ "REGISTER_SAMPLES" | translate }}</span>
    <app-button
      [btnText]="'LOGIN'"
      (buttonClicked)="auth.auth.loginWithRedirect()"
      >{{ "LOGIN" | translate }}</app-button
    >
  </div>
</div>
