<ng-container [formGroup]="formGroup">
  <div formArrayName="pinna" class="form-container form-1">
    <ng-container *ngFor="let pinnaForm of pinna.controls; let index = index">
      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'crossSectionPart'"
          [label]="'CROSS_SECTION_PART_SPECIES'"
          [dropdownList]="crossSectionPartDropdown"
          [preDefinedId]="getGroup(index).controls['crossSectionPart'].value"
        ></app-dropdown>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'verticalDistance'"
          [label]="'VERTICAL_DISTANCE'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'typicalSize'"
          [label]="'TYPICAL_SIZE'"
        ></app-input>
      </div>

      <div class="form-row form-3">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'depth'"
          [label]="'DEPTH'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'averageCrossSectionDepth'"
          [label]="'AVERAGE_CROSS_SECTION_DEPTH'"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'biotope'"
          [label]="'BIOTOPE'"
        ></app-input>
      </div>

      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>

      <app-button
        (buttonClicked)="removePinna(index)"
        [style.--btnPadding]="'6px 15px'"
        class="minus-btn"
        [btnText]="'REMOVE_SPECIES'"
        [tooltip]="'REMOVE_ENTRY' | translate"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== pinna.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_SPECIES'"
      (buttonClicked)="addNewPinna()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
