import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
export enum BirdFormGroup {
  Species = 'species',
  Threats = 'threats',
}
export const birdConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
      [
        'Γεωγραφικό Πλάτος (WGS84) Τέλος',
        'Γεωγραφικό Μήκος (WGS84) Τέλος',
        false,
      ],
    ],
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 2,
      },
      {
        header: 'Ώρα Δειγματοληψίας',
        type: 'time',
        formName: 'time',
        order: 3,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 5,
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
        order: 6,
      },
      {
        header: 'Νομός',
        type: 'prefecture',
        formName: 'prefecture',
        order: 14,
      },
      {
        header: 'Τοποθεσία',
        type: 'string',
        formName: 'locality',
        order: 15,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 16,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
        order: 17,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 27,
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Διάρκεια (min)',
        type: 'duration',
        formName: 'duration',
        order: 4,
      },
      {
        header: 'Μέθοδος Δειγματοληψίας',
        type: 'string',
        formName: 'method',
        order: 7,
      },
      {
        header: 'Ενδιαίτημα',
        type: 'string',
        formName: 'habitat',
        order: 8,
      },
      {
        header: 'Κυρίαρχος Τύπος Βλάστησης',
        type: 'string',
        formName: 'primaryVegetationType',
        order: 9,
      },
      {
        header: 'Ύψος Βλάστησης',
        type: 'string',
        formName: 'vegetationHeight',
        order: 10,
      },
      {
        header: 'Κάλυψη',
        type: 'float',
        formName: 'coverage',
        order: 11,
      },
      {
        header: 'Πυκνότητα',
        type: 'string',
        formName: 'density',
        order: 12,
      },
      {
        header: 'Ποιότητα',
        type: 'string',
        formName: 'quality',
        order: 13,
      },
      {
        header: 'Υψόμετρο (m)',
        type: 'float',
        formName: 'altitude',
        order: 18,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84) Τέλος',
        type: 'float',
        formName: 'longEnd',
        order: 19,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84) Τέλος',
        type: 'float',
        formName: 'latEnd',
        order: 20,
      },
      {
        header: 'ΥψόμετροT (m)',
        type: 'float',
        formName: 'altitudeEnd',
        order: 21,
      },
      {
        header: 'Θερμοκρασία (οC)',
        type: 'string',
        formName: 'temperature',
        order: 22,
      },
      {
        header: 'Νεφώσεις',
        type: 'string',
        formName: 'cloudCover',
        order: 23,
      },
      {
        header: 'Κατεύθυνση Ανέμου',
        type: 'string',
        formName: 'windDirection',
        order: 24,
      },
      {
        header: 'Κατεύθυνση Ανέμου (αζιμούθιο)',
        type: 'string',
        formName: 'windDirectionAzimuth',
        order: 25,
      },
      {
        header: 'Ένταση Ανέμου (bf)',
        type: 'float',
        formName: 'windIntensity',
        order: 26,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: BirdFormGroup.Species,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Αφορά A/A Δείγματος',
    validateLocation: [
      [
        'Τετμημένη X (EGSA) σε m είδους',
        'Τεταγμένη Υ (EGSA) σε m είδους',
        true,
      ],
    ],
    configuration: [
      {
        header: 'Αφορά A/A Δείγματος',
        type: 'string',
        formName: 'aaSample',
        order: 1,
        disabled: true,
      },
      {
        header: 'Ώρα είδους',
        type: 'time',
        formName: 'time',
        order: 2,
      },
      {
        header: 'Τετμημένη X (EGSA) σε m είδους',
        type: 'float',
        formName: 'egsax',
        order: 3,
      },
      {
        header: 'Τεταγμένη Υ (EGSA) σε m είδους',
        type: 'float',
        formName: 'egsay',
        order: 4,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 5,
      },
      {
        header: 'Φύλο είδους',
        type: 'string',
        formName: 'gender',
        order: 6,
      },
      {
        header: 'Ηλικία είδους',
        type: 'string',
        formName: 'age',
        order: 7,
      },
      {
        header: 'Αριθμός ατόμων είδους',
        type: 'int',
        formName: 'numberOfPeople',
        order: 8,
      },
      {
        header: 'Παρατηρήσεις είδους',
        type: 'string',
        formName: 'observations',
        order: 9,
      },
      {
        header: '2009/147/ΕΚ - Παρ I είδους',
        type: 'boolean',
        formName: 'pari',
        order: 10,
      },
      {
        header: '2009/147/ΕΚ - Παρ IΙ είδους',
        type: 'boolean',
        formName: 'parii',
        order: 11,
      },
      {
        header: '2009/147/ΕΚ - Παρ IΙΙ είδους',
        type: 'boolean',
        formName: 'pariii',
        order: 12,
      },
      {
        header: 'Συμπεριφορά είδους',
        type: 'string',
        formName: 'attitude',
        order: 13,
      },
      {
        header: 'Τύπος Παρατήρησης είδους',
        type: 'string',
        formName: 'observationType',
        order: 14,
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: BirdFormGroup.Threats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Αφορά A/A Δείγματος',
    configuration: [
      {
        header: 'Αφορά A/A Δείγματος',
        type: 'string',
        formName: 'aaSample',
        order: 1,
        disabled: true,
      },
      {
        header: 'Κωδικός πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsId',
        order: 2,
      },
      {
        header: 'Όνομα είδους απειλής',
        type: 'string',
        formName: 'speciesId',
        order: 3,
      },
      {
        header: 'Τύπος (πίεση ή απειλή)',
        type: 'string',
        formName: 'type',
        order: 4,
      },
      {
        header: 'Σημασία απειλής',
        type: 'string',
        formName: 'importance',
        order: 5,
      },
      {
        header: 'Τύπος πίεσης ή απειλής',
        type: 'string',
        formName: 'typeOfThreat',
        order: 6,
      },
    ],
  },
];
