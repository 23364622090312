import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, takeUntil, finalize } from 'rxjs';
import { IBookSpeciesHabitatSpreadEntryDto } from 'src/app/core/dtos/bookSpeciesHabitatSpread.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { BookHabitatsSpeciesService } from '../../services/book-habitats-species.service';

interface selectedSpecies {
  order: string;
  class: string;
  family: string;
  genus: string;
}

@Component({
  selector: 'app-book-habitats-species-entry',
  templateUrl: './book-habitats-species-entry.component.html',
  styleUrls: ['./book-habitats-species-entry.component.scss'],
})
export class BookHabitatsSpeciesEntryComponent implements OnChanges {
  private destroy$ = new Subject<void>();

  @Input() public entries!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public speciesIds: IDropdownItem[] = [];
  @Input() public habitatTypes: IDropdownItem[] = [];
  @Input() public abudance: IDropdownItem[] = [];
  @Input() public sourceReliability: IDropdownItem[] = [];
  @Input() public methodReliability: IDropdownItem[] = [];
  @Input() public prefectures: IDropdownItem[] = [];
  @Input() public naturaCodes: IDropdownItem[] = [];
  @Input() public gridCells: IDropdownItem[] = [];
  @Input() public entriesById: IBookSpeciesHabitatSpreadEntryDto[] = [];
  @Input() public disabledControls = false;
  @Input() public readOnlyForm = false;
  @Input() public referenceId?: string;
  @Input() public regionalUnits: IDropdownItem[] = [];
  @Input() public ctSpecies: any[] = [];
  public selectedSpecies: selectedSpecies[] = [];
  public groupIndex = -1;

  public positionAccuracy: IDropdownItem[] = [
    {
      id: 'Υψηλή',
      name: 'Υψηλή',
    },
    {
      id: 'Μέτρια',
      name: 'Μέτρια',
    },
    {
      id: 'Χαμηλή',
      name: 'Χαμηλή',
    },
  ];

  public measurementUnit: IDropdownItem[] = [
    { id: 'Number of individuals', name: 'Number of individuals' },
    { id: 'Number of adults', name: 'Number of adults' },
    { id: 'Number of subadults', name: 'Number of subadults' },
    { id: 'Number of breeding females', name: 'Number of breeding females' },
    { id: 'Number of calling males', name: 'Number of calling males' },
    { id: 'Number of males', name: 'Number of males' },
    { id: 'Number of pairs', name: 'Number of pairs' },
    { id: 'Number of shoots', name: 'Number of shoots' },
    { id: 'Number of tufts', name: 'Number of tufts' },
    { id: 'Number of flowering stems', name: 'Number of flowering stems' },
  ];

  public populationIndicator: IDropdownItem[] = [
    { id: 'number of localities', name: 'Number of localities' },
    { id: 'number of colonies', name: 'Number of colonies' },
    { id: 'number of inhabited logs', name: 'Number of inhabited logs' },
    { id: 'number of inhabited trees', name: 'Number of inhabited trees' },
    {
      id: 'number of inhabited stones/boulders',
      name: 'Number of inhabited stones/boulders',
    },
    {
      id: 'length of inhabited feature in km',
      name: 'Length of inhabited feature in km',
    },
    { id: 'area coverd by population', name: 'Area covered by population' },
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private bookService: BookHabitatsSpeciesService,
    public common: CommonService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entriesById'] && this.referenceId) {
      this.entriesById.forEach((item, index) => {
        this.addNewEntries();
        const fg = this.entries.controls[index] as FormGroup;

        if (item.speciesId) {
          fg.controls['isSpecies'].patchValue(true);
        }
        if (item.habitatId) {
          fg.controls['isHabitat'].patchValue(true);
        }
        fg.patchValue(item as FormGroup);

        // Bug Fix (issue #153)
        fg.controls['latitude'].updateValueAndValidity();
      });
      this.entries.markAsTouched();
      this.entriesById = [];
      if (this.disabledControls === true) {
        this.entries.disable({ emitEvent: false });
      }
    }
    if (changes['ctSpecies'] && this.referenceId) {
      this.entries.controls.forEach((control) => {
        (control as FormGroup).controls['speciesId'].updateValueAndValidity();
      });
    }
  }

  public getGroup(index: number): FormGroup {
    return this.entries.controls[index] as FormGroup;
  }

  public addNewEntries(): void {
    this.groupIndex += 1;
    const newFg = this.bookService.initEntriesFormGroup(
      this.fb,
      this.groupIndex
    );
    newFg.controls['speciesId'].valueChanges.subscribe((value) => {
      this.checkSpecies(value, this.groupIndex);

      if (!newFg.controls['speciesId'].disabled) {
        const species = this.ctSpecies.find(
          (item) => item.id === newFg.controls['speciesId'].value
        );
        if (species == null && newFg.controls['speciesId'].value !== 'Άλλο') {
          newFg.controls['speciesIdOther'].setValue('', {
            emitEvent: false,
          });
          newFg.controls['speciesIdOther'].clearValidators();
          newFg.controls['speciesIdOther'].disable({
            emitEvent: false,
          });
          return;
        }

        if (
          (species && species.code === 'Άλλο') ||
          newFg.controls['speciesId'].value === 'Άλλο'
        ) {
          newFg.controls['speciesIdOther'].enable({
            emitEvent: false,
          });
          newFg.controls['speciesIdOther'].setValidators(Validators.required);
          newFg.controls['speciesIdOther'].updateValueAndValidity({
            emitEvent: false,
            onlySelf: true,
          });
        } else {
          newFg.controls['speciesIdOther'].setValue('', {
            emitEvent: false,
          });
          newFg.controls['speciesIdOther'].clearValidators();
          newFg.controls['speciesIdOther'].disable({
            emitEvent: false,
          });
          newFg.controls['speciesIdOther'].updateValueAndValidity({
            emitEvent: false,
            onlySelf: true,
          });
        }
      }
    });

    this.entries.push(newFg);
    this.monitorCoords(this.groupIndex.toString());
    this.selectedSpecies.push({
      class: '',
      family: '',
      genus: '',
      order: '',
    });
    this.monitorSpeciesHabitat(this.groupIndex.toString());
    const fg = this.entries.controls[this.groupIndex] as FormGroup;
    if (
      this.formGroup.controls['speciesCategory'].value === 'Τύποι Οικοτόπων'
    ) {
      fg.controls['isHabitat'].patchValue(true);
    } else {
      fg.controls['isSpecies'].patchValue(true);
    }

    if (
      this.formGroup.controls['speciesCategory'].value === 'Χλωρίδα' &&
      fg.controls['floristicRegion'].disabled
    ) {
      fg.controls['floristicRegion'].enable();
    }

    if (
      this.formGroup.controls['speciesCategory'].value !== 'Χλωρίδα' &&
      fg.controls['floristicRegion'].enabled
    ) {
      fg.controls['floristicRegion'].setValue('');
      fg.controls['floristicRegion'].disable();
    }

    if (
      this.formGroup.controls['speciesCategory'].value === 'Χλωρίδα' &&
      fg.controls['releveCode'].disabled
    ) {
      fg.controls['releveCode'].enable();
    }

    if (
      this.formGroup.controls['speciesCategory'].value !== 'Χλωρίδα' &&
      fg.controls['releveCode'].enabled
    ) {
      fg.controls['releveCode'].setValue('');
      fg.controls['releveCode'].disable();
    }

    if (
      this.formGroup.controls['speciesCategory'].value === 'Χλωρίδα' &&
      fg.controls['conservationStatus'].disabled
    ) {
      fg.controls['conservationStatus'].enable();
    }

    if (
      this.formGroup.controls['speciesCategory'].value !== 'Χλωρίδα' &&
      fg.controls['conservationStatus'].enabled
    ) {
      fg.controls['conservationStatus'].setValue('');
      fg.controls['conservationStatus'].disable();
    }

    if (
      this.formGroup.controls['speciesCategory'].value === 'Ιχθυοπανίδα' &&
      fg.controls['waterBody'].disabled
    ) {
      fg.controls['waterBody'].enable();
    }

    if (
      this.formGroup.controls['speciesCategory'].value !== 'Ιχθυοπανίδα' &&
      fg.controls['waterBody'].enabled
    ) {
      fg.controls['waterBody'].setValue('');
      fg.controls['waterBody'].disable();
    }
  }

  public removeEntries(groupIndex: number): void {
    const index = this.entries.controls.findIndex((control) => {
      return control.value.groupIndex === groupIndex;
    });
    this.entries.removeAt(index);
    this.selectedSpecies.splice(index, 1);
  }

  public checkSpecies(species: string, index: number): void {
    if (species == null || species === '') {
      (this.entries.controls[index] as FormGroup).controls['annexII'].setValue(
        false,
        { emitEvent: false }
      );
      (this.entries.controls[index] as FormGroup).controls['annexIV'].setValue(
        false,
        { emitEvent: false }
      );
      (this.entries.controls[index] as FormGroup).controls['annexV'].setValue(
        false,
        { emitEvent: false }
      );
      return;
    }

    let speciesCode = this.ctSpecies.find(
      (value) => value.id === parseInt(species)
    );
    if (speciesCode != null) {
      if (parseInt(species) === speciesCode.id) {
        if (speciesCode.annexII?.startsWith('Y')) {
          (this.entries.controls[index] as FormGroup).controls[
            'annexII'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.entries.controls[index] as FormGroup).controls[
            'annexII'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexIV?.startsWith('Y')) {
          (this.entries.controls[index] as FormGroup).controls[
            'annexIV'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.entries.controls[index] as FormGroup).controls[
            'annexIV'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexV?.startsWith('Y')) {
          (this.entries.controls[index] as FormGroup).controls[
            'annexV'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.entries.controls[index] as FormGroup).controls[
            'annexV'
          ].setValue(false, { emitEvent: false });
        }
      }
    }
  }

  public async speciesSelected(event: any, groupIndex: number) {
    const index = this.entries.controls.findIndex((control) => {
      return control.value.groupIndex === groupIndex;
    });
    let res = await this.bookService.GetSpeciesInfo(event as string);
    // this.selectedSpecies = {
    //   ...(await this.bookService.GetSpeciesInfo(event as string)),
    // };
    // COMMENT: We cannot support this since we must use the speciesId from
    // multiple versioned tables. The `Book_Species` table is deprecated.
    // this.selectedSpecies[index].class = res?.class;
    // this.selectedSpecies[index].family = res?.family;
    // this.selectedSpecies[index].genus = res?.genus;
    // this.selectedSpecies[index].order = res?.order;
  }

  private monitorSpeciesHabitat(groupIndex: string) {
    const index = this.entries.controls.findIndex((control) => {
      return control.value.groupIndex === groupIndex;
    });
    const options = { emitEvent: false, onlySelf: true };
    const fg = this.entries.controls[index] as FormGroup;
    fg.controls['isSpecies'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          fg.controls['habitatId'].disable();
          fg.controls['syntaxa'].disable();
          fg.controls['speciesId'].enable();
          fg.controls['isHabitat'].patchValue(!value, options);
          fg.controls['habitatId'].patchValue('');
          fg.controls['syntaxa'].patchValue('');
          fg.updateValueAndValidity();
        } else {
          fg.controls['isSpecies'].patchValue(true, options);
          fg.updateValueAndValidity();
        }
      });

    fg.controls['isHabitat'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          fg.controls['habitatId'].enable();
          fg.controls['syntaxa'].enable();
          fg.controls['speciesId'].disable();
          fg.controls['isSpecies'].patchValue(!value, options);
          fg.controls['speciesId'].patchValue('');
          this.selectedSpecies[index].class = '';
          this.selectedSpecies[index].family = '';
          this.selectedSpecies[index].genus = '';
          this.selectedSpecies[index].order = '';
          fg.updateValueAndValidity();
        } else {
          fg.controls['isHabitat'].patchValue(true, options);
          fg.updateValueAndValidity();
        }
      });
  }

  private monitorCoords(groupIndex: string): void {
    const index = this.entries.controls.findIndex((control) => {
      return control.value.groupIndex === groupIndex;
    });
    const fg = this.entries.controls[index] as FormGroup;
    this.common
      .monitorCoords(
        this.destroy$,
        fg,
        'xegsa',
        'yegsa',
        'latitude',
        'longitude'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * Find the new coordinates of the map
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public onMarkerMoved(
    coordinates: { lat: number; lng: number },
    groupIndex: string
  ): void {
    const index = this.entries.controls.findIndex((control) => {
      return control.value.groupIndex === groupIndex;
    });
    const fg = this.entries.controls[index] as FormGroup;
    if (coordinates != null) {
      fg.controls['latitude'].patchValue(coordinates.lat, {
        emitEvent: false,
      });
      fg.controls['latitude'].markAsTouched();
      fg.controls['longitude'].patchValue(coordinates.lng, {
        emitEvent: false,
      });
      fg.controls['longitude'].markAsTouched();

      const coordinatesEGSA = this.common.convertWGStoEGSA(
        coordinates.lat,
        coordinates.lng
      );
      fg.controls['xegsa'].patchValue(coordinatesEGSA.x, {
        emitEvent: false,
      });
      fg.controls['xegsa'].markAsTouched();
      fg.controls['yegsa'].patchValue(coordinatesEGSA.y, {
        emitEvent: false,
      });
      fg.controls['yegsa'].markAsTouched();
    }
  }

  /**
   * Returns the coordinates of every entry
   *
   * @param {number} groupIndex The index of the entry
   * @returns {ICoordinates} The coordinates of the entry
   */
  public entryCoordinates(groupIndex: string): ICoordinates | undefined {
    const index = this.entries.controls.findIndex((control) => {
      return control.value.groupIndex === groupIndex;
    });
    const fg = this.entries.controls[index] as FormGroup;
    let co: ICoordinates | undefined = undefined;

    if (
      !!fg?.controls['latitude']?.value &&
      !!fg?.controls['longitude']?.value
    ) {
      co = {
        lat: parseFloat(fg?.controls['latitude']?.value),
        lng: parseFloat(fg?.controls['longitude']?.value),
      };
    }
    return co;
  }

  // public changeCheckbox(e: any, index: number) {
  //   const fg = this.getGroup(index);
  //   fg.control('')
  //   this.entries.controls[index].
  // }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
