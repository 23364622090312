import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  IInvertebrateThreatsDto,
  IInvertebrateSpeciesDto,
  IInvertebrateDto,
} from 'src/app/core/dtos/invertebrate.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ISampleIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { environment } from 'src/environments/environment';
import { SamplesService } from './samples.service';
import { IFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';

@Injectable({
  providedIn: 'root',
})
export class InvertebrateService {
  constructor(
    private http: HttpClient,
    public common: CommonService,
    private sampleService: SamplesService
  ) {}

  public getInvertebrates(
    dataVersion?: string
  ): Observable<IInvertebrateDto[]> {
    const url = environment.endpoint + 'Invertebrates/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IInvertebrateDto[]>(url, { params: params });
  }

  public getInvertebrateById(id: number): Observable<IInvertebrateDto> {
    const url = environment.endpoint + 'Invertebrates';
    const params = new HttpParams().set('id', id);
    return this.http.get<IInvertebrateDto>(url, { params: params });
  }

  public putInvertebrate(
    id: number,
    payload: IInvertebrateDto
  ): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Invertebrates';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public getInvertebratesThreats(
    dataVersion?: string
  ): Observable<IInvertebrateThreatsDto[]> {
    const url = environment.endpoint + 'Invertebrates/threats';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IInvertebrateThreatsDto[]>(url, { params: params });
  }

  public getInvertebratesSpecies(
    dataVersion?: string
  ): Observable<IInvertebrateSpeciesDto[]> {
    const url = environment.endpoint + 'Invertebrates/species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IInvertebrateSpeciesDto[]>(url, { params: params });
  }

  public addInvertebrate(
    payload: IInvertebrateDto
  ): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Invertebrates';
    return this.http.post<ISampleIdResponse>(url, payload);
  }

  public addBatchInvertebrate(
    payload: IInvertebrateDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Invertebrates/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public addFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Invertebrates/files';
    return this.http.post<void>(url, payload);
  }

  public removeFiles(payload: IFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Invertebrates/remove-files';
    return this.http.post<void>(url, payload);
  }

  public initInvertebrateForm(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);

    sample = fb.group({
      ...sample.controls,
      duration: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      habitat: fb.control(''),
      altitude: fb.control('', [
        Validators.required,
        Validators.min(-10),
        Validators.max(2800),
      ]),
      method: fb.control(''),
      weatherConditions: fb.control(''),
      topography: fb.control(''),
      crossSectionLength: fb.control(''),
      humidity: fb.control(''),
      temperature: fb.control(''),
      latEnd: fb.control('', [
        this.common.latLongValidator('latEnd', 'longEnd'),
      ]),
      longEnd: fb.control('', [
        this.common.latLongValidator('latEnd', 'longEnd'),
      ]),
      xEnd: fb.control('', [
        this.common.latLongValidator('xEnd', 'yEnd', true),
      ]),
      yEnd: fb.control('', [
        this.common.latLongValidator('xEnd', 'yEnd', true),
      ]),
      vegetationType: fb.control(''),
      species: fb.array([]),
      threats: fb.array([]),
      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
    });
    return sample;
  }

  public initSpeciesFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      species: fb.control('', [Validators.required]),
      crossSectionPart: fb.control('', [Validators.required]),
      numberOfPeople: fb.control('', [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      relativeAbundanceCategory: fb.control('', [Validators.required]),
      relativeAbundanceStatus: fb.control('', [Validators.required]),
      observation: fb.control(''),
      parii: fb.control(false),
      pariv: fb.control(false),
      parv: fb.control(false),
    });
  }

  public initThreatsFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      pressuresAndThreatsId: fb.control('', [Validators.required]),
      speciesId: fb.control('', [Validators.required]),
      type: fb.control('', [Validators.required]),
      importance: fb.control('', [Validators.required]),
    });
  }

  public prepareInvertebrateRequest(
    invertebrateForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctSpecies: IInvertebrateSpeciesDto[] = []
  ): IInvertebrateDto {
    const invertebrate: IInvertebrateDto = {};
    const species = invertebrateForm.controls['species'] as FormArray;
    const threats = invertebrateForm.controls['threats'] as FormArray;

    invertebrate.date = this.common.formatDate(invertebrateForm?.value.date);
    invertebrate.naturaCode = invertebrateForm?.value.naturaCode;
    invertebrate.samplingCode = invertebrateForm?.value.samplingCode;
    invertebrate.financialInstrument =
      invertebrateForm?.value.financialInstrument;
    invertebrate.prefecture = invertebrateForm?.value.prefecture;
    invertebrate.researcher = invertebrateForm?.value.researcher;
    invertebrate.time = this.common.formatTime(invertebrateForm?.value.time);
    invertebrate.locality = invertebrateForm?.value.locality;
    invertebrate.observations = invertebrateForm?.value.observations;
    invertebrate.file = invertebrateForm?.value.file;
    invertebrate.gridCell = invertebrateForm?.value.gridCell;
    invertebrate.photo = invertebrateForm?.value.photo;
    invertebrate.duration = invertebrateForm?.value.duration;
    invertebrate.habitat = invertebrateForm?.value.habitat;
    invertebrate.altitude = invertebrateForm?.value.altitude;
    invertebrate.method = invertebrateForm?.value.method;
    invertebrate.weatherConditions = invertebrateForm?.value.weatherConditions;
    invertebrate.topography = invertebrateForm?.value.topography;
    invertebrate.crossSectionLength =
      invertebrateForm?.value.crossSectionLength;
    invertebrate.vegetationType = invertebrateForm?.value.vegetationType;
    invertebrate.humidity = invertebrateForm?.value.humidity;
    invertebrate.temperature = invertebrateForm?.value.temperature;
    invertebrate.pointX = invertebrateForm?.value.lat;
    invertebrate.pointY = invertebrateForm?.value.long;
    invertebrate.pointXEnd = invertebrateForm?.value.latEnd;
    invertebrate.pointYEnd = invertebrateForm?.value.longEnd;
    invertebrate.protocolId = Protocols.Invertebrates;

    if (fromSpreadsheet === true) {
      invertebrate.species = invertebrateForm.value.species;
      if (invertebrate.species && invertebrate.species.length === 0) {
        invertebrate.species = undefined;
      } else if (invertebrate.species && invertebrate.species.length > 0) {
        invertebrate.species.forEach((item) => {
          item.species = ctSpecies
            .find((itemInner) => itemInner.code === item.species)
            ?.id?.toString();
        });
      }

      invertebrate.threats = invertebrateForm.value.threats;
      if (invertebrate.threats && invertebrate.threats.length === 0) {
        invertebrate.threats = undefined;
      } else if (invertebrate.threats && invertebrate.threats.length > 0) {
        invertebrate.threats.forEach((item) => {
          item.speciesId = ctSpecies
            .find((itemInner) => itemInner.code === item.speciesId)
            ?.id?.toString();
        });
      }
    } else {
      if (!species?.touched) {
        invertebrate.species = undefined;
      } else {
        species?.controls.forEach(
          (control: {
            value: {
              eok: number;
              parii: boolean;
              pariv: boolean;
              parv: boolean;
            };
          }) => {
            if (control.value.eok === 0) {
              control.value.parii = true;
            } else if (control.value.eok === 1) {
              control.value.pariv = true;
            } else if (control.value.eok === 2) {
              control.value.parv = true;
            }
          }
        );
        invertebrate.species = species?.value;
      }
      if (!threats?.touched) {
        invertebrate.threats = undefined;
      } else {
        invertebrate.threats = threats?.value;
      }
    }

    return invertebrate;
  }
}
