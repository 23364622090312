import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LandPageComponent } from './core/components/land-page/land-page.component';
import { RedirectAuthGuard } from './shared/guards/redirect-auth.guard';
import * as URLS from './URLS';

const routes: Routes = [
  {
    path: '',
    component: LandPageComponent,
    canActivate: [RedirectAuthGuard]
  },
  {
    path: URLS.URL_PROTOCOLS,
    loadChildren: () =>
      import('./samplings/samplings.module').then((m) => m.SamplingsModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
