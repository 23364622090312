import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs';
import { SamplesService } from 'src/app/samplings/services/samples.service';
import { ToastService } from '../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-files-modal',
  templateUrl: './upload-files-modal.component.html',
  styleUrls: ['./upload-files-modal.component.scss'],
})
export class UploadFilesModalComponent implements OnInit {
  @Input() isBibliography: boolean = false;
  public files: any[] = [];
  public form!: FormGroup;
  public loading = false;
  public errors: string[] = [];

  constructor(
    private fb: FormBuilder,
    private samplesService: SamplesService,
    private toastService: ToastService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      uploadId: this.fb.control('', [Validators.required]),
    });
  }

  public cannotUpload(): boolean {
    return (
      this.loading ||
      this.form.invalid ||
      this.files.length !== 1 ||
      this.errors.length > 0
    );
  }

  public uploadFiles(): void {
    if (!this.cannotUpload()) {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.files[0]);
      formData.append('UploadId', this.form.controls['uploadId'].value);

      if (this.isBibliography) {
        this.samplesService
          .addSpreadsheetBookFiles(formData)
          .pipe(
            catchError((err) => {
              this.loading = false;
              this.toastService.show('GENERIC_FAILURE_FILES', {
                header: 'GENERIC_FAILURE_HEADER',
              });
              throw err;
            })
          )
          .subscribe(() => {
            this.loading = false;
            this.toastService.show('GENERIC_SUCCESS_FILES', {
              header: 'GENERIC_SUCCESS_HEADER',
            });
          });
      } else {
        this.samplesService
          .addSpreadsheetFiles(formData)
          .pipe(
            catchError((err) => {
              this.loading = false;
              this.toastService.show('GENERIC_FAILURE_FILES', {
                header: 'GENERIC_FAILURE_HEADER',
              });
              throw err;
            })
          )
          .subscribe(() => {
            this.loading = false;
            this.toastService.show('GENERIC_SUCCESS_FILES', {
              header: 'GENERIC_SUCCESS_HEADER',
            });
          });
      }
    }
  }

  public checkFiles(): void {
    this.errors = [];
    if (this.files.length > 1) {
      this.errors.push('UPLOAD_FILES_SINGLE_FILE_ERROR');
    }

    if (
      this.files.some((file) => file.name.split('.').slice(-1)[0] !== 'zip')
    ) {
      this.errors.push('UPLOAD_FILES_ZIP_ERROR');
    }
  }
}
