import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { finalize, Subject, takeUntil } from 'rxjs';
import {
  IBirdSpeciesDto,
  ISampleBirdSpeciesDto,
} from 'src/app/core/dtos/bird.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { BirdService } from '../../services/bird.service';

@Component({
  selector: 'app-bird-species',
  templateUrl: './bird-species.component.html',
  styleUrls: ['./bird-species.component.scss'],
})
export class BirdSpeciesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public formGroup!: FormGroup;
  @Input() public species!: FormArray;
  @Input() public speciesById: ISampleBirdSpeciesDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  destroy$ = new Subject<void>();
  public speciesDropdown: IDropdownItem[] = [];
  public speciesForm!: FormGroup;
  public ctSpecies: IBirdSpeciesDto[] = [];
  public genderDropdown: IDropdownItem[] = [
    { id: 'Αρσενικό', name: 'Αρσενικό' },
    { id: 'Θηλυκό', name: 'Θηλυκό' },
  ];
  public ageDropdown: IDropdownItem[] = [
    { id: 'ενήλικο', name: 'Ενήλικο' },
    { id: 'ανήλικο', name: 'Ανήλικο' },
  ];
  public observationDropdown: IDropdownItem[] = [
    { id: 'Flush', name: 'Flush' },
    {
      id: 'Τυπικός εντοπισμός ή Fly over για το πρωτόκολλο σημειακής καταγραφής στρουθιόμορφων',
      name: 'Τυπικός εντοπισμός ή Fly over για το πρωτόκολλο σημειακής καταγραφής στρουθιόμορφων',
    },
  ];
  public behaviorDropdown: IDropdownItem[] = [
    { id: 'ΠΤ', name: 'ΠΤ - Ανεμοπορία πτήση' },
    { id: 'ΚΝ', name: 'ΚΝ - Κούρνια' },
    { id: 'ΤΡ', name: 'ΤΡ - Αναζήτηση - εύρεση τροφής & τροφοληψία' },
    { id: 'ΧΡ', name: 'ΧΡ - Ορισμός - προάστιση χωροκράτειας' },
    { id: 'ΖΕ', name: 'ΖΕ - Δημιουργία ζευγαριών' },
    { id: 'ΦΛ', name: 'ΦΛ - Εντοπισμός θέσεων φωλεοποίησης' },
    { id: 'ΑΥ', name: 'ΑΥ - Ωοθεσία και επώαση αυγών' },
    { id: 'ΤΡμ', name: 'ΤΡμ - Μεταφορά τροφής στα μικρά' },
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private birdService: BirdService
  ) {}

  ngOnInit(): void {
    if (this.speciesById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }

    this.species.valueChanges.subscribe((value) => {
      value.forEach((control: any, index: number) => {
        this.checkSpecies(control['species'], index);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    this.birdService
      .getBirdsSpecies(this.dataVersion)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          if (this.speciesById.length > 0) {
            this.speciesById.forEach(() => {
              this.addNewSpecies();
            });
            this.species.patchValue(this.speciesById);
            this.species.markAsTouched();
            this.speciesById = [];
            if (this.disabledControls === true) {
              this.species.disable();
            }
          }

          return;
        })
      )
      .subscribe((res) => {
        this.ctSpecies = res;
        if (res && res.length > 0) {
          res.forEach((species) => {
            this.speciesDropdown.push({
              id: species.code,
              name: species.code,
            });
          });
        }

        if (res != undefined) {
          this.ctSpecies = res as IBirdSpeciesDto[];
          if (this.ctSpecies.length > 0) {
            this.ctSpecies.forEach((species) => {
              this.speciesDropdown.push({
                id: species.code,
                name: species.code,
                actualId: species.id,
              });
            });
          }
        }
      });
  }

  public checkSpecies(species: string, index: number): void {
    let speciesCode = this.ctSpecies.find((value) => value.code === species);
    if (speciesCode != null) {
      if ((species as string) === speciesCode.code) {
        if (speciesCode.annexI == true) {
          (this.species.controls[index] as FormGroup).controls['pari'].setValue(
            true,
            { emitEvent: false }
          );
        } else {
          (this.species.controls[index] as FormGroup).controls['pari'].setValue(
            false,
            { emitEvent: false }
          );
        }
        if (speciesCode.annexII == true) {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'parii'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexIII == true) {
          (this.species.controls[index] as FormGroup).controls[
            'pariii'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'pariii'
          ].setValue(false, { emitEvent: false });
        }
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.species.controls[index] as FormGroup;
  }

  public removeSpecies(index: number): void {
    this.species.removeAt(index);
  }

  public addNewSpecies(): void {
    this.species.push(this.birdService.initSpeciesFormGroup(this.fb));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
