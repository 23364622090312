import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
export enum ChlorisFormGroup {
  Functions = 'functions',
  Pressures = 'pressures',
  Populations = 'populations',
}

export const chlorisConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
    ],
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 4,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 5,
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
        order: 7,
      },
      {
        header: 'Τοποθεσία',
        type: 'string',
        formName: 'locality',
        order: 12,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 13,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
        order: 14,
      },
      {
        header: 'Παρατηρήσεις δείγματος',
        type: 'string',
        formName: 'observations',
        order: 23,
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
      },
      {
        header: 'Έργο εποπτείας/Φάση έργου',
        type: 'string',
        formName: 'ergoEpoptiaFasiErgou',
        order: 3,
      },
      {
        header: 'Συντάκτης',
        type: 'string',
        formName: 'author',
        order: 6,
      },
      {
        header: 'Συσχέτιση με εποπτεία Ι (Sam_ID)',
        type: 'string',
        formName: 'epoptiaOneId',
        order: 8,
      },
      {
        header: 'Σχόλια συσχέτισης εποπτεία Ι',
        type: 'string',
        formName: 'epoptiaOneComments',
        order: 9,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 10,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 11,
      },
      {
        header: 'Shapefile',
        type: 'string',
        formName: 'shapefile',
        order: 15,
      },
      {
        header: 'Υψόμετρο (m)',
        type: 'string',
        formName: 'altitudeText',
        order: 16,
      },
      {
        header: 'Ανάγλυφο',
        type: 'string',
        formName: 'relief',
        order: 17,
      },
      {
        header: 'Τύπος εδάφους',
        type: 'string',
        formName: 'soil',
        order: 18,
      },
      {
        header: 'Βάθος Α0',
        type: 'string',
        formName: 'a0',
        order: 19,
      },
      {
        header: 'Έκθεση',
        type: 'string',
        formName: 'exposit',
        order: 20,
      },
      {
        header: 'Κλίση',
        type: 'string',
        formName: 'slope',
        order: 21,
      },
      {
        header: 'Υπόστρωμα',
        type: 'string',
        formName: 'substrate',
        order: 22,
      },
      {
        header: 'Πιέσεις/Απειλές',
        type: 'string',
        formName: 'threats',
        order: 24,
      },
      {
        header: 'Ενδιαίτημα',
        type: 'string',
        formName: 'habitat',
        order: 25,
      },
      {
        header: 'Ποιότητα',
        type: 'string',
        formName: 'quality',
        order: 26,
      },
      {
        header: 'Τάση ποιότητας',
        type: 'string',
        formName: 'qualityTrend',
        order: 27,
      },
      {
        header: 'Δυνατότητα αποκατάστασης ποιότητας',
        type: 'string',
        formName: 'qualityFix',
        order: 28,
      },
      {
        header: 'Έκταση',
        type: 'string',
        formName: 'area',
        order: 29,
      },
      {
        header: 'Τάση έκτασης',
        type: 'string',
        formName: 'areaTrend',
        order: 30,
      },
      {
        header: 'Δυνατότητα αποκατάστασης έκτασης',
        type: 'string',
        formName: 'areaFix',
        order: 31,
      },
      {
        header: 'Μονάδα μέτρησης πληθυσμού',
        type: 'string',
        formName: 'unit',
        order: 32,
      },
      {
        header: 'Μέθοδος μέτρησης πληθυσμού',
        type: 'string',
        formName: 'method',
        order: 33,
      },
      {
        header: 'Στρωμάτωση πληθυσμού',
        type: 'string',
        formName: 'strat',
        order: 34,
      },
      {
        header: 'Παρατηρήσεις πληθυσμού',
        type: 'string',
        formName: 'populationObservations',
        order: 35,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: ChlorisFormGroup.Functions,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
        disabled: true,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
        disabled: true,
      },
      {
        header: 'Ειδική Δομή/Λειτουργία',
        type: 'string',
        formName: 'structure',
        order: 3,
      },
      {
        header: 'Κατάσταση Άριστη',
        type: 'boolean',
        formName: 'statusEx',
        order: 4,
      },
      {
        header: 'Κατάσταση Ικανοποιητική',
        type: 'boolean',
        formName: 'statusMed',
        order: 5,
      },
      {
        header: 'Κατάσταση Μη Ικανοποιητική',
        type: 'boolean',
        formName: 'statusBad',
        order: 6,
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: ChlorisFormGroup.Populations,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
        disabled: true,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
        disabled: true,
      },
      {
        header: 'Obs_ID',
        type: 'string',
        formName: 'obsId',
        order: 3,
      },
      {
        header: 'Shapefile',
        type: 'string',
        formName: 'shapefile',
        order: 4,
      },
      {
        header: 'AA polygon',
        type: 'int',
        formName: 'aApolygon',
        order: 5,
      },
      {
        header: 'Μέγεθος δειγματοεπιφάνειας (m x m)',
        type: 'string',
        formName: 'samplingSurface',
        order: 6,
      },
      {
        header: 'Αναπαραγωγικά Α',
        type: 'int',
        formName: 'adult_A',
        order: 7,
      },
      {
        header: 'Αναπαραγωγικά Κ1',
        type: 'int',
        formName: 'adult_K1',
        order: 8,
      },
      {
        header: 'Αναπαραγωγικά Κ2',
        type: 'int',
        formName: 'adult_K2',
        order: 9,
      },
      {
        header: 'Αρτίβλαστα Α',
        type: 'int',
        formName: 'young_A',
        order: 10,
      },
      {
        header: 'Αρτίβλαστα Κ3',
        type: 'int',
        formName: 'young_K2',
        order: 11,
      },
      {
        header: 'Βλαστητικά Α',
        type: 'int',
        formName: 'veget_A',
        order: 12,
      },
      {
        header: 'Βλαστητικά Κ4',
        type: 'int',
        formName: 'veget_K2',
        order: 13,
      },
      {
        header: 'Επιφάνεια πληθυσμού (m2)',
        type: 'int',
        formName: 'populationArea',
        order: 14,
      },
      {
        header: 'Συστάδα min',
        type: 'float',
        formName: 'clusterMin',
        order: 15,
      },
      {
        header: 'Συστάδα max',
        type: 'float',
        formName: 'clusterMax',
        order: 16,
      },
      {
        header: 'Ύψος πληθυσμού',
        type: 'string',
        formName: 'height',
        order: 17,
      },
      {
        header: 'Ζωτικότητα πληθυσμού',
        type: 'int',
        formName: 'vit',
        order: 18,
      },
      {
        header: 'Φύλο πληθυσμού',
        type: 'string',
        formName: 'sex',
        order: 19,
      },
      {
        header: 'Ειδική Δομή πληθυσμού',
        type: 'string',
        formName: 'structure',
        order: 20,
      },
      {
        header: 'Σχόλια πληθυσμού',
        type: 'string',
        formName: 'comment',
        order: 21,
      },
    ],
  },
  // This is the 4th sheet
  {
    formGroupName: ChlorisFormGroup.Pressures,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
        disabled: true,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
        disabled: true,
      },
      {
        header: 'Κωδικός πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsId',
        order: 3,
      },
      {
        header: 'Όνομα είδους πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsDescription',
        order: 4,
        disabled: true,
      },
      {
        header: 'Χρονικός Χαρακτήρας πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'timeCharacter',
        order: 5,
      },
      {
        header: 'Σημασία/Eπίδραση πίεσης ή απειλής',
        type: 'string',
        formName: 'importance',
        order: 6,
      },
      {
        header: 'Στοιχείο που αφορά',
        type: 'string',
        formName: 'target',
        order: 7,
      },
      {
        header: 'Κάλυψη (Wh, Ma, Mi)',
        type: 'string',
        formName: 'cover_Wh_Ma_Mi',
        order: 8,
      },
      {
        header: 'Κάλυψη% πίεσης ή απειλής',
        type: 'float',
        formName: 'cover',
        order: 9,
      },
      {
        header: 'Επεξήγηση πίεσης ή απειλής',
        type: 'string',
        formName: 'explanation',
        order: 10,
      },
      {
        header: 'Ξενικό είδος',
        type: 'string',
        formName: 'foreignSpecies',
        order: 11,
      },
    ],
  },
];
