import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
export enum FishFormGroup {
  Species = 'species',
  Threats = 'threats',
  OtherThreats = 'otherThreats',
}

export const fishConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
    ],
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 4,
      },
      {
        header: 'Ώρα Δειγματοληψίας',
        type: 'time',
        formName: 'time',
        order: 5,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 7,
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
        order: 10,
      },
      {
        header: 'Τοποθεσία',
        type: 'string',
        formName: 'locality',
        order: 11,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 12,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
        order: 13,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 95,
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
      },
      {
        header: 'Έργο εποπτείας / Φάση έργου',
        type: 'string',
        formName: 'ergoEpoptiaFasiErgou',
        order: 3,
      },
      {
        header: 'Διάρκεια',
        type: 'duration',
        formName: 'duration',
        order: 6,
      },
      {
        header: 'Ψαράς',
        type: 'string',
        formName: 'fisher',
        order: 8,
      },
      {
        header: 'Συντάκτης',
        type: 'string',
        formName: 'author',
        order: 9,
      },
      {
        header: 'Shapefile',
        type: 'string',
        formName: 'shapefile',
        order: 14,
      },
      {
        header: 'Υψόμετρο (m)',
        type: 'float',
        formName: 'altitude',
        order: 15,
      },
      {
        header: 'Κωδικός τοποθεσίας',
        type: 'string',
        formName: 'siteCodeText',
        order: 16,
      },
      {
        header: 'Υδάτινο σώμα',
        type: 'string',
        formName: 'course',
        order: 17,
      },
      {
        header: 'Υδρογραφική λεκάνη',
        type: 'string',
        formName: 'hydrographicBasin',
        order: 18,
      },
      {
        header: 'Σταθμός αναφοράς (εκτ.)',
        type: 'string',
        formName: 'referenceSiteEst',
        order: 19,
      },
      {
        header: 'Κλίση %',
        type: 'float',
        formName: 'slope',
        order: 20,
      },
      {
        header: 'Τύπος Εξοπλισμού',
        type: 'string',
        formName: 'samplingEquipment',
        order: 21,
      },
      {
        header: 'Ηλεκτρισμός',
        type: 'string',
        formName: 'electricity',
        order: 22,
      },
      {
        header: 'Μέση Τάση (Volt)',
        type: 'float',
        formName: 'averageVoltage',
        order: 23,
      },
      {
        header: 'Μέση Συχνότητα',
        type: 'float',
        formName: 'averageFrequency',
        order: 24,
      },
      {
        header: 'Δειγματοληπτική προσπάθεια',
        type: 'string',
        formName: 'samplingEffort',
        order: 25,
      },
      {
        header: 'Μέθοδος δειγματοληψίας (Α)',
        type: 'string',
        formName: 'samplingStrategyA',
        order: 26,
      },
      {
        header: 'Μέθοδος δειγματοληψίας (Β)',
        type: 'string',
        formName: 'samplingStrategyB',
        order: 27,
      },
      {
        header: 'Μήκος σταθμού (m)',
        type: 'float',
        formName: 'fishedLength',
        order: 28,
      },
      {
        header: 'Επιφάνεια δειγμ/ψίας (m2)',
        type: 'float',
        formName: 'fishedArea',
        order: 29,
      },
      {
        header: 'Καθεστώς ροής',
        type: 'string',
        formName: 'flowRegime',
        order: 30,
      },
      {
        header: 'Υγρό πλάτος (m)',
        type: 'float',
        formName: 'wettedWidthM',
        order: 31,
      },
      {
        header: 'Μήκος αριστερής όχθης (m)',
        type: 'float',
        formName: 'leftBankM',
        order: 32,
      },
      {
        header: 'Μήκος δεξιάς όχθης (m)',
        type: 'float',
        formName: 'rightBankM',
        order: 33,
      },
      {
        header: 'Ποσοστό επιφάνειας με πλάτος ≤ 1m',
        type: 'float',
        formName: 'width1',
        order: 34,
      },
      {
        header: 'Ποσοστό επιφάνειας με πλάτος μεταξύ 1 και 5m',
        type: 'float',
        formName: 'width5',
        order: 35,
      },
      {
        header: 'Ποσοστό επιφάνειας με πλάτος μεταξύ 5 και 10m',
        type: 'float',
        formName: 'width10',
        order: 36,
      },
      {
        header: 'Ποσοστό επιφάνειας με πλάτος μεταξύ 10 και 20m',
        type: 'float',
        formName: 'width20',
        order: 37,
      },
      {
        header: 'Ποσοστό επιφάνειας με πλάτος ≥ 20m',
        type: 'float',
        formName: 'widthGT20',
        order: 38,
      },
      {
        header: 'Ποσοστό επιφάνειας με βάθος < 0,25m',
        type: 'float',
        formName: 'depth025',
        order: 39,
      },
      {
        header: 'Ποσοστό επιφάνειας με βάθος μεταξύ 0,25 και 0,50m',
        type: 'float',
        formName: 'depth05',
        order: 40,
      },
      {
        header: 'Ποσοστό επιφάνειας με βάθος μεταξύ 0,50 και 1m',
        type: 'float',
        formName: 'depth1',
        order: 41,
      },
      {
        header: 'Ποσοστό επιφάνειας με βάθος > 1m',
        type: 'float',
        formName: 'depthGT1',
        order: 42,
      },
      {
        header: 'Μέσο βάθος (m)',
        type: 'float',
        formName: 'depthMean',
        order: 43,
      },
      {
        header: 'Μέγιστο βάθος (m)',
        type: 'float',
        formName: 'depthMax',
        order: 44,
      },
      {
        header: 'Υπόστρωμα βράχος (%)',
        type: 'float',
        formName: 'subsRock',
        order: 45,
      },
      {
        header: 'Υπόστρωμα ογκόλιθος (%)',
        type: 'float',
        formName: 'subsBoulder',
        order: 46,
      },
      {
        header: 'Υπόστρωμα κροκάλες (%)',
        type: 'float',
        formName: 'subsCobble',
        order: 47,
      },
      {
        header: 'Υπόστρωμα βότσαλα (%)',
        type: 'float',
        formName: 'subsPebble',
        order: 48,
      },
      {
        header: 'Υπόστρωμα χαλίκι (%)',
        type: 'float',
        formName: 'subsGravel',
        order: 49,
      },
      {
        header: 'Υπόστρωμα άμμος (%)',
        type: 'float',
        formName: 'subsSand',
        order: 50,
      },
      {
        header: 'Υπόστρωμα ιλύς (%)',
        type: 'float',
        formName: 'subsSilt',
        order: 51,
      },
      {
        header: 'Υπόστρωμα άργιλος (%)',
        type: 'float',
        formName: 'subsClay',
        order: 52,
      },
      {
        header: 'Υπόστρωμα οργανικό (%)',
        type: 'float',
        formName: 'subsOrganic',
        order: 53,
      },
      {
        header: 'Τεχνητό υπόστρωμα (%)',
        type: 'float',
        formName: 'subsArtificial',
        order: 54,
      },
      {
        header: 'Σκίαση σταθμού (%)',
        type: 'float',
        formName: 'shadeness',
        order: 55,
      },
      {
        header:
          'Επικρατούσες καιρικές συνθήκες κατά τη διάρκεια και πριν τη δειγμ/ψία',
        type: 'string',
        formName: 'weather',
        order: 56,
      },
      {
        header: 'Μέση ταχύτητα ροής στα σημεία δειγματοληψίας, σε (m/sec)',
        type: 'string',
        formName: 'velocityMSec',
        order: 57,
      },
      {
        header: 'Αγωγιμότητα (μS/cm)',
        type: 'float',
        formName: 'conductivitymScm',
        order: 58,
      },
      {
        header: 'Διαλυμένο οξυγόνο σε (mg/L)',
        type: 'float',
        formName: 'o2mgL',
        order: 59,
      },
      {
        header: 'pH',
        type: 'float',
        formName: 'pH',
        order: 60,
      },
      {
        header: 'Θερμοκρασία αέρα (°C)',
        type: 'float',
        formName: 'tofAir',
        order: 61,
      },
      {
        header: 'Θερμοκρασία νερού (°C)',
        type: 'float',
        formName: 'tofWater',
        order: 62,
      },
      {
        header: 'Αλατότητα (ppt)',
        type: 'float',
        formName: 'salinityPpt',
        order: 63,
      },
      {
        header: 'Θολότητα',
        type: 'string',
        formName: 'turbidity',
        order: 64,
      },
      {
        header: 'Ελόφυτα',
        type: 'string',
        formName: 'helophytes',
        order: 65,
      },
      {
        header: 'Ελόφυτα (κυρίαρχο είδος)',
        type: 'string',
        formName: 'helophytesDominatingSp',
        order: 66,
      },
      {
        header: 'Βλάστηση πυθμένα',
        type: 'string',
        formName: 'bottomVegetation',
        order: 67,
      },
      {
        header: 'Βλάστηση πυθμένα (κυρίαρχο είδος)',
        type: 'string',
        formName: 'bottomVegetationDominatingSp',
        order: 68,
      },
      {
        header: 'Ενδιαίτημα εκβαθύνσεων (%)',
        type: 'float',
        formName: 'habTypePool',
        order: 69,
      },
      {
        header: 'Ενδιαίτημα αργών ροών (%)',
        type: 'float',
        formName: 'habTypeGlide',
        order: 70,
      },
      {
        header: 'Ενδιαίτημα γρήγορων ροών (%)',
        type: 'float',
        formName: 'habTypeRun',
        order: 71,
      },
      {
        header: 'Ενδιαίτημα χειμαρρικής ροής (%)',
        type: 'float',
        formName: 'habTypeRiffle',
        order: 72,
      },
      {
        header: 'Ενδιαίτημα καταρρακτών (%)',
        type: 'float',
        formName: 'habTypeRapid',
        order: 73,
      },
      {
        header: 'Ενδιαίτημα άλλο',
        type: 'string',
        formName: 'habTypeOther',
        order: 74,
      },
      {
        header: 'Πιέσεις για το ενδιαίτημα',
        type: 'string',
        formName: 'importantPressures',
        order: 75,
      },
      {
        header: 'Μεγάλα φερτά οργανικά υλικά',
        type: 'boolean',
        formName: 'logsLargeWoodyDebris',
        order: 76,
      },
      {
        header: 'Προεξέχουσα βλάστηση',
        type: 'boolean',
        formName: 'overhangingVegetation',
        order: 77,
      },
      {
        header: 'Πυκνή κάλυψη πυθμένα με μακρόφυτα',
        type: 'boolean',
        formName: 'denseMacrophyteBeds',
        order: 78,
      },
      {
        header: 'Βαθιές εκβαθύνσεις',
        type: 'boolean',
        formName: 'deepPools',
        order: 79,
      },
      {
        header: 'Ογκόλιθοι / Μεγάλες πέτρες',
        type: 'boolean',
        formName: 'bouldersCobbles',
        order: 80,
      },
      {
        header: 'Ποτάμια ροή κάτω από την όχθη',
        type: 'boolean',
        formName: 'undercutBanks',
        order: 81,
      },
      {
        header: 'Κάλυψη πυθμένα με πυκνές ρίζες',
        type: 'boolean',
        formName: 'thickRootMats',
        order: 82,
      },
      {
        header: 'Βαλτώδη όρια',
        type: 'boolean',
        formName: 'marshyFringes',
        order: 83,
      },
      {
        header: 'Απομονωμένες εκβαθύνσεις αποκομμένης ροής',
        type: 'boolean',
        formName: 'isolatedBackwaterPools',
        order: 84,
      },
      {
        header: 'Χειμαρρώδεις ροές',
        type: 'boolean',
        formName: 'riffles',
        order: 85,
      },
      {
        header: 'Άλλοι τύποι φυσικής κάλυψης',
        type: 'string',
        formName: 'otherTypesPhysicalCoverage',
        order: 86,
      },
      {
        header: 'Αποτελεσματικότητα δειγματοληψίας ενδιαιτήματος',
        type: 'string',
        formName: 'efficacyOfHabitatSampling',
        order: 87,
      },
      {
        header: 'Επαρκής ποιότητα ενδιατήματος (περιοχή δειγματοληψίας)',
        type: 'float',
        formName: 'sufficientHabitatQuality',
        order: 88,
      },
      {
        header: 'Ανεπαρκής ποιότητα ενδιατήματος (περιοχή δειγματοληψίας)',
        type: 'float',
        formName: 'insufficientHabitatQuality',
        order: 89,
      },
      {
        header: 'Άγνωστη ποιότητα ενδιαιτήματος (περιοχή δειγματοληψίας)',
        type: 'float',
        formName: 'unknownHabitatQuality',
        order: 90,
      },
      {
        header:
          'Επαρκής έκταση καταλαμβανόμενου ενδιαιτήματος (περιοχή δειγματοληψίας)',
        type: 'string',
        formName: 'sufficientHabitatArea',
        order: 91,
      },
      {
        header:
          'Επαρκής ποιότητα καταλαμβανόμενου ενδιαιτήματος (περιοχή δειγματοληψίας)',
        type: 'string',
        formName: 'sufficientHabitatXQuality',
        order: 92,
      },
      {
        header:
          'Επαρκώς μεγάλη έκταση μη καταλαμβανόμενου ενδιαιτήματος κατάλληλης ποιότητας (περιοχή δειγματοληψίας)',
        type: 'string',
        formName: 'insufficientHabitatArea',
        order: 93,
      },
      {
        header: 'Πιέσεις/Απειλές Ενδιαιτήματος',
        type: 'string',
        formName: 'habitatThreats',
        order: 94,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: FishFormGroup.Species,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 2,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 3,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 4,
        disabled: true,
      },
      {
        header: 'Obs_ID',
        type: 'string',
        formName: 'obsId',
        order: 5,
      },
      {
        header: 'Νεαρά άτομα είδους',
        type: 'int',
        formName: 'yofY',
        order: 6,
      },
      {
        header: 'Ενήλικα άτομα είδους',
        type: 'int',
        formName: 'adults',
        order: 7,
      },
      {
        header: 'Σύνολο ατόμων είδους',
        type: 'int',
        formName: 'sumOfIndivid',
        order: 8,
      },
      {
        header: 'Ενδιαίτημα είδους',
        type: 'string',
        formName: 'speciesHabitat',
        order: 9,
      },
      {
        header: '% Παρασίτων, Ανωμαλιών (εκτ.) είδους',
        type: 'float',
        formName: 'parasitesPerCent',
        order: 10,
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: FishFormGroup.Threats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 2,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 3,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 4,
        disabled: true,
      },
      {
        header: 'Κωδικός πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'hDCode',
        order: 5,
      },
      {
        header: 'Όνομα είδους πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsDescription',
        order: 6,
        disabled: true,
      },
      {
        header: 'Έκταση αναφοράς',
        type: 'string',
        formName: 'siteOrSegment',
        order: 7,
      },
      {
        header: 'Ποσοστό πληθυσμού που επηρεάζεται',
        type: 'string',
        formName: 'populationPercentage',
        order: 8,
      },
      {
        header: 'Σημασία / Επίδραση πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'impact',
        order: 9,
      },
      {
        header: 'Χρονικός Χαρακτήρας πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'timeCharacter',
        order: 10,
      },
      {
        header: 'Τρόπος Επίδρασης πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'impactType',
        order: 11,
      },
    ],
  },
  // This is the 4th sheet
  // {
  //   formGroupName: FishFormGroup.OtherThreats,
  //   formType: 'formArray',
  //   headerNumber: 'A/A',
  //   headerAssignedNumber: 'Αφορά A/A Δείγματος',
  //   configuration: [
  //     {
  //       header: 'Άλλες πιέσεις και απειλές',
  //       type: 'string',
  //       formName: 'otherPressuresAndThreats',
  //     },
  //   ],
  // },
];
