<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-2">
    <div class="sub-category-title">
      {{ "1120C" | translate }}
    </div>
    <ng-container *ngFor="let cForm of c.controls; let index = index">
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getCGroup(index)"
          [formName]="'higherLimitDepth'"
          [label]="'HIGHER_LIMIT_DEPTH'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getCGroup(index)"
          [formName]="'latitude'"
          [label]="'LATITUDE'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getCGroup(index)"
          [formName]="'longitude'"
          [label]="'LONGTITUDE'"
          [required]="true"
        ></app-input>
      </div>
      <app-textarea
        [style.min-width]="'100%'"
        [formGroup]="getCGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>
      <div class="form-row form-3">
        <app-button
          (buttonClicked)="removeC(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== c.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_RECORD'"
      (buttonClicked)="addNewC()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
  <br />
  <div class="seperator"></div>
  <div formArrayName="species" class="form-container form-2">
    <div class="sub-category-title">
      {{ "1120A" | translate }}
    </div>
    <ng-container *ngIf="touched && hasErrors">
      <ng-container
        *ngIf="
          errors != null &&
          errors['duplicatePicket'] !== null &&
          errors['duplicatePicket']
        "
      >
        <div class="error-message">
          {{ "PICKET_ERROR" | translate }}
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let aForm of a.controls; let index = index">
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getAGroup(index)"
          [formName]="'picket'"
          [label]="'PICKET'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getAGroup(index)"
          [formName]="'linearDistance'"
          [label]="'LINEAR_DISTANCE'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getAGroup(index)"
          [formName]="'lowestLimitDepth'"
          [label]="'LOWEST_LIMIT_DEPTH'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row form-3">
        <app-input
          [type]="'number'"
          [formGroup]="getAGroup(index)"
          [formName]="'latitude'"
          [label]="'LATITUDE'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getAGroup(index)"
          [formName]="'longitude'"
          [label]="'LONGTITUDE'"
          [required]="true"
        ></app-input>
        <app-dropdown
          [formGroup]="getAGroup(index)"
          [formName]="'lowestLimitTypology'"
          [label]="'LOWEST_LIMIT_TOPOLOGY'"
          [dropdownList]="typologyDropdown"
          [required]="true"
          [preDefinedId]="
            getAGroup(index).controls['lowestLimitTypology'].value
          "
        ></app-dropdown>
      </div>
      <app-textarea
        [style.min-width]="'100%'"
        [formGroup]="getAGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>
      <div class="form-row form-3">
        <app-button
          (buttonClicked)="removeA(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== a.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_RECORD'"
      (buttonClicked)="addNewA()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
