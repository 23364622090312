import { Component } from '@angular/core';

@Component({
  selector: 'app-book-habitats',
  templateUrl: './book-habitats.component.html',
  styleUrls: ['./book-habitats.component.scss']
})
export class BookHabitatsComponent {

}
