import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { finalize, forkJoin, Subject, takeUntil } from 'rxjs';
import {
  IBirdSpeciesDto,
  IBirdThreatsDto,
  ISampleBirdThreatsDto,
} from 'src/app/core/dtos/bird.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { BirdService } from '../../services/bird.service';

@Component({
  selector: 'app-bird-threats',
  templateUrl: './bird-threats.component.html',
  styleUrls: ['./bird-threats.component.scss'],
})
export class BirdThreatsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: ISampleBirdThreatsDto[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  private destroy$ = new Subject<void>();
  public threatsForm!: FormGroup;
  public birdsThreats: IBirdThreatsDto[] = [];
  public birdsSpecies: IBirdSpeciesDto[] = [];
  public threatsDropdown: IDropdownItem[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public typeDropdown: IDropdownItem[] = [
    { id: 'P(πίεση)', name: 'P(πίεση)' },
    { id: 'T(απειλή)', name: 'T(απειλή)' },
    { id: 'P or T(και τα δύο)', name: 'P or T(και τα δύο)' },
  ];
  public importanceDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'L',
    },
    {
      id: 'M',
      name: 'M',
    },
    {
      id: 'H',
      name: 'H',
    },
  ];
  public typeOfThreatDropdown: IDropdownItem[] = [
    {
      id: 'i',
      name: 'i',
    },
    {
      id: 'o',
      name: 'o',
    },
    {
      id: 'b',
      name: 'b',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private birdService: BirdService
  ) {}

  ngOnInit(): void {
    if (this.threatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.birdService.getBirdsThreats(this.dataVersion),
      this.birdService.getBirdsSpecies(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.birdsThreats.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.birdsThreats.forEach((threat) => {
              dropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
            this.threatsDropdown = dropdown;
          }

          if (this.birdsSpecies.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.birdsSpecies.forEach((species) => {
              dropdown.push({
                id: species.code,
                name: species.code,
                actualId: species.id,
              });
            });
            this.speciesDropdown = dropdown;
          }

          if (this.threatsById.length > 0) {
            this.threatsById.forEach(() => {
              this.addNewThreats();
            });
            this.threats.patchValue(this.threatsById);
            this.threats.markAsTouched();
            this.threatsById = [];
            if (this.disabledControls === true) {
              this.threats.disable();
            }
          }
          return;
        })
      )
      .subscribe(([birdsThreats, birdsSpecies]) => {
        if (birdsThreats != undefined) {
          this.birdsThreats = birdsThreats as IBirdThreatsDto[];
        }

        if (birdsSpecies != undefined) {
          this.birdsSpecies = birdsSpecies as IBirdSpeciesDto[];
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    this.threats.push(this.birdService.initThreatsFormGroup(this.fb));
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
