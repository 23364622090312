<div class="map-container">
  <div class="map-frame">
    <div id="map-{{ mapId }}"></div>
  </div>
  <div class="map-search-options">
    <div class="options-group">
      <app-button
        class="completion-btn"
        (buttonClicked)="addCircle()"
        [btnText]="'ADD_CIRCLE'"
        [isDisabled]="circle != null"
        [style.--btnBgColor]="'var(--color-secondary)'"
        [style.--btnWidth]="'250px'"
      ></app-button>
      <app-button
        class="completion-btn"
        (buttonClicked)="removeCircle()"
        [isDisabled]="circle == null"
        [btnText]="'REMOVE_CIRCLE'"
        [style.--btnBgColor]="'var(--color-secondary)'"
        [style.--btnWidth]="'250px'"
      ></app-button>
    </div>
    <div class="options-group">
      <app-button
        class="completion-btn"
        (buttonClicked)="addPolygonDraw()"
        [btnText]="'ADD_POLYGON'"
        [style.--btnBgColor]="'var(--color-secondary)'"
        [style.--btnWidth]="'250px'"
      ></app-button>
      <app-button
        class="completion-btn"
        (buttonClicked)="removePolygonDraw()"
        [isDisabled]="polygonPoints == null || polygonPoints.length === 0"
        [btnText]="'REMOVE_POLYGON'"
        [style.--btnBgColor]="'var(--color-secondary)'"
        [style.--btnWidth]="'250px'"
      ></app-button>
    </div>
    <app-button
      class="completion-btn"
      (buttonClicked)="search()"
      [isDisabled]="
        circle == null && (polygonPoints == null || polygonPoints.length === 0)
      "
      [btnText]="'SEARCH_MAP'"
      [style.--btnBgColor]="'var(--color-header)'"
      [style.--btnWidth]="'250px'"
    ></app-button>
  </div>
  <!-- <div class="map-layers">
    <div class="map-layers-title">
      <div class="map-layers-title-text">
        {{ "MAP_LAYERS" | translate }}
      </div>
      <div>
        <img
          src="assets/images/question.svg"
          ngbTooltip="{{ 'MAP_LAYERS_INFO' | translate }}"
        />
      </div>
    </div>
    <div class="map-layers-items">
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="naturaLayerToggle"
          (ngModelChange)="toggleNaturaLayer()"
        />
        {{ "NATURA_SITES" | translate }}
      </label>
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="gridLayerToggle"
          (ngModelChange)="toggleGridLayer()"
        />
        Grid Cells
      </label>
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="samplesLayerToggle"
          (ngModelChange)="toggleSamplesLayer()"
        />
        {{ "MAP_LAYER_SAMPLES" | translate }}
      </label>
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="speciesLayerToggle"
          (ngModelChange)="toggleSpeciesLayer()"
        />
        {{ "MAP_LAYER_SPECIES" | translate }}
      </label>
      <label *ngIf="hasRandom" class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="randomSpeciesLayerToggle"
          (ngModelChange)="toggleRandomSpeciesLayer()"
        />
        {{ "MAP_LAYER_RANDOM_SPECIES" | translate }}
      </label>

      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="seaLayerToggle"
          (ngModelChange)="toggleSeaLayer()"
        />
        {{ "SEA_LAYER" | translate }}
      </label>
      <label class="toggle btn btn-secondary" ngbButtonLabel>
        <input
          type="checkbox"
          [(ngModel)]="landLayerToggle"
          (ngModelChange)="toggleLandLayer()"
        />
        {{ "LAND_LAYER" | translate }}
      </label>
    </div>
  </div> -->
</div>
