export enum Protocols {
  Bibliography = 0,
  Habitats = 1,
  Mammals = 2,
  Chloris = 3,
  Amphibian = 4,
  Fish = 5,
  Sea = 6,
  Invertebrates = 7,
  Bird = 8,
  SeaHabitat = 9,
}
