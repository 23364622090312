import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
export enum BibliographyFormGroup {
  Entries = 'entries',
}
export const bibliographyConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    configuration: [
      {
        header: 'Έργο εποπτείας / Φάση έργου',
        type: 'string',
        formName: 'ergoEpoptiaFasiErgou',
        order: 1,
      },
      {
        header: 'Κωδικός Αναφοράς',
        type: 'string',
        formName: 'referenceId',
        order: 2,
      },
      {
        header: 'Ομάδα ειδών/T.O.',
        type: 'string',
        formName: 'speciesCategory',
        order: 3,
      },
      {
        header: 'Τύπος Αναφοράς (Βιβλίο, Περιοδικό, Συνέδριο κλπ)',
        type: 'string',
        formName: 'referenceTypeCode',
        order: 4,
      },
      {
        header: 'Πλήρης Αναφορά',
        type: 'string',
        formName: 'fullReference',
        order: 5,
      },
      {
        header: 'Συντμημένη αναφορά',
        type: 'string',
        formName: 'shortReference',
        order: 6,
      },
      {
        header: 'Τίτλος άρθρου σε περιοδικό ή  κεφαλαίου σε βιβλίο',
        type: 'string',
        formName: 'articleTitle',
        order: 7,
      },
      {
        header: 'Ονόματα συγγραφέων',
        type: 'string',
        formName: 'authors',
        order: 8,
      },
      {
        header: 'Έτος έκδοσης',
        type: 'int',
        formName: 'publicationYear',
        order: 9,
      },
      {
        header: 'Έτος από - έως',
        type: 'string',
        format: '####-####',
        formName: 'fromYearTo',
        order: 10,
      },
      {
        header: 'Τίτλος περιοδικού',
        type: 'string',
        formName: 'magazine',
        order: 11,
      },
      {
        header: 'Τίτλος βιβλίου',
        type: 'string',
        formName: 'book',
        order: 12,
      },
      {
        header: 'ISSN',
        type: 'string',
        formName: 'issn',
        order: 13,
      },
      {
        header: 'ISBN',
        type: 'string',
        formName: 'isbn',
        order: 14,
      },
      {
        header: 'DOI',
        type: 'string',
        formName: 'doi',
        order: 15,
      },
      {
        header: 'Τίτλος συνεδρίου',
        type: 'string',
        formName: 'congress',
        order: 16,
      },
      {
        header: 'Εκδότης',
        type: 'string',
        formName: 'publisher',
        order: 17,
      },
      {
        header: 'Επιμελητής',
        type: 'string',
        formName: 'editor',
        order: 18,
      },
      {
        header: 'Διεύθυνση URL',
        type: 'string',
        formName: 'url',
        order: 19,
      },
      {
        header: 'Ημερομηνία Λήψης',
        type: 'date',
        formName: 'downloadedOn',
        order: 20,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: BibliographyFormGroup.Entries,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Κωδικός Αναφοράς',
    splitHeaderToMap: true,
    validateLocation: [
      ['Τετμημένη X (EGSA) σε m', 'Τεταγμένη Y (EGSA) σε m', true],
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
    ],
    configuration: [
      {
        header: 'Κωδικός Αναφοράς',
        type: 'string',
        formName: 'referenceId',
        order: 1,
        disabled: true,
      },
      {
        header: 'SpRef_ID',
        type: 'string',
        formName: 'spRef_ID',
        order: 2,
      },
      {
        header: 'Ομάδα ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 3,
        disabled: true,
      },
      {
        header: 'Ονομασία είδους',
        type: 'string',
        formName: 'speciesId',
        order: 4,
      },
      {
        header: 'Ονομασία είδους ΆΛΛΟ',
        type: 'string',
        formName: 'speciesIdOther',
        order: 5,
      },
      {
        header: 'Κωδικός Τύπου Οικοτόπου',
        type: 'string',
        formName: 'habitatId',
        order: 6,
      },
      {
        header: 'Συνταξινομική Μονάδα (Τύποι οικοτόπων)',
        type: 'string',
        formName: 'syntaxa',
        order: 7,
      },
      {
        header: 'Τετμημένη X (EGSA) σε m',
        type: 'float',
        formName: 'xegsa',
        order: 8,
      },
      {
        header: 'Τεταγμένη Y (EGSA) σε m',
        type: 'float',
        formName: 'yegsa',
        order: 9,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'latitude',
        order: 10,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'longitude',
        order: 11,
      },
      {
        header: 'Υψόμετρο (m)',
        type: 'float',
        formName: 'altitude',
        order: 12,
      },
      {
        header: 'Κωδικός Κελιού Πλέγματος 10x10km (ETRS LAEA)',
        type: 'string',
        formName: 'gridCell',
        order: 13,
      },
      {
        header: 'Περιφερειακή ενότητα',
        type: 'string',
        formName: 'regionalUnit',
        order: 14,
      },
      {
        header: 'Περιοχή',
        type: 'string',
        formName: 'region',
        order: 15,
      },
      {
        header: 'Τοποθεσία (Τοπωνύμιο)',
        type: 'string',
        formName: 'location',
        order: 16,
      },
      {
        header: 'Θαλάσσια Περιοχή',
        type: 'string',
        formName: 'seaRegion',
        order: 17,
      },
      {
        header: 'Κωδικός περιοχής NATURA (SCI)',
        type: 'string',
        formName: 'naturaCode',
        order: 18,
      },
      {
        header: 'Υδάτινο σώμα (Ιχθυοπανίδα)',
        type: 'string',
        formName: 'waterBody',
        order: 19,
      },
      {
        header: 'Shapefile',
        type: 'string',
        formName: 'shapefile',
        order: 20,
      },
      {
        header: 'Φυτογεωγραφική περιοχή (Χλωρίδα)',
        type: 'string',
        formName: 'floristicRegion',
        order: 21,
      },
      {
        header: 'Ακρίβεια θέσης',
        type: 'string',
        formName: 'positionAccuracy',
        order: 22,
      },
      {
        header: 'Μέθοδος Δειγματοληψίας',
        type: 'string',
        formName: 'samplingMethod',
        order: 23,
      },
      {
        header: 'Αξιοπιστία μεθόδου',
        type: 'string',
        formName: 'methodReliability',
        order: 24,
      },
      {
        header: 'Αξιοπιστία πηγής',
        type: 'string',
        formName: 'sourceReliability',
        order: 25,
      },
      {
        header: 'Κωδικός αριθμός Φυτοληψίας TURBOVEG (Χλωρίδα)',
        type: 'string',
        formName: 'releveCode',
        order: 26,
      },
      {
        header: 'Κατάσταση διατήρησης',
        type: 'string',
        formName: 'conservationStatus',
        order: 27,
      },
      {
        header: 'Σχετική Αφθονία',
        type: 'string',
        formName: 'abundance',
        order: 28,
      },
      {
        header: 'Πλήθος',
        type: 'int',
        formName: 'numberOfPeople',
        order: 29,
      },
      {
        header: 'Πλήθος ζευγών',
        type: 'int',
        formName: 'numberOfPairs',
        order: 30,
      },
      {
        header: 'Πλήθος ατόμων Min',
        type: 'int',
        formName: 'minNumberOfPeople',
        order: 31,
      },
      {
        header: 'Πλήθος ατόμων Max',
        type: 'int',
        formName: 'maxNumberOfPeople',
        order: 32,
      },
      {
        header: 'Μονάδα Μέτρησης',
        type: 'string',
        formName: 'measurementUnit',
        order: 33,
      },
      {
        header: 'Πληθυσμιακή Ένδειξη',
        type: 'string',
        formName: 'populationIndicator',
        order: 34,
      },
    ],
  },
];
