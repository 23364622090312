<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container">
    <ng-container
      *ngFor="let speciesForm of species.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
        ></app-dropdown>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'otherSpecies'"
          [label]="'OTHER_SPECIES'"
          [ngClass]="{
            disabled:
              getGroup(index).controls['otherSpecies'].disabled &&
              getGroup(index).controls['otherSpecies'].value === ''
          }"
        ></app-input>
      </div>

      <div class="form-row">
        <div class="checkbox-container">
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexII" />
            {{ "PARII" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexIV" />
            {{ "PARIV" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.annexV" />
            {{ "PARV" | translate }}
          </label>
        </div>
      </div>

      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'populationDensity'"
          [label]="'POPULATION_DENSITY'"
        ></app-input>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'relativeAbundance'"
          [label]="'RELATIVE_ABUDANCE'"
          [dropdownList]="abundanceDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['relativeAbundance'].value"
        ></app-dropdown>
      </div>
      <app-textarea
        [style.min-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'observations'"
        [label]="'OBSERVATIONS'"
      ></app-textarea>
      <div class="form-row form-3">
        <app-button
          (buttonClicked)="removeSpecies(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'REMOVE_SPECIES'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== species.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_SPECIES'"
      (buttonClicked)="addNewSpecies()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
