import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() btnText!: string;
  @Input() isDisabled = false;
  @Input() tooltip = '';
  @Input() isExport = false;
  @Output() public buttonClicked: EventEmitter<void> = new EventEmitter();
  @Output() public closeButtonClicked: EventEmitter<void> = new EventEmitter();
}
