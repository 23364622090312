import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  IMammalsDto,
  IMammalSpeciesDto,
  IMammalThreatsDto,
} from 'src/app/core/dtos/mammals.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ISampleIdResponse,
  ISpreadsheetFileUploadPayload,
} from 'src/app/shared/dtos/generic.dto';
import { dateValidator } from 'src/app/shared/validators/dateValidator';
import { environment } from 'src/environments/environment';
import { SamplesService } from './samples.service';
import { IFileDeleteRequest } from 'src/app/shared/files-container/files-container.component';

@Injectable({
  providedIn: 'root',
})
export class MammalsService {
  constructor(
    private http: HttpClient,
    public common: CommonService,
    private sampleService: SamplesService
  ) {}

  public getMammals(dataVersion?: string): Observable<IMammalsDto[]> {
    const url = environment.endpoint + 'Mammals/all';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMammalsDto[]>(url, { params: params });
  }

  public getMammalById(id: number): Observable<IMammalsDto> {
    const url = environment.endpoint + 'Mammals';
    const params = new HttpParams().set('id', id);
    return this.http.get<IMammalsDto>(url, { params: params });
  }

  public putMammal(
    id: number,
    payload: IMammalsDto
  ): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Mammals';
    const params = new HttpParams().set('id', id);
    return this.http.put<ISampleIdResponse>(url, payload, { params: params });
  }

  public addMammal(payload: IMammalsDto): Observable<ISampleIdResponse> {
    const url = environment.endpoint + 'Mammals';
    return this.http.post<ISampleIdResponse>(url, payload);
  }

  public addBatchMammal(
    payload: IMammalsDto[]
  ): Observable<ISpreadsheetFileUploadPayload> {
    const url = environment.endpoint + 'Mammals/addBatch';
    return this.http.post<ISpreadsheetFileUploadPayload>(url, payload);
  }

  public addFiles(payload: FormData): Observable<void> {
    const url = environment.endpoint + 'Mammals/files';
    return this.http.post<void>(url, payload);
  }

  public removeFiles(payload: IFileDeleteRequest): Observable<void> {
    const url = environment.endpoint + 'Mammals/remove-files';
    return this.http.post<void>(url, payload);
  }

  public getMammalsSpecies(
    dataVersion?: string
  ): Observable<IMammalSpeciesDto[]> {
    const url = environment.endpoint + 'Mammals/species';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMammalSpeciesDto[]>(url, { params: params });
  }

  public getMammalsThreats(
    dataVersion?: string
  ): Observable<IMammalThreatsDto[]> {
    const url = environment.endpoint + 'Mammals/threats';
    const params = new HttpParams().set('DataVersion', dataVersion ?? '');
    return this.http.get<IMammalThreatsDto[]>(url, { params: params });
  }

  private objectToFormData(
    obj: any,
    formData: FormData,
    parentKey = ''
  ): FormData {
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const item = value[i];
          if (typeof item === 'object' && !(item instanceof File)) {
            this.objectToFormData(item, formData, `${newKey}[${i}]`);
          } else {
            formData.append(`${newKey}[]`, item);
          }
        }
      } else if (value instanceof File) {
        formData.append(key, value);
      } else if (typeof value === 'object') {
        this.objectToFormData(value, formData, newKey);
      } else {
        formData.append(newKey, value);
      }
    }

    return formData;
  }

  public initMammalFormGroup(fb: FormBuilder): FormGroup<any> {
    let sample = this.sampleService.initSamplesFormGroup(fb);

    sample = fb.group({
      ...sample.controls,
      duration: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      // typosOikotopou: fb.control(''),
      habitat: fb.control(''),
      // sampleSurface: fb.control(''),
      altitude: fb.control('', [Validators.min(0), Validators.max(2800)]),
      method: fb.control('', [Validators.required]),
      ergoEpoptiaFasiErgou: fb.control('', [Validators.required]),
      speciesCategory: fb.control('', [Validators.required]),
      author: fb.control(''),
      courseLength: fb.control('', [
        Validators.min(0),
        Validators.max(100000000),
      ]),
      capture: fb.control(false),
      temperature: fb.control('', [Validators.min(-10), Validators.max(45)]),
      windIntensity: fb.control('', [Validators.min(0), Validators.max(10)]),
      cloudiness: fb.control(''),
      raining: fb.control(''),
      shapefile: fb.control(''),
      habitatThreats: fb.control(''),
      species: fb.array([]),
      threats: fb.array([], [this.uniqueThreats()]),
      habitats: fb.array([]),
      isWGS84: fb.control(true),
      isEGSA: fb.control(false),
      samId: fb.control('', [Validators.required]),
      randomObservation: fb.control(false),
    });
    return sample;
  }

  public uniqueThreats(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let foundDuplicate = false;
      (control as FormArray).controls.forEach((threat) => {
        const filteredArray = (control as FormArray).controls.filter((item) => {
          return (
            (item as FormGroup).controls['pressuresAndThreatsId'].value ===
              (threat as FormGroup).controls['pressuresAndThreatsId'].value &&
            (item as FormGroup).controls['speciesId'].value ===
              (threat as FormGroup).controls['speciesId'].value &&
            (item as FormGroup).controls['speciesIdOther'].value ===
              (threat as FormGroup).controls['speciesIdOther'].value
          );
        });

        if (filteredArray.length > 1) {
          foundDuplicate = true;
        }
      });

      return foundDuplicate ? { duplicatePressure: true } : null;
    };
  }

  public initSpeciesFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      species: fb.control('', [Validators.required]),
      // age: fb.control('', [Validators.min(0)]),
      // gender: fb.control(''),
      numberOfPeople: fb.control('', [
        Validators.min(0),
        Validators.pattern(/^\d+$/),
      ]),
      // relativeAbundance: fb.control(''),
      observations: fb.control(''),
      eok: fb.control(''),
      parii: fb.control(false),
      pariv: fb.control(false),
      parv: fb.control(false),
      obs_ID: fb.control(''),
      date: fb.control('', {
        validators: [Validators.required, dateValidator()],
      }),
      otherSpecies: fb.control(''),
      notes: fb.control(''),
      activities: fb.control(''),
      biomarker: fb.control(''),
      biomarkerQuantity: fb.control('', [
        Validators.min(0),
        Validators.max(100000000),
      ]),
      biomarkerQuality: fb.control(''),
      habitatCode1: fb.control('', [Validators.required]),
      habitatCode2: fb.control(''),
      habitatCode3: fb.control(''),
      microHabitat: fb.control(''),
      pointX: fb.control('', [
        this.common.latLongValidator('pointX', 'pointY'),
      ]),
      pointY: fb.control('', [
        this.common.latLongValidator('pointX', 'pointY'),
      ]),
      altitude: fb.control('', [Validators.min(0), Validators.max(2800)]),
      habitatQuality: fb.control(''),
    });
  }

  public initThreatsFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      pressuresAndThreatsId: fb.control('', [Validators.required]),
      speciesId: fb.control('', [Validators.required]),
      // type: fb.control('', [Validators.required]),
      importance: fb.control(''),
      speciesIdOther: fb.control(''),
      timeCharacter: fb.control(''),
      effectMode: fb.control(''),
    });
  }

  public initHabitatsFormGroup(fb: FormBuilder): FormGroup<any> {
    return fb.group({
      id: fb.control(''),
      habitatCode1: fb.control('', [Validators.required]),
      habitatCode2: fb.control(''),
      habitatCode3: fb.control(''),
      humanActivities: fb.control(''),
    });
  }

  public prepareMammalRequest(
    mammalForm: FormGroup<any>,
    fromSpreadsheet = false,
    ctSpecies: IMammalSpeciesDto[] = [],
    editMode = false
  ): IMammalsDto {
    const mammal: IMammalsDto = {};
    const species = mammalForm.controls['species'] as FormArray;
    const threats = mammalForm.controls['threats'] as FormArray;
    const habitats = mammalForm.controls['habitats'] as FormArray;

    if (mammalForm?.controls['randomObservation'].value) {
      mammal.randomObservation = true;
      mammal.protocolId = Protocols.Mammals;
      mammal.ergoEpoptiaFasiErgou = mammalForm?.value.ergoEpoptiaFasiErgou;
      mammal.speciesCategory = mammalForm?.value.speciesCategory;
    } else {
      mammal.randomObservation = false;

      mammal.date = this.common.formatDate(mammalForm?.value.date);
      mammal.naturaCode = mammalForm?.value.naturaCode;
      mammal.samplingCode = mammalForm?.value.samplingCode;
      mammal.financialInstrument = mammalForm?.value.financialInstrument;
      mammal.prefecture = mammalForm?.value.prefecture;
      mammal.researcher = mammalForm?.value.researcher;
      mammal.time = this.common.formatTime(mammalForm?.value.time);
      mammal.locality = mammalForm?.value.locality;
      mammal.observations = mammalForm?.value.observations;
      mammal.file = mammalForm?.value.file;
      mammal.gridCell = mammalForm?.value.gridCell;
      mammal.photo = mammalForm?.value.photo;
      mammal.duration = mammalForm?.value.duration;
      mammal.habitat = mammalForm?.value.habitat;
      mammal.altitude = mammalForm?.value.altitude;
      mammal.method = mammalForm?.value.method;
      mammal.pointX = mammalForm.value.lat;
      mammal.pointY = mammalForm.value.long;
      mammal.protocolId = Protocols.Mammals;

      mammal.ergoEpoptiaFasiErgou = mammalForm?.value.ergoEpoptiaFasiErgou;
      mammal.speciesCategory = mammalForm?.value.speciesCategory;
      mammal.author = mammalForm?.value.author;
      mammal.courseLength = mammalForm?.value.courseLength;
      mammal.capture = mammalForm?.value.capture;
      mammal.temperature = mammalForm?.value.temperature;
      mammal.windIntensity = mammalForm?.value.windIntensity;
      mammal.cloudiness = mammalForm?.value.cloudiness;
      mammal.raining = mammalForm?.value.raining;
      mammal.shapefile = mammalForm?.value.shapefile;
      mammal.habitatThreats = mammalForm?.value.habitatThreats;
    }

    if (
      mammal.method !== 'B' &&
      mammal.method !== 'J' &&
      mammal.method !== 'N'
    ) {
      mammal.capture = undefined;
    }

    if (
      mammal.method !== 'D' &&
      mammal.method !== 'E' &&
      mammal.method !== 'M' &&
      mammal.method !== 'N'
    ) {
      mammal.courseLength = undefined;
    }

    if (fromSpreadsheet === true) {
      mammal.samId = mammalForm?.value.samId;

      mammal.species = mammalForm.value.species;
      if (mammal.species && mammal.species.length === 0) {
        mammal.species = undefined;
      } else if (mammal.species && mammal.species.length > 0) {
        mammal.species.forEach((item) => {
          if (item.species === 'Άλλο') {
            item.species = undefined;
          } else {
            item.species = ctSpecies
              .find((itemInner) => itemInner.code === item.species)
              ?.id?.toString();
            item.otherSpecies = undefined;
          }
          if (mammal.method !== 'I') {
            item.biomarker = undefined;
            item.biomarkerQuantity = undefined;
            item.biomarkerQuality = undefined;
          }
          if (!!item.date) {
            item.date = this.common.formatDate(item.date.toString());
          }
        });
      }

      mammal.threats = mammalForm.value.threats;
      if (mammal.threats && mammal.threats.length === 0) {
        mammal.threats = undefined;
      } else if (mammal.threats && mammal.threats.length > 0) {
        mammal.threats.forEach((item) => {
          if (item.speciesId === 'Άλλο') {
            item.speciesId = undefined;
          } else {
            item.speciesId = ctSpecies
              .find((itemInner) => itemInner.code === item.speciesId)
              ?.id?.toString();
            item.speciesIdOther = undefined;
          }
        });
      }

      mammal.habitats = mammalForm.value.habitats;
      if (mammal.habitats && mammal.habitats.length === 0) {
        mammal.habitats = undefined;
      }
    } else {
      let speciesSubCategory = '';
      if (mammalForm?.value.speciesCategory === 'Χειρόπτερα') {
        speciesSubCategory = 'BA';
      } else if (mammalForm?.value.speciesCategory === 'Οπληφόρα') {
        speciesSubCategory = 'UN';
      } else if (mammalForm?.value.speciesCategory === 'Σαρκοφάγα') {
        speciesSubCategory = 'CA';
      } else if (mammalForm?.value.speciesCategory === 'Μικροθηλαστικά') {
        speciesSubCategory = 'SM';
      }

      let samId = '';
      if (!editMode) {
        if (mammalForm?.controls['randomObservation'].value) {
          samId =
            'RANDOM_' +
            mammalForm?.value.ergoEpoptiaFasiErgou +
            '_THI_0000_' +
            speciesSubCategory;
          mammal.samId = samId;
        } else {
          const timestamp = Date.now().toString();
          samId =
            mammalForm?.value.samplingCode +
            '_' +
            mammalForm?.value.ergoEpoptiaFasiErgou +
            '_THI_0000_' +
            speciesSubCategory +
            '_' +
            timestamp;

          mammal.samId = samId;
        }
      }

      mammal.species = species?.value;
      if (!species?.touched) {
        mammal.species = undefined;
      } else {
        if (mammal.species && mammal.species.length > 0) {
          const ctOtherSpecies = ctSpecies.find((item) => {
            return item.code === 'Άλλο';
          });
          mammal.species.forEach((item, index) => {
            if (item.species === ctOtherSpecies?.id) {
              item.species = undefined;
            } else {
              item.otherSpecies = undefined;
            }
            if (mammal.method !== 'I') {
              item.biomarker = undefined;
              item.biomarkerQuantity = undefined;
              item.biomarkerQuality = undefined;
            }
            if (!editMode) {
              item.obs_ID =
                samId + '_' + (index + 1).toString().padStart(3, '0');
            }
            if (!!item.date) {
              item.date = this.common.formatDate(item.date.toString());
            }
          });
        }
      }
      if (!threats?.touched) {
        mammal.threats = undefined;
      } else {
        mammal.threats = threats?.value;
      }

      if (!habitats?.touched) {
        mammal.habitats = undefined;
      } else {
        mammal.habitats = habitats?.value;
      }
    }

    return mammal;
  }
}
