<div class="two-col-container">
  <div class="protocols">
    <div class="title-container">
      <span class="title-text">{{ "SAMPLINGS" | translate }}</span>
    </div>
    <div class="line"></div>
    <div class="protocols-wrapper">
      <ng-container *ngFor="let protocol of protocols; let i = index">
        <div
          *ngIf="permissions[menuBtnList[i].permission] != null"
          class="protocols-container"
          (click)="navigateToRegistration(menuBtnList[i].url, protocol.id)"
        >
          <span class="protocols-text uppercase">{{
            protocol.description | translate
          }}</span>
        </div>
      </ng-container>
      <div *ngIf="noItems">
        {{ "NO_PROTOCOLS" | translate }}
      </div>
    </div>
  </div>
  <div class="protocols">
    <div class="bibliography">
      <div class="title-container">
        <span class="title-text">{{ "BIBLIOGRAPHY" | translate }}</span>
      </div>
      <div class="line"></div>
      <div class="protocols-wrapper">
        <div
          class="protocols-container"
          (click)="navigateToBook()"
          *ngIf="permissions['access:book'] != null"
        >
          <span class="protocols-text">{{
            "BOOK_ADD_AND_SEARCH" | translate
          }}</span>
        </div>

        <div *ngIf="noItems">
          {{ "NO_PROTOCOLS" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
