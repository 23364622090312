import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  Subject,
  takeUntil,
  finalize,
  forkJoin,
  delay,
} from 'rxjs';
import { IMammalThreatsDto } from 'src/app/core/dtos/mammals.dto';
import {
  ICellCodeDto,
  IHabitatSpeciesDto,
  INaturaSitesDto,
  IPrefecturesDto,
} from 'src/app/core/dtos/protocols.dto';
import {
  ISeaHabitat1170Request,
  ISeaHabitatARequest,
  ISeaHabitatBRequest,
  ISeaHabitatCRequest,
  ISeaHabitatPATRequest,
  ISeaHabitatsDto,
  ISeaHabitatSpeciesRequest,
} from 'src/app/core/dtos/sea-habitat.dto';
import { ISeaHabitatDto, ISeaSpeciesDto } from 'src/app/core/dtos/sea.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { ITabBtnGroup } from 'src/app/shared/button-group/button-group.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { ISampleIdResponse } from 'src/app/shared/dtos/generic.dto';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProtocolsService } from '../../services/protocols.service';
import { SeaService } from '../../services/sea.service';
import { IFileDeleteRequest, IFilePayload } from 'src/app/shared/files-container/files-container.component';

@Component({
  selector: 'app-sea-habitat',
  templateUrl: './sea-habitat.component.html',
  styleUrls: ['./sea-habitat.component.scss'],
})
export class SeaHabitatComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public seaHabitat: ISeaHabitatsDto = {};
  public seaHabitatById?: ISeaHabitatsDto;
  public seaHabitatForm!: FormGroup;
  public naturaSites: INaturaSitesDto[] = [];
  public sitesDropdown: IDropdownItem[] = [];
  public prefectures: IPrefecturesDto[] = [];
  public prefecturesDropdown: IDropdownItem[] = [];
  public habitatSpecies: IHabitatSpeciesDto[] = [];
  public habitatSpeciesDropdown: IDropdownItem[] = [];
  public habitatTypes: ISeaHabitatDto[] = [];
  public habitatTypesDropdown: IDropdownItem[] = [];
  public mammalThreats: IMammalThreatsDto[] = [];
  public codes: ICellCodeDto[] = [];
  public codesDropdown: IDropdownItem[] = [];
  public tabBtn: ITabBtnGroup[] = [
    { id: 'map', name: 'Επελεξε τοποθεσια', isChecked: true },
    { id: 'coordinates', name: 'Εισαγωγη συντεταγμενων', isChecked: false },
  ];
  public multiTabGroup = [
    { id: 0, name: 'Γεωγραφια', isChecked: true },
    { id: 1, name: 'Παρουσία ειδων', isChecked: true },
    { id: 2, name: 'Πιέσεις-Απειλές', isChecked: true },
    { id: 3, name: 'Δομές και λειτουργίες - 1120 (α)', isChecked: true },
    { id: 4, name: 'Δομές και λειτουργίες - 1120 (β)', isChecked: true },
    { id: 5, name: 'Δομές και λειτουργίες - 1170', isChecked: true },
  ];

  public fucalesDropdown: IDropdownItem[] = [
    {
      id: 'Φυσικό όριο βραχώδους υποστρώματος',
      name: 'Φυσικό όριο βραχώδους υποστρώματος',
    },
    { id: 'Φωτόφιλη Θαμνώδης Βλάστηση', name: 'Φωτόφιλη Θαμνώδης Βλάστηση' },
    { id: 'Σκιόφιλη Βλάστηση', name: 'Σκιόφιλη Βλάστηση' },
    { id: 'Επίπαγοι Ροδοφυκών', name: 'Επίπαγοι Ροδοφυκών' },
    {
      id: 'Υποτυπώδης νηματοειδής κάλυψη',
      name: 'Υποτυπώδης νηματοειδής κάλυψη',
    },
  ];

  public thamnodisDropdown: IDropdownItem[] = [
    {
      id: 'Φυσικό όριο βραχώδους υποστρώματος',
      name: 'Φυσικό όριο βραχώδους υποστρώματος',
    },
    { id: 'Σκιόφιλη Βλάστηση', name: 'Σκιόφιλη Βλάστηση' },
    { id: 'Επίπαγοι Ροδοφυκών', name: 'Επίπαγοι Ροδοφυκών' },
    {
      id: 'Υποτυπώδης νηματοειδής κάλυψη',
      name: 'Υποτυπώδης νηματοειδής κάλυψη',
    },
  ];

  public ecoDropdown: IDropdownItem[] = [
    { id: 'High', name: 'High' },
    { id: 'Good', name: 'Good' },
    { id: 'Moderate', name: 'Moderate' },
    { id: 'Low', name: 'Low' },
    { id: 'Bad', name: 'Bad' },
  ];

  public orientationDropdown: IDropdownItem[] = [
    { id: 'N', name: 'N' },
    { id: 'NE', name: 'NE' },
    { id: 'NW', name: 'NW' },
    { id: 'S', name: 'S' },
    { id: 'SE', name: 'SE' },
    { id: 'SW', name: 'SW' },
    { id: 'W', name: 'W' },
    { id: 'E', name: 'E' },
  ];

  public methodDropdown: IDropdownItem[] = [
    { id: 'Δειγματοληψία ιζήματος', name: 'Δειγματοληψία ιζήματος' },
    { id: 'Δειγματοληψία μακροφύτων', name: 'Δειγματοληψία μακροφύτων' },
    { id: 'Αυτοψία από ακτή', name: 'Αυτοψία από ακτή' },
    {
      id: 'Υποβρύχιες οπτικές μετρήσεις με ελεύθερη κατάδυση',
      name: 'Υποβρύχιες οπτικές μετρήσεις με ελεύθερη κατάδυση',
    },
    {
      id: 'Υποβρύχιες οπτικές μετρήσεις με αυτόνομη κατάδυση',
      name: 'Υποβρύχιες οπτικές μετρήσεις με αυτόνομη κατάδυση',
    },
    {
      id: 'Επιτόπιες τηλεπισκοπικές μέθοδοι (υποβρύχια κάμερα ή drone)',
      name: 'Επιτόπιες τηλεπισκοπικές μέθοδοι (υποβρύχια κάμερα ή drone)',
    },
    { id: 'Δορυφορική τηλεπισκόπηση', name: 'Δορυφορική τηλεπισκόπηση' },
  ];

  public meadowLandscapeDropdown: IDropdownItem[] = [
    { id: 'ΛΙΒΑΔΙ ΕΝΙΑΙΟ', name: 'ΛΙΒΑΔΙ ΕΝΙΑΙΟ' },
    {
      id: 'ΛΙΒΑΔΙ ΜΕ ΦΥΣΙΚΑ ΔΙΑΚΕΝΑ (PATCHY)',
      name: 'ΛΙΒΑΔΙ ΜΕ ΦΥΣΙΚΑ ΔΙΑΚΕΝΑ (PATCHY)',
    },
    { id: 'ΛΙΒΑΔΙ ΜΕ ΡΑΒΔΩΣΕΙΣ', name: 'ΛΙΒΑΔΙ ΜΕ ΡΑΒΔΩΣΕΙΣ' },
    {
      id: 'ΛΙΒΑΔΙ ΠΟΥ ΣΧΗΜΑΤΊΖΕΙ ΥΦΑΛΟΥΣ',
      name: 'ΛΙΒΑΔΙ ΠΟΥ ΣΧΗΜΑΤΊΖΕΙ ΥΦΑΛΟΥΣ',
    },
    { id: 'ΛΙΒΑΔΙ ΜΕ ΦΑΣΕΙΣ DEAD MATTE', name: 'ΛΙΒΑΔΙ ΜΕ ΦΑΣΕΙΣ DEAD MATTE' },
  ];

  public mode: number = 0;

  public coordSystemDropdown: IDropdownItem[] = [
    { id: 'EGSA', name: 'EGSA' },
    { id: 'WGS84', name: 'WGS84' },
  ];

  public financialDropdown: IDropdownItem[] = [
    { id: 'ΥΜΕΠΕΡΑΑ', name: 'ΥΜΕΠΕΡΑΑ' },
    { id: 'ΕΠΠΕΡΑΑ 2007-2013', name: 'ΕΠΠΕΡΑΑ 2007-2013' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];

  public typologyDropdown: IDropdownItem[] = [
    { id: 'A', name: 'A' },
    {
      id: 'B',
      name: 'B',
    },
  ];

  public ergoEpoptiaFasiErgouDropdown: IDropdownItem[] = [
    { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
    { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
    { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
    { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
    { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
  ];

  public predefinedFiles: IFilePayload[] = [];
  public coordinates: ICoordinates | undefined;
  public files: any[] = [];
  public filesToDelete: any[] = [];
  public errors: ValidationErrors[] = [];
  public speciesById: ISeaHabitatSpeciesRequest[] = [];
  public threatsById: ISeaHabitatPATRequest[] = [];
  public struct1120aById: ISeaHabitatARequest[] = [];
  public struct1120bById: ISeaHabitatBRequest[] = [];
  public struct1120cById: ISeaHabitatCRequest[] = [];
  public ctSpecies: ISeaSpeciesDto[] = [];

  @Input() public protocolId!: number;
  @Input() public sampleId?: number;
  @Input() public readOnlyForm = false;
  @ViewChild('nav') public nav: any;

  constructor(
    private fb: FormBuilder,
    private protocolService: ProtocolsService,
    private router: Router,
    public toastService: ToastService,
    public common: CommonService,
    public seaService: SeaService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      protocolId: number;
    };
    this.protocolId = state?.protocolId;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.protocolId = history.state.protocolId;
    this.initSeaHabitatFormGroup();

    if (this.sampleId) {
      this.seaService
        .getSeaHabitatById(this.sampleId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.getValuesFromServices();
            return;
          })
        )
        .subscribe((res) => {
          if (res != undefined) {
            this.seaHabitatById = res as ISeaHabitatDto;
            // Fix different property names
            this.seaHabitatById.lat = this.seaHabitatById.pointX;
            this.seaHabitatById.long = this.seaHabitatById.pointY;

            if (res.files != null) {
              this.predefinedFiles = res.files as any;
            }

            // Fix date
            if (this.seaHabitatById.date) {
              const convertedDate = new Date(this.seaHabitatById.date);
              const dateString =
                convertedDate.getDate().toString().padStart(2, '0') +
                (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                convertedDate.getFullYear().toString();
              this.seaHabitatById.date = dateString;
            }
            // Fix time
            if (this.seaHabitatById.time) {
              const timeString = this.seaHabitatById.time
                .toString()
                .slice(0, 5)
                .replaceAll(':', '');
              this.seaHabitatById.time = timeString;
            }
          }
        });
    } else {
      this.getValuesFromServices();
      this.seaHabitatForm.controls['typology'].disable();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.protocolService.getCellCodes(),
      this.protocolService.getNaturaSites(this.seaHabitatById?.dataVersion),
      this.protocolService.getPrefectures(this.seaHabitatById?.dataVersion),
      this.seaService.getHabitats(this.seaHabitatById?.dataVersion),
      this.seaService.getSpecies(this.seaHabitatById?.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.codes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.codes.forEach((code) => {
              dropdown.push({
                id: code.cellCode,
                name: code.cellCode,
              });
            });
            this.codesDropdown = dropdown;
          }

          if (this.naturaSites.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.naturaSites.forEach((site) => {
              dropdown.push({ id: site.naturaCode, name: site.name });
            });
            this.sitesDropdown = dropdown;
          }

          if (this.prefectures.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.prefectures.forEach((prefecture) => {
              dropdown.push({
                id: prefecture.id,
                name: prefecture.prefecture,
              });
            });
            this.prefecturesDropdown = dropdown;
          }

          if (this.habitatTypes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.habitatTypes.forEach((habitat) => {
              dropdown.push({
                id: habitat.habType,
                name: habitat.habTypeName,
                actualId: parseInt(habitat.code),
              });
            });
            this.habitatTypesDropdown = dropdown;
          }

          this.seaHabitatForm.controls['habType'].valueChanges.subscribe(
            (value) => {
              const habitat = this.habitatTypes.find(
                (item) => item.code === value
              );
              if (habitat == null) {
                return;
              }

              const firstTabId =
                document.querySelector('#sea-habitats-id a')?.id;

              value = habitat.habType;
              if (value === '1120') {
                this.seaHabitatForm.controls['meadowLandscape'].enable();

                this.seaHabitatForm.controls['typology'].disable();
                this.seaHabitatForm.controls['typology'].setValue('');

                // Clearing 1170
                (
                  this.seaHabitatForm.controls['struct1170'] as FormGroup
                ).reset();
                if (firstTabId != null) {
                  this.nav.select(firstTabId);
                }

                this.mode = 1;
              } else if (value === '1170') {
                this.seaHabitatForm.controls['meadowLandscape'].setValue('');
                this.seaHabitatForm.controls['meadowLandscape'].disable();

                this.seaHabitatForm.controls['typology'].enable();

                (this.seaHabitatForm.controls['a'] as FormArray).clear();
                (this.seaHabitatForm.controls['b'] as FormArray).clear();
                (this.seaHabitatForm.controls['c'] as FormArray).clear();
                if (firstTabId != null) {
                  this.nav.select(firstTabId);
                }

                this.mode = 2;
              } else if (value === '1110') {
                this.seaHabitatForm.controls['typology'].enable();
              } else {
                this.seaHabitatForm.controls['meadowLandscape'].setValue('');
                this.seaHabitatForm.controls['meadowLandscape'].disable();

                this.seaHabitatForm.controls['typology'].setValue('');
                this.seaHabitatForm.controls['typology'].disable();

                // Clearing 1120
                (this.seaHabitatForm.controls['a'] as FormArray).clear();
                (this.seaHabitatForm.controls['b'] as FormArray).clear();
                (this.seaHabitatForm.controls['c'] as FormArray).clear();

                // Clearing 1170
                (
                  this.seaHabitatForm.controls['struct1170'] as FormGroup
                ).reset();
                if (firstTabId != null) {
                  this.nav.select(firstTabId);
                }
                this.mode = 0;
              }
            }
          );

          if (this.sampleId && this.seaHabitatById) {
            this.seaHabitatForm.patchValue(this.seaHabitatById);
            if (this.seaHabitatById.species) {
              this.speciesById = this.seaHabitatById.species;
            }
            if (this.seaHabitatById.threats) {
              this.threatsById = this.seaHabitatById.threats;
            }
            if (this.seaHabitatById.a) {
              this.struct1120aById = this.seaHabitatById.a;
            }
            if (this.seaHabitatById.b) {
              this.struct1120bById = this.seaHabitatById.b;
            }
            if (this.seaHabitatById.c) {
              this.struct1120cById = this.seaHabitatById.c;
            }
            if (this.seaHabitatById.typology) {
              this.seaHabitatForm.controls['typology'].enable();
            }
            if (this.readOnlyForm === true) {
              this.seaHabitatForm.disable();
            }
          }

          return;
        })
      )
      .subscribe(
        ([codes, naturaSites, prefectures, habitatTypes, ctSpecies]) => {
          if (codes != undefined) {
            this.codes = codes as ICellCodeDto[];
          }

          if (naturaSites != undefined) {
            this.naturaSites = naturaSites as INaturaSitesDto[];
          }

          if (prefectures != undefined) {
            this.prefectures = prefectures as IPrefecturesDto[];
          }

          if (habitatTypes != undefined) {
            this.habitatTypes = habitatTypes as ISeaHabitatDto[];
          }

          if (ctSpecies != undefined) {
            this.ctSpecies = ctSpecies as ISeaSpeciesDto[];
          }
        }
      );
  }

  private monitorCoords(): void {
    this.common
      .monitorCoords(
        this.destroy$,
        this.seaHabitatForm,
        'x',
        'y',
        'lat',
        'long'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICoordinates) => (this.coordinates = data));
  }

  /**
   * Initialization of the user form.
   */
  private initSeaHabitatFormGroup() {
    this.seaHabitatForm = this.seaService.initSeaHabitatFormGroup(this.fb);

    if (this.seaHabitatForm?.controls['habType']?.value !== '1120') {
      this.seaHabitatForm.controls['meadowLandscape'].disable();
    }

    this.seaHabitatForm?.controls['samId'].clearValidators();

    this.monitorCoords();
    this.common.monitorCoordSystem(this.seaHabitatForm, this.destroy$);
  }

  public get species(): FormArray {
    return this.seaHabitatForm.controls['species'] as FormArray;
  }

  public get threats(): FormArray {
    return this.seaHabitatForm.controls['threats'] as FormArray;
  }

  public get a(): FormArray {
    return this.seaHabitatForm.controls['a'] as FormArray;
  }

  public get b(): FormArray {
    return this.seaHabitatForm.controls['b'] as FormArray;
  }

  public get c(): FormArray {
    return this.seaHabitatForm.controls['c'] as FormArray;
  }

  public get struct1170(): FormGroup {
    return this.seaHabitatForm.controls['struct1170'] as FormGroup;
  }

  public get coordSystem(): number {
    return this.seaHabitatForm.controls['isWGS84'].value ? 1 : 0;
  }

  public hasErrors1170(controls: string[]): boolean {
    let flag = false;
    let fg = this.seaHabitatForm.controls['struct1170'] as FormGroup;
    controls.forEach((ctrl) => {
      if (fg.controls[ctrl].errors != null) {
        flag = true;
      }
    });
    return flag;
  }

  public getErrors1170(controls: string[]): ValidationErrors {
    let error: ValidationErrors = {};
    let fg = this.seaHabitatForm.controls['struct1170'] as FormGroup;
    controls.forEach((ctrl) => {
      if (fg.controls[ctrl].errors != null) {
        error = fg.controls[ctrl].errors ?? {};
      }
    });
    return error;
  }

  public touched1170(controls: string[]): boolean {
    let flag = false;
    let fg = this.seaHabitatForm.controls['struct1170'] as FormGroup;
    controls.forEach((ctrl) => {
      if (fg.controls[ctrl].touched) {
        flag = true;
      }
    });
    return flag;
  }

  /**
   * Adds form value in corresponding observable.
   */
  public prepareSeaHabitatRequest(): void {
    this.errors = this.common.validateAllFormControl(this.seaHabitatForm);
    if (this.errors.length > 0) {
      return;
    }

    this.seaHabitat = this.seaService.prepareSeaHabitatRequest(
      this.seaHabitatForm,
      false,
      this.habitatTypes,
      this.ctSpecies,
      this.sampleId != null
    );

    if (this.sampleId && !this.readOnlyForm) {
      this.updateSample();
    } else {
      this.registerNewSample();
    }
  }

  public registerNewSample(): void {
    this.seaService
      .addSeaHabitat(this.seaHabitat)
      .pipe(
        catchError((err) => {
          this.toastService.show('GENERIC_FAILURE_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
          throw err;
        })
      )
      .subscribe((sampleIdResponse: ISampleIdResponse) => {
        if (this.files.length > 0) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append('files', file);
          });

          formData.append('SampleId', sampleIdResponse.sampleId.toString());
          this.seaService
            .addFiles(formData)
            .pipe(
              catchError((err) => {
                this.toastService.show('GENERIC_FAILURE_TEXT', {
                  header: 'GENERIC_FAILURE_HEADER',
                });
                throw err;
              })
            )
            .subscribe(() => {
              this.toastService.show('GENERIC_SUCCESS_TEXT', {
                header: 'GENERIC_SUCCESS_HEADER',
              });

              this.activeModal.close({
                sampleId: sampleIdResponse.sampleId,
                samplingCode: this.seaHabitatForm.value.samplingCode,
              });
            });
        } else {
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });

          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.seaHabitatForm.value.samplingCode,
          });
        }
      });
  }

  public updateSample(): void {
    if (this.sampleId) {
      this.seaService
        .putSeaHabitat(this.sampleId, this.seaHabitat)
        .pipe(
          catchError((err) => {
            this.toastService.show('GENERIC_FAILURE_TEXT', {
              header: 'GENERIC_FAILURE_HEADER',
            });
            throw err;
          })
        )
        .subscribe((sampleIdResponse: ISampleIdResponse) => {
          if (this.files != null) {
            // Uploading new files
            const formData = new FormData();
            let addFiles = false;
            this.files.forEach((file: any) => {
              if (file instanceof File) {
                addFiles = true;
                formData.append('files', file);
              }
            });

            if (addFiles) {
              formData.append('SampleId', (this.sampleId as number).toString());
              this.seaService
                .addFiles(formData)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          // Deleting files
          if (this.filesToDelete != null && this.filesToDelete.length > 0) {
            const payload: IFileDeleteRequest = {
              sampleId: this.sampleId as number,
              fileIds: []
            };
            this.filesToDelete.forEach(file => {
              if (file.id != null) {
                payload.fileIds.push(file.id)
              }
            });

            if (payload.fileIds.length > 0) {
              this.seaService
                .removeFiles(payload)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.seaHabitatForm.value.samplingCode,
          });
        });
    }
  }

  /**
   * Change view
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public tabBtnClick(btnId: string) {
    if (btnId === 'map') {
      this.tabBtn[0].isChecked = true;
      this.tabBtn[1].isChecked = false;
    } else {
      this.tabBtn[1].isChecked = true;
      this.tabBtn[0].isChecked = false;
    }
  }

  /**
   * Find the new coordinates of the map
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public onMarkerMoved(coordinates: { lat: number; lng: number }): void {
    this.coordinates = coordinates;
    this.submitCoordinates();
  }

  public submitCoordinates(): void {
    if (this.coordinates != null) {
      this.seaHabitatForm.controls['lat'].patchValue(this.coordinates.lat, {
        emitEvent: false,
      });
      this.seaHabitatForm.controls['long'].patchValue(this.coordinates.lng, {
        emitEvent: false,
      });

      const coordinates = this.common.convertWGStoEGSA(
        this.coordinates.lat,
        this.coordinates.lng
      );
      this.seaHabitatForm.controls['x'].patchValue(coordinates.x, {
        emitEvent: false,
      });
      this.seaHabitatForm.controls['y'].patchValue(coordinates.y, {
        emitEvent: false,
      });
    }
  }

  public confirmClose(): void {
    if (this.seaHabitatForm.dirty) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.result.then((data) => {
        if (data) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
