<div class="new-record-container">
  <div class="details-container">
    <div class="title">
      <ng-container *ngIf="protocolId === 0">
        {{ "BIBLIO_HEADER" | translate }}
      </ng-container>
      <ng-container *ngIf="protocolId !== 0">
        {{ "NEW_RECORD_TITLE" | translate }} - {{ title | translate }}
      </ng-container>
    </div>
    <div class="description">
      <ng-container *ngIf="protocolId === 0">
        {{ "BIBLIO_SUBTITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="protocolId !== 0">
        {{ "NEW_RECORD_DESCRIPTION" | translate }}
      </ng-container>
    </div>
  </div>
  <div class="with-form" (click)="openModal('create')">
    <img src="assets/images/new_form.svg" />
    <div class="label">
      {{
        protocolId === 0
          ? ("NEW_REPORT_RECORD" | translate)
          : ("NEW_FORM_RECORD" | translate)
      }}
    </div>
  </div>
  <app-spreadsheet-upload
    class="spreadsheet-container"
    [protocolId]="protocolId ?? 0"
  ></app-spreadsheet-upload>

  <div class="zip-upload">
    <div class="upload-files">
      <app-button
        [btnText]="'UPLOAD_FILES'"
        (buttonClicked)="openUploadFilesModal()"
      ></app-button>
    </div>
    <div class="upload-codes">
      <p-accordion [activeIndex]="-1">
        <p-accordionTab header="{{ 'FIND_UPLOAD_CODE' | translate }}">
          <p-table
            #uploadCodesDataTable
            [value]="uploadCodes"
            [tableStyle]="{ 'min-width': '100%' }"
            [styleClass]="'p-datatable-sm'"
            [paginator]="true"
            [rows]="10"
            [scrollable]="true"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "UPLOAD_CODE" | translate }}</th>
                <th
                  pSortableColumn="{{
                    protocolId !== protocols.Bibliography
                      ? 'samId'
                      : 'referenceId'
                  }}"
                >
                  {{
                    protocolId !== protocols.Bibliography
                      ? "Sam_Id"
                      : ("BOOK_REFERENCE_ID" | translate)
                  }}
                </th>
                <th>{{ "DATE" | translate }}</th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <input
                    pInputText
                    [ngClass]="'p-inputtext-sm'"
                    type="text"
                    #textInput
                    (input)="
                      uploadCodesDataTable.filter(
                        textInput.value,
                        protocolId !== protocols.Bibliography
                          ? 'samId'
                          : 'referenceId',
                        'contains'
                      )
                    "
                    [value]="
                      $any(
                        uploadCodesDataTable.filters[
                          protocolId !== protocols.Bibliography
                            ? 'samId'
                            : 'referenceId'
                        ]
                      )?.value
                    "
                    placeholder="Αναζήτηση στήλης..."
                    class="w-full"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-code>
              <tr>
                <td class="code">{{ code.code }}</td>
                <td>
                  {{
                    protocolId !== protocols.Bibliography
                      ? code.samId
                      : code.referenceId
                  }}
                </td>
                <td>{{ code.date | date : "dd/MM/yyyy" }}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <hr />

  <div class="custom-table" *ngIf="protocolId === protocols.Mammals">
    <app-mammal-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-mammal-search>
  </div>

  <div class="custom-table" *ngIf="protocolId === protocols.Amphibian">
    <app-amphibian-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-amphibian-search>
  </div>

  <div class="custom-table" *ngIf="protocolId === protocols.Sea">
    <app-sea-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-sea-search>
  </div>

  <div class="custom-table" *ngIf="protocolId === protocols.Fish">
    <app-fish-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-fish-search>
  </div>

  <div class="custom-table" *ngIf="protocolId === protocols.Chloris">
    <app-chloris-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-chloris-search>
  </div>

  <div class="custom-table" *ngIf="protocolId === protocols.SeaHabitat">
    <app-sea-habitats-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-sea-habitats-search>
  </div>

  <div class="custom-table" *ngIf="protocolId === protocols.Bird">
    <app-bird-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-bird-search>
  </div>

  <div class="custom-table" *ngIf="protocolId === protocols.Bibliography">
    <app-book-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-book-search>
  </div>

  <div class="custom-table" *ngIf="protocolId === protocols.Invertebrates">
    <app-invertebrate-search
      [recordChangedTrigger]="recordChangedTrigger"
    ></app-invertebrate-search>
  </div>
</div>
