import { SeaSpeciesCategory } from 'src/app/samplings/config-files/sea-config';
import {
  DataSubType,
  DataType,
} from 'src/app/shared/table/enumerations/table.enumerations';

export interface IDataTableColumns {
  header: string;
  propertyName: string;
  searchColumn?: string;
  searchDataType?: DataType;
  searchDataSubType?: DataSubType;
  shown: boolean;
  isID: boolean;
  preselected: boolean;
  noFilter?: boolean;
  noSort?: boolean;
  data?: any[];
}

export const sampleColumns: IDataTableColumns[] = [
  {
    header: 'RANDOM_OBSERVATION',
    propertyName: 'randomObservation',
    searchColumn: 'RandomObservation',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: false,
    preselected: true,
    isID: false,
  },
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
  },
  {
    header: 'SAM_ID',
    propertyName: 'samId',
    searchColumn: 'SamId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'DATE',
    propertyName: 'date',
    searchColumn: 'Date',
    searchDataType: DataType.Date,
    searchDataSubType: DataSubType.DateN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TIME',
    propertyName: 'time',
    searchColumn: 'Time',
    searchDataType: DataType.Time,
    searchDataSubType: DataSubType.TimeN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RESEARCHER',
    propertyName: 'researcher',
    searchColumn: 'Researcher',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FINANCIAL_INSTRUMENT',
    propertyName: 'financialInstrument',
    searchColumn: 'FinancialInstrument',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NATURA_CODE',
    propertyName: 'naturaCode',
    searchColumn: 'NaturaCode',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'GRID_CELL',
    propertyName: 'gridCell',
    searchColumn: 'GridCell',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'PREFECTURE',
    propertyName: 'prefecture',
    searchColumn: 'Prefecture',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'LOCATION',
    propertyName: 'locality',
    searchColumn: 'Locality',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const speciesColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AGE',
    propertyName: 'age',
    searchColumn: 'Age',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_PEOPLE',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const threatsColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
  },
  {
    header: 'PRESSURE_THREAT',
    propertyName: 'pressuresAndThreatsId',
    searchColumn: 'PressuresAndThreatsId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'SpeciesId',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'TYPE',
    propertyName: 'type',
    searchColumn: 'Type',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'P(πίεση)',
        name: 'P(πίεση)',
      },
      {
        id: 'T(απειλή)',
        name: 'T(απειλή)',
      },
      {
        id: 'P or T(και τα δύο)',
        name: 'P or T(και τα δύο)',
      },
    ],
  },
  {
    header: 'IMPORTANCE',
    propertyName: 'importance',
    searchColumn: 'Importance',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'L',
        name: 'L(Low)',
        matchMode: 'startsWith',
      },
      {
        id: 'M',
        name: 'M(Medium)',
        matchMode: 'startsWith',
      },
      {
        id: 'H',
        name: 'H(High)',
        matchMode: 'startsWith',
      },
    ],
  },
];

export const sampleMammalColumns: IDataTableColumns[] = [
  {
    header: 'RANDOM_OBSERVATION',
    propertyName: 'randomObservation',
    searchColumn: 'RandomObservation',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
    noFilter: true,
  },
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'DURATION',
    propertyName: 'duration',
    searchColumn: 'Duration',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT',
    propertyName: 'habitat',
    searchColumn: 'Habitat',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'METHOD',
    propertyName: 'method',
    searchColumn: 'Method',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ERGO_EPOPTIA_FASI_ERGOU',
    propertyName: 'ergoEpoptiaFasiErgou',
    searchColumn: 'ErgoEpoptiaFasiErgou',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
      { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
      { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
      { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
      { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
    ],
  },
  {
    header: 'SPECIES_CATEGORY',
    propertyName: 'speciesCategory',
    searchColumn: 'SpeciesCategory',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'Χειρόπτερα',
        name: 'Χειρόπτερα',
      },
      {
        id: 'Σαρκοφάγα',
        name: 'Σαρκοφάγα',
      },
      {
        id: 'Οπληφόρα',
        name: 'Οπληφόρα',
      },
      {
        id: 'Μικροθηλαστικά',
        name: 'Μικροθηλαστικά',
      },
    ],
  },
  {
    header: 'AUTHOR',
    propertyName: 'author',
    searchColumn: 'Author',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'COURSE_LENGTH',
    propertyName: 'courseLength',
    searchColumn: 'CourseLength',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'CAPTURE',
    propertyName: 'capture',
    searchColumn: 'Capture',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TEMPERATURE',
    propertyName: 'temperature',
    searchColumn: 'Temperature',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WIND_INTENSITY',
    propertyName: 'windIntensity',
    searchColumn: 'WindIntensity',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WEATHER',
    propertyName: 'cloudiness',
    searchColumn: 'Cloudiness',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'S', name: '(S) - Ηλιοφάνεια' },
      { id: 'C1', name: '(C1) - Διαστήματα με συννεφιά και ήλιο' },
      { id: 'C2', name: '(C2) - Συννεφιά με έντονο φως' },
    ],
  },
  {
    header: 'RAINING_SNOWING',
    propertyName: 'raining',
    searchColumn: 'Raining',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: '0', name: '(0) - Όχι' },
      { id: '1', name: '(1) - Ελαφριά βροχή' },
      { id: '2', name: '(2) - Έντονη βροχή' },
      { id: '3', name: '(3) - Ελαφριά χιονόπτωση' },
      { id: '4', name: '(4) - Έντονη χιονόπτωση' },
    ],
  },
  {
    header: 'SHAPEFILE',
    propertyName: 'shapefile',
    searchColumn: 'Shapefile',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT_THREATS',
    propertyName: 'habitatThreats',
    searchColumn: 'HabitatThreats',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleMammalSpeciesColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_PEOPLE',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES_ACTIVITY',
    propertyName: 'activities',
    searchColumn: 'Activities',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'M', name: '(M) - Σε κίνηση' },
      { id: 'R', name: '(R) - Στατικό' },
      { id: 'W', name: '(W) - Στο νερό' },
      { id: 'T', name: '(T) - Σε δέντρο' },
      { id: 'G', name: '(G) - Στο έδαφος' },
      { id: 'D', name: '(D) - Νεκρό' },
      { id: 'DOR', name: '(DOR) - Νεκρό στο δρόμο' },
      { id: 'C', name: '(C) - Ζευγάρωμα' },
      { id: 'F', name: '(F) - Σε πτήση' },
      { id: 'Gr', name: '(Gr) - Σε γκρουπ' },
      { id: 'Si', name: '(Si) - Μόνα άτομα' },
    ],
  },
  {
    header: 'SPECIES_NOTES',
    propertyName: 'notes',
    searchColumn: 'Notes',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'M', name: '(M) - Αρσενικό (Φάση αναπαραγωγής Μ, ΜΜ, ΜΜΜ)' },
      { id: 'F', name: '(F) - Θηλυκό (Φάση αναπαραγωγής F, FF, FFF)' },
      { id: 'J', name: '(J) - Νεαρό' },
      { id: 'A', name: '(A) - Ενήλικο ' },
      { id: 'P', name: '(P) - Εγκυμοσύνη / Κύηση ' },
      { id: 'L', name: '(L) - Θηλασμός / Γαλουχία' },
      { id: 'JJ', name: '(JJ) - Αρ. κατσικιών (x<1 έτους)' },
      { id: 'J', name: '(J) - Αρ. χρονιάρικων (1<x>2)' },
      { id: 'AF', name: '(AF) - Αρ. ενήλικων θηλυκών (x>3)' },
      { id: 'AM', name: '(AM) - Αρ. ενήλικων αρσενικών: (x>3)' },
      {
        id: 'C1',
        name: '(C1) - Άγριος φαινότυπος, με επιβεβαίωση γενετικών αναλύσεων',
      },
      {
        id: 'C2',
        name: '(C2) - Άγριος φαινότυπος βάσει αξιολόγησης χαρακτήρων (Kitchener 2005)',
      },
      {
        id: 'C3',
        name: '(C3) - Μη ολικά ορατός φαινότυπος ή δύσκολο να ερμηνευθεί, αλλά περιλαμβάνει κάποιους άγριους χαρακτήρες',
      },
      {
        id: 'C4',
        name: '(C4) - Φαινότυπος με κάποιους άγριους χαρακτήρες, αλλά καθαρά μη άγριο (πιθανό υβρίδιο)',
      },
      { id: '0', name: '(0) - Φαινότυπος οικόσιτης γάτας' },
    ],
  },
  {
    header: 'BIOMARKER',
    propertyName: 'biomarker',
    searchColumn: 'Biomarker',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Περιττώματα', name: 'Περιττώματα' },
      { id: 'Ίχνη', name: 'Ίχνη' },
      { id: 'Λαγούμια', name: 'Λαγούμια' },
      { id: 'Υπολείμματα τροφών', name: 'Υπολείμματα τροφών' },
      { id: 'Σημάδεμα', name: 'Σημάδεμα' },
    ],
  },
  {
    header: 'BIOMARKER_QUANTITY',
    propertyName: 'biomarkerQuantity',
    searchColumn: 'BiomarkerQuantity',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BIOMARKER_QUALITY',
    propertyName: 'biomarkerQuality',
    searchColumn: 'BiomarkerQuality',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Παλιά', name: 'Παλιά' },
      { id: 'Νέα - πρόσφατα', name: 'Νέα - πρόσφατα' },
      { id: 'Φρέσκα', name: 'Φρέσκα' },
      { id: 'Ξερά', name: 'Ξερά' },
      { id: 'Παλιά', name: 'Παλιά' },
      { id: 'Ενεργά', name: 'Ενεργά' },
      { id: 'Ανενεργά', name: 'Ανενεργά' },
      { id: 'Θετικός σταθμός βίδρας', name: 'Θετικός σταθμός βίδρας' },
      { id: 'Αρνητικός σταθμός βίδρας', name: 'Αρνητικός σταθμός βίδρας' },
    ],
  },
  {
    header: 'HABITAT_CODE_1',
    propertyName: 'habitatCode1',
    searchColumn: 'HabitatCode1',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: '1', name: '(1) - Δάσος Κωνοφόρων' },
      { id: '2', name: '(2) - Δάσος Φυλλοβόλων' },
      { id: '3', name: '(3) - Δάσος Μεικτό' },
      { id: '4', name: '(4) - Φρύγανα' },
      { id: '5', name: '(5) - Μακκία' },
      { id: '6', name: '(6) - Καλλιέργειες' },
      { id: '7', name: '(7) - Αμμοθίνες' },
      { id: '8', name: '(8) - Υγρά Λιβάδια' },
      { id: '9', name: '(9) - Λίμνη' },
      { id: '10', name: '(10) - Ποτάμι' },
      { id: '11', name: '(11) - Κανάλι' },
      { id: '12', name: '(12) - Καλαμιώνας' },
      { id: '13', name: '(13) - Λιβάδι' },
      { id: '14', name: '(14) - Σπήλαιο' },
      { id: '15', name: '(15) - Σάρα (Χαλικώνας)' },
      { id: '16', name: '(16) - Παρόχθιο δάσος' },
      { id: '17', name: '(17) - Ορυχείο' },
      { id: '18', name: '(18) - Σπίτι' },
      { id: '19', name: '(19) - Γέφυρα' },
      { id: '20', name: '(20) - Γαλαρία' },
    ],
  },
  {
    header: 'HABITAT_CODE_2',
    propertyName: 'habitatCode2',
    searchColumn: 'HabitatCode2',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'a', name: '(a) - Με Υποόροφο' },
      { id: 'b', name: '(b) - Χωρίς Υποόροφο' },
      { id: 'c', name: '(c) - Ξέφωτο-Άνοιγμα' },
      { id: 'd', name: '(d) - Βραχώδες' },
      { id: 'e', name: '(e) - Ετήσιες Καλλιέργειες' },
      { id: 'f', name: '(f) - Δενδρώδεις Καλλιέργειες' },
      { id: 'g', name: '(g) - Πέτρες' },
      { id: 'h', name: '(h) - Συστάδα σε καλλιέργειές' },
      { id: 'i', name: '(i) - Φυτοφράχτης' },
    ],
  },
  {
    header: 'HABITAT_CODE_3',
    propertyName: 'habitatCode3',
    searchColumn: 'HabitatCode3',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Α', name: '(A) - Φυσικό' },
      { id: 'Β', name: '(B) - Τεχνητό' },
      { id: 'Γ', name: '(Γ) - Έχει υποστεί αλλαγές' },
    ],
  },
  {
    header: 'SPECIES_ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'MICROHABITAT',
    propertyName: 'microHabitat',
    searchColumn: 'MicroHabitat',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'A', name: '(Α) - Δέντρα' },
      { id: 'B', name: '(B) - Μακία-Θάμνοι' },
      { id: 'C', name: '(C) - Φρύγανα' },
      { id: 'D', name: '(D) - Πόες' },
      { id: 'E', name: '(E) - Παρόχθια (καλάμια, βούρλα, λυγαριές)' },
      { id: 'F', name: '(F) - Έδαφος' },
      { id: 'Fa', name: '(Fa) - Οδόστρωμα' },
      { id: 'G', name: '(G) - Ξέφωτο' },
      { id: 'H', name: '(H) - Πέτρες' },
      { id: 'Ha', name: '(Ha) - Ξερολιθιά / Πετροσωρός' },
      { id: 'I', name: '(I) - Βράχια' },
      { id: 'J', name: '(J) - Άμμος' },
      { id: 'K', name: '(K) - Κορμός' },
      { id: 'Ka', name: '(Ka) - Στοίβα από ξύλα' },
      { id: 'L', name: '(L) Ρυάκι' },
      { id: 'M', name: '(M) - Ποτάμι' },
      { id: 'N', name: '(N) - Βάλτος (πιο πολλά φυτά από νερό)' },
      { id: 'O', name: '(O) - Έλος (πιο πολύ νερό από φυτά)' },
      { id: 'Pa', name: '(Pa) - Ποτίστρα/ Πηγάδι' },
      { id: 'Pb', name: '(Pb) - Στέρνα' },
      { id: 'Q', name: '(Q) - Λίμνη' },
      { id: 'Qa', name: '(Qa) - Τεχνητή λιμνοδεξαμενή' },
      { id: 'R', name: '(R) - Κτίσματα' },
    ],
  },
  {
    header: 'HABITAT_QUALITY',
    propertyName: 'habitatQuality',
    searchColumn: 'HabitatQuality',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'G', name: '(G) - Καλή' },
      { id: 'M', name: '(M) - Μέτρια' },
      { id: 'B', name: '(B) - Κακή' },
      { id: 'U', name: '(U) - Άγνωστη' },
    ],
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleMammalRandomSpeciesColumns: IDataTableColumns[] = [
  {
    header: 'SAM_ID',
    propertyName: 'samId',
    searchColumn: 'SamId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'OBS_ID',
    propertyName: 'obs_ID',
    searchColumn: 'Obs_ID',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_PEOPLE',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DATE',
    propertyName: 'date',
    searchColumn: 'Date',
    searchDataType: DataType.Date,
    searchDataSubType: DataSubType.DateN,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'SPECIES_ACTIVITY',
    propertyName: 'activities',
    searchColumn: 'Activities',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'M', name: '(M) - Σε κίνηση' },
      { id: 'R', name: '(R) - Στατικό' },
      { id: 'W', name: '(W) - Στο νερό' },
      { id: 'T', name: '(T) - Σε δέντρο' },
      { id: 'G', name: '(G) - Στο έδαφος' },
      { id: 'D', name: '(D) - Νεκρό' },
      { id: 'DOR', name: '(DOR) - Νεκρό στο δρόμο' },
      { id: 'C', name: '(C) - Ζευγάρωμα' },
      { id: 'F', name: '(F) - Σε πτήση' },
      { id: 'Gr', name: '(Gr) - Σε γκρουπ' },
      { id: 'Si', name: '(Si) - Μόνα άτομα' },
    ],
  },
  {
    header: 'SPECIES_NOTES',
    propertyName: 'notes',
    searchColumn: 'Notes',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'M', name: '(M) - Αρσενικό (Φάση αναπαραγωγής Μ, ΜΜ, ΜΜΜ)' },
      { id: 'F', name: '(F) - Θηλυκό (Φάση αναπαραγωγής F, FF, FFF)' },
      { id: 'J', name: '(J) - Νεαρό' },
      { id: 'A', name: '(A) - Ενήλικο ' },
      { id: 'P', name: '(P) - Εγκυμοσύνη / Κύηση ' },
      { id: 'L', name: '(L) - Θηλασμός / Γαλουχία' },
      { id: 'JJ', name: '(JJ) - Αρ. κατσικιών (x<1 έτους)' },
      { id: 'J', name: '(J) - Αρ. χρονιάρικων (1<x>2)' },
      { id: 'AF', name: '(AF) - Αρ. ενήλικων θηλυκών (x>3)' },
      { id: 'AM', name: '(AM) - Αρ. ενήλικων αρσενικών: (x>3)' },
      {
        id: 'C1',
        name: '(C1) - Άγριος φαινότυπος, με επιβεβαίωση γενετικών αναλύσεων',
      },
      {
        id: 'C2',
        name: '(C2) - Άγριος φαινότυπος βάσει αξιολόγησης χαρακτήρων (Kitchener 2005)',
      },
      {
        id: 'C3',
        name: '(C3) - Μη ολικά ορατός φαινότυπος ή δύσκολο να ερμηνευθεί, αλλά περιλαμβάνει κάποιους άγριους χαρακτήρες',
      },
      {
        id: 'C4',
        name: '(C4) - Φαινότυπος με κάποιους άγριους χαρακτήρες, αλλά καθαρά μη άγριο (πιθανό υβρίδιο)',
      },
      { id: '0', name: '(0) - Φαινότυπος οικόσιτης γάτας' },
    ],
  },
  {
    header: 'HABITAT_CODE_1',
    propertyName: 'habitatCode1',
    searchColumn: 'HabitatCode1',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: '1', name: '(1) - Δάσος Κωνοφόρων' },
      { id: '2', name: '(2) - Δάσος Φυλλοβόλων' },
      { id: '3', name: '(3) - Δάσος Μεικτό' },
      { id: '4', name: '(4) - Φρύγανα' },
      { id: '5', name: '(5) - Μακκία' },
      { id: '6', name: '(6) - Καλλιέργειες' },
      { id: '7', name: '(7) - Αμμοθίνες' },
      { id: '8', name: '(8) - Υγρά Λιβάδια' },
      { id: '9', name: '(9) - Λίμνη' },
      { id: '10', name: '(10) - Ποτάμι' },
      { id: '11', name: '(11) - Κανάλι' },
      { id: '12', name: '(12) - Καλαμιώνας' },
      { id: '13', name: '(13) - Λιβάδι' },
      { id: '14', name: '(14) - Σπήλαιο' },
      { id: '15', name: '(15) - Σάρα (Χαλικώνας)' },
      { id: '16', name: '(16) - Παρόχθιο δάσος' },
      { id: '17', name: '(17) - Ορυχείο' },
      { id: '18', name: '(18) - Σπίτι' },
      { id: '19', name: '(19) - Γέφυρα' },
      { id: '20', name: '(20) - Γαλαρία' },
    ],
  },
  {
    header: 'HABITAT_CODE_2',
    propertyName: 'habitatCode2',
    searchColumn: 'HabitatCode2',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'a', name: '(a) - Με Υποόροφο' },
      { id: 'b', name: '(b) - Χωρίς Υποόροφο' },
      { id: 'c', name: '(c) - Ξέφωτο-Άνοιγμα' },
      { id: 'd', name: '(d) - Βραχώδες' },
      { id: 'e', name: '(e) - Ετήσιες Καλλιέργειες' },
      { id: 'f', name: '(f) - Δενδρώδεις Καλλιέργειες' },
      { id: 'g', name: '(g) - Πέτρες' },
      { id: 'h', name: '(h) - Συστάδα σε καλλιέργειές' },
      { id: 'i', name: '(i) - Φυτοφράχτης' },
    ],
  },
  {
    header: 'HABITAT_CODE_3',
    propertyName: 'habitatCode3',
    searchColumn: 'HabitatCode3',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Α', name: '(A) - Φυσικό' },
      { id: 'Β', name: '(B) - Τεχνητό' },
      { id: 'Γ', name: '(Γ) - Έχει υποστεί αλλαγές' },
    ],
  },
  {
    header: 'SPECIES_ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'MICROHABITAT',
    propertyName: 'microHabitat',
    searchColumn: 'MicroHabitat',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'A', name: '(Α) - Δέντρα' },
      { id: 'B', name: '(B) - Μακία-Θάμνοι' },
      { id: 'C', name: '(C) - Φρύγανα' },
      { id: 'D', name: '(D) - Πόες' },
      { id: 'E', name: '(E) - Παρόχθια (καλάμια, βούρλα, λυγαριές)' },
      { id: 'F', name: '(F) - Έδαφος' },
      { id: 'Fa', name: '(Fa) - Οδόστρωμα' },
      { id: 'G', name: '(G) - Ξέφωτο' },
      { id: 'H', name: '(H) - Πέτρες' },
      { id: 'Ha', name: '(Ha) - Ξερολιθιά / Πετροσωρός' },
      { id: 'I', name: '(I) - Βράχια' },
      { id: 'J', name: '(J) - Άμμος' },
      { id: 'K', name: '(K) - Κορμός' },
      { id: 'Ka', name: '(Ka) - Στοίβα από ξύλα' },
      { id: 'L', name: '(L) Ρυάκι' },
      { id: 'M', name: '(M) - Ποτάμι' },
      { id: 'N', name: '(N) - Βάλτος (πιο πολλά φυτά από νερό)' },
      { id: 'O', name: '(O) - Έλος (πιο πολύ νερό από φυτά)' },
      { id: 'Pa', name: '(Pa) - Ποτίστρα/ Πηγάδι' },
      { id: 'Pb', name: '(Pb) - Στέρνα' },
      { id: 'Q', name: '(Q) - Λίμνη' },
      { id: 'Qa', name: '(Qa) - Τεχνητή λιμνοδεξαμενή' },
      { id: 'R', name: '(R) - Κτίσματα' },
    ],
  },
  {
    header: 'HABITAT_QUALITY',
    propertyName: 'habitatQuality',
    searchColumn: 'HabitatQuality',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'G', name: '(G) - Καλή' },
      { id: 'M', name: '(M) - Μέτρια' },
      { id: 'B', name: '(B) - Κακή' },
      { id: 'U', name: '(U) - Άγνωστη' },
    ],
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleMammalThreatsColumns: IDataTableColumns[] = [
  {
    header: 'RANDOM_OBSERVATION',
    propertyName: 'randomObservation',
    searchColumn: 'RandomObservation',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    preselected: true,
    isID: false,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'OBS_ID',
    propertyName: 'obs_ID',
    searchColumn: 'Obs_ID',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'PRESSURE_THREAT',
    propertyName: 'pressuresAndThreatsId',
    searchColumn: 'PressuresAndThreatsId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'SPECIES',
    propertyName: 'speciesId',
    searchColumn: 'SpeciesId',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'speciesIdOther',
    searchColumn: 'SpeciesIdOther',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'IMPORTANCE',
    propertyName: 'importance',
    searchColumn: 'Importance',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'L',
        name: 'L(Low)',
        matchMode: 'startsWith',
      },
      {
        id: 'M',
        name: 'M(Medium)',
        matchMode: 'startsWith',
      },
      {
        id: 'H',
        name: 'H(High)',
        matchMode: 'startsWith',
      },
    ],
  },
  {
    header: 'TIME_CHARACTER',
    propertyName: 'timeCharacter',
    searchColumn: 'TimeCharacter',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
        name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      },
      {
        id: 'Στο παρόν',
        name: 'Στο παρόν',
      },
      {
        id: 'Στο παρόν και στο μέλλον',
        name: 'Στο παρόν και στο μέλλον',
      },
      {
        id: 'Μόνο στο μέλλον',
        name: 'Μόνο στο μέλλον',
      },
    ],
  },
  {
    header: 'IMPACT_TYPE',
    propertyName: 'effectMode',
    searchColumn: 'EffectMode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
];

export const sampleMammalHabitatsColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'HABITAT_CODE_1',
    propertyName: 'habitatCode1',
    searchColumn: 'HabitatCode1',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      { id: '1', name: '(1) - Δάσος Κωνοφόρων' },
      { id: '2', name: '(2) - Δάσος Φυλλοβόλων' },
      { id: '3', name: '(3) - Δάσος Μεικτό' },
      { id: '4', name: '(4) - Φρύγανα' },
      { id: '5', name: '(5) - Μακκία' },
      { id: '6', name: '(6) - Καλλιέργειες' },
      { id: '7', name: '(7) - Αμμοθίνες' },
      { id: '8', name: '(8) - Υγρά Λιβάδια' },
      { id: '9', name: '(9) - Λίμνη' },
      { id: '10', name: '(10) - Ποτάμι' },
      { id: '11', name: '(11) - Κανάλι' },
      { id: '12', name: '(12) - Καλαμιώνας' },
      { id: '13', name: '(13) - Λιβάδι' },
      { id: '14', name: '(14) - Σπήλαιο' },
      { id: '15', name: '(15) - Σάρα (Χαλικώνας)' },
      { id: '16', name: '(16) - Παρόχθιο δάσος' },
      { id: '17', name: '(17) - Ορυχείο' },
      { id: '18', name: '(18) - Σπίτι' },
      { id: '19', name: '(19) - Γέφυρα' },
      { id: '20', name: '(20) - Γαλαρία' },
    ],
  },
  {
    header: 'HABITAT_CODE_2',
    propertyName: 'habitatCode2',
    searchColumn: 'HabitatCode2',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      { id: 'a', name: '(a) - Με Υποόροφο' },
      { id: 'b', name: '(b) - Χωρίς Υποόροφο' },
      { id: 'c', name: '(c) - Ξέφωτο-Άνοιγμα' },
      { id: 'd', name: '(d) - Βραχώδες' },
      { id: 'e', name: '(e) - Ετήσιες Καλλιέργειες' },
      { id: 'f', name: '(f) - Δενδρώδεις Καλλιέργειες' },
      { id: 'g', name: '(g) - Πέτρες' },
      { id: 'h', name: '(h) - Συστάδα σε καλλιέργειές' },
      { id: 'i', name: '(i) - φυτοφράχτης' },
    ],
  },
  {
    header: 'HABITAT_CODE_3',
    propertyName: 'habitatCode3',
    searchColumn: 'HabitatCode3',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      { id: 'Α', name: '(A) - Φυσικό' },
      { id: 'Β', name: '(Β) - Τεχνητό' },
      { id: 'Γ', name: '(Γ) - Έχει υποστεί αλλαγές' },
    ],
  },
  {
    header: 'HUMAN_ACTIVITIES',
    propertyName: 'humanActivities',
    searchColumn: 'HumanActivities',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      { id: '1', name: '(1) - Καμία' },
      { id: '2', name: '(2) - Υλοτομία' },
      { id: '3', name: '(3) - Αλιεία' },
      { id: '4', name: '(4) - Τουρισμός' },
      { id: '5', name: '(5) - Κτηνοτροφία (παρουσία ζώων, εγκαταστάσεων)' },
      { id: '6', name: '(6) - Γεωργία' },
      { id: '7', name: '(7) - Κυνήγι' },
      { id: '8', name: '(8) - Έργα δόμησης, οδοποιίας, κλπ' },
      { id: '9', name: '(9) - Φράγμα' },
      { id: '10', name: '(10) - ΆΛΛΟ' },
    ],
  },
];

export const sampleFishColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SampleCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'ERGO_EPOPTIA_FASI_ERGOU',
    propertyName: 'ergoEpoptiaFasiErgou',
    searchColumn: 'ErgoEpoptiaFasiErgou',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
      { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
      { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
      { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
      { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
    ],
  },
  {
    header: 'AUTHOR',
    propertyName: 'author',
    searchColumn: 'Author',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FISHER',
    propertyName: 'fisher',
    searchColumn: 'Fisher',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SITE_CODE',
    propertyName: 'siteCodeText',
    searchColumn: 'SiteCodeText',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DURATION',
    propertyName: 'duration',
    searchColumn: 'Duration',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HYDROGRAPHIC_BASIN',
    propertyName: 'hydrographicBasin',
    searchColumn: 'HydrographicBasin',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FISH_COURSE',
    propertyName: 'course',
    searchColumn: 'Course',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'REFERENCE_SITE_EST',
    propertyName: 'referenceSiteEst',
    searchColumn: 'ReferenceSiteEst',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Ναι',
        name: 'Ναι',
      },
      {
        id: 'Εγγύς',
        name: 'Εγγύς',
      },
      {
        id: 'Όχι',
        name: 'Όχι',
      },
    ],
  },
  {
    header: 'SLOPE',
    propertyName: 'slope',
    searchColumn: 'Slope',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EQUIPMENT_DROPDOWN',
    propertyName: 'samplingEquipment',
    searchColumn: 'SamplingEquipment',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SAMPLING_EFFORT',
    propertyName: 'samplingEffort',
    searchColumn: 'SamplingEffort',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ELECTRICITY',
    propertyName: 'electricity',
    searchColumn: 'Electricity',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'PDC',
        name: 'PDC',
      },
      {
        id: 'DC',
        name: 'DC',
      },
      {
        id: 'AC',
        name: 'AC',
      },
      {
        id: 'Άλλο',
        name: 'Άλλο',
      },
    ],
  },
  {
    header: 'AVERAGE_VOLTAGE',
    propertyName: 'averageVoltage',
    searchColumn: 'AverageVoltage',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AVERAGE_FREQUENCY',
    propertyName: 'averageFrequency',
    searchColumn: 'AverageFrequency',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SAMPLING_STRATEGY_A',
    propertyName: 'samplingStrategyA',
    searchColumn: 'SamplingStrategyA',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SAMPLING_STRATEGY_Β',
    propertyName: 'samplingStrategyB',
    searchColumn: 'SamplingStrategyB',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FISHED_LENGTH',
    propertyName: 'fishedLength',
    searchColumn: 'FishedLength',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FISHED_AREA',
    propertyName: 'fishedArea',
    searchColumn: 'FishedArea',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FLOW_REGIME',
    propertyName: 'flowRegime',
    searchColumn: 'FlowRegime',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SHADENESS',
    propertyName: 'shadeness',
    searchColumn: 'Shadeness',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WEATHER_FISH',
    propertyName: 'weather',
    searchColumn: 'Weather',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WETTED_WIDTH',
    propertyName: 'wettedWidthM',
    searchColumn: 'WettedWidthM',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'LEFT_BANK',
    propertyName: 'leftBankM',
    searchColumn: 'LeftBankM',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RIGHT_BANK',
    propertyName: 'rightBankM',
    searchColumn: 'RightBankM',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WIDTH_1',
    propertyName: 'width1',
    searchColumn: 'Width1',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WIDTH_5',
    propertyName: 'width5',
    searchColumn: 'Width5',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WIDTH_10',
    propertyName: 'width10',
    searchColumn: 'Width10',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WIDTH_20',
    propertyName: 'width20',
    searchColumn: 'Width20',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WIDTH_GT20',
    propertyName: 'widthGT20',
    searchColumn: 'WidthGT20',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEPTH_025',
    propertyName: 'depth025',
    searchColumn: 'Depth025',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEPTH_05',
    propertyName: 'depth05',
    searchColumn: 'Depth05',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEPTH_1',
    propertyName: 'depth1',
    searchColumn: 'Depth1',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEPTH_GT1',
    propertyName: 'depthGT1',
    searchColumn: 'DepthGT1',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEPTH_MEAN',
    propertyName: 'depthMean',
    searchColumn: 'DepthMean',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEPTH_MAX',
    propertyName: 'depthMax',
    searchColumn: 'DepthMax',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_ROCK',
    propertyName: 'subsRock',
    searchColumn: 'SubsRock',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_BOULDER',
    propertyName: 'subsBoulder',
    searchColumn: 'SubsBoulder',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_COBBLE',
    propertyName: 'subsCobble',
    searchColumn: 'SubsCobble',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_PEBBLE',
    propertyName: 'subsPebble',
    searchColumn: 'SubsPebble',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_GRAVEL',
    propertyName: 'subsGravel',
    searchColumn: 'SubsGravel',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_SAND',
    propertyName: 'subsSand',
    searchColumn: 'SubsSand',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_SILT',
    propertyName: 'subsSilt',
    searchColumn: 'SubsSilt',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_CLAY',
    propertyName: 'subsClay',
    searchColumn: 'SubsClay',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_ORGANIC',
    propertyName: 'subsOrganic',
    searchColumn: 'SubsOrganic',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUBS_ARTIFICIAL',
    propertyName: 'subsArtificial',
    searchColumn: 'SubsArtificial',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'VELOCITY',
    propertyName: 'velocityMSec',
    searchColumn: 'VelocityMSec',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HELOPHYTES',
    propertyName: 'helophytes',
    searchColumn: 'Helophytes',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HELOPHYTES_MAIN',
    propertyName: 'helophytesDominatingSp',
    searchColumn: 'HelophytesDominatingSp',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOTTOM_VEGETATION',
    propertyName: 'bottomVegetation',
    searchColumn: 'BottomVegetation',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOTTOM_VEGETATION_MAIN',
    propertyName: 'bottomVegetationDominatingSp',
    searchColumn: 'BottomVegetationDominatingSp',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'CONDUCTIVITY',
    propertyName: 'conductivitymScm',
    searchColumn: 'ConductivitymScm',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OXYGEN',
    propertyName: 'o2mgL',
    searchColumn: 'O2mgL',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'PH',
    propertyName: 'pH',
    searchColumn: 'pH',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AIR_TEMPERATURE',
    propertyName: 'tofAir',
    searchColumn: 'TofAir',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WATER_TEMPERATURE',
    propertyName: 'tofWater',
    searchColumn: 'TofWater',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SALINITY',
    propertyName: 'salinityPpt',
    searchColumn: 'SalinityPpt',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TURBIDITY',
    propertyName: 'turbidity',
    searchColumn: 'Turbidity',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABTYPE_POOL',
    propertyName: 'habTypePool',
    searchColumn: 'HabTypePool',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABTYPE_GLIDE',
    propertyName: 'habTypeGlide',
    searchColumn: 'HabTypeGlide',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABTYPE_RUN',
    propertyName: 'habTypeRun',
    searchColumn: 'HabTypeRun',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABTYPE_RIFFLE',
    propertyName: 'habTypeRiffle',
    searchColumn: 'HabTypeRiffle',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABTYPE_RAPID',
    propertyName: 'habTypeRapid',
    searchColumn: 'HabTypeRapid',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABTYPE_OTHER',
    propertyName: 'habTypeOther',
    searchColumn: 'HabTypeOther',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'IMPORTANT_PRESSURES',
    propertyName: 'importantPressures',
    searchColumn: 'ImportantPressures',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'LOGS_LARGE',
    propertyName: 'logsLargeWoodyDebris',
    searchColumn: 'LogsLargeWoodyDebris',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OVERHANGING_VEGETATION',
    propertyName: 'overhangingVegetation',
    searchColumn: 'OverhangingVegetation',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DENSE_MACROPHYTEBEDS',
    propertyName: 'denseMacrophyteBeds',
    searchColumn: 'DenseMacrophyteBeds',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEEP_POOLS',
    propertyName: 'deepPools',
    searchColumn: 'DeepPools',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOULDERS_COBBLES',
    propertyName: 'bouldersCobbles',
    searchColumn: 'BouldersCobbles',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'UNDERCUT_BANKS',
    propertyName: 'undercutBanks',
    searchColumn: 'UndercutBanks',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'THICK_ROOT_MATS',
    propertyName: 'thickRootMats',
    searchColumn: 'ThickRootMats',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'MARSHY_FRINGES',
    propertyName: 'marshyFringes',
    searchColumn: 'MarshyFringes',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ISOLATED_BACKWATER_POOLS',
    propertyName: 'isolatedBackwaterPools',
    searchColumn: 'IsolatedBackwaterPools',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RIFFLES',
    propertyName: 'riffles',
    searchColumn: 'Riffles',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EFFICACY',
    propertyName: 'efficacyOfHabitatSampling',
    searchColumn: 'EfficacyOfHabitatSampling',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OTHER_TYPES_PHYSICAL_COVERAGE',
    propertyName: 'otherTypesPhysicalCoverage',
    searchColumn: 'OtherTypesPhysicalCoverage',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUFFICIENT_HABITAT_QUALITY',
    propertyName: 'sufficientHabitatQuality',
    searchColumn: 'SufficientHabitatQuality',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'INSUFFICIENT_HABITAT_QUALITY',
    propertyName: 'insufficientHabitatQuality',
    searchColumn: 'InsufficientHabitatQuality',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'UNKNOWN_HABITAT_QUALITY',
    propertyName: 'unknownHabitatQuality',
    searchColumn: 'UnknownHabitatQuality',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SUFFICIENT_HABITAT_AREA',
    propertyName: 'sufficientHabitatArea',
    searchColumn: 'SufficientHabitatArea',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Ναι',
        name: 'Ναι',
      },
      {
        id: 'Όχι',
        name: 'Όχι',
      },
      {
        id: 'Άγνωστο',
        name: 'Άγνωστο',
      },
    ],
  },
  {
    header: 'INSUFFICIENT_HABITAT_AREA',
    propertyName: 'insufficientHabitatArea',
    searchColumn: 'InsufficientHabitatArea',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Ναι',
        name: 'Ναι',
      },
      {
        id: 'Όχι',
        name: 'Όχι',
      },
      {
        id: 'Άγνωστο',
        name: 'Άγνωστο',
      },
    ],
  },
  {
    header: 'SUFFICIENT_X_HABITAT_QUALITY',
    propertyName: 'sufficientHabitatXQuality',
    searchColumn: 'SufficientHabitatXQuality',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Ναι',
        name: 'Ναι',
      },
      {
        id: 'Όχι',
        name: 'Όχι',
      },
      {
        id: 'Άγνωστο',
        name: 'Άγνωστο',
      },
    ],
  },
  {
    header: 'HABITAT_THREATS_PRESSURES',
    propertyName: 'habitatThreats',
    searchColumn: 'HabitatThreats',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleFishSpeciesColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SampleCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FISH_YOFY',
    propertyName: 'yofY',
    searchColumn: 'YofY',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FISH_ADULTS',
    propertyName: 'adults',
    searchColumn: 'Adults',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FISH_SUM_OF_INDIVID',
    propertyName: 'sumOfIndivid',
    searchColumn: 'SumOfIndivid',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES_HABITAT',
    propertyName: 'speciesHabitat',
    searchColumn: 'SpeciesHabitat',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Ευνοϊκό',
        name: 'Ευνοϊκό',
      },
      {
        id: 'Ανεπαρκές',
        name: 'Ανεπαρκές',
      },
      {
        id: 'Δυσμενές',
        name: 'Δυσμενές',
      },
      {
        id: 'Άγνωστο',
        name: 'Άγνωστο',
      },
    ],
  },
  {
    header: 'PARASITES_PERCENT',
    propertyName: 'parasitesPerCent',
    searchColumn: 'ParasitesPerCent',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleFishThreatsColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SampleCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'PRESSURE_THREAT',
    propertyName: 'hdCode',
    searchColumn: 'HDCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'SITE_OR_SEGMENTS',
    propertyName: 'siteOrSegment',
    searchColumn: 'SiteOrSegment',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'Θέση δειγματοληψίας',
        name: 'Θέση δειγματοληψίας',
      },
      {
        id: 'Ευρύτερη περιοχή θέσης δειγματοληψίας',
        name: 'Ευρύτερη περιοχή θέσης δειγματοληψίας',
      },
    ],
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'POPULATION_PERCENTAGE',
    propertyName: 'populationPercentage',
    searchColumn: 'PopulationPercentage',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'Ολόκληρος >90%',
        name: 'Ολόκληρος >90%',
      },
      {
        id: 'Πλειονότητα 50-90%',
        name: 'Πλειονότητα 50-90%',
      },
      {
        id: 'Μειονότητα <50%',
        name: 'Μειονότητα <50%',
      },
    ],
  },
  {
    header: 'IMPACT_THREAT',
    propertyName: 'impact',
    searchColumn: 'Impact',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'L',
        name: 'L(Low)',
        matchMode: 'startsWith',
      },
      {
        id: 'M',
        name: 'M(Medium)',
        matchMode: 'startsWith',
      },
      {
        id: 'H',
        name: 'H(High)',
        matchMode: 'startsWith',
      },
    ],
  },
  {
    header: 'TIME_CHARACTER',
    propertyName: 'timeCharacter',
    searchColumn: 'TimeCharacter',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
        name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      },
      {
        id: 'Στο παρόν',
        name: 'Στο παρόν',
      },
      {
        id: 'Στο παρόν και στο μέλλον',
        name: 'Στο παρόν και στο μέλλον',
      },
      {
        id: 'Μόνο στο μέλλον',
        name: 'Μόνο στο μέλλον',
      },
    ],
  },
  {
    header: 'IMPACT_TYPE',
    propertyName: 'impactType',
    searchColumn: 'ImpactType',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
];

export const sampleAmphibianColumns: IDataTableColumns[] = [
  {
    header: 'RANDOM_OBSERVATION',
    propertyName: 'randomObservation',
    searchColumn: 'RandomObservation',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
    noFilter: true,
  },
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'METHOD',
    propertyName: 'method',
    searchColumn: 'Method',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AUTHOR',
    propertyName: 'author',
    searchColumn: 'Author',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DURATION',
    propertyName: 'duration',
    searchColumn: 'Duration',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'COURSE_LENGTH',
    propertyName: 'courseLengthM',
    searchColumn: 'CourseLengthM',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT_DESCRIPTION',
    propertyName: 'habitatDescription',
    searchColumn: 'HabitatDescription',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TEMPERATURE',
    propertyName: 'temperature',
    searchColumn: 'Temperature',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WIND_INTENSITY',
    propertyName: 'windIntensity',
    searchColumn: 'WindIntensity',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WEATHER',
    propertyName: 'weather',
    searchColumn: 'Hliof_Nefosi',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RAIN_CODE',
    propertyName: 'rainCode',
    searchColumn: 'RainCode',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RAIN_CODE',
    propertyName: 'rainCode',
    searchColumn: 'RainCode',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'LOCATION',
    propertyName: 'placeName',
    searchColumn: 'PlaceName',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AMPHIBIAN_THREATS',
    propertyName: 'threats',
    searchColumn: 'ThreatsInput',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleAmphibianHabitatsColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'HABITAT_A',
    propertyName: 'habitatCodeA',
    searchColumn: 'HabitatCodeA',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'HABITAT_B',
    propertyName: 'habitatCodeB',
    searchColumn: 'HabitatCodeB',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
];

export const sampleAmphibianSpeciessColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'SPECIES_SAMPLE_CODE',
    propertyName: 'id',
    searchColumn: 'Id',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'NUMBER_OF_PEOPLE',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'DATE',
    propertyName: 'date',
    searchColumn: 'Date',
    searchDataType: DataType.Date,
    searchDataSubType: DataSubType.DateN,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'TIME_SPECIES_FOUND',
    propertyName: 'time',
    searchColumn: 'Time',
    searchDataType: DataType.Time,
    searchDataSubType: DataSubType.TimeN,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'RESEARCHER',
    propertyName: 'researcher',
    searchColumn: 'Researcher',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'HABITAT_A',
    propertyName: 'habitatCodeA',
    searchColumn: 'HabitatCodeA',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'HABITAT_B',
    propertyName: 'habitatCodeB',
    searchColumn: 'HabitatCodeB',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'HABITAT_QUALITY',
    propertyName: 'habitatQuality',
    searchColumn: 'HabitatQuality',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'ACTIVITY',
    propertyName: 'activity',
    searchColumn: 'Activity',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'MICROHABITAT',
    propertyName: 'microHabitat',
    searchColumn: 'MicroHabitat',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
];

export const sampleAmphibianRandomSpeciessColumns: IDataTableColumns[] = [
  {
    header: 'SAM_ID',
    propertyName: 'samId',
    searchColumn: 'SamId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'OBS_ID',
    propertyName: 'obsId',
    searchColumn: 'ObsId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'SPECIES_SAMPLE_CODE',
    propertyName: 'id',
    searchColumn: 'Id',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'NUMBER_OF_PEOPLE',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'DATE',
    propertyName: 'date',
    searchColumn: 'Date',
    searchDataType: DataType.Date,
    searchDataSubType: DataSubType.DateN,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'TIME_SPECIES_FOUND',
    propertyName: 'time',
    searchColumn: 'Time',
    searchDataType: DataType.Time,
    searchDataSubType: DataSubType.TimeN,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'RESEARCHER',
    propertyName: 'researcher',
    searchColumn: 'Researcher',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'HABITAT_A',
    propertyName: 'habitatCodeA',
    searchColumn: 'HabitatCodeA',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'HABITAT_B',
    propertyName: 'habitatCodeB',
    searchColumn: 'HabitatCodeB',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'HABITAT_QUALITY',
    propertyName: 'habitatQuality',
    searchColumn: 'HabitatQuality',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'ACTIVITY',
    propertyName: 'activity',
    searchColumn: 'Activity',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'MICROHABITAT',
    propertyName: 'microHabitat',
    searchColumn: 'MicroHabitat',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
];

export const sampleAmphibianThreatsColumns: IDataTableColumns[] = [
  {
    header: 'RANDOM_OBSERVATION',
    propertyName: 'randomObservation',
    searchColumn: 'RandomObservation',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'OBS_ID',
    propertyName: 'obsId',
    searchColumn: 'ObsId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'SPECIES_SAMPLE_CODE',
    propertyName: 'speciesSampleCode',
    searchColumn: 'SpeciesSampleCode',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'PRESSURE_THREAT',
    propertyName: 'pressuresAndThreatsId',
    searchColumn: 'PressuresAndThreatsId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'IMPORTANCE',
    propertyName: 'importance',
    searchColumn: 'Importance',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'L',
        name: 'L(Low)',
        matchMode: 'startsWith',
      },
      {
        id: 'M',
        name: 'M(Medium)',
        matchMode: 'startsWith',
      },
      {
        id: 'H',
        name: 'H(High)',
        matchMode: 'startsWith',
      },
    ],
  },
  {
    header: 'TIME_CHARACTER',
    propertyName: 'timeCharacter',
    searchColumn: 'TimeCharacter',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
        name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      },
      {
        id: 'Στο παρόν',
        name: 'Στο παρόν',
      },
      {
        id: 'Στο παρόν και στο μέλλον',
        name: 'Στο παρόν και στο μέλλον',
      },
      {
        id: 'Μόνο στο μέλλον',
        name: 'Μόνο στο μέλλον',
      },
    ],
  },
  {
    header: 'IMPACT_TYPE',
    propertyName: 'impact',
    searchColumn: 'Impact',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
];

export const sampleSeaColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'ERGO_EPOPTIA_FASI_ERGOU',
    propertyName: 'ergoEpoptiaFasiErgou',
    searchColumn: 'ErgoEpoptiaFasiErgou',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
      { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
      { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
      { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
      { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
    ],
  },
  {
    header: 'DURATION_SEA',
    propertyName: 'duration',
    searchColumn: 'Duration',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT_TYPE',
    propertyName: 'habType',
    searchColumn: 'HabType',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'METHOD',
    propertyName: 'method',
    searchColumn: 'Method',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Παρατήρηση επί σκάφους', name: 'Παρατήρηση επί σκάφους' },
      { id: 'Ακουστική', name: 'Ακουστική' },
      {
        id: 'Συνδυασμένη οπτική και ακουστική  αναζήτηση κητωδών με χρήση σκάφους',
        name: 'Συνδυασμένη οπτική και ακουστική  αναζήτηση κητωδών με χρήση σκάφους',
      },
      { id: 'Παρατήρηση από αέρως', name: 'Παρατήρηση από αέρως' },
      { id: 'Εκβρασμός', name: 'Εκβρασμός' },
      { id: 'Αυτόματη κάμερα', name: 'Αυτόματη κάμερα' },
      {
        id: 'Παρατήρηση κατά την επίσκεψη σε καταφύγιο',
        name: 'Παρατήρηση κατά την επίσκεψη σε καταφύγιο',
      },
      {
        id: 'Καταγραφή παρατήρησης μέσω του Δικτύου Διάσωσης και Συλλογής Πληροφοριών (ΔΔΣΠ)',
        name: 'Καταγραφή παρατήρησης μέσω του Δικτύου Διάσωσης και Συλλογής Πληροφοριών (ΔΔΣΠ)',
      },
      {
        id: 'Παρατήρηση ιχνών στην παραλία',
        name: 'Παρατήρηση ιχνών στην παραλία',
      },
      {
        id: 'Παρατήρηση ατόμων στην παραλία',
        name: 'Παρατήρηση ατόμων στην παραλία',
      },
      { id: 'Σύλληψη ατόμων στη θάλασσα', name: 'Σύλληψη ατόμων στη θάλασσα' },
      { id: 'Δειγματοληψία ιζήματος', name: 'Δειγματοληψία ιζήματος' },
      { id: 'Δειγματοληψία μακροφύτων', name: 'Δειγματοληψία μακροφύτων' },
      { id: 'Αυτοψία από την ακτή', name: 'Αυτοψία από την ακτή' },
      {
        id: 'Υποβρύχιες οπτικές μετρήσεις με ελεύθερη κατάδυση',
        name: 'Υποβρύχιες οπτικές μετρήσεις με ελεύθερη κατάδυση',
      },
      {
        id: 'Υποβρύχιες οπτικές μετρήσεις με αυτόνομη κατάδυση',
        name: 'Υποβρύχιες οπτικές μετρήσεις με αυτόνομη κατάδυση',
      },
      {
        id: 'Επιτόπιες τηλεπισκοπικές μέθοδοι (υποβρύχια κάμερα ή drone)',
        name: 'Επιτόπιες τηλεπισκοπικές μέθοδοι (υποβρύχια κάμερα ή drone)',
      },
      { id: 'Δορυφορική τηλεπισκόπηση', name: 'Δορυφορική τηλεπισκόπηση' },
    ],
  },
  {
    header: 'TRANSECT_LENGTH',
    propertyName: 'transectLength',
    searchColumn: 'TransectLength',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'MIN_DEPTH',
    propertyName: 'minDepth',
    searchColumn: 'MinDepth',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'MAX_DEPTH',
    propertyName: 'maxDepth',
    searchColumn: 'MaxDepth',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AUTHOR',
    propertyName: 'author',
    searchColumn: 'Author',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES_CATEGORY',
    propertyName: 'speciesCategory',
    searchColumn: 'SpeciesCategory',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: SeaSpeciesCategory.Cetaceans, name: SeaSpeciesCategory.Cetaceans },
      { id: SeaSpeciesCategory.Seals, name: SeaSpeciesCategory.Seals },
      {
        id: SeaSpeciesCategory.SeaTurtles,
        name: SeaSpeciesCategory.SeaTurtles,
      },
      {
        id: SeaSpeciesCategory.SeaInvertebrates,
        name: SeaSpeciesCategory.SeaInvertebrates,
      },
    ],
  },
  {
    header: 'DURATION_ON_EFFORT',
    propertyName: 'durationOnEffort',
    searchColumn: 'DurationOnEffort',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TRANSECT_HEADING',
    propertyName: 'transectHeading',
    searchColumn: 'TransectHeading',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT_THREATS',
    propertyName: 'habitatThreats',
    searchColumn: 'HabitatThreats',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TIME_MEASUREMENT_UNIT',
    propertyName: 'timeMeasurementUnit',
    searchColumn: 'TimeMeasurementUnit',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Λεπτά', name: 'Λεπτά' },
      { id: 'Ώρες', name: 'Ώρες' },
      { id: 'Ημέρες', name: 'Ημέρες' },
      { id: 'Μήνες', name: 'Μήνες' },
    ],
  },
  {
    header: 'TERRESTRIAL_HABITAT_SEAL',
    propertyName: 'terrestrialHabitatSeal',
    searchColumn: 'TerrestrialHabitatSeal',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Κατάλληλο για ξεκούραση', name: 'Κατάλληλο για ξεκούραση' },
      {
        id: 'Κατάλληλο για ξεκούραση και αναπαραγωγή',
        name: 'Κατάλληλο για ξεκούραση και αναπαραγωγή',
      },
    ],
  },
  {
    header: 'SHAPEFILE',
    propertyName: 'shapefile',
    searchColumn: 'Shapefile',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaSpeciesColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEPTH',
    propertyName: 'depth',
    searchColumn: 'Depth',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AGE',
    propertyName: 'age',
    searchColumn: 'Age',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Νεογέννητο', name: 'Νεογέννητο' },
      { id: 'Απογαλακτισμένο', name: 'Απογαλακτισμένο' },
      { id: 'Νεαρό', name: 'Νεαρό' },
      { id: 'Ενήλικο', name: 'Ενήλικο' },
      { id: 'Άγνωστο', name: 'Άγνωστο' },
      { id: 'Νεαρό άτομο', name: 'Νεαρό άτομο' },
      { id: 'Ενήλικο άτομο', name: 'Ενήλικο άτομο' },
      { id: 'Άγνωστο', name: 'Άγνωστο' },
    ],
  },
  {
    header: 'GENDER',
    propertyName: 'gender',
    searchColumn: 'Gender',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Αρσενικό', name: 'Αρσενικό' },
      { id: 'Θηλυκό', name: 'Θηλυκό' },
    ],
  },
  {
    header: 'NUMBER_OF_PEOPLE',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT',
    propertyName: 'habitat',
    searchColumn: 'Habitat',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'A', name: '(A) - Καταφύγιο/ Σπηλιά: Εντός αυτού, στην ξηρά' },
      { id: 'B', name: '(B) - Καταφύγιο/ Σπηλιά: Εντός αυτού, στο νερό' },
      { id: 'C', name: '(C) - Καταφύγιο/ Σπηλιά: Εκτός αυτού' },
      { id: 'D', name: '(D) - Καταφύγιο/ Σπηλιά: Ασαφές' },
      { id: 'E', name: '(E) - Ανοιχτό σπήλαιο' },
      { id: 'F', name: '(F) - Παραλία' },
      { id: 'G', name: '(G) - Βράχια' },
      { id: 'H', name: '(H) - Θάλασσα' },
      { id: 'I', name: '(I) - Οικιστική περιοχή' },
    ],
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES_CATEGORY',
    propertyName: 'speciesCategory',
    searchColumn: 'SpeciesCategory',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: SeaSpeciesCategory.Cetaceans, name: SeaSpeciesCategory.Cetaceans },
      { id: SeaSpeciesCategory.Seals, name: SeaSpeciesCategory.Seals },
      {
        id: SeaSpeciesCategory.SeaTurtles,
        name: SeaSpeciesCategory.SeaTurtles,
      },
      {
        id: SeaSpeciesCategory.SeaInvertebrates,
        name: SeaSpeciesCategory.SeaInvertebrates,
      },
    ],
  },
  {
    header: 'DEPTH_RANGE',
    propertyName: 'depthRange',
    searchColumn: 'DepthRange',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_DEAD_PEOPLE',
    propertyName: 'numberOfDeadPeople',
    searchColumn: 'NumberOfDeadPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'PRESENCE',
    propertyName: 'presence',
    searchColumn: 'Presence',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BIOTOPE',
    propertyName: 'biotope',
    searchColumn: 'Biotope',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TOTAL_LENGTH',
    propertyName: 'totalLength',
    searchColumn: 'TotalLength',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'LENGTH_TURTLE',
    propertyName: 'lengthTurtle',
    searchColumn: 'LengthTurtle',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'METHOD_TURTLE',
    propertyName: 'methodTurtle',
    searchColumn: 'MethodTurtle',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Από το ίχνος φωλεοποίησης',
        name: 'Από το ίχνος φωλεοποίησης',
        matchMode: 'startsWith',
      },
      {
        id: 'Από εκκόλαψη αβγών',
        name: 'Από εκκόλαψη αβγών',
        matchMode: 'startsWith',
      },
      { id: 'Από θήρευση', name: 'Από θήρευση', matchMode: 'startsWith' },
      {
        id: 'Από παρατήρηση της ωοτοκίας',
        name: 'Από παρατήρηση της ωοτοκίας',
        matchMode: 'startsWith',
      },
      { id: 'Άλλο', name: 'Άλλο', matchMode: 'startsWith' },
    ],
  },
  {
    header: 'DEATH_STATUS',
    propertyName: 'deathStatus',
    searchColumn: 'DeathStatus',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'ΝΕΚΡΟ', name: 'ΝΕΚΡΟ' },
      { id: 'ΖΩΝΤΑΝΟ', name: 'ΖΩΝΤΑΝΟ' },
    ],
  },
  {
    header: 'DEATH_REASON',
    propertyName: 'deathReasson',
    searchColumn: 'DeathReasson',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'ΑΓΝΩΣΤΟ', name: 'ΑΓΝΩΣΤΟ' },
      { id: 'ΑΝΘΡΩΠΟΓΕΝΝΗΣ', name: 'ΑΝΘΡΩΠΟΓΕΝΝΗΣ' },
      { id: 'ΜΗ ΑΝΘΡΩΠΟΓΕΝΝΗΣ', name: 'ΜΗ ΑΝΘΡΩΠΟΓΕΝΝΗΣ' },
    ],
  },
  {
    header: 'ObsId',
    propertyName: 'obsId',
    searchColumn: 'ObsId',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_NEWBORN_CETACEANS',
    propertyName: 'numberOfNewbornCetaceans',
    searchColumn: 'NumberOfNewbornCetaceans',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_VERY_YOUNG_CETACEANS',
    propertyName: 'numberOfVeryYoungCetaceans',
    searchColumn: 'NumberOfVeryYoungCetaceans',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_YOUNG_CETACEANS',
    propertyName: 'numberOfYoungCetaceans',
    searchColumn: 'NumberOfYoungCetaceans',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_SUB_ADULT_CETACEANS',
    propertyName: 'numberOfSubAdultCetaceans',
    searchColumn: 'NumberOfSubAdultCetaceans',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_ADULT_CETACEANS',
    propertyName: 'numberOfAdultCetaceans',
    searchColumn: 'NumberOfAdultCetaceans',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_MALE_CETACEANS',
    propertyName: 'numberOfMaleCetaceans',
    searchColumn: 'NumberOfMaleCetaceans',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_FEMALE_CETACEANS',
    propertyName: 'numberOfFemaleCetaceans',
    searchColumn: 'NumberOfFemaleCetaceans',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_UNDEFINED_GENDER_CETACEANS',
    propertyName: 'numberOfUndefinedGenderCetaceans',
    searchColumn: 'NumberOfUndefinedGenderCetaceans',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OBSERVATION_DATE_CETACEANS',
    propertyName: 'observationDateCetaceans',
    searchColumn: 'ObservationDateCetaceans',
    searchDataType: DataType.Date,
    searchDataSubType: DataSubType.DateN,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaPinnaColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'CROSS_SECTION_PART_SPECIES',
    propertyName: 'crossSectionPart',
    searchColumn: 'CrossSectionPart',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: '00-05', name: '00-05' },
      { id: '05-10', name: '05-10' },
      { id: '10-15', name: '10-15' },
      { id: '15-20', name: '15-20' },
      { id: '20-25', name: '20-25' },
      { id: '25-30', name: '25-30' },
      { id: '30-35', name: '30-35' },
      { id: '35-40', name: '35-40' },
      { id: '40-45', name: '40-45' },
      { id: '45-50', name: '45-50' },
      { id: '50-55', name: '50-55' },
      { id: '55-60', name: '55-60' },
      { id: '60-65', name: '60-65' },
      { id: '65-70', name: '65-70' },
      { id: '70-75', name: '70-75' },
      { id: '75-80', name: '75-80' },
      { id: '80-85', name: '80-85' },
      { id: '85-90', name: '85-90' },
      { id: '90-95', name: '90-95' },
      { id: '95-100', name: '95-100' },
      { id: '100-105', name: '100-105' },
      { id: '105-110', name: '105-110' },
      { id: '110-115', name: '110-115' },
      { id: '115-120', name: '115-120' },
      { id: '120-125', name: '120-125' },
      { id: '125-130', name: '125-130' },
      { id: '130-135', name: '130-135' },
      { id: '135-140', name: '135-140' },
      { id: '140-145', name: '140-145' },
      { id: '145-150', name: '145-150' },
      { id: '150-155', name: '150-155' },
      { id: '155-160', name: '155-160' },
      { id: '160-165', name: '160-165' },
      { id: '165-170', name: '165-170' },
      { id: '170-175', name: '170-175' },
      { id: '175-180', name: '175-180' },
      { id: '180-185', name: '180-185' },
      { id: '185-190', name: '185-190' },
      { id: '190-195', name: '190-195' },
      { id: '195-200', name: '195-200' },
    ],
  },
  {
    header: 'VERTICAL_DISTANCE',
    propertyName: 'verticalDistance',
    searchColumn: 'VerticalDistance',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEPTH',
    propertyName: 'depth',
    searchColumn: 'Depth',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AVERAGE_CROSS_SECTION_DEPTH',
    propertyName: 'averageCrossSectionDepth',
    searchColumn: 'AverageCrossSectionDepth',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TYPICAL_SIZE',
    propertyName: 'typicalSize',
    searchColumn: 'TypicalSize',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BIOTOPE',
    propertyName: 'biotope',
    searchColumn: 'Biotope',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaThreatsColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'PRESSURE_THREAT',
    propertyName: 'pressuresAndThreatsId',
    searchColumn: 'PressuresAndThreatsId',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'IMPACT',
    propertyName: 'impact',
    searchColumn: 'Impact',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'L',
        name: 'L(Low)',
        matchMode: 'startsWith',
      },
      {
        id: 'M',
        name: 'M(Medium)',
        matchMode: 'startsWith',
      },
      {
        id: 'H',
        name: 'H(High)',
        matchMode: 'startsWith',
      },
    ],
  },
  {
    header: 'TIME_CHARACTER',
    propertyName: 'timeCharacter',
    searchColumn: 'TimeCharacter',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
        name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      },
      {
        id: 'Στο παρόν',
        name: 'Στο παρόν',
      },
      {
        id: 'Στο παρόν και στο μέλλον',
        name: 'Στο παρόν και στο μέλλον',
      },
      {
        id: 'Μόνο στο μέλλον',
        name: 'Μόνο στο μέλλον',
      },
    ],
  },
  {
    header: 'IMPACT_TYPE',
    propertyName: 'effectMode',
    searchColumn: 'EffectMode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
];

export const sampleChlorisColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'ERGO_EPOPTIA_FASI_ERGOU',
    propertyName: 'ergoEpoptiaFasiErgou',
    searchColumn: 'ErgoEpoptiaFasiErgou',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'E2A',
        name: '2ο έργο εποπτείας - Φάση Α',
      },
      {
        id: 'E2B',
        name: '2ο έργο εποπτείας - Φάση Β',
      },
      {
        id: 'E2C',
        name: '2ο έργο εποπτείας - Φάση Γ',
      },
      {
        id: 'E2D',
        name: '2ο έργο εποπτείας - Φάση Δ',
      },
      {
        id: 'E1X',
        name: '1ο έργο εποπτείας - Όλες οι φάσεις',
      },
    ],
  },
  {
    header: 'HABITAT',
    propertyName: 'habitat',
    searchColumn: 'Habitat',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'AUTHOR',
    propertyName: 'author',
    searchColumn: 'Author',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ALTITUDE',
    propertyName: 'altitudeText',
    searchColumn: 'AltitudeText',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'GEOLOGICAL_SUBSTRATE',
    propertyName: 'substrate',
    searchColumn: 'Substrate',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RELIEF',
    propertyName: 'relief',
    searchColumn: 'Relief',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SOIL',
    propertyName: 'soil',
    searchColumn: 'Soil',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'A0_DEPTH',
    propertyName: 'a0',
    searchColumn: 'Α0',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EXPOSITION_D',
    propertyName: 'exposit',
    searchColumn: 'Exposit',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SLOPE',
    propertyName: 'slope',
    searchColumn: 'Slope',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT_THREATS',
    propertyName: 'threats',
    searchColumn: 'Threats',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'UNIT',
    propertyName: 'unit',
    searchColumn: 'Unit',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'STRATIFICATION',
    propertyName: 'strat',
    searchColumn: 'Strat',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'MEASURE_METHOD',
    propertyName: 'method',
    searchColumn: 'Method',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EPOPTIA_I',
    propertyName: 'epoptiaOneId',
    searchColumn: 'EpoptiaOneId',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EPOPTIA_I_COMMENTS',
    propertyName: 'epoptiaOneComments',
    searchColumn: 'EpoptiaOneComments',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'QUALITY',
    propertyName: 'quality',
    searchColumn: 'Quality',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Επαρκής',
        name: 'Επαρκής',
      },
      {
        id: 'Ανεπαρκής',
        name: 'Ανεπαρκής',
      },
      {
        id: 'Άγνωστη',
        name: 'Άγνωστη',
      },
    ],
  },
  {
    header: 'QUALITY_TREND',
    propertyName: 'qualityTrend',
    searchColumn: 'QualityTrend',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: '+',
        name: '+',
      },
      {
        id: '=',
        name: '=',
      },
      {
        id: '-',
        name: '-',
      },
      {
        id: 'Αβέβαιη',
        name: 'Αβέβαιη',
      },
      {
        id: 'Άγνωστη',
        name: 'Άγνωστη',
      },
    ],
  },
  {
    header: 'QUALITY_FIX',
    propertyName: 'qualityFix',
    searchColumn: 'QualityFix',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Εύκολη',
        name: 'Εύκολη',
      },
      {
        id: 'Δυνατή',
        name: 'Δυνατή',
      },
      {
        id: 'Δύσκολη/Αδύνατη',
        name: 'Δύσκολη/Αδύνατη',
      },
    ],
  },
  {
    header: 'AREA',
    propertyName: 'area',
    searchColumn: 'Area',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Επαρκής',
        name: 'Επαρκής',
      },
      {
        id: 'Ανεπαρκής',
        name: 'Ανεπαρκής',
      },
      {
        id: 'Άγνωστη',
        name: 'Άγνωστη',
      },
    ],
  },
  {
    header: 'AREA_TREND',
    propertyName: 'areaTrend',
    searchColumn: 'AreaTrend',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: '+',
        name: '+',
      },
      {
        id: '=',
        name: '=',
      },
      {
        id: '-',
        name: '-',
      },
      {
        id: 'Αβέβαιη',
        name: 'Αβέβαιη',
      },
      {
        id: 'Άγνωστη',
        name: 'Άγνωστη',
      },
    ],
  },
  {
    header: 'AREA_FIX',
    propertyName: 'areaFix',
    searchColumn: 'AreaFix',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Εύκολη',
        name: 'Εύκολη',
      },
      {
        id: 'Δυνατή',
        name: 'Δυνατή',
      },
      {
        id: 'Δύσκολη/Αδύνατη',
        name: 'Δύσκολη/Αδύνατη',
      },
    ],
  },
  {
    header: 'POPULATION_OBSERVATIONS',
    propertyName: 'populationObservations',
    searchColumn: 'PopulationObservations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleChlorisFunctionsColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
  },
  {
    header: 'STRUCTURE',
    propertyName: 'structure',
    searchColumn: 'Structure',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'QUALITY_EX',
    propertyName: 'statusEx',
    searchColumn: 'StatusEx',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'QUALITY_MED',
    propertyName: 'statusMed',
    searchColumn: 'StatusMed',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'QUALITY_BAD',
    propertyName: 'statusBad',
    searchColumn: 'StatusBad',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleChlorisThreatsColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
  },
  {
    header: 'CODE_OF_SPECIES_THREATS',
    propertyName: 'pressuresAndThreatsId',
    searchColumn: 'PressuresAndThreatsId',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TIME_CHARACTER',
    propertyName: 'timeCharacter',
    searchColumn: 'TimeCharacter',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
        name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      },
      {
        id: 'Στο παρόν',
        name: 'Στο παρόν',
      },
      {
        id: 'Στο παρόν και στο μέλλον',
        name: 'Στο παρόν και στο μέλλον',
      },
      {
        id: 'Μόνο στο μέλλον',
        name: 'Μόνο στο μέλλον',
      },
    ],
  },
  {
    header: 'IMPORTANCE_THREAT',
    propertyName: 'importance',
    searchColumn: 'Importance',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TARGET',
    propertyName: 'target',
    searchColumn: 'Target',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Πληθυσμό',
        name: 'Πληθυσμό',
      },
      {
        id: 'Ενδιαίτημα',
        name: 'Ενδιαίτημα',
      },
      {
        id: 'Πληθυσμό & Ενδιαίτημα',
        name: 'Πληθυσμό & Ενδιαίτημα',
      },
    ],
  },
  {
    header: 'COVER_WH_MA_MI',
    propertyName: 'cover_Wh_Ma_Mi',
    searchColumn: 'Cover_Wh_Ma_Mi',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Wh',
        name: '(Wh) >90%',
      },
      {
        id: 'Ma',
        name: '(Ma) 50-90%',
      },
      {
        id: 'Mi',
        name: '(Mi) <50%',
      },
    ],
  },
  {
    header: 'COVER',
    propertyName: 'cover',
    searchColumn: 'Cover',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FOREIGN_SPECIES',
    propertyName: 'foreignSpecies',
    searchColumn: 'ForeignSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EXPLANATION',
    propertyName: 'explanation',
    searchColumn: 'Explanation',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleChlorisPopulationColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
  },
  {
    header: 'AA_POLYGON',
    propertyName: 'aApolygon',
    searchColumn: 'AApolygon',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SAMPLING_SURFACE',
    propertyName: 'samplingSurface',
    searchColumn: 'SamplingSurface',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ADULT_K1',
    propertyName: 'adult_K1',
    searchColumn: 'Adult_K1',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ADULT_K2',
    propertyName: 'adult_K2',
    searchColumn: 'Adult_K2',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ADULT_A',
    propertyName: 'adult_A',
    searchColumn: 'Adult_A',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'YOUNG_A',
    propertyName: 'young_A',
    searchColumn: 'Young_A',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'YOUNG_K2',
    propertyName: 'young_K2',
    searchColumn: 'Young_K2',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'VEGET_A',
    propertyName: 'veget_A',
    searchColumn: 'Veget_A',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'VEGET_K2',
    propertyName: 'veget_K2',
    searchColumn: 'Veget_K2',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'POPULATION_AREA',
    propertyName: 'populationArea',
    searchColumn: 'PopulationArea',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'CLUSTER_MIN',
    propertyName: 'clusterMin',
    searchColumn: 'ClusterMin',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'CLUSTER_MAX',
    propertyName: 'clusterMax',
    searchColumn: 'ClusterMax',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'POPULATION_HEIGHT',
    propertyName: 'height',
    searchColumn: 'Height',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'VITALITY',
    propertyName: 'vit',
    searchColumn: 'Vit',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'SEX',
    propertyName: 'sex',
    searchColumn: 'Sex',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'STRUCTURE',
    propertyName: 'structure',
    searchColumn: 'Structure',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'POPULATION_COMMENTS',
    propertyName: 'comment',
    searchColumn: 'Comment',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaHabitatColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'ERGO_EPOPTIA_FASI_ERGOU',
    propertyName: 'ergoEpoptiaFasiErgou',
    searchColumn: 'ErgoEpoptiaFasiErgou',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
      { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
      { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
      { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
      { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
    ],
  },
  {
    header: 'HABITAT_TYPE',
    propertyName: 'habType',
    searchColumn: 'HabType',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: true,
  },
  {
    header: 'MIN_DEPTH',
    propertyName: 'depthMin',
    searchColumn: 'DepthMin',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    preselected: true,
    isID: true,
  },
  {
    header: 'MAX_DEPTH',
    propertyName: 'depthMax',
    searchColumn: 'DepthMax',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    preselected: true,
    isID: true,
  },
  {
    header: 'SHORE_ORIENTATION',
    propertyName: 'orientation',
    searchColumn: 'Orientation',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: true,
    data: [
      { id: 'N', name: 'N' },
      { id: 'NE', name: 'NE' },
      { id: 'NW', name: 'NW' },
      { id: 'S', name: 'S' },
      { id: 'SE', name: 'SE' },
      { id: 'SW', name: 'SW' },
      { id: 'W', name: 'W' },
      { id: 'E', name: 'E' },
    ],
  },
  {
    header: 'MEADOW_LANDSCAPE',
    propertyName: 'meadowLandscape',
    searchColumn: 'MeadowLandscape',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: true,
    data: [
      { id: 'ΛΙΒΑΔΙ ΕΝΙΑΙΟ', name: 'ΛΙΒΑΔΙ ΕΝΙΑΙΟ' },
      {
        id: 'ΛΙΒΑΔΙ ΜΕ ΦΥΣΙΚΑ ΔΙΑΚΕΝΑ (PATCHY)',
        name: 'ΛΙΒΑΔΙ ΜΕ ΦΥΣΙΚΑ ΔΙΑΚΕΝΑ (PATCHY)',
      },
      { id: 'ΛΙΒΑΔΙ ΜΕ ΡΑΒΔΩΣΕΙΣ', name: 'ΛΙΒΑΔΙ ΜΕ ΡΑΒΔΩΣΕΙΣ' },
      {
        id: 'ΛΙΒΑΔΙ ΠΟΥ ΣΧΗΜΑΤΊΖΕΙ ΥΦΑΛΟΥΣ',
        name: 'ΛΙΒΑΔΙ ΠΟΥ ΣΧΗΜΑΤΊΖΕΙ ΥΦΑΛΟΥΣ',
      },
      {
        id: 'ΛΙΒΑΔΙ ΜΕ ΦΑΣΕΙΣ DEAD MATTE',
        name: 'ΛΙΒΑΔΙ ΜΕ ΦΑΣΕΙΣ DEAD MATTE',
      },
    ],
  },
  {
    header: 'AUTHOR',
    propertyName: 'author',
    searchColumn: 'Author',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
  },
  {
    header: 'METHOD',
    propertyName: 'method',
    searchColumn: 'Method',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: true,
    data: [
      { id: 'Δειγματοληψία ιζήματος', name: 'Δειγματοληψία ιζήματος' },
      { id: 'Δειγματοληψία μακροφύτων', name: 'Δειγματοληψία μακροφύτων' },
      { id: 'Αυτοψία από ακτή', name: 'Αυτοψία από ακτή' },
      {
        id: 'Υποβρύχιες οπτικές μετρήσεις με ελεύθερη κατάδυση',
        name: 'Υποβρύχιες οπτικές μετρήσεις με ελεύθερη κατάδυση',
      },
      {
        id: 'Υποβρύχιες οπτικές μετρήσεις με αυτόνομη κατάδυση',
        name: 'Υποβρύχιες οπτικές μετρήσεις με αυτόνομη κατάδυση',
      },
      {
        id: 'Επιτόπιες τηλεπισκοπικές μέθοδοι (υποβρύχια κάμερα ή drone)',
        name: 'Επιτόπιες τηλεπισκοπικές μέθοδοι (υποβρύχια κάμερα ή drone)',
      },
      { id: 'Δορυφορική τηλεπισκόπηση', name: 'Δορυφορική τηλεπισκόπηση' },
    ],
  },
  {
    header: 'TYPOLOGY',
    propertyName: 'typology',
    searchColumn: 'Typology',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: true,
    data: [
      { id: 'A', name: 'A' },
      { id: 'B', name: 'B' },
    ],
  },
  {
    header: 'SHAPEFILE',
    propertyName: 'shapefile',
    searchColumn: 'Shapefile',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
  },
];

export const sampleSeaHabitatSpeciesColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'SPECIES',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'OTHER_SPECIES',
    propertyName: 'otherSpecies',
    searchColumn: 'OtherSpecies',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RELATIVE_ABUNDANCE',
    propertyName: 'relativeAbundance',
    searchColumn: 'RelativeAbundance',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Α', name: '(Α) - Άφθονο' },
      { id: 'Κ', name: '(Κ) - Κοινό' },
      { id: 'Π', name: '(Π) - Παρόν' },
      { id: 'Ν', name: '(Ν) - Νεκρό' },
    ],
  },
  {
    header: 'POPULATION_DENSITY',
    propertyName: 'populationDensity',
    searchColumn: 'PopulationDensity',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaHabitatThreatsColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'CODE_OF_SPECIES_THREATS',
    propertyName: 'pressuresAndThreatsId',
    searchColumn: 'PressuresAndThreatsId',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT_TYPE',
    propertyName: 'habType',
    searchColumn: 'HabType',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32,
    shown: true,
    preselected: true,
    isID: true,
  },
  {
    header: 'IMPORTANCE',
    propertyName: 'influenceIntensity',
    searchColumn: 'InfluenceIntensity',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'L',
        name: 'L(Low)',
        matchMode: 'startsWith',
      },
      {
        id: 'M',
        name: 'M(Medium)',
        matchMode: 'startsWith',
      },
      {
        id: 'H',
        name: 'H(High)',
        matchMode: 'startsWith',
      },
    ],
  },
  {
    header: 'TIME_DETERMINATOR',
    propertyName: 'timeDetermination',
    searchColumn: 'TimeDetermination',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
        name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      },
      { id: 'Στο παρόν', name: 'Στο παρόν' },
      { id: 'Στο παρόν και στο μέλλον', name: 'Στο παρόν και στο μέλλον' },
      { id: 'Μόνο στο μέλλον', name: 'Μόνο στο μέλλον' },
    ],
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaHabitatStructures1120aColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'PICKET',
    propertyName: 'picket',
    searchColumn: 'Picket',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'LINEAR_DISTANCE',
    propertyName: 'linearDistance',
    searchColumn: 'LinearDistance',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'LOWEST_LIMIT_DEPTH',
    propertyName: 'lowestLimitDepth',
    searchColumn: 'LowestLimitDepth',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'LOWEST_LIMIT_TOPOLOGY',
    propertyName: 'lowestLimitTypology',
    searchColumn: 'LowestLimitTypology',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Προελαύνον', name: 'Προελαύνον' },
      {
        id: 'Σταθερό (Γραμμικό με παρουσία πλαγιότροπων)',
        name: 'Σταθερό (Γραμμικό με παρουσία πλαγιότροπων)',
      },
      {
        id: 'Σταθερό (Γραμμικό χωρίς παρουσία πλαγιότροπων)',
        name: 'Σταθερό (Γραμμικό χωρίς παρουσία πλαγιότροπων)',
      },
      { id: 'Υποχωρόν', name: 'Υποχωρόν' },
    ],
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaHabitatStructures1120bColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'POSITION',
    propertyName: 'position',
    searchColumn: 'Position',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Διατομή 1', name: 'Διατομή 1' },
      { id: 'Διατομή 2', name: 'Διατομή 2' },
      { id: 'Διατομή 3', name: 'Διατομή 3' },
      { id: 'Κέντρο', name: 'Κέντρο' },
    ],
  },
  {
    header: 'MEAN_SPROUT_DENSITY',
    propertyName: 'meanSproutDensity',
    searchColumn: 'MeanSproutDensity',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'MEAN_PERCENTAGE_PLAGIOTROPIN',
    propertyName: 'meanPercentagePlagiotropin',
    searchColumn: 'MeanPercentagePlagiotropin',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'MEAN_LEAVES_BUNDLE_LENGTH',
    propertyName: 'meanLeavesBundleLength',
    searchColumn: 'MeanLeavesBundleLength',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ORIENTATION',
    propertyName: 'orientation',
    searchColumn: 'Orientation',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SAND_COVERAGE',
    propertyName: 'sandCoverage',
    searchColumn: 'SandCoverage',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'ROCK_COVERAGE',
    propertyName: 'rockCoverage',
    searchColumn: 'RockCoverage',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'DEAD_MATTE_COVERAGE',
    propertyName: 'deadMatteCoverage',
    searchColumn: 'DeadMatteCoverage',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'C_NODOSA_COVERAGE',
    propertyName: 'cNodosaCoverage',
    searchColumn: 'CNodosaCoverage',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'CAULERPA_COVERAGE',
    propertyName: 'caulerpaCoverage',
    searchColumn: 'CaulerpaCoverage',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'H_STIPULACE_COVERAGE',
    propertyName: 'hStipulaceCoverage',
    searchColumn: 'HStipulaceCoverage',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaHabitatStructures1120cColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'HIGHER_LIMIT_DEPTH',
    propertyName: 'higherLimitDepth',
    searchColumn: 'HigherLimitDepth',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleSeaHabitatStructures1170Columns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'EGSIA01',
    propertyName: 'EGSIA01',
    searchColumn: 'EGSIA01',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EGSIB01',
    propertyName: 'EGSIB01',
    searchColumn: 'EGSIB01',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EGSIC01',
    propertyName: 'EGSIC01',
    searchColumn: 'EGSIC01',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EGSIIA01',
    propertyName: 'EGSIIA01',
    searchColumn: 'EGSIIA01',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'EGSIIB01',
    propertyName: 'EGSIIB01',
    searchColumn: 'EGSIIB01',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'INVERTEBRATE_01',
    propertyName: 'invertebrate01',
    searchColumn: 'Invertebrate01',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'INVERTEBRATE_5',
    propertyName: 'invertebrate5',
    searchColumn: 'Invertebrate5',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OTHER_01',
    propertyName: 'other01',
    searchColumn: 'Other01',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'OTHER_5',
    propertyName: 'other5',
    searchColumn: 'Other5',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WOODY_5',
    propertyName: 'woody5',
    searchColumn: 'Woody5',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BUSHY_5',
    propertyName: 'bushy5',
    searchColumn: 'Bushy5',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'FILAMENTOUS_ELOQUENT_5',
    propertyName: 'filamentousEloquent5',
    searchColumn: 'FilamentousEloquent5',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const bookColumns: IDataTableColumns[] = [
  {
    header: 'BOOK_REFERENCE_ID',
    propertyName: 'referenceId',
    searchColumn: 'ReferenceId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
  },
  {
    header: 'ERGO_EPOPTIA_FASI_ERGOU',
    propertyName: 'ergoEpoptiaFasiErgou',
    searchColumn: 'ErgoEpoptiaFasiErgou',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
      { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
      { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
      { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
      { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
    ],
  },
  {
    header: 'BOOK_SPECIES_CATEGORY',
    propertyName: 'speciesCategory',
    searchColumn: 'SpeciesCategory',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_REFERENCE_TYPE',
    propertyName: 'referenceTypeCode',
    searchColumn: 'ReferenceType',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_FULL_REFERENCE',
    propertyName: 'fullReference',
    searchColumn: 'FullReference',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_SHORT_REFERENCE',
    propertyName: 'shortReference',
    searchColumn: 'ShortReference',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_ARTICLE_TITLE',
    propertyName: 'articleTitle',
    searchColumn: 'ArticleTitle',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_AUTHORS',
    propertyName: 'authors',
    searchColumn: 'Authors',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_PUBLICATION_YEAR',
    propertyName: 'publicationYear',
    searchColumn: 'PublicationYear',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_FROM_YEAR_TO',
    propertyName: 'fromYearTo',
    searchColumn: 'FromYearTo',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_MAGAZINE',
    propertyName: 'magazine',
    searchColumn: 'Magazine',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_BOOK',
    propertyName: 'book',
    searchColumn: 'Book',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_ISSN',
    propertyName: 'issn',
    searchColumn: 'ISSN',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_ISBN',
    propertyName: 'isbn',
    searchColumn: 'ISBN',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_DOI',
    propertyName: 'doi',
    searchColumn: 'Doi',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_CONGRESS',
    propertyName: 'congress',
    searchColumn: 'Congress',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_PUBLISHER',
    propertyName: 'publisher',
    searchColumn: 'Publisher',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_EDITOR',
    propertyName: 'editor',
    searchColumn: 'Editor',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_URL',
    propertyName: 'url',
    searchColumn: 'URL',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_DOWNLOADED_ON',
    propertyName: 'downloadedOn',
    searchColumn: 'DownloadedOn',
    searchDataType: DataType.Date,
    searchDataSubType: DataSubType.DateN,
    shown: true,
    isID: false,
    preselected: true,
    noFilter: true,
  },
];

export const bookSpeciesColumns: IDataTableColumns[] = [
  {
    header: 'BOOK_REFERENCE_ID',
    propertyName: 'referenceId',
    searchColumn: 'ReferenceId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
    noFilter: true,
  },
  {
    header: 'BOOK_SPECIES_ID',
    propertyName: 'speciesId',
    searchColumn: 'SpeciesId',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'BOOK_SPECIES_ID_OTHER',
    propertyName: 'speciesIdOther',
    searchColumn: 'SpeciesIdOther',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_HABITAT_ID',
    propertyName: 'habitatId',
    searchColumn: 'HabitatId',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_SYNTAXA',
    propertyName: 'syntaxa',
    searchColumn: 'Syntaxa',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.DoubleN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_REGIONAL_UNIT',
    propertyName: 'regionalUnit',
    searchColumn: 'RegionalUnit',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'BOOK_REGION',
    propertyName: 'region',
    searchColumn: 'Region',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_LOCATION',
    propertyName: 'location',
    searchColumn: 'Location',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_SAMPLING_METHOD',
    propertyName: 'samplingMethod',
    searchColumn: 'SamplingMethod',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_METHOD_RELIABILITY',
    propertyName: 'methodReliability',
    searchColumn: 'MethodReliability',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_SOURCE_RELIABILITY',
    propertyName: 'sourceReliability',
    searchColumn: 'SourceReliability',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_POSITION_ACCURACY',
    propertyName: 'positionAccuracy',
    searchColumn: 'PositionAccuracy',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Υψηλή', name: 'Υψηλή' },
      { id: 'Μέτρια', name: 'Μέτρια' },
      { id: 'Χαμηλή', name: 'Χαμηλή' },
    ],
  },
  {
    header: 'BOOK_FLORISTIC_REGION',
    propertyName: 'floristicRegion',
    searchColumn: 'FloristicRegion',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_SEA_REGION',
    propertyName: 'seaRegion',
    searchColumn: 'SeaRegion',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_WATER_BODY',
    propertyName: 'waterBody',
    searchColumn: 'WaterBody',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_RELEVE_CODE',
    propertyName: 'releveCode',
    searchColumn: 'ReleveCode',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_CONSERVATION_STATUS',
    propertyName: 'conservationStatus',
    searchColumn: 'ConservationStatus',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_ABUDANCE',
    propertyName: 'abundance',
    searchColumn: 'Abundance',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_NUMBER_OF_PEOPLE',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_MIN_NUMBER_OF_PEOPLE',
    propertyName: 'minNumberOfPeople',
    searchColumn: 'MinNumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_MAX_NUMBER_OF_PEOPLE',
    propertyName: 'maxNumberOfPeople',
    searchColumn: 'MaxNumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_NUMBER_OF_PAIRS',
    propertyName: 'numberOfPairs',
    searchColumn: 'NumberOfPairs',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_MEASUREMENT_UNIT',
    propertyName: 'measurementUnit',
    searchColumn: 'MeasurementUnit',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Number of individuals', name: 'Number of individuals' },
      { id: 'Number of adults', name: 'Number of adults' },
      { id: 'Number of subadults', name: 'Number of subadults' },
      { id: 'Number of breeding females', name: 'Number of breeding females' },
      { id: 'Number of calling males', name: 'Number of calling males' },
      { id: 'Number of males', name: 'Number of males' },
      { id: 'Number of pairs', name: 'Number of pairs' },
      { id: 'Number of shoots', name: 'Number of shoots' },
      { id: 'Number of tufts', name: 'Number of tufts' },
      { id: 'Number of flowering stems', name: 'Number of flowering stems' },
    ],
  },
  {
    header: 'BOOK_POPULATION_INDICATOR',
    propertyName: 'populationIndicator',
    searchColumn: 'PopulationIndicator',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'number of localities', name: 'number of localities' },
      { id: 'number of colonies', name: 'number of colonies' },
      { id: 'number of inhabited logs', name: 'number of inhabited logs' },
      { id: 'number of inhabited trees', name: 'number of inhabited trees' },
      {
        id: 'number of inhabited stones/boulders',
        name: 'number of inhabited stones/boulders',
      },
      {
        id: 'length of inhabited feature in km',
        name: 'length of inhabited feature in km',
      },
      { id: 'area coverd by population', name: 'area coverd by population' },
    ],
  },
];

export const sampleBirdColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'DURATION',
    propertyName: 'duration',
    searchColumn: 'Duration',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_METHOD',
    propertyName: 'method',
    searchColumn: 'Method',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      {
        id: 0,
        name: '(0) - Καταγραφή με τη μέθοδο των σταθμών σημειακής καταγραφής από εποπτικά σημεία',
      },
      {
        id: 1,
        name: "(1) - Καταγραφή με τη μέθοδο της εξ' αποστάσεως καταγραφής από γραμμικές διαδρομές με αυτοκίνητο",
      },
      {
        id: 2,
        name: '(2) - Καταγραφή με τη μέθοδο των σταθμών σημειακής καταγραφής και την αναπαραγωγή ηχητικών αρχείων',
      },
      {
        id: 3,
        name: "(3) - Καταγραφή με τη μέθοδο των σταθμών σημειακής καταγραφής με άμεση παρατήρηση και εξ' ακοής",
      },
      {
        id: 4,
        name: '(4) - Καταγραφή με τη μέθοδο των γραμμικών διαδρομών με διαβηματισμό',
      },
      {
        id: 5,
        name: "(5) - Καταγραφή με τη μέθοδο της εξ' αποστάσεως καταγραφής από γραμμικές διαδρομές με διαβηματισμό",
      },
      {
        id: 6,
        name: '(6 - Καταγραφή με τη μέθοδο της άμεσης καταμέτρησης από σταθμούς σημειακής καταγραφής',
      },
      {
        id: 7,
        name: '(7) - Καταγραφή με τη μέθοδο της καταγραφής από γραμμικές διαδρομές με διαβηματισμό',
      },
      {
        id: 8,
        name: '(8) - Καταγραφή με τη μέθοδο του θαλάσσιου περίπλου ακτογραμμών με σκάφος',
      },
      {
        id: 9,
        name: '(9) - Καταγραφή με τη μέθοδο των θαλάσσιων διαδρομών ανοιχτής θαλάσσης',
      },
      {
        id: 10,
        name: '(10) - Συλλογή υλικού για εφαρμογή γενετικής ανάλυσης με την μη παρεμβατική μέθοδο της συλλογής βιολογικού υλικού',
      },
      { id: 11, name: '(11) - Τυχαίες καταγραφές' },
    ],
  },
  {
    header: 'HABITAT',
    propertyName: 'habitat',
    searchColumn: 'Habitat',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_PRIMARY_VEGETATION_TYPE',
    propertyName: 'primaryVegetationType',
    searchColumn: 'PrimaryVegetationType',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_VEGETATION_HEIGHT',
    propertyName: 'vegetationHeight',
    searchColumn: 'VegetationHeight',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 0, name: '(0) - Γυμνό (0-1εκ.)' },
      { id: 1, name: '(1) - Χαμηλή (1-15εκ.)' },
      { id: 2, name: '(2) - Μετρίου Ύψους (15-50εκ.)' },
      { id: 3, name: '(3) - Θάμνοι και χαμηλά δέντρα (50εκ-2μ)' },
      { id: 4, name: '(4) - Δέντρα (2-5μ)' },
      { id: 5, name: '(5) - Ψηλά Δέντρα (>5μ)' },
    ],
  },
  {
    header: 'SEARCH_BIRD_COVERAGE',
    propertyName: 'coverage',
    searchColumn: 'Coverage',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_DENSITY',
    propertyName: 'density',
    searchColumn: 'Density',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 0, name: '(0) - Υψηλή' },
      { id: 1, name: '(1) - Μέτρια' },
      { id: 2, name: '(2) - Χαμηλή' },
    ],
  },
  {
    header: 'SEARCH_BIRD_QUALITY',
    propertyName: 'quality',
    searchColumn: 'Quality',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 0, name: '(0) - Π. Κακή' },
      { id: 1, name: '(1) - Κακή' },
      { id: 2, name: '(2) - Μέτρια' },
      { id: 3, name: '(3) - Καλή' },
      { id: 4, name: '(4) - Άριστη' },
    ],
  },
  {
    header: 'SEARCH_BIRD_ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_ALTITUDE_END',
    propertyName: 'altitudet',
    searchColumn: 'Altitudet',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_TEMPERATURE',
    propertyName: 'temperature',
    searchColumn: 'Temperature',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'CLOUD_COVER',
    propertyName: 'cloudCover',
    searchColumn: 'CloudCover',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 0, name: '(0) - <20%' },
      { id: 1, name: '(1) - 20-40%' },
      { id: 2, name: '(2) - 40-60%' },
      { id: 3, name: '(3) - 60-80%' },
      { id: 4, name: '(4) - >80%' },
    ],
  },
  {
    header: 'WIND_DIRECTION',
    propertyName: 'windDirection',
    searchColumn: 'WindDirection',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 0, name: '(0) - Β' },
      { id: 1, name: '(1) - ΒΑ' },
      { id: 2, name: '(2) - Α' },
      { id: 3, name: '(3) - ΝΑ' },
      { id: 4, name: '(4) - Ν' },
      { id: 5, name: '(5) - ΝΔ' },
      { id: 6, name: '(6) - Δ' },
      { id: 7, name: '(7) - ΒΔ' },
      { id: 8, name: '(8) - Χωρίς Άνεμο' },
    ],
  },
  {
    header: 'WIND_DIRECTION_AZIMUTH',
    propertyName: 'windDirectionAzimuth',
    searchColumn: 'WindDirectionAzimuth',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_WIND_INTENSITY',
    propertyName: 'windIntensity',
    searchColumn: 'WindIntensity',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleBirdSpeciesColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'SEARCH_BIRD_TIME',
    propertyName: 'time',
    searchColumn: 'Time',
    searchDataType: DataType.Time,
    searchDataSubType: DataSubType.TimeN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_SPECIES_NAME',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'SEARCH_BIRD_GENDER',
    propertyName: 'gender',
    searchColumn: 'Gender',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Αρσενικό', name: 'Αρσενικό' },
      { id: 'Θηλυκό', name: 'Θηλυκό' },
    ],
  },
  {
    header: 'SEARCH_BIRD_AGE',
    propertyName: 'age',
    searchColumn: 'Age',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'ενήλικο', name: 'ενήλικο' },
      { id: 'ανήλικο', name: 'ανήλικο' },
    ],
  },
  {
    header: 'SEARCH_BIRD_NUMBER_OF_PEOPLE',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES_OBSERVATIONS',
    propertyName: 'observations',
    searchColumn: 'Observations',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_PAR_I',
    propertyName: 'pari',
    searchColumn: 'pari',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_PAR_II',
    propertyName: 'parii',
    searchColumn: 'parii',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_PAR_III',
    propertyName: 'pariii',
    searchColumn: 'pariii',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SEARCH_BIRD_ATTITUDE',
    propertyName: 'attitude',
    searchColumn: 'Attitude',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'ΠΤ', name: 'ΠΤ - Ανεμοπορία πτήση' },
      { id: 'ΚΝ', name: 'ΚΝ - Κούρνια' },
      { id: 'ΤΡ', name: 'ΤΡ - Αναζήτηση - εύρεση τροφής & τροφοληψία' },
      { id: 'ΧΡ', name: 'ΧΡ - Ορισμός - προάστιση χωροκράτειας' },
      { id: 'ΖΕ', name: 'ΖΕ - Δημιουργία ζευγαριών' },
      { id: 'ΦΛ', name: 'ΦΛ - Εντοπισμός θέσεων φωλεοποίησης' },
      { id: 'ΑΥ', name: 'ΑΥ - Ωοθεσία και επώαση αυγών' },
      { id: 'ΤΡμ', name: 'ΤΡμ - Μεταφορά τροφής στα μικρά' },
    ],
  },
  {
    header: 'SEARCH_BIRD_OBSERVATION_TYPE',
    propertyName: 'observationType',
    searchColumn: 'ObservationType',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    data: [
      { id: 'Flush', name: 'Flush' },
      {
        id: 'Τυπικός εντοπισμός ή Fly over για το πρωτόκολλο σημειακής καταγραφής στρουθιόμορφων',
        name: 'Τυπικός εντοπισμός ή Fly over για το πρωτόκολλο σημειακής καταγραφής στρουθιόμορφων',
      },
    ],
  },
];

export const sampleBirdThreatsColumns: IDataTableColumns[] = [
  {
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noFilter: true,
    noSort: true,
  },
  {
    header: 'CODE_OF_SPECIES_THREATS',
    propertyName: 'pressuresAndThreatsId',
    searchColumn: 'PressuresAndThreatsId',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: false,
  },
  {
    header: 'SEARCH_BIRD_THREAT_SPECIES_NAME',
    propertyName: 'speciesId',
    searchColumn: 'SpeciesId',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    preselected: true,
    isID: false,
    noSort: true,
  },
  {
    header: 'SEARCH_BIRD_THREAT_TYPE',
    propertyName: 'type',
    searchColumn: 'Type',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      { id: 'P(πίεση)', name: 'P(πίεση)' },
      { id: 'T(απειλή)', name: 'T(απειλή)' },
      { id: 'P or T(και τα δύο)', name: 'P or T(και τα δύο)' },
    ],
  },
  {
    header: 'SEARCH_BIRD_THREAT_IMPORTANCE',
    propertyName: 'importance',
    searchColumn: 'Importance',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'L',
        name: 'L',
      },
      {
        id: 'M',
        name: 'M',
      },
      {
        id: 'H',
        name: 'H',
      },
    ],
  },
  {
    header: 'SEARCH_BIRD_THREAT_TYPE_OF_THREAT',
    propertyName: 'typeOfThreat',
    searchColumn: 'TypeOfThreat',
    searchDataType: DataType.List,
    shown: true,
    preselected: true,
    isID: false,
    data: [
      {
        id: 'i',
        name: 'i',
      },
      {
        id: 'o',
        name: 'o',
      },
      {
        id: 'b',
        name: 'b',
      },
    ],
  },
];

export const sampleInvertebrateColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'DURATION',
    propertyName: 'duration',
    searchColumn: 'Duration',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'METHOD_INVERTEBRATES',
    propertyName: 'method',
    searchColumn: 'Method',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HABITAT',
    propertyName: 'habitat',
    searchColumn: 'Habitat',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'VEGETATION_TYPE',
    propertyName: 'vegetationType',
    searchColumn: 'VegetationType',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'BOOK_ALTITUDE',
    propertyName: 'altitude',
    searchColumn: 'Altitude',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TEMPERATURE_INVERTEBRATES',
    propertyName: 'temperature',
    searchColumn: 'Temperature',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'CROSS_SECTION_LENGTH_INVERTEBRATES',
    propertyName: 'crossSectionLength',
    searchColumn: 'CrossSectionLength',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.FloatN,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'TOPOGRAPHY',
    propertyName: 'topography',
    searchColumn: 'Topography',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'HUMIDITY_INVERTEBRATES',
    propertyName: 'humidity',
    searchColumn: 'Humidity',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'WEATHER_CONDITIONS',
    propertyName: 'weatherConditions',
    searchColumn: 'WeatherConditions',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleInvertebrateSpeciesColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'SPECIES_NAME',
    propertyName: 'species',
    searchColumn: 'Species',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'CROSS_SECTION_PART_INVERTEBRATES',
    propertyName: 'crossSectionPart',
    searchColumn: 'CrossSectionPart',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'NUMBER_OF_PEOPLE_INVERTEBRATES',
    propertyName: 'numberOfPeople',
    searchColumn: 'NumberOfPeople',
    searchDataType: DataType.Numerical,
    searchDataSubType: DataSubType.Int64N,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RELATIVE_ABUNDANCE_CATEGORY_INVERTEBRATES',
    propertyName: 'relativeAbundanceCategory',
    searchColumn: 'RelativeAbundanceCategory',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'RELATIVE_ABUNDANCE_STATUS_INVERTEBRATES',
    propertyName: 'relativeAbundanceStatus',
    searchColumn: 'RelativeAbundanceStatus',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES_OBSERVATIONS',
    propertyName: 'observation',
    searchColumn: 'Observation',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'PARII_INVERTEBRATES',
    propertyName: 'parii',
    searchColumn: 'parii',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },

  {
    header: 'PARIV_INVERTEBRATES',
    propertyName: 'pariv',
    searchColumn: 'pariv',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },

  {
    header: 'PARV_INVERTEBRATES',
    propertyName: 'parv',
    searchColumn: 'parv',
    searchDataType: DataType.Boolean,
    searchDataSubType: DataSubType.BooleanN,
    shown: true,
    isID: false,
    preselected: true,
  },
];

export const sampleInvertebrateThreatsColumns: IDataTableColumns[] = [
  {
    noFilter: true,
    header: 'SAMPLE_CODE',
    propertyName: 'samplingCode',
    searchColumn: 'SamplingCode',
    searchDataType: DataType.String,
    shown: true,
    preselected: true,
    isID: true,
    noSort: true,
  },
  {
    header: 'CODE_OF_SPECIES_THREATS',
    propertyName: 'pressuresAndThreatsId',
    searchColumn: 'PressuresAndThreatsId',
    searchDataType: DataType.String,
    shown: true,
    isID: false,
    preselected: true,
  },
  {
    header: 'SPECIES_CODE_THREAT',
    propertyName: 'speciesId',
    searchColumn: 'SpeciesId',
    searchDataType: DataType.List,
    searchDataSubType: DataSubType.Int32N,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
  },
  {
    header: 'THREAT_TYPE_INVERTEBRATES',
    propertyName: 'type',
    searchColumn: 'Type',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
    data: [
      {
        id: 'P(πίεση)',
        name: 'P(πίεση)',
      },
      {
        id: 'T(απειλή)',
        name: 'T(απειλή)',
      },
      {
        id: 'P or T(και τα δύο)',
        name: 'P or T(και τα δύο)',
      },
    ],
  },
  {
    header: 'THREAT_IMPORTANCE_INVERTEBRATES',
    propertyName: 'importance',
    searchColumn: 'Importance',
    searchDataType: DataType.List,
    shown: true,
    isID: false,
    preselected: true,
    noSort: true,
    data: [
      {
        id: 'L',
        name: 'L (Low)',
        matchMode: 'startsWith',
      },
      {
        id: 'M',
        name: 'M (Medium)',
        matchMode: 'startsWith',
      },
      {
        id: 'H',
        name: 'H (High)',
        matchMode: 'startsWith',
      },
    ],
  },
];
