<div class="modal-container">
  <div class="title">
    {{ "CONFIRM_MODAL_TITLE" | translate }}
  </div>
  <div class="description">
    {{
      bibliographyReportWithoutSpeciesOrHabitats
        ? ("CONFIRM_MODAL_BIBLIOGRAPHY_WITHOUT_SPECIES_HABITATS_DESCRIPTION"
          | translate)
        : ("CONFIRM_MODAL_DESCRIPTION" | translate)
    }}
  </div>
  <div class="btn">
    <app-button
      class="completion-btn"
      (buttonClicked)="activeModal.close(true)"
      [btnText]="'CONFIRM_MODAL_YES'"
      [style.--btnBgColor]="'#e8108e'"
      [style.--btnWidth]="'200px'"
      [style.margin-right]="'16px'"
    ></app-button>

    <app-button
      class="completion-btn"
      (buttonClicked)="activeModal.close(false)"
      [btnText]="'CONFIRM_MODAL_NO'"
      [style.--btnBgColor]="'var(--color-secondary)'"
      [style.--btnWidth]="'200px'"
    ></app-button>
  </div>
</div>
