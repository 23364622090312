import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ISeaPinnaRequestDto } from 'src/app/core/dtos/sea.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { SeaService } from '../../services/sea.service';

@Component({
  selector: 'app-sea-pinna-nobilis',
  templateUrl: './sea-pinna-nobilis.component.html',
  styleUrls: ['./sea-pinna-nobilis.component.scss'],
})
export class SeaPinnaNobilisComponent implements OnChanges {
  @Input() public pinna!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public pinnaById: ISeaPinnaRequestDto[] = [];
  @Input() public disabledControls = false;
  @Input() public sampleId?: number;

  public crossSectionPartDropdown: IDropdownItem[] = [
    { id: '00-05', name: '00-05' },
    { id: '05-10', name: '05-10' },
    { id: '10-15', name: '10-15' },
    { id: '15-20', name: '15-20' },
    { id: '20-25', name: '20-25' },
    { id: '25-30', name: '25-30' },
    { id: '30-35', name: '30-35' },
    { id: '35-40', name: '35-40' },
    { id: '40-45', name: '40-45' },
    { id: '45-50', name: '45-50' },
    { id: '50-55', name: '50-55' },
    { id: '55-60', name: '55-60' },
    { id: '60-65', name: '60-65' },
    { id: '65-70', name: '65-70' },
    { id: '70-75', name: '70-75' },
    { id: '75-80', name: '75-80' },
    { id: '80-85', name: '80-85' },
    { id: '85-90', name: '85-90' },
    { id: '90-95', name: '90-95' },
    { id: '95-100', name: '95-100' },
    { id: '100-105', name: '100-105' },
    { id: '105-110', name: '105-110' },
    { id: '110-115', name: '110-115' },
    { id: '115-120', name: '115-120' },
    { id: '120-125', name: '120-125' },
    { id: '125-130', name: '125-130' },
    { id: '130-135', name: '130-135' },
    { id: '135-140', name: '135-140' },
    { id: '140-145', name: '140-145' },
    { id: '145-150', name: '145-150' },
    { id: '150-155', name: '150-155' },
    { id: '155-160', name: '155-160' },
    { id: '160-165', name: '160-165' },
    { id: '165-170', name: '165-170' },
    { id: '170-175', name: '170-175' },
    { id: '175-180', name: '175-180' },
    { id: '180-185', name: '180-185' },
    { id: '185-190', name: '185-190' },
    { id: '190-195', name: '190-195' },
    { id: '195-200', name: '195-200' },
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private seaService: SeaService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pinnaById'] && this.sampleId) {
      this.pinnaById.forEach(() => {
        this.addNewPinna();
      });
      this.pinna.patchValue(this.pinnaById);
      this.pinna.markAsTouched();
      this.pinnaById = [];
      if (this.disabledControls === true) {
        this.pinna.disable();
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.pinna.controls[index] as FormGroup;
  }

  public addNewPinna(): void {
    this.pinna.push(this.seaService.initPinnaFormGroup(this.fb));
  }

  public removePinna(index: number): void {
    this.pinna.removeAt(index);
  }
}
