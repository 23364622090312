import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import {
  IDataTableColumns,
  sampleBirdColumns,
  sampleBirdSpeciesColumns,
  sampleBirdThreatsColumns,
  sampleColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  IBirdDto,
  ISampleBirdSpeciesDto,
  ISampleBirdThreatsDto,
} from 'src/app/core/dtos/bird.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import {
  birdConfig,
  BirdFormGroup,
} from 'src/app/samplings/config-files/bird-config';
import { BirdService } from 'src/app/samplings/services/bird.service';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { utils, writeFile } from 'xlsx';
import { SearchService } from '../services/search.service';
import { TableWrapperComponent } from '../table-wrapper/table-wrapper.component';
import {
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import { ITableFilterRequest } from '../table/interfaces/table.interfaces';
import { catchError, forkJoin } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-bird-search',
  templateUrl: './bird-search.component.html',
  styleUrls: ['./bird-search.component.scss'],
})
export class BirdSearchComponent extends TableWrapperComponent {
  public sampleBirdTotalRecords: number = 0;
  public sampleBird: IBirdDto[] = [];
  public sampleBirdColumns: IDataTableColumns[] = [];
  public sampleBirdFirst = 0;

  public speciesTotalRecords: number = 0;
  public species: ISampleBirdSpeciesDto[] = [];
  public speciesColumns: IDataTableColumns[] = [];
  public speciesFirst = 0;

  public threatsTotalRecords: number = 0;
  public threats: ISampleBirdThreatsDto[] = [];
  public threatsColumns: IDataTableColumns[] = [];
  public threatsFirst = 0;

  protected override payload: ITableFilterRequest = {
    samples: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    sampleBird: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    species: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    threats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    locationCircle: null,
  };
  private tablesInitialized: number = 0;

  public activeTabId: number = 0;
  public multiTabGroup = [
    { id: 0, name: 'SEARCH_SAMPLES', isChecked: true },
    { id: 1, name: 'SEARCH_SAMPLES_BIRD', isChecked: true },
    { id: 2, name: 'SEARCH_SPECIES', isChecked: true },
    { id: 3, name: 'SEARCH_THREATS', isChecked: true },
  ];

  constructor(
    protected override searchService: SearchService,
    protected override protocolService: ProtocolsService,
    protected override fb: FormBuilder,
    protected override translate: TranslateService,
    private birdService: BirdService,
    private modalService: NgbModal,
    protected override toastService: ToastService
  ) {
    super(searchService, protocolService, fb, translate, toastService);
  }

  ngOnInit(): void {
    this.fetchSampleBirdColumns();
    this.fetchSampleColumns();
    this.fetchSpeciesColumns();
    this.fetchThreatsColumns();

    this.watchDataversion();
  }

  protected override fetchSampleColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.sampleColumns = sampleColumns;
    const sampleColumnsTemp = [...sampleColumns];
    sampleColumnsTemp.forEach((column) => {
      if (
        column.searchDataType === DataType.List &&
        (column.data == null || column.data.length === 0)
      ) {
        if (column.propertyName === 'prefecture') {
          this.protocolService.getPrefectures().subscribe((data) => {
            column.data = data.map((prefecture) => {
              return { id: prefecture.id, name: prefecture.prefecture };
            });
            this.sampleColumns = sampleColumnsTemp;
          });
        }
      }

      if (column.header === 'RANDOM_OBSERVATION') {
        column.shown = false;
      }

      if (column.header === 'SAM_ID') {
        column.shown = false;
      }
    });
    this.sampleColumns = sampleColumnsTemp;
  }

  protected override fetchSpeciesColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.speciesColumns = sampleBirdSpeciesColumns;
    const speciesColumnsTemp = [...sampleBirdSpeciesColumns];
    speciesColumnsTemp.forEach((column) => {
      if (column.searchDataType === DataType.List) {
        if (column.propertyName === 'species') {
          this.searchService
            .getSpecies(Protocols.Bird, this.version)
            .subscribe((species) => {
              column.data = species.map((item) => {
                return {
                  id: item.id,
                  name: `(${Protocols[item.protocol].toString()}) - ${
                    item.name
                  }`,
                };
              });
              this.speciesColumns = speciesColumnsTemp;
            });
        }
      }
    });
  }

  protected override fetchThreatsColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.threatsColumns = sampleBirdThreatsColumns;
    const threatsColumnsTemp = [...sampleBirdThreatsColumns];
    threatsColumnsTemp.forEach((column) => {
      if (column.searchDataType === DataType.List) {
        if (column.propertyName === 'speciesId') {
          this.searchService
            .getSpecies(Protocols.Bird, this.version)
            .subscribe((species) => {
              column.data = species.map((item) => {
                return {
                  id: item.id,
                  name: `(${Protocols[item.protocol].toString()}) - ${
                    item.name
                  }`,
                };
              });
              this.threatsColumns = threatsColumnsTemp;
            });
        }
      }
    });
  }

  private fetchSampleBirdColumns(): void {
    this.sampleBirdColumns = sampleBirdColumns;
    forkJoin([
      this.protocolService.getHabitatSpecies(this.version),
      this.protocolService.getCorines(this.version),
    ]).subscribe(([habitat, corines]) => {
      if (habitat != null) {
        const habitatItem = this.sampleBirdColumns.find(
          (item) => item.propertyName === 'habitat'
        );
        if (habitatItem != null) {
          habitatItem.data = habitat.map((item) => {
            return {
              id: item.code,
              name: `(${item.code}) - ${item.species}`,
            };
          });
        }
      }

      if (corines != null) {
        const corinesItem = this.sampleBirdColumns.find(
          (item) => item.propertyName === 'primaryVegetationType'
        );
        if (corinesItem != null) {
          corinesItem.data = corines.map((item) => {
            return {
              id: item.code,
              name: `(${item.code}) - ${item.description}`,
            };
          });
        }
      }
    });
  }

  protected override watchDataversion(): void {
    this.subscription.add(
      this.form.controls['dataVersion'].valueChanges.subscribe((version) => {
        // Updating tables.
        const dataVersion = this.payload.samples?.items.find(
          (item) => item.name === 'DataVersion'
        );
        if (dataVersion != null) {
          if (dataVersion.rules != null && dataVersion.rules.length > 0) {
            dataVersion.rules[0].value = version;
          } else {
            dataVersion.rules = [
              {
                value: version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ];
          }
        } else {
          this.payload.samples?.items.push({
            name: 'DataVersion',
            dataType: DataType.String,
            rules: [
              {
                value: this.version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ],
          });
        }

        this.fetchSampleBirdColumns();
        this.fetchSampleColumns();
        this.fetchSpeciesColumns();
        this.fetchThreatsColumns();

        this.fetchData();
      })
    );
  }

  protected override fetchData(): void {
    this.searchService.filterBird(this.payload).subscribe((response) => {
      if (response != null) {
        if (response.samples != null) {
          this.sampleTotalRecords = response.samples.totalCount;

          // TODO: Uncomment this when we resolve the time filtering issue.
          // response.samples.rows.forEach(item => {
          //   if (item.time != null) {
          //     const timeString = item.time.toString().slice(0, 5);
          //     item.time = timeString;
          //   }
          // });

          this.samples = response.samples.rows;
        }

        if (response.sampleBird != null) {
          this.sampleBirdTotalRecords = response.sampleBird.totalCount;
          this.sampleBird = response.sampleBird.rows;
          this.sampleCoordinates = this.sampleBird
            .filter((item) => item.lat != null && item.long != null)
            .map((item) => {
              return {
                lat: item.lat,
                lng: item.long,
                text: item.samplingCode,
              };
            });
        }

        if (response.speciesBird != null) {
          this.speciesTotalRecords = response.speciesBird.totalCount;
          this.species = response.speciesBird.rows;
        }

        if (response.threatsBird != null) {
          this.threatsTotalRecords = response.threatsBird.totalCount;
          this.threats = response.threatsBird.rows;
        }
      }
    });
  }

  public loadSampleBirdData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 4) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(event, sampleBirdColumns);
    this.payload.sampleBird = tableFilter;

    if (event.first) {
      this.sampleBirdFirst = event.first;
    }

    this.fetchData();
  }

  public loadSpeciesData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 4) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleBirdSpeciesColumns
    );
    this.payload.species = tableFilter;

    if (event.first) {
      this.speciesFirst = event.first;
    }

    this.fetchData();
  }

  public loadThreatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 4) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleBirdThreatsColumns
    );
    this.payload.threats = tableFilter;

    if (event.first) {
      this.threatsFirst = event.first;
    }

    this.fetchData();
  }

  protected override initializePaginator() {
    this.sampleFirst = 0;
    this.sampleBirdFirst = 0;
    this.speciesFirst = 0;
    this.threatsFirst = 0;
  }

  public openExportModal() {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ExportModalComponent, {
      centered: true,
      modalDialogClass: 'export-modal',
      backdrop: 'static',
      keyboard: true,
    });

    if (modalRef) {
      modalRef.result.then((res) => {
        if (res != null) {
          this.exportFiles(res.xlsxFile, res.zipFile);
        }
      });
    }
  }

  public exportFiles(xlsxFile = false, zipFile = false) {
    let payload = { ...this.payload };
    if (payload.samples) {
      payload.samples.rows = this.sampleTotalRecords;
      payload.samples.first = 0;
    }
    if (payload.sampleBird) {
      payload.sampleBird.rows = this.sampleBirdTotalRecords;
      payload.sampleBird.first = 0;
    }
    if (payload.species) {
      payload.species.rows = this.speciesTotalRecords;
      payload.species.first = 0;
    }
    if (payload.threats) {
      payload.threats.rows = this.threatsTotalRecords;
      payload.threats.first = 0;
    }

    let samples: any[] = [];
    let speciesBird: any[] = [];
    let threatsBird: any[] = [];

    this.searchService.filterBird(payload).subscribe((response) => {
      const date = new Date();
      const filename =
        'EP2_Export_Bird_' +
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      if (xlsxFile) {
        if (response && response.sampleBird) {
          samples = this.buildExportArray(
            response,
            'sampleBird',
            true,
            birdConfig,
            ''
          );
        }

        if (response && response.speciesBird) {
          speciesBird = this.buildExportArray(
            response,
            'speciesBird',
            false,
            birdConfig,
            BirdFormGroup.Species
          );
        }

        if (response && response.threatsBird) {
          threatsBird = this.buildExportArray(
            response,
            'threatsBird',
            false,
            birdConfig,
            BirdFormGroup.Threats
          );
        }

        const workbook = utils.book_new();

        const wsSamples = utils.aoa_to_sheet(samples);
        wsSamples['!cols'] = this.fixExportArrayColumnsWidth(samples);
        wsSamples['!rows'] = [{ hpt: 50 }];

        const wsSpeciesBird = utils.aoa_to_sheet(speciesBird);
        wsSpeciesBird['!cols'] = this.fixExportArrayColumnsWidth(speciesBird);
        wsSpeciesBird['!rows'] = [{ hpt: 50 }];

        const wsThreatsBird = utils.aoa_to_sheet(threatsBird);
        wsThreatsBird['!cols'] = this.fixExportArrayColumnsWidth(threatsBird);
        wsThreatsBird['!rows'] = [{ hpt: 50 }];

        utils.book_append_sheet(
          workbook,
          wsSamples,
          this.translate.instant('SPREADSHEET_BIRD_SAMPLES')
        );
        utils.book_append_sheet(
          workbook,
          wsSpeciesBird,
          this.translate.instant('SPREADSHEET_BIRD_SPECIES')
        );
        utils.book_append_sheet(
          workbook,
          wsThreatsBird,
          this.translate.instant('SPREADSHEET_BIRD_THREATS')
        );

        const date = new Date();
        writeFile(workbook, filename + '.xlsx', { compression: true });
      }

      if (zipFile) {
        this.getZip(response, filename);
      }
    });
  }
}
