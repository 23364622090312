import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IProtocolsDto } from 'src/app/core/dtos/protocols.dto';
import {
  URL_SEA_HABITATS,
  URL_AMPHIBIAN,
  URL_BIRD,
  URL_CHLORIS,
  URL_FISH,
  URL_HABITATS,
  URL_INVERTEBRATES,
  URL_MAMMAL,
  URL_SEA_SPECIES,
  URL_BIBLIOGRAPHY,
  URL_SEARCH,
} from 'src/app/URLS';
import { ProtocolsService } from '../../services/protocols.service';
import * as jwtDecode from 'jwt-decode';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AppPermissions } from './enumerations/permission.enumeration';

export interface IFormsSelection {
  id: number;
  url: string;
  permission: string;
}

@Component({
  selector: 'app-protocols',
  templateUrl: './protocols.component.html',
  styleUrls: ['./protocols.component.scss'],
})
export class ProtocolsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public protocols: IProtocolsDto[] = [
    {
      id: 1,
      description: 'HABITATS',
    },
    {
      id: 2,
      description: 'MAMMALS',
    },
    {
      id: 3,
      description: 'CHLORIS',
    },
    {
      id: 4,
      description: 'AMPHIBIAN',
    },
    {
      id: 5,
      description: 'FISH',
    },
    {
      id: 6,
      description: 'SEA',
    },
    {
      id: 7,
      description: 'INVERTEBRATES',
    },
    {
      id: 8,
      description: 'BIRD',
    },
    {
      id: 9,
      description: 'SEA_HABITATS',
    },
  ];
  public menuBtnList: IFormsSelection[] = [
    {
      id: 0,
      url: URL_HABITATS,
      permission: AppPermissions.HABITAT,
    },
    {
      id: 1,
      url: URL_MAMMAL,
      permission: AppPermissions.MAMMAL,
    },
    {
      id: 2,
      url: URL_CHLORIS,
      permission: AppPermissions.CHLORIS,
    },
    {
      id: 3,
      url: URL_AMPHIBIAN,
      permission: AppPermissions.AMPHIBIAN,
    },
    {
      id: 4,
      url: URL_FISH,
      permission: AppPermissions.FISH,
    },
    {
      id: 5,
      url: URL_SEA_SPECIES,
      permission: AppPermissions.SEA,
    },
    {
      id: 6,
      url: URL_INVERTEBRATES,
      permission: AppPermissions.INVERTEBRATE,
    },
    {
      id: 7,
      url: URL_BIRD,
      permission: AppPermissions.BIRD,
    },
    {
      id: 8,
      url: URL_SEA_HABITATS,
      permission: AppPermissions.SEA,
    },
  ];
  public readonly AppPermissions: typeof AppPermissions = AppPermissions;
  public permissions: { [permission: string]: boolean } = {};
  public noItems: boolean = false;

  constructor(
    private protocolsService: ProtocolsService,
    public router: Router,
    private authService: AuthService
  ) {}

  /**
   * NgOnInit hook
   */
  ngOnInit(): void {
    // this.protocolsService
    //   .getProtocols()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((res) => {
    //     if (res != undefined) {
    //       this.protocols = res as IProtocolsDto[];
    //       this.protocols.forEach((protocol) => {
    //         protocol.description = this.fixUppercase(protocol.description);
    //       });
    //       // TODO Fix bibliography when we have the call
    //       this.bibliography = this.protocols;
    //     }
    //   });

    const token = this.authService.jwtToken$.getValue();
    if (token != null) {
      const decoded: { permissions: string[] } = jwtDecode.default(token);
      if (decoded.permissions.length > 0) {
        decoded.permissions.forEach((permission) => {
          this.permissions[permission] = true;
        });
      }
      this.noItems = !(Object.keys(this.permissions).length > 0);
    } else {
      this.noItems = !(Object.keys(this.permissions).length > 0);
    }
  }

  /**
   * Fixes the uppercase transformation
   *
   * @param {string} str The string that will be fixed
   * @returns {string} The fixed string
   */
  private fixUppercase(str: string | undefined): string | undefined {
    if (str) {
      str = str.replaceAll('ί', 'ι');
      str = str.replaceAll('ϊ', 'ι');
      str = str.replaceAll('ΐ', 'ι');
      str = str.replaceAll('Ί', 'ι');
      str = str.replaceAll('Ϊ', 'ι');
      str = str.replaceAll('ή', 'η');
      str = str.replaceAll('Ή', 'η');
      str = str.replaceAll('ύ', 'υ');
      str = str.replaceAll('Ύ', 'υ');
      str = str.replaceAll('ϋ', 'υ');
      str = str.replaceAll('Ϋ', 'υ');
      str = str.replaceAll('ό', 'ο');
      str = str.replaceAll('Ό', 'ο');
      str = str.replaceAll('ώ', 'ω');
      str = str.replaceAll('Ώ', 'ω');
      str = str.replaceAll('ά', 'α');
      str = str.replaceAll('Ά', 'α');
      str = str.replaceAll('έ', 'ε');
      str = str.replaceAll('Έ', 'ε');
    }

    return str;
  }

  /**
   * navigation back to landing page
   */
  public goBack(): void {
    window.history.back();
  }

  /**
   * navigation to the appropriate registration form
   */
  public navigateToRegistration(url: string, id: number): void {
    this.router.navigate(['../' + url], { state: { protocolId: id } });
  }

  /**
   * navigation to a book report
   */
  public navigateToBook(): void {
    this.router.navigate(['../' + URL_BIBLIOGRAPHY], {
      state: { protocolId: 0 },
    });
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
