import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
})
export class BackComponent {
  public urlHome = '/';

  constructor(public router: Router) {}

  /**
   * navigation back to landing page
   */
  public goBack(): void {
    window.history.back();
  }
}
