import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  OperatorFunction,
  Subject,
} from 'rxjs';

export interface IDropdownItem {
  id: number | string;
  name: string;
  actualId?: number;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() formName!: string;
  @Input() formGroup!: FormGroup;
  @Input() dropdownList: IDropdownItem[] = [];
  @Input() placeholder: string = '';
  @Input() label?: string;
  @Input() preDefinedId?: number | string;
  @Input() public required = false;

  @Output() valueChanged = new EventEmitter<string | number>();
  @Output() valueChangedExtraData = new EventEmitter<string>();
  @Output() valueChangedExtraDataName = new EventEmitter<string>();

  public selectedValue?: IDropdownItem;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['preDefinedId']) {
      let selectedValue = this.dropdownList.filter((item) => {
        return (
          (item.actualId != null
            ? item.actualId.toString()
            : item.id.toString()) === this.preDefinedId?.toString()
        );
      });
      // Search by name.
      if (selectedValue == null || selectedValue.length === 0) {
        selectedValue = this.dropdownList.filter((item) => {
          return (
            item.name === this.preDefinedId?.toString() &&
            this.preDefinedId?.toString() !== ''
          );
        });
      }
      if (selectedValue && selectedValue.length > 0) {
        this.selectedValue = selectedValue[0];
        // this.preDefinedId = undefined;
      }
    }

    if (changes['dropdownList']) {
      this.selectedValue = undefined;
      let selectedValue = this.dropdownList.filter((item) => {
        return (
          (item.actualId != null
            ? item.actualId.toString()
            : item.id.toString()) === this.preDefinedId?.toString()
        );
      });
      // Search by name.
      if (selectedValue == null || selectedValue.length === 0) {
        selectedValue = this.dropdownList.filter((item) => {
          return (
            item.name === this.preDefinedId?.toString() &&
            this.preDefinedId?.toString() !== ''
          );
        });
      }
      if (selectedValue && selectedValue.length > 0) {
        this.selectedValue = selectedValue[0];
        // this.preDefinedId = undefined;
      }
    }
  }

  /**
   * Function that is called when user focuses out of the input
   *
   */
  public onBlur() {
    this.formGroup.controls[this.formName].markAsTouched();
  }

  /**
   * When model changes value
   *
   * @param {any} changedValue The changed value
   */
  onModelChanging(changedValue: any) {
    if (!!changedValue) {
      if (this.selectedValue?.actualId != null) {
        this.formGroup.controls[this.formName].patchValue(
          this.selectedValue?.actualId
        );
        this.valueChanged.emit(this.selectedValue?.actualId);
        this.valueChangedExtraData.emit(this.selectedValue?.id.toString());
      } else {
        this.formGroup.controls[this.formName].patchValue(
          this.selectedValue?.id
        );
        this.valueChanged.emit(this.selectedValue?.id);
      }
      this.valueChangedExtraDataName.emit(this.selectedValue?.name.toString());
      this.formGroup.controls[this.formName].markAsTouched();
    } else {
      this.formGroup.controls[this.formName].patchValue('');
      this.valueChanged.emit('');
      this.formGroup.controls[this.formName].markAsTouched();
    }
  }

  public getSelectedValue(): string | null {
    const option = this.dropdownList.find(
      (item) => item.id === this.formGroup.controls[this.formName].value
    );
    return option != null ? option.name : null;
  }

  public get disabled(): boolean {
    return this.formGroup.controls[this.formName].disabled;
  }

  public get hasErrors(): boolean {
    return this.formGroup.controls[this.formName].errors != null;
  }

  public get errors(): ValidationErrors | null {
    return this.formGroup.controls[this.formName].errors;
  }

  public get touched(): boolean {
    return this.formGroup.controls[this.formName].touched;
  }
}
