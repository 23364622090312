import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  /**
   *
   * @param route
   * @param state
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Observable<boolean>(observable => {
      this.authService.authenticated$.subscribe((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigate(['/']);
        }
        observable.next(isAuthenticated);
      });
    });
  }
}
