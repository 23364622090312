import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FilterMatchMode, LazyLoadEvent, SelectItem } from 'primeng/api';
import { IDataTableColumns } from 'src/app/core/consts/data-table-columns';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MammalsComponent } from 'src/app/samplings/components/mammals/mammals.component';
import { AmphibiansComponent } from 'src/app/samplings/components/amphibians/amphibians.component';
import { BirdsComponent } from 'src/app/samplings/components/birds/birds.component';
import { ChlorisComponent } from 'src/app/samplings/components/chloris/chloris.component';
import { FishComponent } from 'src/app/samplings/components/fish/fish.component';
import { HabitatComponent } from 'src/app/samplings/components/habitat/habitat.component';
import { InvertebratesComponent } from 'src/app/samplings/components/invertebrates/invertebrates.component';
import { SeaComponent } from 'src/app/samplings/components/sea/sea.component';
import { SeaHabitatComponent } from 'src/app/samplings/components/sea-habitat/sea-habitat.component';
import { FormSuccessModalComponent } from '../form-success-modal/form-success-modal.component';
import { SearchService } from '../services/search.service';
import { TableRecordsType } from './interfaces/table.interfaces';
import { BookHabitatsSpeciesComponent } from 'src/app/samplings/components/book-habitats-species/book-habitats-species.component';
import { DataType } from 'src/app/shared/table/enumerations/table.enumerations';
import { Table } from 'primeng/table';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() public records: TableRecordsType = [];
  @Input() public totalRecords: number = 0;
  @Input() public columns: IDataTableColumns[] = [];
  @Input() public isReference = false;
  @Input() public first = 0;

  @Output() public onLoadData = new EventEmitter<LazyLoadEvent>();
  @Output() public onRecordChange = new EventEmitter<boolean>();
  public cols: IDataTableColumns[] = [];
  public allCols: string[] = [];
  public _selectedColumns: IDataTableColumns[] = [];
  public recordId?: IDataTableColumns;
  public protocols = Protocols;
  public searchDataType = DataType;

  public stringMatchModeOptions: SelectItem[] = [
    { label: 'Αρχίζει με', value: FilterMatchMode.STARTS_WITH },
    { label: 'Περιέχει', value: FilterMatchMode.CONTAINS },
    { label: 'Τελειώνε σε', value: FilterMatchMode.ENDS_WITH },
    { label: 'Ίσο με', value: FilterMatchMode.EQUALS },
    { label: 'Όχι ίσο με', value: FilterMatchMode.NOT_EQUALS },
  ];

  public numberMatchModeOptions: SelectItem[] = [
    { label: 'Ίσο με', value: FilterMatchMode.EQUALS },
    { label: 'Όχι ίσο με', value: FilterMatchMode.NOT_EQUALS },
    { label: 'Μεγαλύτερο από', value: FilterMatchMode.GREATER_THAN },
    {
      label: 'Μεγαλύτερο από ή ίσο',
      value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    },
    { label: 'Μικρότερο από', value: FilterMatchMode.LESS_THAN },
    {
      label: 'Μικρότερο από ή ίσο',
      value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
    },
  ];

  public dateMatchModeOptions: SelectItem[] = [
    { label: 'Ίση με', value: FilterMatchMode.DATE_IS },
  ];

  public timeMatchModeOptions: SelectItem[] = [
    { label: 'Ίσο με', value: FilterMatchMode.EQUALS },
  ];

  @ViewChild('dt1', { static: true }) pTableRef!: Table;

  constructor(
    private modalService: NgbModal,
    private searchService: SearchService,
    public toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.cols = this.columns.filter((col) => {
      return col.shown;
    });
    this.allCols = this.columns.map((col) => col.propertyName);
    this._selectedColumns = this.cols.filter((col) => {
      return col.preselected;
    });
    this.recordId = this.columns.find((col) => {
      return col.isID;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['first'] != null) {
      this.pTableRef._first = changes['first'].currentValue;
    }
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }

  public loadData(event: LazyLoadEvent): void {
    this.onLoadData.emit(event);
  }

  /**
   * Function that uses the component's confirmClose function to determine whether to close the modal or not
   * @param modalRef the modal to try and close
   * @returns false so that the modal does not close without the checks
   */
  private closeOnEscape(modalRef: NgbModalRef | undefined) {
    modalRef?.componentInstance.confirmClose();
    return false;
  }

  public openModal(
    option: 'create' | 'read' | 'update' | 'delete',
    samplingCode: string,
    sampleId: number
  ) {
    let modalRef: NgbModalRef | undefined;

    this.searchService
      .getSampleId(samplingCode, sampleId)
      .subscribe((response) => {
        switch (response.protocol) {
          case Protocols.Mammals:
            modalRef = this.modalService.open(MammalsComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          case Protocols.Amphibian:
            modalRef = this.modalService.open(AmphibiansComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          case Protocols.Bird:
            modalRef = this.modalService.open(BirdsComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          case Protocols.Chloris:
            modalRef = this.modalService.open(ChlorisComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          case Protocols.Fish:
            modalRef = this.modalService.open(FishComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          case Protocols.Habitats:
            modalRef = this.modalService.open(HabitatComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          case Protocols.Invertebrates:
            modalRef = this.modalService.open(InvertebratesComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          case Protocols.Sea:
            modalRef = this.modalService.open(SeaComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          case Protocols.SeaHabitat:
            modalRef = this.modalService.open(SeaHabitatComponent, {
              centered: true,
              modalDialogClass: 'new-record-modal',
              keyboard: true,
              backdrop: 'static',
              beforeDismiss: () => this.closeOnEscape(modalRef),
            });
            if (option === 'read') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = true;
            }
            if (option === 'update') {
              modalRef.componentInstance.sampleId = response.sampleId;
              modalRef.componentInstance.readOnlyForm = false;
            }
            break;

          default:
            if (this.isReference) {
              modalRef = this.modalService.open(BookHabitatsSpeciesComponent, {
                centered: true,
                modalDialogClass: 'new-record-modal',
                keyboard: true,
                backdrop: 'static',
                beforeDismiss: () => this.closeOnEscape(modalRef),
              });
              if (option === 'read') {
                modalRef.componentInstance.referenceId = samplingCode;
                modalRef.componentInstance.readOnlyForm = true;
              }
              if (option === 'update') {
                modalRef.componentInstance.referenceId = samplingCode;
                modalRef.componentInstance.readOnlyForm = false;
              }
            }

            break;
        }

        if (modalRef) {
          modalRef.componentInstance.protocolId = this.isReference
            ? 0
            : response.protocol;

          modalRef.result.then((data) => {
            if (data != null) {
              if (option === 'update' || option === 'create') {
                this.onRecordChange.emit(true);
              }
              const successModalRef = this.modalService.open(
                FormSuccessModalComponent,
                {
                  centered: true,
                  modalDialogClass: 'success-form-modal',
                }
              );

              successModalRef.componentInstance.param = data;
              successModalRef.componentInstance.reference = this.isReference;
              if (option === 'update') {
                successModalRef.componentInstance.successfullEdit = true;
              }

              successModalRef.result.then(() => {});
            }
          });
        } else {
          this.toastService.show('FAILED_TO_OPEN_RECORD_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
        }
      });
  }
}
