import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
export enum SeaFormGroup {
  Species = 'species',
  Pinna = 'pinna',
  Threats = 'threats',
}
export enum SeaSpeciesCategory {
  Cetaceans = 'Κητώδη',
  Seals = 'Φώκιες',
  SeaTurtles = 'Θαλάσσιες Χελώνες',
  SeaInvertebrates = 'Θαλάσσια Ασπόνδυλα',
}
export const seaConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    speciesCategory: 'Ομάδα Ειδών',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
    ],
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 5,
      },
      {
        header: 'Ώρα Δειγματοληψίας',
        type: 'time',
        formName: 'time',
        order: 6,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 10,
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
        order: 12,
      },
      {
        header: 'Τοποθεσία',
        type: 'string',
        formName: 'locality',
        order: 15,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 16,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
        order: 17,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 24,
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Ομάδα Ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 2,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 3,
      },
      {
        header: 'Έργο εποπτείας / Φάση έργου',
        type: 'string',
        formName: 'ergoEpoptiaFasiErgou',
        order: 4,
      },
      {
        header: 'Χρονική διάρκεια',
        type: 'duration',
        formName: 'duration',
        order: 7,
      },
      {
        header: 'Μονάδα μέτρησης χρόνου ',
        type: 'string',
        formName: 'timeMeasurementUnit',
        order: 8,
      },
      {
        header: 'Χρονική διάρκεια on effort',
        type: 'duration',
        formName: 'durationOnEffort',
        order: 9,
      },
      {
        header: 'Συντάκτης',
        type: 'string',
        formName: 'author',
        order: 11,
      },
      {
        header: 'Μέθοδος Δειγματοληψίας',
        type: 'string',
        formName: 'method',
        order: 13,
      },
      {
        header: 'Τύπος οικοτόπου',
        type: 'string',
        formName: 'habType',
        order: 14,
      },
      {
        header: 'Shapefile',
        type: 'string',
        formName: 'shapefile',
        order: 18,
      },
      {
        header: 'Χερσαίο ενδιαίτημα Φώκιας',
        type: 'string',
        formName: 'terrestrialHabitatSeal',
        order: 19,
      },
      {
        header: 'Μήκος τομής (m)',
        type: 'int',
        formName: 'transectLenght',
        order: 20,
      },
      {
        header: 'Διεύθυνση τομής (deg)',
        type: 'string',
        formName: 'transectHeading',
        order: 21,
      },
      {
        header: 'Ελάχιστο βάθος (m)',
        type: 'int',
        formName: 'minDepth',
        order: 22,
      },
      {
        header: 'Μέγιστο βάθος (m)',
        type: 'int',
        formName: 'maxDepth',
        order: 23,
      },
      {
        header: 'Πιέσεις Ενδιαιτήματος',
        type: 'string',
        formName: 'habitatThreats',
        order: 25,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: SeaFormGroup.Species,
    speciesCategory: 'Ομάδα Ειδών',
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    validateLocation: [
      [
        'Γεωγραφικό Πλάτος Παρατήρησης (WGS84)',
        'Γεωγραφικό Μήκος Παρατήρησης (WGS84)',
        false,
      ],
    ],
    configuration: [
      {
        header: 'Ομάδα Ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 1,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 2,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 3,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 4,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 5,
        disabled: true,
      },
      {
        header: 'Obs_ID',
        type: 'string',
        formName: 'obsId',
        order: 6,
      },
      {
        header: 'Ημερομηνία Παρατήρησης',
        type: 'date',
        formName: 'observationDateCetaceans',
        order: 7,
      },
      {
        header: 'Γεωγραφικό Πλάτος Παρατήρησης (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 8,
      },
      {
        header: 'Γεωγραφικό Μήκος Παρατήρησης (WGS84)',
        type: 'float',
        formName: 'long',
        order: 9,
      },
      {
        header: 'Βάθος είδους',
        type: 'float',
        formName: 'depth',
        order: 10,
      },
      {
        header: 'Εύρος βάθους',
        type: 'string',
        formName: 'depthRange',
        order: 11,
      },
      {
        header: 'Ηλικία είδους',
        type: 'string',
        formName: 'age',
        order: 12,
      },
      {
        header: 'Αριθμός νεογέννητων ατόμων κητωδών',
        type: 'int',
        formName: 'numberOfNewbornCetaceans',
        order: 13,
      },
      {
        header: 'Αριθμός μικρών ατόμων κητωδών',
        type: 'int',
        formName: 'numberOfVeryYoungCetaceans',
        order: 14,
      },
      {
        header: 'Αριθμός νεαρών ατόμων κητωδών',
        type: 'int',
        formName: 'numberOfYoungCetaceans',
        order: 15,
      },
      {
        header: 'Αριθμός υποενήλικων ατόμων κητωδών',
        type: 'int',
        formName: 'numberOfSubAdultCetaceans',
        order: 16,
      },
      {
        header: 'Αριθμός Ενήλικων ατόμων κητωδών',
        type: 'int',
        formName: 'numberOfAdultCetaceans',
        order: 17,
      },
      {
        header: 'Αριθμός αρσενικών ατόμων κητωδών',
        type: 'int',
        formName: 'numberOfMaleCetaceans',
        order: 18,
      },
      {
        header: 'Αριθμός θηλυκών ατόμων κητωδών',
        type: 'int',
        formName: 'numberOfFemaleCetaceans',
        order: 19,
      },
      {
        header: 'Αριθμός ατόμων αγνώστου φύλου κητωδών',
        type: 'int',
        formName: 'numberOfUndefinedGenderCetaceans',
        order: 20,
      },
      {
        header: 'Φύλο είδους',
        type: 'string',
        formName: 'gender',
        order: 21,
      },
      {
        header: 'Αριθμός ατόμων είδους',
        type: 'int',
        formName: 'numberOfPeople',
        order: 22,
      },
      {
        header: 'Αριθμός ατόμων ασπονδύλων',
        type: 'int',
        formName: 'numberOfPeopleSeaInvertebrates',
        order: 23,
      },
      {
        header: 'Αριθμός Νεκρών ατόμων ασπονδύλων',
        type: 'int',
        formName: 'numberOfDeadPeople',
        order: 24,
      },
      {
        header: 'Παρουσία',
        type: 'boolean',
        formName: 'presence',
        order: 25,
      },
      {
        header: 'Ενδιαίτημα είδους',
        type: 'string',
        formName: 'habitat',
        order: 26,
      },
      {
        header: 'Βιότοπος',
        type: 'string',
        formName: 'biotope',
        order: 27,
      },
      {
        header: 'Ολικό μήκος ατόμου',
        type: 'float',
        formName: 'totalLength',
        order: 28,
      },
      {
        header: 'Ευθύ μήκος καβουκιού θαλάσσιας χελώνας (cm)',
        type: 'float',
        formName: 'lengthTurtle',
        order: 29,
      },
      {
        header: 'Μέθοδος εντοπισμού φωλιάς Θαλάσσιας χελώνας',
        type: 'string',
        formName: 'methodTurtle',
        order: 30,
      },
      {
        header: 'Εκβρασμός: Κατάσταση Νεκρού ή Τραυματισμένου ζώου',
        type: 'string',
        formName: 'deathStatus',
        order: 31,
      },
      {
        header: 'Εκβρασμός: Αιτία Τραυματισμού ή θανάτου ζώου',
        type: 'string',
        formName: 'deathReasson',
        order: 32,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 33,
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: SeaFormGroup.Pinna,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Ομάδα Ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 1,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 2,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 3,
        disabled: true,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 4,
        disabled: true,
      },
      {
        header: 'Τμήμα τομής είδους',
        type: 'string',
        formName: 'crossSectionPart',
        order: 5,
      },
      {
        header: 'Κάθετη απόσταση (cm)',
        type: 'float',
        formName: 'verticalDistance',
        order: 6,
      },
      {
        header: 'Βάθος (m)',
        type: 'float',
        formName: 'depth',
        order: 7,
      },
      {
        header: 'Μέσο Βάθος Τομής (m)',
        type: 'float',
        formName: 'averageCrossSectionDepth',
        order: 8,
      },
      {
        header: 'Χαρακτηριστικό μέγεθος (cm)',
        type: 'float',
        formName: 'typicalSize',
        order: 9,
      },
      {
        header: 'Βιότοπος',
        type: 'string',
        formName: 'biotope',
        order: 10,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 11,
      },
    ],
  },
  // This is the 4rd sheet
  {
    formGroupName: SeaFormGroup.Threats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Ομάδα Ειδών',
        type: 'string',
        formName: 'speciesCategory',
        order: 1,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 2,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 3,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 4,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 5,
        disabled: true,
      },
      {
        header: 'Κωδικός πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsId',
        order: 6,
      },
      {
        header: 'Όνομα είδους απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsDescription',
        order: 7,
        disabled: true,
      },
      {
        header: 'Επίδραση / Ένταση',
        type: 'string',
        formName: 'impact',
        order: 8,
      },
      {
        header: 'Χρονικός Προσδιορισμός',
        type: 'string',
        formName: 'timeCharacter',
        order: 9,
      },
      {
        header: 'Παρατηρήσεις / Σχόλια',
        type: 'string',
        formName: 'observations',
        order: 10,
      },
    ],
  },
];
