<ng-container [formGroup]="formGroup">
  <div formArrayName="threats" class="form-container form-2">
    <ng-container
      *ngFor="let threatsForm of threats.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'pressuresAndThreatsId'"
          [label]="'PRESSURE_THREAT'"
          [dropdownList]="threatsDropdown"
          [required]="true"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'speciesId'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'type'"
          [label]="'TYPE'"
          [dropdownList]="typeDropdown"
          [required]="true"
        ></app-dropdown>
      </div>
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'importance'"
          [label]="'IMPORTANCE'"
          [dropdownList]="importanceDropdown"
          [required]="true"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'timeCharacter'"
          [label]="'TIME_CHARACTER'"
          [dropdownList]="timeCharacterDropdown"
          [required]="true"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'impact'"
          [label]="'IMPACT'"
          [dropdownList]="impactDropdown"
          [required]="true"
        ></app-dropdown>
        <app-button
          (buttonClicked)="removeThreats(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
        ></app-button>
      </div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_THREAT'"
      (buttonClicked)="addNewThreats()"
    ></app-button>
  </div>
</ng-container>
