<ng-container [formGroup]="formGroup">
  <div formArrayName="threats" class="form-container form-2">
    <ng-container
      *ngFor="let threatsForm of threats.controls; let index = index"
    >
      <div class="form-row form-3">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'otherPressuresAndThreats'"
          [label]="'FISH_OTHER_PRESSURES'"
          [required]="true"
        ></app-input>
        <app-button
          (buttonClicked)="removeThreats(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== threats.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_THREAT'"
      (buttonClicked)="addNewThreats()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
