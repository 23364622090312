import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { CommonService } from 'src/app/core/services/common.service';
import { AmphibianFormGroup } from '../config-files/amphibian-config';
import { BibliographyFormGroup } from '../config-files/bibliography-config';
import { BirdFormGroup } from '../config-files/bird-config';
import { ChlorisFormGroup } from '../config-files/chloris-config';
import { FishFormGroup } from '../config-files/fish-config';
import { HabitatFormGroup } from '../config-files/habitat-config';
import { InvertebrateFormGroup } from '../config-files/invertebrate-config';
import { MammalsFormGroup } from '../config-files/mammals-config';
import { SeaFormGroup } from '../config-files/sea-config';
import { SeaHabitatFormGroup } from '../config-files/sea-habitat-config';
import { AmphibiansService } from './amphibians.service';
import { BirdService } from './bird.service';
import { BookHabitatsSpeciesService } from './book-habitats-species.service';
import { ChlorisService } from './chloris.service';
import { FishService } from './fish.service';
import { HabitatService } from './habitat.service';
import { InvertebrateService } from './invertebrate.service';
import { MammalsService } from './mammals.service';
import { SeaService } from './sea.service';

@Injectable({
  providedIn: 'root',
})
export class SpreadsheetFormsService {
  constructor(
    private fb: FormBuilder,
    private mammalsService: MammalsService,
    private amphibiansService: AmphibiansService,
    private fishService: FishService,
    private habitatService: HabitatService,
    private invertebrateService: InvertebrateService,
    private seaService: SeaService,
    private birdService: BirdService,
    private chlorisService: ChlorisService,
    public bookService: BookHabitatsSpeciesService,
    private commonService: CommonService
  ) {}

  public InitFormGroup(formGroup: string, protocolId: number): FormGroup<any> {
    let spreadsheetForm!: FormGroup<any>;
    switch (protocolId) {
      case Protocols.Bibliography:
        if (formGroup === '') {
          spreadsheetForm = this.bookService.initBibliographyFormGroup(this.fb);
        } else if (formGroup === BibliographyFormGroup.Entries) {
          spreadsheetForm = this.bookService.initEntriesFormGroup(this.fb);
        }
        break;

      case Protocols.Amphibian:
        if (formGroup === '') {
          spreadsheetForm = this.amphibiansService.initAmphibianFormGroup(
            this.fb
          );
        } else if (formGroup === AmphibianFormGroup.Habitats) {
          spreadsheetForm = this.amphibiansService.initHabitatsFormGroup(
            this.fb
          );
        } else if (formGroup === AmphibianFormGroup.Species) {
          spreadsheetForm = this.amphibiansService.initSpeciesFormGroup(
            this.fb
          );
        } else if (formGroup === AmphibianFormGroup.SpeciesThreats) {
          spreadsheetForm = this.amphibiansService.initSpeciesThreatsFormGroup(
            this.fb
          );
        }
        break;

      case Protocols.Bird:
        if (formGroup === '') {
          spreadsheetForm = this.birdService.initBirdForm(this.fb);
        } else if (formGroup === BirdFormGroup.Species) {
          spreadsheetForm = this.birdService.initSpeciesFormGroup(this.fb);
        } else if (formGroup === BirdFormGroup.Threats) {
          spreadsheetForm = this.birdService.initThreatsFormGroup(this.fb);
        }
        break;

      case Protocols.Chloris:
        if (formGroup === '') {
          spreadsheetForm = this.chlorisService.initChlorisFormGroup(this.fb);
        } else if (formGroup === ChlorisFormGroup.Functions) {
          spreadsheetForm = this.chlorisService.initFunctionsFormGroup(this.fb);
        } else if (formGroup === ChlorisFormGroup.Pressures) {
          spreadsheetForm = this.chlorisService.initThreatsFormGroup(this.fb);
        } else if (formGroup === ChlorisFormGroup.Populations) {
          spreadsheetForm = this.chlorisService.initPopulationsFormGroup(
            this.fb
          );
        }
        break;

      case Protocols.Fish:
        if (formGroup === '') {
          spreadsheetForm = this.fishService.initFishFormGroup(this.fb);
        } else if (formGroup === FishFormGroup.Species) {
          spreadsheetForm = this.fishService.initSpeciesFormGroup(this.fb);
        } else if (formGroup === FishFormGroup.Threats) {
          spreadsheetForm = this.fishService.initThreatsFormGroup(this.fb);
        } else if (formGroup === FishFormGroup.OtherThreats) {
          spreadsheetForm = this.fishService.initOtherThreatsFormGroup(this.fb);
        }
        break;

      case Protocols.Habitats:
        if (formGroup === '') {
          spreadsheetForm = this.habitatService.initHabitatFormGroup(this.fb);
        } else if (formGroup === HabitatFormGroup.Species) {
          spreadsheetForm = this.habitatService.initSpeciesFormGroup(this.fb);
        } else if (formGroup === HabitatFormGroup.Threats) {
          spreadsheetForm = this.habitatService.initThreatsFormGroup(this.fb);
        } else if (formGroup === HabitatFormGroup.Impacts) {
          spreadsheetForm = this.habitatService.initImpactFormGroup(this.fb);
        } else if (formGroup === HabitatFormGroup.Structs) {
          spreadsheetForm = this.habitatService.initStructFormGroup(this.fb);
        }
        break;

      case Protocols.Invertebrates:
        if (formGroup === '') {
          spreadsheetForm = this.invertebrateService.initInvertebrateForm(
            this.fb
          );
        } else if (formGroup === InvertebrateFormGroup.Species) {
          spreadsheetForm = this.invertebrateService.initSpeciesFormGroup(
            this.fb
          );
        } else if (formGroup === InvertebrateFormGroup.Threats) {
          spreadsheetForm = this.invertebrateService.initThreatsFormGroup(
            this.fb
          );
        }
        break;

      case Protocols.Mammals:
        if (formGroup === '') {
          spreadsheetForm = this.mammalsService.initMammalFormGroup(this.fb);
        } else if (formGroup === MammalsFormGroup.Species) {
          spreadsheetForm = this.mammalsService.initSpeciesFormGroup(this.fb);
        } else if (formGroup === MammalsFormGroup.Threats) {
          spreadsheetForm = this.mammalsService.initThreatsFormGroup(this.fb);
        } else if (formGroup === MammalsFormGroup.Habitats) {
          spreadsheetForm = this.mammalsService.initHabitatsFormGroup(this.fb);
        }
        break;

      case Protocols.Sea:
        if (formGroup === '') {
          spreadsheetForm = this.seaService.initSeaFormGroup(this.fb);
        } else if (formGroup === SeaFormGroup.Species) {
          spreadsheetForm = this.seaService.initSpeciesFormGroup(this.fb);
        } else if (formGroup === SeaFormGroup.Pinna) {
          spreadsheetForm = this.seaService.initPinnaFormGroup(this.fb);
        } else if (formGroup === SeaFormGroup.Threats) {
          spreadsheetForm = this.seaService.initThreatsFormGroup(this.fb);
        }
        break;

      case Protocols.SeaHabitat:
        if (formGroup === '') {
          spreadsheetForm = this.seaService.initSeaHabitatFormGroup(this.fb);
        } else if (formGroup === SeaHabitatFormGroup.Species) {
          spreadsheetForm = this.seaService.initSeaHabitatSpeciesFormGroup(
            this.fb
          );
        } else if (formGroup === SeaHabitatFormGroup.Threats) {
          spreadsheetForm = this.seaService.initSeaHabitatThreatsFormGroup(
            this.fb
          );
        } else if (formGroup === SeaHabitatFormGroup.A) {
          spreadsheetForm = this.seaService.initSeaHabitatStructAFormGroup(
            this.fb
          );
        } else if (formGroup === SeaHabitatFormGroup.B) {
          spreadsheetForm = this.seaService.initSeaHabitatStructBFormGroup(
            this.fb
          );
        } else if (formGroup === SeaHabitatFormGroup.C) {
          spreadsheetForm = this.seaService.initSeaHabitatStructCFormGroup(
            this.fb
          );
        } else if (formGroup === SeaHabitatFormGroup.Struct1170) {
          spreadsheetForm = this.seaService.initSeaHabitatStruct1170FormGroup(
            this.fb
          );
        }
        break;

      default:
        break;
    }
    return spreadsheetForm;
  }
}
