<ng-container [formGroup]="formGroup">
  <div class="form-container form-3">
    <div class="form-row">
      <app-dropdown
        [formGroup]="formGroup"
        [formName]="'unit'"
        [label]="'POPULATION_MEASURE_UNIT'"
        [dropdownList]="unitsDropdown"
        [required]="true"
        [preDefinedId]="formGroup.controls['unit'].value"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="formGroup"
        [formName]="'strat'"
        [label]="'POPULATION_STRATIFICATION'"
        [dropdownList]="stratDropdown"
        [required]="true"
        [preDefinedId]="formGroup.controls['strat'].value"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="formGroup"
        [formName]="'method'"
        [label]="'POPULATION_MEASURE_METHOD'"
        [dropdownList]="methodDropdown"
        [required]="true"
        [preDefinedId]="formGroup.controls['method'].value"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="formGroup"
        [formName]="'populationObservations'"
        [label]="'POPULATION_OBSERVATIONS'"
      ></app-textarea>
    </div>
  </div>
  <div formArrayName="populations" class="form-container form-1">
    <ng-container
      *ngFor="let populationsForm of populations.controls; let index = index"
    >
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'aApolygon'"
          [label]="'AA_POLYGON'"
          [required]="true"
        ></app-input>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'samplingSurface'"
          [label]="'SAMPLING_SURFACE'"
          [ngClass]="{
            disabled:
              getGroup(index).controls['samplingSurface'].disabled && !sampleId
          }"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'adult_A'"
          [label]="'ADULT_A'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'adult_K1'"
          [label]="'ADULT_K1'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'adult_K2'"
          [label]="'ADULT_K2'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'young_A'"
          [label]="'YOUNG_A'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'young_K2'"
          [label]="'YOUNG_K2'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'veget_A'"
          [label]="'VEGET_A'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'veget_K2'"
          [label]="'VEGET_K2'"
          [required]="true"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'populationArea'"
          [label]="'POPULATION_AREA'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'clusterMin'"
          [label]="'CLUSTER_MIN'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'clusterMax'"
          [label]="'CLUSTER_MAX'"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'height'"
          [label]="'POPULATION_HEIGHT'"
          [required]="true"
        ></app-input>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'vit'"
          [label]="'POPULATION_VITALITY'"
          [dropdownList]="vitDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['vit'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'sex'"
          [label]="'POPULATION_GENDER'"
          [dropdownList]="sexDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['sex'].value"
        ></app-dropdown>
      </div>
      <div class="form-row">
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'structure'"
          [label]="'POPULATION_STRUCTURE'"
          [required]="true"
        ></app-input>
      </div>
      <app-textarea
        [style.max-width]="'100%'"
        [formGroup]="getGroup(index)"
        [formName]="'comment'"
        [label]="'POPULATION_COMMENTS'"
      ></app-textarea>
      <div class="form-row">
        <app-button
          (buttonClicked)="removePopulations(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== populations.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_MEASUREMENT'"
      (buttonClicked)="addNewPopulations()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
