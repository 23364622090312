<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>

<div class="title-container">
  <span class="title-text uppercase">{{ "NEW_REPORT" | translate }}</span>
</div>
<div class="bookHS-container">
  <div class="info-container">
    <span class="info-text uppercase">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container" style="grid-template-rows: 1fr 1fr">
    <div class="form-row">
      <app-dropdown
        [formGroup]="bookHSForm"
        [formName]="'ergoEpoptiaFasiErgou'"
        [label]="'ERGO_EPOPTIA_FASI_ERGOU'"
        [dropdownList]="ergoEpoptiaFasiErgouDropdown"
        [required]="true"
        [preDefinedId]="bookHSById?.ergoEpoptiaFasiErgou"
      ></app-dropdown>
      <app-dropdown
        [formGroup]="bookHSForm"
        [formName]="'speciesCategory'"
        [label]="'BOOK_SPECIES_CATEGORY'"
        [dropdownList]="speciesCategoryDropdown"
        [required]="true"
        [preDefinedId]="bookHSById?.speciesCategory"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-input
        [formGroup]="bookHSForm"
        [formName]="'referenceId'"
        [label]="'BOOK_REFERENCE_ID'"
      ></app-input>

      <app-dropdown
        [formGroup]="bookHSForm"
        [formName]="'referenceTypeCode'"
        [label]="'BOOK_REFERENCE_TYPE'"
        [dropdownList]="referenceTypes"
        [required]="true"
        [preDefinedId]="bookHSById?.referenceTypeCode"
      ></app-dropdown>
    </div>

    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="bookHSForm"
      [formName]="'shortReference'"
      [label]="'BOOK_SHORT_REFERENCE'"
      [required]="true"
    ></app-textarea>
  </div>

  <div class="info-container">
    <span class="info-text uppercase">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" class="multi-tab-buttons">
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container form-2">
          <app-textarea
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'fullReference'"
            [label]="'BOOK_FULL_REFERENCE'"
            [required]="true"
          ></app-textarea>
          <app-input
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'articleTitle'"
            [label]="'BOOK_ARTICLE_TITLE'"
            [required]="true"
          >
          </app-input>
          <app-input
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'authors'"
            [label]="'BOOK_AUTHORS'"
          >
          </app-input>
          <div class="form-row form-3">
            <app-input
              [mask]="'0000'"
              [formGroup]="bookHSForm"
              [formName]="'publicationYear'"
              [label]="'BOOK_PUBLICATION_YEAR'"
            >
            </app-input>
            <app-input
              [mask]="'0000-0000'"
              [formGroup]="bookHSForm"
              [formName]="'fromYearTo'"
              [label]="'BOOK_FROM_YEAR_TO'"
            >
            </app-input>
          </div>
          <app-input
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'magazine'"
            [label]="'BOOK_MAGAZINE'"
          >
          </app-input>
          <app-input
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'book'"
            [label]="'BOOK_BOOK'"
          >
          </app-input>
          <div class="form-row form-3">
            <app-input
              [formGroup]="bookHSForm"
              [formName]="'issn'"
              [label]="'BOOK_ISSN'"
            >
            </app-input>
            <app-input
              [formGroup]="bookHSForm"
              [formName]="'isbn'"
              [label]="'BOOK_ISBN'"
            >
            </app-input>
            <app-input
              [formGroup]="bookHSForm"
              [formName]="'doi'"
              [label]="'BOOK_DOI'"
            >
            </app-input>
          </div>
          <app-input
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'congress'"
            [label]="'BOOK_CONGRESS'"
          >
          </app-input>
          <app-input
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'publisher'"
            [label]="'BOOK_PUBLISHER'"
          >
          </app-input>
          <app-input
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'editor'"
            [label]="'BOOK_EDITOR'"
          >
          </app-input>
          <app-input
            [style.max-width]="'100%'"
            [formGroup]="bookHSForm"
            [formName]="'url'"
            [label]="'BOOK_URL'"
          >
          </app-input>
          <div class="form-row form-3">
            <app-input
              [mask]="'00/00/0000'"
              [formGroup]="bookHSForm"
              [formName]="'downloadedOn'"
              [label]="'BOOK_DOWNLOADED_ON'"
            >
            </app-input>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[1].name }}</a
      >
      <ng-template ngbNavContent>
        <app-book-habitats-species-entry
          [entries]="entries"
          [speciesIds]="speciesIds"
          [ctSpecies]="ctSpecies"
          [formGroup]="bookHSForm"
          [habitatTypes]="habitatTypes"
          [abudance]="abudance"
          [sourceReliability]="sourceReliability"
          [methodReliability]="methodReliability"
          [prefectures]="prefectures"
          [naturaCodes]="naturaCodes"
          [gridCells]="gridCells"
          [entriesById]="entriesById"
          [disabledControls]="readOnlyForm"
          [readOnlyForm]="readOnlyForm"
          [referenceId]="referenceId"
          [regionalUnits]="regionalUnits"
        ></app-book-habitats-species-entry>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <ng-container
    *ngIf="
      !referenceId ||
      (referenceId && predefinedFiles.length > 0) ||
      (referenceId && !readOnlyForm)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    [btnText]="referenceId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    (buttonClicked)="confirmRegistration()"
    [isDisabled]="!bookHSForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
