import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent {
  public xlsxFile = false;
  public zipFile = false;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService
  ) {}

  public cancelExport() {
    this.activeModal.close();
  }

  public confirmExport() {
    this.activeModal.close({
      xlsxFile: this.xlsxFile,
      zipFile: this.zipFile,
    });
  }
}
