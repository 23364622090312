import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-form-success-modal',
  templateUrl: './form-success-modal.component.html',
  styleUrls: ['./form-success-modal.component.scss'],
})
export class FormSuccessModalComponent {
  public param = {
    sampleId: null,
    samplingCode: null,
    isRandom: false,
  };
  public reference: boolean = false;
  public successfullEdit: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}
}
