import { Component } from '@angular/core';
import { TableWrapperComponent } from '../table-wrapper/table-wrapper.component';
import { SearchService } from '../services/search.service';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { FormBuilder } from '@angular/forms';
import {
  IDataTableColumns,
  sampleColumns,
  sampleMammalColumns,
  sampleMammalHabitatsColumns,
  sampleMammalRandomSpeciesColumns,
  sampleMammalSpeciesColumns,
  sampleMammalThreatsColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import {
  IMammalsDto,
  ISampleMammalHabitatsDto,
  ISampleMammalSpeciesDto,
  ISampleMammalThreatsDto,
} from 'src/app/core/dtos/mammals.dto';
import { LazyLoadEvent } from 'primeng/api';
import { ITableFilterRequest } from '../table/interfaces/table.interfaces';
import {
  mammalsConfig,
  MammalsFormGroup,
} from 'src/app/samplings/config-files/mammals-config';
import { utils, writeFile } from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-mammal-search',
  templateUrl: './mammal-search.component.html',
  styleUrls: ['./mammal-search.component.scss'],
})
export class MammalSearchComponent extends TableWrapperComponent {
  public sampleMammalsTotalRecords: number = 0;
  public sampleMammals: IMammalsDto[] = [];
  public sampleMammalColumns: IDataTableColumns[] = [];
  public sampleMammalsFirst = 0;

  public speciesTotalRecords: number = 0;
  public species: ISampleMammalSpeciesDto[] = [];
  public speciesColumns: IDataTableColumns[] = [];
  public speciesFirst = 0;

  public randomSpeciesTotalRecords: number = 0;
  public randomSpecies: ISampleMammalSpeciesDto[] = [];
  public randomSpeciesColumns: IDataTableColumns[] = [];
  public randomSpeciesFirst = 0;

  public threatsTotalRecords: number = 0;
  public threats: ISampleMammalThreatsDto[] = [];
  public threatsColumns: IDataTableColumns[] = [];
  public threatsFirst = 0;

  public habitatsTotalRecords: number = 0;
  public habitats: ISampleMammalHabitatsDto[] = [];
  public habitatsColumns: IDataTableColumns[] = [];
  public habitatsFirst = 0;

  protected override payload: ITableFilterRequest = {
    samples: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    sampleMammals: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    species: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    speciesRandomMammals: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    threats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    habitats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    locationCircle: null,
  };
  private tablesInitialized: number = 0;

  public activeTabId: number = 0;
  public multiTabGroup = [
    { id: 0, name: 'SEARCH_SAMPLES', isChecked: true },
    { id: 1, name: 'SEARCH_SAMPLES_MAMMALS', isChecked: true },
    { id: 2, name: 'SEARCH_SPECIES', isChecked: true },
    { id: 3, name: 'RANDOM_OBSERVATION', isChecked: true },
    { id: 4, name: 'SEARCH_THREATS', isChecked: true },
    { id: 5, name: 'SEARCH_HABITATS', isChecked: true },
  ];

  constructor(
    protected override searchService: SearchService,
    protected override protocolService: ProtocolsService,
    protected override fb: FormBuilder,
    protected override translate: TranslateService,
    private modalService: NgbModal,
    protected override toastService: ToastService
  ) {
    super(searchService, protocolService, fb, translate, toastService);
  }

  ngOnInit(): void {
    this.fetchSampleMammalColumns();
    this.fetchSampleColumns();
    this.fetchSpeciesColumns();
    this.fetchRandomSpeciesColumns();
    this.fetchThreatsColumns();
    this.fetchHabitatsColumns();

    this.watchDataversion();
  }

  protected override fetchSampleColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.sampleColumns = sampleColumns;
    const sampleColumnsTemp = [...sampleColumns];
    sampleColumnsTemp.forEach((column) => {
      if (
        column.searchDataType === DataType.List &&
        (column.data == null || column.data.length === 0)
      ) {
        if (column.propertyName === 'prefecture') {
          this.protocolService.getPrefectures().subscribe((data) => {
            column.data = data.map((prefecture) => {
              return { id: prefecture.id, name: prefecture.prefecture };
            });
            this.sampleColumns = sampleColumnsTemp;
          });
        }
      }

      if (column.header === 'RANDOM_OBSERVATION') {
        column.shown = true;
      }
    });
    this.sampleColumns = sampleColumnsTemp;
  }

  protected override fetchSpeciesColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.speciesColumns = sampleMammalSpeciesColumns;
    const speciesColumnsTemp = [...sampleMammalSpeciesColumns];
    speciesColumnsTemp.forEach((column) => {
      if (column.searchDataType === DataType.List) {
        if (column.propertyName === 'species') {
          this.searchService
            .getSpecies(2, this.version)
            .subscribe((species) => {
              column.data = species.map((item) => {
                return {
                  id: item.id,
                  name: `(${Protocols[item.protocol].toString()}) - ${
                    item.name
                  }`,
                };
              });
              this.speciesColumns = speciesColumnsTemp;
            });
        }
      }
    });
  }

  private fetchRandomSpeciesColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.randomSpeciesColumns = sampleMammalRandomSpeciesColumns;
    const randomSpeciesColumnsTemp = [...sampleMammalRandomSpeciesColumns];
    randomSpeciesColumnsTemp.forEach((column) => {
      if (column.searchDataType === DataType.List) {
        if (column.propertyName === 'species') {
          this.searchService
            .getSpecies(2, this.version)
            .subscribe((species) => {
              column.data = species.map((item) => {
                return {
                  id: item.id,
                  name: `(${Protocols[item.protocol].toString()}) - ${
                    item.name
                  }`,
                };
              });
              this.randomSpeciesColumns = randomSpeciesColumnsTemp;
            });
        }
      }
    });
  }

  protected override fetchThreatsColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.threatsColumns = sampleMammalThreatsColumns;
    const threatsColumnsTemp = [...sampleMammalThreatsColumns];
    threatsColumnsTemp.forEach((column) => {
      if (column.searchDataType === DataType.List) {
        if (column.propertyName === 'speciesId') {
          this.searchService
            .getSpecies(2, this.version)
            .subscribe((species) => {
              column.data = species.map((item) => {
                return {
                  id: item.id,
                  name: `(${Protocols[item.protocol].toString()}) - ${
                    item.name
                  }`,
                };
              });
              this.threatsColumns = threatsColumnsTemp;
            });
        }
      }
    });
  }

  private fetchSampleMammalColumns(): void {
    this.sampleMammalColumns = sampleMammalColumns;
  }

  private fetchHabitatsColumns(): void {
    this.habitatsColumns = sampleMammalHabitatsColumns;
  }

  protected override watchDataversion(): void {
    this.subscription.add(
      this.form.controls['dataVersion'].valueChanges.subscribe((version) => {
        // Updating tables.
        const dataVersion = this.payload.samples?.items.find(
          (item) => item.name === 'DataVersion'
        );
        if (dataVersion != null) {
          if (dataVersion.rules != null && dataVersion.rules.length > 0) {
            dataVersion.rules[0].value = version;
          } else {
            dataVersion.rules = [
              {
                value: version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ];
          }
        } else {
          this.payload.samples?.items.push({
            name: 'DataVersion',
            dataType: DataType.String,
            rules: [
              {
                value: this.version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ],
          });
        }

        this.fetchSampleMammalColumns();
        this.fetchSampleColumns();
        this.fetchSpeciesColumns();
        this.fetchRandomSpeciesColumns();
        this.fetchThreatsColumns();
        this.fetchHabitatsColumns();

        this.fetchData();
      })
    );
  }

  protected override fetchData(): void {
    this.searchService.filterMammals(this.payload).subscribe((response) => {
      if (response != null) {
        if (response.samples != null) {
          this.sampleTotalRecords = response.samples.totalCount;

          // TODO: Uncomment this when we resolve the time filtering issue.
          // response.samples.rows.forEach(item => {
          //   if (item.time != null) {
          //     const timeString = item.time.toString().slice(0, 5);
          //     item.time = timeString;
          //   }
          // });

          this.samples = response.samples.rows;
        }

        if (response.sampleMammals != null) {
          this.sampleMammalsTotalRecords = response.sampleMammals.totalCount;
          this.sampleMammals = response.sampleMammals.rows;
          this.sampleCoordinates = this.sampleMammals
            .filter((item) => item.lat != null && item.long != null)
            .map((item) => {
              return {
                lat: item.lat,
                lng: item.long,
                text: item.samplingCode,
              };
            });
        }

        if (response.speciesMammals != null) {
          this.speciesTotalRecords = response.speciesMammals.totalCount;
          this.species = response.speciesMammals.rows;
          this.speciesCoordinates = this.species
            .filter(
              (item) =>
                item.pointX != null &&
                item.pointY != null &&
                item.pointX !== -1 &&
                item.pointY !== -1
            )
            .map((item) => {
              return {
                lat: item.pointX,
                lng: item.pointY,
                text:
                  item.species != null ? item.species.toString() : undefined,
              };
            });
        }

        if (response.randomSpeciesMammals != null) {
          this.randomSpeciesTotalRecords =
            response.randomSpeciesMammals.totalCount;
          this.randomSpecies = response.randomSpeciesMammals.rows;
          this.randomSpeciesCoordinates = this.randomSpecies
            .filter(
              (item) =>
                item.pointX != null &&
                item.pointY != null &&
                item.pointX !== -1 &&
                item.pointY !== -1
            )
            .map((item) => {
              return {
                lat: item.pointX,
                lng: item.pointY,
                text:
                  item.species != null ? item.species.toString() : undefined,
              };
            });
        }

        if (response.threatsMammals != null) {
          this.threatsTotalRecords = response.threatsMammals.totalCount;
          this.threats = response.threatsMammals.rows;
        }

        if (response.habitatsMammals != null) {
          this.habitatsTotalRecords = response.habitatsMammals.totalCount;
          this.habitats = response.habitatsMammals.rows;
        }
      }
    });
  }

  public loadSampleMammalsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(event, sampleMammalColumns);
    this.payload.sampleMammals = tableFilter;

    if (event.first) {
      this.sampleMammalsFirst = event.first;
    }

    this.fetchData();
  }

  public loadSpeciesData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleMammalSpeciesColumns
    );
    this.payload.species = tableFilter;

    if (event.first) {
      this.speciesFirst = event.first;
    }

    this.fetchData();
  }

  public loadRandomSpeciesData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleMammalRandomSpeciesColumns
    );
    this.payload.speciesRandomMammals = tableFilter;

    if (event.first) {
      this.randomSpeciesFirst = event.first;
    }

    this.fetchData();
  }

  public loadThreatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleMammalThreatsColumns
    );
    this.payload.threats = tableFilter;

    if (event.first) {
      this.threatsFirst = event.first;
    }

    this.fetchData();
  }

  public loadHabitatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 6) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleMammalHabitatsColumns
    );
    this.payload.habitats = tableFilter;

    if (event.first) {
      this.habitatsFirst = event.first;
    }

    this.fetchData();
  }

  protected override initializePaginator() {
    this.sampleFirst = 0;
    this.sampleMammalsFirst = 0;
    this.speciesFirst = 0;
    this.randomSpeciesFirst = 0;
    this.threatsFirst = 0;
    this.habitatsFirst = 0;
  }

  public openExportModal() {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ExportModalComponent, {
      centered: true,
      modalDialogClass: 'export-modal',
      backdrop: 'static',
      keyboard: true,
    });

    if (modalRef) {
      modalRef.result.then((res) => {
        if (res != null) {
          this.exportFiles(res.xlsxFile, res.zipFile);
        }
      });
    }
  }

  public exportFiles(xlsxFile = false, zipFile = false) {
    let payload = { ...this.payload };
    if (payload.samples) {
      payload.samples.rows = this.sampleTotalRecords;
      payload.samples.first = 0;
    }
    if (payload.sampleMammals) {
      payload.sampleMammals.rows = this.sampleMammalsTotalRecords;
      payload.sampleMammals.first = 0;
    }
    if (payload.habitats) {
      payload.habitats.rows = this.habitatsTotalRecords;
      payload.habitats.first = 0;
    }
    if (payload.species) {
      payload.species.rows = this.speciesTotalRecords;
      payload.species.first = 0;
    }
    if (payload.speciesRandomMammals) {
      payload.speciesRandomMammals.rows = this.randomSpeciesTotalRecords;
      payload.speciesRandomMammals.first = 0;
    }
    if (payload.threats) {
      payload.threats.rows = this.threatsTotalRecords;
      payload.threats.first = 0;
    }

    let samples: any[] = [];
    let habitatsMammals: any[] = [];
    let speciesMammals: any[] = [];
    let randomSpeciesMammals: any[] = [];
    let threatsMammals: any[] = [];

    this.searchService.filterMammals(payload).subscribe((response) => {
      const date = new Date();
      const filename =
        'EP2_Export_Mammals_' +
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      if (xlsxFile) {
        if (response && response.sampleMammals) {
          samples = this.buildExportArray(
            response,
            'sampleMammals',
            true,
            mammalsConfig,
            ''
          );
        }

        if (response && response.habitatsMammals) {
          habitatsMammals = this.buildExportArray(
            response,
            'habitatsMammals',
            false,
            mammalsConfig,
            MammalsFormGroup.Habitats
          );
        }

        if (response && response.speciesMammals) {
          speciesMammals = this.buildExportArray(
            response,
            'speciesMammals',
            false,
            mammalsConfig,
            MammalsFormGroup.Species
          );
        }

        if (response && response.randomSpeciesMammals) {
          randomSpeciesMammals = this.buildExportArray(
            response,
            'randomSpeciesMammals',
            false,
            mammalsConfig,
            MammalsFormGroup.RandomObservations,
            true
          );
        }

        if (response && response.threatsMammals) {
          threatsMammals = this.buildExportArray(
            response,
            'threatsMammals',
            false,
            mammalsConfig,
            MammalsFormGroup.Threats
          );
        }

        const workbook = utils.book_new();

        const wsSamples = utils.aoa_to_sheet(samples);
        wsSamples['!cols'] = this.fixExportArrayColumnsWidth(samples);
        wsSamples['!rows'] = [{ hpt: 50 }];

        const wsHabitatsMammals = utils.aoa_to_sheet(habitatsMammals);
        wsHabitatsMammals['!cols'] =
          this.fixExportArrayColumnsWidth(habitatsMammals);
        wsHabitatsMammals['!rows'] = [{ hpt: 50 }];

        const wsSpeciesMammals = utils.aoa_to_sheet(speciesMammals);
        wsSpeciesMammals['!cols'] =
          this.fixExportArrayColumnsWidth(speciesMammals);
        wsSpeciesMammals['!rows'] = [{ hpt: 50 }];

        const wsRandomSpeciesMammals = utils.aoa_to_sheet(randomSpeciesMammals);
        wsRandomSpeciesMammals['!cols'] =
          this.fixExportArrayColumnsWidth(randomSpeciesMammals);
        wsRandomSpeciesMammals['!rows'] = [{ hpt: 50 }];

        const wsThreatsMammals = utils.aoa_to_sheet(threatsMammals);
        wsThreatsMammals['!cols'] =
          this.fixExportArrayColumnsWidth(threatsMammals);
        wsThreatsMammals['!rows'] = [{ hpt: 50 }];

        utils.book_append_sheet(
          workbook,
          wsSamples,
          this.translate.instant('SPREADSHEET_MAMMALS_SAMPLES')
        );
        utils.book_append_sheet(
          workbook,
          wsHabitatsMammals,
          this.translate.instant('SPREADSHEET_MAMMALS_HABITATS')
        );
        utils.book_append_sheet(
          workbook,
          wsSpeciesMammals,
          this.translate.instant('SPREADSHEET_MAMMALS_SPECIES')
        );
        utils.book_append_sheet(
          workbook,
          wsRandomSpeciesMammals,
          this.translate.instant('SPREADSHEET_MAMMALS_RANDOM_SPECIES')
        );
        utils.book_append_sheet(
          workbook,
          wsThreatsMammals,
          this.translate.instant('SPREADSHEET_MAMMALS_THREATS')
        );

        const date = new Date();
        writeFile(workbook, filename + '.xlsx', { compression: true });
      }

      if (zipFile) {
        this.getZip(response, filename);
      }
    });
  }
}
