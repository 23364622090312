export interface IPrefectures {
  [key: string]: number;
}

export const Prefectures: IPrefectures = {
  'Αιτωλίας & Ακαρνανίας': 1,
  Αργολίδος: 2,
  Αρκαδίας: 3,
  Άρτης: 4,
  Αττικής: 5,
  Αχαίας: 6,
  Βοιωτίας: 7,
  Γρεβενών: 8,
  Δράμας: 9,
  Δωδεκανήσου: 10,
  Έβρου: 11,
  Ευβοίας: 12,
  Ευρυτανίας: 13,
  Ζακύνθου: 14,
  Ηλείας: 15,
  Ημαθίας: 16,
  Ηρακλείου: 17,
  Θεσπρωτίας: 18,
  Θεσσαλoνίκης: 19,
  Ιωαννίνων: 20,
  Καβάλας: 21,
  Καρδίτσης: 22,
  Καστοριάς: 23,
  Κερκύρας: 24,
  Κεφαλληνίας: 25,
  Κιλκίς: 26,
  Κοζάνης: 27,
  Κορινθίας: 28,
  Κυκλάδων: 29,
  Λακωνίας: 30,
  Λαρίσης: 31,
  Λασιθίου: 32,
  Λέσβου: 33,
  Λευκάδος: 34,
  Μαγνησίας: 35,
  Μεσσηνίας: 36,
  Ξάνθης: 37,
  Πέλλης: 38,
  Πιερίας: 39,
  Πρεβέζης: 40,
  Ρεθύμνης: 41,
  Ροδόπης: 42,
  Σάμου: 43,
  Σερρών: 44,
  Τρικάλων: 45,
  Φθιώτιδος: 46,
  Φλωρίνης: 47,
  Φωκίδος: 48,
  Χαλκιδικής: 49,
  Χανίων: 50,
  Χίου: 51,
  'Αγίου Όρους (Καρυαί)': 52,
  Άγνωστος: 99,
};
