import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ITabBtnGroup {
  id: string;
  name: string;
  isChecked: boolean;
  isDisabled?: boolean;
  data?: string;
}

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent {
  @Input() public buttonList: ITabBtnGroup[] | undefined;
  @Input() public groupName = 'customBtnRadio';
  @Output() public buttonClick = new EventEmitter<string>();

  /**
   *
   * @param {string} btnId the id of the button that is clicked
   */
  public btnClick(btnId: string) {
    this.buttonClick.emit(btnId);
  }
}
