import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'natura';

  constructor(
    public translate: TranslateService, 
    public router: Router
  ) {
    this.translate.addLangs(['GR']);
    this.translate.setDefaultLang('GR');
    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang?.match(/GR/) ? browserLang : 'GR');
  }
}
