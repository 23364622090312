import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, finalize } from 'rxjs';
import { ISeaHabitatPATRequest } from 'src/app/core/dtos/sea-habitat.dto';
import {
  ISeaHabitatDto,
  ISeaPressureAndThreatDto,
} from 'src/app/core/dtos/sea.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { SeaService } from '../../services/sea.service';

@Component({
  selector: 'app-sea-habitat-threats',
  templateUrl: './sea-habitat-threats.component.html',
  styleUrls: ['./sea-habitat-threats.component.scss'],
})
export class SeaHabitatThreatsComponent
  implements OnInit, OnDestroy, OnChanges
{
  private destroy$ = new Subject<void>();
  public ctThreats: ISeaPressureAndThreatDto[] = [];
  public threatsForm!: FormGroup;
  public threatsDropdown: IDropdownItem[] = [];
  public typeDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('TYPE_P'),
      name: this.translate.instant('TYPE_P'),
    },
    {
      id: this.translate.instant('TYPE_T'),
      name: this.translate.instant('TYPE_T'),
    },
    {
      id: this.translate.instant('TYPE_P_T'),
      name: this.translate.instant('TYPE_P_T'),
    },
  ];
  public ctImportanceDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'Low',
    },
    {
      id: 'M',
      name: 'Medium',
    },
    {
      id: 'H',
      name: 'High',
    },
  ];
  public importanceDropdown: IDropdownItem[] = [];

  public ctTimeDeterminationDropdown: IDropdownItem[] = [
    {
      id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
    },
    {
      id: 'Στο παρόν',
      name: 'Στο παρόν',
    },
    {
      id: 'Στο παρόν και στο μέλλον',
      name: 'Στο παρόν και στο μέλλον',
    },
    {
      id: 'Μόνο στο μέλλον',
      name: 'Μόνο στο μέλλον',
    },
  ];
  public timeDeterminationDropdown: IDropdownItem[] = [];

  public habitatTypes: ISeaHabitatDto[] = [];
  public habitatTypesDropdown: IDropdownItem[] = [];

  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public threatsById: ISeaHabitatPATRequest[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private seaService: SeaService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.threatsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threatsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.seaService.getThreats(this.dataVersion),
      this.seaService.getHabitats(this.dataVersion),
    ])
      .pipe(
        finalize(() => {
          this.importanceDropdown = this.ctImportanceDropdown;
          this.timeDeterminationDropdown = this.ctTimeDeterminationDropdown;
          if (this.ctThreats.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.ctThreats.forEach((threat) => {
              dropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
            this.threatsDropdown = dropdown;
          }

          if (this.habitatTypes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.habitatTypes.forEach((habitat) => {
              dropdown.push({
                id: habitat.habType,
                name: habitat.habTypeName,
                actualId: parseInt(habitat.code),
              });
            });
            this.habitatTypesDropdown = dropdown;
          }

          if (this.threatsById.length > 0) {
            this.threatsById.forEach(() => {
              this.addNewThreats();
            });
            this.threats.patchValue(this.threatsById);
            this.threats.markAsTouched();
            this.threatsById = [];
            if (this.disabledControls === true) {
              this.threats.disable();
            }
          }
          return;
        })
      )
      .subscribe(([threats, habitats]) => {
        if (threats != undefined) {
          this.ctThreats = threats as ISeaPressureAndThreatDto[];
        }

        if (habitats != undefined) {
          this.habitatTypes = habitats as ISeaHabitatDto[];
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    let fg = this.seaService.initSeaHabitatThreatsFormGroup(this.fb);
    fg.controls['pressuresAndThreatsId'].valueChanges.subscribe((value) => {
      if (value) {
        if ((value as string).startsWith('PX')) {
          fg.controls['timeDetermination'].setValue('', { emiiValue: false });
          fg.controls['timeDetermination'].disable();
          fg.controls['influenceIntensity'].setValue('', { emiiValue: false });
          fg.controls['influenceIntensity'].disable();

          this.importanceDropdown = [];
          this.timeDeterminationDropdown = [];
        } else {
          fg.controls['timeDetermination'].enable();
          fg.controls['influenceIntensity'].enable();

          this.importanceDropdown = this.ctImportanceDropdown;
          this.timeDeterminationDropdown = this.ctTimeDeterminationDropdown;
        }
      }
    });
    this.threats.push(fg);
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
