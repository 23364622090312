import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil, finalize } from 'rxjs';
import { IHabitatStruct, IStructRequest } from 'src/app/core/dtos/habitat.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { HabitatService } from '../../services/habitat.service';

@Component({
  selector: 'app-habitat-struct',
  templateUrl: './habitat-struct.component.html',
  styleUrls: ['./habitat-struct.component.scss'],
})
export class HabitatStructComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctStructs: IHabitatStruct[] = [];
  public speciesForm!: FormGroup;
  public structsDropdown: IDropdownItem[] = [];

  @Input() public structs!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public structsById: IStructRequest[] = [];
  @Input() public disabledControls = false;
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private habitatService: HabitatService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.structsById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['structsById'] && this.sampleId) {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    this.habitatService
      .getStructs(this.dataVersion)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          if (this.structsById.length > 0) {
            this.structsById.forEach(() => {
              this.addNew();
            });
            this.structs.patchValue(this.structsById);
            this.structs.markAsTouched();
            this.structsById = [];
            if (this.disabledControls === true) {
              this.structs.disable();
            }
          }

          return;
        })
      )
      .subscribe((res) => {
        if (res != undefined) {
          this.ctStructs = res as any[];
          if (this.ctStructs.length > 0) {
            this.ctStructs.forEach((species) => {
              this.structsDropdown.push({
                id: species.code,
                name: species.structureOrFunction,
              });
            });
          }
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.structs.controls[index] as FormGroup;
  }

  public addNew(): void {
    this.structs.push(this.habitatService.initStructFormGroup(this.fb));
  }

  public remove(index: number): void {
    this.structs.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
