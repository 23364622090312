import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, Subject, takeUntil, finalize, forkJoin } from 'rxjs';
import {
  IMammalsDto,
  IMammalSpeciesDto,
  IMammalThreatsDto,
  ISampleMammalHabitatsDto,
  ISampleMammalSpeciesDto,
  ISampleMammalThreatsDto,
} from 'src/app/core/dtos/mammals.dto';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ICellCodeDto,
  IHabitatSpeciesDto,
  IHabitatTypesDto,
  INaturaSitesDto,
  IPrefecturesDto,
} from 'src/app/core/dtos/protocols.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { ITabBtnGroup } from 'src/app/shared/button-group/button-group.component';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { ISampleIdResponse } from 'src/app/shared/dtos/generic.dto';
import { ICoordinates } from 'src/app/shared/map/map.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MammalsService } from '../../services/mammals.service';
import { ProtocolsService } from '../../services/protocols.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { SamplesService } from '../../services/samples.service';
import { dateValidator } from 'src/app/shared/validators/dateValidator';
import { timeValidator } from 'src/app/shared/validators/timeValidator';
import {
  IFileDeleteRequest,
  IFilePayload,
} from 'src/app/shared/files-container/files-container.component';

@Component({
  selector: 'app-mammals',
  templateUrl: './mammals.component.html',
  styleUrls: ['./mammals.component.scss'],
})
export class MammalsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public mammal: IMammalsDto = {};
  public mammalById?: IMammalsDto;
  public mammalForm!: FormGroup;
  public naturaSites: INaturaSitesDto[] = [];
  public sitesDropdown: IDropdownItem[] = [];
  public prefectures: IPrefecturesDto[] = [];
  public prefecturesDropdown: IDropdownItem[] = [];
  public habitatSpecies: IHabitatSpeciesDto[] = [];
  public habitatSpeciesDropdown: IDropdownItem[] = [];
  public habitatTypes: IHabitatTypesDto[] = [];
  public habitatTypesDropdown: IDropdownItem[] = [];
  public mammalThreats: IMammalThreatsDto[] = [];
  public codes: ICellCodeDto[] = [];
  public codesDropdown: IDropdownItem[] = [];
  public ctSpecies: IMammalSpeciesDto[] = [];
  public ctSpeciesFiltered: IMammalSpeciesDto[] = [];
  public predefinedFiles: IFilePayload[] = [];

  public tabBtn: ITabBtnGroup[] = [
    { id: 'map', name: 'Επέλεξε τοποθεσία', isChecked: true },
    { id: 'coordinates', name: 'Εισαγωγή συντεταγμένων', isChecked: false },
  ];
  public multiTabGroup = [
    { id: 0, name: 'Γεωγραφία', isChecked: true },
    { id: 1, name: 'Κύρια Ενδιαιτήματα Δείγματος', isChecked: true },
    { id: 2, name: 'Παρουσία ειδών', isChecked: true },
    { id: 3, name: 'Πιέσεις/Απειλές', isChecked: true },
  ];
  public activeTabId = 0;

  public coordSystemDropdown: IDropdownItem[] = [
    { id: 'EGSA', name: 'EGSA' },
    { id: 'WGS84', name: 'WGS84' },
  ];

  public financialDropdown: IDropdownItem[] = [
    { id: 'ΥΜΕΠΕΡΑΑ', name: 'ΥΜΕΠΕΡΑΑ' },
    { id: 'ΕΠΠΕΡΑΑ 2007-2013', name: 'ΕΠΠΕΡΑΑ 2007-2013' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];

  public ergoEpoptiaFasiErgouDropdown: IDropdownItem[] = [
    { id: 'E2A', name: '2ο έργο εποπτείας - Φάση Α' },
    { id: 'E2B', name: '2ο έργο εποπτείας - Φάση Β' },
    { id: 'E2C', name: '2ο έργο εποπτείας - Φάση Γ' },
    { id: 'E2D', name: '2ο έργο εποπτείας - Φάση Δ' },
    { id: 'E1X', name: '1ο έργο εποπτείας - Όλες οι φάσεις' },
  ];

  public speciesCategoryDropdown: IDropdownItem[] = [
    { id: 'Χειρόπτερα', name: 'Χειρόπτερα' },
    { id: 'Οπληφόρα', name: 'Οπληφόρα' },
    { id: 'Σαρκοφάγα', name: 'Σαρκοφάγα' },
    { id: 'Μικροθηλαστικά', name: 'Μικροθηλαστικά' },
  ];

  public cloudinessDropdown: IDropdownItem[] = [
    { id: 'S', name: 'Ηλιοφάνεια' },
    { id: 'C1', name: 'Διαστήματα με συννεφιά και ήλιο' },
    { id: 'C2', name: 'Συννεφιά με έντονο φως' },
  ];

  public rainingDropdown: IDropdownItem[] = [
    { id: '0', name: 'Όχι' },
    { id: '1', name: 'Ελαφριά βροχή' },
    { id: '2', name: 'Έντονη βροχή' },
    { id: '3', name: 'Ελαφριά χιονόπτωση' },
    { id: '4', name: 'Έντονη χιονόπτωση' },
  ];

  public methodHiropteraDropdown: IDropdownItem[] = [
    { id: 'A', name: 'Επιθεωρήσεις καταφυγίων χειροπτέρων' },
    { id: 'B', name: 'Παγιδεύσεις χειροπτέρων' },
    { id: 'C', name: 'Ολονύχτιες ηχογραφήσεις' },
    { id: 'D', name: 'Σημειακές ηχογραφήσεις' },
    { id: 'E', name: 'Ακουστικές διαδρομές' },
    { id: 'I', name: 'Καταγραφή βιοδηλωτικών' },
    { id: 'L', name: 'Επιστήμη των πολιτών' },
    { id: 'T', name: 'Τυχαίες παρατηρήσεις' },
  ];

  public methodOpliforaDropdown: IDropdownItem[] = [
    { id: 'N', name: 'Πεζοπορικές Διαδρομές' },
    { id: 'O', name: 'Παρατήρηση από σταθερές θέσεις θέασης' },
    { id: 'I', name: 'Καταγραφή βιοδηλωτικών' },
    { id: 'L', name: 'Επιστήμη των πολιτών' },
    { id: 'T', name: 'Τυχαίες παρατηρήσεις' },
  ];

  public methodSarkofagaDropdown: IDropdownItem[] = [
    { id: 'F', name: 'Συλλογή και επεξεργασία δεδομένων ΕΛΓΑ' },
    { id: 'G', name: 'Ερωτηματολόγια – Συνεντεύξεις' },
    { id: 'H', name: 'Αναπαραγόμενα Καλέσματα' },
    { id: 'I', name: 'Καταγραφή βιοδηλωτικών' },
    { id: 'J', name: 'Φωτοπαγίδευση' },
    { id: 'K', name: 'Γενετική παρακολούθηση' },
    { id: 'L', name: 'Επιστήμη των πολιτών' },
    { id: 'M', name: 'Διαδρομές με Αυτοκίνητο' },
    { id: 'T', name: 'Τυχαίες παρατηρήσεις' },
  ];

  public methodMikrothilastikaDropdown: IDropdownItem[] = [
    { id: 'G', name: 'Ερωτηματολόγια – Συνεντεύξεις' },
    { id: 'I', name: 'Καταγραφή βιοδηλωτικών' },
    { id: 'L', name: 'Επιστήμη των πολιτών' },
    { id: 'N', name: 'Πεζοπορικές Διαδρομές' },
    { id: 'O', name: 'Παρατήρηση από σταθερές θέσεις θέασης' },
    { id: 'P', name: 'Παγιδεύσεις μικρών θηλαστικών' },
    { id: 'Q', name: 'Αναζήτηση εμεσμάτων (pellets)' },
    { id: 'R', name: 'Τοποθέτηση και έλεγχος Nest Boxes ή Nest tubes' },
    {
      id: 'S',
      name: 'Τοποθέτηση και έλεγχος τούνελ ιχνών για μικρά θηλαστικά',
    },
    { id: 'T', name: 'Τυχαίες παρατηρήσεις' },
  ];

  public methodDropdown: IDropdownItem[] = [];

  public coordinates: ICoordinates | undefined;

  public files: any[] = [];
  public filesToDelete: any[] = [];
  public errors: ValidationErrors[] = [];
  public speciesById: ISampleMammalSpeciesDto[] = [];
  public threatsById: ISampleMammalThreatsDto[] = [];
  public habitatsById: ISampleMammalHabitatsDto[] = [];
  public selectedSpecies: any[] = [];

  @Input() public protocolId!: number;
  @Input() public sampleId?: number;
  @Input() public readOnlyForm = false;

  constructor(
    private fb: FormBuilder,
    private protocolService: ProtocolsService,
    private router: Router,
    public toastService: ToastService,
    public common: CommonService,
    public mammalsService: MammalsService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      protocolId: number;
    };
    this.protocolId = state?.protocolId;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.protocolId = history.state.protocolId;
    this.initMammalFormGroup();
    this.mammalForm.controls['samId'].removeValidators(Validators.required);
    this.mammalForm.controls['method'].disable();
    this.mammalForm.controls['capture'].disable();
    this.mammalForm.controls['courseLength'].disable();
    this.selectedSpecies = [];

    this.mammalForm.controls['randomObservation'].valueChanges.subscribe(
      (value) => {
        this.activeTabId = value ? 2 : 0;

        if (value === true) {
          this.files = [];
          this.mammalForm.controls['date'].removeValidators([
            Validators.required,
            dateValidator(),
          ]);
          this.mammalForm.controls['date'].updateValueAndValidity();
          this.mammalForm.controls['time'].removeValidators([
            Validators.required,
            timeValidator(),
          ]);
          this.mammalForm.controls['time'].updateValueAndValidity();
          this.mammalForm.controls['samplingCode'].removeValidators(
            Validators.required
          );
          this.mammalForm.controls['samplingCode'].updateValueAndValidity();
          this.mammalForm.controls['financialInstrument'].removeValidators(
            Validators.required
          );
          this.mammalForm.controls[
            'financialInstrument'
          ].updateValueAndValidity();
          this.mammalForm.controls['lat'].removeValidators([
            Validators.required,
            this.common.latLongValidator('lat', 'long'),
          ]);
          if (
            this.mammalForm.controls['lat'].value != null &&
            this.mammalForm.controls['lat'].value !== ''
          ) {
            this.mammalForm.controls['lat'].updateValueAndValidity();
          }
          this.mammalForm.controls['long'].removeValidators([
            Validators.required,
            this.common.latLongValidator('lat', 'long'),
          ]);
          if (
            this.mammalForm.controls['long'].value != null &&
            this.mammalForm.controls['long'].value !== ''
          ) {
            this.mammalForm.controls['long'].updateValueAndValidity();
          }

          this.mammalForm.controls['method'].removeValidators(
            Validators.required
          );
          this.mammalForm.controls['method'].updateValueAndValidity();

          this.mammalForm.controls['species'].addValidators([
            Validators.required,
          ]);
          this.mammalForm.controls['species'].updateValueAndValidity();
        } else if (value === false) {
          this.mammalForm.controls['date'].addValidators([
            Validators.required,
            dateValidator(),
          ]);
          this.mammalForm.controls['date'].updateValueAndValidity();
          this.mammalForm.controls['time'].addValidators([
            Validators.required,
            timeValidator(),
          ]);
          this.mammalForm.controls['time'].updateValueAndValidity();
          this.mammalForm.controls['samplingCode'].addValidators(
            Validators.required
          );
          this.mammalForm.controls['samplingCode'].updateValueAndValidity();
          this.mammalForm.controls['financialInstrument'].addValidators(
            Validators.required
          );
          this.mammalForm.controls[
            'financialInstrument'
          ].updateValueAndValidity();
          this.mammalForm.controls['lat'].addValidators([
            Validators.required,
            this.common.latLongValidator('lat', 'long'),
          ]);
          this.mammalForm.controls['lat'].updateValueAndValidity();
          this.mammalForm.controls['long'].addValidators([
            Validators.required,
            this.common.latLongValidator('lat', 'long'),
          ]);
          this.mammalForm.controls['long'].updateValueAndValidity();

          this.mammalForm.controls['method'].addValidators(Validators.required);
          this.mammalForm.controls['method'].updateValueAndValidity();

          this.mammalForm.controls['species'].removeValidators([
            Validators.required,
          ]);
          this.mammalForm.controls['species'].updateValueAndValidity();
        }
        (this.mammalForm.controls['habitats'] as FormArray).clear();
        (this.mammalForm.controls['threats'] as FormArray).clear();
        (this.mammalForm.controls['species'] as FormArray).clear();
      }
    );

    this.species.valueChanges.subscribe((value) => {
      const val = value as ISampleMammalSpeciesDto[];
      const res: any[] = [];
      const ctOtherSpecies = this.ctSpecies.find((item) => {
        return item.code === 'Άλλο';
      });

      this.species.controls.forEach((control) => {
        if (
          (control as FormGroup).controls['species'].value !==
          ctOtherSpecies?.id
        ) {
          res.push({
            species: (control as FormGroup).controls['species'].value,
            other: '',
          });
        }

        if (
          (control as FormGroup).controls['species'].value ===
            ctOtherSpecies?.id &&
          (control as FormGroup).controls['otherSpecies'].value !== ''
        ) {
          res.push({
            species: (control as FormGroup).controls['species'].value,
            other: (control as FormGroup).controls['otherSpecies'].value,
          });
        }
      });

      this.selectedSpecies = res;
    });

    if (this.sampleId) {
      this.mammalsService
        .getMammalById(this.sampleId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.getValuesFromServices();
            return;
          })
        )
        .subscribe((res) => {
          if (res != undefined) {
            this.mammalById = res as IMammalsDto;
            // Fix different property names
            this.mammalById.lat = this.mammalById.pointX;
            this.mammalById.long = this.mammalById.pointY;

            if (res.files != null) {
              this.predefinedFiles = res.files as any;
            }

            // Fix date
            if (this.mammalById.date) {
              const convertedDate = new Date(this.mammalById.date);
              const dateString =
                convertedDate.getDate().toString().padStart(2, '0') +
                (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                convertedDate.getFullYear().toString();
              this.mammalById.date = dateString;
            }
            // Fix time
            if (this.mammalById.time) {
              const timeString = this.mammalById.time
                .toString()
                .slice(0, 5)
                .replaceAll(':', '');
              this.mammalById.time = timeString;
            }
            // Fix species date
            if (this.mammalById.species && this.mammalById.species.length > 0) {
              this.mammalById.species.forEach((item) => {
                if (item.date) {
                  const convertedDate = new Date(item.date);
                  const dateString =
                    convertedDate.getDate().toString().padStart(2, '0') +
                    (convertedDate.getMonth() + 1).toString().padStart(2, '0') +
                    convertedDate.getFullYear().toString();
                  item.date = dateString;
                }
              });
            }
          }
        });
    } else {
      this.getValuesFromServices();
    }
  }

  private getValuesFromServices() {
    forkJoin([
      this.protocolService.getCellCodes(),
      this.protocolService.getNaturaSites(this.mammalById?.dataVersion),
      this.protocolService.getPrefectures(this.mammalById?.dataVersion),
      this.mammalsService.getMammalsThreats(this.mammalById?.dataVersion),
      this.mammalsService.getMammalsSpecies(this.mammalById?.dataVersion),
    ])
      .pipe(
        finalize(() => {
          if (this.codes.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.codes.forEach((code) => {
              dropdown.push({
                id: code.cellCode,
                name: code.cellCode,
              });
            });
            this.codesDropdown = dropdown;
          }

          if (this.naturaSites.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.naturaSites.forEach((site) => {
              dropdown.push({ id: site.naturaCode, name: site.name });
            });
            this.sitesDropdown = dropdown;
          }

          if (this.prefectures.length > 0) {
            const dropdown: IDropdownItem[] = [];
            this.prefectures.forEach((prefecture) => {
              dropdown.push({
                id: prefecture.id,
                name: prefecture.prefecture,
              });
            });
            this.prefecturesDropdown = dropdown;
          }

          this.mammalForm.controls['speciesCategory'].valueChanges.subscribe(
            (value) => {
              if (value != null && value !== '') {
                if (
                  this.mammalForm.controls['method'].disabled &&
                  !this.readOnlyForm
                ) {
                  this.mammalForm.controls['method'].enable();
                }

                if (!this.sampleId) {
                  this.mammalForm.controls['method'].setValue('');
                }

                const speciesFiltered = this.ctSpecies.filter((item) => {
                  return (
                    !!item.taxonomySubGroup && item.taxonomySubGroup === value
                  );
                });
                const ctOtherSpecies = this.ctSpecies.find((item) => {
                  return item.code === 'Άλλο';
                });
                if (ctOtherSpecies) {
                  speciesFiltered.push(ctOtherSpecies);
                }
                this.ctSpeciesFiltered = speciesFiltered;

                if (value === 'Χειρόπτερα') {
                  this.methodDropdown = this.methodHiropteraDropdown;
                }
                if (value === 'Οπληφόρα') {
                  this.methodDropdown = this.methodOpliforaDropdown;
                }
                if (value === 'Σαρκοφάγα') {
                  this.methodDropdown = this.methodSarkofagaDropdown;
                }
                if (value === 'Μικροθηλαστικά') {
                  this.methodDropdown = this.methodMikrothilastikaDropdown;
                }

                (
                  this.mammalForm.controls['species'] as FormArray
                ).controls.forEach((control) => {
                  (control as FormGroup).controls['species'].setValue('');
                });

                (this.mammalForm.controls['threats'] as FormArray).clear();
                this.selectedSpecies = [];
              }
            }
          );

          this.mammalForm.controls['method'].valueChanges.subscribe((value) => {
            if (value != null) {
              if (value === 'B' || value === 'J' || value === 'N') {
                if (!this.readOnlyForm) {
                  this.mammalForm.controls['capture'].enable();
                }
              } else {
                this.mammalForm.controls['capture'].setValue(false);
                this.mammalForm.controls['capture'].disable();
              }

              if (
                value === 'D' ||
                value === 'E' ||
                value === 'M' ||
                value === 'N'
              ) {
                if (!this.readOnlyForm) {
                  this.mammalForm.controls['courseLength'].enable();
                }
              } else {
                this.mammalForm.controls['courseLength'].setValue('');
                this.mammalForm.controls['courseLength'].disable();
              }

              if (value === 'I') {
                (
                  this.mammalForm.controls['species'] as FormArray
                ).controls.forEach((control) => {
                  (control as FormGroup).controls['biomarker'].enable();
                  (control as FormGroup).controls['biomarker'].setValue('');
                  (control as FormGroup).controls['biomarkerQuantity'].enable();
                  (control as FormGroup).controls['biomarkerQuantity'].setValue(
                    ''
                  );
                  (control as FormGroup).controls['biomarkerQuality'].enable();
                  (control as FormGroup).controls['biomarkerQuality'].setValue(
                    ''
                  );
                });
              } else {
                (
                  this.mammalForm.controls['species'] as FormArray
                ).controls.forEach((control) => {
                  (control as FormGroup).controls['biomarker'].setValue('');
                  (control as FormGroup).controls['biomarker'].disable();

                  (control as FormGroup).controls['biomarkerQuantity'].setValue(
                    ''
                  );
                  (control as FormGroup).controls[
                    'biomarkerQuantity'
                  ].disable();

                  (control as FormGroup).controls['biomarkerQuality'].setValue(
                    ''
                  );
                  (control as FormGroup).controls['biomarkerQuality'].disable();
                });
              }
            }
          });

          if (this.sampleId && this.mammalById) {
            if (this.mammalById.species) {
              this.speciesById = this.mammalById.species;
            }
            if (this.mammalById.threats) {
              this.threatsById = this.mammalById.threats;
            }
            if (this.mammalById.habitats) {
              this.habitatsById = this.mammalById.habitats;
            }

            this.mammalForm.patchValue(this.mammalById);

            this.mammalForm.controls['randomObservation'].disable();

            if (this.readOnlyForm === true) {
              this.mammalForm.disable();
            }
          }

          return;
        })
      )
      .subscribe(
        ([codes, naturaSites, prefectures, mammalThreats, ctSpecies]) => {
          if (codes != undefined) {
            this.codes = codes as ICellCodeDto[];
          }

          if (naturaSites != undefined) {
            this.naturaSites = naturaSites as INaturaSitesDto[];
          }

          if (prefectures != undefined) {
            this.prefectures = prefectures as IPrefecturesDto[];
          }

          if (mammalThreats != undefined) {
            this.mammalThreats = mammalThreats as IMammalThreatsDto[];
          }

          if (ctSpecies != undefined) {
            this.ctSpecies = ctSpecies as IMammalSpeciesDto[];
          }
        }
      );
  }

  private monitorCoords(): void {
    this.common
      .monitorCoords(this.destroy$, this.mammalForm, 'x', 'y', 'lat', 'long')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICoordinates) => (this.coordinates = data));
  }

  /**
   * Initialization of the user form.
   */
  private initMammalFormGroup() {
    this.mammalForm = this.mammalsService.initMammalFormGroup(this.fb);
    this.monitorCoords();
    this.common.monitorCoordSystem(this.mammalForm, this.destroy$);
  }

  public get species(): FormArray {
    return this.mammalForm.controls['species'] as FormArray;
  }

  public get threats(): FormArray {
    return this.mammalForm.controls['threats'] as FormArray;
  }

  public get habitats(): FormArray {
    return this.mammalForm.controls['habitats'] as FormArray;
  }

  public get coordSystem(): number {
    return this.mammalForm.controls['isWGS84'].value ? 1 : 0;
  }

  /**
   * Adds form value in corresponding observable.
   */
  public prepareMammalRequest(): void {
    this.errors = this.common.validateAllFormControl(this.mammalForm);
    if (this.errors.length > 0) {
      return;
    }

    this.mammal = this.mammalsService.prepareMammalRequest(
      this.mammalForm,
      false,
      this.ctSpecies,
      this.sampleId != null
    );

    if (this.sampleId && !this.readOnlyForm) {
      this.updateSample();
    } else {
      this.registerNewSample();
    }
  }

  public registerNewSample(): void {
    this.mammalsService
      .addMammal(this.mammal)
      .pipe(
        catchError((err) => {
          this.toastService.show('GENERIC_FAILURE_TEXT', {
            header: 'GENERIC_FAILURE_HEADER',
          });
          throw err;
        })
      )
      .subscribe((sampleIdResponse: ISampleIdResponse) => {
        if (this.files.length > 0) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append('files', file);
          });

          formData.append('SampleId', sampleIdResponse.sampleId.toString());
          this.mammalsService
            .addFiles(formData)
            .pipe(
              catchError((err) => {
                this.toastService.show('GENERIC_FAILURE_TEXT', {
                  header: 'GENERIC_FAILURE_HEADER',
                });
                throw err;
              })
            )
            .subscribe(() => {
              this.toastService.show('GENERIC_SUCCESS_TEXT', {
                header: 'GENERIC_SUCCESS_HEADER',
              });

              this.activeModal.close({
                sampleId: sampleIdResponse.sampleId,
                samplingCode: this.mammalForm.value.samplingCode,
                isRandom: !!this.mammal.randomObservation,
              });
            });
        } else {
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.mammalForm.value.samplingCode,
            isRandom: !!this.mammal.randomObservation,
          });
        }
      });
  }

  public updateSample(): void {
    if (this.sampleId) {
      this.mammalsService
        .putMammal(this.sampleId, this.mammal)
        .pipe(
          catchError((err) => {
            this.toastService.show('GENERIC_FAILURE_TEXT', {
              header: 'GENERIC_FAILURE_HEADER',
            });
            throw err;
          })
        )
        .subscribe((sampleIdResponse: ISampleIdResponse) => {
          if (this.files != null) {
            // Uploading new files
            const formData = new FormData();
            let addFiles = false;
            this.files.forEach((file: any) => {
              if (file instanceof File) {
                addFiles = true;
                formData.append('files', file);
              }
            });

            if (addFiles) {
              formData.append('SampleId', (this.sampleId as number).toString());
              this.mammalsService
                .addFiles(formData)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }

          // Deleting files
          if (this.filesToDelete != null && this.filesToDelete.length > 0) {
            const payload: IFileDeleteRequest = {
              sampleId: this.sampleId as number,
              fileIds: [],
            };
            this.filesToDelete.forEach((file) => {
              if (file.id != null) {
                payload.fileIds.push(file.id);
              }
            });

            if (payload.fileIds.length > 0) {
              this.mammalsService
                .removeFiles(payload)
                .pipe(
                  catchError((err) => {
                    this.toastService.show('GENERIC_FAILURE_TEXT', {
                      header: 'GENERIC_FAILURE_HEADER',
                    });
                    throw err;
                  })
                )
                .subscribe();
            }
          }
          this.toastService.show('GENERIC_SUCCESS_TEXT', {
            header: 'GENERIC_SUCCESS_HEADER',
          });
          this.activeModal.close({
            sampleId: sampleIdResponse.sampleId,
            samplingCode: this.mammalForm.value.samplingCode,
            isRandom: !!this.mammal.randomObservation,
          });
        });
    }
  }

  public confirmClose(): void {
    if (this.mammalForm.dirty) {
      const successModalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        modalDialogClass: 'success-form-modal',
      });
      successModalRef.result.then((data) => {
        if (data) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }
  /**
   * Change view
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public tabBtnClick(btnId: string) {
    if (btnId === 'map') {
      this.tabBtn[0].isChecked = true;
      this.tabBtn[1].isChecked = false;
    } else {
      this.tabBtn[1].isChecked = true;
      this.tabBtn[0].isChecked = false;
    }
  }

  /**
   * Find the new coordinates of the map
   *
   * @param {string} btnId the id of the tab that is clicked
   */
  public onMarkerMoved(coordinates: { lat: number; lng: number }): void {
    this.coordinates = coordinates;
    this.submitCoordinates();
  }

  public submitCoordinates(): void {
    if (this.coordinates != null) {
      this.mammalForm.controls['lat'].patchValue(this.coordinates.lat, {
        emitEvent: false,
      });
      this.mammalForm.controls['long'].patchValue(this.coordinates.lng, {
        emitEvent: false,
      });

      const coordinates = this.common.convertWGStoEGSA(
        this.coordinates.lat,
        this.coordinates.lng
      );
      this.mammalForm.controls['x'].patchValue(coordinates.x, {
        emitEvent: false,
      });
      this.mammalForm.controls['y'].patchValue(coordinates.y, {
        emitEvent: false,
      });
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
