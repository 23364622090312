import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MammalsComponent } from './components/mammals/mammals.component';
import { ProtocolsComponent } from './components/protocols/protocols.component';
import { CommonModule } from '@angular/common';
import { SamplingsRoutingModule } from './samplings-routing.module';
import { DetailsPageWrapperComponent } from './components/details-page-wrapper/details-page-wrapper.component';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NgbActiveModal,
  NgbModalModule,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MammalSpeciesComponent } from './components/mammal-species/mammal-species.component';
import { MammalThreatsComponent } from './components/mammal-threats/mammal-threats.component';
import { AmphibiansComponent } from './components/amphibians/amphibians.component';
import { AmphibianSpeciesComponent } from './components/amphibian-species/amphibian-species.component';
import { AmphibianThreatsComponent } from './components/amphibian-threats/amphibian-threats.component';
import { FishComponent } from './components/fish/fish.component';
import { FishSpeciesComponent } from '../samplings/components/fish-species/fish-species.component';
import { FishThreatsComponent } from '../samplings/components/fish-threats/fish-threats.component';
import { FishOtherThreatsComponent } from '../samplings/components/fish-other-threats/fish-other-threats.component';
import { SeaComponent } from '../samplings/components/sea/sea.component';
import { SeaSpeciesComponent } from '../samplings/components/sea-species/sea-species.component';
import { SeaThreatsComponent } from '../samplings/components/sea-threats/sea-threats.component';
import { SeaHabitatComponent } from '../samplings/components/sea-habitat/sea-habitat.component';
import { SeaHabitatSpeciesComponent } from '../samplings/components/sea-habitat-species/sea-habitat-species.component';
import { SeaHabitatThreatsComponent } from '../samplings/components/sea-habitat-threats/sea-habitat-threats.component';
import { SeaHabitatAComponent } from '../samplings/components/sea-habitat-a/sea-habitat-a.component';
import { SeaHabitatBComponent } from './components/sea-habitat-b/sea-habitat-b.component';
import { HabitatComponent } from '../samplings/components/habitat/habitat.component';
import { HabitatStructComponent } from '../samplings/components/habitat-struct/habitat-struct.component';
import { HabitatSpeciesComponent } from '../samplings/components/habitat-species/habitat-species.component';
import { HabitatImpactComponent } from '../samplings/components/habitat-impact/habitat-impact.component';
import { HabitatThreatComponent } from '../samplings/components/habitat-threat/habitat-threat.component';
import { InvertebratesComponent } from './components/invertebrates/invertebrates.component';
import { InvertebrateSpeciesComponent } from './components/invertebrate-species/invertebrate-species.component';
import { InvertebrateThreatsComponent } from './components/invertebrate-threats/invertebrate-threats.component';
import { BirdsComponent } from './components/birds/birds.component';
import { BirdSpeciesComponent } from './components/bird-species/bird-species.component';
import { BirdThreatsComponent } from './components/bird-threats/bird-threats.component';
import { ChlorisComponent } from './components/chloris/chloris.component';
import { ChlorisFunctionsComponent } from './components/chloris-functions/chloris-functions.component';
import { ChlorisThreatsComponent } from './components/chloris-threats/chloris-threats.component';
import { ChlorisPopulationComponent } from './components/chloris-population/chloris-population.component';
import { NewRecordComponent } from './components/new-record/new-record.component';
import { BookHabitatsSpeciesComponent } from './components/book-habitats-species/book-habitats-species.component';
import { BookHabitatsSpeciesEntryComponent } from './components/book-habitats-species-entry/book-habitats-species-entry.component';
import { BookHabitatsComponent } from './components/book-habitats/book-habitats.component';
import { SeaPinnaNobilisComponent } from './components/sea-pinna-nobilis/sea-pinna-nobilis.component';
import { AmphibianHabitatsComponent } from './components/amphibian-habitats/amphibian-habitats.component';
import { MammalHabitatsComponent } from './components/mammal-habitats/mammal-habitats.component';

@NgModule({
  declarations: [
    ProtocolsComponent,
    MammalsComponent,
    DetailsPageWrapperComponent,
    MammalSpeciesComponent,
    MammalThreatsComponent,
    AmphibiansComponent,
    AmphibianSpeciesComponent,
    AmphibianThreatsComponent,
    FishComponent,
    FishSpeciesComponent,
    FishThreatsComponent,
    FishOtherThreatsComponent,
    SeaComponent,
    SeaSpeciesComponent,
    SeaThreatsComponent,
    SeaHabitatComponent,
    SeaHabitatSpeciesComponent,
    SeaHabitatThreatsComponent,
    SeaHabitatAComponent,
    SeaHabitatBComponent,
    HabitatComponent,
    HabitatStructComponent,
    HabitatSpeciesComponent,
    HabitatImpactComponent,
    HabitatThreatComponent,
    InvertebratesComponent,
    InvertebrateSpeciesComponent,
    InvertebrateThreatsComponent,
    BirdsComponent,
    BirdSpeciesComponent,
    BirdThreatsComponent,
    ChlorisComponent,
    ChlorisFunctionsComponent,
    ChlorisThreatsComponent,
    ChlorisPopulationComponent,
    NewRecordComponent,
    BookHabitatsSpeciesComponent,
    BookHabitatsSpeciesEntryComponent,
    BookHabitatsComponent,
    SeaPinnaNobilisComponent,
    AmphibianHabitatsComponent,
    MammalHabitatsComponent,
  ],
  exports: [],
  providers: [NgbActiveModal],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    SharedModule,
    SamplingsRoutingModule,
    ReactiveFormsModule,
    NgbNavModule,
  ],
})
export class SamplingsModule {}
