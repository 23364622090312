<ng-container [formGroup]="formGroup">
  <div formArrayName="habitats" class="form-container form-1">
    <ng-container
      *ngFor="let habitatsForm of habitats.controls; let index = index"
    >
      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCodeA'"
          [label]="'HABITAT_A'"
          [dropdownList]="habADropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['habitatCodeA'].value"
        ></app-dropdown>
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'habitatCodeB'"
          [label]="'HABITAT_B'"
          [dropdownList]="habBDropdown"
          [preDefinedId]="getGroup(index).controls['habitatCodeB'].value"
        ></app-dropdown>
      </div>
      <app-button
        *ngIf="habitats.controls.length > 1"
        (buttonClicked)="removeHabitat(index)"
        [style.--btnPadding]="'8px 20px'"
        class="minus-btn remove-habitats"
        [btnText]="'-'"
        [hidden]="disabledControls"
      ></app-button>
      <div class="line" *ngIf="index + 1 !== habitats.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_HABITAT'"
      (buttonClicked)="addNewHabitat()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
