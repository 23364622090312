<ng-container [formGroup]="formGroup">
  <div formArrayName="species" class="form-container form-2">
    <ng-container
      *ngFor="let speciesForm of species.controls; let index = index"
    >
      <div class="form-row">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'species'"
          [label]="'SPECIES'"
          [dropdownList]="speciesDropdown"
          [required]="true"
          [preDefinedId]="getGroup(index).controls['species'].value"
        ></app-dropdown>
        <app-input
          [formGroup]="getGroup(index)"
          [formName]="'otherSpecies'"
          [label]="'OTHER_SPECIES'"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'parasitesPerCent'"
          [label]="'PARASITES_PERCENT'"
          [required]="true"
        ></app-input>
      </div>

      <div class="form-row form-3">
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'yofY'"
          [label]="'FISH_YOFY'"
          [required]="true"
        ></app-input>
        <app-input
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'adults'"
          [label]="'FISH_ADULTS'"
          [required]="true"
        ></app-input>
        <app-input
          [hidden]="!disabledControls"
          [type]="'number'"
          [formGroup]="getGroup(index)"
          [formName]="'sumOfIndivid'"
          [label]="'FISH_SUM_OF_INDIVID'"
        ></app-input>
      </div>
      <div class="form-row form-3">
        <app-dropdown
          [formGroup]="getGroup(index)"
          [formName]="'speciesHabitat'"
          [label]="'SPECIES_HABITAT'"
          [dropdownList]="speciesHabitatDropdown"
          [preDefinedId]="getGroup(index).controls['speciesHabitat'].value"
        ></app-dropdown>
      </div>

      <div class="form-row">
        <div class="checkbox-container">
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.parii" />
            {{ "PARII" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.pariv" />
            {{ "PARIV" | translate }}
          </label>
          <label
            class="btn btn-secondary"
            ngbButtonLabel
            [class.disabled]="true"
          >
            <input type="checkbox" [checked]="getGroup(index).value.parv" />
            {{ "PARV" | translate }}
          </label>
        </div>
      </div>

      <div class="form-row form-3" [formGroup]="getGroup(index)">
        <app-button
          (buttonClicked)="removeSpecies(index)"
          [style.--btnPadding]="'6px 15px'"
          class="minus-btn"
          [btnText]="'-'"
          [tooltip]="'REMOVE_ENTRY' | translate"
          [hidden]="disabledControls"
        ></app-button>
      </div>
      <div class="line" *ngIf="index + 1 !== species.controls.length"></div>
    </ng-container>
    <app-button
      class="add-btn"
      [btnText]="'ADD_SPECIES'"
      (buttonClicked)="addNewSpecies()"
      [hidden]="disabledControls"
    ></app-button>
  </div>
</ng-container>
