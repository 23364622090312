import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private accountService: AuthService) { }
  /**
   * Handles authorization interception for http requests.
   *
   * @param {HttpRequest}request The request to intercept.
   * @param {HttpHandler} next the http handler.
   * @returns {Observable} Observable Empty if handlled or error if it occurs.
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Get the security token
    const token = this.accountService.jwtToken$.getValue();

    // Add the authorization header to the request (by cloning it) if the token exists
    const changedReq =
      token != null
        ? request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        })
        : request;

    return next.handle(changedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        const url = this.router.url;
        if (error && error.status === 401) {
          this.accountService.jwtToken$.next('');
          this.router.navigate(['/']);
        } else if (error && error.status === 403) {
          this.router.navigate(['/protocols']);
        }

        // If it's not an authentication error, this interceptor is not concerned
        return throwError(error);
      })
    );
  }
}
