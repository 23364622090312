<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>
<div class="title-container">
  <span class="title-text uppercase"
    >{{ "NEW_REGISTRATION" | translate }} - {{ "AMPHIBIAN" | translate }}</span
  >
</div>
<div class="random-observation-check-box" [formGroup]="amphibianForm">
  <label
    class="btn btn-secondary"
    ngbButtonLabel
    [ngClass]="{ disabled: sampleId }"
  >
    <input type="checkbox" formControlName="randomObservation" />
    {{ "RANDOM_OBSERVATION" | translate }}
  </label>
</div>
<div class="amphibians-container">
  <div class="info-container">
    <span class="info-text uppercase">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container">
    <div
      class="form-row form-3"
      [hidden]="amphibianForm.controls['randomObservation'].value === true"
    >
      <app-input
        [formGroup]="amphibianForm"
        [formName]="'samplingCode'"
        [label]="'SAMPLE_CODE'"
        [required]="true"
      ></app-input>
    </div>
    <div class="form-row form-3">
      <app-dropdown
        class="dropdown-column"
        [formGroup]="amphibianForm"
        [formName]="'ergoEpoptiaFasiErgou'"
        [label]="'ERGO_EPOPTIA_FASI_ERGOU'"
        [dropdownList]="ergoEpoptiaFasiErgouDropdown"
        [required]="true"
        [preDefinedId]="amphibianById?.ergoEpoptiaFasiErgou"
      ></app-dropdown>
    </div>
    <div
      class="form-row"
      [hidden]="amphibianForm.controls['randomObservation'].value === true"
    >
      <app-input
        [mask]="'00/00/0000'"
        [formGroup]="amphibianForm"
        [formName]="'date'"
        [label]="'DATE'"
        [required]="true"
      ></app-input>
      <app-input
        [mask]="'00:00'"
        [formGroup]="amphibianForm"
        [formName]="'time'"
        [label]="'TIME'"
        [required]="true"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="amphibianForm"
        [formName]="'duration'"
        [label]="'DURATION'"
      ></app-input>
    </div>
    <div
      class="form-row"
      [hidden]="amphibianForm.controls['randomObservation'].value === true"
    >
      <app-input
        [formGroup]="amphibianForm"
        [formName]="'researcher'"
        [label]="'RESEARCHER'"
      ></app-input>
      <app-input
        [formGroup]="amphibianForm"
        [formName]="'author'"
        [label]="'AUTHOR'"
      ></app-input>

      <app-dropdown
        [formGroup]="amphibianForm"
        [formName]="'financialInstrument'"
        [label]="'FINANCIAL_INSTRUMENT'"
        [dropdownList]="financialDropdown"
        [preDefinedId]="'ΥΜΕΠΕΡΑΑ'"
        [required]="true"
        [preDefinedId]="amphibianById?.financialInstrument"
      ></app-dropdown>
    </div>

    <app-textarea
      [hidden]="amphibianForm.controls['randomObservation'].value === true"
      [style.max-width]="'100%'"
      [formGroup]="amphibianForm"
      [formName]="'habitatDescription'"
      [label]="'HABITAT_DESCRIPTION'"
    ></app-textarea>

    <div
      class="form-row"
      [hidden]="amphibianForm.controls['randomObservation'].value === true"
    >
      <app-dropdown
        [formGroup]="amphibianForm"
        [formName]="'weather'"
        [label]="'WEATHER'"
        [dropdownList]="weatherDropdown"
        [preDefinedId]="amphibianById?.hliof_Nefosi"
      ></app-dropdown>
      <app-input
        [showMaskTyped]="false"
        [suffix]="'°C'"
        [mask]="'separator.2'"
        [allowNegativeNumbers]="true"
        [formGroup]="amphibianForm"
        [formName]="'temperature'"
        [label]="'TEMPERATURE'"
      ></app-input>
      <app-dropdown
        [formGroup]="amphibianForm"
        [formName]="'rainCode'"
        [label]="'RAIN_CODE'"
        [dropdownList]="rainDropdown"
        [preDefinedId]="amphibianById?.rainCode"
      ></app-dropdown>
    </div>
    <div
      class="form-row"
      [hidden]="amphibianForm.controls['randomObservation'].value === true"
    >
      <app-input
        [type]="'number'"
        [formGroup]="amphibianForm"
        [formName]="'windIntensity'"
        [label]="'WIND_INTENSITY'"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="amphibianForm"
        [formName]="'courseLengthM'"
        [label]="'COURSE_LENGTH'"
      ></app-input>
      <app-dropdown
        [formGroup]="amphibianForm"
        [formName]="'method'"
        [label]="'METHOD'"
        [dropdownList]="methodDropdown"
        [required]="true"
        [preDefinedId]="amphibianById?.method"
      ></app-dropdown>
    </div>

    <app-textarea
      [hidden]="amphibianForm.controls['randomObservation'].value === true"
      [style.max-width]="'100%'"
      [formGroup]="amphibianForm"
      [formName]="'threats'"
      [label]="'AMPHIBIAN_THREATS'"
    ></app-textarea>

    <app-textarea
      [hidden]="amphibianForm.controls['randomObservation'].value === true"
      [style.max-width]="'100%'"
      [formGroup]="amphibianForm"
      [formName]="'observations'"
      [label]="'OBSERVATIONS'"
    ></app-textarea>
  </div>
  <div
    class="info-container"
    [hidden]="amphibianForm.controls['randomObservation'].value === true"
  >
    <span class="info-text uppercase">{{ "LOCATION" | translate }}</span>
    <div class="line"></div>
  </div>
  <div
    class="form-container form-1"
    [hidden]="amphibianForm.controls['randomObservation'].value === true"
  >
    <div class="form-row" [formGroup]="amphibianForm" [hidden]="readOnlyForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isEGSA" />
        {{ coordSystemDropdown[0].id }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isWGS84" />
        {{ coordSystemDropdown[1].id }}
      </label>
    </div>
    <div class="form-row">
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="amphibianForm"
        [formName]="'lat'"
        [label]="'LATITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="amphibianForm"
        [formName]="'long'"
        [label]="'LONGTITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="amphibianForm"
        [formName]="'x'"
        [label]="'X'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="amphibianForm"
        [formName]="'y'"
        [label]="'Y'"
        [required]="true"
      ></app-input>
    </div>
  </div>
  <br />
  <div
    class="map-container"
    [hidden]="amphibianForm.controls['randomObservation'].value === true"
  >
    <app-map
      [coordinates]="coordinates"
      (markerMoved)="onMarkerMoved($event)"
      [disabled]="readOnlyForm"
    ></app-map>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <nav
    ngbNav
    #nav="ngbNav"
    [destroyOnHide]="false"
    class="multi-tab-buttons"
    [activeId]="activeTabId"
    [ngClass]="{
      'hide-geography':
        amphibianForm.controls['randomObservation'].value === true,
      'hide-habitats':
        amphibianForm.controls['randomObservation'].value === true
    }"
  >
    <ng-container [ngbNavItem]="0">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name }}</a
      >
      <ng-template ngbNavContent>
        <div
          [ngClass]="
            readOnlyForm ? 'form-container form-2' : 'form-container form-1'
          "
        >
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="amphibianForm"
              [formName]="'altitude'"
              [label]="'ALTITUDE'"
            ></app-input>

            <app-input
              [formGroup]="amphibianForm"
              [formName]="'locality'"
              [label]="'LOCATION'"
            ></app-input>
          </div>

          <div class="form-row form-3">
            <app-dropdown
              class="dropdown-column"
              [hidden]="!readOnlyForm"
              [formGroup]="amphibianForm"
              [formName]="'naturaCode'"
              [label]="'NATURA_CODE'"
              [dropdownList]="sitesDropdown"
              [required]="true"
              [preDefinedId]="amphibianById?.naturaCode"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="amphibianForm"
              [hidden]="!readOnlyForm"
              [formName]="'gridCell'"
              [label]="'GRID_CELL'"
              [dropdownList]="codesDropdown"
              [required]="true"
              [preDefinedId]="amphibianById?.gridCell"
            ></app-dropdown>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container [ngbNavItem]="1">
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        >{{ multiTabGroup[1].name }}</a
      >
      <ng-template ngbNavContent>
        <app-amphibian-habitats
          [formGroup]="amphibianForm"
          [habitats]="habitats"
          [habitatsById]="habitatsById"
          [disabledControls]="readOnlyForm"
          [readOnlyForm]="readOnlyForm"
          [dataVersion]="amphibianById?.dataVersion"
          [sampleId]="sampleId"
        >
        </app-amphibian-habitats>
      </ng-template>
    </ng-container>

    <ng-container [ngbNavItem]="2">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[2].name }}</a
      >
      <ng-template ngbNavContent>
        <app-amphibian-species
          [formGroup]="amphibianForm"
          [species]="species"
          [ctSpecies]="ctSpecies"
          [habitatADropdown]="habADropdown"
          [habitatBDropdown]="habBDropdown"
          [codesDropdown]="codesDropdown"
          [speciesById]="speciesById"
          [disabledControls]="readOnlyForm"
          [readOnlyForm]="readOnlyForm"
          [dataVersion]="amphibianById?.dataVersion"
          [sampleId]="sampleId"
        ></app-amphibian-species>
      </ng-template>
    </ng-container>

    <!-- <ng-container [ngbNavItem]="3">
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[3].isChecked }"
        >{{ multiTabGroup[3].name }}</a
      >
      <ng-template ngbNavContent>
        <app-amphibian-threats
          [formGroup]="amphibianForm"
          [threats]="threats"
        ></app-amphibian-threats>
      </ng-template>
    </ng-container> -->
  </nav>
  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <ng-container
    *ngIf="
      (!sampleId && !!!amphibianForm.controls['randomObservation'].value) ||
      (sampleId &&
        predefinedFiles.length > 0 &&
        !!!amphibianForm.controls['randomObservation'].value) ||
      (sampleId &&
        !readOnlyForm &&
        !!!amphibianForm.controls['randomObservation'].value)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    [btnText]="sampleId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    (buttonClicked)="prepareAmphibianRequest()"
    [isDisabled]="!amphibianForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
