import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api';
import {
  IDataTableColumns,
  sampleChlorisColumns,
  sampleChlorisFunctionsColumns,
  sampleChlorisPopulationColumns,
  sampleChlorisThreatsColumns,
  sampleColumns,
} from 'src/app/core/consts/data-table-columns';
import {
  IChlorisDto,
  IChlorisFunctions,
  IChlorisPopulations,
  IChlorisPressures,
} from 'src/app/core/dtos/chloris.dto';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { ChlorisService } from 'src/app/samplings/services/chloris.service';
import { ProtocolsService } from 'src/app/samplings/services/protocols.service';
import { SearchService } from '../services/search.service';
import { TableWrapperComponent } from '../table-wrapper/table-wrapper.component';
import {
  DataType,
  FilterOperator,
  FilterType,
} from '../table/enumerations/table.enumerations';
import { ITableFilterRequest } from '../table/interfaces/table.interfaces';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { utils, writeFile } from 'xlsx';
import {
  chlorisConfig,
  ChlorisFormGroup,
} from 'src/app/samplings/config-files/chloris-config';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-chloris-search',
  templateUrl: './chloris-search.component.html',
  styleUrls: ['./chloris-search.component.scss'],
})
export class ChlorisSearchComponent extends TableWrapperComponent {
  public sampleChlorisTotalRecords: number = 0;
  public sampleChloris: IChlorisDto[] = [];
  public sampleChlorisColumns: IDataTableColumns[] = [];
  public sampleChlorisFirst = 0;

  public functionsTotalRecords: number = 0;
  public functions: IChlorisFunctions[] = [];
  public functionsColumns: IDataTableColumns[] = [];
  public functionsFirst = 0;

  public threatsTotalRecords: number = 0;
  public threats: IChlorisPressures[] = [];
  public threatsColumns: IDataTableColumns[] = [];
  public threatsFirst = 0;

  public populationTotalRecords: number = 0;
  public population: IChlorisPopulations[] = [];
  public populationColumns: IDataTableColumns[] = [];
  public populationFirst = 0;

  protected override payload: ITableFilterRequest = {
    samples: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    sampleChloris: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    functions: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    threats: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
    population: {
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: null,
      items: [],
    },
  };
  private tablesInitialized: number = 0;

  public activeTabId: number = 0;
  public multiTabGroup = [
    { id: 0, name: 'SEARCH_SAMPLES', isChecked: true },
    { id: 1, name: 'SEARCH_SAMPLES_CHLORIS', isChecked: true },
    { id: 2, name: 'SEARCH_FUNCTIONS', isChecked: true },
    { id: 3, name: 'SEARCH_THREATS', isChecked: true },
    { id: 4, name: 'SEARCH_POPULATION', isChecked: true },
  ];

  constructor(
    protected override searchService: SearchService,
    protected override protocolService: ProtocolsService,
    private chlorisService: ChlorisService,
    protected override fb: FormBuilder,
    protected override translate: TranslateService,
    private modalService: NgbModal,
    protected override toastService: ToastService
  ) {
    super(searchService, protocolService, fb, translate, toastService);
  }

  ngOnInit(): void {
    this.fetchSampleChlorisColumns();
    this.fetchSampleColumns();
    this.fetchFunctionsColumns();
    this.fetchThreatsColumns();
    this.fetchPopulationColumns();

    this.watchDataversion();
  }

  protected override fetchSpeciesColumns(): void {}

  protected override fetchSampleColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.sampleColumns = sampleColumns;
    const sampleColumnsTemp = [...sampleColumns];
    sampleColumnsTemp.forEach((column) => {
      if (
        column.searchDataType === DataType.List &&
        (column.data == null || column.data.length === 0)
      ) {
        if (column.propertyName === 'prefecture') {
          this.protocolService.getPrefectures().subscribe((data) => {
            column.data = data.map((prefecture) => {
              return { id: prefecture.id, name: prefecture.prefecture };
            });
            this.sampleColumns = sampleColumnsTemp;
          });
        }
      }
      if (column.header === 'RANDOM_OBSERVATION') {
        column.shown = false;
        this.sampleColumns = sampleColumnsTemp;
      }
    });
  }

  private fetchFunctionsColumns(): void {
    // TODO: Currently this works only for one property.
    // To allow more properties to be populated we will
    // need to orchistrate the subscriptions.
    this.functionsColumns = sampleChlorisFunctionsColumns;
    const speciesColumnsTemp = [...sampleChlorisFunctionsColumns];
    speciesColumnsTemp.forEach((column) => {
      if (column.searchDataType === DataType.List) {
        if (column.propertyName === 'species') {
          this.searchService
            .getSpecies(Protocols.Chloris, this.version)
            .subscribe((species) => {
              column.data = species.map((item) => {
                return {
                  id: item.id,
                  name: `(${Protocols[item.protocol].toString()}) - ${
                    item.name
                  }`,
                };
              });
              this.functionsColumns = speciesColumnsTemp;
            });
        }
      }
    });
  }

  protected override fetchThreatsColumns(): void {
    this.threatsColumns = sampleChlorisThreatsColumns;
    forkJoin([
      this.chlorisService.getChlorisImportances(this.version),
    ]).subscribe(([ctImportance]) => {
      if (ctImportance != null) {
        const importance = this.threatsColumns.find(
          (item) => item.propertyName === 'importance'
        );
        if (importance != null) {
          importance.data = ctImportance.map((item) => {
            return {
              id: item.code,
              name: `(${item.code}) - ${item.description}`,
            };
          });
        }
      }
    });
  }

  private fetchSampleChlorisColumns(): void {
    this.sampleChlorisColumns = sampleChlorisColumns;
    forkJoin([
      this.searchService.getSpecies(Protocols.Chloris, this.version),
      this.chlorisService.getSoil(this.version),
      this.chlorisService.getDepth(this.version),
      this.chlorisService.getExpo(this.version),
      this.chlorisService.getRelief(this.version),
      this.chlorisService.getChlorisUnits(this.version),
      this.chlorisService.getChlorisStratifications(this.version),
      this.chlorisService.getQuadrat(this.version),
    ]).subscribe(
      ([
        ctSpecies,
        ctSoil,
        ctDepth,
        ctExposition,
        ctRelief,
        ctUnit,
        ctStrat,
        ctQuadrat,
      ]) => {
        if (ctSpecies != null) {
          const species = this.sampleChlorisColumns.find(
            (item) => item.propertyName === 'species'
          );
          if (species != null) {
            species.data = ctSpecies.map((item) => {
              return {
                id: item.id,
                name: `(${item.id}) - ${item.name}`,
              };
            });
          }
        }
        if (ctSoil != null) {
          const soil = this.sampleChlorisColumns.find(
            (item) => item.propertyName === 'soil'
          );
          if (soil != null) {
            soil.data = ctSoil.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }
        if (ctDepth != null) {
          const a0 = this.sampleChlorisColumns.find(
            (item) => item.propertyName === 'a0'
          );
          if (a0 != null) {
            a0.data = ctDepth.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) ${item.descriptionGR}`,
              };
            });
          }
        }
        if (ctExposition != null) {
          const exposit = this.sampleChlorisColumns.find(
            (item) => item.propertyName === 'exposit'
          );
          if (exposit != null) {
            exposit.data = ctExposition.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.codeGR}`,
              };
            });
          }
        }
        if (ctRelief != null) {
          const relief = this.sampleChlorisColumns.find(
            (item) => item.propertyName === 'relief'
          );
          if (relief != null) {
            relief.data = ctRelief.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }
        if (ctUnit != null) {
          const unit = this.sampleChlorisColumns.find(
            (item) => item.propertyName === 'unit'
          );
          if (unit != null) {
            unit.data = ctUnit.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctStrat != null) {
          const strat = this.sampleChlorisColumns.find(
            (item) => item.propertyName === 'strat'
          );
          if (strat != null) {
            strat.data = ctStrat.map((item) => {
              return {
                id: item.code,
                name: item.code,
              };
            });
          }
        }
        if (ctQuadrat != null) {
          const method = this.sampleChlorisColumns.find(
            (item) => item.propertyName === 'method'
          );
          if (method != null) {
            method.data = ctQuadrat.map((item) => {
              return {
                id: item.code,
                name: `(${item.code}) - ${item.description}`,
              };
            });
          }
        }
      }
    );
  }

  private fetchPopulationColumns(): void {
    this.populationColumns = sampleChlorisPopulationColumns;
    forkJoin([
      this.chlorisService.getChlorisVitality(this.version),
      this.chlorisService.getChlorisSex(this.version),
    ]).subscribe(([ctVitality, ctSex]) => {
      if (ctVitality != null) {
        const vit = this.populationColumns.find(
          (item) => item.propertyName === 'vit'
        );
        if (vit != null) {
          vit.data = ctVitality.map((item) => {
            return {
              id: item.code,
              name: `(${item.code}) - ${item.description}`,
            };
          });
        }
      }
      if (ctSex != null) {
        const sex = this.populationColumns.find(
          (item) => item.propertyName === 'sex'
        );
        if (sex != null) {
          sex.data = ctSex.map((item) => {
            return {
              id: item.sex,
              name: item.sex,
            };
          });
        }
      }
    });
  }

  protected override watchDataversion(): void {
    this.subscription.add(
      this.form.controls['dataVersion'].valueChanges.subscribe((version) => {
        // Updating tables.
        const dataVersion = this.payload.samples?.items.find(
          (item) => item.name === 'DataVersion'
        );
        if (dataVersion != null) {
          if (dataVersion.rules != null && dataVersion.rules.length > 0) {
            dataVersion.rules[0].value = version;
          } else {
            dataVersion.rules = [
              {
                value: version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ];
          }
        } else {
          this.payload.samples?.items.push({
            name: 'DataVersion',
            dataType: DataType.String,
            rules: [
              {
                value: this.version,
                filterType: FilterType.Equal,
                operator: FilterOperator.Or,
              },
            ],
          });
        }

        this.fetchSampleChlorisColumns();
        this.fetchSampleColumns();
        this.fetchFunctionsColumns();
        this.fetchThreatsColumns();
        this.fetchPopulationColumns();

        this.fetchData();
      })
    );
  }

  protected override fetchData(): void {
    this.searchService.filterChloris(this.payload).subscribe((response) => {
      if (response != null) {
        if (response.samples != null) {
          this.sampleTotalRecords = response.samples.totalCount;

          // TODO: Uncomment this when we resolve the time filtering issue.
          // response.samples.rows.forEach(item => {
          //   if (item.time != null) {
          //     const timeString = item.time.toString().slice(0, 5);
          //     item.time = timeString;
          //   }
          // });

          this.samples = response.samples.rows;
        }

        if (response.sampleChloris != null) {
          this.sampleChlorisTotalRecords = response.sampleChloris.totalCount;
          this.sampleChloris = response.sampleChloris.rows;

          this.sampleCoordinates = this.sampleChloris
            .filter((item) => item.lat != null && item.long != null)
            .map((item) => {
              return {
                lat: item.lat,
                lng: item.long,
                text:
                  item.samplingCode != null
                    ? item.samplingCode.toString()
                    : undefined,
              };
            });
        }

        if (response.functionsChloris != null) {
          this.functionsTotalRecords = response.functionsChloris.totalCount;
          this.functions = response.functionsChloris.rows;
        }

        if (response.threatsChloris != null) {
          this.threatsTotalRecords = response.threatsChloris.totalCount;
          this.threats = response.threatsChloris.rows;
        }

        if (response.populationChloris != null) {
          this.populationTotalRecords = response.populationChloris.totalCount;
          this.population = response.populationChloris.rows;
        }
      }
    });
  }

  public loadSampleChlorisData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 5) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(event, sampleChlorisColumns);
    this.payload.sampleChloris = tableFilter;

    if (event.first) {
      this.sampleChlorisFirst = event.first;
    }

    this.fetchData();
  }

  public loadFunctionsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 5) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleChlorisFunctionsColumns
    );
    this.payload.functions = tableFilter;

    if (event.first) {
      this.functionsFirst = event.first;
    }

    this.fetchData();
  }

  public loadThreatsData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 5) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleChlorisThreatsColumns
    );
    this.payload.threats = tableFilter;

    if (event.first) {
      this.threatsFirst = event.first;
    }

    this.fetchData();
  }

  public loadPopulationData(event: LazyLoadEvent): void {
    this.tablesInitialized++;
    if (this.tablesInitialized < 5) {
      return;
    }

    const tableFilter = this.prepareFilterPayload(
      event,
      sampleChlorisPopulationColumns
    );
    this.payload.population = tableFilter;

    if (event.first) {
      this.populationFirst = event.first;
    }

    this.fetchData();
  }

  protected override initializePaginator() {
    this.sampleFirst = 0;
    this.sampleChlorisFirst = 0;
    this.functionsFirst = 0;
    this.threatsFirst = 0;
    this.populationFirst = 0;
  }

  public openExportModal() {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ExportModalComponent, {
      centered: true,
      modalDialogClass: 'export-modal',
      backdrop: 'static',
      keyboard: true,
    });

    if (modalRef) {
      modalRef.result.then((res) => {
        if (res != null) {
          this.exportFiles(res.xlsxFile, res.zipFile);
        }
      });
    }
  }

  public exportFiles(xlsxFile = false, zipFile = false) {
    let payload = { ...this.payload };
    if (payload.samples) {
      payload.samples.rows = this.sampleTotalRecords;
      payload.samples.first = 0;
    }
    if (payload.sampleChloris) {
      payload.sampleChloris.rows = this.sampleChlorisTotalRecords;
      payload.sampleChloris.first = 0;
    }
    if (payload.functions) {
      payload.functions.rows = this.functionsTotalRecords;
      payload.functions.first = 0;
    }
    if (payload.threats) {
      payload.threats.rows = this.threatsTotalRecords;
      payload.threats.first = 0;
    }
    if (payload.population) {
      payload.population.rows = this.populationTotalRecords;
      payload.population.first = 0;
    }

    let samples: any[] = [];
    let functionsChloris: any[] = [];
    let populationChloris: any[] = [];
    let threatsChloris: any[] = [];

    this.searchService.filterChloris(payload).subscribe((response) => {
      const date = new Date();
      const filename =
        'EP2_Export_Chloris_' +
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      if (xlsxFile) {
        if (response && response.sampleChloris) {
          samples = this.buildExportArray(
            response,
            'sampleChloris',
            true,
            chlorisConfig,
            ''
          );
        }

        if (response && response.functionsChloris) {
          functionsChloris = this.buildExportArray(
            response,
            'functionsChloris',
            false,
            chlorisConfig,
            ChlorisFormGroup.Functions
          );
        }

        if (response && response.populationChloris) {
          populationChloris = this.buildExportArray(
            response,
            'populationChloris',
            false,
            chlorisConfig,
            ChlorisFormGroup.Populations
          );
        }

        if (response && response.threatsChloris) {
          threatsChloris = this.buildExportArray(
            response,
            'threatsChloris',
            false,
            chlorisConfig,
            ChlorisFormGroup.Pressures
          );
        }

        const workbook = utils.book_new();

        const wsSamples = utils.aoa_to_sheet(samples);
        wsSamples['!cols'] = this.fixExportArrayColumnsWidth(samples);
        wsSamples['!rows'] = [{ hpt: 50 }];

        const wsFunctionsChloris = utils.aoa_to_sheet(functionsChloris);
        wsFunctionsChloris['!cols'] =
          this.fixExportArrayColumnsWidth(functionsChloris);
        wsFunctionsChloris['!rows'] = [{ hpt: 50 }];

        const wsPopulationChloris = utils.aoa_to_sheet(populationChloris);
        wsPopulationChloris['!cols'] =
          this.fixExportArrayColumnsWidth(populationChloris);
        wsPopulationChloris['!rows'] = [{ hpt: 50 }];

        const wsThreatsChloris = utils.aoa_to_sheet(threatsChloris);
        wsThreatsChloris['!cols'] =
          this.fixExportArrayColumnsWidth(threatsChloris);
        wsThreatsChloris['!rows'] = [{ hpt: 50 }];

        utils.book_append_sheet(
          workbook,
          wsSamples,
          this.translate.instant('SPREADSHEET_CHLORIS_SAMPLES')
        );
        utils.book_append_sheet(
          workbook,
          wsFunctionsChloris,
          this.translate.instant('SPREADSHEET_CHLORIS_FUNCTIONS')
        );
        utils.book_append_sheet(
          workbook,
          wsPopulationChloris,
          this.translate.instant('SPREADSHEET_CHLORIS_POPULATION')
        );
        utils.book_append_sheet(
          workbook,
          wsThreatsChloris,
          this.translate.instant('SPREADSHEET_CHLORIS_THREATS')
        );

        const date = new Date();
        writeFile(workbook, filename + '.xlsx', { compression: true });
      }

      if (zipFile) {
        this.getZip(response, filename);
      }
    });
  }
}
