<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>
<div class="title-container">
  <span class="title-text uppercase"
    >{{ "NEW_REGISTRATION" | translate }} -
    {{ "INVERTEBRATES" | translate }}</span
  >
</div>
<div class="invertebrates-container">
  <div class="info-container uppercase">
    <span class="info-text">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container">
    <div class="form-row form-3">
      <app-input
        [formGroup]="invertebrateForm"
        [formName]="'samplingCode'"
        [label]="'SAMPLE_CODE'"
        [required]="true"
      ></app-input>
    </div>
    <div class="form-row">
      <app-input
        [mask]="'00/00/0000'"
        [formGroup]="invertebrateForm"
        [formName]="'date'"
        [label]="'DATE'"
        [required]="true"
      ></app-input>
      <app-input
        [mask]="'00:00'"
        [formGroup]="invertebrateForm"
        [formName]="'time'"
        [label]="'TIME'"
        [required]="true"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'duration'"
        [label]="'DURATION'"
      ></app-input>
    </div>
    <div class="form-row form-3">
      <app-input
        [formGroup]="invertebrateForm"
        [formName]="'weatherConditions'"
        [label]="'WEATHER'"
      ></app-input>
      <app-dropdown
        [formGroup]="invertebrateForm"
        [formName]="'habitat'"
        [label]="'HABITAT'"
        [dropdownList]="habitatSpeciesDropdown"
        [preDefinedId]="invertebrateById?.habitat"
      ></app-dropdown>
      <app-dropdown
        class="dropdown-column"
        [formGroup]="invertebrateForm"
        [hidden]="!readOnlyForm"
        [formName]="'naturaCode'"
        [label]="'NATURA_CODE'"
        [dropdownList]="sitesDropdown"
        [required]="true"
        [preDefinedId]="invertebrateById?.naturaCode"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-input
        [formGroup]="invertebrateForm"
        [formName]="'topography'"
        [label]="'TOPOGRAPHY'"
      ></app-input>
      <app-input
        [formGroup]="invertebrateForm"
        [formName]="'vegetationType'"
        [label]="'VEGETATION_TYPE'"
      ></app-input>
      <app-input
        [formGroup]="invertebrateForm"
        [formName]="'crossSectionLength'"
        [label]="'CROSS_SECTION_LENGTH'"
        [type]="'number'"
      ></app-input>
    </div>
    <div class="form-row">
      <app-input
        [formGroup]="invertebrateForm"
        [formName]="'humidity'"
        [label]="'HUMIDITY'"
      ></app-input>
      <app-dropdown
        [formGroup]="invertebrateForm"
        [formName]="'financialInstrument'"
        [label]="'FINANCIAL_INSTRUMENT'"
        [dropdownList]="financialDropdown"
        [required]="true"
        [preDefinedId]="invertebrateById?.financialInstrument"
      ></app-dropdown>
      <app-input
        [showMaskTyped]="false"
        [suffix]="'°C'"
        [mask]="'separator.2'"
        [allowNegativeNumbers]="true"
        [formGroup]="invertebrateForm"
        [formName]="'temperature'"
        [label]="'TEMPERATURE'"
      ></app-input>
    </div>
    <div class="form-row form-3">
      <app-input
        [formGroup]="invertebrateForm"
        [formName]="'method'"
        [label]="'METHOD'"
      ></app-input>
      <app-input
        [formGroup]="invertebrateForm"
        [formName]="'researcher'"
        [label]="'RESEARCHER'"
      ></app-input>
    </div>
    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="invertebrateForm"
      [formName]="'observations'"
      [label]="'OBSERVATIONS'"
    ></app-textarea>
  </div>
  <div class="info-container uppercase">
    <span class="info-text">{{ "LOCATION" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container form-1">
    <div
      class="form-row"
      [formGroup]="invertebrateForm"
      [hidden]="readOnlyForm"
    >
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isEGSA" />
        {{ coordSystemDropdown[0].id }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isWGS84" />
        {{ coordSystemDropdown[1].id }}
      </label>
    </div>
    <div class="form-row" [ngClass]="{ hidden: coordSystem !== 1 }">
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'lat'"
        [label]="'LATITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'long'"
        [label]="'LONGTITUDE'"
        [required]="true"
      ></app-input>
    </div>
    <div class="form-row" [ngClass]="{ hidden: coordSystem !== 1 }">
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'latEnd'"
        [label]="'LATITUDE_END'"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'longEnd'"
        [label]="'LONGTITUDE_END'"
      ></app-input>
    </div>
    <div class="form-row" [ngClass]="{ hidden: coordSystem !== 0 }">
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'x'"
        [label]="'X'"
        [required]="true"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'y'"
        [label]="'Y'"
        [required]="true"
      ></app-input>
    </div>
    <div class="form-row" [ngClass]="{ hidden: coordSystem !== 0 }">
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'xEnd'"
        [label]="'X_END'"
      ></app-input>
      <app-input
        [type]="'number'"
        [formGroup]="invertebrateForm"
        [formName]="'yEnd'"
        [label]="'Y_END'"
      ></app-input>
    </div>
  </div>
  <br />
  <div class="map-container">
    <app-map
      [coordinates]="coordinates"
      (markerMoved)="onMarkerMoved($event)"
      [disabled]="readOnlyForm"
    ></app-map>
  </div>
  <div class="info-container">
    <span class="info-text uppercase">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" class="multi-tab-buttons">
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name }}</a
      >
      <ng-template ngbNavContent>
        <div
          [ngClass]="
            readOnlyForm ? 'form-container form-2' : 'form-container form-1'
          "
        >
          <div class="form-row">
            <app-input
              [type]="'number'"
              [formGroup]="invertebrateForm"
              [formName]="'altitude'"
              [label]="'ALTITUDE'"
              [required]="true"
            ></app-input>
            <app-dropdown
              [formGroup]="invertebrateForm"
              [hidden]="!readOnlyForm"
              [formName]="'gridCell'"
              [label]="'GRID_CELL'"
              [dropdownList]="codesDropdown"
              [required]="true"
              [preDefinedId]="invertebrateById?.gridCell"
            ></app-dropdown>
            <app-input
              [formGroup]="invertebrateForm"
              [formName]="'locality'"
              [label]="'LOCATION'"
            ></app-input>
            <app-dropdown
              [formGroup]="invertebrateForm"
              [formName]="'prefecture'"
              [label]="'PREFECTURE'"
              [dropdownList]="prefecturesDropdown"
              [required]="true"
              [preDefinedId]="invertebrateById?.prefecture"
            ></app-dropdown>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[1].name }}</a
      >
      <ng-template ngbNavContent>
        <app-invertebrate-species
          [formGroup]="invertebrateForm"
          [species]="species"
          [speciesById]="speciesById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="invertebrateById?.dataVersion"
          [sampleId]="sampleId"
        ></app-invertebrate-species>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
        >{{ multiTabGroup[2].name }}</a
      >
      <ng-template ngbNavContent>
        <app-invertebrate-threats
          [formGroup]="invertebrateForm"
          [threats]="threats"
          [threatsById]="threatsById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="invertebrateById?.dataVersion"
          [sampleId]="sampleId"
        ></app-invertebrate-threats>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <ng-container
    *ngIf="
      !sampleId ||
      (sampleId && predefinedFiles.length > 0) ||
      (sampleId && !readOnlyForm)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    (buttonClicked)="prepareInvertebrateRequest()"
    [btnText]="sampleId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    [isDisabled]="!invertebrateForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
