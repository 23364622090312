import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormSuccessModalComponent } from 'src/app/shared/form-success-modal/form-success-modal.component';
import { Protocols } from 'src/app/core/enums/protocol-ids';
import { MammalsComponent } from '../mammals/mammals.component';
import { AmphibiansComponent } from '../amphibians/amphibians.component';
import { FishComponent } from '../fish/fish.component';
import { HabitatComponent } from '../habitat/habitat.component';
import { InvertebratesComponent } from '../invertebrates/invertebrates.component';
import { SeaComponent } from '../sea/sea.component';
import { SeaHabitatComponent } from '../sea-habitat/sea-habitat.component';
import { BirdsComponent } from '../birds/birds.component';
import { ChlorisComponent } from '../chloris/chloris.component';
import { BookHabitatsSpeciesComponent } from '../book-habitats-species/book-habitats-species.component';
import { Subject } from 'rxjs';
import { IMammalsDto } from 'src/app/core/dtos/mammals.dto';
import { IAmphibianDto } from 'src/app/core/dtos/amphibian.dto';
import { IInvertebrateDto } from 'src/app/core/dtos/invertebrate.dto';
import { IBirdDto } from 'src/app/core/dtos/bird.dto';
import { IChlorisDto } from 'src/app/core/dtos/chloris.dto';
import { IHabitatDto } from 'src/app/core/dtos/habitat.dto';
import { IFishDto } from 'src/app/core/dtos/fish.dto';
import { ISeaDto } from 'src/app/core/dtos/sea.dto';
import { IBookSpeciesHabitatSpreadDto } from 'src/app/core/dtos/bookSpeciesHabitatSpread.dto';
import { BookHabitatsSpeciesService } from '../../services/book-habitats-species.service';
import { ISeaHabitatsDto } from 'src/app/core/dtos/sea-habitat.dto';
import { Table } from 'primeng/table';
import {
  URL_FISH,
  URL_AMPHIBIAN,
  URL_INVERTEBRATES,
  URL_MAMMAL,
  URL_SEA_SPECIES,
  URL_SEA_HABITATS,
  URL_HABITATS,
  URL_CHLORIS,
  URL_BIRD,
  URL_BIBLIOGRAPHY,
} from 'src/app/URLS';
import { UploadFilesModalComponent } from 'src/app/shared/upload-files-modal/upload-files-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { IDataTableColumns } from 'src/app/core/consts/data-table-columns';
import { SamplesService } from '../../services/samples.service';
import { IUploadCodesResponse } from 'src/app/core/dtos/upload-codes.dto';

@Component({
  selector: 'app-new-record',
  templateUrl: './new-record.component.html',
  styleUrls: ['./new-record.component.scss'],
})
export class NewRecordComponent implements OnInit, OnDestroy {
  @Input() public protocolId?: Protocols;
  public title = '';
  private destroy$ = new Subject<void>();
  public allRecords:
    | IMammalsDto[]
    | IAmphibianDto[]
    | IInvertebrateDto[]
    | IChlorisDto[]
    | IHabitatDto[]
    | IFishDto[]
    | ISeaDto[]
    | ISeaHabitatsDto[]
    | IBirdDto[]
    | IBookSpeciesHabitatSpreadDto[] = [];
  public protocols = Protocols;
  public cols: IDataTableColumns[] = [];
  public allCols: string[] = [];
  public _selectedColumns: IDataTableColumns[] = [];
  public recordId?: IDataTableColumns;
  public uploadCodes: IUploadCodesResponse[] = [];
  public recordChangedTrigger: boolean = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    public bookService: BookHabitatsSpeciesService,
    public translate: TranslateService,
    private sampleServise: SamplesService
  ) {
    const protocol = this.router.url.replaceAll('/', '');
    if (protocol === URL_AMPHIBIAN) {
      this.protocolId = Protocols.Amphibian;
    } else if (protocol === URL_MAMMAL) {
      this.protocolId = Protocols.Mammals;
    } else if (protocol === URL_FISH) {
      this.protocolId = Protocols.Fish;
    } else if (protocol === URL_SEA_SPECIES) {
      this.protocolId = Protocols.Sea;
    } else if (protocol === URL_CHLORIS) {
      this.protocolId = Protocols.Chloris;
    } else if (protocol === URL_SEA_HABITATS) {
      this.protocolId = Protocols.SeaHabitat;
    } else if (protocol === URL_INVERTEBRATES) {
      this.protocolId = Protocols.Invertebrates;
    } else if (protocol === URL_BIRD) {
      this.protocolId = Protocols.Bird;
    } else if (protocol === URL_HABITATS) {
      this.protocolId = Protocols.Habitats;
    } else if (protocol === URL_BIBLIOGRAPHY) {
      this.protocolId = Protocols.Bibliography;
    }

    this.cols.forEach((col) => {
      col.header = this.translate.instant(col.header);
    });
  }

  ngOnInit(): void {
    const indexOfId = Object.values(Protocols).indexOf(
      this.protocolId as unknown as Protocols
    );
    this.title = Object.keys(Protocols)[indexOfId]?.toUpperCase() ?? 'REPORT';

    if (this.protocolId) {
      this.sampleServise.getUpdateCodes(this.protocolId).subscribe((res) => {
        this.uploadCodes = res;
      });
    }
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }

  public openModal(
    option: 'create' | 'read' | 'update' | 'delete',
    sampleId?: number,
    referenceId?: string
  ) {
    let modalRef: NgbModalRef | undefined;
    let reference = false;

    switch (this.protocolId) {
      case Protocols.Mammals:
        modalRef = this.modalService.open(MammalsComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      case Protocols.Amphibian:
        modalRef = this.modalService.open(AmphibiansComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      case Protocols.Bird:
        modalRef = this.modalService.open(BirdsComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      case Protocols.Chloris:
        modalRef = this.modalService.open(ChlorisComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      case Protocols.Fish:
        modalRef = this.modalService.open(FishComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      case Protocols.Habitats:
        modalRef = this.modalService.open(HabitatComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      case Protocols.Invertebrates:
        modalRef = this.modalService.open(InvertebratesComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      case Protocols.Sea:
        modalRef = this.modalService.open(SeaComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      case Protocols.SeaHabitat:
        modalRef = this.modalService.open(SeaHabitatComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.sampleId = sampleId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;

      default:
        reference = true;
        modalRef = this.modalService.open(BookHabitatsSpeciesComponent, {
          centered: true,
          modalDialogClass: 'new-record-modal',
          keyboard: true,
          backdrop: 'static',
          beforeDismiss: () => this.closeOnEscape(modalRef),
        });
        if (option === 'read') {
          modalRef.componentInstance.referenceId = referenceId;
          modalRef.componentInstance.readOnlyForm = true;
        }
        if (option === 'update') {
          modalRef.componentInstance.referenceId = referenceId;
          modalRef.componentInstance.readOnlyForm = false;
        }
        break;
    }

    if (modalRef) {
      modalRef.componentInstance.protocolId = this.protocolId;

      modalRef.result.then((data) => {
        if (data != null) {
          this.recordChangedTrigger = true;

          const successModalRef = this.modalService.open(
            FormSuccessModalComponent,
            {
              centered: true,
              modalDialogClass: 'success-form-modal',
            }
          );

          successModalRef.componentInstance.param = data;
          successModalRef.componentInstance.reference = reference;
          if (option === 'update') {
            successModalRef.componentInstance.successfullEdit = true;
          }

          successModalRef.result.then(() => {
            this.recordChangedTrigger = false;
          });
        }
      });
    }
  }

  /**
   * Function that uses the component's confirmClose function to determine whether to close the modal or not
   * @param modalRef the modal to try and close
   * @returns false so that the modal does not close without the checks
   */
  private closeOnEscape(modalRef: NgbModalRef | undefined) {
    modalRef?.componentInstance.confirmClose();
    return false;
  }

  public clear(table: Table) {
    table.clear();
  }

  /**
   * Gets the global filters for the table.
   * @returns An array of strings containing the
   * properties participating in the table filtering.
   */
  public getGlobalFilters(): string[] {
    if (this.protocolId === Protocols.Habitats) {
      return ['releveNR', 'date', 'researcher'];
    } else if (this.protocolId === Protocols.Bibliography) {
      return ['referenceId', 'referenceTypeCode', 'articleTitle'];
    }

    return ['samplingCode', 'date', 'researcher'];
  }

  public openUploadFilesModal(): void {
    const modalRef = this.modalService.open(UploadFilesModalComponent, {
      centered: true,
      size: 'lg',
    });

    modalRef.componentInstance.isBibliography =
      this.protocolId === Protocols.Bibliography;
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
