import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ISearchResponse,
  ISearchSamplingResponse,
  ISpeciesSearchResponse,
  ITableFilterRequest,
} from '../table/interfaces/table.interfaces';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient) {}

  public filter(payload: ITableFilterRequest): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterSea(payload: ITableFilterRequest): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/sea';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterSeaHabitats(
    payload: ITableFilterRequest
  ): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/sea-habitats';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterAmphibians(
    payload: ITableFilterRequest
  ): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/amphibians';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterMammals(
    payload: ITableFilterRequest
  ): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/mammals';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterFish(payload: ITableFilterRequest): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/fish';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterChloris(
    payload: ITableFilterRequest
  ): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/chloris';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterBird(payload: ITableFilterRequest): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/bird';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterBook(payload: ITableFilterRequest): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/book';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public filterInvertebrate(
    payload: ITableFilterRequest
  ): Observable<ISearchResponse> {
    const url = environment.endpoint + 'Search/filter/invertebrate';
    return this.http.post<ISearchResponse>(url, payload);
  }

  public getSpecies(
    protocol: number,
    dataVersion?: string
  ): Observable<ISpeciesSearchResponse[]> {
    const url = environment.endpoint + 'Search/species';
    const params = new HttpParams()
      .set('DataVersion', dataVersion ?? '')
      .set('Protocol', protocol);
    return this.http.get<ISpeciesSearchResponse[]>(url, { params: params });
  }

  public getSampleId(
    samplingCode: string,
    sampleId: number
  ): Observable<ISearchSamplingResponse> {
    const url = environment.endpoint + 'Search/sampleId';
    const params = new HttpParams()
      .set('samplingCode', samplingCode)
      .set('sampleId', sampleId);
    return this.http.get<ISearchSamplingResponse>(url, { params: params });
  }

  public getZipFile(sampleIds: number[]) {
    const url = environment.endpoint + 'Search/download/getZip';
    return this.http.post(url, sampleIds, { responseType: 'blob' });
  }
}
