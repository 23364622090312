import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';

import {
  ISeaSpeciesDto,
  ISeaSpeciesRequestDto,
} from 'src/app/core/dtos/sea.dto';
import { CommonService } from 'src/app/core/services/common.service';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { SeaSpeciesCategory } from '../../config-files/sea-config';
import { SeaService } from '../../services/sea.service';

@Component({
  selector: 'app-sea-species',
  templateUrl: './sea-species.component.html',
  styleUrls: ['./sea-species.component.scss'],
})
export class SeaSpeciesComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  public ctSpecies: ISeaSpeciesDto[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public seaSpeciesCategory = SeaSpeciesCategory;
  public speciesRadio = '';

  public EOKDropdown: IDropdownItem[] = [
    { id: 0, name: this.translate.instant('PARII') },
    { id: 1, name: this.translate.instant('PARIV') },
    { id: 2, name: this.translate.instant('PARV') },
  ];

  public habitatDropdown: IDropdownItem[] = [
    { id: 'A', name: 'Καταφύγιο/ Σπηλιά: Εντός αυτού, στην ξηρά' },
    { id: 'B', name: 'Καταφύγιο/ Σπηλιά: Εντός αυτού, στο νερό' },
    { id: 'C', name: 'Καταφύγιο/ Σπηλιά: Εκτός αυτού' },
    { id: 'D', name: 'Καταφύγιο/ Σπηλιά: Ασαφές' },
    { id: 'E', name: 'Ανοιχτό σπήλαιο' },
    { id: 'F', name: 'Παραλία' },
    { id: 'G', name: 'Βράχια' },
    { id: 'H', name: 'Θάλασσα' },
    { id: 'I', name: 'Οικιστική περιοχή' },
  ];

  public methodTurtleDropdown: IDropdownItem[] = [
    { id: 'Από το ίχνος φωλεοποίησης', name: 'Από το ίχνος φωλεοποίησης' },
    { id: 'Από εκκόλαψη αβγών', name: 'Από εκκόλαψη αβγών' },
    { id: 'Από θήρευση', name: 'Από θήρευση' },
    { id: 'Από παρατήρηση της ωοτοκίας', name: 'Από παρατήρηση της ωοτοκίας' },
    { id: 'Άλλο', name: 'Άλλο' },
  ];

  public deathStatusDropdown: IDropdownItem[] = [
    { id: 'ΝΕΚΡΟ', name: 'ΝΕΚΡΟ' },
    { id: 'ΖΩΝΤΑΝΟ', name: 'ΖΩΝΤΑΝΟ' },
  ];

  public deathReassonDropdown: IDropdownItem[] = [
    { id: 'ΑΓΝΩΣΤΟ', name: 'ΑΓΝΩΣΤΟ' },
    { id: 'ΑΝΘΡΩΠΟΓΕΝΗΣ', name: 'ΑΝΘΡΩΠΟΓΕΝΗΣ' },
    { id: 'ΜΗ ΑΝΘΡΩΠΟΓΕΝΗΣ', name: 'ΜΗ ΑΝΘΡΩΠΟΓΕΝΗΣ' },
  ];

  public genderDropdown: IDropdownItem[] = [
    { id: 'Αρσενικό', name: this.translate.instant('MALE') },
    { id: 'Θηλυκό', name: this.translate.instant('FEMALE') },
  ];

  public depthRangeDropdown: IDropdownItem[] = [
    { id: '00-05', name: '00-05' },
    { id: '05-10', name: '05-10' },
    { id: '10-15', name: '10-15' },
    { id: '15-20', name: '15-20' },
    { id: '20-25', name: '20-25' },
    { id: '25-30', name: '25-30' },
  ];

  public ageSealsDropdown: IDropdownItem[] = [
    { id: 'Νεογέννητο', name: 'Νεογέννητο' },
    { id: 'Απογαλακτισμένο', name: 'Απογαλακτισμένο' },
    { id: 'Νεαρό', name: 'Νεαρό' },
    { id: 'Ενήλικο', name: 'Ενήλικο' },
    { id: 'Άγνωστο', name: 'Άγνωστο' },
  ];

  public ageSeaTurtlesDropdown: IDropdownItem[] = [
    { id: 'Νεαρό άτομο', name: 'Νεαρό άτομο' },
    { id: 'Ενήλικο άτομο', name: 'Ενήλικο άτομο' },
    { id: 'Άγνωστο', name: 'Άγνωστο' },
  ];

  public ageDropdown: IDropdownItem[] = [];

  @Input() public species!: FormArray;
  @Input() public formGroup!: FormGroup;
  @Input() public speciesById: ISeaSpeciesRequestDto[] = [];
  @Input() public disabledControls = false;
  @Input() public speciesCategory = '';
  @Input() public dataVersion?: string;
  @Input() public sampleId?: number;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private seaService: SeaService,
    private common: CommonService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.speciesById.length === 0 && !this.sampleId) {
      this.getValuesFromServices();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesById'] && this.sampleId) {
      this.getValuesFromServices();
    }

    if (changes['speciesCategory']) {
      this.updateDropdown();
      if (this.speciesCategory === SeaSpeciesCategory.Seals) {
        this.ageDropdown = this.ageSealsDropdown;
      } else if (this.speciesCategory === SeaSpeciesCategory.SeaTurtles) {
        this.ageDropdown = this.ageSeaTurtlesDropdown;
      }
    }
  }

  private getValuesFromServices() {
    this.seaService
      .getSpecies(this.dataVersion)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          if (this.speciesById.length > 0) {
            this.speciesById.forEach(() => {
              this.addNewSpecies();
            });
            this.species.patchValue(this.speciesById);
            this.species.markAsTouched();
            this.speciesById = [];
            if (this.disabledControls === true) {
              this.species.disable();
            }
          }

          return;
        })
      )
      .subscribe((res) => {
        if (res != undefined) {
          this.ctSpecies = res as ISeaSpeciesDto[];
          this.ctSpecies = this.ctSpecies.sort((a, b) => {
            return a.code > b.code ? 1 : -1;
          });
        }
        this.updateDropdown();
        this.species.valueChanges.subscribe((value) => {
          value.forEach((control: any, index: number) => {
            this.checkSpecies(control['species'], index);
          });

          this.species.controls.forEach((control: AbstractControl) => {
            if (!(control as FormGroup).controls['species'].disabled) {
              const species = this.ctSpecies.find(
                (item) =>
                  item.id === (control as FormGroup).controls['species'].value
              );
              if (species == null) {
                (control as FormGroup).controls['otherSpecies'].setValue('', {
                  emitEvent: false,
                });
                (control as FormGroup).controls[
                  'otherSpecies'
                ].clearValidators();
                (control as FormGroup).controls['otherSpecies'].disable({
                  emitEvent: false,
                });
                return;
              }

              if (species.code === 'Άλλο') {
                (control as FormGroup).controls['otherSpecies'].enable({
                  emitEvent: false,
                });
                (control as FormGroup).controls['otherSpecies'].setValidators(
                  Validators.required
                );
                (control as FormGroup).controls[
                  'otherSpecies'
                ].updateValueAndValidity({
                  emitEvent: false,
                  onlySelf: true,
                });
              } else {
                (control as FormGroup).controls['otherSpecies'].setValue('', {
                  emitEvent: false,
                });
                (control as FormGroup).controls[
                  'otherSpecies'
                ].clearValidators();
                (control as FormGroup).controls['otherSpecies'].disable({
                  emitEvent: false,
                });
                (control as FormGroup).controls[
                  'otherSpecies'
                ].updateValueAndValidity({
                  emitEvent: false,
                  onlySelf: true,
                });
              }
            }
          });
        });
      });
  }

  private updateDropdown() {
    // If category is Seals, convert it to cetaceans
    const speciesDropdown: IDropdownItem[] = [];
    this.ctSpecies
      .filter((species) => {
        return (
          species.subClass === this.speciesCategory || species.code === 'Άλλο'
        );
      })
      .forEach((species) => {
        speciesDropdown.push({
          id: species.code,
          name: species.code,
          actualId: species.id,
        });
      });
    if (
      this.speciesCategory !== SeaSpeciesCategory.Cetaceans &&
      this.speciesCategory !== SeaSpeciesCategory.SeaInvertebrates
    ) {
      const index = speciesDropdown.findIndex((item) => item.id === 'Άλλο');
      if (index !== -1) {
        speciesDropdown.splice(index, 1);
      }
    }

    this.speciesDropdown = speciesDropdown;
  }

  public checkSpecies(species: string, index: number): void {
    let speciesCode = this.ctSpecies.find(
      (value) => value.id === parseInt(species)
    );
    if (speciesCode != null) {
      if (parseInt(species) === speciesCode.id) {
        if (speciesCode.annexII?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'annexII'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'annexII'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexIV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'annexIV'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'annexIV'
          ].setValue(false, { emitEvent: false });
        }
        if (speciesCode.annexV?.startsWith('Y')) {
          (this.species.controls[index] as FormGroup).controls[
            'annexV'
          ].setValue(true, { emitEvent: false });
        } else {
          (this.species.controls[index] as FormGroup).controls[
            'annexV'
          ].setValue(false, { emitEvent: false });
        }
      }
    }
  }

  public getGroup(index: number): FormGroup {
    return this.species.controls[index] as FormGroup;
  }

  public addNewSpecies(): void {
    this.species.push(
      this.seaService.initSpeciesFormGroup(
        this.fb,
        this.speciesCategory !== SeaSpeciesCategory.SeaInvertebrates
      )
    );
  }

  public removeSpecies(index: number): void {
    this.species.removeAt(index);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
