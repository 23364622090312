<div class="wrapper-container">
  <div class="title">
    {{ "SEARCH_PAGE_HEADER" | translate }}
  </div>
  <div class="options">
    <div class="version-dropdown">
      <app-dropdown
        class="dropdown-column"
        [formGroup]="form"
        [formName]="'dataVersion'"
        [label]="'SEARCH_DATA_VERSION'"
        [dropdownList]="dataVersions"
        [preDefinedId]="version"
      ></app-dropdown>
    </div>
    <app-button
      class="btn-export"
      (buttonClicked)="openExportModal()"
      [btnText]="'EXPORT_BUTTON'"
      [tooltip]="'EXPORT_DATA' | translate"
      [isExport]="true"
    ></app-button>
  </div>

  <nav
    ngbNav
    #nav="ngbNav"
    [destroyOnHide]="false"
    [activeId]="activeTabId"
    class="multi-tab-buttons"
  >
    <ng-container [ngbNavItem]="0">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name | translate }}</a
      >
      <ng-template ngbNavContent>
        <div class="table-content">
          <app-table
            [columns]="sampleColumns"
            [records]="samples"
            [totalRecords]="sampleTotalRecords"
            (onLoadData)="loadSampleData($event)"
            [first]="sampleFirst"
            (onRecordChange)="!!$event ? fetchData() : null"
          ></app-table>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="1">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[1].name | translate
        }}<span class="map-indicator samples"></span
      ></a>
      <ng-template ngbNavContent>
        <div class="table-content">
          <app-table
            [columns]="sampleInvertebrateColumns"
            [records]="sampleInvertebrate"
            [totalRecords]="sampleInvertebrateTotalRecords"
            (onLoadData)="loadSampleInvertebrateData($event)"
            [first]="sampleInvertebrateFirst"
            (onRecordChange)="!!$event ? fetchData() : null"
          ></app-table>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="2">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[2].name | translate }}</a
      >
      <ng-template ngbNavContent>
        <div class="table-content">
          <app-table
            [columns]="speciesColumns"
            [records]="species"
            [totalRecords]="speciesTotalRecords"
            (onLoadData)="loadSpeciesData($event)"
            [first]="speciesFirst"
            (onRecordChange)="!!$event ? fetchData() : null"
          ></app-table>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="3">
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[3].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[3].name | translate }}</a
      >
      <ng-template ngbNavContent>
        <div class="table-content">
          <app-table
            [columns]="threatsColumns"
            [records]="threats"
            [totalRecords]="threatsTotalRecords"
            (onLoadData)="loadThreatsData($event)"
            [first]="threatsFirst"
            (onRecordChange)="!!$event ? fetchData() : null"
          ></app-table>
        </div>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <div class="map">
    <app-map-search
      [hasSample]="true"
      [hasSpecies]="true"
      [sampleCoordinates]="sampleCoordinates"
      [speciesCoordinates]="speciesCoordinates"
      (circleSearchUpdated)="locationCircleSearch($event)"
      (polygonSearchUpdated)="locationPolygonSearch($event)"
    ></app-map-search>
  </div>
</div>
