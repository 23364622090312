import { Validators } from '@angular/forms';
import { IConfigFile } from 'src/app/core/dtos/config-files.dto';
import { dateValidator } from 'src/app/shared/validators/dateValidator';
import { timeValidator } from 'src/app/shared/validators/timeValidator';
export enum AmphibianFormGroup {
  Habitats = 'habitats',
  Species = 'species',
  RandomObservations = 'species',
  SpeciesThreats = 'species.speciesThreats',
}
export const amphibianConfig: IConfigFile[] = [
  // This is the 1st sheet
  {
    formGroupName: '',
    formType: 'formArray',
    headerNumber: 'A/A',
    validateLocation: [
      ['Γεωγραφικό Πλάτος (WGS84)', 'Γεωγραφικό Μήκος (WGS84)', false],
    ],
    removeValidations: [
      {
        formName: 'date',
        validators: [Validators.required, dateValidator()],
        removeLatLongValidator: false,
      },
      {
        formName: 'time',
        validators: [Validators.required, timeValidator()],
        removeLatLongValidator: false,
      },
      {
        formName: 'samplingCode',
        validators: [Validators.required],
        removeLatLongValidator: false,
      },
      {
        formName: 'financialInstrument',
        validators: [Validators.required],
        removeLatLongValidator: false,
      },
      {
        formName: 'lat',
        validators: [Validators.required],
        removeLatLongValidator: true,
      },
      {
        formName: 'long',
        validators: [Validators.required],
        removeLatLongValidator: true,
      },
      {
        formName: 'method',
        validators: [Validators.required],
        removeLatLongValidator: false,
      },
    ],
    configuration: [
      {
        header: 'Τυχαία Παρατήρηση',
        type: 'boolean',
        formName: 'randomObservation',
        order: 1,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 2,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 5,
      },
      {
        header: 'Ώρα Δειγματοληψίας',
        type: 'time',
        formName: 'time',
        order: 6,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 8,
      },
      {
        header: 'Χρηματοδοτικό Μέσο',
        type: 'string',
        formName: 'financialInstrument',
        order: 10,
      },
      {
        header: 'Τοποθεσία (Τοπωνύμιο)',
        type: 'string',
        formName: 'locality',
        order: 13,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84)',
        type: 'float',
        formName: 'lat',
        order: 15,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84)',
        type: 'float',
        formName: 'long',
        order: 16,
      },
      {
        header: 'Παρατηρήσεις',
        type: 'string',
        formName: 'observations',
        order: 24,
      },
      // ------------------------ End of Sample ---------------------
      {
        header: 'Έργο εποπτείας/Φάση έργου',
        type: 'string',
        formName: 'ergoEpoptiaFasiErgou',
        order: 3,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 4,
      },
      {
        header: 'Διάρκεια',
        type: 'duration',
        formName: 'duration',
        order: 7,
      },
      {
        header: 'Συντάκτης',
        type: 'string',
        formName: 'author',
        order: 9,
      },
      {
        header: 'Μέθοδος Δειγματοληψίας',
        type: 'string',
        formName: 'method',
        order: 11,
      },
      {
        header: 'Περιγραφή ενδιαιτήματος',
        type: 'string',
        formName: 'habitatDescription',
        order: 12,
      },
      {
        header: 'Μήκος διαδρομής (m)',
        type: 'float',
        formName: 'courseLengthM',
        order: 14,
      },
      {
        header: 'Shapefile',
        type: 'string',
        formName: 'shapefile',
        order: 17,
      },
      {
        header: 'Υψόμετρο (m)',
        type: 'float',
        formName: 'altitude',
        order: 18,
      },
      {
        header: 'Θερμοκρασία (°C)',
        type: 'float',
        formName: 'temperature',
        order: 19,
      },
      {
        header: 'Ένταση Ανέμου (Bft)',
        type: 'float',
        formName: 'windIntensity',
        order: 20,
      },
      {
        header: 'Ηλιοφάνεια / Νέφωση',
        type: 'string',
        formName: 'weather',
        order: 21,
      },
      {
        header: 'Βροχόπτωση',
        type: 'int',
        formName: 'rainCode',
        order: 22,
      },
      {
        header: 'Πιέσεις/Απειλές Ενδιαιτήματος',
        type: 'string',
        formName: 'threats',
        order: 23,
      },
    ],
  },
  // This is the 2nd sheet
  {
    formGroupName: AmphibianFormGroup.Habitats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 1,
        disabled: true,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
        disabled: true,
      },
      {
        header: '1ος Κωδικός κύριου ενδιαιτήματος',
        type: 'int',
        formName: 'habitatCodeA',
        order: 3,
      },
      {
        header: '2ος Κωδικός κύριου ενδιαιτήματος',
        type: 'string',
        formName: 'habitatCodeB',
        order: 4,
      },
    ],
  },
  // This is the 3rd sheet
  {
    formGroupName: AmphibianFormGroup.Species,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    validateLocation: [
      [
        'Γεωγραφικό Πλάτος (WGS84) είδους',
        'Γεωγραφικό Μήκος (WGS84) είδους',
        false,
      ],
    ],
    configuration: [
      {
        header: 'Obs_ID',
        type: 'string',
        formName: 'obsId',
        order: 1,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 3,
        disabled: true,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 4,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 5,
      },
      {
        header: 'Ώρα εύρεσης είδους',
        type: 'time',
        formName: 'time',
        order: 6,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 7,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 8,
      },
      {
        header: 'Αριθμός ατόμων είδους',
        type: 'int',
        formName: 'numberOfPeople',
        order: 9,
      },
      {
        header: 'Δραστηριότητα είδους',
        type: 'string',
        formName: 'activity',
        order: 10,
      },
      {
        header: 'Μικροενδιαίτημα είδους',
        type: 'string',
        formName: 'microHabitat',
        order: 11,
      },
      {
        header: '1ος Κωδικός ενδιαιτήματος είδους',
        type: 'int',
        formName: 'habitatCodeA',
        order: 12,
      },
      {
        header: '2ος Κωδικός ενδιαιτήματος είδους',
        type: 'string',
        formName: 'habitatCodeB',
        order: 13,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84) είδους',
        type: 'float',
        formName: 'pointX',
        order: 14,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84) είδους',
        type: 'float',
        formName: 'pointY',
        order: 15,
      },
      {
        header: 'Υψόμετρο (m) είδους',
        type: 'float',
        formName: 'altitude',
        order: 16,
      },
      {
        header: 'Ποιότητα ενδιαιτήματος είδους',
        type: 'string',
        formName: 'habitatQuality',
        order: 17,
      },
      {
        header: 'Σχόλια είδους',
        type: 'string',
        formName: 'observations',
        order: 18,
      },
    ],
  },
  // This is the 4th sheet
  {
    isRandomSheet: true,
    formGroupName: AmphibianFormGroup.RandomObservations,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    validateLocation: [
      [
        'Γεωγραφικό Πλάτος (WGS84) είδους',
        'Γεωγραφικό Μήκος (WGS84) είδους',
        false,
      ],
    ],
    addValidations: [
      {
        formName: 'pointX',
        validators: [Validators.required],
      },
      {
        formName: 'pointY',
        validators: [Validators.required],
      },
    ],
    configuration: [
      {
        header: 'Obs_ID',
        type: 'string',
        formName: 'obsId',
        order: 1,
      },
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 2,
        disabled: true,
      },
      {
        header: 'Ερευνητής',
        type: 'string',
        formName: 'researcher',
        order: 3,
      },
      {
        header: 'Ημερομηνία',
        type: 'date',
        formName: 'date',
        order: 4,
      },
      {
        header: 'Ώρα εύρεσης είδους',
        type: 'time',
        formName: 'time',
        order: 5,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 6,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 7,
      },
      {
        header: 'Αριθμός ατόμων είδους',
        type: 'int',
        formName: 'numberOfPeople',
        order: 8,
      },
      {
        header: 'Δραστηριότητα είδους',
        type: 'string',
        formName: 'activity',
        order: 9,
      },
      {
        header: 'Μικροενδιαίτημα είδους',
        type: 'string',
        formName: 'microHabitat',
        order: 10,
      },
      {
        header: '1ος Κωδικός ενδιαιτήματος είδους',
        type: 'int',
        formName: 'habitatCodeA',
        order: 11,
      },
      {
        header: '2ος Κωδικός ενδιαιτήματος είδους',
        type: 'string',
        formName: 'habitatCodeB',
        order: 12,
      },
      {
        header: 'Γεωγραφικό Πλάτος (WGS84) είδους',
        type: 'float',
        formName: 'pointX',
        order: 13,
      },
      {
        header: 'Γεωγραφικό Μήκος (WGS84) είδους',
        type: 'float',
        formName: 'pointY',
        order: 14,
      },
      {
        header: 'Υψόμετρο (m) είδους',
        type: 'float',
        formName: 'altitude',
        order: 15,
      },
      {
        header: 'Ποιότητα ενδιαιτήματος είδους',
        type: 'string',
        formName: 'habitatQuality',
        order: 16,
      },
      {
        header: 'Σχόλια είδους',
        type: 'string',
        formName: 'observations',
        order: 17,
      },
    ],
  },
  // This is the 5th sheet
  {
    formGroupName: AmphibianFormGroup.SpeciesThreats,
    formType: 'formArray',
    headerNumber: 'A/A',
    headerAssignedNumber: 'Sam_ID',
    splitHeaderToMap: true,
    configuration: [
      {
        header: 'Sam_ID',
        type: 'string',
        formName: 'samId',
        order: 1,
        disabled: true,
      },
      {
        header: 'Κωδικός Δειγματοληψίας (Έντυπο)',
        type: 'string',
        formName: 'samplingCode',
        order: 2,
        disabled: true,
      },
      {
        header: 'Obs_ID Τυχαίας Παρατήρησης',
        type: 'string',
        formName: 'obsId',
        order: 3,
        disabled: true,
      },
      {
        header: 'Όνομα είδους',
        type: 'string',
        formName: 'species',
        order: 4,
      },
      {
        header: 'Άλλο είδος',
        type: 'string',
        formName: 'otherSpecies',
        order: 5,
      },
      {
        header: 'Κωδικός πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'pressuresAndThreatsId',
        order: 6,
      },
      {
        header: 'Όνομα είδους πίεσης ή απειλής',
        type: 'string',
        formName: 'pressuresAndThreatsDescription',
        order: 7,
        disabled: true,
      },
      {
        header: 'Σημασία / Επίδραση πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'importance',
        order: 8,
      },
      {
        header: 'Χρονικός Χαρακτήρας πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'timeCharacter',
        order: 9,
      },
      {
        header: 'Τρόπος Επίδρασης πίεσης ή απειλής είδους',
        type: 'string',
        formName: 'impact',
        order: 10,
      },
    ],
  },
];
