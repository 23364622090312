import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import {
  IAmphibianSpeciesDto,
  IAmphibianThreatsDto,
} from 'src/app/core/dtos/amphibian.dto';
import { IDropdownItem } from 'src/app/shared/dropdown/dropdown.component';
import { AmphibiansService } from '../../services/amphibians.service';

@Component({
  selector: 'app-amphibian-threats',
  templateUrl: './amphibian-threats.component.html',
  styleUrls: ['./amphibian-threats.component.scss'],
})
export class AmphibianThreatsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public ctThreats: IAmphibianThreatsDto[] = [];
  public threatsForm!: FormGroup;
  public threatsDropdown: IDropdownItem[] = [];
  public ctSpecies: IAmphibianSpeciesDto[] = [];
  public speciesDropdown: IDropdownItem[] = [];
  public typeDropdown: IDropdownItem[] = [
    {
      id: this.translate.instant('TYPE_P'),
      name: this.translate.instant('TYPE_P'),
    },
    {
      id: this.translate.instant('TYPE_T'),
      name: this.translate.instant('TYPE_T'),
    },
  ];
  public importanceDropdown: IDropdownItem[] = [
    {
      id: 'L',
      name: 'L',
    },
    {
      id: 'M',
      name: 'M',
    },
    {
      id: 'H',
      name: 'H',
    },
  ];

  public timeCharacterDropdown: IDropdownItem[] = [
    {
      id: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
      name: 'Στο παρελθόν και τώρα σε αναστολή μέσω μέτρων',
    },
    {
      id: 'Στο παρόν',
      name: 'Στο παρόν',
    },
    {
      id: 'Στο παρόν και στο μέλλον',
      name: 'Στο παρόν και στο μέλλον',
    },
    {
      id: 'Μόνο στο μέλλον',
      name: 'Μόνο στο μέλλον',
    },
  ];

  public impactDropdown: IDropdownItem[] = [
    {
      id: 'Έκταση',
      name: 'Έκταση',
    },
    {
      id: 'Έκταση και Δομή/λειτουργίες',
      name: 'Έκταση και Δομή/λειτουργίες',
    },
    {
      id: 'Δομή/λειτουργίες',
      name: 'Δομή/λειτουργίες',
    },
  ];

  @Input() public threats!: FormArray;
  @Input() public formGroup!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private amphibiansService: AmphibiansService
  ) { }

  /**
   *
   */
  ngOnInit(): void {
    this.amphibiansService
      .getAmphibianThreats()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res != undefined) {
          this.ctThreats = res as IAmphibianThreatsDto[];
          if (this.ctThreats.length > 0) {
            this.ctThreats.forEach((threat) => {
              this.threatsDropdown.push({
                id: threat.code,
                name: threat.description,
              });
            });
          }
        }
      });

    this.amphibiansService
      .getAmphibianSpecies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res != undefined) {
          this.ctSpecies = res as IAmphibianSpeciesDto[];
          if (this.ctSpecies.length > 0) {
            this.ctSpecies.forEach((species) => {
              this.speciesDropdown.push({
                id: species.code,
                name:
                  species.otherName != null ? species.otherName : '',
              });
            });
          }
        }
      });
  }

  public getGroup(index: number): FormGroup {
    return this.threats.controls[index] as FormGroup;
  }

  public addNewThreats(): void {
    this.threats.push(this.initThreatsFormGroup());
  }

  public removeThreats(index: number): void {
    this.threats.removeAt(index);
  }

  /**
   * Initialization of the user form.
   */
  private initThreatsFormGroup(): FormGroup {
    return this.fb.group({
      pressuresAndThreatsId: this.fb.control('', [Validators.required]),
      speciesId: this.fb.control('', [Validators.required]),
      type: this.fb.control('', [Validators.required]),
      importance: this.fb.control('', [Validators.required]),
      timeCharacter: this.fb.control('', [Validators.required]),
      impact: this.fb.control('', [Validators.required])
    });
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
