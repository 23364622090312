<div class="close-modal" (click)="confirmClose()">
  <img src="assets/images/x.svg" />
</div>
<div class="title-container">
  <span class="title-text uppercase"
    >{{ "NEW_REGISTRATION" | translate }} -
    {{ "SEA_HABITATS" | translate }}</span
  >
</div>
<div class="sea-habitat-container">
  <div class="info-container uppercase">
    <span class="info-text">{{ "GENERAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container">
    <div class="form-row form-3">
      <app-input
        [formGroup]="seaHabitatForm"
        [formName]="'samplingCode'"
        [label]="'SAMPLE_CODE'"
        [required]="true"
      ></app-input>
    </div>
    <div class="form-row form-3">
      <app-dropdown
        class="dropdown-column"
        [formGroup]="seaHabitatForm"
        [formName]="'ergoEpoptiaFasiErgou'"
        [label]="'ERGO_EPOPTIA_FASI_ERGOU'"
        [dropdownList]="ergoEpoptiaFasiErgouDropdown"
        [required]="true"
        [preDefinedId]="seaHabitatById?.ergoEpoptiaFasiErgou"
      ></app-dropdown>
    </div>
    <div class="form-row form-3">
      <app-input
        [mask]="'00/00/0000'"
        [formGroup]="seaHabitatForm"
        [formName]="'date'"
        [label]="'DATE'"
        [required]="true"
      ></app-input>
      <app-input
        [mask]="'00:00'"
        [formGroup]="seaHabitatForm"
        [formName]="'time'"
        [label]="'TIME'"
        [required]="true"
      ></app-input>
      <app-input
        [formGroup]="seaHabitatForm"
        [formName]="'researcher'"
        [label]="'RESEARCHER'"
      ></app-input>
    </div>
    <div class="form-row">
      <app-input
        [formGroup]="seaHabitatForm"
        [formName]="'author'"
        [label]="'AUTHOR'"
      ></app-input>
      <app-dropdown
        [formGroup]="seaHabitatForm"
        [formName]="'financialInstrument'"
        [label]="'FINANCIAL_INSTRUMENT'"
        [dropdownList]="financialDropdown"
        [required]="true"
        [preDefinedId]="seaHabitatById?.financialInstrument"
      ></app-dropdown>
      <app-dropdown
        [hidden]="!readOnlyForm"
        class="dropdown-column"
        [formGroup]="seaHabitatForm"
        [formName]="'naturaCode'"
        [label]="'NATURA_CODE'"
        [dropdownList]="sitesDropdown"
        [required]="true"
        [preDefinedId]="seaHabitatById?.naturaCode"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-dropdown
        [formGroup]="seaHabitatForm"
        [formName]="'method'"
        [label]="'METHOD'"
        [dropdownList]="methodDropdown"
        [required]="true"
        [preDefinedId]="seaHabitatById?.method"
      ></app-dropdown>
    </div>
    <div class="form-row">
      <app-dropdown
        [formGroup]="seaHabitatForm"
        [formName]="'habType'"
        [label]="'HABITAT_TYPE'"
        [dropdownList]="habitatTypesDropdown"
        [required]="true"
        [preDefinedId]="seaHabitatById?.habType"
      ></app-dropdown>
      <app-dropdown
        *ngIf="
          seaHabitatForm.controls['typology'].enabled ||
          seaHabitatById?.typology
        "
        [formGroup]="seaHabitatForm"
        [formName]="'typology'"
        [label]="'TYPOLOGY'"
        [dropdownList]="typologyDropdown"
        [preDefinedId]="seaHabitatById?.typology"
      ></app-dropdown>
    </div>
    <div class="form-row form-3"></div>
    <app-textarea
      [style.max-width]="'100%'"
      [formGroup]="seaHabitatForm"
      [formName]="'observations'"
      [label]="'OBSERVATIONS'"
    ></app-textarea>
  </div>
  <div class="info-container uppercase">
    <span class="info-text">{{ "LOCATION" | translate }}</span>
    <div class="line"></div>
  </div>
  <div class="form-container form-1">
    <div class="form-row" [formGroup]="seaHabitatForm" [hidden]="readOnlyForm">
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isEGSA" />
        {{ coordSystemDropdown[0].id }}
      </label>
      <label class="btn btn-secondary" ngbButtonLabel>
        <input type="checkbox" formControlName="isWGS84" />
        {{ coordSystemDropdown[1].id }}
      </label>
    </div>
    <div class="form-row">
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="seaHabitatForm"
        [formName]="'lat'"
        [label]="'LATITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 1 }"
        [type]="'number'"
        [formGroup]="seaHabitatForm"
        [formName]="'long'"
        [label]="'LONGTITUDE'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="seaHabitatForm"
        [formName]="'x'"
        [label]="'X'"
        [required]="true"
      ></app-input>
      <app-input
        [ngClass]="{ hidden: coordSystem !== 0 }"
        [type]="'number'"
        [formGroup]="seaHabitatForm"
        [formName]="'y'"
        [label]="'Y'"
        [required]="true"
      ></app-input>
    </div>
  </div>
  <br />
  <div class="map-container">
    <app-map
      [coordinates]="coordinates"
      (markerMoved)="onMarkerMoved($event)"
      [disabled]="readOnlyForm"
    ></app-map>
  </div>
  <div class="info-container uppercase">
    <span class="info-text">{{ "ADDITIONAL_INFO" | translate }}</span>
    <div class="line"></div>
  </div>
  <nav
    ngbNav
    #nav="ngbNav"
    id="sea-habitats-id"
    [destroyOnHide]="false"
    class="multi-tab-buttons"
    [ngClass]="{
      'hide-1120': mode === 0 || mode === 2,
      'hide-1170': mode == 0 || mode == 1
    }"
  >
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[0].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[0].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container form-2">
          <div class="form-row">
            <app-input
              [formGroup]="seaHabitatForm"
              [formName]="'locality'"
              [label]="'LOCATION'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="seaHabitatForm"
              [formName]="'depthMin'"
              [label]="'DEPTH_MIN'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="seaHabitatForm"
              [formName]="'depthMax'"
              [label]="'DEPTH_MAX'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-dropdown
              [formGroup]="seaHabitatForm"
              [hidden]="!readOnlyForm"
              [formName]="'gridCell'"
              [label]="'GRID_CELL'"
              [dropdownList]="codesDropdown"
              [required]="true"
              [preDefinedId]="seaHabitatById?.gridCell"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="seaHabitatForm"
              [formName]="'orientation'"
              [label]="'SHORE_ORIENTATION'"
              [dropdownList]="orientationDropdown"
              [preDefinedId]="seaHabitatById?.orientation"
            ></app-dropdown>
            <app-dropdown
              [formGroup]="seaHabitatForm"
              [formName]="'meadowLandscape'"
              [label]="'MEADOW_LANDSCAPE'"
              [dropdownList]="meadowLandscapeDropdown"
              [preDefinedId]="seaHabitatById?.meadowLandscape"
            ></app-dropdown>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        [ngClass]="{ 'checked-btn': multiTabGroup[1].isChecked }"
        ngbNavLink
        >{{ multiTabGroup[1].name }}</a
      >
      <ng-template ngbNavContent>
        <app-sea-habitat-species
          [formGroup]="seaHabitatForm"
          [species]="species"
          [speciesById]="speciesById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="seaHabitatById?.dataVersion"
          [sampleId]="sampleId"
        ></app-sea-habitat-species>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[2].isChecked }"
        >{{ multiTabGroup[2].name }}</a
      >
      <ng-template ngbNavContent>
        <app-sea-habitat-threats
          [formGroup]="seaHabitatForm"
          [threats]="threats"
          [threatsById]="threatsById"
          [disabledControls]="readOnlyForm"
          [dataVersion]="seaHabitatById?.dataVersion"
          [sampleId]="sampleId"
        ></app-sea-habitat-threats>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[3].isChecked }"
        >{{ multiTabGroup[3].name }}</a
      >
      <ng-template ngbNavContent>
        <app-sea-habitat-a
          [formGroup]="seaHabitatForm"
          [a]="a"
          [c]="c"
          [struct1120aById]="struct1120aById"
          [struct1120cById]="struct1120cById"
          [disabledControls]="readOnlyForm"
          [sampleId]="sampleId"
        >
        </app-sea-habitat-a>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[4].isChecked }"
        >{{ multiTabGroup[4].name }}</a
      >
      <ng-template ngbNavContent>
        <app-sea-habitat-b
          [formGroup]="seaHabitatForm"
          [b]="b"
          [struct1120bById]="struct1120bById"
          [disabledControls]="readOnlyForm"
          [sampleId]="sampleId"
          >>
        </app-sea-habitat-b>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="multi-button"
        ngbNavLink
        [ngClass]="{ 'checked-btn': multiTabGroup[5].isChecked }"
        >{{ multiTabGroup[5].name }}</a
      >
      <ng-template ngbNavContent>
        <div class="form-container form-2">
          <div class="sub-category-title">
            {{ "COVER_EEIC_1M" | translate }}
          </div>
          <ng-container
            *ngIf="
              touched1170([
                'EGSIA01',
                'EGSIB01',
                'EGSIC01',
                'EGSIIA01',
                'EGSIIB01',
                'invertebrate01',
                'other01'
              ]) &&
              hasErrors1170([
                'EGSIA01',
                'EGSIB01',
                'EGSIC01',
                'EGSIIA01',
                'EGSIIB01',
                'invertebrate01',
                'other01'
              ])
            "
          >
            <ng-container
              *ngIf="
                getErrors1170([
                  'EGSIA01',
                  'EGSIB01',
                  'EGSIC01',
                  'EGSIIA01',
                  'EGSIIB01',
                  'invertebrate01',
                  'other01'
                ]) != {} &&
                getErrors1170([
                  'EGSIA01',
                  'EGSIB01',
                  'EGSIC01',
                  'EGSIIA01',
                  'EGSIIB01',
                  'invertebrate01',
                  'other01'
                ])['overHundredWidth'] !== null &&
                getErrors1170([
                  'EGSIA01',
                  'EGSIB01',
                  'EGSIC01',
                  'EGSIIA01',
                  'EGSIIB01',
                  'invertebrate01',
                  'other01'
                ])['overHundredWidth']
              "
            >
              <div class="error-message">
                {{ "OVER_HUNDRED_ERROR" | translate }}
              </div>
            </ng-container>
          </ng-container>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'EGSIA01'"
              [label]="'EGSIA01'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'EGSIB01'"
              [label]="'EGSIB01'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'EGSIC01'"
              [label]="'EGSIC01'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'EGSIIA01'"
              [label]="'EGSIIA01'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'EGSIIB01'"
              [label]="'EGSIIB01'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'invertebrate01'"
              [label]="'INVERTEBRATE_01'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'other01'"
              [label]="'OTHER_01'"
            ></app-input>
          </div>
        </div>

        <div class="form-container form-2">
          <div class="sub-category-title">
            {{ "COVER_EEIC_5M" | translate }}
          </div>
          <ng-container
            *ngIf="
              touched1170([
                'woody5',
                'bushy5',
                'filamentousEloquent5',
                'invertebrate5',
                'other5'
              ]) &&
              hasErrors1170([
                'woody5',
                'bushy5',
                'filamentousEloquent5',
                'invertebrate5',
                'other5'
              ])
            "
          >
            <ng-container
              *ngIf="
                getErrors1170([
                  'woody5',
                  'bushy5',
                  'filamentousEloquent5',
                  'invertebrate5',
                  'other5'
                ]) != {} &&
                getErrors1170([
                  'woody5',
                  'bushy5',
                  'filamentousEloquent5',
                  'invertebrate5',
                  'other5'
                ])['overHundredWidth'] !== null &&
                getErrors1170([
                  'woody5',
                  'bushy5',
                  'filamentousEloquent5',
                  'invertebrate5',
                  'other5'
                ])['overHundredWidth']
              "
            >
              <div class="error-message">
                {{ "OVER_HUNDRED_ERROR" | translate }}
              </div>
            </ng-container>
          </ng-container>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'woody5'"
              [label]="'WOODY_5'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'bushy5'"
              [label]="'BUSHY_5'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'filamentousEloquent5'"
              [label]="'FILAMENTOUS_ELOQUENT_5'"
            ></app-input>
          </div>
          <div class="form-row form-3">
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'invertebrate5'"
              [label]="'INVERTEBRATE_5'"
            ></app-input>
            <app-input
              [type]="'number'"
              [formGroup]="struct1170"
              [formName]="'other5'"
              [label]="'OTHER_5'"
            ></app-input>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="multi-tab-container"></div>

  <ng-container
    *ngIf="
      !sampleId ||
      (sampleId && predefinedFiles.length > 0) ||
      (sampleId && !readOnlyForm)
    "
  >
    <div class="info-container">
      <span class="info-text uppercase">{{ "RELATED_FILES" | translate }}</span>
      <div class="line"></div>
    </div>
    <app-files-container
      [predefinedFiles]="predefinedFiles"
      (attachedFiles)="files = $event"
      (filesToBeDeleted)="filesToDelete = $event"
      [readonly]="readOnlyForm"
    ></app-files-container>
  </ng-container>

  <div [ngClass]="errors.length > 0 ? 'errors-visible' : 'errors-invisible'">
    {{ "FORM_VALIDATION_ERRORS" | translate }}
  </div>
  <app-button
    class="completion-btn"
    [btnText]="sampleId ? 'UPDATE_RECORD' : 'COMPLETE_REGISTRATION'"
    (click)="prepareSeaHabitatRequest()"
    [isDisabled]="!seaHabitatForm.valid"
    [style.--btnBgColor]="'var(--color-header)'"
    [style.--btnWidth]="'300px'"
    [hidden]="readOnlyForm"
  ></app-button>
</div>
